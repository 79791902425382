import {END_POINT_URL} from "config/env.conf";
import axios from 'axios';

export function getOptSummary(provinceId,token) {
    const url = `${END_POINT_URL}/opt/optSummary/${provinceId}`;

    return axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
}


export function getSuspendSummary(token) {
    const url = `${END_POINT_URL}/suspend/suspendSummary`;

    return axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
}