import React, { useEffect, useState } from "react";
import {
  addCareer,
  deleteCareer,
  editCareer,
  getCareer,
  getSystem
} from "contexts/API/CommonSetting/MasterData";

import ListboxSystem from "components/Common/ListboxSystem";
import MaterialTable from "components/Table/MaterialTable";
import { useSweetAlert } from "components/Alert/SweetAlert";

const CareerList = props => {
  const { sweetAlerts } = useSweetAlert();
  const [systemList, setSystemList] = useState([]);

  useEffect(() => {
    getSystem()
      .then(r => {
        let sysMapper = r.data.data.map(v => {
          return { id: v.systemTypeId, value: v.detail };
        });

        setSystemList(sysMapper);
      })
      .catch(err => {
        sweetAlerts.error(
          "ไม่สามารถดึงข้อมูลชื่อระบบได้",
          err.response.data.desc
        );
      });
  }, []);

  const columns = [
    {
      title: "careerTypeId",
      field: "careerTypeId",
      hidden: true
    },
    {
      title: "อาชีพ",
      field: "detail",
      cellStyle: {
        minWidth: "300px"
      }
    },
    {
      title: "ระบบ",
      field: "systemTypeId",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      },

      render: rowData => {
        console.log("systemList>>", systemList);
        const v = systemList.find(e => e.id === parseInt(rowData.systemTypeId));

        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        return (
          <ListboxSystem
            defaultValue={{ id: props.rowData.systemTypeId }}
            data={systemList}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.onChange(e.id);
              } else {
                props.onChange(0);
              }
            }}
          />
        );
      }
    }
  ];

  const [data, setData] = useState([]);
  useEffect(() => {
    getCareer()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.response.data.desc);
      });
  }, []);

  const optionsTable = { pageSize: 10 };

  return (
    <MaterialTable
      title={"อาชีพ"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            addCareer(newData.detail, newData.systemTypeId)
              .then(result => {
                //console.log("result>>",result);
                newData.careerTypeId = result.data.data.careerId;
                newData.systemTypeId =
                  newData.systemTypeId === undefined ? 0 : newData.systemTypeId;
                setData([...data, newData]);
                sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
              })
              .catch(err => {
                sweetAlerts.error(
                  "ไม่สามารถเพิ่มข้อมูลได้",
                  err.response.data.desc
                );
              });

            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;

            editCareer(oldData.careerTypeId, newData.detail)
              .then(result => {
                sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                setData([...dataUpdate]);
              })
              .catch(err => {
                sweetAlerts.error(
                  "ไม่สามารถแก้ไขข้อมูลได้",
                  err.response.data.desc
                );
              });
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);

            deleteCareer(oldData.careerTypeId)
              .then(result => {
                sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                setData([...dataDelete]);
              })
              .catch(err => {
                sweetAlerts.error(
                  "ไม่สามารถลบข้อมูลได้",
                  err.response.data.desc
                );
              });
            resolve();
          })
      }}
    />
  );
};
export default CareerList;
