const ConvertDate = props => {
  if (props) {
    const new_date = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric"
    };
    return new Date(props).toLocaleDateString("th-TH", new_date);
  } else return null;
};
export default ConvertDate;
