import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
//-------------ประเภทหน่วยงาน-----------------//
export function saveOnlineReport(fileName, reportUrl) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/file/online-reports/save/reports?fileName=${fileName}&reportUrl=${reportUrl}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function sendOnlineReport(provinceId, reportType, fileUUID) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/report/online/send`;
  const body = {
    provinceId: provinceId,
    reportType: reportType,
    fileUUID: fileUUID,
    fileType: "pdf"
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getOnlineReport(query) {
  const isAuth = cookies.get("userInfo");

  const url = `${END_POINT_URL}/report/online${query}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
