import React, { useEffect, useState } from "react";
import {
  addNumber,
  deleteNumber,
  editNumber,
  getNumber
} from "contexts/API/VoteCard/Setting";

import MaterialTable from "components/Table/MaterialTable";
import NumberFormat from "react-number-format";
import { useSweetAlert } from "components/Alert/SweetAlert";

const Number = props => {
  const { sweetAlerts } = useSweetAlert();
  const [columns] = useState([
    {
      title: "cardNumberId",
      field: "cardNumberId",
      hidden: true
    },
    {
      title: "เลขเริ่มต้น",
      field: "startNumber",
      cellStyle: {
        minWidth: "300px",
        textAlign: "center"
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandsGroupStyle="thousand"
            style={{
              borderStyle: "none none none none",

              textAlign: "center"
            }}
            value={rowData.startNumber}
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandsGroupStyle="thousand"
            style={{
              borderStyle: "none none dotted none",

              textAlign: "center"
            }}
            value={props.rowData.startNumber}
            onValueChange={values => {
              const { value } = values;

              props.rowData.startNumber = parseInt(value);
            }}
          />
        );
      }
    },
    {
      title: "เลขสิ้นสุด",
      field: "endNumber",
      cellStyle: {
        minWidth: "300px",
        textAlign: "center"
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandsGroupStyle="thousand"
            style={{
              borderStyle: "none none none none",

              textAlign: "center"
            }}
            value={rowData.endNumber}
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandsGroupStyle="thousand"
            style={{
              borderStyle: "none none dotted none",

              textAlign: "center"
            }}
            value={props.rowData.endNumber}
            onValueChange={values => {
              const { value } = values;

              props.rowData.endNumber = parseInt(value);
            }}
          />
        );
      }
    },
    {
      title: "เริ่มต้น - สิ้นสุด",
      field: "numbertext",
      editable: "never",
      cellStyle: {
        minWidth: "300px",
        textAlign: "center"
      }
    }
  ]);

  const [data, setData] = useState([]);
  useEffect(() => {
    getNumber()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const optionsTable = { pageSize: 10 };
  return (
    <MaterialTable
      title={"ชนิดหมายเลข"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            console.log(newData);
            addNumber(newData.startNumber, newData.endNumber)
              .then(result => {
                //console.log("result>>",result);
                newData.numbertext =
                  newData.startNumber + "-" + newData.endNumber;
                newData.cardNumberId = result.data.data.cardNumberId;
                setData([...data, newData]);
                sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
              });

            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            newData.numbertext = newData.startNumber + "-" + newData.endNumber;
            dataUpdate[index] = newData;

            editNumber(
              oldData.cardNumberId,
              newData.startNumber,
              newData.endNumber
            )
              .then(result => {
                sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");

                setData([...dataUpdate]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
              });
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);

            deleteNumber(oldData.cardNumberId)
              .then(result => {
                sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                setData([...dataDelete]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
              });
            resolve();
          })
      }}
    />
  );
};
export default Number;
