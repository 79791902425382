import React, { useState } from "react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ModalFormReport from "./ModalFormReport";
import ModalReport from "./ModalReport";
import { REPORTING_SERVICE } from "config/env.conf";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
    //maxWidth: 360,
    //backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const RptSummaryWtd = props => {
  const classes = useStyles();
  const [showModalReport, setshowModalReport] = useState(false);
  const openModalReport = () => setshowModalReport(true);
  const closeModalReport = () => setshowModalReport(false);

  const [showModalReport1, setshowModalReport1] = useState(false);
  const openModalReport1 = () => setshowModalReport1(true);
  const closeModalReport1 = () => setshowModalReport1(false);
  const [child, setChild] = useState("");
  const [title, setTitle] = useState("");
  const [index, setIndex] = useState("");
  // TODO: Link Report

  return (
    <form>
      <ModalReport
        closeModalReport={closeModalReport}
        showModalReport={showModalReport}
        linkReport={child}
        titleReport={title}
        indexReport={index}
      ></ModalReport>

      <ModalFormReport
        closeModalReport1={closeModalReport1}
        showModalReport1={showModalReport1}
        linkReport={child}
        titleReport={title}
        indexReport={index}
      ></ModalFormReport>
      <Card>
        <CardBody>
          <GridContainer spacing={2}>
            <GridItem xs={12} md={6}>
              <h4 className={classes.cardTitle}>
                รายงานระบบทะเบียนผู้ถูกเพิกถอนสิทธิเลือกตั้ง
              </h4>
            </GridItem>
          </GridContainer>
          <GridContainer spacing={2}>
            <GridItem xs={12} md={12}>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
              >
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="1. ทะเบียนเพิกถอนสิทธิเลือกตั้ง จำแนกตามประเภทการเลือกตั้ง (ส.ส.1/11)"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt1&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "1. ทะเบียนเพิกถอนสิทธิเลือกตั้ง จำแนกตามประเภทการเลือกตั้ง (ส.ส.1/11)"
                      );
                      setIndex("1_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="2. ประกาศคณะกรรมการการเลือกตั้งประจำเขตเลือกตั้ง เรื่อง การเพิกถอนสิทธิเลือกตั้ง"
                    onClick={() => {
                      openModalReport1();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt2_Grouping&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "2. ประกาศคณะกรรมการการเลือกตั้งประจำเขตเลือกตั้ง เรื่อง การเพิกถอนสิทธิเลือกตั้ง"
                      );
                      setIndex("2_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="3. ทะเบียนเพิกถอนสิทธิเลือกตั้ง จำแนกตามจังหวัด"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt3&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "3. ทะเบียนเพิกถอนสิทธิเลือกตั้ง จำแนกตามจังหวัด"
                      );
                      setIndex("3_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="4. รายชื่อผู้ที่ถึงกำหนดพ้นจากการถูกเพิกถอนสิทธิเลือกตั้ง"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt4&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "4. รายชื่อผู้ที่ถึงกำหนดพ้นจากการถูกเพิกถอนสิทธิเลือกตั้ง"
                      );
                      setIndex("4_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="5. สถิติจำนวนผู้ถูกเพิกถอนสิทธิเลือกตั้ง จำแนกตามประเภทการเลือกตั้ง"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt5&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "5. สถิติจำนวนผู้ถูกเพิกถอนสิทธิเลือกตั้ง จำแนกตามประเภทการเลือกตั้ง"
                      );
                      setIndex("5_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="6. สถิติจำนวนผู้ถูกเพิกถอนสิทธิเลือกตั้ง จำแนกตามประเภทความผิด"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt6&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "6. สถิติจำนวนผู้ถูกเพิกถอนสิทธิเลือกตั้ง จำแนกตามประเภทความผิด"
                      );
                      setIndex("6_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="7. สถิติจำนวนผู้ถูกเพิกถอนสิทธิเลือกตั้ง จำแนกตามระยะเวลาที่ถูกเพิกถอนสิทธิเลือกตั้ง"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt7&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "7. สถิติจำนวนผู้ถูกเพิกถอนสิทธิเลือกตั้ง จำแนกตามระยะเวลาที่ถูกเพิกถอนสิทธิเลือกตั้ง"
                      );
                      setIndex("7_1");
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  href={
                    REPORTING_SERVICE +
                    "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt8&rs:Command=Render&rc:Parameters=Collapsed"
                  }
                  target="_blank"
                >
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText primary="8. สถิติจำนวนผู้ถูกเพิกถอนสิทธิเลือกตั้ง จำแนกรายจังหวัด" />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="9. ทะเบียนผู้อยู่ระหว่างถูกเพิกถอนสิทธิเลือกตั้ง ไม่ว่าคดีถึงที่สุดหรือไม่"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt9&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "9. ทะเบียนผู้อยู่ระหว่างถูกเพิกถอนสิทธิเลือกตั้ง ไม่ว่าคดีถึงที่สุดหรือไม่"
                      );
                      setIndex("9_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="10. ทะเบียนเพิกถอนสิทธิเลือกตั้ง จำแนกตามวันที่บันทึก"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt10&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "10. ทะเบียนเพิกถอนสิทธิเลือกตั้ง จำแนกตามวันที่บันทึก"
                      );
                      setIndex("10_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="11. รายชื่อผู้ถูกเพิกถอนสิทธิเลือกตั้งที่คดีอยู่ระหว่างอุทธรณ์"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE + "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt11&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "11. รายชื่อผู้ถูกเพิกถอนสิทธิเลือกตั้งที่คดีอยู่ระหว่างอุทธรณ์"
                      );
                      setIndex("11_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="12. รายชื่อผู้ถูกเพิกถอนสิทธิเลือกตั้งที่คดีอยู่ระหว่างฎีกา"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt12&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "12. รายชื่อผู้ถูกเพิกถอนสิทธิเลือกตั้งที่คดีอยู่ระหว่างฎีกา"
                      );
                      setIndex("12_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="13. รายชื่อผู้ถูกเพิกถอนสิทธิที่ย้ายเข้า - ย้ายออก"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt13&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "13. รายชื่อผู้ถูกเพิกถอนสิทธิที่ย้ายเข้า - ย้ายออก"
                      );
                      setIndex("13_1");
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  href={
                    REPORTING_SERVICE +
                    "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt14&rs:Command=Render&rc:Parameters=Collapsed"
                  }
                  target="_blank"
                >
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText primary="14. สถิติผู้ถูกเพิกถอนสิทธิเลือกตั้ง จำแนกตามสถานะการถูกเพิกถอนสิทธิเลือกตั้ง เรียงตามจังหวัด" />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  href={
                    REPORTING_SERVICE +
                    "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt15&rs:Command=Render&rc:Parameters=Collapsed"
                  }
                  target="_blank"
                >
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText primary="15. รายงานผู้ถูกเพิกถอนสิทธิเลือกตั้ง ที่มีคำพิพากษาศาลมากกว่าหนึ่งคำพิพากษา" />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="16. รายชื่อผู้ถูกเพิกถอนสิทธิเลือกตั้งที่ ไม่ถูกเพิกถอนสิทธิ ยกคำร้อง หรือเสียชีวิต"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt16&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "16. รายชื่อผู้ถูกเพิกถอนสิทธิเลือกตั้งที่ ไม่ถูกเพิกถอนสิทธิ ยกคำร้อง หรือเสียชีวิต"
                      );
                      setIndex("16_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="17. ทะเบียนผู้อยู่ระหว่างถูกเพิกถอนสิทธิเลือกตั้งไม่ว่าคดีนั้นจะถึงที่สุดแล้วหรือไม่ (สำหรับการเลือกตั้งทั่วไป และการเลือกตั้งท้องถิ่น)"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt17&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "17. ทะเบียนผู้อยู่ระหว่างถูกเพิกถอนสิทธิเลือกตั้งไม่ว่าคดีนั้นจะถึงที่สุดแล้วหรือไม่ (สำหรับการเลือกตั้งทั่วไป และการเลือกตั้งท้องถิ่น)"
                      );
                      setIndex("17_1");
                    }}
                  />
                </ListItem>
                {/* <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="18. ทะเบียนรายชื่อผู้เคยถูกเพิกถอนสิทธิเลือกตั้งและยังไม่พ้นห้าปีนับแต่วันที่พ้นจากการถูกเพิกถอนสิทธิเลือกตั้ง (มาตรา 50(21))"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt18&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "18. ทะเบียนรายชื่อผู้เคยถูกเพิกถอนสิทธิเลือกตั้งและยังไม่พ้นห้าปีนับแต่วันที่พ้นจากการถูกเพิกถอนสิทธิเลือกตั้ง (มาตรา  50(21))"
                      );
                      setIndex("18_1");
                    }}
                  />
                </ListItem> */}
                {/* <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="19. ตรวจสอบรายชื่อผู้เคยถูกเพิกถอนสิทธิเลือกตั้งและยังไม่พ้นห้าปีนับแต่วันที่พ้นจากการถูกเพิกถอนสิทธิเลือกตั้ง (มาตรา ๕๐(๒๑))"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE+"/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt19&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "19. ตรวจสอบรายชื่อผู้เคยถูกเพิกถอนสิทธิเลือกตั้งและยังไม่พ้นห้าปีนับแต่วันที่พ้นจากการถูกเพิกถอนสิทธิเลือกตั้ง (มาตรา ๕๐(๒๑))"
                      );
                      setIndex("19_1");
                    }}
                  />
                </ListItem> */}

              </List>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </form>
  );
};

export default RptSummaryWtd;
