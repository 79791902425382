import React, { useEffect, useState } from "react";
import {
  addTitle,
  deleteTitle,
  editTitle,
  getSystem,
  getTitle
} from "contexts/API/CommonSetting/MasterData";

import ListboxGender from "components/Common/ListboxGender";
import ListboxSystem from "components/Common/ListboxSystem";
import MaterialTable from "components/Table/MaterialTable";
import { useSweetAlert } from "components/Alert/SweetAlert";

const Title = props => {
  const { sweetAlerts } = useSweetAlert();
  const [systemList, setSystemList] = useState([]);
  useEffect(() => {
    getSystem()
      .then(r => {
        let sysMapper = r.data.data.map(v => {
          return { id: v.systemTypeId, value: v.detail };
        });

        setSystemList(sysMapper);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  let genderList = [
    { id: 0, value: "เลือกเพศ" },
    { id: 1, value: "ชาย" },
    { id: 2, value: "หญิง" },
    { id: 3, value: "ไม่ระบุ" }
  ];

  const columns = [
    {
      title: "titleId",
      field: "titleId",
      hidden: true
    },
    {
      title: "ชื่อย่อ",
      field: "titleShortName",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      }
    },
    {
      title: "ชื่อเต็ม",
      field: "titleLongName",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      }
    },
    {
      title: "เพศ",
      field: "gender",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      },
      render: rowData => {
        const v = genderList.find(e => e.id === parseInt(rowData.gender));

        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        return (
          <ListboxGender
            defaultValue={{ id: props.rowData.gender }}
            data={genderList}
            onChange={(i, e, v) => {
              if (e !== null) {
                console.log(e);
                props.onChange(e.id);
              } else {
                props.onChange(0);
              }
            }}
          />
        );
      }
    },
    {
      title: "ระบบ",
      field: "system",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      },

      render: rowData => {
        const v = systemList.find(e => e.id === parseInt(rowData.system));

        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        return (
          <ListboxSystem
            defaultValue={{ id: props.rowData.system }}
            data={systemList}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.onChange(e.id);
              } else {
                props.onChange(0);
              }
            }}
          />
        );
      }
    }
  ];

  const [data, setData] = useState([]);
  useEffect(() => {
    getTitle()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const optionsTable = { pageSize: 10 };
  return (
    <MaterialTable
      title={"คำนำหน้าชื่อ"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            addTitle(
              newData.titleShortName,
              newData.titleLongName,
              newData.gender,
              newData.system
            )
              .then(result => {
                newData.gender =
                  newData.gender === undefined ? 3 : newData.gender;
                newData.system =
                  newData.system === undefined ? 0 : newData.system;
                newData.titleId = result.data.data.titleId;
                setData([...data, newData]);
                sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
              });

            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;

            editTitle(
              oldData.titleId,
              newData.titleShortName,
              newData.titleLongName,
              newData.gender,
              newData.system
            )
              .then(result => {
                sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                setData([...dataUpdate]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
              });
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);

            deleteTitle(oldData.titleId)
              .then(result => {
                sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                setData([...dataDelete]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
              });
            resolve();
          })
      }}
    />
  );
};
export default Title;
