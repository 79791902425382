import React, { useContext, useEffect, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import ConvertDate from "utils/ConvertDate";
import DatepickerVotedate from "components/Common/Datepicker";
import LoadingData from "components/Common/LoadingData";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxProvince from "components/Common/ListboxProvince";
import MustNotNull from "utils/Validators/MustNotNull";
import TableComponent from "components/Table/MaterialTable";
import TextField from "@material-ui/core/TextField";
import { VoteDateContext } from "contexts/VoteDate";
import { deleteVoteCardPurchase } from "contexts/API/VoteCard/Purchase";
/* API */
import { getSearchLists } from "contexts/API/VoteCard/Allocate";
import { makeStyles } from "@material-ui/core/styles";
import style from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles(style);

const CardAllocate = ({ pageProps, setPageProps }) => {
  const [, setClickSearch] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  const [loading, setLoading] = useState(false);
  const optionsTable = { exportButton: false, paging: true };
  /* const tableIcons = {
    Detail: forwardRef((props, ref) => (
      <FontAwesomeIcon icon={faClipboard} style={{ fontSize: 20 }} />
      //   <i className="fas fa-clipboard-list" style={{ fontSize: 20 }}></i>
    )),
    Allocate: forwardRef((props, ref) => (
      <FontAwesomeIcon icon={faDollyFlatbed} style={{ fontSize: 20 }} />
    )),
    Deleted: forwardRef((props, ref) => (
      <FontAwesomeIcon icon={faTrash} style={{ fontSize: 20 }} />
    )),
    Edit: forwardRef((props, ref) => (
      <FontAwesomeIcon icon={faEdit} style={{ fontSize: 20 }} />
    ))
  }; */
  const {
    provinceSelected,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setProvinceSelected,
    onProvinceSelected,
    voteCardPurchaseDocumentNo,
    setVoteCardPurchaseDocumentNo
  } = useContext(VoteDateContext);

  //const [voteCardPurchaseDocumentNo, setVoteCardPurchaseDocumentNo] = useState(null);
  /*   const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
   */
  const classes = useStyles();
  const [columns] = useState([
    {
      title: "จังหวัด",
      field: "ProvinceName",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "เลขที่หนังสืออ้างอิง (สั่งซื้อบัตรเลือกตั้ง)",
      field: "DocumentNo",
      editable: "never",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "วันที่สั่งซื้อ",
      field: "PurchaseDateTime",
      editable: "never",
      cellStyle: {
        minWidth: "50px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.PurchaseDateTime);
      }
    },
    {
      title: "สถานะการจัดสรรบัตร",
      field: "VoteCardAllocateStatusName",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "สถานะการส่งข้อมูลให้โรงพิมพ์",
      field: "ReceivePringHouseStatusName",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "สถานะรายงานการรับบัตรเลือกตั้ง",
      field: "VoteCardReceiveLocal",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    /* {
      title: "วิธีการรับบัตรเลือกตั้ง",
      field: "ReceiveName",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "วันที่ส่ง/รับบัตร",
      field: "",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.ReceiveDateTime);
      }
    }, */
    {
      title: "",
      field: "",
      cellStyle: {
        minWidth: "230px"
      },
      render: rowData => {
        return (
          <>
            <Button
              size="sm"
              color="warning"
              onClick={() => {
                const dataEdit = [...data];
                const index = rowData.tableData.id;
                dataEdit.splice(index, 1);

                editAlloHandler(rowData);
              }}
            >
              จัดสรรบัตร
            </Button>
            <Button
              size="sm"
              color="danger"
              onClick={() => {
                const dataDelete = [...data];
                const index = rowData.tableData.id;
                dataDelete.splice(index, 1);

                sweetAlerts.error(
                  "ยืนการลบข้อมูล",
                  "คุณต้องการลบข้อมูลใช่หรือไม่",
                  () => {
                    deleteVoteCardPurchase({
                      voteCardPurchaseID: rowData.PurchaseID
                    })
                      .then(result => {
                        setData([...dataDelete]);
                        sweetAlerts.success(`ลบข้อมูลสำเร็จแล้ว`);
                      })
                      .catch(err => {
                        if (err.response.data.code !== 20000) {
                          sweetAlerts.danger(`ไม่สามารถลบข้อมูลได้`);
                        }
                      });
                  },
                  true
                );
              }}
            >
              ลบ
            </Button>
          </>
        );
      }
    }
  ]);

  const [data, setData] = useState([]);

  const onClickCancel = e => {
    setStartDate(null);
    setEndDate(null);
    setProvinceSelected(null);
    setVoteCardPurchaseDocumentNo(null);
    setData([]);
  };

  const onClickSearch = e => {
    setClickSearch(false);
    setLoading(true);
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }
    if (startDate !== null && endDate === null) {
      sweetAlerts.warning("กรุณาเลือกวันสั่งซื้อสิ้นสุด");
    }

    const optQueryUrl = () => {
      let endPoint = "?";

      if (provinceSelected !== null) {
        endPoint += `ProvinceID=${provinceSelected.id}`;
      }

      /* if (amphurSelected !== null) {
        //
        endPoint += `AmphurID=${amphurSelected.id}&`;
      }
      if (tambolSelected !== null) {
        //
        endPoint += `TambolID=${tambolSelected.id}&`;
      }
      if (optSelected !== null) {
        //
        endPoint += `OptID=${optSelected.id}&`;
      } */
      if (startDate !== null) {
        endPoint += `&PurchaseStartDate=${startDate.format().split("T")[0]}`;
      }
      if (endDate !== null) {
        endPoint += `&PurchaseEndDate=${endDate.format().split("T")[0]}`;
      }

      if (voteCardPurchaseDocumentNo !== null && voteCardPurchaseDocumentNo != undefined) {
        endPoint += `&DocumentNo=${voteCardPurchaseDocumentNo}`;
      }

      return endPoint;
    };
    setLoading(true);
    // getSearchLists(optQueryUrl(), optExportUrl())
    getSearchLists(optQueryUrl())

      .then(result => {
        setData(result.data.data);

        setLoading(false);
        setClickSearch(true);
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", err.toString());
      });
  };
  // const optExportUrl = () => { };
  /*   const addAlloHandler = rowData => {
      setPageProps({ active: "AddAllocate", props: rowData });
    };
    const detailAlloHandler = rowData => {
      setPageProps({ active: "DetailAllocate", props: rowData });
    }; */

  const editAlloHandler = rowData => {
    setPageProps({ active: "EditAllocate", props: rowData });
  };


  useEffect(() => {
    if (pageProps.action === "cancel") {
      onClickSearch();
    }
  }, [pageProps]);

  return (
    <>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4>ค้นหารายการที่ต้องจัดสรรบัตรเลือกตั้ง</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {/*
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CheckboxOpttype
                      optTypeSelected={optTypeSelected}
                      onOptTypeSelected={onOptTypeSelected}
                      required={true}
                    ></CheckboxOpttype>
                  </GridItem>
                </GridContainer>
                */}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <ListboxProvince
                      value={provinceSelected}
                      onChange={onProvinceSelected}
                      required={true}
                      validators={[MustNotNull]}
                    ></ListboxProvince>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DatepickerVotedate
                      id="begin"
                      label="วันที่สั่งซื้อเริ่มต้น"
                      value={startDate}
                      onChange={setStartDate}
                    ></DatepickerVotedate>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DatepickerVotedate
                      id="endDate"
                      label="วันที่สั่งซื้อสิ้นสุด"
                      value={endDate}
                      onChange={setEndDate}
                    ></DatepickerVotedate>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      id="voteCardPurchaseDocumentNo"
                      label="เลขที่หนังสืออ้างอิง (สั่งซื้อบัตรเลือกตั้ง)"
                      required
                      fullWidth
                      style={{ marginTop: "15px" }}
                      value={voteCardPurchaseDocumentNo}
                      defaultValue={voteCardPurchaseDocumentNo}
                      onChange={e => {
                        setVoteCardPurchaseDocumentNo(e.target.value);
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <br /> <br />
                <GridContainer
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <GridItem xs={6} sm={6} md={6}></GridItem>
                  <GridItem>
                    <Button onClick={onClickCancel}>ยกเลิก</Button>
                    <Button
                      type="button"
                      color="info"
                      className={classes.buttoncustom}
                      onClick={onClickSearch}
                    >
                      ค้นหา
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title="รายการที่ต้องจัดสรรบัตรเลือกตั้ง"
          columns={columns}
          data={data}
          localization={{}}
          defaultExpanded={true}
          options={optionsTable}
        />
      )}
    </>
  );
};
export default CardAllocate;
