import MaterialTable from "components/Table/MaterialTable";
import {
  getsuspendOrgType,
  editsuspendOrgType,
  deletesuspendOrgType,
  addsuspendOrgType
} from "contexts/API/SUSPEND/common";
import { useSweetAlert } from "components/Alert/SweetAlert";
import React, { useState, useEffect } from "react";
const OrgType = props => {
  const { sweetAlerts } = useSweetAlert();
  const [columns] = useState([
    {
      title: "suspendOrgTypeId",
      field: "suspendOrgTypeId",
      hidden: true
    },
    {
      title: "ประเภทหน่วยงาน",
      field: "detail",
      cellStyle: {
        minWidth: "900px"
      }
    }
  ]);

  const [data, setData] = useState([]);
  useEffect(() => {
    getsuspendOrgType()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const optionsTable = { pageSize: 10 };
  return (
    <MaterialTable
      title={"จัดการประเภทหน่วยงานที่มีสิทธิระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
              addsuspendOrgType(newData.detail)
                .then(result => {
                  //console.log("result>>",result);
                  newData.suspendOrgTypeId = result.data.data.suspendOrgTypeId;
                  setData([...data, newData]);
                  sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
                  
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
                });

              resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;

              editsuspendOrgType(oldData.suspendOrgTypeId, newData.detail)
                .then(result => {
                  
                  sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                  setData([...dataUpdate]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });
              resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);

              deletesuspendOrgType(oldData.suspendOrgTypeId)
                .then(result => {
                  sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                  setData([...dataDelete]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
                });
              resolve();
          })
      }}
    />
  );
};
export default OrgType;
