import React, { useState } from "react";

import { FileUpload } from "components/Common/FileUpload";
import ImageIcon from "@material-ui/icons/Image";
import NumberFormat from "react-number-format";
import Paper from "@material-ui/core/Paper";
import TableComponent from "components/Table/MaterialTable";
import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

const TablePersonDetail = props => {
  const optionsTable = {
    exportButton: false,
    paging: false,
    pageSize: 5,
    search: false
  };
  const [fileUrl, setFileUrl] = useState();
  const { sweetAlerts } = useSweetAlert();

  const [columns] = useState([
    {
      title: "เลขประจำตัวประชาชน",
      field: "idCard",
      cellStyle: {
        minWidth: "100px"
      },
      render: rowData => (
        <NumberFormat
          style={{
            width: "100%",
            margin: "0 0 17px 0",
            paddingTop: "27px"
          }}
          value={rowData.idCard}
          placeholder="เลขประจำตัวประชาชน"
          customInput={TextField}
          format="# #### ##### ## #"
          mask="X"
          fullWidth
          onValueChange={v => {
            if (
              v !== null &&
              v !== undefined &&
              v.value !== null &&
              v.value !== undefined &&
              v.value.length === 13
            ) {
              const err = ThaiCitizenID(v.value);

              if (err[0]) {
                sweetAlerts.danger("เลขประจำตัวประชาชนผิด");
                rowData.idCard = "";
              } else {
                rowData.idCard = v.value;
              }
            } else {
              rowData.idCard = "";
            }
          }}
        />
      )
    },
    {
      title: "ชื่อผู้มารับบัตร",
      field: "firstName",
      cellStyle: {
        minWidth: "100px"
      },
      render: rowData => (
        <TextField
          id="standard-basic"
          label="ชื่อผู้มารับบัตร"
          fullWidth
          defaultValue={rowData.firstName}
          onChange={e => {
            rowData.firstName = e.target.value;
          }}
        />
      )
    },
    {
      title: "สกุลผู้มารับบัตร",
      field: "lastName",
      cellStyle: {
        minWidth: "100px"
      },
      render: rowData => (
        <TextField
          id="standard-basic"
          label="สกุลผู้มารับบัตร"
          fullWidth
          defaultValue={rowData.lastName}
          onChange={e => {
            rowData.lastName = e.target.value;
          }}
        />
      )
    },
    {
      title: "แนบรูป",
      field: "fileUUID",
      cellStyle: {
        minWidth: "400px"
      },
      render: rowData => (
        <FileUpload
          id="file-upload-2"
          label="แนบรูป"
          remove
          download
          fileName={rowData.personFileName}
          url={fileUrl}
          onFileRemove={() => {
            setFileUrl("");
          }}
          onFileHandler={(e, f, progressCb) => {
            uploadFile("votecard", f, progressCb)
              .then(r => {
                const data = r.data.data;

                if (data.fileId) {
                  setFileUrl(getFileDownloadUrl("votecard", data.fileId));
                  rowData.fileUUID = data.fileId;
                  rowData.personFileName = data.fileName;
                  //  setFileName(data.fileName);
                }

                sweetAlerts.success("แนบไฟล์เรียบร้อย");
              })
              .catch(err => {
                console.log(err);
              });
          }}
          fileType="image/jpeg"
          btnName={
            <>
              <ImageIcon />
              แนบรูปภาพ
            </>
          }
          btnProps={{
            color: "info"
          }}
        />
      )
    }
  ]);

  return (
    <div>
      <TableComponent
        title={"รายละเอียดผู้มารับบัตร"}
        columns={columns}
        data={props.data}
        options={optionsTable}
        enableInitColumns={false}
        components={{
          Container: props => <Paper {...props} elevation={0} />
        }}
      ></TableComponent>
    </div>
  );
};
export default TablePersonDetail;
