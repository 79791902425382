import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getSearchLists(queryStr) {
  console.log(queryStr);
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchallocatetobranch${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addPersonreceivelist(
  voteCardPurchaseDetailID,
  receiveTypeCode,
  receiveDate,
  fileUUID,
  dataTable
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/addPersonReceive/purchaseDetailId/${voteCardPurchaseDetailID}`;
  const body = {
    receiveTypeCode: receiveTypeCode,
    receiveDate: receiveDate,
    assignFileUUID: fileUUID,
    voteCardPersonReceive: dataTable
  };

  console.log(body);

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

/*
export function updateAllocatePrintingList(purchaseDetailData, personReceiveData) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/updatepurchasedetailstatuslist`;
  const body = {
    purchaseDetailData: purchaseDetailData,
    personReceiveData: personReceiveData
  };
    
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
*/

export function deleteItem(queryStr) {
  console.log(queryStr);
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/deleteallocatetobranch${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
