import { InspectorContextProvider } from "contexts/Inspector";
import React from "react";
// core components./RptSummaryInspect
import RptSummaryInspect from "./RptSummaryInspect";

// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

//const styles = {};
//const useStyles = makeStyles(styles);

const ReportIndex = props => {
  return (
    <div>
      <InspectorContextProvider>
        <RptSummaryInspect />
      </InspectorContextProvider>
    </div>
  );
};
export default ReportIndex;
