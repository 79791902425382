import React, { useContext, useEffect, useState } from "react";
import {
  editOutPresident,
  getReasonMemPosition,
  getReasonPresPosition
} from "contexts/API/OPT/OutPosition";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Checkbox from "@material-ui/core/Checkbox";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import ConvertDate from "utils/ConvertDate";
import Cookies from "universal-cookie";
import CustomInput from "components/CustomInput/CustomInput.js";
import DateVoteDate from "components/Common/Datepicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import HandelError from "utils/HandleError";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxOutPosition from "components/Common/ListboxOutPosition";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxVotedate from "components/Common/ListboxVotedate";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import TableComponent from "components/Table/MaterialTable";
import { VoteDateContext } from "contexts/VoteDate";
import axios from "axios";
import conf from "config/env.conf";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const cookies = new Cookies();
const OptoutPosition = props => {
  const { sweetAlerts } = useSweetAlert();
  const {
    onOptTypeSelected,
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,

    tambolSelected,
    optTypeSelected,
    onOptSelected,
    optSelected,

    votedateSelected,
    onVotedateSelected,
    optVoteTypeSelected,
    onOptVoteTypeSelected,
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);

  const useStyles = makeStyles({
    buttoncustom: {
      fontSize: 14,
      fontWeight: 500
      //color: props => props.color
    }
  });
  const [loading, setLoading] = useState(false);
  //const [_newData, _setNewData] = useState();
  const isAuth = cookies.get("userInfo");
  const [columns, setColumns] = useState([
    {
      title: "ตำแหน่ง",
      field: "positionAreaName",
    },

    {
      title: "optVoteId",
      field: "optVoteId",
      hidden: true
    },
    {
      title: "optAreaId",
      field: "optAreaId",
      hidden: true
    },
    {
      title: "หมายเลขผู้สมัคร",
      field: "applyNo",
      editable: "never",
      cellStyle: {
        minWidth: "50px"
      }
    },
    {
      title: "ชื่อ สกุล",
      field: "fullname",
      cellStyle: {
        minWidth: "150px"
      }
    },
    {
      title: "พ้นจากตำแหน่ง",
      field: "chkOutPosition",
      cellStyle: {
        minWidth: "200px",
        padding: "5px"
      }
    }
  ]);
  const [positionStatusMemList, setPositionStatusMemList] = useState([]);
  const [positionStatusPresList, setPositionStatusPresList] = useState([]);
  useEffect(() => {
    getReasonMemPosition()
      .then(r => {
        let positionMemList = r.data.data.map(v => {
          return { id: v.statusId, value: v.detail };
        });

        setPositionStatusMemList(positionMemList);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getReasonPresPosition()
      .then(r => {
        let positionPresList = r.data.data.map(v => {
          return { id: v.statusId, value: v.detail };
        });

        setPositionStatusPresList(positionPresList);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const ConvertPosition = (type, id) => {
    let _position = "";
    if (type === 1) {
      _position = positionStatusMemList.find(e => e.id === parseInt(id));
    }
    if (type === 2) {
      _position = positionStatusPresList.find(e => e.id === parseInt(id));
    }

    return _position;
  };
  const verifyData = newData => {
    if (!newData.outDatetime) {
      sweetAlerts.warning("กรุณาบันทึกข้อมูลให้ครบถ้วน");
      return false;
    }

    if (!newData.outPositionStatusId) {
      sweetAlerts.warning("กรุณาเลือกสาเหตุ");
      return false;
    }

    return true;
  };

  const [data, setData] = useState([]);
  const handleSubmit = e => {

    /* if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาระบุวันเลือกตั้ง");
    } */

    if (optSelected === null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
    }

    //if (optSelected !== null && votedateSelected !== null) {
    if (optSelected !== null) {
      setLoading(true);
      //const url = `${conf.END_POINT_URL}/opt/${optSelected.id}/voteDate/${votedateSelected.id}/outOfPosition`;
      const url = `${conf.END_POINT_URL}/opt/${optSelected.id}/outOfPosition`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${isAuth.token}`
          }
        })
        .then(res => {
          // setData(res.data.data);

          setData(
            res.data.data.map((value, idx, arrays) => {
              return {
                ...arrays[idx],
                positionTH: ConvertPosition(
                  value.optPositionTypeId,
                  value.outPositionStatusId
                ),
                areaNo: value.areaNo ? value.areaNo : `ผ.ถ.`
              };
            })
          );

          setLoading(false);

          const columnConfig = [
            /*  {
               title: "เขตที่",
               field: "areaNo",
               editable: "never",
               defaultGroupOrder: 0,
 
               cellStyle: {
                 backgroundColor: "#039be5",
                 color: "#FFF",
                 fontWeight: 500
               },
               headerStyle: {
                 fontWeight: 500
               }
             }, */

            {
              title: "ตำแหน่ง",
              field: "positionAreaName",
              editable: "never",
              defaultGroupOrder: 0,

              cellStyle: {
                backgroundColor: "#039be5",
                color: "#FFF",
                fontWeight: 500
              },
              headerStyle: {
                fontWeight: 500
              }
            },

            {
              title: "optVoteId",
              field: "optVoteId",
              hidden: true
            },
            {
              title: "optAreaId",
              field: "optAreaId",
              hidden: true
            },
            /*        {
                     title: "อำเภอ",
                     field: "amphurName",
                     editable: "never",
                     cellStyle: {
                       minWidth: "50px"
                     }
                   },*/

            {
              title: "หมายเลขผู้สมัคร",
              field: "applyNo",
              editable: "never",
              cellStyle: {
                minWidth: "10px",
                textAlign: "center"
              }
            },
            {
              title: "ชื่อ สกุล",
              field: "fullname",
              editable: "never",
              exportData: row => {
                return row.fullname;
              },
              cellStyle: {
                minWidth: "250px"
              }
            },
            // {
            //   title: "วันที่พ้น",
            //   field: "outDatetime",
            //   editable: "never",
            //   hidden: true,
            //   export: true,
            //   exportData: row => {
            //     return row.outDatetime;
            //   }
            // },

            {
              title: "พ้นจากตำแหน่ง",
              field: "chkOutPosition",
              cellStyle: {
                minWidth: "200px",
                padding: "5px"
              },
              exportData: row => {
                return row.chkOutPosition ? "พ้นจากตำแหน่ง" : "-";
              },
              render: rowData => {
                return (
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer
                      direction="column"
                      justify="space-between"
                      alignItems="center"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            inputProps={{
                              "aria-label": "disabled checked checkbox"
                            }}
                            checked={rowData.chkOutPosition === 1}
                          />
                        }
                        label={
                          <p style={{ color: "#616161", fontSize: "14px" }}>
                            พ้นจากตำแหน่ง
                          </p>
                        }
                      />

                      {rowData.optPositionTypeId === 2 ? (
                        <CustomInput
                          disabled={rowData.chkOutPosition === 0}
                          labelText="สาเหตุการพ้นจากตำแหน่ง"
                          // value={props.remarkMem}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value:
                              rowData.positionTH === undefined
                                ? ""
                                : rowData.positionTH.value
                            // disabled: true

                            //  inputRef: props.memRemarkRef
                          }}
                        />
                      ) : (
                        <CustomInput
                          disabled={rowData.chkOutPosition === 0}
                          labelText="สาเหตุการพ้นจากตำแหน่ง"
                          // value={props.remarkMem}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value:
                              rowData.positionTH === undefined
                                ? ""
                                : rowData.positionTH.value
                            // disabled: true

                            //  inputRef: props.memRemarkRef
                          }}
                        />
                      )}
                      <CustomInput
                        labelText="วันที่พ้นจากตำแหน่ง"
                        // value={props.remarkMem}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: ConvertDate(rowData.outDatetime) || ""
                          // disabled: true

                          //  inputRef: props.memRemarkRef
                        }}
                      />
                    </GridContainer>
                  </GridItem>
                );
              },
              editComponent: props => {
                const dateValue = props.rowData.outDatetime
                  ? props.rowData.outDatetime
                  : null;
                return (
                  <GridContainer
                    direction="row"
                    justify="center"
                    alignItems="center"
                    label="พ้นจากตำแหน่ง"
                  >
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={
                          //console.log("props.rowData.chkOutPosition >>", props.rowData.chkOutPosition),
                          <Checkbox
                            checked={props.rowData.chkOutPosition === 1}
                            onChange={e => {
                              e.target.checked === true
                                ? props.onChange(1)
                                : props.onChange(0);
                            }}

                          // onClick={event => {
                          //   if (event.target.checked) {
                          //     props.onChange(1);
                          //     console.log(props.rowData);
                          //     console.log(event.target.checked);
                          //   } else {
                          //     props.onChange(0);
                          //     // props.onRowDataChange({
                          //     //   ...props.rowData,
                          //     //   chkOutPosition: 0,
                          //     //   outDatetime: null,
                          //     //   outPositionStatusId: null
                          //     // });
                          //   }
                          // }}
                          />
                        }
                        label={
                          <p style={{ color: "#616161", fontSize: "14px" }}>
                            พ้นจากตำแหน่ง
                          </p>
                        }
                      />
                      {props.rowData.optPositionTypeId === 2 ? (
                        <ListboxOutPosition
                          // disabled={
                          //   // props.rowData.chkOutPosition === 1 &&
                          //   props.rowData.oldChkOutPosition === 1
                          // }
                          title="สาเหตุการพ้นจากตำแหน่ง"
                          defaultValue={{
                            id: props.rowData.chkOutPosition === 1 ? props.rowData.outPositionStatusId : 0
                          }}
                          data={positionStatusMemList}
                          onChange={(i, e, v) => {
                            if (v !== null) {
                              props.rowData.outPositionStatusId = v.id;
                            } else {
                              props.rowData.outPositionStatusId = null;
                            }
                          }}
                        />
                      ) : (
                        <ListboxOutPosition
                          // disabled={
                          //   //   props.rowData.chkOutPosition === 1 &&
                          //   props.rowData.oldChkOutPosition === 1
                          // }
                          title="สาเหตุการพ้นจากตำแหน่ง"
                          customProps={{
                            fontSize: "14px"
                          }}
                          defaultValue={{
                            //id: props.rowData.chkOutPosition === 1 ? props.rowData.outPositionStatusId : 0
                            id: props.rowData.outPositionStatusId
                          }}
                          data={positionStatusPresList}
                          onChange={(i, e, v) => {
                            if (v !== null) {
                              props.rowData.outPositionStatusId = v.id;
                            }
                          }}
                        />
                      )}

                      <DateVoteDate
                        //  disabled={props.rowData.oldChkOutPosition === 1}
                        label="วันที่พ้นจากตำแหน่ง"
                        value={dateValue}
                        onChange={date =>
                          props.onRowDataChange({
                            ...props.rowData,
                            outDatetime: date
                          })
                        }
                      />
                    </GridItem>
                  </GridContainer>
                );
              }
            },

            {
              title: "ไม่จัดเลือกตั้งกรณีไม่ถึง 180 วัน",
              field: "chkNotSetVoteDate",
              cellStyle: {
                minWidth: "100px"
              },
              exportData: row => {
                return row.optPositionTypeId ? "ไม่จัดเลือกตั้ง" : "-";
              },
              render: rowData => (
                <GridContainer
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  {rowData.optPositionTypeId === 2 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          inputProps={{
                            "aria-label": "disabled checked checkbox"
                          }}
                          checked={rowData.chkNotSetVoteDate === 1}
                        />
                      }
                      label={
                        <p style={{ color: "#616161", fontSize: "14px" }}>
                          ไม่จัดเลือกตั้งกรณีไม่ถึง 180 วัน
                        </p>
                      }
                    />
                  ) : (
                    <></>
                  )}
                </GridContainer>
              ),
              editComponent: props => {
                return (
                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <GridItem xs={12} sm={12} md={12}>
                      {props.rowData.optPositionTypeId === 2 ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={props.rowData.chkNotSetVoteDate === 1}
                              onChange={e => {
                                e.target.checked === true
                                  ? props.onChange(1)
                                  : props.onChange(0);
                                // props.onChange(e.target.checked);
                              }}
                            />
                          }
                          label={
                            <p style={{ color: "#616161", fontSize: "14px" }}>
                              ไม่จัดเลือกตั้งกรณีไม่ถึง 180 วัน
                            </p>
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </GridItem>
                  </GridContainer>
                );
              }
            }
          ];
          setColumns(columnConfig);
        })
        .catch(err => {
          HandelError(err);
          setLoading(false);

          sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
        });
    }
  };

  const optionsTable = { paging: false, defaultExpanded: true };

  const classes = useStyles();

  return (
    <>
      <Card>
        <CardHeader color="primary" text>
          <CardText color="primary">
            <h4 className={classes.cardTitle}>บันทึกพ้นจากตำแหน่ง</h4>
          </CardText>
        </CardHeader>

        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <br /> <br />
              <GridContainer
                justify="space-between"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={12} sm={12} md={12}>
                  <CheckboxOpttype
                    optTypeSelected={optTypeSelected}
                    onOptTypeSelected={onOptTypeSelected}
                    required={true}
                  ></CheckboxOpttype>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {/* <GridItem xs={12} sm={12} md={4}>
                  <ListboxOptVotetype
                    required={true}
                    value={optVoteTypeSelected}
                    onChange={onOptVoteTypeSelected}
                    validators={[MustNotNull]}
                  ></ListboxOptVotetype>
                </GridItem> */}
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxProvince
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                    required={true}
                    validators={[MustNotNull]}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxDistinct
                    value={amphurSelected}
                    provinceSelected={provinceSelected}
                    onChange={onAmphurSelected}
                  ></ListboxDistinct>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxOpts
                    value={optSelected}
                    getOptTypeSelectedLists={getOptTypeSelectedLists}
                    optTypeSelected={optTypeSelected}
                    provinceSelected={provinceSelected}
                    amphurSelected={amphurSelected}
                    tambolSelected={tambolSelected}
                    onChange={onOptSelected}
                    required={true}
                    validators={[MustNotNull]}
                  ></ListboxOpts>
                </GridItem>
              </GridContainer>
              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxOpts
                    value={optSelected}
                    getOptTypeSelectedLists={getOptTypeSelectedLists}
                    optTypeSelected={optTypeSelected}
                    provinceSelected={provinceSelected}
                    amphurSelected={amphurSelected}
                    tambolSelected={tambolSelected}
                    onChange={onOptSelected}
                    required={true}
                    validators={[MustNotNull]}
                  ></ListboxOpts>
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <ListboxVotedate
                    value={votedateSelected}
                    optSelected={optSelected}
                    optVoteTypeSelected={optVoteTypeSelected}
                    onChange={onVotedateSelected}
                    required={true}
                    validators={[MustNotNull]}
                  ></ListboxVotedate>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}></GridItem>
              </GridContainer> */}
              <br /> <br /> <br />
              <GridContainer justify="space-between" alignItems="center">
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button className={classes.buttoncustom}>ยกเลิก</Button>
                  <Button color="info" onClick={handleSubmit}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title={"รายชื่อผู้ที่ดำรงตำแหน่ง ณ ปัจจุบัน"}
          columns={columns}
          data={data}
          enableInitColumns={false}
          components={{}}
          editable={{
            /* isEditHidden: rowData => optVoteTypeSelected.id === null || optVoteTypeSelected.id === 1,
 */
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                //   console.log(newData.outPositionStatusId);
                if (!verifyData(newData)) {
                  reject();
                } else {

                  setTimeout(() => {
                    const dataUpdate = [...data];

                    newData.positionTH = ConvertPosition(
                      newData.optPositionTypeId,
                      newData.outPositionStatusId
                    );

                    const index = oldData.tableData.id;

                    dataUpdate[index] = newData;

                    editOutPresident(
                      oldData.msOptId,
                      oldData.optVoteId,
                      oldData.PKID,
                      newData.outDatetime,
                      newData.outPositionStatusId,
                      oldData.optPositionTypeId,
                      oldData.optAreaId,
                      newData.chkNotSetVoteDate === null
                        ? 0
                        : newData.chkNotSetVoteDate,
                      newData.oldChkOutPosition,
                      newData.chkOutPosition === null
                        ? 0
                        : newData.chkOutPosition
                    )
                      .then(result => {
                        //  newData.oldChkOutPosition = newData.chkOutPosition;

                        newData.oldChkOutPosition = newData.chkOutPosition;
                        if (newData.chkOutPosition === 0) {
                          newData.outDatetime = null
                          newData.positionTH = undefined
                          newData.chkNotSetVoteDate = 0
                        }
                        dataUpdate[index] = newData;
                        setData([...dataUpdate]);

                        sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");

                        //  setData([...dataUpdate]);
                      })
                      .catch(err => {
                        HandelError(err);
                        sweetAlerts.error(
                          "ไม่สามารถแก้ไขข้อมูลได้",
                          err.toString()
                        );
                      });

                    resolve();
                  }, 300);
                }
              })
          }}
          options={optionsTable}
        //  onRowUpdate={onRowUpdate}
        ></TableComponent>
      )}
    </>
  );
};

export default OptoutPosition;
