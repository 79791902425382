import React, { useContext, useEffect, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import CheckboxWtdStatus from "components/Common/CheckboxWtdStatus";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datepicker from "components/Common/Datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import ListboxWtdYear from "components/Common/ListboxWtdYear.js";
import LoadingData from "components/Common/LoadingData";
import NumberFormat from "react-number-format";
import { Paper } from "@material-ui/core";
import { REPORTING_SERVICE } from "config/env.conf";
import TableComponent from "components/Table/MaterialTable";
import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { WtdContext } from "contexts/Wtd";
import { checkWtdPerson } from "contexts/API/WTD/CheckWtd";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useSweetAlert } from "components/Alert/SweetAlert";

const CheckPerson = props => {
  const { sweetAlerts } = useSweetAlert();
  const [idValid, setIdValid] = useState(true);

  const [idCard, setIdCard] = useState(null);
  //const [year, setYear] = useState("");
  const [dateOfCheck, setDateOfCheck] = useState("");
  const [, setWtdStatusList] = useState("");
  const [data, setData] = useState([]);
  const [wtdLaw, setWtdLaw] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orgName, setOrgName] = useState(null);
  const [orgNameState, setOrgNameState] = useState(true);
  const [keyTempIdCard, setKeyTempIdCard] = useState(null);
  const [disableStatus, setDisableStatus] = useState(true);
  const [columns, setColumns] = useState([
    {
      title: "เลขประจำตัวประชาชน",
      field: "idCard",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      }
    },
    {
      title: "ผลการตรวจเพิกถอนสิทธิเลือกตั้ง",
      field: "wtdStatusDetail",
      cellStyle: {
        textAlign: "center"
      }
    }
  ]);

  const {
    yearSelected,
    onYearSelected,
    wtdCheckBoxSelected,
    onWtdCheckBoxSelected,
    getWtdCheckboxLists
  } = useContext(WtdContext);
  const onIDCardHandler = v => {
    setIdCard(v.value);
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      if (isError) {
        sweetAlerts.danger("เลขประจำตัวประชาชนผิด");
        //  setIdValid(!idValid);
      } else {
        setIdValid(!idValid);
      }
    } else {
      setIdValid(true);
    }
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (idCard !== null && orgNameState === false) {
      setDisableStatus(false);
    } else {
      setDisableStatus(true);
    }
  }, [idCard, orgNameState]);
  const handelSubmit = () => {
    let year = 0;

    if (!yearSelected) {
      year = 0;
    } else {
      year = yearSelected.id;
    }

    let status = [];

    setWtdStatusList(getWtdCheckboxLists());

    // console.log("getWtdCheckboxLists>>", getWtdCheckboxLists().length);

    // console.log(">>", wtdStatusList.length);

    if (getWtdCheckboxLists().length > 0) {
      status = getWtdCheckboxLists();
    }

    const today = new Date();
    today.setFullYear(today.getFullYear() - year);
    const checkDate = today.toISOString().split("T")[0]; //วันที่ตรวจสอบย้อนหลัง

    if (idCard === null) {
      sweetAlerts.warning("กรุณาระบุเลขประจำตัวประชาชน");
      return;
    }
    if (orgNameState === "" && orgName === null) {
      setOrgNameState(false);
    }

    if (orgName === null) {
      sweetAlerts.warning("กรุณาระบุหน่วยงาน");
      return;
    }
    if (checkDate === null) {
      sweetAlerts.warning("กรุณาเลือกระยะเวลาถูกเพิกถอน");
      return;
    } else {
      setLoading(true);
      setData([]);

      //   console.log("dateOfCheck>>", dateOfCheck);

      checkWtdPerson(
        checkDate,
        [idCard],
        null,
        null,
        status,
        wtdLaw,
        idCard.toString()
      )
        .then(result => {
          setKeyTempIdCard(result.data.data.keyTempIdCard);
          const dataUniqKey = {};
          result.data.data.result.forEach(v => {
            let postionStatus = "";
            let __level__ = 0;
            if (
              !v.wtdSdate ||
              !v.wtdEdate /* ถ้าไม่มี wtdSdate or wtdEdate ให้ถือว่ายังไม่ถูกเพิกถอน */
            ) {
              __level__ = 0;
              postionStatus = "--ไม่พบข้อมูล--";
            } else {
              __level__ = 1;
              postionStatus = `${ConvertDate(v.wtdSdate)} - ${ConvertDate(
                v.wtdEdate
              )} `;
              /*  จ.${v.provinceName}`; */
            }

            if (dataUniqKey[v.idCard] === undefined) {
              /* บันทึกสถานะ */
              dataUniqKey[v.idCard] = {
                idCard: v.idCard,
                result: [postionStatus],
                __level__: __level__
              };
            } else if (__level__ >= 1) {
              /* เพิ่มประวัติการดำรงตำแหน่ง */

              if (dataUniqKey[v.idCard].__level__ === 0) {
                dataUniqKey[v.idCard].result = [postionStatus];
                dataUniqKey[v.idCard].__level__ = __level__;
              } else {
                dataUniqKey[v.idCard].result = [
                  ...dataUniqKey[v.idCard].result,
                  postionStatus
                ];
                dataUniqKey[v.idCard].__level__ = __level__;
              }
            } else {
              //do nothing
            }
          });
          setData(Object.values(dataUniqKey));
          setLoading(false);
          setColumns([
            {
              title: "เลขประจำตัวประชาชน",
              field: "idCard",
              cellStyle: {
                minWidth: "150px"
              },
              render: rowData => {
                return (
                  <NumberFormat
                    value={rowData.idCard}
                    format="# #### ##### ## #"
                    style={{
                      width: "100%",
                      border: "0",
                      fontSize: "15px"
                    }}
                  />
                );
              }
            },
            {
              title: "ผลการตรวจเพิกถอนสิทธิเลือกตั้ง",
              field: "result",
              render: rawData => {
                return rawData.result.map((e, i) => {
                  return <div key={i}>{e}</div>;
                });
              },
              cellStyle: {
                minWidth: "600px"
              }
            }
          ]);
          setLoading(false);
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.response.data.desc);
        });
    }
    //wtdStatusSelected.id;
  };

  const exportUrl = () => {
    let endPointExp = "";
    let year = 0;

    if (!yearSelected) {
      year = 0;
    } else {
      year = yearSelected.id;
    }

    const today = new Date();
    today.setFullYear(today.getFullYear() - year);
    const checkDate = today.toISOString().split("T")[0];

    let checkCurrenDate;

    console.log(
      "if checkCurrenDate >>",
      dateOfCheck === null || dateOfCheck === ""
    );

    if (dateOfCheck === null || dateOfCheck === "") {
      checkCurrenDate = today.toISOString().split("T")[0];
    } else {
      checkCurrenDate = dateOfCheck.split("T")[0];
    }

    endPointExp += `&keyTempIdCard=${keyTempIdCard}&wtdStatus=${
      getWtdCheckboxLists.length > 0 ? getWtdCheckboxLists.toString() : 0
    }&wtdLaw=${wtdLaw !== null ? wtdLaw : "-"}&date=${checkDate}&orgName=${
      orgName !== null ? orgName : 0
    }&year=${year !== null ? year : 0}&dateOfCheck=${checkCurrenDate}&total=1`;

    return endPointExp;
  };

  const handleSubmitExport = e => {
    e.preventDefault();
    window.open(
      //`${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt_exp_checkperson&rs:Command=Render&rc:Parameters=Collapsed${exportUrl()}`,
      `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rptWTDPersonCheck&rs:Command=Render&rc:Parameters=Collapsed${exportUrl()}`,
      "_blank"
    );
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br /> <br />
          <GridContainer
            justify="space-between"
            alignItems="top"
            direction="row"
          >
            <GridItem xs={12} sm={12} md={6}>
              <NumberFormat
                style={{
                  width: "100%",
                  margin: "0 0 17px 0",
                  paddingTop: "27px"
                }}
                placeholder="เลขประจำตัวประชาชนที่ต้องการตรวจสอบ"
                customInput={TextField}
                format="# #### ##### ## #"
                mask="X"
                fullWidth
                error={idValid}
                onValueChange={onIDCardHandler}
              />
              <ListboxWtdYear
                defaultValue={{ id: 0 }}
                onChange={onYearSelected}
              ></ListboxWtdYear>
              <CustomInput
                labelText="หมายเลขคดี"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: wtdLaw,

                  onChange: e => {
                    setWtdLaw(e.target.value);
                  }
                }}
              />
              <Datepicker
                name="dateOfCheck"
                id="dateOfCheck"
                label="ตรวจสอบ ณ วันที"
                value={new Date()}
                //value={dateOfCheck}
                onChange={date => setDateOfCheck(date.format())}
                /* onChange={date => {
              if (date !== null) {
                let arr = date.format().split("T");
                setDateOfCheck(arr[0]);
              } else {
                setDateOfCheck(null);
              }
            }} */
              ></Datepicker>

              <CustomInput
                // success={orgNameState === true || orgName !== null}
                // error={orgNameState === false}
                id="orgName"
                required
                labelText="หน่วยงานที่ขอตรวจสอบ (โปรดระบุ)"
                formControlProps={{
                  fullWidth: true
                }}
                error={orgNameState}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  autoComplete: "new-password",
                  onChange: e => {
                    if (verifyLength(e.target.value, 1)) {
                      setOrgNameState(false);
                    } else {
                      setOrgNameState(true);
                    }
                    setOrgName(e.target.value);
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CheckboxWtdStatus
                wtdCheckBoxSelected={wtdCheckBoxSelected}
                onWtdCheckBoxSelected={onWtdCheckBoxSelected}
              ></CheckboxWtdStatus>
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          ></GridContainer>
          <br /> <br /> <br />
          <GridContainer justify="space-between" alignItems="center">
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button
                onClick={() => {
                  setData([]);
                }}
              >
                ยกเลิก
              </Button>
              <Button
                color="info"
                disabled={disableStatus}
                onClick={handelSubmit}
              >
                ตรวจสอบ
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <br />
      {data.length !== 0 ? (
        <GridContainer justify="space-between" alignItems="center">
          <GridItem xs={6} sm={6} md={6}></GridItem>
          <GridItem>
            นำออกข้อมูล:{" "}
            <Button justIcon round color="success" onClick={handleSubmitExport}>
              <FontAwesomeIcon icon={faFileExcel} />
            </Button>
          </GridItem>
        </GridContainer>
      ) : null}
      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title={"ผลการตรวจสอบบุคคลที่ถูกเพิกถอนสิทธิเลือกตั้ง"}
          columns={columns}
          data={data}
          components={{
            Container: props => <Paper {...props} elevation={0} />
            // Header: props => {
            //   return (
            //     <TableHead>
            //       <TableRow>
            //         <TableCell rowSpan={2}>รายการ</TableCell>
            //         <TableCell rowSpan={2}>เลขประจำตัวประชาชน</TableCell>
            //         <TableCell rowSpan={2}>ผลการตรวจ</TableCell>
            //       </TableRow>
            //     </TableHead>
            //   );
            // }
          }}
          localization={{}}
          options={{
            paging: false,
            search: false,
            exportButton: false
          }}
        />
      )}
    </>
  );
};
export default CheckPerson;
