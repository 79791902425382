import React, { useContext, useEffect, useState } from "react";
import { delCandidate, getCadidate } from "contexts/API/INSPEC/Candidate";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectApplyDate from "components/Common/ListboxInspectApplyDate";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import { Paper } from "@material-ui/core";
import { REPORTING_SERVICE } from "config/env.conf";
import { useSweetAlert } from "components/Alert/SweetAlert";

const Candidate = ({ pageProps, setPageProps }) => {
  const {
    provinceSelected,
    onProvinceSelected,
    onInspectApplyDateSelected,
    inspectApplyDateSelected
  } = useContext(InspectorContext);

  const { sweetAlerts } = useSweetAlert();
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([
    {
      title: "เลขที่ใบสมัคร",
      field: "applyNo",
      cellStyle: {
        textAlign: "center"
      }
    },

    {
      title: "ชื่อ สกุล",
      field: "firstName"
    },
    {
      title: "สถานะการสมัคร",
      field: "applyStatus"
    },
    {
      title: "ผลการตรวจสอบ",
      field: "result",
      cellStyle: {
        minWidth: "150px"
      }
    },
    {
      title: "เอกสารการรับสมัคร",
      field: "document"
    }
  ]);

  const [data, setData] = useState([]);
  useEffect(() => {
    if (pageProps.action === "cancel") {
      handelSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps]);
  const handelSearch = async () => {
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }
    if (inspectApplyDateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกช่วงวันที่สมัคร");
      return;
    } else {
      setLoading(true);
      try {
        const res = await getCadidate(
          provinceSelected.id,
          inspectApplyDateSelected.id
        );
        setData(res.data.data);
        setLoading(false);
        const columns = [
          {
            title: "เลขที่ใบสมัคร",
            field: "applyNo",
            cellStyle: {
              textAlign: "center"
            }
          },

          {
            title: "ชื่อ สกุล",
            field: "firstName",
            cellStyle: {
              minWidth: "200px"
            },
            render: rawData => {
              return (
                rawData.personTitle +
                " " +
                rawData.firstName +
                " " +
                rawData.lastName
              );
            }
          },
          {
            title: "สถานะการสมัคร",
            field: "applyStatus"
            // cellStyle: {
            //   minWidth: "150px"
            // }
          },

          {
            title: "ผลการตรวจสอบ",
            field: "result",
            cellStyle: {
              minWidth: "150px"
            }
          },
          {
            title: "เอกสารการรับสมัคร",
            field: "document",
            cellStyle: {
              minWidth: "200px"
            },
            render: rawData => {
              return rawData.documents.map((doc, index) => (
                <div key={index}>
                  {doc.fileUUID !== null ? (
                    <CheckIcon style={{ color: "#4caf50" }} />
                  ) : (
                    <ClearIcon style={{ color: "#f44336" }} />
                  )}{" "}
                  <span>{doc.docTypeName}</span>
                </div>
              ));
            }
          }
        ];
        setColumns(columns);
      } catch (error) {
        HandelError(error);
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", error.toString());
      }
    }
  };

  const optionsTable = {};
  const editCandidate = rowData => {
    setPageProps({
      active: "EditCandidate",
      props: {
        rowData: rowData
      }
    });
  };
  const AddCandidateHandler = () => {
    setPageProps({ active: "AddCandidate", props: null });
  };

  const handleSubmitExport = e => {
    e.preventDefault();
    window.open(
      `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2finspector%2finspector_rpt12_4_Grouping&rs:Command=Render&rc:Parameters=Collapsed&province_id=${provinceSelected.id}&apply_date_id=${inspectApplyDateSelected.id}`,
      "_blank"
    );
  };
  return (
    <div>
      <Card>
        <CardHeader color="danger" text>
          <CardText color="danger">
            <h4>ค้นหารายชื่อผู้สมัครผู้ตรวจการเลือกตั้ง</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxProvince
                    required={true}
                    value={provinceSelected}
                    // defaultValue={provinceSelected || { id: 0 }}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxInspectApplyDate
                    value={inspectApplyDateSelected}
                    required={true}
                    onChange={onInspectApplyDateSelected}
                  ></ListboxInspectApplyDate>
                </GridItem>
              </GridContainer>
              <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button>ยกเลิก</Button>
                  <Button
                    color="info"
                    onClick={handelSearch}
                  //type="submit"
                  >
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <GridItem xs={6} sm={6} md={6}>
                    {provinceSelected && inspectApplyDateSelected ? (
                      <>
                        <>
                          <Button color="success" onClick={AddCandidateHandler}>
                            เพิ่มผู้สมัคร
                          </Button>
                        </>
                        <>
                          <Button
                            color="warning"
                            type="submit"
                            onClick={handleSubmitExport}
                          >
                            พิมพ์ ผตล 3 (รายชื่อผู้สมัครทั้งหมด)
                          </Button>
                        </>
                      </>
                    ) : (
                      ""
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <MaterialTable
                title={"รายชื่อผู้สมัครผู้ตรวจการเลือกตั้ง"}
                options={optionsTable}
                columns={columns}
                enableInitColumns={false}
                data={data}
                actions={[
                  rowData => ({
                    icon: () => (
                      <>
                        <Button
                          size="sm"
                          color="warning"
                          onClick={() => editCandidate(rowData)}
                        >
                          แก้ไข
                        </Button>
                      </>
                    )
                  }),
                  rowData => ({
                    icon: () => (
                      <>
                        {rowData.applyStatusId === 1 ? (
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => {
                              //  const dataDelete = [...data];

                              sweetAlerts.error(
                                "ยืนการลบข้อมูล",
                                "คุณต้องการลบข้อมูลใช่หรือไม่",
                                () => {
                                  delCandidate(rowData.personApplyCmdId)
                                    .then(result => {
                                      const dataDelete = [...data];
                                      const _PK = rowData.personApplyCmdId;
                                      const objIndex = dataDelete.findIndex(
                                        rowData =>
                                          rowData.personApplyCmdId === _PK
                                      );
                                      dataDelete[objIndex] = rowData;
                                      dataDelete.splice(objIndex, 1);
                                      setData([...dataDelete]);

                                      sweetAlerts.success(`ลบข้อมูลสำเร็จ`);
                                    })
                                    .catch(err => {
                                      if (err.response.data.code !== 20000) {
                                        sweetAlerts.danger(
                                          `ไม่สามารถลบข้อมูลได้`,
                                          err.response.data.desc
                                        );
                                      }
                                    });
                                },
                                true
                              );
                            }}
                          >
                            ลบ
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    )
                  })
                ]}
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}
              />
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
};
export default Candidate;
