import React, { useContext, useEffect, useState } from "react";
/* API */
import {
  deleteVoteCardPurchase,
  getSearchLists
} from "contexts/API/VoteCard/Purchase";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import DatepickerVotedate from "components/Common/Datepicker";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InputLabel from "@material-ui/core/InputLabel";
import ListboxProvince from "components/Common/ListboxProvince";
/* Material Components */
import LoadingData from "components/Common/LoadingData";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TableComponent from "components/Table/MaterialTable";
import { VoteDateContext } from "contexts/VoteDate";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const PurChase = ({ pageProps, setPageProps }) => {
  const useStyles = makeStyles({});

  const [loading, setLoading] = useState(false);
  const [clickSearch, setClickSearch] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [documentNo, setDocumentNo] = useState(null);

  const {
    provinceSelected,
    setProvinceSelected,
    onProvinceSelected,
    positionTypeSelected,
    setPostionTypeSelected
  } = useContext(VoteDateContext);

  const [
    voteCardPurchaseStatusSelected,
    setVoteCardPurchaseStatusSelected
  ] = useState(null);
  const classes = useStyles();
  const optionsTable = { exportButton: true, paging: true };

  useEffect(() => {
    if (pageProps.action === "cancel") {
      onClickSearch();
    }
  }, [pageProps]);
  const [columns] = useState([
    {
      title: "จังหวัด",
      field: "ProvinceName",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "เลขที่หนังสืออ้างอิง",
      field: "DocumentNo",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "สถานะการสั่งซื้อบัตร",
      field: "PurchaseStatusName",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "สถานะการจัดสรรบัตร",
      field: "PublishStatusName",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "สถานะการส่งข้อมูลให้โรงพิมพ์",
      field: "SendPringHouseStatusName",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "สถานะการส่ง/รับบัตรของโรงพิมพ์",
      field: "ReceivePringHouseStatusName",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "สถานะรายงานการรับบัตรเลือกตั้ง",
      field: "ReceiveOptStatusName",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "วันที่สั่งซื้อ",
      field: "PurchaeOrderDate",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.PurchaseDateTime);
      }
    },
    {
      title: "",
      field: "",
      cellStyle: {
        minWidth: "140px"
      },
      render: rowData => {
        return (
          <>
            <Button
              size="sm"
              color="info"
              onClick={() => {
                setPageProps({ active: "DetailPurchase", props: rowData });
              }}
            >
              รายละเอียด
            </Button>
          </>
        );
      }
    }
  ]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (pageProps.props != null) {
      setClickSearch(false);
      setLoading(true);

      const optQueryUrl = () => {
        let endPoint = "?";

        if (provinceSelected !== null) {
          endPoint += `&ProvinceID=${provinceSelected.id}`;
        }

        if (positionTypeSelected !== null) {
          endPoint += `&PositionID=${positionTypeSelected.id}`;
        }

        if (beginDate !== null) {
          endPoint += `&PurchaseStartDate=${beginDate.split("T")[0]}`;
        }

        if (endDate !== null) {
          endPoint += `&PurchaseEndDate=${endDate.split("T")[0]}`;
        }
        if (documentNo !== null) {
          endPoint += `&DocumentNo=${documentNo}`;
        }

        return endPoint;
      };

      getSearchLists(optQueryUrl(), optExportUrl())
        .then(result => {
          setData(result.data.data);
          setLoading(false);
          setClickSearch(true);
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้.", err.toString());
        });
    }
  }, []);

  const onClickCancel = e => {
    setProvinceSelected(null);
    setPostionTypeSelected(null);
    setVoteCardPurchaseStatusSelected(null);
    setBeginDate(null);
    setEndDate(null);
    setData([]);
  };
  const onClickSearch = () => {
    setLoading(true);

    const optQueryUrl = () => {
      let endPoint = "?";

      if (provinceSelected !== null) {
        endPoint += `&ProvinceID=${provinceSelected.id}`;
      }

      if (positionTypeSelected !== null) {
        endPoint += `&PositionID=${positionTypeSelected.id}`;
      }

      if (beginDate !== null) {
        endPoint += `&PurchaseStartDate=${beginDate.split("T")[0]}`;
      }

      if (endDate !== null) {
        endPoint += `&PurchaseEndDate=${endDate.split("T")[0]}`;
      }

      if (voteCardPurchaseStatusSelected !== null) {
        endPoint += `&PurchaseStatus=${voteCardPurchaseStatusSelected}`;
      }

      if (documentNo !== null && documentNo !== "") {
        endPoint += `&DocumentNo=${documentNo}`;
      }
      return endPoint;
    };

    getSearchLists(optQueryUrl(), optExportUrl())
      .then(result => {
        setData(result.data.data);
        setLoading(false);
        setClickSearch(true);
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้.", err.toString());
      });
  };

  const optExportUrl = () => { };
  const addPurchaseHandler = () => {
    setPageProps({ active: "AddPurChase", props: null });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" text>
              <CardText color="success">
                <h4>ค้นหารายการสั่งซื้อบัตรเลือกตั้ง</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxProvince
                          value={provinceSelected}
                          onChange={onProvinceSelected}
                        ></ListboxProvince>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <DatepickerVotedate
                          id="begin"
                          label="วันที่สั่งซื้อเริ่มต้น"
                          value={beginDate}
                          defaultValue={beginDate}
                          onChange={date => {
                            setBeginDate(date.format());
                          }}
                        ></DatepickerVotedate>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <DatepickerVotedate
                          id="endDate"
                          label="วันที่สั่งซื้อสิ้นสุด"
                          value={endDate}
                          defaultValue={endDate}
                          onChange={date => {
                            setEndDate(date.format());
                          }}
                        ></DatepickerVotedate>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="เลขที่หนังสืออ้างอิง"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: documentNo || "",
                            style: { paddingTop: "10px" },
                            onChange: e => {
                              setDocumentNo(e.target.value);
                            }
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <br />
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel>สถานะการสั่งซื้อบัตร</InputLabel>
                          <Select
                            defaultValue={voteCardPurchaseStatusSelected}
                            fullWidth
                            margin={20}
                            onChange={e => {
                              setVoteCardPurchaseStatusSelected(e.target.value);
                            }}
                          >
                            <MenuItem value={"1"}>
                              บันทึกข้อมูลการสั่งซื้อ
                              ยังไม่อัพโหลดเอกสารการสั่งซื้อ
                            </MenuItem>
                            <MenuItem value={"2"}>
                              อัพโหลดเอกสารแล้วรอส่งคำสั่งซื้อไปยัง สนง.กกต
                            </MenuItem>
                            <MenuItem value={"3"}>
                              ส่งคำสั่งซื้อบัตรเลือกตั้งไปยัง สนง.กกต
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {/*
                                                <ListboxVotecardPurchaseStatus
                                                    value={voteCardPurchaseStatusSelected}
                                                    onChange={onVoteCardPurchaseStatusSelected}
                                                ></ListboxVotecardPurchaseStatus>
                                                */}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer justify="space-between" alignItems="center">
                  <GridItem xs={6} sm={6} md={6}>
                    <Button color="success" onClick={addPurchaseHandler}>
                      เพิ่มรายการสั่งซื้อบัตรเลือกตั้ง
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button onClick={onClickCancel}>ยกเลิก</Button>
                    <Button color="info" type="button" onClick={onClickSearch}>
                      ค้นหา
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title="รายการสั่งซื้อบัตรเลือกตั้ง"
          columns={columns}
          data={data}
          localization={{}}
          defaultExpanded={true}
          options={optionsTable}
          defaultExpanded={true}
          editable={{
            isDeleteHidden: rowData => rowData.PurchaseStatusID > 2,
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);

                deleteVoteCardPurchase({
                  voteCardPurchaseID: oldData.PurchaeID
                })
                  .then(result => {
                    sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                    dataDelete.splice(index, 1);
                    setData(dataDelete);
                    resolve();
                  })
                  .catch(err => {
                    sweetAlerts.danger(
                      `ลบข้อมูลไม่สำเร็จ`,
                      err.response.data.desc
                    );
                  });
              })
          }}
          components={{}}
        ></TableComponent>
      )}
    </div>
  );
};
export default PurChase;
