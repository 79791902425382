import React, { createRef, useContext } from "react";

import AtLeastOne from "utils/Validators/AtLeastOne";
import Button from "components/CustomButtons/Button.js";
//import ListboxAreasetting from "components/Common/ListboxAreasetting";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOpttype from "components/Common/ListboxOpttype.js";
// Core Components
import ListboxProvince from "components/Common/ListboxProvince.js";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import Slide from "@material-ui/core/Slide";
import { VoteDateContext } from "contexts/VoteDate";
// Context APIs
import { addNewOpt } from "contexts/API/OPT/Opt";
// Material Core
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
//let optTypes = { id: "", value: "เลือกประเภท อปท." };
// const [getLastOpt, setGetLastOpt] = useState({
//   id: "",
//   value: "เลือกประเภท อปท."
// });
const ModaladdOpt = props => {
  const { sweetAlerts } = useSweetAlert();

  const {
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,
    onTambolSelected,
    tambolSelected,
    listboxOptSelected,
    onListboxOptSelected,
    setListboxOptSelected,
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);

  const optTypes1 = Number(getOptTypeSelectedLists().slice(-1)[0]);

  //const { show, closeModal, onaddSuccess } = props;
  const { show, closeModal } = props;

  const classes = useStyles();

  const optNameRef = createRef();

  const onSubmit = () => {
    let optSubTypeId = null;
    let provincedId = null;
    let amphurId = null;
    let tambolId = null;
    let optName = null;
    // console.log(">>> onSubmit <<<");
    // console.log("listboxOptSelected", listboxOptSelected);
    // console.log(optTypes1);
    if (listboxOptSelected === null && optTypes1 === undefined) {
      sweetAlerts.warning("กรุณาเลือกประเภท อปท.");

      return;
    } else {
      if (listboxOptSelected !== null) {
        optSubTypeId = listboxOptSelected.id;
      } else {
        optSubTypeId = optTypes1
      }
    }

    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    } else {
      provincedId = provinceSelected.id;
    }

    if ([2, 3, 4, 5].includes(optSubTypeId)) {
      if (amphurSelected === null) {
        sweetAlerts.warning("กรุณาเลือกอำเภอ");
        return;
      } else {
        amphurId = amphurSelected.id;
      }
    }

    if ([5].includes(optSubTypeId)) {
      if (tambolSelected === null) {
        sweetAlerts.warning("กรุณาเลือกตำบล");
        return;
      } else {
        tambolId = tambolSelected.id;
      }
    }

    if (optNameRef.current.value === "") {
      sweetAlerts.warning("กรุณาระบุชื่อ อปท.");
      return;
    } else {
      optName = optNameRef.current.value;
    }

    addNewOpt(optSubTypeId, optName, provincedId, amphurId, tambolId)
      .then(result => {
        sweetAlerts.success(
          `เพิ่มข้อมูลเรียบร้อยแล้ว กรุณากดค้นหาข้อมูลที่ต้องการ`
        );
        closeModal();
        setListboxOptSelected(null);
        //addSucess(optName);
      })
      .catch(err => {
        if (err.response.data && err.response.data.desc) {
          sweetAlerts.error("เพิ่ม อปท. ไม่สำเร็จ", err.response.data.desc);
        } else {
          sweetAlerts.error("เพิ่ม อปท. ไม่สำเร็จ");
        }
      });
  };
  /*   const addSucess = optName => {
      onaddSuccess(optName);
    }; */

  // useEffect(() => {
  //   let getLastOpt = optTypes.slice(-1)[0];
  //   setGetLastOpt(getLastOpt);
  //   console.log(getLastOpt);
  // }, [optTypes]);
  return (
    <div className={show ? "modal" : "hide"}>
      {listboxOptSelected && console.log([5].includes(listboxOptSelected.id))}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={show}
        fullWidth={true}
        transition={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          className={classes.titlemodal}
        >
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            {" " + props.title}
          </div>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <form className={classes.form}>
            <FormControl>
              <ListboxOpttype
                validators={[AtLeastOne]}
                defaultValue={{ id: parseInt(optTypes1) }}
                // value={
                //   listboxOptSelected ? listboxOptSelected : { id: optTypes1 }
                // }
                onChange={onListboxOptSelected}
              ></ListboxOpttype>
              <ListboxProvince
                validators={[AtLeastOne]}
                value={provinceSelected}
                title={"เลือกจังหวัด"}
                onChange={onProvinceSelected}
              ></ListboxProvince>
              {(listboxOptSelected &&
                [1, 2, 3, 4, 5, 6, 7].includes(listboxOptSelected.id)) || ([1, 2, 3, 4, 5, 6, 7].includes(parseInt(optTypes1))) ? (
                <ListboxDistinct
                  value={amphurSelected}
                  provinceSelected={provinceSelected}
                  title={parseInt(optTypes1) === 1 ? "เลือกเขตอำเภอ" : "เลือกอำเภอ"}
                  onChange={onAmphurSelected}
                ></ListboxDistinct>
              ) : (
                ""
              )}
              {(listboxOptSelected &&
                [5, 6, 7].includes(listboxOptSelected.id)) || ([5, 6, 7].includes(parseInt(optTypes1))) ? (
                <ListboxSubdistinct
                  provinceSelected={provinceSelected}
                  amphurSelected={amphurSelected}
                  title={"เลือกตำบล"}
                  value={tambolSelected}
                  onChange={onTambolSelected}
                ></ListboxSubdistinct>
              ) : (
                ""
              )}

              <CustomInput
                name="optname"
                default
                required
                labelText="ชื่อ อปท. *"
                inputProps={{
                  inputRef: optNameRef
                }}
              />
              {/* {errors.optname && (
                <p className={classes.error}>{errors.optname}</p>
              )} */}
            </FormControl>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={closeModal}>ยกเลิก</Button>
              <Button onClick={onSubmit} variant="contained" color="success">
                บันทึก
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModaladdOpt;
