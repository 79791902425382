import React, { useState } from "react";

import AddPerson from "components/pages/suspend/AddSuspend/AddPerson";
import EditSuspendPerson from "components/pages/suspend/AddSuspend/EditPerson";
import ListSuspendPerson from "./ListSuspendPerson";
import { SuspendContextProvider } from "contexts/Suspend";

// @material-ui/core components

// core components

const SwitchComponents = ({ active, children }) => {
  return children.filter(child => child.props.name === active);
};

const ListSuspendIndex = props => {
  const [pageProps, setPageProps] = useState({
    active: "ListSuspendPerson",
    props: null
  });

  return (
    <SuspendContextProvider active={pageProps.active}>
      <SwitchComponents active={pageProps.active}>
        <ListSuspendPerson
          name="ListSuspendPerson"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <EditSuspendPerson
          name="EditSuspendPerson"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <AddPerson
          name="AddPerson"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
      </SwitchComponents>
    </SuspendContextProvider>
  );
};
export default ListSuspendIndex;
