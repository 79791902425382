import React, { useContext, useState } from "react";
import { delUploadCmd, getuploadCmd } from "contexts/API/INSPEC/Command";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import { DataGrid } from "@material-ui/data-grid";
import FileSaver from "file-saver";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectApplyDate from "components/Common/ListboxInspectApplyDate";
import LoadingData from "components/Common/LoadingData";
//import MaterialTable from "components/Table/MaterialTable";
import ModalUpload from "./ModalUpload";
//import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { downloadFileFromUrl } from "contexts/API/FileManagement/download";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const Command = props => {
  const { onInspectApplyDateSelected, inspectApplyDateSelected } = useContext(
    InspectorContext
  );
  const [selectionModel, setSelectionModel] = React.useState([]);
  const { sweetAlerts } = useSweetAlert();
  //const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [showModalUpload, setshowModalUpload] = useState(false);
  const openModalUpload = () => setshowModalUpload(true);
  const closeModalUpload = () => setshowModalUpload(false);
  const [loading, setLoading] = useState(false);
  const [childDetail, setchildDetail] = useState(null);

  const useStyles = makeStyles(theme => ({
    root: {
      "& .MuiDataGrid-columnHeaderWrapper": {
        backgroundColor: "rgb(221, 221, 221)"
      }
    }
  }));
  const classes = useStyles();
  const deleteCmd = params => {
    // let idx = e
    // let row = data[idx];

    sweetAlerts.error(
      "ยืนยันการลบข้อมูล",

      "คุณต้องการลบข้อมูลใช่หรือไม่",
      () => {
        delUploadCmd(params.row.cmdUploadId, params.row.docTypeId)
          .then(response => {
            clickSearch();
            setSelectionModel([]);
            sweetAlerts.success("ลบข้อมูลเรียบร้อย");
          })
          .catch(err => {
            HandelError(err);
            setSelectionModel([]);
            sweetAlerts.danger("ขออภัยไม่สามารถลบข้อมูลได้");
          });
      },
      true
    );
  };

  const fileSuccess = command_upload_id => {
    if (command_upload_id) {
      setSelectionModel([]);
      clickSearch();
    }
  };

  const clickSearch = async () => {
    if (inspectApplyDateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกช่วงวันที่สมัคร");
      return;
    } else {
      setLoading(true);
      try {
        const res = await getuploadCmd(inspectApplyDateSelected.id);

        res.data.data.map((v, index) => {
          v.id = v.cmdId;
          v.ids = index + 1;
        });

        setData(res.data.data);
        setSelectionModel([]);
        // console.log(res.data.data);
        // let x = res.data.data.filter(v => {
        //   return v.fileUUID !== null || v.CmdUploadIdReport4 === null;
        // });
        // Object.keys(x).length === res.data.data.length
        //   ? setIsSelected(false)
        //   : setIsSelected(true);
        // console.log("x", x);
        // console.log("x", Object.keys(x).length);
        // console.log(res.data.data.length);
        setLoading(false);
        // const columns = [
        //   { field: 'id', headerName: 'ID', width: 90 },
        //   { field: 'firstName', headerName: 'First name', width: 150 },
        //   { field: 'lastName', headerName: 'Last name', width: 150 },
        //   {
        //     field: 'age',
        //     headerName: 'Age',
        //     type: 'number',
        //     width: 110,
        //   },
        //   {
        //     field: 'fullName',
        //     headerName: 'Full name',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 160,
        //     valueGetter: (params) =>
        //       `${params.getValue(params.id, 'firstName') || ''} ${
        //         params.getValue(params.id, 'lastName') || ''
        //       }`,
        //   },

        //  setColumns(columns);
        // setLoading(false);
      } catch (error) {
        // HandelError(error);
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", error.toString());
      }
    }
  };
  // const rows = [
  //   {
  //     id: 1,
  //     username: "@MaterialUI",
  //     age: 20
  //   },
  //   {
  //     id: 2,
  //     username: "@MaterialUI",
  //     age: 20
  //   }
  // ];
  const downloadFile = (url, fileName) => {
    if (url && fileName) {
      downloadFileFromUrl(url)
        .then(res => {
          //   console.log(res);
          FileSaver.saveAs(res.data, fileName);
        })
        .catch(err => {
          console.log("download file failed.");
        });
    } else {
      throw Error(
        "url not found, see https://github.com/DGA-SCD/ECTPL/issues/463"
      );
    }
  };
  function getFullName(params) {
    if (params.getValue(params.id, "numOfReplacePersonApply") > 0) {
      return (
        <Typography>มีการเลือกผู้ดำรงตำแหน่งแทน ผตล. ลบคำสั่งไม่ได้</Typography>
      );
    }
    if (params.getValue(params.id, "numOfInspectorInProvince") > 0) {
      return <Typography>มีการจับสลาก ผตล.จว. ลบคำสั่งไม่ได้</Typography>;
    }
    if (
      params.getValue(params.id, "numOfInspectorInProvince") === null &&
      params.getValue(params.id, "numOfReplacePersonApply") === null &&
      params.getValue(params.id, "CmdUploadIdReport4") === null
    ) {
      return <Typography>[ไม่พบไฟล์ ผตล.4]</Typography>;
    }
    if (
      params.getValue(params.id, "numOfInspectorInProvince") === 0 &&
      params.getValue(params.id, "numOfReplacePersonApply") === 0 &&
      params.getValue(params.id, "CmdUploadIdReport4") !== null
    ) {
      return (
        <Button
          color="danger"
          size="sm"
          // value={params}
          onClick={() => deleteCmd(params)}
        >
          ลบ
        </Button>
      );
    }
    //     //     params.numOfInspectorInProvince === null &&
    //     //     params.numOfReplacePersonApply === null &&
    //     //     params.CmdUploadIdReport4 === null
    //     //   ) {
    //     //     return "[ไม่พบไฟล์ ผตล.4]";
    //     //   }

    // if (params.getValue(params.id, "numOfReplacePersonApply") > 0) {
    //   ("มีการเลือกผู้ดำรงตำแหน่งแทน ผตล. ลบคำสั่งไม่ได้");
    // }
    // if (params.getValue(params.id, "numOfInspectorInProvince") > 0) {
    //   ("มีการจับสลาก ผตล.จว. ลบคำสั่งไม่ได้");
    // }
    //   if (params.numOfReplacePersonApply > 0) {
    //     //     return "มีการเลือกผู้ดำรงตำแหน่งแทน ผตล. ลบคำสั่งไม่ได้";
    //     //   }
    //     //   if (params.numOfInspectorInProvince > 0) {
    //     //     return "มีการจับสลาก ผตล.จว. ลบคำสั่งไม่ได้";
    //     //   }
    //     //   if (
    //     //     params.numOfInspectorInProvince === null &&
    //     //     params.numOfReplacePersonApply === null &&
    //     //     params.CmdUploadIdReport4 === null
    //     //   ) {
    //     //     return "[ไม่พบไฟล์ ผตล.4]";
    //     //   }
    //     // }
    //if (
    //     //     params.numOfInspectorInProvince === 0 &&
    //     //     params.numOfReplacePersonApply === 0 &&
    //     //     params.CmdUploadIdReport4 !== null
    //     //   ) {
    //     //     return (
    //     //       <Button
    //     //         color="danger"
    //     //         size="sm"
    //     //         value={params}
    //     //         onClick={() => deleteCmd(params)}
    //     //       >
    //     //         ลบ
    //     //       </Button>
    //     //     );
    //     //   }
  }
  const columns = [
    {
      field: "id",
      // minWidth: 150,
      hide: true
    },
    {
      headerName: "ลำดับ",
      field: "ids",
      flex: 2
      //width: 200
    },

    {
      headerName: "ไฟล์คำสั่ง",
      field: "fileUUID",

      flex: 5,
      renderCell: params => {
        return params.getValue(params.id, "fileUUID") ? (
          <Typography
            style={{ cursor: "pointer", color: "purple" }}
            onClick={() =>
              downloadFile(
                getFileDownloadUrl(
                  "inspect",
                  params.getValue(params.id, "fileUUID")
                ),
                params.getValue(params.id, "filename")
              )
            }
          >
            {params.getValue(params.id, "filename")}
          </Typography>
        ) : (
          // <LinkDownload
          //   url={getFileDownloadUrl(
          //     "inspect",
          //     params.getValue(params.id, "fileUUID")
          //   )}
          //   fileId={params.getValue(params.id, "fileUUID")}
          //   fileName={params.getValue(params.id, "filename")}
          // />
          ""
        );
      }
    },
    {
      headerName: "จังหวัดในแนบท้ายประกาศ",
      field: "provinceName",
      //  width: 200
      flex: 3
    },
    // {
    //   field: "CmdUploadIdReport4",
    //   width: 200
    // },
    {
      field: "numOfInspectorInProvince",
      headerName: " ",
      renderCell: getFullName,
      //  width: 200
      flex: 3
    }
  ];
  // const columns = [
  //   {
  //     headerName: "ID",
  //     field: "cmdId",
  //     identity: true
  //   },
  //   {
  //     headerName: "ไฟล์คำสั่ง",
  //     field: "file"
  //   },

  //   {
  //     title: "จังหวัดในประกาศแนบท้าย",
  //     headerName: "provinceName"
  //   },

  //   {
  //     title: "numOfInspectorInProvince",
  //     headerName: "numOfInspectorInProvince"

  //     // valueGetter: params => {
  //     //   if (
  //     //     params.numOfInspectorInProvince === 0 &&
  //     //     params.numOfReplacePersonApply === 0 &&
  //     //     params.CmdUploadIdReport4 !== null
  //     //   ) {
  //     //     return (
  //     //       <Button
  //     //         color="danger"
  //     //         size="sm"
  //     //         value={params}
  //     //         onClick={() => deleteCmd(params)}
  //     //       >
  //     //         ลบ
  //     //       </Button>
  //     //     );
  //     //   }

  //     //   if (params.numOfReplacePersonApply > 0) {
  //     //     return "มีการเลือกผู้ดำรงตำแหน่งแทน ผตล. ลบคำสั่งไม่ได้";
  //     //   }
  //     //   if (params.numOfInspectorInProvince > 0) {
  //     //     return "มีการจับสลาก ผตล.จว. ลบคำสั่งไม่ได้";
  //     //   }
  //     //   if (
  //     //     params.numOfInspectorInProvince === null &&
  //     //     params.numOfReplacePersonApply === null &&
  //     //     params.CmdUploadIdReport4 === null
  //     //   ) {
  //     //     return "[ไม่พบไฟล์ ผตล.4]";
  //     //   }
  //     // }
  //   }
  // ];
  // const actions = [
  //   {
  //     tooltip: "อัพโหลดไฟล์คำสั่ง",

  //     icon: () => (
  //       <Button variant="contained" color="warning" size="sm">
  //         อัพโหลดไฟล์คำสั่ง
  //       </Button>
  //     ),
  //     onClick: (evt, data) => {
  //       openModalUpload();

  //       setchildDetail({
  //         data: data,
  //         candidate: inspectApplyDateSelected.id
  //       });
  //       // alert("ยุบทั้งหมด" + data.length + " อปท.");
  //     },
  //     disabled: false,
  //     hidden: false
  //   }
  // ];
  // const optionsTable = {
  //   pageSize: 10,
  //   selection: isSelected,
  //   selectionProps: rowData => ({
  //     disabled: rowData.fileUUID !== null || rowData.CmdUploadIdReport4 === null
  //     // color: "primary"
  //   })
  // };

  return (
    <div>
      <ModalUpload
        closeModalUpload={closeModalUpload}
        showModalUpload={showModalUpload}
        childDetail={childDetail}
        onaddSuccess={fileSuccess}
        //  onaddfileSuccess={fileSuccess}
      ></ModalUpload>
      <Card>
        <CardHeader color="danger" text>
          <CardText color="danger">
            <h4>บันทึกคำสั่งแต่งตั้ง ผตล.</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxInspectApplyDate
                    value={inspectApplyDateSelected}
                    required={true}
                    onChange={onInspectApplyDateSelected}
                  ></ListboxInspectApplyDate>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}></GridItem>
              </GridContainer>
              <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button>ยกเลิก</Button>
                  <Button color="info" onClick={clickSearch}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <GridItem xs={6} sm={6} md={6}>
                    <span style={{ color: "red", paddingLeft: "20px" }}>
                      หากมีการเลือกผู้ดำรงตำแหน่งแทน ผตล. จะไม่สามารถลบคำสั่งได้
                    </span>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} style={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      color="warning"
                      size="sm"
                      disabled={selectionModel.length === 0}
                      onClick={() => {
                        openModalUpload();
                        setchildDetail({
                          data: selectionModel,
                          candidate: inspectApplyDateSelected.id
                        });
                        //  setchildDetail(selectionModel);
                      }}
                    >
                      อัพโหลดไฟล์คำสั่ง
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <div
                style={{ height: "1000px", width: "100%" }}
                className={classes.root}
              >
                <DataGrid
                  columns={columns}
                  rows={data}
                  // pageSize={50}
                  hideFooter={true}
                  checkboxSelection
                  isRowSelectable={params =>
                    params.row.fileUUID === null &&
                    params.row.CmdUploadIdReport4 !== null &&
                    params.row.numOfReplacePersonApply === null &&
                    params.row.numOfInspectorInProvince === null
                  }
                  //   selectionProps: rowData => ({
                  //     disabled: rowData.fileUUID !== null || rowData.CmdUploadIdReport4 === null
                  //     // color: "primary"
                  //   })
                  // };
                  sortModel={[
                    {
                      field: "ids",
                      sort: "asc"
                    }
                  ]}
                  onSelectionModelChange={newSelection => {
                    setSelectionModel(newSelection.selectionModel);
                    //  openModalUpload();
                  }}
                  selectionModel={selectionModel}
                />
              </div>
              {/* <MaterialTable
                title={"รายการไฟล์คำสั่งแต่งตั้งผู้ตรวจการเลือกตั้ง"}
                options={optionsTable}
                columns={columns}
                data={data}
                actions={actions}
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}
                localization={{
                  toolbar: {
                    nRowsSelected: "เลือกทั้งหมด {0} จังหวัด."
                  }
                }}
              /> */}
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
};
export default Command;
