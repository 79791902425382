import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@material-ui/core/Collapse';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ModalReport01 from "./ModalReport1";
import ModalReport02 from "./ModalReport2";
import ModalReport03 from "./ModalReport3";
import { REPORTING_SERVICE } from "config/env.conf";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody"
//import CardHeader from "components/Card/CardHeader.js";
//import CardText from "components/Card/CardText.js";


const useStyles = makeStyles((theme) => ({
      root: {
            width: '100%',
            //maxWidth: 360,
            //backgroundColor: theme.palette.background.paper,
      },
      nested: {
            paddingLeft: theme.spacing(4),
      },
}));


const RptSummaryOpt = props => {
      console.log(REPORTING_SERVICE);

      const classes = useStyles();
      const [showModalReport1, setshowModalReport1] = useState(false);
      const openModalReport1 = () => setshowModalReport1(true);
      const closeModalReport1 = () => setshowModalReport1(false);

      const [showModalReport2, setshowModalReport2] = useState(false);
      const openModalReport2 = () => setshowModalReport2(true);
      const closeModalReport2 = () => setshowModalReport2(false);


      const [showModalReport3, setshowModalReport3] = useState(false);
      const openModalReport3 = () => setshowModalReport3(true);
      const closeModalReport3 = () => setshowModalReport3(false);


      const [child, setChild] = useState('');
      const [title, setTitle] = useState('');
      const [index, setIndex] = useState('');
      const [open1, setOpen1] = React.useState(true);
      const [open,] = React.useState(true);
      //const [open2, setOpen2] = React.useState(true);
      const [open3, setOpen3] = React.useState(true);
      const [open4, setOpen4] = React.useState(true);
      const [open5, setOpen5] = React.useState(true);
      const [open6, setOpen6] = React.useState(true);
      const [open7, setOpen7] = React.useState(true);
      const [open8, setOpen8] = React.useState(true);
      const [open9, setOpen9] = React.useState(true);


      const handleClick1 = (num) => {
            setOpen1(!open1);
      };
      /*   const handleClick2 = (num) => {
              setOpen2(!open2);
        }; */

      const handleClick3 = () => {
            setOpen3(!open3);
      };
      const handleClick4 = () => {
            setOpen4(!open4);
      };
      const handleClick5 = () => {
            setOpen5(!open5);
      };

      const handleClick6 = () => {
            setOpen6(!open6);
      };
      const handleClick7 = () => {
            setOpen7(!open7);
      };
      const handleClick8 = () => {
            setOpen8(!open8);
      };
      const handleClick9 = () => {
            setOpen9(!open9);
      }

      return (
            <>
                  <ModalReport01
                        closeModalReport1={closeModalReport1}
                        showModalReport1={showModalReport1}
                        linkReport={child}
                        titleReport={title}
                        indexReport={index}

                  ></ModalReport01>

                  <ModalReport02
                        closeModalReport2={closeModalReport2}
                        showModalReport2={showModalReport2}
                        linkReport={child}
                        titleReport={title}
                        indexReport={index}
                  />

                  <ModalReport03
                        closeModalReport3={closeModalReport3}
                        showModalReport3={showModalReport3}
                        linkReport={child}
                        titleReport={title}
                        indexReport={index}
                  />

                  <Card>
                        <CardBody>
                              <GridContainer spacing={2}>
                                    <GridItem xs={12} md={12}>
                                          <h4 className={classes.cardTitle}>รายงานระบบทะเบียนสมาชิกสภาท้องถิ่น และผู้บริหารท้องถิ่น</h4>
                                    </GridItem>
                              </GridContainer>
                              <GridContainer spacing={2}>
                                    <GridItem xs={12} md={12}>
                                          <List component="nav"
                                                aria-labelledby="nested-list-subheader"
                                                /*  subheader={
                                                       <ListSubheader component="div" id="nested-list-subheader">
                                                             รายการรายงาน
                                                         </ListSubheader>
                                                 } */
                                                className={classes.root}>
                                                <ListItem button onClick={handleClick1}>
                                                      <ListItemIcon>
                                                            <MenuBookIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="1. ข้อมูลพื้นฐานองค์กรปกครองส่วนท้องถิ่น" />
                                                      {open1 ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={open1} timeout="auto" unmountOnExit>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="1.1 จำนวนองค์กรปกครองส่วนท้องถิ่น ณ ปัจจุบัน"
                                                                        onClick={() => {
                                                                              openModalReport1();

                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt1_1&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('1.1 จำนวนองค์กรปกครองส่วนท้องถิ่น ณ ปัจจุบัน')
                                                                              setIndex('1_1')
                                                                        }}
                                                                  />
                                                                  {/* // onClick={e => printingReport1_01("")} /> */}
                                                            </ListItem>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="1.2 รายชื่อองค์กรปกครองส่วนท้องถิ่น ณ ปัจจุบัน"

                                                                        onClick={() => {
                                                                              openModalReport1();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt1_2&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('1.2 รายชื่อองค์กรปกครองส่วนท้องถิ่น ณ ปัจจุบัน')
                                                                              setIndex('1_2')
                                                                        }}


                                                                  />

                                                            </ListItem>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="1.3 รายชื่อองค์กรปกครองส่วนท้องถิ่น ที่มีการเปลี่ยนแปลงสถานะ" onClick={() => {
                                                                        openModalReport1();
                                                                        setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt1_3&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('1.3 รายชื่อองค์กรปกครองส่วนท้องถิ่น ที่มีการเปลี่ยนแปลงสถานะ')
                                                                        setIndex('1_3')
                                                                  }} />
                                                            </ListItem>
                                                      </List>
                                                </Collapse>

                                                <ListItem button>
                                                      <ListItemIcon>
                                                            <MenuBookIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="2. ทะเบียนสมาชิกสภาท้องถิ่น/ผู้บริหารท้องถิ่น" onClick={() => {
                                                            openModalReport2();
                                                            setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt2_1&rs:Command=Render&rc:Parameters=Collapsed')
                                                            setTitle('2 ทะเบียนสมาชิกสภาท้องถิ่น/ผู้บริหารท้องถิ่น')
                                                            setIndex('2_1')
                                                      }} />
                                                      {/* {open2 ? <ExpandLess /> : <ExpandMore />} */}
                                                </ListItem>
                                                {/* <Collapse in={open2} timeout="auto" unmountOnExit>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="ทะเบียนสมาชิกสภาท้องถิ่น/ผู้บริหารท้องถิ่น"
                                                                  
                                                                  onClick={() => {
                                                                        openModalReport2();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt2_1&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('2.1 ทะเบียนสมาชิกสภาท้องถิ่น/ผู้บริหารท้องถิ่น')
                                                                        setIndex('2_1')
                                                                      }} />
                                                            </ListItem>
                                                      </List>
                                                </Collapse> */}

                                                <ListItem button onClick={handleClick3}>
                                                      <ListItemIcon>
                                                            <MenuBookIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="3. ครบวาระ" />
                                                      {open3 ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={open3} timeout="auto" unmountOnExit>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="3.1 จำนวนองค์กรปกครองส่วนท้องถิ่นที่ครบวาระ"

                                                                        onClick={() => {
                                                                              openModalReport1();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt3_1&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('3.1 จำนวนองค์กรปกครองส่วนท้องถิ่นที่ครบวาระ')
                                                                              setIndex('3_1')
                                                                        }} />
                                                            </ListItem>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>

                                                                  <ListItemText primary="3.2 รายชื่อองค์กรปกครองส่วนท้องถิ่นที่ครบวาระ" onClick={() => {
                                                                        openModalReport1();
                                                                        setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt3_2&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('3.2 รายชื่อองค์กรปกครองส่วนท้องถิ่นที่ครบวาระ')
                                                                        setIndex('3_2')
                                                                  }} />
                                                            </ListItem>


                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>

                                                                  <ListItemText primary="3.3 จำนวนองค์กรปกครองส่วนท้องถิ่นที่ครบวาระ จำแนกรายเดือน" onClick={() => {
                                                                        openModalReport1();
                                                                        setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt3_3&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('3.3 จำนวนองค์กรปกครองส่วนท้องถิ่นที่ครบวาระ จำแนกรายเดือน')
                                                                        setIndex('3_3')
                                                                  }} />
                                                            </ListItem>



                                                            {/* <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                 
                                                                  <ListItemText primary="จำนวนองค์กรปกครองส่วนท้องถิ่นที่ครบวาระ จำแนกรายเดือน" onClick={() => {
                                                                        openModalReport1();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt3_2&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('3.3 จำนวนองค์กรปกครองส่วนท้องถิ่นที่ครบวาระ จำแนกรายเดือน ')
                                                                        setIndex('3_3')
                                                                      }} />
                                                            </ListItem> */}
                                                      </List>
                                                </Collapse>
                                                <ListItem button onClick={handleClick4}>
                                                      <ListItemIcon>
                                                            <MenuBookIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="4. วันเลือกตั้ง" />
                                                      {open4 ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={open4} timeout="auto" unmountOnExit>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="4.1 จำนวนองค์กรปกครองส่วนท้องถิ่นที่มีการเลือกตั้ง"

                                                                        onClick={() => {
                                                                              openModalReport1();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt4_1&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('4.1 จำนวนองค์กรปกครองส่วนท้องถิ่นที่มีการเลือกตั้ง')
                                                                              setIndex('4_1')
                                                                        }}

                                                                  ></ListItemText>
                                                            </ListItem>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="4.2 รายชื่อองค์กรปกครองส่วนท้องถิ่นที่มีการเลือกตั้ง"
                                                                        onClick={() => {
                                                                              openModalReport1();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt4_2&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('4.2 รายชื่อองค์กรปกครองส่วนท้องถิ่นที่มีการเลือกตั้ง')
                                                                              setIndex('4_2')
                                                                        }}


                                                                  />
                                                            </ListItem>
                                                            {/* <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="จำนวนองค์กรปกครองส่วนท้องถิ่นที่มีการเลือกตั้ง แทนตำแหน่งที่ว่าง" 
                                                                   onClick={() => {
                                                                        openModalReport1();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt4_3&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('4.3 จำนวนองค์กรปกครองส่วนท้องถิ่นที่มีการเลือกตั้ง แทนตำแหน่งที่ว่าง')
                                                                        setIndex('4_3')
                                                                      }}
                                                       
                                                                  
                                                                  />
                                                            </ListItem> */}
                                                      </List>
                                                </Collapse>

                                                <ListItem button onClick={handleClick5}>
                                                      <ListItemIcon>
                                                            <MenuBookIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="5. ผู้สมัครรับเลือกตั้ง" />
                                                      {open5 ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={open5} timeout="auto" unmountOnExit>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="5.1 จำนวนผู้สมัครรับเลือกตั้ง จำแนกตามช่วงอายุ"
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt5_1_agerange&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('5.1 จำนวนผู้สมัครรับเลือกตั้ง จำแนกตามช่วงอายุ')
                                                                              setIndex('5_1')
                                                                        }} />
                                                            </ListItem>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="5.2 จำนวนผู้สมัครรับเลือกตั้ง จำแนกตามวุฒิการศึกษา"
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt5_2_education&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('5.2 จำนวนผู้สมัครรับเลือกตั้ง จำแนกตามวุฒิการศึกษา')
                                                                              setIndex('5_2')
                                                                        }} />
                                                            </ListItem>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="5.3 จำนวนผู้สมัครรับเลือกตั้ง จำแนกตามอาชีพ" onClick={() => {
                                                                        openModalReport2();
                                                                        setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt5_3_career&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('5.3 จำนวนผู้สมัครรับเลือกตั้ง จำแนกตามอาชีพ')
                                                                        setIndex('5_3')
                                                                  }} />
                                                            </ListItem>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="5.4 จำนวนผู้สมัครรับเลือกตั้ง จำแนกราย อปท." onClick={() => {
                                                                        openModalReport2();
                                                                        setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt5_4_opt&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('5.4 จำนวนผู้สมัครรับเลือกตั้ง จำแนกราย อปท.')
                                                                        setIndex('5_4')
                                                                  }} />
                                                            </ListItem>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="5.5 จำนวนผู้สมัครรับเลือกตั้ง จำแนกรายเขตเลือกตั้ง" onClick={() => {
                                                                        openModalReport2();
                                                                        setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt5_5_vote&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('5.5 จำนวนผู้สมัครรับเลือกตั้ง จำแนกรายเขตเลือกตั้ง')
                                                                        setIndex('5_5')
                                                                  }} />
                                                            </ListItem>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="5.6 รายชื่อผู้สมัครรับเลือกตั้ง จำแนกรายเขตเลือกตั้ง" onClick={() => {
                                                                        openModalReport2();
                                                                        setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt5_6&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('5.6 รายชื่อผู้สมัครรับเลือกตั้ง จำแนกรายเขตเลือกตั้ง')
                                                                        setIndex('5_6')
                                                                  }} />
                                                            </ListItem>

                                                      </List>
                                                </Collapse>
                                                <ListItem button onClick={handleClick6}>
                                                      <ListItemIcon>
                                                            <MenuBookIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="6. การใช้สิทธิเลือกตั้ง" />
                                                      {open6 ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={open6} timeout="auto" unmountOnExit>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="6.1 การใช้สิทธิเลือกตั้ง จำแนกราย อปท. "
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt6_1&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('6.1 การใช้สิทธิเลือกตั้ง จำแนกราย อปท. ')
                                                                              setIndex('6_1')
                                                                        }} />
                                                            </ListItem>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="6.2 การใช้สิทธิเลือกตั้ง จำแนกรายเขตเลือกตั้ง"
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt6_2&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('6.2 การใช้สิทธิเลือกตั้ง จำแนกรายเขตเลือกตั้ง')
                                                                              setIndex('6_2')
                                                                        }} />
                                                            </ListItem>

                                                      </List>


                                                </Collapse>
                                                <ListItem button onClick={handleClick7}>
                                                      <ListItemIcon>
                                                            <MenuBookIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="7. ผลคะแนนการเลือกตั้ง " />
                                                      {open7 ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={open7} timeout="auto" unmountOnExit>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="7.1 ผลคะแนนการเลือกตั้ง จำแนกรายเขตเลือกตั้ง "
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt7_1&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('7.1 ผลคะแนนการเลือกตั้ง จำแนกรายเขตเลือกตั้ง')
                                                                              setIndex('7_1')
                                                                        }} />
                                                            </ListItem>
                                                      </List>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="7.2 การนับคะแนนใหม่ จำแนกรายเขตเลือกตั้ง"
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt7_2&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('7.2 การนับคะแนนใหม่ จำแนกรายเขตเลือกตั้ง')
                                                                              setIndex('7_2')
                                                                        }} />
                                                            </ListItem>
                                                      </List>
                                                </Collapse>
                                                <ListItem button onClick={handleClick8}>
                                                      <ListItemIcon>
                                                            <MenuBookIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="8. ผู้ได้รับเลือกตั้ง" />
                                                      {open8 ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={open8} timeout="auto" unmountOnExit>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="8.1 จำนวนผู้ได้รับเลือกตั้ง จำแนกตามช่วงอายุ "
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt8_1_agerange&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('8.1 จำนวนผู้ได้รับเลือกตั้ง จำแนกตามช่วงอายุ ')
                                                                              setIndex('8_1')
                                                                        }} />
                                                            </ListItem>
                                                      </List>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="8.2 จำนวนผู้ได้รับเลือกตั้ง จำแนกตามวุฒิการศึกษา"
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt8_2_education&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('8.2 จำนวนผู้ได้รับเลือกตั้ง จำแนกตามวุฒิการศึกษา')
                                                                              setIndex('8_2')
                                                                        }} />

                                                            </ListItem>
                                                      </List>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="8.3 จำนวนผู้ได้รับเลือกตั้ง จำแนกตามอาชีพ"
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt8_3_career&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('8.3 จำนวนผู้ได้รับเลือกตั้ง จำแนกตามอาชีพ')
                                                                              setIndex('8_3')
                                                                        }} />

                                                            </ListItem>
                                                      </List>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="8.4 จำนวนผู้ได้รับเลือกตั้ง จำแนกราย อปท."
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt8_4_opt&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('8.4 จำนวนผู้ได้รับเลือกตั้ง จำแนกราย อปท.')
                                                                              setIndex('8_4')
                                                                        }} />

                                                            </ListItem>
                                                      </List>

                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="8.5 จำนวนผู้ได้รับเลือกตั้ง จำแนกรายเขตเลือกตั้ง"
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt8_5_vote&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('8.5 จำนวนผู้ได้รับเลือกตั้ง จำแนกรายเขตเลือกตั้ง')
                                                                              setIndex('8_5')
                                                                        }} />

                                                            </ListItem>
                                                      </List>

                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="8.6 รายชื่อผู้ได้รับเลือกตั้ง จำแนกรายเขตเลือกตั้ง"
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2Freports%2Fopt%2Fopt_rpt8_6&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('8.6 รายชื่อผู้ได้รับเลือกตั้ง จำแนกรายเขตเลือกตั้ง')
                                                                              setIndex('8_6')
                                                                        }} />

                                                            </ListItem>
                                                      </List>
                                                      {/* <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="8.5 รายชื่อผู้ได้รับเลือกตั้งรายเขตเลือกตั้ง" 
                                                                   onClick={() => {
                                                                        openModalReport2();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt8_5&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('8.5 รายชื่อผู้ได้รับเลือกตั้งรายเขตเลือกตั้ง')
                                                                        setIndex('8_5')
                                                                      }}/> 
                                                                      
                                                            </ListItem>
                                                        
                                                      </List> */}
                                                </Collapse>

                                                <ListItem button onClick={handleClick9}>
                                                      <ListItemIcon>
                                                            <MenuBookIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="9. แบบรายงานตามกฎหมายหรือระเบียบที่เกี่ยวข้อง" />
                                                      {open9 ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={open9} timeout="auto" unmountOnExit>

                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="9.1 แบบ ส.ถ./ผ.ถ. 4/4 ประกาศบัญชีรายชื่อผู้สมัครรับเลือกตั้งที่ได้รับสมัครรับเลือกตั้งและไม่ได้รับสมัครรับเลือกตั้ง"
                                                                        onClick={() => {
                                                                              openModalReport3();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_1_44_Grouping&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('9.1 แบบ ส.ถ./ผ.ถ. 4/4 ประกาศบัญชีรายชื่อผู้สมัครรับเลือกตั้งที่ได้รับสมัครรับเลือกตั้งและไม่ได้รับสมัครรับเลือกตั้ง')
                                                                              setIndex('9_1')
                                                                        }} />

                                                            </ListItem>
                                                      </List>

                                                      {/*                                                     
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="'9.2 แบบ ส.ถ./ผ.ถ. 5/7 รายงานผลการนับคะแนนเลือกตั้ง" 
                                                                   onClick={() => {
                                                                        openModalReport3();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_2_57_Grouping&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('9.2 แบบ ส.ถ./ผ.ถ.  5/7 รายงานผลการนับคะแนนเลือกตั้ง')
                                                                        setIndex('9_2')
                                                                      }}/> 
                                                                      
                                                            </ListItem>
                                                      </List>
                                                    

                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="9.3 แบบ ส.ถ./ผ.ถ. 5/7(น) รายงานผลการนับคะแนนเลือกตั้ง (กรณีนับคะแนนเลือกตั้งใหม่)" 
                                                                   onClick={() => {
                                                                        openModalReport3();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_3_57n_Grouping&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('9.3 แบบ ส.ถ./ผ.ถ. 5/7(น) รายงานผลการนับคะแนนเลือกตั้ง (กรณีนับคะแนนเลือกตั้งใหม่) ')
                                                                        setIndex('9_3')
                                                                      }}/> 
                                                                      
                                                            </ListItem>
                                                      </List>



                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="9.4 แบบ ส.ถ./ผ.ถ. 6/1 ประกาศผลการเลือกตั้ง" 
                                                                   onClick={() => {
                                                                        openModalReport3();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_4_61_Grouping&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('9.4 แบบ ส.ถ./ผ.ถ. 6/1 ประกาศผลการเลือกตั้ง')
                                                                        setIndex('9_4')
                                                                      }}/> 
                                                                      
                                                            </ListItem>
                                                      </List> 
                                                       <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="9.5 แบบ ส.ถ./ผ.ถ. 6/1 (น) ประกาศผลการเลือกตั้ง (กรณีนับคะแนนเลือกตั้งใหม่)" 
                                                                   onClick={() => {
                                                                        openModalReport3();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_5_61n_Grouping&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('9.5 แบบ ส.ถ./ผ.ถ. 6/1 (น) ประกาศผลการเลือกตั้ง (กรณีนับคะแนนเลือกตั้งใหม่) ')
                                                                        setIndex('9_5')
                                                                      }}/> 
                                                                      
                                                            </ListItem>
                                                      </List>

                                                          <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="9.6 แบบ ส.ถ. 6/2 ประกาศผลการเลือกตั้ง (บางส่วน)" 
                                                                   onClick={() => {
                                                                        openModalReport3();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_6_62_Grouping&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('9.6 แบบ ส.ถ. 6/2 ประกาศผลการเลือกตั้ง (บางส่วน)')
                                                                        setIndex('9_6')
                                                                      }}/> 
                                                                      
                                                            </ListItem>
                                                      </List>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="9.7 แบบ ส.ถ./ผ.ถ. 6/3 ประกาศผลการเลือกตั้ง (เพิ่มเติม)" 
                                                                   onClick={() => {
                                                                        openModalReport3();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_7_63_Grouping&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('9.7 แบบ ส.ถ./ผ.ถ. 6/3 ประกาศผลการเลือกตั้ง (เพิ่มเติม)')
                                                                        setIndex('9_7')
                                                                      }}/> 
                                                                      
                                                            </ListItem>
                                                      </List>

                                                          <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="9.8 แบบส.ถ./ผ.ถ.6/4 หนังสือแจ้งผลการได้รับเลือกตั้ง" 
                                                                   onClick={() => {
                                                                        openModalReport3();
                                                                        setChild(REPORTING_SERVICE+'/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_8_64_Grouping&rs:Command=Render&rc:Parameters=Collapsed')
                                                                        setTitle('9.8 แบบส.ถ./ผ.ถ.6/4 หนังสือแจ้งผลการได้รับเลือกตั้ง')
                                                                        setIndex('9_8')
                                                                      }}/> 
                                                                      
                                                            </ListItem>
                                                      </List>*/}
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="9.2 แบบ ส.ถ./ผ.ถ. 6/4 หนังสือแจ้งผลการได้รับเลือกตั้ง"
                                                                        onClick={() => {
                                                                              openModalReport3();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_8_64_Grouping&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('9.2 แบบ ส.ถ./ผ.ถ. 6/4 หนังสือแจ้งผลการได้รับเลือกตั้ง')
                                                                              setIndex('9_8')
                                                                        }} />

                                                            </ListItem>
                                                      </List>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="9.3 แบบ ส.ถ./ผ.ถ. 6/5 ทะเบียนผู้ได้รับการเลือกตั้ง"
                                                                        onClick={() => {
                                                                              openModalReport3();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_9_65_Grouping&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('9.3 แบบ ส.ถ./ผ.ถ. 6/5 ทะเบียนผู้ได้รับการเลือกตั้ง ')
                                                                              setIndex('9_9')
                                                                        }} />

                                                            </ListItem>
                                                      </List>

                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="9.4 แบบสรุปผลมติที่ประชุม กกต.จว. (แบบ3)"
                                                                        onClick={() => {
                                                                              openModalReport3();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_frm9_10_3&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('9.4 แบบสรุปผลมติที่ประชุม กกต.จว. (แบบ3)')
                                                                              setIndex('9_10')
                                                                        }} />

                                                            </ListItem>
                                                      </List>

                                                      {/* <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="แบบรายงานที่ สนง.กกต. สั่งการ" component = "a" href = "http://ectpl-be.scd-dga.com/ReportServer/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt_frm3&rs:Command=Render" />
                                                            </ListItem>
                                                      </List> */}
                                                </Collapse>





                                                <ListItem button>
                                                      <ListItemIcon>
                                                            <MenuBookIcon />
                                                      </ListItemIcon>
                                                      <ListItemText primary="10. Export ข้อมูล" />
                                                      {open ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={open} timeout="auto" unmountOnExit>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="10.1 ข้อมูลพื้นฐาน อปท."
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt10_1&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('10.1 ข้อมูลพื้นฐาน อปท.')
                                                                              setIndex('10_1')
                                                                        }} />
                                                            </ListItem>
                                                      </List>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="10.2 ข้อมูลผู้สมัคร/ผู้ได้รับเลือก/ผลคะแนน"
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt10_2&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('10.2 ข้อมูลผู้สมัคร/ผู้ได้รับเลือก/ผลคะแนน')
                                                                              setIndex('10_2')
                                                                        }} />

                                                            </ListItem>
                                                      </List>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="10.3  ข้อมูลการใช้สิทธิเลือกตั้ง จำแนกราย อปท. "
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt10_3&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('10.3  ข้อมูลการใช้สิทธิเลือกตั้ง จำแนกราย อปท. ')
                                                                              setIndex('10_3')
                                                                        }} />

                                                            </ListItem>
                                                      </List>
                                                      <List component="div" disablePadding>
                                                            <ListItem button className={classes.nested}>
                                                                  <ListItemIcon>
                                                                        <NavigateNextIcon />
                                                                  </ListItemIcon>
                                                                  <ListItemText primary="10.4 ข้อมูลการใช้สิทธิเลือกตั้ง จำแนกรายเขตเลือกตั้ง"
                                                                        onClick={() => {
                                                                              openModalReport2();
                                                                              setChild(REPORTING_SERVICE + '/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt10_4&rs:Command=Render&rc:Parameters=Collapsed')
                                                                              setTitle('10.4 ข้อมูลการใช้สิทธิเลือกตั้ง จำแนกรายเขตเลือกตั้ง')
                                                                              setIndex('10_4')
                                                                        }} />

                                                            </ListItem>
                                                      </List>
                                                </Collapse>


                                          </List>
                                    </GridItem>
                              </GridContainer>
                        </CardBody>

                  </Card>

            </>
      );
};

export default RptSummaryOpt;