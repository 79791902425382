import { useEffect } from "react";

export function Validate(value, required, setValid) {
  useEffect(() => {
    if ((value === "" || value === 0) && required) {
      return setValid(true);
    }
    if (value !== 0 && required) {
      return setValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
}

export default Validate;
// export default function Validate(value, required, setValid) {
//   return _Validate(value, required, setValid);
// }
