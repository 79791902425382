import React, { useEffect, useState } from "react";
import {
  addVoteDate,
  delVoteDate,
  editVoteDate,
  getPosition,
  getVoteDate
} from "contexts/API/INSPEC/VoteDate";

import ConvertDate from "utils/ConvertDate";
//import CustomInput from "components/CustomInput/CustomInput.js";
import DateVoteDate from "components/Common/Datepicker";
import HandelError from "utils/HandleError";
import ListboxInspecVotetype from "components/Common/ListboxInspecVotetype";
import ListboxPositionInspect from "components/Common/ListboxPositionInspect";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import { getVoteType } from "contexts/API/OPT/Setting";
import { useSweetAlert } from "components/Alert/SweetAlert";

const AddVoteDate = props => {
  const { sweetAlerts } = useSweetAlert();
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [voteTypeList, setVoteTypeList] = useState([]);
  //let positionList = "";
  const optionsTable = { pageSize: "10" };

  useEffect(() => {
    let positionList = "";
    const loadData = async () => {
      try {
        setLoading(true);
        const res = await getVoteDate();
        const vote = await getVoteType();
        const position = await getPosition();
        //  setPositionStatusList(position.data.data);
        const _vote = vote.data.data.filter(e => e.voteTypeId !== 3);

        // voteList.filter(e => e.id !== 3);
        //setVoteTypeList(_vote);
        setVoteTypeList(vote);

        setData(res.data.data);
        const columns = [
          {
            title: "inspectorVotedateId",
            field: "inspectorVotedateId",
            hidden: true
          },
          {
            title: "วันที่เลือกตั้ง",
            field: "voteDate",
            render: rowData => {
              return ConvertDate(rowData.voteDate);
              /* return (
                                <CustomInput
                                    // value={props.remarkMem}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: ConvertDate(rowData.voteDate),
                                        disabled: true

                                        //  inputRef: props.memRemarkRef
                                    }}
                                />
                            ); */
            },
            editComponent: props => {
              const dateValue = props.rowData.voteDate
                ? props.rowData.voteDate
                : null;
              return (
                <DateVoteDate
                  label="วันที่เลือกตั้ง"
                  value={dateValue}
                  onChange={date =>
                    props.onRowDataChange({
                      ...props.rowData,
                      voteDate: date
                    })
                  }
                />
              );
            }
          },
          {
            title: "ตำแหน่งที่จัดเลือกตั้ง",
            field: "inspectorPositionTypeId",
            cellStyle: {
              minWidth: "150px"
            },
            render: rowData => {
              positionList = position.data.data.map(t => {
                return {
                  id: t.inspectorPositionTypeId,
                  value: t.positionTypeName
                };
              });

              const t = positionList.find(
                p => p.id === rowData.inspectorPositionTypeId
              );
              return t !== undefined ? t.value : "";
            },
            /* render: rowData => {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            positionList = position.data.data.map(t => {
                                return {
                                    id: t.inspectorPositionTypeId,
                                    value: t.positionTypeName
                                };
                            });

                            const t = positionList.find(
                                p => p.id === rowData.inspectorPositionTypeId
                            );

                            return (
                                <CustomInput
                                    // value={props.remarkMem}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: t.value,
                                        disabled: true

                                        //  inputRef: props.memRemarkRef
                                    }}
                                />
                            );
                        },*/
            editComponent: props => {
              return (
                <ListboxPositionInspect
                  defaultValue={{
                    id: props.rowData.inspectorPositionTypeId
                  }}
                  data={positionList}
                  onChange={(i, e, v) => {
                    if (e !== null) {
                      props.rowData.inspectorPositionTypeId = e.id;
                    }
                  }}
                />
              );
            }
          },
          {
            title: "ประเภทการเลือกตั้ง",
            field: "inspectorVoteTypeId",
            cellStyle: {
              minWidth: "250px"
            },
            render: rowData => {
              let voteList = vote.data.data.map(v => {
                return { id: v.voteTypeId, value: v.detail };
              });
              //const newArr = voteList.filter(e => e.id !== 3);
              const newArr = voteList;

              const v = newArr.find(
                e => e.id === parseInt(rowData.inspectorVoteTypeId)
              );
              return v !== undefined ? v.value : "";
            },
            /*  render: rowData => {
                            let voteList = vote.data.data.map(v => {
                                return { id: v.voteTypeId, value: v.detail };
                            });
                            const newArr = voteList.filter(e => e.id !== 3);

                            const v = newArr.find(
                                e => e.id === parseInt(rowData.inspectorVoteTypeId)
                            );
                            //  return v !== undefined ? v.value : "";
                            return (
                                <CustomInput
                                    // value={props.remarkMem}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: v.value,
                                        disabled: true

                                        //  inputRef: props.memRemarkRef
                                    }}
                                />
                            );
                        }, */

            editComponent: props => {
              return (
                <ListboxInspecVotetype
                  defaultValue={{
                    id: props.rowData.inspectorVoteTypeId
                  }}
                  data={voteTypeList}
                  onChange={(i, e, v) => {
                    if (e !== null) {
                      props.rowData.inspectorVoteTypeId = e.id;
                    }
                  }}
                />
              );
            }
          },
          {
            title: "จำนวนจังหวัดที่บันทึกผลการจับสลาก",
            field: "NumOfRecord",
            editable: "never",
            cellStyle: {
              minWidth: "250px",
              textAlign: "center"
            },
            headerStyle: {
              minWidth: "250px",
              textAlign: "center"
            }
          }
        ];
        setColumns(columns);
        setLoading(false);
      } catch (error) {
        HandelError(error);
        setLoading(false);
      }
    };
    loadData();
  }, []);

  return loading ? (
    <LoadingData />
  ) : (
    <MaterialTable
      title={"บันทึกวันเลือกตั้ง"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              addVoteDate(
                newData.voteDate,
                newData.inspectorVoteTypeId,
                newData.inspectorPositionTypeId
              )
                .then(result => {
                  //   setData([...data, newData]);
                  setData([newData, ...data]);
                  sweetAlerts.success("บันทึกข้อมูลเรียบร้อยแล้ว");
                  newData.inspectorVotedateId = result.data.data.voteDateId;
                  newData.NumOfRecord = result.data.data.numOfRecord;
                })
                .catch(err => {
                  HandelError(err);
                  sweetAlerts.error(
                    "ไม่สามารถเพิ่มข้อมูลได้",
                    err.response.data.desc
                  );
                });

              resolve();
            }, 1000);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            if (
              oldData.voteDate === newData.voteDate &&
              oldData.inspectorVoteTypeId === newData.inspectorVoteTypeId &&
              oldData.inspectorPositionTypeId ===
              newData.inspectorPositionTypeId
            ) {
              sweetAlerts.warning("คุณยังไม่ได้ทำการระบุข้อมูลที่ต้องการแก้ไข");
              resolve();
              return;
            }

            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;

              editVoteDate(
                oldData.inspectorVotedateId,
                newData.voteDate,
                newData.inspectorVoteTypeId,
                newData.inspectorPositionTypeId
              )
                .then(result => {
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);

                  sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");

                  //  setData([...dataUpdate]);
                })
                .catch(err => {
                  HandelError(err);

                  sweetAlerts.error(
                    "ไม่สามารถแก้ไขข้อมูลได้",
                    err.response.data.desc
                  );
                });

              resolve();
            }, 1000);
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              delVoteDate(oldData.inspectorVotedateId)
                .then(result => {
                  setData([...dataDelete]);
                  sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");

                  //  setData([...dataUpdate]);
                })
                .catch(err => {
                  HandelError(err);
                  sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
                });

              resolve();
            }, 1000);
          })
      }}
    />
  );
};
export default AddVoteDate;
