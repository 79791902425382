import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
/* core components */
import React from "react";
//import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxOptTitleList = props => {

  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  let endPoint = "?sysId=[0,1]";
  /* if (props.sysId) {
    endPoint += `sysId=${props.sysId}`;
  }
 */
  const [isLoading, fetchData] = useFetch(conf.END_POINT_URL + "/ms/titlelist" + endPoint, []);


  let content = (
    <TextField disabled label="เลือกคำนำหน้าชื่อ" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return { id: v.titleId, value: v.titleShortName };
    });
    if (props.controll) {
      data.push({ id: 0, value: "ไม่ระบุ" });
    }
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        disabled={props.disabled}
        id="listboxOptTitleList"
        options={data}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={props.title ? props.title : "เลือกคำนำหน้าชื่อ"}
              margin="normal"
              error={error}
              fullWidth
              helperText={helperText}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }

  return content;
};
export default ListboxOptTitleList;
