import React, { useState, useContext, useEffect } from "react";

/* API */
//import { getSearchLists } from "contexts/API/VoteCard/Publish";
import { getSearchDetailInfoLists, addVoteCardAllocate, updateVoteCardAllocate } from "contexts/API/VoteCard/Allocate";
import { updateVoteCardPurchaseDetailStatus } from "contexts/API/VoteCard/Purchase";

import LoadingData from "components/Common/LoadingData";
import CardHeader from "components/Card/CardHeader.js";
import TableComponent from "components/Table/MaterialTable";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import TextField from "@material-ui/core/TextField";
import ListboxVotecardNumber from "components/Common/ListboxVotecardNumber";
import ListboxVotecardColor from "components/Common/ListboxVotecardColor";
import ListboxVotecardPrinting from "components/Common/ListboxVotecardPrinting";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.js";
import { VoteDateContext } from "contexts/VoteDate";
const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalallocateUpdate = props => {
  const [loading, setLoading] = useState(false);
  const {
    votecardNumberSelected,
    onVotecardNumberSelected,
    votecardPrintingSelected,
    onVotecardPrintingSelected,
    votecardColorSelected, //สีบัตร
    onVotecardColorSelected,
  } = useContext(VoteDateContext);

  const { showModaladdCardallocate, closeModaladdCardallocate, areaAllocate } = props;
  //const [clickSearch, setClickSearch] = useState(false);
  const { sweetAlerts } = useSweetAlert();

  const classes = useStyles();

  /*  const [inUse, setInUse] = useState(0);
   const handleChangedInputNumber = (id, e, v) => {
     setInUse(v);
   }; */
  const [columns] = useState([
    { title: "โรงพิมพ์", field: "PrintHouseName", editable: "never" },
    {
      title: "สี",
      field: "VoteCardColorName",
      editable: "never",
      cellStyle: {
        width: "200px",
        textAlign: "left"
      },
      headerStyle: {
        width: "200px",
        textAlign: "left"
      }
    },
    {
      title: "หมายเลข",
      field: "",
      editable: "never",
      cellStyle: {
        width: "200px",
        textAlign: "left"
      },
      headerStyle: {
        width: "200px",
        textAlign: "left"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.VoteCardNumberStart) + ' - ' + new Intl.NumberFormat().format(rowData.VoteCardNumberEnd);
      }
    },
    {
      title: "ตั้งแต่เล่มที่ - ถึงเล่มที่",
      field: "VoteCardNumberName",
      editable: "never",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
    },

    {
      title: "คงเหลือ",
      field: "VoteCardBalanceAmount",
      editable: "never",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
    },
    {
      title: "จำนวนที่ใช้",
      field: "VoteCardDisburse",
      type: "numeric",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
    }
  ]);
  const [data, setData] = useState([]);
  const optionsTable = { exportButton: false, paging: true };


  useEffect(() => {
    const optQueryUrl = () => {
      let endPoint = "?";

      if (votecardPrintingSelected !== null) {
        endPoint += `PrintHouseID=${votecardPrintingSelected.id}&`;
      }

      if (votecardColorSelected !== null) {
        endPoint += `CardColorID=${votecardColorSelected.id}&`;
      }

      if (votecardNumberSelected !== null) {
        endPoint += `CardNo=${votecardNumberSelected.value}&`;
      }

      return endPoint;
    };

    getSearchDetailInfoLists(optQueryUrl(), '')
      .then(result => {
        setData(result.data.data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
      });
  }, [sweetAlerts, votecardColorSelected, votecardNumberSelected, votecardPrintingSelected]);

  return (
    <div className={showModaladdCardallocate ? "modal" : "hide"}>
      <Dialog
        open={showModaladdCardallocate}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModaladdCardallocate}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>
              แก้ไขรายการบัตรเลือกตั้งที่ต้องการจัดสรร
            </h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <ListboxVotecardPrinting
                value={votecardPrintingSelected}
                onChange={onVotecardPrintingSelected}
              ></ListboxVotecardPrinting>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <ListboxVotecardColor
                votecardPrintingSelected={votecardPrintingSelected}
                value={votecardColorSelected}
                onChange={onVotecardColorSelected}
              ></ListboxVotecardColor>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <ListboxVotecardNumber
                votecardPrintingSelected={votecardPrintingSelected}
                votecardColorSelected={votecardColorSelected}
                value={votecardNumberSelected}
                onChange={onVotecardNumberSelected}
              ></ListboxVotecardNumber>
            </GridItem>
          </GridContainer>
          <br></br>
          <hr></hr>
          {loading ? (
            <LoadingData />
          ) : (
            <TableComponent
              title="รายการจัดสรรบัตรเลือกตั้ง"
              columns={columns}
              data={data}
              localization={{}}
              options={optionsTable}
              components={{
                Container: props => <Paper {...props} elevation={0} />
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...data];
                      const index = oldData.tableData.id;

                      const body = {
                        voteCardAllocateID: newData.DataKeyAllocate,
                        voteCardPurchaseDetailID: areaAllocate.VoteCardPurchaseDetailID,
                        voteCardPublishDetailID: newData.DataKey,
                        startVolumnNo: newData.VoteCardNumberStart,
                        endVolumnNo: newData.VoteCardNumberEnd,
                        volumnAllocate: newData.VoteCardDisburse,
                        allocateDateTime: '',
                        Remark: ''
                      };

                      if (newData.DataKeyAllocate < 1) {
                        addVoteCardAllocate(body)
                          .then(r => {
                            newData.DataKeyAllocate = r.voteCardAllocateId;
                            newData.VoteCardBalanceAmount = (oldData.VoteCardBalanceAmount + oldData.VoteCardDisburse) - newData.VoteCardDisburse;

                            sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
                            updateVoteCardPurchaseDetailStatus({ voteCardPurchaseDetailID: areaAllocate.VoteCardPurchaseDetailID, voteCardPurchaseStatusID: 4 }).then(result => {
                              sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
                              resolve();
                            }).catch(err => {
                              sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
                            });
                          })
                          .catch(error => {
                            if (error.response) {
                              sweetAlerts.error(
                                "ไม่สามารถบันทึกข้อมูล : ", error.response.data.desc
                              );
                            }
                          });
                      } else {
                        updateVoteCardAllocate(body)
                          .then(r => {
                            newData.VoteCardBalanceAmount = (oldData.VoteCardBalanceAmount + oldData.VoteCardNotUsed) - newData.VoteCardNotUsed;
                            sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
                          })
                          .catch(error => {
                            if (error.response) {
                              sweetAlerts.error(
                                "ไม่สามารถบันทึกข้อมูล : ", error.response.data.desc
                              );
                            }
                          });
                      }

                      console.log(newData);
                      dataUpdate[index] = newData;
                      setData([...dataUpdate]);

                      resolve();
                    }, 1000);
                  })
              }}
            />
          )}
          <hr></hr>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={closeModaladdCardallocate}>ปิด</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalallocateUpdate;
