import React, {useContext} from "react";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/user-login-screen.png";

import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";

import {Dialog} from "@material-ui/core";
import Slide from "material-auto-rotating-carousel/lib/Slide";
import {ChangeUser, ConfirmPassword, Password, ShowPassword, WrongUserOrPassword} from "../../Constants/strings";
import {UserInfoContext} from "../../contexts/users";
import {Redirect} from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {auth} from "../../contexts/API/Login";
import getTokenInfo from "../../utils/Token";
import Cookies from "universal-cookie";
import {useSweetAlert} from "components/Alert/SweetAlert";

const useStyles = makeStyles(styles);

export default function LockScreenPage() {
    const cookies = new Cookies();
    const {sweetAlerts} = useSweetAlert();
    const {userInfo, isExpired, canReLogin, setUserInfo, logout} = useContext(UserInfoContext);
    const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
    const [isVisiblePassword, setIsVisiblePassword] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [modal, setModal] = React.useState(true);


    let userName = "";
    if (userInfo && userInfo.tokenInfo && userInfo.tokenInfo.data && userInfo.tokenInfo.data.username) {
        userName = userInfo.tokenInfo.data.username;
    }

    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    const classes = useStyles();

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });


    /* Functions */
    const onChangeUser = () => {
        logout();
        return <Redirect to="/login"/>
    }

    const onHandleClickShowPassword = () => {
        setIsVisiblePassword(!isVisiblePassword);
    };

    const onClickLogin = () => {
        auth(userName, password)
            .then(resp => {
                if (
                    resp.status === 200 &&
                    resp.data.data !== undefined &&
                    resp.data.data.token !== undefined
                ) {
                    let token = resp.data.data.token;
                    let pageIds = resp.data.data.pageIds;
                    let tokenInfo = getTokenInfo(token);

                    const usrInfo = {
                        pageIds: pageIds,
                        token: token,
                        tokenInfo: tokenInfo,
                        provinceId: resp.data.data.provinceId,
                        provinceName: resp.data.data.provinceName,
                        iscenter: resp.data.data.iscenter,
                        isfirstchangepassword: resp.data.data.isfirstchangepassword,
                        isadminwtd: resp.data.data.iscenter
                    };

                    cookies.set("userInfo", usrInfo, {path: "/"});
                    setUserInfo(usrInfo);
                } else {
                    alert("Authentication failed");
                }
            })
            .catch(err => {
                sweetAlerts.warning(
                    WrongUserOrPassword,
                    err.response.data.desc
                );
            });
    };

    return (
        isExpired() && canReLogin() ? <div className={classes.container}>
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={modal}
                transition={Transition}
                keepMounted
                onClose={() => setModal(false)}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                disableBackdropClick={true}
            >
                <Card
                    profile
                    className={classes.customCardClass + " " + classes[cardAnimation]}
                >
                    <CardAvatar profile className={classes.cardAvatar}>
                        <a href="#" onClick={e => e.preventDefault()}>
                            <img src={avatar} alt="..."/>
                        </a>
                    </CardAvatar>
                    <CardBody profile>
                        <form>
                            <h4 className={classes.cardTitle}>{userName}</h4>
                            <CustomInput
                                labelText={Password}
                                id="password-re-login"
                                formControlProps={{fullWidth: true}}
                                inputProps={{
                                    type: isVisiblePassword ? "text" : "password",
                                    autoComplete: "off",
                                    value: password,
                                    onChange: (e) => {
                                        setPassword(e.target.value)
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={ShowPassword}
                                                onClick={onHandleClickShowPassword}
                                                edge="end"
                                            >
                                                {isVisiblePassword ? (
                                                    <Visibility/>
                                                ) : (
                                                    <VisibilityOff/>
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </form>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                        <Button
                            color="info"
                            onClick={onClickLogin}
                        >
                            {ConfirmPassword}
                        </Button>
                        <Button
                            color="rose"
                            onClick={onChangeUser}
                        >
                            {ChangeUser}
                        </Button>
                    </CardFooter>
                </Card>

            </Dialog>
        </div> : <div/>
    );
}
