import React, { useContext, useState } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import DatepickerVotedate from "components/Common/Datepicker";
import DatepickerYear from "components/Common/DatepickerYear";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxMsAreaEct from "components/Common/ListboxMsAreaEct.js";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxOptChgStatus from "components/Common/ListboxOptChgStatus";
import Slide from "@material-ui/core/Slide";
import { VoteDateContext } from "contexts/VoteDate";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalReport01 = props => {
  const {
    areaEctSelected,

    onAreaEctSelected,

    optVoteTypeSelected,

    onOptVoteTypeSelected,
    onAmphurSelected,
    onProvinceSelected,
    provinceSelected,

    amphurSelected,
    optTypeSelected,
    onOptTypeSelected,
    getOptTypeSelectedLists,
    optChgStatusSelected,
    onOptChgStatusSelected
  } = useContext(VoteDateContext);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { sweetAlerts } = useSweetAlert();
  const [isFromValid, setIsFromValid] = useState(true);
  const [isToValid, setIsToValid] = useState(true);
  const { showModalReport1, closeModalReport1 } = props;

  const [year, setYear] = useState(null);
  const classes = useStyles();
  const handleDateChange = date => {
    let arr = date.format().split("T");
    let _date = arr[0].split("-");

    setYear(_date[0]);
  };
  const getQuery = () => {
    let endPoint = "";
    let optTypes = getOptTypeSelectedLists();
    if (["1_1"].includes(props.indexReport)) {
      endPoint += `&area_ect_id=${areaEctSelected !== null ? areaEctSelected.id : 0
        }&province_id=${provinceSelected !== null ? provinceSelected.id : 0}`;
    }
    if (["1_2"].includes(props.indexReport)) {
      endPoint += `&area_ect_id=${areaEctSelected !== null ? areaEctSelected.id : 0
        }&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&amphur_id=${amphurSelected !== null ? amphurSelected.id : 0}
      &opt_sub_type=${optTypes.length > 0 ? optTypes.toString() : 0}`;
    }
    if (["1_3"].includes(props.indexReport)) {
      endPoint += `&area_ect_id=${areaEctSelected !== null ? areaEctSelected.id : 0
        }&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&amphur_id=${amphurSelected !== null ? amphurSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate
        }&opt_chg_status=${optChgStatusSelected !== null ? optChgStatusSelected.id : 0}`;
    }
    if (["3_2", "3_1"].includes(props.indexReport)) {
      let optTypes = getOptTypeSelectedLists();

      if (optTypes.length > 0) {
        endPoint += `&opt_sub_type=${optTypes.toString()}`;
      } else {
        endPoint += `&opt_sub_type=0`;
      }
      endPoint += `&area_ect_id=${areaEctSelected !== null ? areaEctSelected.id : 0
        }&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate}`;
    }

    if (["3_3"].includes(props.indexReport)) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&year=${year === null ? new Date().getFullYear() : year}`;
    }
    /* 
    if (["4_1"].includes(props.indexReport)) {
      let optTypes = getOptTypeSelectedLists();

      endPoint += `&area_ect_id=${areaEctSelected !== null ? areaEctSelected.id : 0
        }&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate}&opt_sub_type=${optTypes.length > 0 ? optTypes.toString() : 0
        }`;
    } */
    if (["4_1", "4_2", "4_3"].includes(props.indexReport)) {
      let optTypes = getOptTypeSelectedLists();
      endPoint += `&area_ect_id=${areaEctSelected !== null ? areaEctSelected.id : 0
        }&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&opt_sub_type=${optTypes.length > 0 ? optTypes.toString() : 0
        }&vote_type=${optVoteTypeSelected !== null ? optVoteTypeSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate}`;
    }

    return endPoint;
  };
  const handleSubmitReport1 = e => {
    e.preventDefault();

    if (
      fromDate === null &&
      toDate === null &&
      ["1_3", "3_1", "3_2", "4_1", "4_2", "4_3"].includes(props.indexReport)
    ) {
      sweetAlerts.warning("กรุณาระบุช่วงวันเลือกตั้ง");
      return;
    } else {
      const query = getQuery();

      console.log(props.linkReport);
      console.log(query);
      window.open(`${props.linkReport}${query}`, "_blank");
    }
  };

  return (
    <div className={showModalReport1 ? "modal" : "hide"}>
      <Dialog
        open={showModalReport1}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModalReport1}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>{props.titleReport}</h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {["1_2", "3_1", "3_2", "4_1", "4_2", "4_3"].includes(
              props.indexReport
            ) ? (
              <GridItem xs={12} sm={12} md={12}>
                <CheckboxOpttype
                  optTypeSelected={optTypeSelected}
                  onOptTypeSelected={onOptTypeSelected}
                ></CheckboxOpttype>
              </GridItem>
            ) : (
              ""
            )}
          </GridContainer>

          {/* <GridContainer>
            {["4_2"].includes(props.indexReport) ? (
              <GridItem xs={12} sm={6} md={4}>
                <ListboxOptVotetype
                  value={optVoteTypeSelected}
                  onChange={onOptVoteTypeSelected}
                ></ListboxOptVotetype>
              </GridItem>
            ) : (
              ""
            )}
          </GridContainer> */}

          <GridContainer>
            {["4_1", "4_2", "4_3"].includes(props.indexReport) ? (
              <GridItem xs={12} sm={6} md={4}>
                <ListboxOptVotetype
                  value={optVoteTypeSelected}
                  onChange={onOptVoteTypeSelected}
                ></ListboxOptVotetype>
              </GridItem>
            ) : (
              ""
            )}
            {["1_1", "1_2", "1_3", "3_1", "3_2", "4_1", "4_2", "4_3"].includes(
              props.indexReport
            ) ? (
              <>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxMsAreaEct
                    value={areaEctSelected}
                    onChange={onAreaEctSelected}
                  ></ListboxMsAreaEct>
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <ListboxProvince
                    value={provinceSelected}
                    areaEctSelected={areaEctSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
              </>
            ) : (
              ""
            )}
            {["1_2", "1_3"].includes(props.indexReport) ? (
              // <div style={{ display: "show" }}>
              <GridItem xs={12} sm={6} md={4}>
                <ListboxDistinct
                  title={"เลือกทุกอำเภอ"}
                  provinceSelected={provinceSelected}
                  onChange={onAmphurSelected}
                ></ListboxDistinct>
              </GridItem>
            ) : (
              // </div>
              ""
            )}
          </GridContainer>
          {["1_3", "3_1", "3_2", "4_1", "4_2", "4_3"].includes(
            props.indexReport
          ) ? (
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <DatepickerVotedate
                  id="begin"
                  label="ตั้งแต่วันที่"
                  value={fromDate}
                  valid={isFromValid}
                  required={true}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setFromDate(arr[0]);
                      setIsFromValid(false);
                    }
                    setIsFromValid(true);
                  }}
                ></DatepickerVotedate>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <DatepickerVotedate
                  id="endDate"
                  label="ถึงวันที่"
                  value={toDate}
                  valid={isToValid}
                  disabled={fromDate === null ? true : false}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setToDate(arr[0]);
                      setIsToValid(false);
                    }
                    setIsToValid(true);
                  }}
                ></DatepickerVotedate>
              </GridItem>
              {["1_3"].includes(props.indexReport) ? (
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxOptChgStatus
                    value={optChgStatusSelected}
                    onChange={onOptChgStatusSelected}
                  ></ListboxOptChgStatus>
                </GridItem>
              ) : (
                ""
              )}
            </GridContainer>
          ) : (
            ""
          )}

          {["3_3"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <ListboxProvince
                  // required={true}
                  value={provinceSelected}
                  // areaEctSelected={areaEctSelected}
                  onChange={onProvinceSelected}
                ></ListboxProvince>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <DatepickerYear
                  label="เลือกปี"
                  value={year}
                  // valid={isYearValid}
                  onChange={handleDateChange}
                ></DatepickerYear>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}

          <br></br>
          <GridContainer
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem xs={12} sm={12} md={6}>
              <Button onClick={closeModalReport1}>ปิดหน้าต่าง</Button>
              <Button color="success" onClick={handleSubmitReport1}>
                ออกรายงาน
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalReport01;
