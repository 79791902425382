import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import OPT_IMPORT_PERSON from "./OptImportPerson";
import OPT_MANAGER from "./OptManager";
// import { VoteDateContextProvider } from "contexts/VoteDate";
//import { VoteDateContext } from "contexts/VoteDate";
import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";

//styles
const styles = {
  textCenter: {
    textAlign: "center"
  },
  //tabRoot: { backgroundColor: "#0a6" },
  span: {
    backgroundColor: "#aa0",
    fontSize: "22px"
  }
};

const useStyles = makeStyles(styles);
const OptPerson = ({ pageProps, setPageProps }) => {
  const classes = useStyles();
  return (
    // <VoteDateContextProvider>
    <div className={classes.tabcustom}>
      <CustomTabs
        styles={customTabsStyles}
        headerColor="primary"
        tabs={[
          {
            tabName: "จัดการข้อมูลผู้สมัคร",
            tabContent: (
              <OPT_MANAGER pageProps={pageProps} setPageProps={setPageProps} />
            )
            //   tabContent: <OPT_MANAGER setActive={setActive} setPageProps({ active: "MangeVoteDate", props: null }); />
          },
          {
            tabName: "นำข้อมูลเข้าจากไฟล์",
            tabContent: (
              <OPT_IMPORT_PERSON
                pageProps={pageProps}
                setPageProps={setPageProps}
              />
            )
          }
        ]}
      />
    </div>
    // </VoteDateContextProvider>
  );
};
export default OptPerson;
