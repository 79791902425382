import React, { useContext, useEffect, useRef, useState } from "react";
/* API */
import {
  deleteVoteCardPublishDetail,
  getSearchLists
} from "contexts/API/VoteCard/Publish";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ConvertDate from "utils/ConvertDate";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LinkDownload from "components/Common/LinkDownload";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxVotecardColor from "components/Common/ListboxVotecardColor";
import ListboxVotecardNumber from "components/Common/ListboxVotecardNumber";
import ListboxVotecardOptType from "components/Common/ListboxVotecardOptType";
import ListboxVotecardPrinting from "components/Common/ListboxVotecardPrinting";
/* Material Components */
import LoadingData from "components/Common/LoadingData";
import ModaladdCardPublish from "./ModaladdCardPublish";
import TableComponent from "components/Table/MaterialTable";
import { VoteDateContext } from "contexts/VoteDate";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import style from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { useSnackBar } from "components/Alert/Snackbar";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles(style);
const VoteCardPublish = ({ pageProps, setPageProps }) => {
  const [loading, setLoading] = useState(false);
  const [, setClickSearch] = useState(false);
  const { snackBars } = useSnackBar();
  const { sweetAlerts } = useSweetAlert();
  const [showModaladdCard, setshowModaladdCard] = useState(false);

  const {
    votecardPrintingSelected, //โรงพิมพ์
    setVotecardPrintingSelected,
    onVotecardPrintingSelected,
    votecardNumberSelected, //หมายเลขบัตร
    setVotecardNumberSelected,
    onVotecardNumberSelected,
    votecardOptTypeSelected, //ประเภท อปท.
    setVotecardOptTypeSelected,
    onVotecardOptTypeSelected,
    positionTypeSelected, //ตำแหน่ง
    setPostionTypeSelected,
    onPostionTypeSelected,
    votecardColorSelected, //สีบัตร
    setVotecardColorSelected,
    onVotecardColorSelected
  } = useContext(VoteDateContext);

  const classes = useStyles();
  const optionsTable = { exportButton: true, paging: true };
  const columns = [
    {
      title: "โรงพิมพ์",
      field: "PrintHouseName",
      cellStyle: {
        width: "200px",
        textAlign: "center"
      },
      headerStyle: {
        width: "200px",
        textAlign: "center"
      }
    },
    {
      title: "ประเภท อปท.",
      field: "VoteCardOptTypeName",
      cellStyle: {
        width: "200px",
        textAlign: "left"
      },
      headerStyle: {
        width: "200px",
        textAlign: "left"
      }
    },
    {
      title: "ตำแหน่ง",
      field: "PositionTypeDetail",
      cellStyle: {
        width: "150px",
        textAlign: "center"
      },
      headerStyle: {
        width: "150px",
        textAlign: "center"
      }
    },
    {
      title: "สี",
      field: "VoteCardColorName",
      cellStyle: {
        width: "70px",
        textAlign: "center"
      },
      headerStyle: {
        width: "70px",
        textAlign: "center"
      }
    },
    {
      title: "ชนิดหมายเลข",
      field: "VoteCardNumberName",
      cellStyle: {
        width: "70px",
        textAlign: "center"
      },
      headerStyle: {
        width: "70px",
        textAlign: "center"
      }
    },
    {
      title: "หมวด",
      field: "Category",
      cellStyle: {
        width: "70px",
        textAlign: "center"
      },
      headerStyle: {
        width: "70px",
        textAlign: "center"
      }
    },
    {
      title: "ครั้งที่",
      field: "LotNumber",
      cellStyle: {
        width: "70px",
        textAlign: "center"
      },
      headerStyle: {
        width: "70px",
        textAlign: "center"
      }
    },
    {
      title: "เลขที่เริ่มต้น",
      field: "VoteCardNumberStart",
      cellStyle: {
        width: "70px",
        textAlign: "center"
      },
      headerStyle: {
        width: "70px",
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.VoteCardNumberStart);
      }
    },
    {
      title: "เลขที่สิ้นสุด",
      field: "VoteCardNumberEnd",
      cellStyle: {
        width: "70px",
        textAlign: "center"
      },
      headerStyle: {
        width: "70px",
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.VoteCardNumberEnd);
      }
    },
    {
      title: "จำนวนเล่ม",
      field: "VoteCardTotalAmount",
      cellStyle: {
        width: "70px",
        textAlign: "center"
      },
      headerStyle: {
        width: "70px",
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(
          rowData.VoteCardNumberEnd - rowData.VoteCardNumberStart + 1
        );
      }
    },
    /*{
      title: "จำนวนฉบับ",
      field: "VoteCardTotalAmount",
      cellStyle: {
        width: "70px",
        textAlign: "center"
      },
      headerStyle: {
        width: "70px",
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.VoteCardTotalAmount);
      }
    },*/
    {
      title: "เลขที่หนังสือสั่งพิมพ์",
      field: "VoteCardPubishDocumentNo",
      cellStyle: {
        width: "150px",
        textAlign: "center"
      },
      headerStyle: {
        width: "150px",
        textAlign: "center"
      }
    },
    {
      title: "วันที่หนังสือ",
      field: "VoteCardPubishDocumentDate",
      cellStyle: {
        width: "150px",
        textAlign: "center"
      },
      headerStyle: {
        width: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.VoteCardPubishDocumentDate);
      }
    },
    {
      title: "เอกสารที่เกี่ยวข้อง",
      field: "VoteCardPubishFileUUID",
      cellStyle: {
        minWidth: "90px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.VoteCardPubishFileUUID ? (
          <LinkDownload
            url={getFileDownloadUrl("votecard", rowData.VoteCardPubishFileUUID)}
            fileId={rowData.VoteCardPubishFileUUID}
            fileName={rowData.VoteCardPubishFileName}
          />
        ) : (
          ""
        );
      }
    },
    {
      title: "",
      field: "",
      cellStyle: {
        minWidth: "100px"
      },
      render: rowData => {
        return (
          <>
            {/*
            <Button
              size="sm"
              color="info"
              onClick={() => {
                const dataView = [...data];
                const index = rowData.tableData.id;
                dataView.splice(index, 1);

                onClickView(rowData);
              }}
            >
              รายละเอียด
            </Button>
            <Button
              size="sm"
              color="warning"
              onClick={() => {
                const dataEdit = [...data];
                const index = rowData.tableData.id;
                dataEdit.splice(index, 1);

                onClickEdit(rowData);
              }}
            >
              แก้ไข
            </Button>
            */}
            <Button
              size="sm"
              color="danger"
              disabled={rowData.VoteCardDisburse > 0 ? true : false}
              onClick={() => {
                const dataDelete = [...data];
                const index = rowData.tableData.id;
                dataDelete.splice(index, 1);
                sweetAlerts.error(
                  "ยืนการยกเลิกการสั่งพิมพ์",
                  "คุณต้องการยกเลิกการสั่งพิมพ์ใช่หรือไม่",
                  () => {
                    deleteVoteCardPublishDetail({
                      voteCardPublishDetailID: rowData.DataKey
                    })
                      .then(result => {
                        setData([...dataDelete]);
                        snackBars.success(`ยกเลิกการสั่งพิมพ์สำเร็จแล้ว`, 3000);
                      })
                      .catch(err => {
                        if (err.response.data.code !== 20000) {
                          sweetAlerts.danger(`ไม่สามารถยกเลิกการสั่งพิมพ์ได้`);
                        }
                      });
                  },
                  true
                );
              }}
            >
              ยกเลิกการสั่งพิมพ์
            </Button>
          </>
        );
      }
    }
  ];

  const [data, setData] = useState([]);

  const [editModaladdCard,] = useState(null);

  const openModaladdCard = () => setshowModaladdCard(true);
  const closeModaladdCard = () => setshowModaladdCard(false);
  const onClickCancel = e => {
    setVotecardPrintingSelected(null);
    setVotecardOptTypeSelected(null);
    setPostionTypeSelected(null);
    setVotecardColorSelected(null);
    setVotecardNumberSelected(null);
    setData([]);
  };
  const prevShowtRef = useRef();
  useEffect(() => {
    prevShowtRef.current = showModaladdCard;
  });
  const prevShow = prevShowtRef.current;

  useEffect(() => {
    if (prevShow === true) {
      onClickSearch();
    }
  }, [showModaladdCard, prevShow]);

  const onClickSearch = e => {
    setClickSearch(false);
    setLoading(true);

    const optQueryUrl = () => {
      let endPoint = "?";

      if (votecardPrintingSelected !== null) {
        //
        endPoint += `PrintHouseID=${votecardPrintingSelected.id}&`;
      }

      if (votecardOptTypeSelected !== null) {
        endPoint += `VoteCareOptTypeID=${votecardOptTypeSelected.id}&`;
      }

      if (positionTypeSelected !== null) {
        endPoint += `PositionTypeID=${positionTypeSelected.id}&`;
      }

      if (votecardColorSelected !== null) {
        endPoint += `CardColorID=${votecardColorSelected.id}&`;
      }

      if (votecardNumberSelected !== null) {
        endPoint += `CardNo=${votecardNumberSelected.id}&`;
      }

      return endPoint;
    };

    getSearchLists(optQueryUrl())
      .then(result => {
        setData(result.data.data);

        setLoading(false);
        setClickSearch(true);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล อปท.", err.toString());
      });
  };

  return (
    <>
      <Card>
        <CardBody>
          <form>
            <ModaladdCardPublish
              showModaladdCard={showModaladdCard}
              closeModaladdCard={closeModaladdCard}
              editModaladdCard={editModaladdCard}
            ></ModaladdCardPublish>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer
                justify="space-around"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={12} sm={12} md={2}>
                  <ListboxVotecardPrinting
                    value={votecardPrintingSelected}
                    onChange={onVotecardPrintingSelected}
                  ></ListboxVotecardPrinting>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <ListboxVotecardOptType
                    value={votecardOptTypeSelected}
                    onChange={onVotecardOptTypeSelected}
                  ></ListboxVotecardOptType>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <ListboxPositionType
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <ListboxVotecardColor
                    votecardPrintingSelected={votecardPrintingSelected}
                    value={votecardColorSelected}
                    onChange={onVotecardColorSelected}
                  ></ListboxVotecardColor>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <ListboxVotecardNumber
                    votecardPrintingSelected={votecardPrintingSelected}
                    votecardColorSelected={votecardColorSelected}
                    value={votecardNumberSelected}
                    onChange={onVotecardNumberSelected}
                  ></ListboxVotecardNumber>
                </GridItem>
              </GridContainer>
              <br /> <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}>
                  <Button
                    className={classes.buttoncustom}
                    onClick={() => {
                      openModaladdCard();
                    }}
                    color="success"
                  >
                    เพิ่มการสั่งพิมพ์
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    className={classes.buttoncustom}
                    onClick={onClickCancel}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    color="info"
                    className={classes.buttoncustom}
                    type="button"
                    onClick={onClickSearch}
                  >
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </form>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title="รายการสั่งพิมพ์บัตรเลือกตั้งในระบบ"
          columns={columns}
          data={data}
          localization={{}}
          defaultExpanded={true}
          options={optionsTable}
        ></TableComponent>
      )}
    </>
  );
};

export default VoteCardPublish;
