import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";

const cookies = new Cookies();

export function addVoteDate(voteDate, voteTypeId, positionTypeId) {
  const isAuth = cookies.get("userInfo");
  const body = {
    voteDate: voteDate,
    voteTypeId: voteTypeId,
    positionTypeId: positionTypeId
  };
  const url = `${END_POINT_URL}/inspector/votedate`;
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function delVoteDate(inspectorVotedateId) {
  const isAuth = cookies.get("userInfo");

  const url = `${END_POINT_URL}/inspector/votedate/${inspectorVotedateId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editVoteDate(
  inspectorVotedateId,
  voteDate,
  voteTypeId,
  positionTypeId
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/votedate/${inspectorVotedateId}`;
  const body = {
    voteDate: voteDate,
    voteTypeId: voteTypeId,
    positionTypeId: positionTypeId
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getPosition() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/position/type`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getVoteDate() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/votedate`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
