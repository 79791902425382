import "./sitemap.css";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, {useContext} from "react";
import Routes from "../../routes";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Tree from "react-d3-tree";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {UserInfoContext} from "contexts/users";
import {useSweetAlert} from "components/Alert/SweetAlert";
import {CONTACT_ADMIN, ERROR_MENU_PERMISSION_DENIED} from "../../Constants/errors";

const useStyles = makeStyles(theme => ({
    root: {
        "& > svg": {
            margin: theme.spacing(2)
        }
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SiteMap({open, setOpen}) {

    const calculateTTP = inputText => {
        const font = "30px Athiti, sans-serif";
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        context.font = font;
        const width = context.measureText(inputText).width;
        return Math.ceil(width);
    };

    const {userInfo} = useContext(UserInfoContext);
    const {sweetAlerts} = useSweetAlert();
    const classes = useStyles();
    const [pathFunc, setPathFunc] = React.useState("diagonal");
    const [orientation, setOrientation] = React.useState("vertical");
    let history = useHistory();
    let rectWidth = 150;
    let rectHeight = 50;
    let link;
    let maxWidths = {0: 250};
    let menus = {
        name: "โปรแกรมฐานข้อมูลการเลือกตั้งท้องถิ่น",
        attributes: {
            deep: 0,
            color: "#999",
            key: 0,
            hasPermission: () => {
                return true;
            }
        },
        children: []
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getMaxWidth = maxWidths => {
        return Math.max(...Object.values(maxWidths));
    };

    const prepareRoute = function (routes, root, deep) {
        routes.forEach(e => {
            if (!root.children) {
                root.children = [];
            }

            if (e.hide === true) {
                return;
            }

            let childrenOfRoot = {
                name: e.name,
                attributes: {
                    deep: deep,
                    path: e.path,
                    layout: e.layout,
                    color: e.color,
                    key: e.pageId,
                    hasPermission: () => {
                        return userInfo.pageIds.includes(e.pageId)
                    }
                },
                children: []
            };

            let nameLen = calculateTTP(e.name);
            if (nameLen > rectWidth || !maxWidths[`${deep}`]) {
                maxWidths[`${deep}`] = nameLen + 10;
            }

            root.children.push(childrenOfRoot);

            if (e.views) {
                prepareRoute(e.views, childrenOfRoot, deep + 1);
            }
        });
    };

    const getPathByPageId = function (menus, pageId) {
        if (menus.attributes.key.toString() === pageId.toString()) {
            return `${menus.attributes.layout ? menus.attributes.layout : ""}${menus.attributes.path ? menus.attributes.path : ""}`;
        }
        for (let i = 0; i < menus.children.length; i++) {
            let e = menus.children[i];
            let data = getPathByPageId(e, pageId);
            if (data) {
                return data;
            }
        }
    }

    prepareRoute(Routes, menus, 1);

    const renderRectSvgNode = ({nodeDatum, toggleNode}) => (
        <g id={nodeDatum.attributes.key} onClick={(e) => {
            if (nodeDatum.children.length === 0) {
                if (!nodeDatum.attributes.hasPermission()) {
                    sweetAlerts.warning(`${ERROR_MENU_PERMISSION_DENIED}${nodeDatum.name}`, CONTACT_ADMIN);
                    return;
                }
                const link = getPathByPageId(menus, e.target.id) || "#";
                history.push(link);
                handleClose();
            } else {
                toggleNode()
            }
        }}>
            <defs>
                <filter
                    x="-50%"
                    y="-50%"
                    width="200%"
                    height="200%"
                    filterUnits="objectBoundingBox"
                    id="shadow-filter"
                >
                    <feOffset
                        dx="0"
                        dy="4"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    />
                    <feGaussianBlur
                        stdDeviation="10"
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
                        in="shadowBlurOuter1"
                        type="matrix"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            {
                (link = `${
                    nodeDatum.attributes.layout ? nodeDatum.attributes.layout : ""
                }${nodeDatum.attributes.path ? nodeDatum.attributes.path : ""}`)
            }
            {(rectWidth = maxWidths[nodeDatum.attributes.deep])}
            <rect id={nodeDatum.attributes.key}
                  width={rectWidth}
                  height={rectHeight}
                  y={-(rectHeight / 2)}
                  x={-(rectWidth / 2)}
                  rx="5"
                  ry="5"
                  fill={nodeDatum.attributes.color}
                  strokeWidth="0"
                  stroke="rgb(0,0,0)"
                  filter="url(#shadow-filter)"
            />
            <text id={nodeDatum.attributes.key} textAnchor="middle" alignmentBaseline="middle" onClick={toggleNode}>
                {nodeDatum.name}
            </text>
        </g>
    );

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            ปิด
                        </Button>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={() => setPathFunc("step")}
                        >
                            โครงสร้างลำดับขั้น
                        </Button>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={() => setPathFunc("diagonal")}
                        >
                            โครงสร้างเส้นทแยงมุม
                        </Button>

                        <Button
                            autoFocus
                            color="inherit"
                            onClick={() => setOrientation("horizontal")}
                        >
                            แนวนอน
                        </Button>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={() => setOrientation("vertical")}
                        >
                            แนวตั้ง
                        </Button>
                    </Toolbar>
                </AppBar>
                <div
                    id="treeWrapper"
                    style={{width: window.screen.width, height: window.screen.height}}
                >
                    <Tree
                        nodeSize={{
                            x: getMaxWidth(maxWidths) + 8,
                            y: getMaxWidth(maxWidths)
                        }}
                        translate={{x: window.screen.width / 2, y: 200}}
                        data={menus}
                        initialDepth={1}
                        renderCustomNodeElement={renderRectSvgNode}
                        pathFunc={pathFunc}
                        orientation={orientation}
                        rootNodeClassName="node__root"
                        branchNodeClassName="node__branch"
                        leafNodeClassName="node__leaf"
                        zoom={0.9}
                        scaleExtent={{min: 0.1, max: 2}}
                    />
                </div>
            </Dialog>
        </div>
    );
}
