import {combineReducers, configureStore} from '@reduxjs/toolkit'

import logger from 'redux-logger'
import {notificationsReducer, popupsReducer} from "./reducers"

const preloadedState = {
    notifications: [],
    notificationsCountUnread: 0,
    notificationsCountRead: 0,

    popups: [],
    isEnabledPopup: true,
    popupsCountUnread: 0,
    popupsCountRead: 0,
}

const rootReducer = combineReducers({
    notifications: notificationsReducer,
    popups: popupsReducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    preloadedState: preloadedState,
})