import React, { useContext, useEffect, useState } from "react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardText from "components/Card/CardText.js";
import ConvertDate from "utils/ConvertDate";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Language from "@material-ui/icons/Language";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import ListItemText from "@material-ui/core/ListItemText";
import ListboxProvince from "components/Common/ListboxProvince";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableComponent from "components/Table/MaterialTable";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { VoteDateContext } from "contexts/VoteDate";
import { UserInfoContext } from "contexts/users";

import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { blackColor } from "assets/jss/material-dashboard-pro-react";
import { getOptSummary, getSuspendSummary } from "contexts/API/HOME/Dashboard";

const useStyles = makeStyles(theme => ({
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  cardCategory: {
    paddingLeft: "calc(100% - 38%)",
    margingTop: "-50px"
  },
}));

export default function Home() {
  const { sweetAlerts } = useSweetAlert();

  const [data, setData] = useState([]);
  const [oldProvinceId, setOldProvinceId] = useState(0);
  const [dataSuspend, setDataSuspend] = useState([]);
  const [dataRevoke, setDataRevoke] = useState([]);
  const [columns] = React.useState([
    {
      title: "ประเภท อปท.",
      field: "opt_name",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      title: "จำนวน",
      field: "opt_cnt",
      cellStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return !rowData.opt_cnt ? 0 : rowData.opt_cnt.toLocaleString();
      },
    },
    {
      title: "จำนวน ผ.ถ.",
      field: "opt_pres_cnt",
      cellStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return !rowData.opt_pres_cnt ? 0 : rowData.opt_pres_cnt.toLocaleString();
      },
    },
    {
      title: "จำนวน ส.ถ.",
      field: "opt_mem_cnt",
      cellStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return !rowData.opt_mem_cnt ? 0 : rowData.opt_mem_cnt.toLocaleString();
      },
    },
    {
      title: "จำนวนเขต",
      field: "area_cnt",
      cellStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return !rowData.area_cnt ? 0 : rowData.area_cnt.toLocaleString();
      },
    },
    {
      title: "จำนวนหน่วย",
      field: "num_station_cnt",
      cellStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return !rowData.num_station_cnt ? 0 : rowData.num_station_cnt.toLocaleString();
      },
    },
    /*   {
        title: "จำนวน อปท. ที่จะครบวาระ อีก 80 วันข้างหน้า",
        field: "eff_opt_cnt",
        cellStyle: {
          textAlign: "center"
        },
        render: rowData => {
          return !rowData.eff_opt_cnt ? 0 : rowData.eff_opt_cnt.toLocaleString();
        },
      }, */
    {
      title: "จำนวน อปท. ที่จะจัดเลือกตั้งอีก 80 วันข้างหน้า",
      field: "votedt_opt_cnt",
      cellStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return !rowData.votedt_opt_cnt ? 0 : rowData.votedt_opt_cnt.toLocaleString();
      },
    }
  ]);
  const [dataSummary, setDataSummary] = useState([]);
  const tableOptions = {
    exportButton: false,
    paging: false,
    search: false
  };
  const classes = useStyles();
  //const { onProvinceSelected, provinceSelected } = useState();
  const { onProvinceSelected, provinceSelected } = useContext(VoteDateContext);
  const { userInfo } = useContext(UserInfoContext);



  useEffect(() => {

    //let oldProvinceId = 0;
    let newProvinceId = provinceSelected ? provinceSelected.id : (userInfo.provinceId !== null && (!userInfo.iscenter)) ? userInfo.provinceId : 0
    if (userInfo.token !== undefined && (data.length === 0 || newProvinceId !== oldProvinceId)) {
      console.log("userInfo>>> ", userInfo)

      setOldProvinceId(newProvinceId)
      //let provinceId = provinceSelected ? provinceSelected.id : 0;
      getOptSummary(newProvinceId, userInfo.token)
        .then(r => {
          const result = r.data;
          if (result.data) {
            setData(result.data);
            setDataSummary(result.data[7]);
          }
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
        });
    }
  }, [userInfo, provinceSelected, data, sweetAlerts, oldProvinceId]);

  useEffect(() => {
    if (userInfo.token !== undefined
      && (dataSuspend.length === 0
        && dataRevoke.length === 0)) {
      getSuspendSummary(userInfo.token)
        .then(r => {
          const result = r.data;
          if (result.data) {
            setDataSuspend(result.data.suspend);
            setDataRevoke(result.data.revoke);
          }
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
        });
    }
  }, [dataSuspend, dataRevoke, sweetAlerts, userInfo]);


  return (
    <div>
      {userInfo.token !== undefined ? (
        <>
          {/* {userInfo.tokenInfo.data.username !== "ect002" && 
           userInfo.tokenInfo.data.username !== "ect003" && 
           userInfo.tokenInfo.data.username !== "ect004" &&
           userInfo.tokenInfo.data.username !== "ect005"  ? ( */}
          {userInfo.pageIds.includes(2) ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="warning" text>
                      <CardText color="warning">
                        <h4 className={classes.cardTitle}> จำนวน อปท.</h4>
                      </CardText>
                    </CardHeader>

                    <CardBody className={classes.cardCategory}>
                      <h4 style={{ marginTop: "-50px" }}>
                        {String(dataSummary.opt_cnt).replace(
                          /(.)(?=(\d{3})+$)/g,
                          "$1,"
                        )}
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="success" text>
                      <CardText color="success">
                        <h4 className={classes.cardTitle}> จำนวน ผ.ถ.</h4>
                      </CardText>
                    </CardHeader>

                    <CardBody className={classes.cardCategory}>
                      <h4 style={{ marginTop: "-50px" }}>
                        {String(dataSummary.opt_pres_cnt).replace(
                          /(.)(?=(\d{3})+$)/g,
                          "$1,"
                        )}
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="danger" text>
                      <CardText color="danger">
                        <h4 className={classes.cardTitle}> จำนวน ส.ถ.</h4>
                      </CardText>
                    </CardHeader>

                    <CardBody className={classes.cardCategory}>
                      <h4 style={{ marginTop: "-50px" }}>
                        {String(dataSummary.opt_mem_cnt).replace(
                          /(.)(?=(\d{3})+$)/g,
                          "$1,"
                        )}
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="primary" text>
                      <CardText color="primary">
                        <h4 className={classes.cardTitle}> จำนวนเขต</h4>
                      </CardText>
                    </CardHeader>

                    <CardBody className={classes.cardCategory}>
                      <h4 style={{ marginTop: "-50px" }}>
                        {String(dataSummary.area_cnt).replace(
                          /(.)(?=(\d{3})+$)/g,
                          "$1,"
                        )}
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>
                {/* <GridItem xs={12} sm={6} md={6} lg={3}>
                  <Card>
                    <CardHeader color="primary" text>
                      <CardText color="primary">
                        <h4 className={classes.cardTitle}> จำนวนหน่วย</h4>
                      </CardText>
                    </CardHeader>

                    <CardBody className={classes.cardCategory}>
                      <h4 style={{ marginTop: "-50px" }}>
                        {String(dataSummary.num_station_cnt).replace(
                          /(.)(?=(\d{3})+$)/g,
                          "$1,"
                        )}
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem> */}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <Card>
                    <CardHeader color="success" icon>
                      <CardIcon color="success">
                        <Language />
                      </CardIcon>
                      <h4 style={{ color: blackColor }}>
                        ทะเบียนสมาชิก ส.ถ./ผ.ถ.
                      </h4>
                    </CardHeader>
                    <CardBody>
                      <GridContainer justify="space-between">
                        <GridItem xs={12} sm={12} md={12}>
                          <CardBody>
                            <GridItem xs={3} sm={3} md={3}>
                              <ListboxProvince
                                defaultValue={{
                                  id: (userInfo.provinceId !== null && (!userInfo.iscenter)) ? userInfo.provinceId : 0,
                                }}
                                //value={provinceSelected}
                                onChange={onProvinceSelected}
                              ></ListboxProvince>
                            </GridItem>
                          </CardBody>
                          <TableComponent
                            title={
                              "สรุปข้อมูลระบบทะเบียนสมาชิกสภาท้องถิ่นและผู้บริหารท้องถิ่น"
                            }
                            columns={columns}
                            data={data}
                            options={tableOptions}
                            enableInitColumns={false}
                            components={{
                              Container: props => (
                                <Paper {...props} elevation={0} />
                              ),
                              Header: props => {
                                const fromdate = ConvertDate(new Date());
                                const date = new Date();
                                const enddate = ConvertDate(
                                  date.setDate(date.getDate() + 80)
                                );
                                //let newText11 = `จำนวน อปท. ที่จะครบวาระ อีก 80 วันข้างหน้า`;
                                let newText12 = `จำนวน อปท. ที่จะจัดเลือกตั้งอีก 80 วันข้างหน้า`;
                                let newText2 = ` (${fromdate} - ${enddate})`;
                                return (
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">
                                        ประเภท อปท.
                                      </TableCell>
                                      <TableCell align="center">จำนวน อปท.</TableCell>
                                      <TableCell align="center">
                                        จำนวน ผ.ถ. ดำรงตำแหน่ง
                                      </TableCell>
                                      <TableCell align="center">
                                        จำนวน ส.ถ. ดำรงตำแหน่ง
                                      </TableCell>
                                      <TableCell align="center">จำนวนเขต</TableCell>
                                      <TableCell align="center">จำนวนหน่วย</TableCell>
                                      {/*  <TableCell align="center">
                                        {newText11}
                                        <br />
                                        {newText2}
                                      </TableCell> */}

                                      <TableCell align="center">
                                        {newText12}
                                        <br />
                                        {newText2}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                );
                              }
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>

            </>
          ) : (
            ""
          )}

          {/* {userInfo.tokenInfo.data.username !== "ect002" && 
           userInfo.tokenInfo.data.username !== "ect003" && 
           userInfo.tokenInfo.data.username !== "ect001" &&
           userInfo.tokenInfo.data.username !== "ect005"  ? ( */}
          {userInfo.pageIds.includes(5) ? (
            <>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <Card>
                    <CardHeader color="primary" text>
                      <CardText color="primary">
                        <h4 className={classes.cardTitle}> จำนวนผู้ที่ถูกระงับสิทธิสมัครรับเลือกตั้ง</h4>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <List>
                        {dataSuspend.map((t, index) => {
                          return (
                            <ListItem key={index}>
                              <ListItemText primary={t.detail} />
                              <ListItemSecondaryAction>
                                {t.total}
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Card>
                    <CardHeader color="primary" text>
                      <CardText color="primary">
                        <h4 className={classes.cardTitle}> จำนวนผู้ที่ถูกเพิกถอนสิทธิสมัครรับเลือกตั้ง</h4>
                      </CardText>
                    </CardHeader>
                    <CardBody>
                      <List>
                        {dataRevoke.map((t, index) => {
                          return (
                            <ListItem key={index}>
                              <ListItemText primary={t.detail} />
                              <ListItemSecondaryAction>
                                {" "}
                                {t.total}
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        "Loading...."
      )}
    </div>
  );
}
