import React, { useContext, useState } from "react";
import { editrecount, getVotePublish, getCountReNewScore } from "contexts/API/OPT/OptVotePublish";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import CloseIcon from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import IconButton from "@material-ui/core/IconButton";
import ListboxArea from "components/Common/ListboxArea";
import ListboxAumphurArea from "components/Common/ListboxAumphurArea";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxVotedate from "components/Common/ListboxVotedate";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MustNotNull from "utils/Validators/MustNotNull";
// core components
import NumberFormat from "react-number-format";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import TableComponent from "components/Table/MaterialTable";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { VoteDateContext } from "contexts/VoteDate";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    zIndex: 99,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[700]
  }
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  },
  header: {
    paddingLeft: "50px"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModaladdNewvote = props => {
  const {
    optVoteTypeSelected,
    onOptVoteTypeSelected,
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,
    tambolSelected,
    optTypeSelected,
    onOptTypeSingleSelected,
    onOptSelected,
    optSelected,

    onVotedateSelected,
    positionTypeSelected,
    areaSelected,
    onAreaSelected,
    votedateSelected,

    onPostionTypeSelected,
    getOptTypeSelectedLists,
    onAumphurAreaSelected,

    aumphurAreaSelected
  } = useContext(VoteDateContext);

  const { sweetAlerts } = useSweetAlert();
  const { showModalNewvote, closeModalNewvote } = props;
  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setListCountReNewScore] = useState([]);

  function fetchDataforNewvote() {
    getVotePublish(
      optSelected,
      votedateSelected,
      optVoteTypeSelected,
      areaSelected,
      positionTypeSelected
    )
      .then(result => {
        if (result.data.data.member && result.data.data.member.length > 0) {
          setLoading(false);
          setData(result.data.data.member);
        }
        if (result.data.data.pres && result.data.data.pres.length > 0) {
          setData(result.data.data.pres);
          setLoading(false);
        }

        getCountReNewScore(optSelected, votedateSelected)
          .then(r => {
            console.log("r.data.data >> ==>", r.data.data);
            let listId = [];
            for (let i = 0; i < r.data.data.length; i++) {
              listId.push(r.data.data[i].person_score_history_id)
            }
            console.log("listId >> ==>", listId);
            setListCountReNewScore(listId);
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
  }
  const handleSubmitNewvote = e => {
    e.preventDefault();

    if (optSelected == null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
      return;
    }
    if (positionTypeSelected == null) {
      sweetAlerts.warning("กรุณาเลือกตำแหน่ง");
      return;
    }
    if (votedateSelected == null) {
      sweetAlerts.warning("กรุณาระบุวันเลือกตั้ง");
      return;
    }
    if (
      positionTypeSelected &&
      positionTypeSelected.id === 2 &&
      areaSelected == null
    ) {
      sweetAlerts.warning("กรุณาระบุเขตที่เลือกตั้ง");
      return;
    }

    const _columnPres = [
      {
        title: "optPersonPresId",
        field: "optPersonPresId",
        hidden: true,
        editable: "never"
      },

      {
        title: "หมายเลขผู้สมัคร",
        field: "applyNo",
        editable: "never"
      },
      {
        title: "ชื่อ สกุล",
        field: "",
        editable: "never",
        cellStyle: {
          minWidth: "200px"
        },
        render: rowData => {
          return (
            rowData.titleDetail +
            " " +
            rowData.firstName +
            " " +
            rowData.lastName
          );
        }
      },
      {
        title: "คะแนน",
        field: "score",
        editable: "never",
        cellStyle: {
          minWidth: "100px"
        },
        render: rowData => {
          return (
            <NumberFormat
              thousandSeparator={true}
              customInput={TextField}
              disabled={true}
              thousandsGroupStyle="thousand"
              value={!rowData.score ? 0 : parseInt(rowData.score)}
            />
          );
        }
      },
      {
        title: "คะแนนใหม่",
        field: "newScore",

        cellStyle: {
          minWidth: "100px"
        },
        render: rowData => {
          return (
            <NumberFormat
              thousandSeparator={true}
              customInput={TextField}
              disabled={true}
              thousandsGroupStyle="thousand"
              value={!rowData.newScore ? 0 : parseInt(rowData.newScore)}
            />
          );
        }
      },
      {
        title: "เหตุผล",
        field: "remark",
        cellStyle: {
          minWidth: "200px"
        },

        editComponent: props => {
          return props.rowData.tableData.id === 0 ? (
            <CustomInput
              labelText="เหตุผล *"
              validators={[MustNotNull]}
              inputProps={{
                onChange: e => {
                  // console.log(e.target.value);
                  props.onChange(e.target.value);
                }
              }}
            />
          ) : (
            <> </>
          );
        }
      }
    ];
    const _columnMember = [
      {
        title: "optPersonMemId",

        field: "optPersonMemId",

        hidden: true
      },

      {
        title: "หมายเลขผู้สมัคร",
        field: "applyNo",
        editable: "never"
      },
      {
        title: "ชื่อ สกุล",
        field: "",
        editable: "never",
        cellStyle: {
          minWidth: "200px"
        },
        render: rowData => {
          return (
            rowData.titleDetail +
            " " +
            rowData.firstName +
            " " +
            rowData.lastName
          );
        }
      },
      {
        title: "คะแนน",
        field: "score",
        editable: "never",
        cellStyle: {
          minWidth: "100px"
        },
        render: rowData => {
          return (
            <NumberFormat
              thousandSeparator={true}
              customInput={TextField}
              disabled={true}
              thousandsGroupStyle="thousand"
              value={!rowData.score ? 0 : parseInt(rowData.score)}
            />
          );
        }
      },
      {
        title: "คะแนนใหม่",
        field: "newScore",
        cellStyle: {
          minWidth: "100px"
        },
        render: rowData => {
          return (
            <NumberFormat
              thousandSeparator={true}
              customInput={TextField}
              disabled={true}
              thousandsGroupStyle="thousand"
              value={!rowData.newScore ? 0 : parseInt(rowData.newScore)}
            />
          );
        }
        // editComponent: props => {
        //   return (
        //     <NumberFormat
        //       thousandSeparator={true}
        //       customInput={TextField}
        //       thousandsGroupStyle="thousand"
        //       value={
        //         !props.rowData.newScore ? 0 : parseInt(props.rowData.newScore)
        //       }
        //       onValueChange={values => {
        //         const { value } = values;

        //         props.rowData.newScore = parseInt(value);
        //         //this.setState({profit: formattedValue})
        //       }}
        //     />
        //   );
        // }
      },
      {
        title: "เหตุผล",
        field: "remark",
        cellStyle: {
          minWidth: "200px"
        },
        editComponent: props => {
          return props.rowData.tableData.id === 0 ? (
            <CustomInput
              labelText="เหตุผล *"
              validators={[MustNotNull]}
              inputProps={{
                onChange: e => {
                  props.onChange(e.target.value);
                }
              }}
            />
          ) : (
            <> </>
          );
        }
      }
    ];
    positionTypeSelected && positionTypeSelected.id === 2
      ? setColumns(_columnMember)
      : setColumns(_columnPres);
    // setColumns(_columnMember);
    fetchDataforNewvote();
  };

  return (
    <div className={showModalNewvote ? "modal" : "hide"}>
      <Dialog
        open={showModalNewvote}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModalNewvote}
        aria-labelledby="form-dialog-title"
      >
        {/* <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>นับคะแนนใหม่</h4>
          </CardHeader>
        </DialogTitle> */}
        <DialogTitle id="customized-dialog-title" onClose={closeModalNewvote}>
          {/* <CardHeader color="success" className={classes.header}> */}
          <h4 className={classes.cardTitle}>นับคะแนนใหม่</h4>
          {/* </CardHeader> */}
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CheckboxOpttype
                optTypeSelected={optTypeSelected}
                onOptTypeSelected={onOptTypeSingleSelected}
                required={true}
              ></CheckboxOpttype>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {/* <GridItem xs={4} sm={4} md={4}>
              <ListboxOpttype
                value={listboxOptSelected}
                onChange={onListboxOptSelected}
              ></ListboxOpttype>
            </GridItem> */}
            <GridItem xs={4} sm={4} md={4}>
              <ListboxOptVotetype
                value={optVoteTypeSelected}
                onChange={onOptVoteTypeSelected}
              ></ListboxOptVotetype>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <ListboxProvince
                value={provinceSelected}
                onChange={onProvinceSelected}
              ></ListboxProvince>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <ListboxDistinct
                value={amphurSelected}
                provinceSelected={provinceSelected}
                onChange={onAmphurSelected}
              ></ListboxDistinct>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <ListboxOpts
                optTypeSelected={optTypeSelected}
                value={optSelected}
                provinceSelected={provinceSelected}
                amphurSelected={amphurSelected}
                tambolSelected={tambolSelected}
                onChange={onOptSelected}
              ></ListboxOpts>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <ListboxVotedate
                value={votedateSelected}
                optSelected={optSelected}
                optVoteTypeSelected={optVoteTypeSelected}
                onChange={onVotedateSelected}
              ></ListboxVotedate>
            </GridItem>

            <GridItem xs={4} sm={4} md={4}>
              <ListboxAumphurArea
                disabled={
                  getOptTypeSelectedLists().toString() === 1 ? true : false
                }
                value={aumphurAreaSelected}
                optSelected={optSelected}
                votedateSelected={votedateSelected}
                optVoteTypeSelected={optVoteTypeSelected}
                onChange={onAumphurAreaSelected}
              ></ListboxAumphurArea>
            </GridItem>
            {/* ) : null} */}
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <ListboxPositionType
                value={positionTypeSelected}
                onChange={onPostionTypeSelected}
              ></ListboxPositionType>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <ListboxArea
                disabled={
                  positionTypeSelected && positionTypeSelected.id === 1
                    ? true
                    : false
                }
                value={areaSelected}
                aumphurAreaSelected={aumphurAreaSelected}
                optSelected={optSelected}
                optVoteTypeSelected={optVoteTypeSelected}
                votedateSelected={votedateSelected}
                onChange={onAreaSelected}
              ></ListboxArea>
            </GridItem>
          </GridContainer>

          <GridContainer></GridContainer>
          <br></br>
          <GridContainer
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem xs={12} sm={12} md={6}>
              <Button color="info" onClick={handleSubmitNewvote}>
                ค้นหา
              </Button>
            </GridItem>
          </GridContainer>
          <br></br>
          {loading === false ? (
            <>
              <TableComponent
                title="รายชื่อผู้ที่ได้รับการเลือกตั้ง"
                columns={columns}
                data={data}
                options={{ exportButton: false, paging: false, search: false }}
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}
                editable={{
                  isDeleteHidden: rowData => rowData.nameoptId !== "",
                  // isEditable: rowData => rowData.name === 'a',
                  onBulkUpdate: changes =>
                    new Promise((resolve, reject) => {
                      const dataUpdate = [...data];

                      if (Object.keys(changes).length !== dataUpdate.length) {
                        sweetAlerts.error("กรุณากรอกคะแนนใหม่ให้ครบถ้วน");
                        reject();
                      }
                      if (!changes[0].newData.remark) {
                        sweetAlerts.error("กรุณากรอกเหตุผล");
                        // alert("กรุณากรอกเหตุผล");
                        reject();
                      } else {
                        setTimeout(() => {
                          //  console.log(changes);

                          if (
                            Object.keys(changes).length === dataUpdate.length &&
                            changes[0].newData.remark !== undefined
                          ) {
                            var val = [];
                            for (
                              let i = 0;
                              i < Object.keys(changes).length;
                              i++
                            ) {
                              var x = {
                                optPersonId:
                                  changes[i].newData.optPersonMemId !==
                                    undefined
                                    ? changes[i].newData.optPersonMemId
                                    : changes[i].newData.optPersonPresId,
                                newScore: Number(changes[i].newData.newScore),
                                oldScore: Number(changes[i].oldData.score),
                                remark: changes[0].newData.remark
                              };

                              val.push(x);
                              const index = changes[i].oldData.tableData.id;
                              dataUpdate[index] = changes[i].newData;
                            }

                            //console.log("val", val);
                            editrecount(val, positionTypeSelected.id)
                              .then(result => {
                                sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                                props.onaddSuccess(result.data.success);
                                closeModalNewvote();
                                //setData([...dataUpdate]);
                                fetchDataforNewvote();
                              })
                              .catch(err => {
                                sweetAlerts.error(
                                  "ไม่สามารถแก้ไขข้อมูลได้",
                                  err.toString()
                                );
                              });

                            // props.onaddSuccess(result);
                          }
                          resolve();
                        }, 1000);
                      }
                    }),

                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        resolve();
                      }, 1000);
                    })
                }}
              ></TableComponent>
            </>
          ) : (
            <></>
          )}
          <br></br>
          <hr></hr>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={closeModalNewvote}>ปิดหน้าต่าง</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModaladdNewvote;
