import React from "react";

import AddPerson from "./AddPerson";

import { SuspendContextProvider } from "contexts/Suspend";

const Index = props => {
 
  return (
    <SuspendContextProvider>
      <AddPerson />
    </SuspendContextProvider>
  );
};

export default Index;
