import React, { useEffect, useState } from "react";

// material-ui icons
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Box from "@material-ui/core/Box";
import Button from "components/CustomButtons/Button.js";
// core components
import CustomInput from "components/CustomInput/CustomInput";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
// material-ui core
//import InputBase from '@material-ui/core/InputBase';
import Divider from "@material-ui/core/Divider";
import FileSaver from "file-saver";
import GetAppIcon from "@material-ui/icons/GetApp";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { downloadFileFromUrl } from "contexts/API/FileManagement/download";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    "box-shadow": "none"
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export function FileUpload(props) {
  const { sweetAlerts } = useSweetAlert();
  let fileInputRef = React.createRef();

  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [fileName, setFilename] = useState(
    props.fileName || props.label || "แนบไฟล์"
  );
  const [btnName] = useState(
    <>
      <AttachFileIcon />
      .pdf
    </>
  );
  const [showRemove, setShowRemove] = useState(
    (props.fileName !== undefined
      && props.fileName !== null
      && props.fileName !== "") ? true : false
  );
  const [showDownload, setShowDownload] = useState(props.url || false);

  useEffect(() => {
    if (props.fileName) {
      setFilename(props.fileName);
    }

    if (props.url) {
      setShowDownload(true);
    }

    return () => {
      setFilename("แนบไฟล์");
    };
  }, [props.fileName, props.url]);

  const fileHandler = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let file_size = e.target.files[0].size;
    //if (file_size > 15002285) {
    if (file_size > 20971520) { //ไบต์(B)
      sweetAlerts.danger(
        "ไม่สามารถแนบไฟล์ได้",
        "ไฟล์ต้องมีขนาดไม่เกินขนาดไม่เกิน 20Mb"
      );
    } else {
      reader.onloadend = e => {
        setFilename(file.name);
        setShowRemove(true);
        setShowDownload(true);
        if (props.onFileHandler !== undefined) {
          props.onFileHandler(e.target.result, file, onUpdateProgress);
        }
      };

      if (file) {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const onClickHandler = () => {
    fileInputRef.current.click();
  };

  const removeFile = () => {
    setShowRemove(false);
    setShowDownload(false);
    setFilename(props.label || "แนบไฟล์");
    setProgress(0);
    fileInputRef.current.value = "";
    if (props.onFileRemove) {
      props.onFileRemove();
    }
  };

  const downloadFile = () => {
    if (props.url && fileName) {
      downloadFileFromUrl(props.url)
        .then(res => {
          FileSaver.saveAs(res.data, fileName);
        })
        .catch(err => {
          console.error("download file failed.");
        });
    } else {
      throw Error(
        "url not found, see https://github.com/DGA-SCD/ECTPL/issues/463"
      );
    }
  };

  const removeButton = () => {
    if (
      props.remove !== undefined &&
      props.remove === true &&
      showRemove === true &&
      props.fileName !== null
    )
      return (
        <Button color="danger" size="sm" onClick={removeFile}>
          <DeleteForeverIcon />
          ลบ
        </Button>
      );
    else return null;
  };

  const downloadButton = () => {
    if (props.download && showDownload)
      return (
        <Button color="success" size="sm" onClick={downloadFile}>
          <GetAppIcon />
          ดาวน์โหลด
        </Button>
      );
    else return null;
  };

  const onUpdateProgress = progress => {
    const resultProgress =
      (((progress.loaded / progress.total) * progress.loaded) /
        progress.total) *
      100;
    setProgress(resultProgress);
  };

  const progressBar = () => {
    if (progress > 0 && progress < 100)
      return (
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress color="secondary" value={Math.round(progress)} />
          </Box>
          <Box minWidth={35}>
            <Typography color="textSecondary">
              {Math.round(progress)}%
            </Typography>
          </Box>
        </Box>

        /*  <Button justIcon round color="success">
           {Math.round(progress)}%
         </Button> */
      );
    else return null;
  };

  return (
    <div>
      <div className={classes.root}>
        <div className="fileinput text-center">
          <input
            type="file"
            accept={props.fileType || "*"}
            onChange={fileHandler}
            ref={fileInputRef}
          />
        </div>
        <CustomInput
          labelText=""
          id={props.id || "input-file"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: fileHandler,
            disabled: true,
            value: fileName
          }}
        />

        <Divider className={classes.divider} orientation="vertical" />
        {removeButton()}
        <Button
          disabled={props.disabled || false}
          color="info"
          size="sm"
          onClick={() => onClickHandler()}
          {...props.btnProps}
        >
          {props.btnName || btnName}
        </Button>
        {downloadButton()}
      </div>

      <div>{progressBar()}</div>
      <p style={{ fontSize: "12px", color: "red", paddingLeft: "10px" }}>
        ไฟล์มีขนาดไม่เกิน 20Mb*
      </p>
    </div>
  );
}

FileUpload.prototype = {
  fileName: PropTypes.string /* filename */,
  id: PropTypes.string,
  fileType: PropTypes.string,
  btnName: PropTypes.element,
  btnProps: PropTypes.object,
  download: PropTypes.bool /* disable or enable download button */,
  remove: PropTypes.bool /* disable and enable remove button */,
  subdir: PropTypes.string /* sub directory to store file */,
  url:
    PropTypes.string /* url to download file, shoul get it from getFileDownloadUrl() */,
  onFileHandler: PropTypes.func /* callback function to manage file */,
  onFileRemove: PropTypes.func
};

export default FileUpload;
