import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getReasonMemPosition() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/statusOutOfPositionMem`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getReasonPresPosition() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/statusOutOfPositionPres`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editOutPresident(
  msOptId,
  optVoteId,
  PKID,
  outDatetime,
  outPositionStatusId,
  optPositionTypeId,
  optAreaId,
  chkNotSetVoteDate,
  oldChkOutPosition,
  chkOutPosition
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${msOptId}/outOfPosition`;

  const body = {
    msOptId: msOptId,
    optVoteId: optVoteId,
    PKID: PKID,
    outDatetime: outDatetime,
    outPositionStatusId: outPositionStatusId,
    optPositionTypeId: optPositionTypeId,
    optAreaId: optAreaId,
    chkNotSetVoteDate: chkNotSetVoteDate,
    oldChkOutPosition: oldChkOutPosition,
    chkOutPosition: chkOutPosition,
    optOutPositionId: PKID
  };

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
