import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";

const cookies = new Cookies();

export function getCheckingInspector(provinceId, applyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/8Inspector/provinceId/${provinceId}/cmdApplyDateId/${applyDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getCheckingProvinceInspector(provinceId, votedateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/provinceInspector/provinceId/${provinceId}/votedateId/${votedateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
