import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxInspectPromotePerson = props => {
  let provinceId = props.inspectProvinceGroupSelected
    ? props.inspectProvinceGroupSelected.id
    : 0;
  let votedateSelected = props.votedateSelected ? props.votedateSelected.id : 0;
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  const [isLoading, fetchData] = useFetch(
    //cadidates8/list/provinceId/:provinceId/cmdApplyDateId/:applyDateId
    conf.END_POINT_URL +
    `/inspector/inspectoList/provinceGroup/${provinceId}/voteDateId/${votedateSelected}`,
    []
  );

  let content = (
    <TextField
      disabled
      label="เลือกรายชื่อผู้ตรวจการ"
      margin="normal"
      fullWidth
    />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return {
        id: v.personApplyCommandId,
        value: v.fullname,
        key: v.provinceId
      };
    });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        name={props.name}
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        //  options={data}
        options={
          props.provinceId
            ? data.filter(v => {
              return v.key === props.provinceId;
            })
            : props.notSelectedprovinceId
              ? data.filter(v => {
                return v.key !== props.notSelectedprovinceId;
              })
              : data
        }
        renderOption={option => (
          <span style={{ fontSize: "15px" }}>{option.value}</span>
        )}
        getOptionLabel={option => option.value}
        style={{ width: "100%" }}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              //    inputProps={{ ...params.inputProps, style: { fontSize: "13px" } }}
              {...params}
              error={error}
              helperText={helperText}
              label="เลือกรายชื่อผู้ตรวจการ"
              margin="normal"
              fullWidth
              inputProps={{
                ...params.inputProps,
                style: { fontSize: "15px" }
              }}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }

  return content;
};
export default ListboxInspectPromotePerson;
