/* core components */
import React, { useContext } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
import { UserInfoContext } from "contexts/users";

//import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxWtdStatus = props => {
  const userInfo = useContext(UserInfoContext);

  let isadminwtd = userInfo.userInfo.isadminwtd;

  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/wtd/wtdStatus",
    []
  );

  let content = (
    <TextField disabled label="สถานะการถูกเพิกถอน" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    let newData = fetchData.data;
    if (!isadminwtd) {
      newData = newData.filter(d => (d.wtdStatusId === 1 || d.wtdStatusId === 3 || d.wtdStatusId === 4));
    }
    data = newData.map(v => {
      return { id: v.wtdStatusId, value: v.detail };
    });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        name={props.name}
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        options={data}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={
                props.titlevotestatus
                  ? props.titlevotestatus
                  : "สถานะการถูกเพิกถอน"
              }
              margin="normal"
              error={error}
              fullWidth
              helperText={helperText}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }
  return content;
};
export default ListboxWtdStatus;
