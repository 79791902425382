import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";

const cookies = new Cookies();

export function getCheckingLists(provinceId, voteDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/inspectorPromote/provinceId/${provinceId}/inspectorVotedateId/${voteDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getPersonForReplace(provinceId, applyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/listInspectPersonForReplace/provinceId/${provinceId}/cmdApplyDateId/${applyDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function updatePersonForReplace(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/replacePerson`;

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getinspectorPromoteStatus() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/inspectorPromoteStatus`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getreplaceInspectorPromote(provinceId, inspectorVotedateId) {
  if (provinceId && inspectorVotedateId) {
    const isAuth = cookies.get("userInfo");
    const url = `${END_POINT_URL}/inspector/replaceInspectorPromote/provinceId/${provinceId.id}/inspectorVotedateId/${inspectorVotedateId.id}`;

    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${isAuth.token}`
      }
    });
  }
}

export function updateReplaceBrance(body, provinceId, inspectorVotedateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/replacePersonPromote/provinceId/${provinceId}/votedateId/${inspectorVotedateId}`;

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
