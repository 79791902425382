import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getAllOptAreaByOptId(optId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/area/`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getAreaVoteLists(optId, voteDate, optVoteId) {

  const isAuth = cookies.get("userInfo");
  let query = `?optVoteTypeId=1`;
  if (voteDate !== null) {
    query += `&voteDate=${voteDate}`;
  }
  if (optVoteId !== null) {
    query += `&optVoteId=${optVoteId}`;
  }
  const url = `${END_POINT_URL}/opt/${optId}/area${query}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getAreaNoLists(optId, optVoteType, voteDate, optVoteId) {
  const isAuth = cookies.get("userInfo");
  let query = `?optVoteTypeId=1`;

  if (optVoteType !== null && optVoteType !== undefined) {
    query = `?optVoteTypeId=${optVoteType}`;
  }

  if (voteDate !== null) {
    query += `&voteDate=${voteDate}`;
  }
  if (optVoteId !== null) {
    query += `&optVoteId=${optVoteId}`;
  }
  const url = `${END_POINT_URL}/opt/${optId}/area${query}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getAmphurArea(optId, optVoteType, voteDate, optVoteId) {
  const isAuth = cookies.get("userInfo");

  let query = `?`;

  if (voteDate !== null) {
    query += `&voteDate=${voteDate}`;
  }
  if (optVoteId !== null) {
    query += `&optVoteId=${optVoteId}`;
  }
  //const url = `${END_POINT_URL}/opt/${optId}/area${query}`;

  const url = `${END_POINT_URL}/opt/${optId}/voteType/${optVoteType}/optAmpher${query}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteOptArea(optId, optAreaId, deleteRemark) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/area/${optAreaId}`;

  const body = {
    deleteRemark: deleteRemark
  };
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    },
    data: body
  });
}

export function addOneOptArea(
  optId,
  listboxOptSelectedId,
  areaNo,
  amphurId,
  numMember,
  numStation
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/area.one`;

  const body = {
    optSubTypeId: listboxOptSelectedId,
    areaNo: areaNo,
    numMember: numMember,
    numStation: numStation,
    amphurId: listboxOptSelectedId === 1 ? amphurId : null
  };
  // if (amphurId !== undefined && amphurId !== null) {
  //   body[0].amphurId = amphurId;
  // }

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addOptArea(
  optId,
  listboxOptSelectedId,
  areaNo,
  amphurId,
  numMember,
  numStation
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/area`;

  const body = [
    {
      optSubTypeId: listboxOptSelectedId,
      areaNo: areaNo,
      numMember: numMember,
      numStation: numStation
    }
  ];

  if (amphurId !== undefined && amphurId !== null) {
    body[0].amphurId = amphurId;
  }

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editOptArea(
  optId,
  optAreaId,
  areaNo,
  amphurId,
  numMember,
  numStation
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/area/${optAreaId}`;

  const body = {
    areaNo: parseInt(areaNo),
    numMember: parseInt(numMember),
    numStation: parseInt(numStation)
  };

  if (amphurId !== undefined && amphurId !== null) {
    body.amphurId = amphurId;
  }

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getAreaNoListsByAmphurId(
  optId,
  optVoteType,
  voteDate,
  amphureId
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/area?voteDate=${voteDate}&optVoteTypeId=${optVoteType}&amphurId=${amphureId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
