import React from "react";
// core components
import RptSummarySuspend from "./RptSummarySuspend";
import { SuspendContextProvider } from "contexts/Suspend";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";



//const styles = {};
//const useStyles = makeStyles(styles);

const ReportIndex = props => {
  return (
    <div>
      <SuspendContextProvider>
        <RptSummarySuspend />
      </SuspendContextProvider>
    </div>
  );
};
export default ReportIndex;
