import Autocomplete from "@material-ui/lab/Autocomplete";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
/* custom components */

const ListboxOptChgStatus = props => {
    let content = (
        <TextField disabled label="เลือกทุกการเปลี่ยนแปลงสถานะ" margin="normal" fullWidth />
    );

    let chgStatusLists = [
        { id: 1, name: "ยกฐานะ" },
        { id: 2, name: "ยุบรวม" },
        { id: 3, name: "เปลี่ยนชื่อ" }
    ];

    let data = [];
    data = chgStatusLists.map(v => {
        //console.log("v.id>>>" + v.name);
        return { id: v.id, value: v.name };
    });

    if (data.length > 0) {
        content = (
            <Autocomplete
                disabled={props.disabled}
                name="optChgStatus"
                value={props.value}
                options={data}
                getOptionLabel={option => option.value}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={props.onChange}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            label="เลือกทุกการเปลี่ยนแปลงสถานะ"
                            margin="normal"
                            fullWidth
                        />
                    );
                }}
            />
        );
    }
    return content;
};

export default ListboxOptChgStatus;