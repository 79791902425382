import Cookies from 'universal-cookie';
import { END_POINT_URL } from 'config/env.conf';
import axios from 'axios';
const cookies = new Cookies();

export function uploadFile(systemName, file, onUploadProgress) {
  const isAuth = cookies.get('userInfo');
  const url = `${END_POINT_URL}/file/${systemName}/upload`;

  const formData = new FormData();
  formData.append('file', file);
  console.log("uploading file " + file.name)
  return axios.post(url, formData, {
    onUploadProgress: onUploadProgress,
    headers: {
      Authorization: `Bearer ${isAuth.token}`,
    },
  });
}
