import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
export function editComment(id, value) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/publish/vote/comment/${id}`;
  const body = {
    comment: value
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delReNewScore(id) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/publish/vote/delReCount`;
  const body = {
    scoreHistoryId: id
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getReplacePerson(optSelected, votedateSelected) {

  const isAuth = cookies.get("userInfo");
  //console.log("votedateSelected >>>>>", votedateSelected)

  let optid = optSelected ? optSelected.id : 0;
  let query = "";
  if (votedateSelected) {
    query = `?votedate=${votedateSelected.id}`;
  }
  const url = `${END_POINT_URL}/opt/${optid}/publish/vote/replacedpersonpres` + query;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getCountReNewScore(optSelected, votedateSelected) {
  const isAuth = cookies.get("userInfo");
  let optid = optSelected ? optSelected.id : 0;

  const url =
    `${END_POINT_URL}/opt/${optid}/votedate/${votedateSelected.id}/publish/countReNewScore`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}


export function getReplaceMemberPerson(areaSelected, optSelected, votedateSelected) {
  const isAuth = cookies.get("userInfo");
  let areaId = areaSelected ? areaSelected : 0;
  let optid = optSelected ? optSelected.id : 0;
  const url = `${END_POINT_URL}/opt/publish/vote/replacedpersonmem?areaId=${areaId}&optid=${optid}&votedate=${votedateSelected.id}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getPositionStatus() {
  const url = `${END_POINT_URL}/opt/publish/vote/position/status`;
  const isAuth = cookies.get("userInfo");
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editVotePublishPresident(
  personId,
  score,
  optVoteSelectedStatusId,
  replaceOptPersonId,
  optPositionStatusId,
  replaceRemark
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/publish/vote/person/${personId}`;
  const body = {
    score: Number(score),
    optPositionTypeId: 1,
    optVoteSelectedStatusId: Number(optVoteSelectedStatusId), //ผลการเลือกตั้ง
    replaceOptPersonId: replaceOptPersonId,
    optPositionStatusId: Number(optPositionStatusId),
    replaceRemark: replaceRemark
  };

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editVotePublishMember(
  personId,
  score,
  optVoteSelectedStatusId,
  replaceOptPersonId,
  optPositionStatusId,
  replaceRemark
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/publish/vote/person/${personId}`;
  const body = {
    score: Number(score),
    optPositionTypeId: 2,
    optVoteSelectedStatusId: Number(optVoteSelectedStatusId),
    replaceOptPersonId: replaceOptPersonId,
    optPositionStatusId: Number(optPositionStatusId),
    replaceRemark: replaceRemark
  };

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editrecount(val, positionTypeSelected) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/publish/vote/recount`;

  const body =
    positionTypeSelected === 1
      ? {
        pres: val
      }
      : {
        member: val
      };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getVotePublish(
  optSelected,
  votedateSelected,
  optVoteTypeSelected,
  areaSelected,
  positionTypeSelected,
  amphurIdSelected
) {
  const isAuth = cookies.get("userInfo");
  let query = "";
  if (optVoteTypeSelected) {
    query = `optVoteTypeId=${optVoteTypeSelected.id}`;
  }
  if (areaSelected) {
    query += `&areaId=${areaSelected.id}`;
  }
  if (positionTypeSelected) {
    query += `&positionTypeId=${positionTypeSelected.id}`;
  }
  if (amphurIdSelected) {
    query += `&amphurId=${amphurIdSelected.id}`;
  }
  // const url = `${END_POINT_URL}/opt/63/votedate/2008-04-20/publish/vote?optVoteTypeId=1&positionTypeId=2`;
  const url =
    `${END_POINT_URL}/opt/${optSelected.id}/votedate/${votedateSelected.id}/publish/vote/import?` +
    query;

  ///api/v1/opt/55/votedate/2012-03-18/publish/vote?optVoteTypeId=1?areaId=1234&positionTypeId=1&amphurId=11
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function bulkUpdateOptPersonScore(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/publish/vote/person/score`;

  let arr_2 = body.map(e => Object.assign({}, e));
  arr_2.forEach(elm => delete elm.FullName);
  arr_2.forEach(elm => delete elm.NoOpt);
  arr_2.forEach(elm => delete elm.NoOpt);
  arr_2.forEach(elm => delete elm.tableData);
  return axios.put(url, arr_2, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
