// core UserList
import PopUp from "./PopUp";
import React from "react";
import { VoteDateContextProvider } from "contexts/VoteDate";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

//const styles = {};
//const useStyles = makeStyles(styles);

const NotiIndex = props => {
  return (
    <div>
      <VoteDateContextProvider>
        <PopUp />
      </VoteDateContextProvider>
    </div>
  );
};
export default NotiIndex;
