import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
export function getReport01(applyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/rpt01?applyDateId=${applyDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getReport02(applyDateId, applyDateOneId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/rpt02?applyDateId=${applyDateId}&applyDate=${applyDateOneId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getReport03(votedateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/rpt03?votedateId=${votedateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
