export function AutocompleteValidator(onChange, validators, setError, setHelperText) {

    return function (e, v) {
        if (onChange !== undefined && onChange !== null) {
            onChange(e, v);
        }

        if (validators !== undefined && validators !== null && validators.length > 0) {
            let isError, helperText;
            for (let i = 0; i < validators.length; i++) {
                let validatorFunc = validators[i];
                [isError, helperText] = validatorFunc(v);
                if (isError) break;
            }

            if (setError !== undefined && setError !== null)
                setError(isError);
            if (setHelperText !== undefined && setHelperText !== null)
                setHelperText(helperText);
        }
    }
}

export default AutocompleteValidator;