import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getSearchLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchallocateprinting${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function updateAllocatePrintingList(purchaseData, purchaseDetailData) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/updatepurchasedetailstatuslist`;
  const body = {
    purchaseData: purchaseData,
    purchaseDetailData: purchaseDetailData
  };

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteItem(queryStr) {
  console.log(queryStr);
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/deleteallocateprinting${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
