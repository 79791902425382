import React, {  useContext } from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem.js";


import ListboxProvince from "../Common/ListboxProvince.js";
import ListboxDistinct from "../Common/ListboxDistinct";
import ListboxSubdistinct from "../Common/ListboxSubdistinct";
import CheckboxOpttype from "../Common/CheckboxOpttype";
import ListboxOpts from "../Common/ListboxOpts";

import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
// import FormControl from "@material-ui/core/FormControl";
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider
// } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";
import "moment/locale/th";
/* import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardText from "../Card/CardText.js";
import CardBody from "../Card/CardBody.js"; */
//import { UserInfoContext } from "contexts/users";
import { VoteDateContext } from "contexts/VoteDate";

const MainVotedate = props => {
  const {
    optVoteTypeSelected,
    provinceSelected,
    optTypeSelected,
    tambolSelected,
    amphurSelected,
    optSelected,

    onOptVoteTypeSelected,
    onProvinceSelected,
    onTamboldSelected,
    onOptTypeSelected,
    onAmphurSelected,
    onOptSelected,


    // utilities 
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);

  return (
    // <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <br /> <br />
      <GridContainer
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <GridItem xs={12} sm={12} md={12}>
          <CheckboxOpttype
            optTypeSelected={optTypeSelected}
            onOptTypeSelected={onOptTypeSelected}
          ></CheckboxOpttype>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <ListboxOptVotetype
            value={optVoteTypeSelected}
            onChange={onOptVoteTypeSelected}
          ></ListboxOptVotetype>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <ListboxProvince
            value={provinceSelected}
            onChange={onProvinceSelected}
          ></ListboxProvince>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <ListboxDistinct
            value={amphurSelected}
            provinceSelected={provinceSelected}
            onChange={onAmphurSelected}
          ></ListboxDistinct>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <ListboxSubdistinct
            provinceSelected={provinceSelected}
            amphurSelected={amphurSelected}
            value={tambolSelected}
            onChange={onTamboldSelected}
          ></ListboxSubdistinct>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <ListboxOpts
            getOptTypeSelectedLists={getOptTypeSelectedLists}
            optTypeSelected={optTypeSelected}
            value={optSelected}
            provinceSelected={provinceSelected}
            amphurSelected={amphurSelected}
            tambolSelected={tambolSelected}
            onChange={onOptSelected}
          ></ListboxOpts>
        </GridItem>
        {props.children}
      </GridContainer>
    </GridItem>
    // </GridContainer>
  );
};
export default MainVotedate;
