import React, { createRef, useContext, useState } from "react";

import AtLeastOne from "utils/Validators/AtLeastOne";
import Button from "components/CustomButtons/Button.js";
import CustomInputWithValidator from "components/CustomInput/CustomInputWithValidator";
//import DatepickerVotedate from "components/Common/Datepicker";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOpttype from "components/Common/ListboxOpttype";
// Core Components
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import MustNotNull from "utils/Validators/MustNotNull";
import Slide from "@material-ui/core/Slide";
import { VoteDateContext } from "contexts/VoteDate";
// Context APIs
import { includeOpt } from "contexts/API/OPT/Opt";
// Material Core
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalIncludedOpt = props => {
  const { show, closeModal, data } = props;

  const [_listboxOptSelected, _setListboxOptSelected] = useState(null);
  const [_tambolSelected, _setTambolSelected] = useState(null);
  // const [remark, setRemark] = useState("");
  // const [dateChange, setDateChange] = useState(null);
  const { sweetAlerts } = useSweetAlert();

  const classes = useStyles();
  const optNameRef = createRef();
  const {
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected
  } = useContext(VoteDateContext);
  const onSubmit = () => {
    let optSubTypeId = null;

    let tambolId = null;
    let optName = null;

    if (_listboxOptSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภท อปท.");
      return;
    } else {
      optSubTypeId = _listboxOptSelected.id;
    }

    if ([5, 6, 7].includes(optSubTypeId)) {
      //   if ([4, 5].includes(optSubTypeId)) {
      if (_tambolSelected === null) {
        sweetAlerts.warning("กรุณาเลือกตำบล");
        return;
      } else {
        tambolId = _tambolSelected.id;
      }
    }

    if (optNameRef.current.value === "") {
      sweetAlerts.warning("กรุณาระบุชื่อ อปท.");
      return;
    } else {
      optName = optNameRef.current.value;
    }
    // if (dateChange === null) {
    //   sweetAlerts.warning("กรุณาระบุวันที่ยุบรวม");
    //   return;
    // }
    includeOpt(
      data.oldOptIds,
      optSubTypeId,
      optName,
      provinceSelected.id,
      amphurSelected.id,
      tambolId,
      1
    )
      .then(result => {
        sweetAlerts.success(
          "เพิ่มข้อมูลเรียบร้อยแล้ว กรุณากดค้นหาข้อมูลที่ต้องการ"
        );
        closeModal();
      })
      .catch(err => {
        sweetAlerts.error("เกิดข้อผิดพลาดขึ้น", err.response.data.desc);
        closeModal();
      });
  };

  return (
    <div className={show ? "modal" : "hide"}>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={show}
        fullWidth={true}
        transition={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          className={classes.titlemodal}
        >
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            {" " + props.title}
          </div>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <form className={classes.form}>
            <FormControl>
              <ListboxOpttype
                validators={[AtLeastOne]}
                onChange={(e, n) => {
                  _setListboxOptSelected(n);
                }}
              ></ListboxOpttype>

              <ListboxProvince
                value={provinceSelected}
                validators={[AtLeastOne]}
                onChange={onProvinceSelected}
                disabled
                // onChange={(e, n) => {
                //   _setProvinceSelected(n);
                // }}
              ></ListboxProvince>

              {_listboxOptSelected &&
              [2, 3, 4, 5, 6, 7].includes(_listboxOptSelected.id) ? (
                <ListboxDistinct
                  value={amphurSelected}
                  provinceSelected={provinceSelected}
                  onChange={onAmphurSelected}
                  disabled
                  title={"เลือกอำเภอ"}
                ></ListboxDistinct>
              ) : null}
              {_listboxOptSelected &&
              [5, 6, 7].includes(_listboxOptSelected.id) ? (
                <ListboxSubdistinct
                  value={_tambolSelected}
                  provinceSelected={provinceSelected}
                  amphurSelected={amphurSelected}
                  title={"เลือกตำบล"}
                  onChange={(e, n) => {
                    _setTambolSelected(n);
                  }}
                ></ListboxSubdistinct>
              ) : null}

              <CustomInputWithValidator
                name="optname"
                validators={[MustNotNull]}
                labelText="ชื่อ อปท. *"
                inputProps={{
                  inputRef: optNameRef
                }}
              />
              {/* <CustomInputWithValidator
                labelText="เหตุผล"
                id="remark"
                validators={[MustNotNull]}
                inputProps={{
                  value: remark,
                  onChange: e => {
                    setRemark(e.target.value);
                  }
                }}
              />
              <DatepickerVotedate
                name="birthday"
                label="วันที่ยุบรวม"
                value={dateChange}
                onChange={date => {
                  if (date) {
                    let arr = date.format().split("T");
                    setDateChange(arr[0]);
                  }
                }}
              ></DatepickerVotedate> */}
            </FormControl>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={closeModal}>ยกเลิก</Button>
              <Button onClick={onSubmit} variant="contained" color="success">
                บันทึก
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalIncludedOpt;
