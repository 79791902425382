import React, { useContext, useEffect, useState } from "react";
import { addOptForm, getListperson } from "contexts/API/OPT/Form61";

import Button from "components/CustomButtons/Button.js";
//import Checkbox from "@material-ui/core/Checkbox";
import Datepicker from "components/Common/Datepicker";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxForm6Type from "components/Common/ListboxForm6Type";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
//import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxPositionFollowVoteDate from "components/Common/ListboxPositionFollowVoteDate";
import ListboxVotedate from "components/Common/ListboxVotedate";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Success from "components/Typography/Success.js";
import Switch from "@material-ui/core/Switch";
import TableComponent from "components/Table/MaterialTable";
import Typography from "@material-ui/core/Typography";
import { VoteDateContext } from "contexts/VoteDate";
// Material Core
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { withStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalImportFile = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    },
    backButton: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }));

  const AntSwitch = withStyles(theme => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex"
    },
    switchBase: {
      padding: 2,

      color: theme.palette.common.white,
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.success.main,
          borderColor: theme.palette.success.main
        }
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none"
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#ff1228"
      //backgroundColor: theme.palette.common.red
    },
    checked: {}
  }))(Switch);
  const { sweetAlerts } = useSweetAlert();
  const [activeStep, setActiveStep] = React.useState(0);
  const [sinceDate,] = useState(null);

  const [noticeDate, setNoticeDate] = useState(null);

  const {
    onOptVoteTypeSelected,
    optVoteTypeSelected,
    provinceSelected,
    optTypeSelected,
    amphurSelected,
    tambolSelected,
    optSelected,
    positionFollowVoteDateSelected,
    onPositionFollowVoteDateSelected,
    onOptSelected,
    votedateSelected,
    onVotedateSelected,
    getOptTypeSelectedLists,
    form6TypeSelected,
    onForm6TypeSelected,
    setForm6TypeSelected
  } = useContext(VoteDateContext);
  //const optVoteIdRef = createRef();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  // const onChange = newValue => {
  //   setData({ ...data, form6TypeSelected: newValue.id });
  // };
  const { showModal, closeModal } = props;

  const classes = useStyles();

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  function getSteps() {
    return ["แบบฟอร์มมติที่ประชุม", "จัดการผู้ที่ได้รับการเลือกตั้ง"];
  }
  const handelSubmit = () => {
    let optPersonPres = [];
    let optPersonMem = [];

    for (let i = 0; i < data.length; i++) {
      if (positionFollowVoteDateSelected.id === 1) {
        let x = {
          optPersonPresId: data[i].optPersonPresId,
          optVoteSelectedStatusId: data[i].optVoteSelectedStatusId
        };
        optPersonPres.push(x);
      }
      if (positionFollowVoteDateSelected.id === 2) {
        let x = {
          optPersonMemId: data[i].optPersonMemId,
          optVoteSelectedStatusId: data[i].optVoteSelectedStatusId
        };
        optPersonMem.push(x);
      }

      //val.push(x);

      //   }
    }

    const getOptSubTypeId = () => {
      for (const [, v] of Object.entries(optTypeSelected)) {
        if (v.checked) {
          return parseInt(v.value);
        }
      }
      return -1;
    };

    const optTypeId = getOptSubTypeId();

    console.log("optTypeId>>", optTypeId)

    addOptForm(
      positionFollowVoteDateSelected,
      votedateSelected,
      optSelected,
      form6TypeSelected,
      optVoteTypeSelected,
      sinceDate,
      noticeDate,
      optPersonMem,
      optPersonPres,
      optTypeId
    )
      .then(result => {
        sweetAlerts.success("บันทึกข้อมูลเรียบร้อยแล้ว");
        props.onaddSuccess(result.data);
        setForm6TypeSelected(null);
        closeModal();
        // setData([...dataUpdate]);
      })
      .catch(err => {
        console.log(err);
        sweetAlerts.error("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
      });
  };
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxForm6Type
                positionTypeSelected={positionFollowVoteDateSelected}
                value={form6TypeSelected}
                onChange={onForm6TypeSelected}
              ></ListboxForm6Type>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Datepicker
                id="noticeDate"
                label="วันที่ประกาศ"
                value={noticeDate}
                onChange={date => setNoticeDate(date)}

              //     handleDateChange(date, "dateRegister")
              ></Datepicker>
            </GridItem>
            {/* <GridItem xs={12} sm={4} md={4}>
              <Datepicker
                id="sinceDate"
                label="วันที่มีผลบังคับ"
                value={sinceDate}
                onChange={date => setSinceDate(date)}

                //     handleDateChange(date, "dateRegister")
              ></Datepicker>
            </GridItem> */}
          </GridContainer>
        );
      case 1:
        return (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              direction="row"
              justify="space-between"
              alignItems="flex-end"
            >
              <GridItem xs={6} sm={6} md={6}></GridItem>
              <GridItem>
                <Button color="success" onClick={handelSubmit}>
                  บันทึกผล
                </Button>
              </GridItem>
            </GridContainer>
            <TableComponent
              title="รายชื่อผู้ที่ได้รับการเลือกตั้ง"
              columns={columns}
              data={data}
              components={{
                Container: props => <Paper {...props} elevation={0} />
              }}
              localization={{}}
              options={{
                paging: false,
                search: false,
                export: false,
                exportButton: false,
                defaultExpanded: true
              }}
            ></TableComponent>
          </GridItem>
        );

      default:
        return "Unknown stepIndex";
    }
  }

  useEffect(() => {
    let endPoint = `/opt/${optSelected.id}/votedate/${votedateSelected.id}/person/publish/vote?`;

    if (optVoteTypeSelected !== null) {
      endPoint += `optVoteTypeId=${optVoteTypeSelected.id}`;
    }

    if (positionFollowVoteDateSelected !== null) {
      endPoint += `&positionTypeId=${positionFollowVoteDateSelected.id}`;
    }

    getListperson(endPoint)
      // getListperson()
      .then(result => {
        let newArr1;

        if (result.data.data.pres.length !== 0) {
          const arr1 = result.data.data.pres;
          newArr1 = arr1.map(v => ({
            ...v,
            positionType: 1,
            PKID: v.optPersonPresId
          }));

          setData(newArr1);
        }
        let amphurIsnull = "";

        if (result.data.data.member.length !== 0) {
          const arr1 = result.data.data.member;

          amphurIsnull = arr1.find((o, i) => {
            if (o.amphurName === null) {
              return true; // stop searching
            }
          });

          // console.log(amphurIsnull);
          // console.log(Object.keys(amphurIsnull).length);

          newArr1 = arr1.map(v => ({
            ...v,
            newKey:
              v.amphurName === null
                ? v.areaNo
                : "อำเภอ " + v.amphurName + " เขตที่ " + v.areaNo,
            positionType: 2,
            PKID: v.optPersonMemId
          }));

          setData(newArr1);
        }

        if (data) {
          const _columns = [
            {
              title: "PKID",
              field: "PKID",
              hidden: true
            },
            {
              title: "หมายเลขผู้สมัคร",
              field: "applyNo",
              editable: "never",
              cellStyle: {
                minWidth: "70px"
              }
            },
            {
              title: "ตำแหน่ง",
              field: "positionType",
              editable: "never",
              render: rowData => {
                return rowData.positionType === 1 ? "ผ.ถ." : "ส.ถ.";
              }
            },
            optVoteTypeSelected.id !== 1
              ? {
                title: "เขต",
                field: "areaNo",
                editable: "never",
                defaultGroupSort: "asc",
                defaultGroupOrder: 0,
                cellStyle: {
                  backgroundColor: "#039be5",
                  color: "#FFF",
                  fontWeight: 500
                }
              }
              : {
                title: "เขตอำเภอ",
                // result.data.data.member &&
                // Object.keys(amphurIsnull).length > 0
                //   ? "เขต"
                //   : "เขตอำเภอ",
                field: "newKey",
                editable: "never",
                defaultGroupSort: "asc",
                defaultGroupOrder: 0,
                cellStyle: {
                  backgroundColor: "#039be5",
                  color: "#FFF",
                  fontWeight: 500
                }
              },

            {
              title: "ชื่อ สกุล",
              field: "",
              cellStyle: {
                minWidth: "200px"
              },
              render: rowData => {
                return (
                  rowData.titleDetail +
                  " " +
                  rowData.firstName +
                  " " +
                  rowData.lastName
                );
              }
            },
            {
              title: "คะแนน",
              field: "score",
              type: "numeric",
              editable: "never",
              render: rowData => {
                return !rowData.score ? 0 : rowData.score.toLocaleString();
              }
            },

            {
              title: "ผลการเลือกตั้ง",
              field: "optVoteSelectedStatusId",
              cellStyle: {
                minWidth: "200px",
                alignItems: "center"
              },
              render: rowData => {
                return (
                  <div>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item style={{ color: "black" }}>
                        ไม่ได้
                      </Grid>
                      <Grid item>
                        <AntSwitch
                          disabled={rowData.optPositionStatusId !== null}
                          checked={rowData.optVoteSelectedStatusId === 2}
                          name="optVoteSelectedStatusId"
                          onChange={e => {
                            // console.log(res.data.data.cadidates);
                            let applyPerson = newArr1;
                            let _chk = e.target.checked === true ? 2 : 3;
                            applyPerson[rowData.tableData.id][
                              e.target.name
                            ] = _chk;
                            setData([...applyPerson]);
                          }}
                        />
                      </Grid>
                      <Grid item style={{ color: "black" }}>
                        ได้
                      </Grid>
                    </Grid>
                  </div>
                );
              }
            },
            {
              title: "สถานะ",
              field: "optPositionStatusDetail"
            }
          ];

          setColumns(_columns);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optSelected, optVoteTypeSelected, positionFollowVoteDateSelected]);

  return (
    <div className={showModal ? "modal" : "hide"}>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        maxWidth="md"
        open={showModal}
        fullWidth={true}
        keepMounted
        transition={Transition}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          className={classes.titlemodal}
        >
          จัดการรายชื่อ
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <ListboxOptVotetype
                disabled
                value={optVoteTypeSelected}
                onChange={onOptVoteTypeSelected}
              ></ListboxOptVotetype>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <ListboxOpts
                disabled
                value={optSelected}
                getOptTypeSelectedLists={getOptTypeSelectedLists}
                optTypeSelected={optTypeSelected}
                provinceSelected={provinceSelected}
                amphurSelected={amphurSelected}
                tambolSelected={tambolSelected}
                onChange={onOptSelected}
              ></ListboxOpts>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <ListboxVotedate
                disabled
                value={votedateSelected}
                optSelected={optSelected}
                optVoteTypeSelected={optVoteTypeSelected}
                onChange={onVotedateSelected}
              ></ListboxVotedate>
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
              <ListboxPositionFollowVoteDate
                disabled
                value={positionFollowVoteDateSelected}
                optSelected={optSelected}
                optVoteTypeSelected={optVoteTypeSelected}
                votedateSelected={votedateSelected}
                onChange={onPositionFollowVoteDateSelected}
              ></ListboxPositionFollowVoteDate>
            </GridItem>
          </GridContainer>
          <br></br>
          <hr></hr>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography component={"span"}>
                  <Success
                    style={{
                      paddingTop: "20px",
                      textAlign: "center",
                      fontSize: "30px",
                      fontWeight: "bolder"
                    }}
                  >
                    บันทึกข้อมูลเรียบร้อย
                  </Success>
                </Typography>
                {/* <Button onClick={handleReset}>ยกเลิก</Button> */}
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions} component={"span"}>
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    กลับ
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    ""
                  ) : (
                    <Button
                      disabled={form6TypeSelected !== null ? false : true}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      ต่อไป
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          <br></br>
          <br></br>
          <hr></hr>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={closeModal}>ปิดหน้าต่าง</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalImportFile;
