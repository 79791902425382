import React, { useState } from "react";

import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";
import Checkbox from "@material-ui/core/Checkbox";
import Datepicker from "components/Common/Datepicker";
import { FileUpload } from "components/Common/FileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem.js";
import ListboxPositionStatus from "components/Common/ListboxPositionStatus";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useEffect } from "react";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const PresidentDetailVotedate = props => {
  const { sweetAlerts } = useSweetAlert();
  //const mindate = new Date();
  const [presUrlFile, setPresUrlFile] = useState();
  const [presNameFile, setPresNameFile] = useState();

  const [presChecked, setPresChecked] = useState(false);

  const onPresChecked = e => {
    setPresChecked(e.target.checked);
    props.setPresClick(e.target.checked);
  };

  useEffect(() => {
    if (props.optVoteTypeSelected.id === 2) {
      props.setPresClick(false);
      setPresChecked(false);
    }
  }, [props.optVoteTypeSelected]);
  return (
    <Card>
      <CardHeader>
        <h4>รายละเอียดจัดเลือกตั้งของ ผ.ถ.</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="postionTypeId"
                  onChange={onPresChecked}
                  checked={presChecked}
                  inputRef={props.presCheckedRef}
                />
              }
              label="เลือก ผ.ถ."
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <ListboxPositionStatus
              disabled={!presChecked}
              name="presidentPositionStatus"
              // defaultValue={
              //   props.optVoteTypeSelected.id === 1 ? { id: 2 } : null
              // }
              // disabled={props.optVoteTypeSelected.id === 1 ? true : false}
              value={props.presPositionStatusSelected}
              onChange={props.onPresPositionStatusSelected}
            ></ListboxPositionStatus>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Datepicker
              disabled={!presChecked}
              //   minDate={mindate}
              id="date-picker-president"
              label="วันที่มีเหตุต้องจัดการเลือกตั้ง"
              value={props.presEffectedDate}
              onChange={props.onPresEffectedDate}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <FileUpload
              disabled={!presChecked}
              id="file-upload-1"
              label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
              remove
              download
              fileName={presNameFile}
              url={presUrlFile}
              onFileRemove={() => {
                setPresUrlFile("");
                setPresNameFile("");
              }}
              onFileHandler={(e, f, progressCb) => {
                uploadFile("opt", f, progressCb)
                  .then(r => {
                    const data = r.data.data;

                    if (data.fileId) {
                      setPresUrlFile(getFileDownloadUrl("opt", data.fileId));
                      setPresNameFile(data.fileName);
                      props.setPresUUIDFile(data.fileId);
                      props.setPresNameFile(data.fileName);
                      // props.onFileMemUpload();
                    }

                    sweetAlerts.success("แนบไฟล์เรียบร้อย");
                  })
                  .catch(err => {
                    console.log(err);
                  });
              }}
              fileType="application/pdf"
              btnName={<>Browse</>}
              btnProps={{
                color: "primary"
              }}
            />
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="หมายเหตุ"
              id="presRemark"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: props.presRemark || "",
                disabled: !presChecked
              }}
            />
          </GridItem> */}
        </GridContainer>
      </CardBody>
    </Card>
  );
};
export default PresidentDetailVotedate;
