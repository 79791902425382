import React from "react";
import NotificationListItem from "./NotificationListItem"
import Divider from "@material-ui/core/Divider";
import Sortable from "./Sortable";

/* EmailList contains a list of Email components */
const NotificationList = ({sortBy, onSortBy, notifications, onNotificationSelected, selectedNotificationId}) => {
    if (!notifications) {
        return (
            <>
                <Sortable currentSortBy={sortBy} onSortBy={(sortBy) => onSortBy(sortBy)}/>
                <Divider/>
                <div className="email-list empty">
                    ไม่มีข้อความ
                </div>
            </>
        );
    }

    return (
        <>
            <Sortable currentSortBy={sortBy} onSortBy={(sortBy) => onSortBy(sortBy)}/>
            <Divider/>
            <div className="email-list">
                {
                    notifications.map((item, key) => {
                        return (
                            <NotificationListItem
                                key={key}
                                onClicked={(id) => {
                                    onNotificationSelected(id);
                                }}
                                item={item}
                                selected={selectedNotificationId === item.id}/>
                        );
                    })
                }
            </div>
        </>
    );
};

export default NotificationList;