import "perfect-scrollbar/css/perfect-scrollbar.css";

import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import Sidebar from "components/Sidebar/Sidebar.js";
import { UserInfoContext } from "contexts/users";
import cx from "classnames";
import { getRoutes } from "routes.js";
//import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/ectpl_logo_new.png";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { useDispatch, useSelector } from "react-redux";
import { findAllNotifications } from "../contexts/API/Notifications/Notifications";
import Popups from "../components/Popups/Popups";
import LockScreenPage from "../components/LogScreen/";

let ps;

const useStyles = makeStyles(styles);

export default function Main(props) {
  const { ...rest } = props;
  const { userInfo, isExpired, canReLogin } = useContext(UserInfoContext);
  const routes = userInfo ? getRoutes(userInfo.pageIds) : [];
  const dispatch = useDispatch();
  const notifications = useSelector((state => state.notifications)).notifications || [];
  const switchRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return switchRoutes(prop.views);
      }
      if (prop.layout === "/main") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  //const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  // const [color, setColor] = React.useState("blue");
  const [color] = React.useState("orange");
  const [bgColor] = React.useState("behance");
  //const [bgColor, setBgColor] = React.useState("blue");
  //const [bgColor, setBgColor] = React.useState("blue");
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  // functions for changeing the states from components
  /* const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  }; */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  const getActiveRoute = routes => {
    let activeRoute = "";

    for (let i = 0; i < routes.length; i++) {

      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        let main_memu = routes[i].name;
        if (collapseActiveRoute !== activeRoute) {
          return main_memu + " > " + collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          let name = routes[i].name;
          return name;
        }
      }
    }
    return activeRoute;
  };
  const loginSuccess = () => {
    if (isExpired()) {
      if (canReLogin()) {
        return <LockScreenPage />;
      } else {
        return <Redirect to="/login" />;
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (notifications.length === 0) {
        findAllNotifications("").then(r => {
          if (r.data && r.data.data && r.data.data.length >= 0) {
            dispatch({ type: 'notifications/insert', payload: r.data.data })
          }
        }).catch(err => console.error(err))
      }
    }, 2000);
  }, [notifications])

  return (
    <div className={classes.wrapper}>
       <Popups />
      <Sidebar
        routes={routes}
        //logoText={"ECTPL"}
        logo={logo}
        //image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}

        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {window.scrollTo(0, 0)}
                {switchRoutes(routes)}
                <Redirect from="/main" to="/main/home" />
              </Switch>
            </div>
          </div>
        }
        <Footer fluid />
      </div>
      {loginSuccess()}
    </div>
  );
}
