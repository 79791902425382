import Autocomplete from "@material-ui/lab/Autocomplete";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
import { Validate } from "utils/Validators/Validate";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxInspectApplyDateSingle = props => {

  const [helperText] = React.useState("");
  const [valid, setValid] = React.useState();
  let applyDateId =
    props.inspectApplyDateSelected &&
      props.inspectApplyDateSelected.id &&
      props.inspectApplyDateSelected.id !== undefined
      ? props.inspectApplyDateSelected.id
      : 0;

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + `/inspector/applyDate/${applyDateId}`,
    [props.inspectApplyDateSelected]
  );

  const customVoteDate = old => {
    let d = new Date(old);
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric"
    };
    return d.toLocaleDateString("th-TH", options);
  };

  let content = (
    <TextField disabled label="วันรับสมัคร" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return {
        id: v.key_apply_date,
        value: customVoteDate(v.apply_date)
      };
    });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        id="ListboxInspectCadidateDateSingle"
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        options={data}
        value={props.value}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={props.onChange}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label="วันรับสมัคร"
              margin="normal"
              error={valid}
              fullWidth
              helperText={helperText}
              onChange={Validate(
                params.inputProps.value,
                props.required,
                setValid
              )}
              required={props.required}
            />
          );
        }}
      />
    );
  }
  return content;
};
export default ListboxInspectApplyDateSingle;
