import React, { useContext, useEffect, useState } from "react";
import { editoptPerson, getPersonbyId } from "contexts/API/OPT/OptPerson";

import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DatepickerVotedate from "components/Common/Datepicker";
import { FileUpload } from "components/Common/FileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
//import common
import ListboxEducation from "components/Common/ListboxEducation.js";
import ListboxOptCareer from "components/Common/ListboxOptCareer";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxOpttype from "components/Common/ListboxOpttype";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxProvince from "components/Common/ListboxProvince.js";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
// Common
import ListboxOptTitleList from "components/Common/ListboxOptTitleList";
import ListboxVotedate from "components/Common/ListboxVotedate";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import Radio from "@material-ui/core/Radio";
import TableHistoryAddr from "./TableHistoryAddr";
import TableHistoryName from "./TableHistoryName";
import TextField from "@material-ui/core/TextField";
import { VoteDateContext } from "contexts/VoteDate";
import { fileInfo } from "contexts/API/FileManagement/fileInfo";
//import getAge from "utils/CalculateAge";
import getAgePerson from "utils/CalculateAgeOpt";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTextField-root": {
      //margin: theme.spacing(1),
      fontSize: "16px"
    }
  }
}));
const EditOptPerson = ({ pageProps, setPageProps }) => {
  const { rowData } = pageProps.props;

  const { sweetAlerts } = useSweetAlert();
  const classes = useStyles();
  const backtoMainHandler = () => {
    setPageProps({ active: "OptPerson", action: "cancel" });
  };

  let initialState = {
    applyNo: "",
    personId: "",
    idCard: "",
    birthdate: null,
    registerDatetime: null,
    age: null,
    firstName: "",
    lastName: "",
    titleId: null,
    tel: "",
    addr1: "",

    addr1ProvinceId: null,
    addr1AmphurId: null,
    addr1TambolId: null,
    addr1Postcode: "",
    genderId: null,
    lowerBachelorDegree: false,
    bachelorDegree: false,
    masterDegree: false,
    phdDegree: false,
    personNameHist: null,
    personAddrHist: null,
    from4_1UUID: ""
  };
  const [data, setData] = useState(initialState);
  //const [data, setData] = useState(null);
  const [, setIsNameUpdate] = useState(false);
  const [, setIsAddrUpdate] = useState(false);
  const [namehistory, setNamehistory] = useState(null);
  const [addrhistory, setAddrhistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [oldData, setOlddata] = useState(null);
  const [addr1ProvinceId, setAddr1ProvinceId] = useState(null);
  const [addr1AmphurId, setAddr1AmphurId] = useState(null);
  const [, setAddr1TambolId] = useState(null);
  const [careerType, setCareerType] = useState({ id: 0, value: "" });
  const [, setTitleId] = useState(null);
  const [helpertextcareer, setHelpertextcareer] = useState("");
  const [isFound] = useState(false);
  const [lastNameState, setLastNameState] = useState("");
  const [firstNameState, setFirstNameState] = useState("");

  const [postCodeState, setPostCodeState] = useState("");
  const [telState, setTelState] = useState("");
  const [addr1State, setAddr1State] = useState("");
  const [helpertexteducate, setHelpertexteducate] = useState("");
  const [helpertexttitle, setHelpertexttitle] = useState("");
  const [helpertextGender, setHelpertextGender] = useState("");
  const [uploadFileName, setUploadFileName] = useState(null);
  const [uploadFileUUID, setUploadFileUUID] = useState(null);
  const [from4_1UUID, setFrom4_1UUID] = useState(null);

  const onCareerType = (evt, newValue) => {
    setCareerType(newValue);
    setData({ ...data, careerTypeId: newValue ? newValue.id : 0 });
  };
  const onadd1ProvinceSelected = (evt, newValue) => {
    setAddr1ProvinceId(newValue);
    setAddr1AmphurId(null);
    setAddr1TambolId(null);
    setData({ ...data, addr1ProvinceId: newValue ? newValue.id : 0 });
  };
  const onadd1AmphurSelected = (evt, newValue) => {
    setAddr1AmphurId(newValue);
    setAddr1TambolId(null);
    setData({ ...data, addr1AmphurId: newValue ? newValue.id : 0 });
  };
  const onadd1TambolSelected = (evt, newValue) => {
    setAddr1TambolId(newValue);
    setData({ ...data, addr1TambolId: newValue ? newValue.id : 0 });
  };

  async function getData(rowData) {
    const data = await getPersonbyId(rowData)
      .then(result => {
        if (Object.keys(result.data.data).length > 0) {
          return result.data.data[0];
        } else {
          sweetAlerts.error("ไม่พบเลขประจำตัวประชาชนนี้ในระบบ");
        }
      })
      .catch(e => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", e.toString());
      });
    return data;
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await getData(rowData);
      setOlddata(data);
      //console.log("data on load", data);
      if (data) {
        if (data["optPersonMem"]) {
          if (data.optPersonMem[0].from4_1UUID !== null && data.optPersonMem[0].from4_1UUID !== "") {
            let resp = await fileInfo("opt", data.optPersonMem[0].from4_1UUID);
            let file = resp.data;
            let fileName = null;
            if (file.data && file.data.fileName) {
              fileName = file.data.fileName;
              setUploadFileName(fileName);
              setUploadFileUUID(data.optPersonMem[0].from4_1UUID);
              setFrom4_1UUID(data.optPersonMem[0].from4_1UUID);
              setData({
                ...data,
                from4_1UUID: from4_1UUID
              });
            }
          }
          setData({
            ...data,
            applyNo: data.optPersonMem[0].applyNo,
            registerDatetime: data.optPersonMem[0].registerDatetime
          });
        }
        if (data["optPersonPres"]) {
          if (data.optPersonPres[0].from4_1UUID !== null && data.optPersonPres[0].from4_1UUID !== "") {
            let resp = await fileInfo("opt", data.optPersonPres[0].from4_1UUID);
            let file = resp.data;
            let fileName = null;
            if (file.data && file.data.fileName) {
              fileName = file.data.fileName;
              setUploadFileName(fileName);
              setUploadFileUUID(data.optPersonPres[0].from4_1UUID);
              //console.log("fileName", fileName);
              setFrom4_1UUID(data.optPersonPres[0].from4_1UUID);
              setData({
                ...data,
                from4_1UUID: from4_1UUID
              });
            }
          }
          setData({
            ...data,
            applyNo: data.optPersonPres[0].applyNo,
            registerDatetime: data.optPersonPres[0].registerDatetime
          });
        }

        setData({
          ...data,

          age: getAgePerson(data.birthdate, votedateSelected.id)
        });
        setNamehistory(data.personNameHistory);
        setAddrhistory(data.personAddrHistory);
        setTitleId({ id: data.titleId });
        setAddr1ProvinceId({ id: data.addr1ProvinceId });
        setAddr1AmphurId({ id: data.addr1AmphurId });
        setAddr1TambolId({ id: data.addr1TambolId });
        setCareerType({ id: data.careerTypeId, value: data.careerTypeName });
        setLoading(false);

        console.log("data >>>> ", data);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    provinceSelected,
    amphurSelected,
    tambolSelected,

    onListboxOptSelected,

    onProvinceSelected,
    onAmphurSelected,
    onTambolSelected,
    positionTypeSelected,
    optSelected,
    votedateSelected,
    optVoteTypeSelected,
    onVotedateSelected,
    areaSelected,

    onPostionTypeSelected,

    optTypeSelected,
    onOptVoteTypeSelected,
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);
  const optIdLists = getOptTypeSelectedLists();
  useEffect(() => {
    if (oldData !== null) {
      if (
        oldData.firstName !== data.firstName ||
        oldData.lastName !== data.lastName ||
        oldData.titleId !== data.titleId
      ) {
        setData({
          ...data,
          personNameHist: {
            oldFirstName: oldData.firstName,
            oldLastName: oldData.lastName,
            oldTitleId: oldData.titleId
          }
        });
        setIsNameUpdate(true);
      }
      // console.log(data.personNameHist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.firstName, data.lastName, data.titleId]);

  useEffect(() => {
    //action === "add" ||
    if (
      areaSelected ||
      votedateSelected ||
      data.applyNo ||
      data.registerDatetime
    ) {
      let _optPersonMem = {
        optVoteTypeId: optVoteTypeSelected ? optVoteTypeSelected.id : 0,
        optAreaId: areaSelected ? areaSelected.id : 0,
        voteDate: votedateSelected ? votedateSelected.id : "",
        applyNo: data.applyNo,
        registerDatetime: data.registerDatetime,
        msOptId: optSelected ? optSelected.id : "",
        from4_1UUID: from4_1UUID
      };
      if (rowData.optPositionTypeId === 1) {
        setData({
          ...data,
          optPersonPres: { ..._optPersonMem }
        });
      }
      if (rowData.optPositionTypeId === 2) {
        setData({
          ...data,
          optPersonMem: { ..._optPersonMem }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areaSelected,
    votedateSelected,
    data.personId,
    data.registerDatetime,
    optSelected,
    isFound,
    data.from4_1UUID, from4_1UUID
  ]);


  useEffect(() => {
    if (oldData !== null) {
      if (
        oldData.addr1 !== data.addr1 ||
        oldData.addr1ProvinceId !== data.addr1ProvinceId ||
        oldData.addr1AmphurId !== data.addr1AmphurId ||
        oldData.addr1TambolId !== data.addr1TambolId
      ) {
        setData({
          ...data,
          addr1Hist: {
            oldAddr1: oldData.addr1,
            oldAddr1TambolId: oldData.addr1TambolId
          }
        });
        setIsAddrUpdate(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.addr1,
    data.addr1ProvinceId,
    data.addr1AmphurId,
    data.addr1TambolId
  ]);
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  const editOptPerson = () => {
    // console.log(data);
    if (data.genderId === null) {
      setHelpertextGender("กรุณาระบุเพศ*");
    }

    if (data.careerTypeId === 0) {
      setHelpertextcareer("กรุณาระบุอาชีพ*");
    }
    if (data.addr1Postcode === null) {
      setPostCodeState("กรุณาระบุรหัสไปรษณีย์*");
    }

    if (lastNameState === "" && data.lastName === "") {
      setLastNameState("error");
    }
    if (firstNameState === "" && data.firstName === "") {
      setFirstNameState("error");
    }
    if (telState === "" && data.tel === "") {
      setTelState("error");
    }
    if (addr1State === "" && data.addr1 === "") {
      setAddr1State("error");
    }
    if (data.titleId === 0) {
      setHelpertexttitle("กรุณาระบุคำนำหน้าชื่อ*");
    }

    if (data.educationalId === 0 || data.educationalId === null) {
      setHelpertexteducate("กรุณาระบุระดับการศึกษา*");
    }

    let age = getAgePerson(data.birthdate, votedateSelected.id);

    /*   if (getAge(data.birthdate) < 45) {
        sweetAlerts.danger(`อายุต้องมากกว่า 45 ปี`);
      } */
    if (age < 25 && rowData.optPositionTypeId === 2) {
      sweetAlerts.danger(`อายุต้องมากกว่า 25 ปี`);
      return false;
    }


    if (age < 35 && rowData.optPositionTypeId === 1) {
      sweetAlerts.danger(`อายุต้องมากกว่า 35 ปี`);
      return false;
    }

    //console.log("registerDatetime >>", data.registerDatetime)
    if (data.registerDatetime === null) {
      sweetAlerts.warning(`กรุณาระบุวันรับสมัคร`);
      return false;
    }

    if (
      helpertextGender === "" &&
      helpertexttitle === "" &&
      data.addr1 &&
      data.tel &&
      data.lastName &&
      data.firstName &&
      //getAge(data.birthdate) >= 25 &&
      data.titleId &&
      data.educationalId &&
      data.addr1Postcode
    ) {
      // if (action === "edit") {
      //action === "edit" ||
      const _data = data;
      // if (isNameUpdate === false) {
      //   setData(delete data["personNameHistory"]);
      // }
      setData(delete data["personAddrHistory"]);
      setData(delete data["personNameHistory"]);
      console.log(">>>>>data>>>>", data);
      editoptPerson(data, rowData)
        .then(result => {
          setData(_data);

          sweetAlerts.success(`แก้ไขข้อมูลสำเร็จแล้ว`);
          // resolve();
        })
        .catch(err => {
          if (err.response.data.code !== 20000) {
            sweetAlerts.danger(
              `ไม่สามารถแก้ไขข้อมูลได้`,
              err.response.data.desc
            );
          }
        });
    } else {
      sweetAlerts.warning(`กรุณากรอกข้อมูลให้ครบถ้วน`);
    }
    //}
  };
  let content = <LoadingData />;

  if (loading === false) {
    content = (
      <>
        <form className={classes.root} noValidate autoComplete="off">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ width: "100%" }}>
                <CardHeader color="primary" text>
                  <CardText color="primary">
                    <h4>จัดการข้อมูลผู้สมัคร</h4>
                  </CardText>
                </CardHeader>

                <CardBody style={{ width: "100%" }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#2d00b3"
                            }}
                          >
                            ข้อมูล อปท.
                          </span>
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxOptVotetype
                        disabled
                        value={optVoteTypeSelected}
                        onChange={onOptVoteTypeSelected}
                      ></ListboxOptVotetype>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxOpttype
                        disabled={true}
                        // defaultValue={{ id: rowData.optSubTypeShortId }}
                        defaultValue={
                          rowData.optSubTypeShortId === null
                            ? { id: parseInt(optIdLists.toString()) }
                            : { id: rowData.optSubTypeShortId }
                        }
                        onChange={onListboxOptSelected}
                      ></ListboxOpttype>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxProvince
                        disabled
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                      ></ListboxProvince>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxDistinct
                        disabled
                        value={amphurSelected}
                        provinceSelected={provinceSelected}
                        onChange={onAmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxSubdistinct
                        disabled
                        amphurSelected={amphurSelected}
                        value={tambolSelected}
                        onChange={onTambolSelected}
                      ></ListboxSubdistinct>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxOpts
                        disabled
                        value={optSelected}
                        getOptTypeSelectedLists={getOptTypeSelectedLists}
                        optTypeSelected={optTypeSelected}
                        provinceSelected={provinceSelected}
                        amphurSelected={amphurSelected}
                        tambolSelected={tambolSelected}
                        onChange={null}
                      ></ListboxOpts>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxPositionType
                        disabled
                        value={positionTypeSelected}
                        onChange={onPostionTypeSelected}
                      ></ListboxPositionType>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxVotedate
                        disabled
                        value={votedateSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onVotedateSelected}
                      ></ListboxVotedate>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="areaNo"
                        default
                        required
                        labelText="เขต"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          defaultValue:
                            rowData.areaNo !== null
                              ? `เขตที่` + rowData.areaNo
                              : ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>
                </CardBody>

                <CardBody style={{ width: "100%" }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#2d00b3"
                            }}
                          >
                            ข้อมูลส่วนบุคคล
                          </span>
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <NumberFormat
                        style={{
                          width: "100%",
                          margin: "0 0 17px 0",
                          paddingTop: "27px"
                        }}
                        disabled
                        value={data.idCard}
                        placeholder="เลขประจำตัวประชาชน"
                        customInput={TextField}
                        format="# #### ##### ## #"
                        mask="X"
                        fullWidth
                      // onValueChange={onIDCardHandler}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <Button
                        disabled
                        variant="contained"
                        color="success"
                      // onClick={() => checkCitizenId(data.idCard)}
                      >
                        ตรวจสอบ
                      </Button>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="applyNo"
                        // value={data.applyNo}
                        default
                        required
                        labelText="หมายเลขผู้สมัคร"
                        formControlProps={{
                          fullWidth: true
                        }}
                        helpertext="กรุณาระบุหมายเลขผู้สมัคร*"
                        inputProps={{
                          defaultValue: rowData.applyNo,
                          disabled: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <DatepickerVotedate
                        name="registerDatetime"
                        label="วันที่รับสมัคร"
                        value={data.registerDatetime}
                        // disabled={true}
                        onChange={
                          date => {
                            let arr = date.format().split("T");

                            setData({
                              ...data,
                              registerDatetime: arr[0]
                            });
                          }
                          //     handleDateChange(date, "dateRegister")
                        }
                      ></DatepickerVotedate>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <DatepickerVotedate
                        name="birthday"
                        label="วัน/เดือน/ปี เกิด"
                        value={data.birthdate}
                        onChange={date => {
                          let arr = date.format().split("T");
                          let calAge = getAgePerson(date.format(), votedateSelected.id);
                          if (calAge < 25 && rowData.optPositionTypeId === 2) {
                            sweetAlerts.warning(`อายุต้องมากกว่า 25 ปี`);
                          } else if (calAge < 35 && rowData.optPositionTypeId === 1) {
                            sweetAlerts.warning(`อายุต้องมากกว่า 35 ปี`);
                          } else {
                            setData({
                              ...data,
                              birthdate: arr[0],
                              age: calAge
                            });
                          }
                        }}
                      ></DatepickerVotedate>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="age"
                        labelText="อายุ"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: getAgePerson(data.birthdate, votedateSelected.id),
                          //value: data.age || "",
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                  >
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              data.genderId === "true" || data.genderId === true
                            }
                            value={true}
                            onClick={e => {
                              if (e.target.checked) {
                                setHelpertextGender("");
                              } else {
                                setHelpertextGender("กรุณาระบุเพศ*");
                              }
                              setData({ ...data, genderId: e.target.value });
                            }}
                            // onClick={
                            //   e =>
                            //     setData({ ...data, genderId: e.target.value })
                            //   //  handleGenderChange(e, "genderId")
                            // }
                            id="genderId"
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        label="ชาย"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            onClick={e => {
                              if (e.target.checked) {
                                setHelpertextGender("");
                              } else {
                                setHelpertextGender("กรุณาระบุเพศ*");
                              }
                              setData({ ...data, genderId: e.target.value });
                            }}
                            // onClick={
                            //   e =>
                            //     setData({ ...data, genderId: e.target.value })
                            //   // e => handleGenderChange(e, "genderId")
                            // }
                            checked={
                              data.genderId === "false" ||
                              data.genderId === false
                            }
                            value={false}
                            id="genderId"
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        label="หญิง"
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextGender}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxOptTitleList
                        defaultValue={{ id: data.titleId }}
                        onChange={(evt, newValue) => {
                          setTitleId(newValue);

                          if (newValue) {
                            setHelpertexttitle("");

                            setData({
                              ...data,
                              titleId: newValue ? newValue.id : 0
                            });
                          } else {
                            setHelpertexttitle("กรุณาระบุคำนำหน้าชื่อ");
                          }
                        }}
                      ></ListboxOptTitleList>

                      <FormHelperText style={{ color: "red" }}>
                        {helpertexttitle}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          firstNameState === "success" ||
                          data.firstName !== null
                        }
                        error={firstNameState === "error"}
                        id="firstName"
                        default
                        required
                        labelText="ชื่อ"
                        formControlProps={{
                          fullWidth: true
                        }}
                        helpertext="กรุณาระบุชื่อ*"
                        inputProps={{
                          value: data.firstName,
                          onChange: e => {
                            if (verifyLength(e.target.value, 1)) {
                              setFirstNameState("success");
                            } else {
                              setFirstNameState("error");
                            }
                            setData({ ...data, firstName: e.target.value });
                          }
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="lastName"
                        success={
                          lastNameState === "success" || data.lastName !== null
                        }
                        error={lastNameState === "error"}
                        default
                        required
                        labelText="นามสกุล"
                        helpertext="กรุณาระบุนามสกุล*"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: data.lastName || "",

                          onChange: e => {
                            if (verifyLength(e.target.value, 1)) {
                              setLastNameState("success");
                            } else {
                              setLastNameState("error");
                            }
                            setData({ ...data, lastName: e.target.value });
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="tel"
                        success={telState === "success" || data.tel !== null}
                        error={telState === "error"}
                        default
                        required
                        labelText="เบอร์โทรศัพท์"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: data.tel || "",
                          onChange: e => {
                            if (
                              verifyNumber(e.target.value) &&
                              verifyLength(e.target.value, 9)
                            ) {
                              setTelState("success");
                            } else {
                              setTelState("error");
                            }
                            setData({ ...data, tel: e.target.value });
                          }
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          addr1State === "success" || data.addr1 !== null
                        }
                        error={addr1State === "error"}
                        helpertext="กรุณาระบุที่อยู่ตามทะเบียนบ้าน*"
                        id="addr1"
                        default
                        required
                        labelText="ที่อยู่ตามทะเบียนบ้าน"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: data.addr1 || "",
                          onChange: e => {
                            if (verifyLength(e.target.value, 1)) {
                              setAddr1State("success");
                            } else {
                              setAddr1State("error");
                            }
                            setData({ ...data, addr1: e.target.value });
                            //  handleChange(e, "addr1");
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxProvince
                        validators={[MustNotNull]}
                        defaultValue={{
                          id: data.addr1ProvinceId
                        }}
                        onChange={onadd1ProvinceSelected}
                      ></ListboxProvince>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxDistinct
                        validators={[MustNotNull]}
                        defaultValue={{
                          id: data.addr1AmphurId
                        }}
                        provinceSelected={addr1ProvinceId}
                        onChange={onadd1AmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxSubdistinct
                        validators={[MustNotNull]}
                        defaultValue={{ id: data.addr1TambolId || -1 }}
                        provinceSelected={addr1ProvinceId}
                        amphurSelected={addr1AmphurId}
                        onChange={onadd1TambolSelected}
                      ></ListboxSubdistinct>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <NumberFormat
                        style={{
                          width: "100%",
                          margin: "0 0 17px 0",
                          paddingTop: "27px"
                        }}
                        value={data.addr1Postcode}
                        placeholder="รหัสไปรษณีย์"
                        customInput={TextField}
                        format="#####"
                        mask="X"
                        fullWidth
                        onValueChange={v => {
                          if (v.value) {
                            setPostCodeState("");
                          } else {
                            setPostCodeState("กรุณาระบุรหัสไปรษณีย์");
                          }
                          setData({ ...data, addr1Postcode: v.value });
                          console.log(data);
                        }}
                      />
                      <label style={{ color: "red" }}>{postCodeState}</label>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxOptCareer
                        validators={[MustNotNull]}
                        value={careerType}
                        onChange={onCareerType}
                        controll={true}
                      ></ListboxOptCareer>
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextcareer}
                      </FormHelperText>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#2d00b3"
                            }}
                          >
                            ประวัติการศึกษา
                          </span>
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormHelperText style={{ color: "red" }}>
                        {helpertexteducate}
                      </FormHelperText>
                      <ListboxEducation
                        defaultValue={{ id: data.educationalId }}
                        onChange={(evt, newValue) => {
                          if (newValue) {
                            setHelpertexteducate("");

                            setData({
                              ...data,
                              educationalId: newValue ? newValue.id : 0
                            });
                          } else {
                            setHelpertexteducate("กรุณาระบุระดับการศึกษา");
                          }
                        }}
                      ></ListboxEducation>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#2d00b3"
                            }}
                          >
                            เอกสารผู้สมัคร
                          </span>
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <FileUpload
                        id="file-upload-person"
                        label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                        fileName={uploadFileName}
                        remove
                        onFileRemove={() => {
                          setUploadFileName("");
                          setUploadFileUUID("");
                          setData({
                            ...data,
                            from4_1UUID: null
                          });
                        }}
                        url={getFileDownloadUrl("opt", uploadFileUUID)}
                        download
                        onFileHandler={(e, f, p) => {
                          uploadFile("opt", f, p)
                            .then(r => {
                              const file = r.data.data;
                              sweetAlerts.success("แนบไฟล์เรียบร้อย");
                              setUploadFileName(file.fileName);
                              setFrom4_1UUID(file.fileId);
                              setData({ ...data, from4_1UUID: file.fileId });
                            })
                            .catch(err => {
                              console.log(err);
                            });
                        }}
                        fileType="application/pdf"
                        btnName={<>Browse</>}
                        btnProps={{
                          color: "primary"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <GridItem xs={6} sm={6} md={6}></GridItem>
                    <GridItem>
                      <Button onClick={backtoMainHandler}>
                        กลับไปหน้าจัดการข้อมูลผู้สมัคร
                      </Button>
                      <Button color="success" onClick={editOptPerson}>
                        บันทึกข้อมูลผู้สมัคร
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <h4>ประวัติการเปลี่ยนแปลงชื่อ</h4>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TableHistoryName history={namehistory} />
            </GridItem>
          </GridContainer>

          <h4>ประวัติการเปลี่ยนแปลงที่อยู่</h4>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TableHistoryAddr history={addrhistory} />
            </GridItem>
          </GridContainer>
        </form>
      </>
    );
  }
  return content;
};
export default EditOptPerson;
