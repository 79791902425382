// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Education from "./Education";
import EducationMajor from "./EducationMajor";
import React from "react";
import University from "./University";
// import VoteType from "./VoteType";
import { VoteDateContextProvider } from "contexts/VoteDate";
import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

const styles = {
  textCenter: {
    textAlign: "center"
  },
  //tabRoot: { backgroundColor: "#0a6" },
  span: {
    backgroundColor: "#aa0",
    fontSize: "22px"
  }
};

const useStyles = makeStyles(styles);

const Index = props => {
  const classes = useStyles();
  /* 
  const addOptSchema = yup.object().shape({
    provinceId: yup.string().required("This field is required."),
    amphurId: yup.string().required("This field is required.")
  }); */

  //   const addOptForm = useForm({
  //     validationSchema: addOptSchema
  //   });

  return (
    <VoteDateContextProvider>
      <div className={classes.tabcustom}>
        <form>
          <CustomTabs
            styles={customTabsStyles}
            headerColor="warning"
            tabs={[
              {
                tabName: "มหาวิทยาลัย",
                tabContent: <University />
              },
              {
                tabName: "สาขาวิชา",
                tabContent: <EducationMajor />
              },
              {
                tabName: "ระดับการศึกษา",
                tabContent: <Education />
              }

              //   {
              //     tabName: "มาตรา",
              //     tabContent: <LawSection />
              //   },
              //   {
              //     tabName: "ประเภทการเลือกตั้ง",
              //     tabContent: <VoteType />
              //   }
            ]}
          />
        </form>
      </div>
    </VoteDateContextProvider>
  );
};

export default Index;
