import { AccountTree, VpnKey } from "@material-ui/icons";
import React, { useContext, useEffect } from "react";

import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
// core components
import Button from "components/CustomButtons/Button.js";
import ChangePassword from "../pages/changePassword/ChangePassword";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import NotificationsIcon from "@material-ui/icons/Notifications";
//import Notifications from "@material-ui/icons/Notifications";
import Paper from "@material-ui/core/Paper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Popper from "@material-ui/core/Popper";
import PropTypes from "prop-types";
import SiteMap from "../Sitemap/Sitemap";
import { UserInfoContext } from "contexts/users";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { markReadNotifications } from "../../contexts/API/Notifications/Notifications";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles(styles);
const MAX_UNREAD = 20; // only 20 unread messages will be displayed

export default function HeaderLinks(props) {
  let history = useHistory();
  const notificationsCountUnread =
    useSelector(state => state.notifications.notificationsCountUnread) || 0;
  const notificationList = useSelector(state => state.notifications)
    .notifications;
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openSiteMap, setOpenSiteMap] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);

  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const onClickedNotification = v => {
    console.log(v);
    markReadNotifications(v.id)
      .then(() => {
        setOpenNotification(null);
        history.push(v.redirectUrl);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const { userInfo, logout } = useContext(UserInfoContext);
  let firstname = "";

  if (
    userInfo !== undefined &&
    userInfo !== null &&
    userInfo.tokenInfo !== undefined &&
    userInfo.tokenInfo.data !== undefined &&
    userInfo.tokenInfo.data.firstname
  ) {
    firstname = userInfo.tokenInfo.data.firstname;
  }

  const handleClickLogout = () => {
    logout();
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const classes = useStyles();
  const { rtlActive } = props;

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });

  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });

  const managerClasses = classNames({
    [classes.managerClasses]: true
  });

  let unread = notificationList
    ? notificationList
        .filter(f => f.isRead === false)
        .sort((a, b) => {
          if (a.createDatetime < b.createDatetime) {
            return 1;
          } else if (a.createDatetime >= b.createDatetime) {
            return -1;
          }
          return 0;
        })
    : [];

  return (
    <div className={wrapper}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          style={{ textAlign: "right", paddingRight: "2vw" }}
        >
          {/* <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div className={managerClasses}>
                                
                                <div 
                                    style={{
                                        fontSize: "16px",
                                        color: "##5f5d5d",
                                        textTransform: "capitalize"
                                    }}
                                >
                                    <Person /> {firstname}
                                </div>
                            </div>
                        </Grid>
                    </Grid> */}
          <div className={managerClasses}>
            <Button
              transparent
              color="transparent"
              aria-label="Person"
              justIcon
              aria-owns={openProfile ? "profile-menu-list" : null}
              aria-haspopup="true"
              //onClick={handleClickProfile}
              className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
              muiClasses={{
                label: rtlActive ? classes.labelRTL : ""
              }}
            >
              <Person />
              <div
                style={{
                  paddingLeft: "30%",
                  fontSize: "16px",
                  color: "##5f5d5d",
                  textTransform: "capitalize"
                }}
              >
                {firstname}
              </div>
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={managerClasses} style={{ marginRight: "5%" }}>
                <Button
                  color="transparent"
                  justIcon
                  aria-label="NotificationsFunc"
                  aria-owns={openNotification ? "notification-menu-list" : null}
                  aria-haspopup="true"
                  onClick={handleClickNotification}
                  className={
                    rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                  }
                  muiClasses={{
                    label: rtlActive ? classes.labelRTL : ""
                  }}
                >
                  <Badge badgeContent={notificationsCountUnread} color="error">
                    <NotificationsIcon fontSize="large" />
                  </Badge>
                  <Hidden mdUp implementation="css">
                    <span
                      onClick={handleClickNotification}
                      className={classes.linkText}
                    >
                      {rtlActive ? "إعلام" : "Notification"}
                    </span>
                  </Hidden>
                </Button>
                <Popper
                  open={Boolean(openNotification)}
                  anchorEl={openNotification}
                  transition
                  disablePortal
                  placement="bottom"
                  className={classNames({
                    [classes.popperClose]: !openNotification,
                    [classes.popperResponsive]: true,
                    [classes.popperNav]: true
                  })}
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      id="notification-menu-list"
                      style={{ transformOrigin: "0 0 0" }}
                    >
                      <Paper className={classes.dropdown}>
                        <ClickAwayListener
                          onClickAway={handleCloseNotification}
                        >
                          <MenuList role="menu">
                            {Array.isArray(unread) && unread.length > 0
                              ? unread
                                  .slice(
                                    0,
                                    Math.min(unread.length, MAX_UNREAD) - 1
                                  )
                                  .map(v => {
                                    if (v.isRead !== false) {
                                      return null;
                                    }
                                    return (
                                      <MenuItem
                                        key={v.id}
                                        onClick={() => {
                                          onClickedNotification(v);
                                        }}
                                        className={dropdownItem}
                                      >
                                        {v.header}
                                      </MenuItem>
                                    );
                                  })
                              : ""}
                            <Divider light />
                            <MenuItem
                              onClick={() => {
                                history.push("/main/notification");
                                setOpenNotification(null);
                              }}
                              className={dropdownItem}
                            >
                              {"ดูข้อความทั้งหมด"}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div style={{ padding: "0 25px 0 0", textAlign: "right" }}>
                <Button
                  color="transparent"
                  aria-label="sitemap"
                  justIcon
                  aria-haspopup="true"
                  onClick={() => setOpenSiteMap(true)}
                  className={
                    rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                  }
                  muiClasses={{
                    label: rtlActive ? classes.labelRTL : ""
                  }}
                >
                  <Avatar style={{ margin: "8px", backgroundColor: "#00acc1" }}>
                    <AccountTree />
                  </Avatar>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div style={{ padding: "0 25px 0 0", textAlign: "right" }}>
                <Button
                  color="transparent"
                  aria-label="เปลี่ยนรหัสผ่าน"
                  justIcon
                  aria-haspopup="true"
                  onClick={() => setOpenChangePassword(true)}
                  className={
                    rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                  }
                  muiClasses={{
                    label: rtlActive ? classes.labelRTL : ""
                  }}
                >
                  <Avatar style={{ margin: "8px", backgroundColor: "#fb8c00" }}>
                    <VpnKey />
                  </Avatar>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div style={{ padding: "0 25px 0 0", textAlign: "right" }}>
                <Button
                  color="transparent"
                  aria-label="ออกจากระบบ"
                  justIcon
                  //aria-haspopup="true"
                  onClick={handleClickLogout}
                  className={
                    rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                  }
                  muiClasses={{
                    label: rtlActive ? classes.labelRTL : ""
                  }}
                >
                  <Avatar style={{ margin: "8px", backgroundColor: "#f50057" }}>
                    <LockOpenIcon />
                  </Avatar>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SiteMap open={openSiteMap} setOpen={setOpenSiteMap}></SiteMap>
      <ChangePassword
        open={openChangePassword}
        setOpen={setOpenChangePassword}
      ></ChangePassword>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
