import React, { useContext, useEffect, useState } from "react";
import { bulkUpdateUseVote, getUseVote } from "contexts/API/OPT/OptUseVote";

import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import FileSaver from "file-saver";
import { FileUpload } from "components/Common/FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ImportUseVote from "assets/resources/opt/import_usevote.xlsx";
import ListboxAumphurArea from "components/Common/ListboxAumphurArea";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxVotedate from "components/Common/ListboxVotedate";
import MaterialTable from "components/Table/MaterialTable";
import NumberFormat from "react-number-format";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { VoteDateContext } from "contexts/VoteDate";
import XLSX from "xlsx";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { onlyNumber } from "utils/Validators/Numbers";
import { useSweetAlert } from "components/Alert/SweetAlert";

const OptuploadExcel = (props) => {
  // console.log("OptuploadExcel", props);
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [enableImport, setEnableImport] = useState(false);
  const [chkType, setChkType] = useState(false);
  const [memNameFile, setMemNameFile] = useState();

  const {
    optVoteTypeSelected,
    onOptVoteTypeSelected,
    onOptTypeSingleSelected,
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,

    tambolSelected,
    optTypeSelected,
    onOptSelected,
    optSelected,

    onVotedateSelected,
    onAumphurAreaSelected,
    positionTypeSelected,
    aumphurAreaSelected,
    areaSelected,
    votedateSelected,
    getOptTypeSelectedLists,
  } = useContext(VoteDateContext);

  const useStyles = makeStyles({
    buttoncustom: {
      fontSize: 14,
      fontWeight: 500,
      //color: props => props.color
    },
  });

  const classes = useStyles();

  const onClickSearch = () => {
    if (optVoteTypeSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภทการเลืือกตั้ง");
      return;
    }

    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }

    if (optSelected === null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
      return;
    }

    if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันที่เลือกตั้ง");
      return;
    }

    // if (getOptSubTypeId() === 1 || getOptSubTypeId() === 6) {
    //   if (!aumphurAreaSelected) {
    //     cleanUp();
    //     sweetAlerts.warning("กรุณาเลือกเขตอำเภอ และนำเข้าไฟล์อีกครั้ง");
    //     return;
    //   }
    // }

    if (chkType && getOptSubTypeId() === 1) {
      if (!aumphurAreaSelected) {
        sweetAlerts.warning("กรุณาเลือกเขตอำเภอ");
        return;
      }
    }

    if (data.length <= 0 || !enableImport) {
      sweetAlerts.warning("กรุณานำเข้าข้อมูลก่อน");
      return;
    }
    // data.forEach(elm => delete elm.areaNo);
    // data.forEach(elm => delete elm.tableData);
    //console.log("data ==>", data);

    bulkUpdateUseVote(optSelected.id, votedateSelected.id, data)
      .then((result) => {
        if (result.data !== null && result.data.data !== undefined) {
          const affected = result.data.data.reduce((t, c) => {
            return (t = t + c.affectedRows);
          }, 0);
          sweetAlerts.success(
            "นำเข้าข้อมูลสำเร็จ",
            <>
              <div style={{ textAlign: "center" }}>
                <b>
                  จำนวนทั้งหมด: <Badge color="success">{affected} </Badge>{" "}
                </b>
              </div>
            </>
          );
          setData([]);
        }
      })
      .catch((err) => {
        sweetAlerts.error("ขออภัย เกิดข้อผิดพลาดขึ้น", err.response.data.desc);
      });
  };

  const getOptSubTypeId = () => {
    for (const [, v] of Object.entries(optTypeSelected)) {
      if (v.checked) {
        return parseInt(v.value);
      }
    }
    return -1;
  };

  const initRow = {
    A: "",
    B: "",
    C: "",
    D: "",
    E: "",
    F: "",
    G: "",
    H: "",
    I: "",
    J: "",
    K: "",
  };

  const onExcelFileHandler = (e) => {
 // 1 = votedateSelected = สถ อย่างเดียว , 3 = มีทั้ง ผถและสถ

    try {
      if (votedateSelected.type === 3 || votedateSelected.type === 1) {
        if (getOptSubTypeId() === 1 && !aumphurAreaSelected) {
          sweetAlerts.warning("กรุณาเลือกเขตอำเภอ และนำเข้าไฟล์อีกครั้ง");
      
          return;
        }
      }
      let data = new Uint8Array(e);

      let workbook = XLSX.read(data, { type: "array" });
      let ws = workbook.Sheets[workbook.SheetNames[0]];
      let rows = XLSX.utils.sheet_to_json(ws, {
        header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"],
      });

      if (rows.length <= 1) {
        throw new Error("ไม่มีข้อมูล");
      }

      let query = "voteTypeId=" + optVoteTypeSelected.id;

      //console.log("areaSelected >> " + areaSelected);

      if (areaSelected !== null) {
        query += "&optAreaId=" + areaSelected.id;
      }

      if (aumphurAreaSelected !== null) {
        query += "&areaAmphurId=" + aumphurAreaSelected.id;
      }

      if (positionTypeSelected !== null) {
        query += "&positionTypeId=" + positionTypeSelected.id;
      }

      return getUseVote(optSelected.id, votedateSelected.id, query)
        .then((r) => {
          if (r.data.data && r.data.data.length <= 0) {
            throw Error("ไม่มีเขตการเลือกตั้ง");
          }

          let aumphurArea = aumphurAreaSelected ? aumphurAreaSelected.id : -1;

          //console.log("getUseVote aumphurArea >>>>>", aumphurArea)

          let memList = {};
          let presList = {};

          r.data.data.forEach((e) => {
            if (e.optPositionTypeId === 1) {
              presList = e;
            } else if (e.optPositionTypeId === 2) {
              if (getOptTypeSelectedLists()[0] === "1") {
                // ถ้าเป็น อบจ. ต้องให้เขตอำเภอเป็น key
                memList[`${e.areaNo}:${e.amphurId}`] = e;
              } else {
                memList[`${e.areaNo}`] = e;
              }
            }
          });

          const headAt = 6; //เริ่มอ่าน header column
          // console.log("headAt: ", rows[headAt]);
          if (
            rows[headAt].A !== "ตำแหน่ง (ส.ถ./ผ.ถ.)" ||
            rows[headAt].B !== "เขตเลือกตั้งที่" ||
            rows[headAt].C !== "ผู้มีสิทธิในบัญชีรายชื่อ" ||
            rows[headAt].D !== "ผู้มีสิทธิที่มาแสดงตน" ||
            rows[headAt].E !== "บัตรเลือกตั้งที่ได้รับมา" ||
            rows[headAt].F !== "บัตรเลือกตั้งที่ใช้" ||
            rows[headAt].G !== "บัตรดี" ||
            rows[headAt].H !== "บัตรเสีย" ||
            rows[headAt].I !== "บัตรไม่เลือกผู้ใด" ||
            rows[headAt].J !== "บัตรเลือกตั้งที่เหลือ" ||
            rows[headAt].K !== "หมายเหตุ"
          ) {
            throw new Error("กรุณาใช้ไฟล์ที่ export ออกจากระบบเท่านั้น");
          }

          let useVoteList = [];

          for (let i = headAt + 1; i < rows.length; i++) {
            let optPositionTypeId = 2;
            let optAreaNo = 0;
            //console.log("K " + i, rows[i].K)
            let row = Object.assign(initRow, rows[i]);
            let rowNum = rows[i].__rowNum__ + 1;
            if ("ผ.ถ." === rows[i].A) {
              optPositionTypeId = 1;
              if (Object.keys(presList).length === 0) {
                throw new Error(
                  `เซลล์ที่ A${rowNum} ไม่มีการจัดเลือกตั้ง ผ.ถ. ในช่วงเวลานี้`
                );
              }
            } else if ("ส.ถ." === rows[i].A) {
              optPositionTypeId = 2;
              setChkType(true);
              if (onlyNumber(rows[i].B)[0]) {
                throw new Error(
                  `เซลล์ที่ B${rowNum} เขตเลือกตั้งที่ ต้องเป็นตัวเลขเท่านั้น`
                );
              }

              optAreaNo = rows[i].B;
              let memListKey = "";
              if (getOptTypeSelectedLists()[0] === "1") {
                // ถ้าเป็น อบจ. ต้องให้เขตอำเภอเป็น key
                memListKey = `${optAreaNo}:${aumphurArea}`;
              } else {
                memListKey = `${optAreaNo}`;
              }

              if (!memList[memListKey]) {
                throw new Error(
                  `เซลล์ที่ B${rowNum} ไม่พบเขตเลือกตั้ง ${optAreaNo}`
                );
              }
            } else {
              throw new Error(
                `เซลล์ที่ A${rowNum} ข้อมูลไม่ถูกต้อง ต้องระบุ 'ส.ถ.' หรือ 'ผ.ถ.' เท่านั้น`
              );
            }

            if (onlyNumber(rows[i].C)[0]) {
              throw new Error(
                `เซลล์ที่ C${rowNum} ผู้มีสิทธิในบัญชีรายชื่อ ต้องเป็นตัวเลขเท่านั้น`
              );
            }
            if (onlyNumber(rows[i].D)[0]) {
              throw new Error(
                `เซลล์ที่ D${rowNum} ผู้มีสิทธิที่มาแสดงตน ต้องเป็นตัวเลขเท่านั้น`
              );
            }
            if (onlyNumber(rows[i].E)[0]) {
              throw new Error(
                `เซลล์ที่ E${rowNum} บัตรเลือกตั้งที่ได้รับมา ต้องเป็นตัวเลขเท่านั้น`
              );
            }
            if (onlyNumber(rows[i].F)[0]) {
              throw new Error(
                `เซลล์ที่ F${rowNum} บัตรเลือกตั้งที่ใช้ ต้องเป็นตัวเลขเท่านั้น`
              );
            }
            if (onlyNumber(rows[i].G)[0]) {
              throw new Error(
                `เซลล์ที่ G${rowNum} บัตรดี ต้องเป็นตัวเลขเท่านั้น`
              );
            }
            if (onlyNumber(rows[i].H)[0]) {
              throw new Error(
                `เซลล์ที่ H${rowNum} บัตรเสีย ต้องเป็นตัวเลขเท่านั้น`
              );
            }
            if (onlyNumber(rows[i].I)[0]) {
              throw new Error(
                `เซลล์ที่ I${rowNum} บัตรไม่เลือกผู้ใด ต้องเป็นตัวเลขเท่านั้น`
              );
            }
            if (onlyNumber(rows[i].J)[0]) {
              throw new Error(
                `เซลล์ที่ J${rowNum} บัตรเลือกตั้งที่เหลือ ต้องเป็นตัวเลขเท่านั้น`
              );
            }

            //   newData.numBadCard +
            //   newData.numGoodCard +
            //   newData.numNoSelectedCard !==
            //   newData.numRealVote &&
            // !newData.diffCardRemark

            if (rows[i].H + rows[i].G + rows[i].I !== rows[i].F && !rows[i].K) {
              throw new Error(
                `บัตรเลือกตั้งที่ใช้ เท่ากับ บัตรดี+บัตรเสีย+บัตรไม่เลือกผู้สมัครผู้ใด หากไม่ถูกต้องกรุณาระบุหมายเหตุ`
              );
            }
            // newData.numCard < newData.numRightVote &&
            // newData.diffCardRemark === null
            if (rows[i].E < rows[i].C && !rows[i].K) {
              throw new Error(
                `บัตรที่ได้รับมา จำนวนต้องมากกว่าหรือเท่ากับ จำนวนผู้มีสิทธิ หากไม่ถูกต้องกรุณาระบุหมายเหตุ`
              );
            }
            // newData.numRealVote > newData.numRightVote &&
            // !newData.diffCardRemark
            if (rows[i].F > rows[i].C && !rows[i].K) {
              throw new Error(
                `บัตรเลือกตั้งที่ใช้ จำนวนต้องน้อยกว่าหรือเท่ากับ จำนวนผู้มีสิทธิเลือกตั้ง หากไม่ถูกต้องกรุณาระบุหมายเหตุ`
              );
            }
            //   newData.numRealVote + newData.numRemainCard !==
            //   newData.numCard &&
            // !newData.diffCardRemark
            if (rows[i].F + rows[i].J !== rows[i].E && !rows[i].K) {
              throw new Error(
                `บัตรที่ได้รับการจัดสรร เท่ากับ บัตรเลือกตั้งที่ใช้ + บัตรที่เหลือ  หากไม่ถูกต้องกรุณาระบุหมายเหตุ`
              );
            }

            if (optPositionTypeId === 1) {
              useVoteList.push({
                optAreaVoteId: null,
                optAreaId: null,
                optVotePresInfoId: presList.optVotePresInfoId,
                optVoteId: presList.optVoteId,
                optPositionTypeId: 1,
                areaNo: rows[i].B,
                numRightVote: rows[i].C,
                numRealVote: rows[i].F,
                numAppearCard: rows[i].D,
                numGoodCard: rows[i].G,
                numNoSelectedCard: rows[i].I,
                numBadCard: rows[i].H,
                // numRightVoteMale: 0,
                // numRightVoteFemale: 0,
                // numRealVoteMale: 0,
                // numRealVoteFemale: 0,
                numRemainCard: rows[i].J,
                diffCardRemark: rows[i].K,
                numCard: rows[i].E,
              });
            } else if (optPositionTypeId === 2) {
              const optAreaNo = rows[i].B;
              let memListKey = "";
              if (getOptTypeSelectedLists()[0] === "1") {
                // ถ้าเป็น อบจ. ต้องให้เขตอำเภอเป็น key
                memListKey = `${optAreaNo}:${aumphurArea}`;
              } else {
                memListKey = `${optAreaNo}`;
              }

              const mem = memList[memListKey];
              useVoteList.push({
                optAreaVoteId: mem.optAreaVoteId,
                optAreaId: mem.optAreaId,
                optVotePresInfoId: null,
                optVoteId: mem.optVoteId,
                optPositionTypeId: 2,
                areaNo: rows[i].B,
                numRightVote: rows[i].C,
                numRealVote: rows[i].F,
                numAppearCard: rows[i].D,
                numGoodCard: rows[i].G,
                numNoSelectedCard: rows[i].I,
                numBadCard: rows[i].H,
                // numRightVoteMale: 0,
                // numRightVoteFemale: 0,
                // numRealVoteMale: 0,
                // numRealVoteFemale: 0,
                numRemainCard: rows[i].J,
                diffCardRemark: rows[i].K,
                numCard: rows[i].E,
              });
            }
          }
          setData(useVoteList);
          setColumns([
            {
              title: "ตำแหน่ง",
              field: "optPositionTypeId",

              render: (rowData) => {
                if (rowData.optPositionTypeId === 1) {
                  return "ผ.ถ.";
                } else if (rowData.optPositionTypeId === 2) {
                  return "ส.ถ.";
                }
              },
            },
            {
              title: "เขต",
              field: "areaNo",
            },
            {
              title: "ผู้มีสิทธิในบัญชีรายชื่อ",
              field: "numRightVote",
              render: (rowData) => {
                return (
                  <NumberFormat
                    thousandSeparator={true}
                    customInput={TextField}
                    disabled={true}
                    thousandsGroupStyle="thousand"
                    value={
                      !rowData.numRightVote ? 0 : parseInt(rowData.numRightVote)
                    }
                  />
                );
              },
            },
            {
              title: "ผู้มีสิทธิที่มาแสดงตน",
              field: "numAppearCard",
              render: (rowData) => {
                return (
                  <NumberFormat
                    thousandSeparator={true}
                    customInput={TextField}
                    disabled={true}
                    thousandsGroupStyle="thousand"
                    value={
                      !rowData.numAppearCard
                        ? 0
                        : parseInt(rowData.numAppearCard)
                    }
                  />
                );
              },
            },
            {
              title: "บัตรเลือกตั้งที่ได้รับมา",
              field: "numCard",
              render: (rowData) => {
                return (
                  <NumberFormat
                    thousandSeparator={true}
                    customInput={TextField}
                    disabled={true}
                    thousandsGroupStyle="thousand"
                    value={!rowData.numCard ? 0 : parseInt(rowData.numCard)}
                  />
                );
              },
            },
            {
              title: "บัตรเลือกตั้งที่ใช้",
              field: "numRealVote",
              render: (rowData) => {
                return (
                  <NumberFormat
                    thousandSeparator={true}
                    customInput={TextField}
                    disabled={true}
                    thousandsGroupStyle="thousand"
                    value={
                      !rowData.numRealVote ? 0 : parseInt(rowData.numRealVote)
                    }
                  />
                );
              },
            },
            {
              title: "บัตรดี",
              field: "numGoodCard",
              render: (rowData) => {
                return (
                  <NumberFormat
                    thousandSeparator={true}
                    customInput={TextField}
                    disabled={true}
                    thousandsGroupStyle="thousand"
                    value={
                      !rowData.numGoodCard ? 0 : parseInt(rowData.numGoodCard)
                    }
                  />
                );
              },
            },
            {
              title: "บัตรเสีย",
              field: "numBadCard",
              render: (rowData) => {
                return (
                  <NumberFormat
                    thousandSeparator={true}
                    customInput={TextField}
                    disabled={true}
                    thousandsGroupStyle="thousand"
                    value={
                      !rowData.numBadCard ? 0 : parseInt(rowData.numBadCard)
                    }
                  />
                );
              },
            },
            {
              title: "บัตรไม่เลือกผู้สมัครผู้ใด",
              field: "numNoSelectedCard",
              render: (rowData) => {
                return (
                  <NumberFormat
                    thousandSeparator={true}
                    customInput={TextField}
                    disabled={true}
                    thousandsGroupStyle="thousand"
                    value={
                      !rowData.numNoSelectedCard
                        ? 0
                        : parseInt(rowData.numNoSelectedCard)
                    }
                  />
                );
              },
            },
            {
              title: "บัตรเลือกตั้งที่เหลือ",
              field: "numRemainCard",
              render: (rowData) => {
                return (
                  <NumberFormat
                    thousandSeparator={true}
                    customInput={TextField}
                    disabled={true}
                    thousandsGroupStyle="thousand"
                    value={
                      !rowData.numRemainCard
                        ? 0
                        : parseInt(rowData.numRemainCard)
                    }
                  />
                );
              },
            },
            {
              title: "หมายเหตุ",
              field: "diffCardRemark",
            },
          ]);
        })
        .catch((err) => {
          sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
        });
    } catch (err) {
      sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
    }
  };

  useEffect(() => {
   
    if (!enableImport) {
      const getOptSubTypeId = () => {
        for (const [, v] of Object.entries(optTypeSelected)) {
          if (v.checked) {
            return parseInt(v.value);
          }
        }
        return -1;
      };

      if (
        enableImport &&
        (!(optSelected && votedateSelected && optVoteTypeSelected) ||
          (getOptSubTypeId() === 1 && !aumphurAreaSelected))
      ) {
        setEnableImport(false);
      }
      if (
        !enableImport &&
        optSelected &&
        votedateSelected &&
        optVoteTypeSelected
      ) {
        if (getOptSubTypeId() === 1) {
          //1 === อบจ.
          if (aumphurAreaSelected) {
            setEnableImport(true);
          } else {
            //setEnableImport(false);
            setEnableImport(true);
          }
        } else {
          setEnableImport(true);
        }
      }
    }
  }, [
    optSelected,
    votedateSelected,
    optVoteTypeSelected,
    aumphurAreaSelected,
    enableImport,
    optTypeSelected,
  ]);

  const cleanUp = (e) => {
    setData([]);
    setChkType(false);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CheckboxOpttype
                        optTypeSelected={optTypeSelected}
                        onOptTypeSelected={onOptTypeSingleSelected}
                        required={true}
                      ></CheckboxOpttype>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxOptVotetype
                        value={optVoteTypeSelected}
                        onChange={onOptVoteTypeSelected}
                        required={true}
                      ></ListboxOptVotetype>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxProvince
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                        required={true}
                      ></ListboxProvince>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxDistinct
                        value={amphurSelected}
                        provinceSelected={provinceSelected}
                        onChange={onAmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxOpts
                        value={optSelected}
                        getOptTypeSelectedLists={getOptTypeSelectedLists}
                        optTypeSelected={optTypeSelected}
                        provinceSelected={provinceSelected}
                        amphurSelected={amphurSelected}
                        tambolSelected={tambolSelected}
                        onChange={onOptSelected}
                        required={true}
                      ></ListboxOpts>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxVotedate
                        value={votedateSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onVotedateSelected}
                        required={true}
                      ></ListboxVotedate>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxAumphurArea
                        value={aumphurAreaSelected}
                        optSelected={optSelected}
                        votedateSelected={votedateSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onAumphurAreaSelected}
                      ></ListboxAumphurArea>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer
                justify="space-between"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={12} sm={12} md={6}>
                  {/* <ExcelUpload></ExcelUpload> */}
                  <FileUpload
                    id="file-upload-2"
                    fileName={memNameFile}
                    label="แนบไฟล์ (เฉพาะ Excel เท่านั้น)"
                    remove
                    onFileRemove={() => {
                      cleanUp();
            
                    }}
                    onFileHandler={onExcelFileHandler}
                    fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    btnName={<>Browse</>}
                    btnProps={
                      enableImport
                        ? {
                            color: "primary",
                          }
                        : { disabled: true, color: "primary" }
                    }
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  ดาวน์โหลดแบบฟอร์มการนำเข้าข้อมูล:{" "}
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      FileSaver(ImportUseVote, "import_usevote.xlsx");
                    }}
                  >
                    <FontAwesomeIcon icon={faFileExcel} /> แบบฟอร์ม
                  </Button>
                </GridItem>
              </GridContainer>
              <br /> <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={12} sm={12} md={12}>
                  {data.length > 0 ? (
                    <MaterialTable
                      title="ยืนยันข้อมูลการใช้สิทธิที่นำเข้า"
                      columns={columns}
                      data={data}
                      enableInitColumns={false}
                      components={{
                        Container: (props) => (
                          <Paper {...props} elevation={0} />
                        ),
                      }}
                      options={{
                        headerStyle: {
                          textAlign: "center",
                        },
                        exportButton: false,
                      }}
                    />
                  ) : null}
                </GridItem>
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button className={classes.buttoncustom}>ยกเลิก</Button>

                  <Button
                    disabled={data.length === 0}
                    color="success"
                    className={classes.buttoncustom}
                    onClick={onClickSearch}
                  >
                    ยืนยันนำเข้าข้อมูลผู้ใช้สิทธิ
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default OptuploadExcel;
