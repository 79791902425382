import React, { useContext, useEffect, useState } from "react";
/* API */
import {
  getCheckingLists,
  getinspectorPromoteStatus,
  getreplaceInspectorPromote,
  updateReplaceBrance
} from "contexts/API/INSPEC/ReplaceBrance";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
/* Material Components */
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import DateVoteDate from "components/Common/Datepicker";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectPromote from "components/Common/ListboxInspectPromote";
import ListboxInspectVoteDateInquire from "components/Common/ListboxInspectVoteDateInquire";
import ListboxInspectorPromoteStatus from "components/Common/ListboxInspectorPromoteStatus";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import { Paper } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles();
const ReplaceBrance = ({ pageProps, setPageProps }) => {
  const [loading, setLoading] = useState(false);
  //const [clickSearch, setClickSearch] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  const [positionList, setPositionList] = useState([]);
  const {
    provinceSelected,
    onProvinceSelected,
    onVotedateSelected,
    votedateSelected
  } = useContext(InspectorContext);

  // const [columns, setColumns] = useState([]);
  //const classes = useStyles();

  const [replacePerson, setReplacePerson] = useState([]);

  useEffect(() => {
    getinspectorPromoteStatus()
      .then(r => {
        let positionPromostList = r.data.data.map(v => {
          return { id: v.inspectorPromoteStatusId, value: v.promoteStatusName };
        });

        setPositionList(positionPromostList);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (provinceSelected && votedateSelected) {
      getreplaceInspectorPromote(provinceSelected, votedateSelected)
        .then(r => {
          let personist = r.data.data.map(v => {
            return {
              id: v.personApplyCommandId,
              value: v.fullname
            };
          });

          setReplacePerson(personist);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [provinceSelected, votedateSelected]);

  const [data, setData] = useState([]);
  const optionsTable = {
    exportButton: false,
    paging: true,
    rowStyle: rowData => {
      if (rowData.tableData.id % 2 !== 0) {
        return { backgroundColor: "#f5f5f5" };
      }

      return {};
    }
  };
  const onClickSearch = e => {
    //setClickSearch(false);

    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    } else if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันเลือกตั้งในระบบ");
      return;
    } else {
      setLoading(true);
      getCheckingLists(provinceSelected.id, votedateSelected.id)
        .then(result => {
          setData(result.data.data);

          setLoading(false);
          //setClickSearch(true);
        })
        .catch(err => {
          setLoading(false);
          sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
        });
    }
  };

  const columns = [
    {
      title: "personApplyCommandId",
      field: "personApplyCommandId",
      hidden: true
    },
    {
      title: "inspectorPersonPromoteId",
      field: "inspectorPersonPromoteId",
      hidden: true
    },
    {
      title: "replaceInspectorPersonPromoteId",
      field: "replaceInspectorPersonPromoteId",
      hidden: true
    },
    {
      title: "replacePersonApplyCommandId",
      field: "replacePersonApplyCommandId",
      hidden: true
    },
    {
      title: "isInspectorInProvince",
      field: "isInspectorInProvince",
      hidden: true
    },
    {
      title: "ชื่อ สกุล",
      field: "fullName",
      cellStyle: {
        minWidth: "150px"
      },
      editable: "never"
    },
    {
      title: "ภูมิลำเนาเดิม",
      field: "provinceOwner",
      cellStyle: {
        minWidth: "100px"
      },
      editable: "never"
    },
    {
      title: "สถานะปัจจุบัน",
      field: "positionText",
      cellStyle: {
        minWidth: "120px"
      },
      editable: "never"
    },
    {
      title: "สาเหตุออกจากตำแหน่ง",
      field: "promoteStatusId",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      },
      render: rowData => {
        const v = positionList.find(
          e => e.id === parseInt(rowData.promoteStatusId)
        );

        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        return (
          <ListboxInspectorPromoteStatus
            defaultValue={{
              id: props.rowData.promoteStatusId
            }}
            data={positionList}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.rowData.promoteStatusId = e.id;
              }
            }}
          />
        );
      }
    },
    {
      title: "สาเหตุ",
      field: "leaveRemark",
      cellStyle: {
        minWidth: "200px"
      }
    },
    {
      title: "วันที่สิ้นสุดการดำรงตำแหน่ง",
      field: "endEffectiveDate",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return (
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ConvertDate(rowData.endEffectiveDate),
              disabled: true
            }}
          />
        );
      },
      editComponent: props => {
        const dateValue = props.rowData.endEffectiveDate
          ? props.rowData.endEffectiveDate
          : null;
        return (
          <DateVoteDate
            label=""
            value={dateValue}
            onChange={date =>
              props.onRowDataChange({
                ...props.rowData,
                endEffectiveDate: date
              })
            }
          />
        );
      }
    },
    {
      title: "ผู้ดำรงตำแหน่งแทน ",
      field: "replacePersonApplyCommandId",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      },

      render: rowData => {
        const v = replacePerson.find(
          e => e.id === parseInt(rowData.replacePersonApplyCommandId)
        );

        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        return (
          <ListboxInspectPromote
            provinceId={provinceSelected}
            inspectApplyDateSelected={votedateSelected}
            defaultValue={{
              id: props.rowData.replacePersonApplyCommandId
                ? props.rowData.replacePersonApplyCommandId
                : 0
            }}
            data={replacePerson}
            onChange={(i, e, v) => {
              console.log(e);
              if (e !== null) {
                props.rowData.replacePersonApplyCommandId = e.id;
              }
            }}
          />
        );
      }
    },
    {
      title: "วันที่เริ่มดำรงตำแหน่ง",
      field: "starEffectiveDateReplacePromote",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      },
      render: rowData => {
        return (
          <CustomInput
            // value={props.remarkMem}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ConvertDate(rowData.starEffectiveDateReplacePromote),
              disabled: true

              //  inputRef: props.memRemarkRef
            }}
          />
        );
      },
      editComponent: props => {
        const dateValue = props.rowData.starEffectiveDateReplacePromote
          ? props.rowData.starEffectiveDateReplacePromote
          : null;
        return (
          <DateVoteDate
            label=""
            value={dateValue}
            onChange={date =>
              props.onRowDataChange({
                ...props.rowData,
                starEffectiveDateReplacePromote: date
              })
            }
          />
        );
      }
    }
  ];

  return (
    <div>
      <Card>
        <CardHeader color="danger" text>
          <CardText color="danger">
            <h4>บันทึกรายชื่อผู้ดำรงตำแหน่งแทน ผตล.จว.</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxProvince
                    required={true}
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxInspectVoteDateInquire
                    value={votedateSelected}
                    required={true}
                    onChange={onVotedateSelected}
                  ></ListboxInspectVoteDateInquire>
                </GridItem>
              </GridContainer>
              <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button>ยกเลิก</Button>
                  <Button color="info" onClick={onClickSearch}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {loading ? (
              <LoadingData />
            ) : (
              <MaterialTable
                title={"รายชื่อผู้ตรวจการเลือกตั้งประจำจังหวัด"}
                options={optionsTable}
                columns={columns}
                data={data}
                components={{
                  Container: props => <Paper {...props} elevation={0} />,
                  Header: props => {
                    return (
                      <TableHead>
                        <TableRow>
                          <TableCell rowSpan={2}>ลำดับ</TableCell>
                          <TableCell rowSpan={2}>ชื่อ สกุล</TableCell>
                          <TableCell rowSpan={2}>ภูมิลำเนาเดิม</TableCell>
                          <TableCell rowSpan={2}>สถานะปัจจุบัน</TableCell>
                          <TableCell colSpan={5} align="center">
                            รายชื่อผู้ดำรงตำแหน่งแทน
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">
                            สาเหตุออกจากตำแหน่ง
                          </TableCell>
                          <TableCell align="center">ระบุสาเหตุ</TableCell>
                          <TableCell align="center" minwidth="200">
                            วันที่สิ้นสุดการดำรงตำแหน่ง
                          </TableCell>
                          <TableCell align="center" width="200">
                            ผู้ดำรงตำแหน่งแทน
                          </TableCell>
                          <TableCell align="center">
                            วันที่เริ่มการดำรงตำแหน่ง
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    );
                  }
                }}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...data];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        console.log("newData", newData);
                        updateReplaceBrance(
                          {
                            personApplyCommandId: oldData.personApplyCommandId,
                            inspectorPersonPromoteId:
                              oldData.inspectorPersonPromoteId,
                            replaceInspectorPersonPromoteId:
                              oldData.replaceInspectorPersonPromoteId,
                            replacePersonApplyCommandId:
                              oldData.replacePersonApplyCommandId,
                            oldReplacePersonApplyCommandId:
                              oldData.replacePersonApplyCommandId,
                            newReplacePersonApplyCommandId:
                              newData.replacePersonApplyCommandId,
                            oldPromoteStatusId: oldData.promoteStatusId,
                            newPromoteStatusId: newData.promoteStatusId,
                            leaveRemark: newData.leaveRemark,
                            endEffectiveDatetime: newData.endEffectiveDate,
                            personReplaceStartEffectiveDate:
                              newData.starEffectiveDateReplacePromote,
                            isInspectorInProvince: true
                          },
                          provinceSelected.id,
                          votedateSelected.id
                        )
                          .then(result => {
                            dataUpdate[index] = newData;
                            setData([...dataUpdate]);

                            sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");

                            //  setData([...dataUpdate]);
                          })
                          .catch(err => {
                            HandelError(err);

                            sweetAlerts.error(
                              "ไม่สามารถแก้ไขข้อมูลได้",
                              err.response.data.desc
                            );
                          });
                        resolve();
                      }, 1000);
                    })
                }}
              />
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default ReplaceBrance;
