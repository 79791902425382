/* core components */
import React, { useContext, useEffect, useState } from "react";
import {
  addReplacedetaileditPage,
  deletedReplacedetail,
  editReplacedetail,
  getReplacedetail
} from "contexts/API/VoteDate/VoteDate";
import {getAllOptAreaByOptId, getAmphurArea, getAreaNoLists} from "contexts/API/OPT/OptArea";

import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datepicker from "components/Common/Datepicker";
import ListboxArea from "components/Common/ListboxArea";
import ListboxAumphurAreaofTable from "components/Common/ListboxAumphurAreaofTable";
import ListboxPositionStatus from "components/Common/ListboxPositionStatus";
import MustNotNull from "utils/Validators/MustNotNull";
import TableComponent from "components/Table/MaterialTable";
import { VoteDateContext } from "contexts/VoteDate";
import { getPositionStatus } from "contexts/API/OPT/Opt";
// Utils
import { useSweetAlert } from "components/Alert/SweetAlert";

const TableEditVotedate = props => {

  const { sweetAlerts } = useSweetAlert();

  const { optVoteTypeSelected } = useContext(VoteDateContext);

  const [optIdSelected] = useState(props.props.optId);
  const [generalOptVoteIdSelected] = useState(props.props.generalOptVoteId);
  const [generalOptVoteVoteTypeId] = useState(props.props.generalOptVoteVoteTypeId);
  //const [optVoteId] = useState(props.props.optVoteId);
  //const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const [_areaAmphur, _setAreaAmphur] = useState();

  const [positionList, setPositionList] = useState([]);
  const [areaNoList, setAreaNoList] = useState([]);
  const [areaAmhurList, setAreaAmhurList] = useState([]);

  useEffect(() => {
    getPositionStatus()
      .then(r => {
        let positionMapper = r.data.data.map(v => {
          return { id: v.statusId, value: v.detail };
        });

        setPositionList(positionMapper);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log('##### getAreaNoLists', props.props.optId, props.props.generalOptVoteVoteTypeId, null, props.props.generalOptVoteId);
    getAreaNoLists(props.props.optId, props.props.generalOptVoteVoteTypeId, null, props.props.generalOptVoteId)
      .then(r => {
        let areaMapper = r.data.data[0].opt_area.map(v => {
          return { id: v.opt_area_id, value: `ส.ถ. เขตที่` + v.area_no };
        });

        // 2024-07-24 Narongsak.M เลือกตั้งเขตใหม่
        if (optVoteTypeSelected.id === 4) {
          getAllOptAreaByOptId(props.props.optId)
              .then(r2 => {
                let areaList = r.data.data[0].opt_area.map(v => {
                  return v.area_no;
                });

                let excludeAreaNo = r2.data.data[0].opt_area
                    .filter(f => !areaList.includes(f.area_no))
                    .map(v => {
                      return {id: v.opt_area_id, value: `ส.ถ. เขตที่` + v.area_no};
                    });

                console.log('##### excludeAreaNo', excludeAreaNo);
                setAreaNoList(excludeAreaNo);
              });
        } else {
            setAreaNoList(areaMapper);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [props.props.optId, props.props.generalOptVoteId]);

  useEffect(() => {
    getAmphurArea(props.props.optId,  props.props.generalOptVoteVoteTypeId, null, props.props.generalOptVoteId)
      .then(r => {
        let ampuhrMapper = r.data.data.map(v => {
          return { id: v.amphur_id, value: v.amphur_name };
        });

        setAreaAmhurList(ampuhrMapper);
      })
      .catch(err => {
        console.log(err);
      });
  }, [props.props.optId, props.props.generalOptVoteId]);
  const columns = [
    {
      field: "optReplaceDetailId",
      title: "optReplaceDetailId",
      hidden: true
    },
    {
      align: "center",
      field: "amphurId",
      title: "เขตอำเภอ",
      editable: "onAdd",

      render: rowData => {
        const v = areaAmhurList.find(e => e.id === parseInt(rowData.amphurId));
        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        return (
          <ListboxAumphurAreaofTable
            defaultValue={{ id: props.rowData.amphurId }}
            data={areaAmhurList}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.onChange(v.id);

                _setAreaAmphur(v.id);
                //props.onChange({ id: v.id, value: v.value });
              }
            }}
          />
        );
      },
      validate: data => {
        const [isValid, helperText] = MustNotNull(data);
        return { isValid: !isValid, helperText: helperText };
      }
    },
    {
      align: "center",
      field: "optAreaId",
      title: "เขตเลือกตั้ง",
      editable: "onAdd",
      // cellStyle: {
      //   minWidth: "300px"
      // },
      render: rowData => {
        const v = areaNoList.find(e => e.id === parseInt(rowData.optAreaId));
        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        const amphurId =
          _areaAmphur !== undefined || _areaAmphur !== null
            ? _areaAmphur
            : null;

        if (amphurId !== undefined) {
          return (
            <ListboxArea
              defaultValue={{ id: props.rowData.optAreaId }}
              optSelected={{ id: optIdSelected }}
              aumphurAreaSelected={{ id: amphurId }}
              optVoteTypeSelected={{ id: generalOptVoteVoteTypeId }}
              optVoteIdSelected={{ id: generalOptVoteIdSelected }}
              onChange={(i, e, v) => {
                if (e !== null) {
                  props.onChange(e.id);
                  // props.onChange({ id: e.id, value: e.value });
                  //props.rowData.optPositionStatusId = e.id;
                }
              }}
            ></ListboxArea>
          );
        } else {
          return (
            <ListboxArea
              defaultValue={{ id: props.rowData.optAreaId }}
              optSelected={{ id: optIdSelected }}
              validators={[MustNotNull]}
              optVoteTypeSelected={{ id: generalOptVoteVoteTypeId }}
              optVoteIdSelected={{ id: generalOptVoteIdSelected }}
              data = {areaNoList}
              onChange={(i, e, v) => {
                if (e !== null) {
                  props.onChange(e.id);
                  // props.onChange({ id: e.id, value: e.value });
                  //props.rowData.optPositionStatusId = e.id;
                }
              }}
            ></ListboxArea>
          );
        }
      },
      validate: data => {
        const [isValid, helperText] = MustNotNull(data);
        return { isValid: !isValid, helperText: helperText };
      }
    },
    {
      align: "center",
      field: "optPositionStatusId",
      title: "สาเหตุการจัดเลือกตั้ง",
      render: rowData => {
        const v = positionList.find(e => e.id === rowData.optPositionStatusId);
        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        return (
          <ListboxPositionStatus
            defaultValue={{ id: props.rowData.optPositionStatusId }}
            validators={[MustNotNull]}
            data={positionList}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.onChange(e.id);
                // props.onChange({ id: v.id, value: v.value });
                //props.rowData.optPositionStatusId = e.id;
              }
            }}
          />
        );
      },
      validate: data => {
        const [isValid, helperText] = MustNotNull(data);
        return { isValid: !isValid, helperText: helperText };
      }
    },
    {
      align: "center",
      field: "replaceDate",
      title: "วันที่เกิดเหตุ",
      render: props => {
        return ConvertDate(props.replaceDate);
      },
      editComponent: props => {
        const dateValue = props.value ? props.value : null;
        return (
          <Datepicker
            label="วันที่เกิดเหตุ"
            value={dateValue}
            onChange={date => props.onChange(date)}
          />
        );
      },
      validate: data => {
        const [isValid, helperText] = MustNotNull(data);
        return { isValid: !isValid, helperText: helperText };
      }
    },
    {
      align: "center",
      field: "remark",
      title: "หมายเหตุ",
      editComponent: props => {
        return (
          <CustomInput
            labelText="หมายเหตุ"
            inputProps={{
              onChange: e => props.onChange(e.target.value),
              defaultValue: props.rowData.remark
            }}
          />
        );
      }
    }
  ];
  //setColumns(_columns);
  const verifyData = newData => {
    if ([1, 4, 5].includes(optVoteTypeSelected.id) && areaAmhurList.length > 0 && !newData.amphurId) {
      sweetAlerts.warning("กรุณาเลือกเขตอำเภอ");
      return false;
    }

    if (!newData.optAreaId) {
      sweetAlerts.warning("กรุณาเลือกเขต");
      return false;
    }

    if (!newData.optPositionStatusId) {
      sweetAlerts.warning("กรุณาเลือกสาเหตุจัดเลือกตั้ง");
      return false;
    }

    if (!newData.replaceDate) {
      sweetAlerts.warning("กรุณาเลือกวันที่เกิดเหตุ");
      return false;
    }

    return true;
  };
  const editAble = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        if (!verifyData(newData)) {
          reject();
        } else {
          setTimeout(() => {
            const obj = data.filter(
              e => e.optAreaId === parseInt(newData.optAreaId)
            ).length;
            if (props.props.mem === undefined || props.props.mem.optVoteId === null) {
              sweetAlerts.error("กรุณาบันทึก การจัดเลือกตั้ง ส.ถ. ก่อนที่จะระบุเขตเลือกตั้ง ");
              reject();
            }
            if (obj === 0) {
              addReplacedetaileditPage(
                optIdSelected,
                //props.props.optVoteId,
                props.props.mem.optVoteId,
                parseInt(newData.optAreaId),
                newData.replaceDate.toISOString().split("T")[0],
                newData.optPositionStatusId,
                newData.remark === undefined ? "-" : newData.remark
              )
                .then(result => {
                  newData.optReplaceDetailId =
                    result.data.data.optReplaceDetailId;
                  newData.optAreaId = newData.optAreaId;
                  // const _res = newData.concat(result.data.data.);
                  // let newData = Object.assign(newData, _res);
                  // console.log(newData);
                  sweetAlerts.success("เพิ่มเขตเลือกตั้งสำเร็จ");
                  setData([...data, newData]);

                  resolve();
                })
                .catch(err => {
                  if (err.response.data && err.response.data.desc) {
                    sweetAlerts.error(
                      "เพิ่มเขตเลือกตั้งไม่สำเร็จ",
                      err.response.data.desc
                    );
                  } else {
                    sweetAlerts.error("เพิ่มเขตเลือกตั้งไม่สำเร็จ");
                  }

                  reject();
                });
            } else {
              sweetAlerts.error("เขตเลือกตั้งซ้ำกับที่เลือกไปแล้ว");
              reject();
            }
          }, 1000);
          // setReplaceDetail([...replaceDetail, newData]);
          // resolve();
        }
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        //     console.log("newData: ", newData);
        if (!verifyData(newData)) {
          reject();
        } else {
          setTimeout(() => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            editReplacedetail(
              optIdSelected,
              oldData.optReplaceDetailId,

              newData.replaceDate,
              newData.optPositionStatusId,
              newData.remark === undefined ? "-" : newData.remark
            )
              .then(result => {
                sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                dataUpdate[index] = newData;
                setData([...dataUpdate]);
                resolve();
              })
              .catch(err => {
                if (err.response.data && err.response.data.desc) {
                  sweetAlerts.error(
                    "เพิ่มเขตเลือกตั้งไม่สำเร็จ",
                    err.response.data.desc
                  );
                } else {
                  sweetAlerts.error("เพิ่มเขตเลือกตั้งไม่สำเร็จ");
                }

                reject();
              });
          }, 1000);
          // setReplaceDetail([...replaceDetail, newData]);
          // resolve();
        }
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        setTimeout(() => {
          deletedReplacedetail(optIdSelected, oldData.optReplaceDetailId)
            .then(result => {
              sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
              dataDelete.splice(index, 1);
              setData(dataDelete);
              resolve();
            })
            .catch(err => {
              reject();
            });
        }, 1000);
      })
  };

  const tableOptions = {
    exportButton: false
  };

  useEffect(() => {
    //console.log("props.props>>", props.props)
    //if (props.props.optVoteId !== null) {
    //  getReplacedetail(props.props.optId, props.props.optVoteId)
    if (props.props.mem !== undefined && props.props.mem.optVoteId !== null) {
      getReplacedetail(props.props.optId, props.props.mem.optVoteId)
        .then(r => {
          setData(r.data.data);
        })
        .catch(err => {
          console.log("error while fetching OPT area", err);
        });
    }
    return () => {
      setData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.props]);

  return (
    <TableComponent
      data={data}
      columns={columns}
      title={"เขตเลือกตั้ง"}
      options={tableOptions}
      editable={editAble}
    />
  );
};

export default TableEditVotedate;
