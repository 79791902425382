import React, { useState } from "react";
import {
  editPageInRole,
  getPageInRole
} from "contexts/API/UserManagement/roles";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxRoles from "components/Common/ListboxRoles";
import ListboxSystemList from "components/Common/ListboxSystemList.js";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
// import ModaladdUser from "./ModaladdUser";
// import ModaleditUser from "./ModaleditUser";
import { Paper } from "@material-ui/core";
import { useSweetAlert } from "components/Alert/SweetAlert";

const PageInRolesList = props => {
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const [roleId, setRoleId] = useState({ id: 0, value: "" });
  const [systemId, setSystemId] = useState({ id: 0, value: "" });
  const [loading, setLoading] = useState(false);

  const onRoleSelected = (evt, newValue) => {
    setRoleId(newValue);
  };

  const onSystemId = (evt, newValue) => {
    setSystemId(newValue);
  };
  const handelSubmit = () => {
    sweetAlerts.warning(
      "ยืนการการเปลี่ยนแปลงข้อมูล",
      "คุณต้องการเปลี่ยนแปลงข้อมูลใช่หรือไม่",
      () => {
        editPageInRole(roleId.id, data)
          .then(result => {
            sweetAlerts.success("บันทึกข้อมูลเรียบร้อยแล้ว");
            //  setData([...dataDelete]);
          })
          .catch(err => {
            sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
          });
      },
      true
    );
  };

  const searchHandler = () => {
    if (systemId.id === 0) {
      sweetAlerts.warning("กรุณาเลือกระบบ");
    }
    if (roleId.id === 0) {
      sweetAlerts.warning("กรุณาเลือกกลุ่มผู้ใช้งาน");
    } else {
      setLoading(true);
      getPageInRole(roleId.id, systemId.id)
        .then(result => {
          setData(result.data.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
        });
    }
  };

  const columns = [
    {
      title: "userId",
      field: "userId",
      hidden: true
    },
    {
      title: "ใช้งาน/ไม่ใช้งาน",
      field: "isSelect",
      cellStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return (
          <Checkbox
            name="isSelect"
            checked={rowData.isSelect}
            onChange={e => {
              let applyPerson = data;

              applyPerson[rowData.tableData.id][e.target.name] =
                e.target.checked;
              setData([...applyPerson]);
            }}
          />
        );
      }
    },
    {
      title: "ชื่อเมนู",
      field: "pageName",
      cellStyle: {
        minWidth: 600
      }
    }
  ];

  const optionsTable = { pageSize: 10 };
  return (
    <>
      <Card>
        <CardHeader color="warning" text>
          <CardText color="warning">
            <h4>Role In Page</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxRoles
                required={true}
                value={roleId}
                onChange={onRoleSelected}
              ></ListboxRoles>
            </GridItem>

            <GridItem xs={6} sm={6} md={6}>
              <ListboxSystemList
                required={true}
                value={systemId}
                onChange={onSystemId}
              ></ListboxSystemList>
            </GridItem>
          </GridContainer>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button color="info" onClick={searchHandler}>
                ค้นหา
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <Card>
          <CardBody>
            <MaterialTable
              title={"รายชื่อหน้าจอ"}
              options={optionsTable}
              columns={columns}
              data={data}
              components={{
                Container: props => <Paper {...props} elevation={0} />
              }}
            />
            <br></br>
            <GridContainer
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <GridItem xs={6} sm={6} md={6}></GridItem>
              <GridItem>
                <Button color="success" onClick={handelSubmit}>
                  บันทึกข้อมูล
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      )}
    </>
  );
};
export default PageInRolesList;
