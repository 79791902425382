import React, {  useContext } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import ListboxProvince from "components/Common/ListboxProvince.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import ListboxVotedate from "components/Common/ListboxVotedate";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { FileUpload } from "components/Common/FileUpload";
//import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
//import ExcelUpload from "components/Common/ExcelUpload";

import { VoteDateContext } from "contexts/VoteDate";

const ImportFile61 = ({ pageProps, setPageProps }) => {
  const useStyles = makeStyles({});
  const { sweetAlerts } = useSweetAlert();
  const {
    optVoteTypeSelected,

    provinceSelected,
    optTypeSelected,
    amphurSelected,
    tambolSelected,
    optSelected,

    onOptVoteTypeSelected,

    onProvinceSelected,
    onOptTypeSelected,
    onAmphurSelected,
    onTambolSelected,
    onOptSelected,
    votedateSelected,
    onVotedateSelected,
    //uttils
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);
  const classes = useStyles();

  //local state

  //const [data, setdata] = useState([]);

  const backtoMainHandler = () => {
    setPageProps({ active: "FormManage61", props: null });
  };
  /* const columns = [
    {
      name: "province_name",
      label: "จังหวัด"
    },
    {
      name: "amphur_name",
      label: "อำเภอ"
    },
    {
      name: "tambol_name",
      label: "ตำบล"
    },
    {
      name: "opt_name",
      label: "เอกสารแบบ"
    },

    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button size="sm" color="danger">
              ลบ
            </Button>
          );
        }
      }
    }
  ];

  const options_table = {
    responsive: "scroll",
    filters: false,
    filter: false,
    sort: false,
    empty: true,
    search: false,
    sortFilterList: false,
    download: false,
    print: false,
    viewColumns: false
  }; */

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" text>
              <CardText color="primary">
                <h4 className={classes.cardTitle}>บันทึกแบบ ส.ถ./ผ.ถ. 6/1</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <br /> <br />
                    <GridContainer
                      justify="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <GridItem xs={12} sm={12} md={12}>
                        <CheckboxOpttype
                          optTypeSelected={optTypeSelected}
                          onOptTypeSelected={onOptTypeSelected}
                        ></CheckboxOpttype>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxOptVotetype
                          value={optVoteTypeSelected}
                          onChange={onOptVoteTypeSelected}
                        ></ListboxOptVotetype>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxProvince
                          value={provinceSelected}
                          onChange={onProvinceSelected}
                        ></ListboxProvince>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxDistinct
                          provinceSelected={provinceSelected}
                          value={amphurSelected}
                          onChange={onAmphurSelected}
                        ></ListboxDistinct>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxSubdistinct
                          amphurSelected={amphurSelected}
                          value={tambolSelected}
                          onChange={onTambolSelected}
                        ></ListboxSubdistinct>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxOpts
                          value={optSelected}
                          getOptTypeSelectedLists={getOptTypeSelectedLists}
                          optTypeSelected={optTypeSelected}
                          provinceSelected={provinceSelected}
                          amphurSelected={amphurSelected}
                          tambolSelected={tambolSelected}
                          onChange={onOptSelected}
                        ></ListboxOpts>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxVotedate
                          value={votedateSelected}
                          optSelected={optSelected}
                          optVoteTypeSelected={optVoteTypeSelected}
                          onChange={onVotedateSelected}
                        ></ListboxVotedate>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ paddingTop: "10px" }}>
                  <GridItem xs={12} sm={12} md={6}>
                    <FileUpload
                      id="file-upload-2"
                      label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                      remove
                      onFileHandler={(e, f) => {
                        sweetAlerts.success("แนบไฟล์เรียบร้อย");
                      }}
                      fileType="application/pdf"
                      btnName={
                        <>
                          Browse
                      </>
                      }
                      btnProps={{
                        color: "primary"
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <GridItem >
                    <Button onClick={backtoMainHandler}>ยกเลิก</Button>
                    <Button color="success" type="submit">
                      บันทึกข้อมูล
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default ImportFile61;
