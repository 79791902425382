import React, { useContext, useState } from "react";
import {
  getOnlineReport,
  saveOnlineReport,
  sendOnlineReport
} from "contexts/API/OPT/Report";

// import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//components
import ConvertDate from "utils/ConvertDate";
import FileSaver from "file-saver";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import HandelError from "utils/HandleError";
import ListboxFullYear from "components/Common/ListboxFullYear";
import ListboxMonth from "components/Common/ListboxMonth";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import { Paper } from "@material-ui/core";
import { REPORTING_SERVICE } from "config/env.conf";
import TableComponent from "components/Table/MaterialTable";
import Typography from "@material-ui/core/Typography";
import { UserInfoContext } from "contexts/users";
import { WtdContext } from "contexts/Wtd";
import { downloadFileFromUrl } from "contexts/API/FileManagement/download";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  buttoncustom: {
    fontSize: 14,
    fontWeight: 500
    //color: props => props.color
  }
});

const RptOnlineWtd = props => {
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const optionsTable = {
    pageSize: 20,
    sorting: false
  };
  const [loading, setLoading] = useState(false);
  const [yearSelected, setYearSelected] = useState(null);
  const onYearSelected = (evt, newValue) => {
    if (newValue) {
      setYearSelected(newValue);
    }
  };
  const [monthSelected, setMonthSelected] = useState(null);
  const onMonthSelected = (evt, newValue) => {
    if (newValue) {
      setMonthSelected(newValue);
    }
  };

  const downloadFile = (url, fileName) => {
    if (url && fileName) {
      downloadFileFromUrl(url)
        .then(res => {
          //   console.log(res);
          FileSaver.saveAs(res.data, fileName);
        })
        .catch(err => {
          console.log("download file failed.");
        });
    } else {
      throw Error(
        "url not found, see https://github.com/DGA-SCD/ECTPL/issues/463"
      );
    }
  };

  const [monthLists] = useState([
    { id: 0, name: "เลือกเดือน" },
    { id: 1, name: "มกราคม" },
    { id: 2, name: "กุมภาพันธ์" },
    { id: 3, name: "มีนาคม" },
    { id: 4, name: "เมษายน" },
    { id: 5, name: "พฤษภาคม" },
    { id: 6, name: "มิถุนายน" },
    { id: 7, name: "กรกฎาคม" },
    { id: 8, name: "สิงหาคม" },
    { id: 9, name: "กันยายน" },
    { id: 10, name: "ตุลาคม" },
    { id: 11, name: "พฤศจิกายน" },
    { id: 12, name: "ธันวาคม" }
  ]);

  const getReport = () => {

    if (monthSelected === null) {
      sweetAlerts.warning(`กรุณาเลือกเดือน`);
      return false;
    } else if (yearSelected === null) {
      sweetAlerts.warning(`กรุณาเลือกปี`);
      return false;
    } else {
      setLoading(true);
      let query = `?`;
      if (provinceSelected) {
        query += `&provinceId=${provinceSelected.id}`;
      }
      if (yearSelected) {
        query += `&year=${yearSelected.id}`;
      }
      if (monthSelected) {
        query += `&month=${monthSelected.id}`;
      }
      query += `&sysName=wtd`;
      getOnlineReport(query)
        .then(response => {
          let res = response.data.data;
          // console.log(res);
          let wholeArray = Object.keys(response.data.data).map(key => res[key]);
          //    console.log(wholeArray);

          setData(wholeArray);
          setLoading(false);
        })
        .catch(e => {
          HandelError(e);
          setLoading(false);
          sweetAlerts.error("ไม่สามารถดึงข้อมูล", e.toString());
        });
    }
  };
  const [columns] = useState([
    {
      title: "จังหวัด",
      field: "provinceName"
    },
    {
      title: "เดือน",
      field: "month",
      render: rowData => {
        const v = monthLists.find(e => e.id === parseInt(rowData.month));

        return v !== undefined ? v.name : "";
      }
    },
    {
      title: "ปี",
      field: "year",
      editable: "never"
    },
    {
      title: "รายงานผู้ถูกเพิกถอนสิทธิเลือกตั้ง",
      field: "type_5",
      cellStyle: {
        minWidth: "100px"
      },
      exportData: rowData => {
        // return "dd";
        let Items = "";
        if (rowData.type_5) {
          Items = rowData.type_5.map(
            (a, index) =>
              `ครั้งที่:${index + 1} วันที่:${ConvertDate(
                a.sendDatetime
              )} ผู้ส่ง:${a.firtName}\n`
          );
          return Items.toString();
        } else {
          return Items;
        }
      },

      render: rowData => {
        let Items = "";
        if (rowData.type_5) {
          Items = rowData.type_5.map((x, index) => (
            <ul key={index}>
              <li>
                <Typography>ครั้งที่ {x.sendNo}: </Typography>
                {x.fileUUID ? (
                  <Typography
                    style={{ cursor: "pointer", color: "purple" }}
                    onClick={() =>
                      downloadFile(
                        getFileDownloadUrl("online-reports", x.fileUUID),
                        x.fileName
                      )
                    }
                  >
                    Download
                  </Typography>
                ) : (
                  ""
                )}

                <Typography>วันที่ :{ConvertDate(x.sendDatetime)}</Typography>
                <Typography>ผู้ส่ง : {x.firtName}</Typography>
              </li>
            </ul>
          ));
          return Items;
        } else {
          return Items;
        }
      }
    },
    {
      title: "รายงานผู้พ้นจากการถูกเพิกถอนสิทธิ",
      field: "type_6",
      cellStyle: {
        minWidth: "100px"
      },
      exportData: rowData => {
        // return "dd";
        let Items = "";
        if (rowData.type_6) {
          Items = rowData.type_6.map(
            (a, index) =>
              `ครั้งที่:${index + 1} วันที่:${ConvertDate(
                a.sendDatetime
              )} ผู้ส่ง:${a.firtName}\n`
          );
          return Items.toString();
        } else {
          return Items;
        }
      },
      render: rowData => {
        let Items = "";
        if (rowData.type_6) {
          Items = rowData.type_6.map((x, index) => (
            <ul key={index}>
              <li>
                <Typography>ครั้งที่ {x.sendNo}: </Typography>
                {x.fileUUID ? (
                  <Typography
                    style={{ cursor: "pointer", color: "purple" }}
                    onClick={() =>
                      downloadFile(
                        getFileDownloadUrl("online-reports", x.fileUUID),
                        x.fileName
                      )
                    }
                  >
                    Download
                  </Typography>
                ) : (
                  ""
                )}

                <Typography>วันที่ :{ConvertDate(x.sendDatetime)}</Typography>
                <Typography>ผู้ส่ง : {x.firtName}</Typography>
              </li>
            </ul>
          ));
          return Items;
        } else {
          return Items;
        }
      }
    },
    {
      title: "รายงานผู้ถูกเพิกถอนที่คดีอยู่ในระหว่างอุทธรณ์",
      field: "type_7",
      cellStyle: {
        minWidth: "100px"
      },
      exportData: rowData => {
        // return "dd";
        let Items = "";
        if (rowData.type_7) {
          Items = rowData.type_7.map(
            (a, index) =>
              `ครั้งที่:${index + 1} วันที่:${ConvertDate(
                a.sendDatetime
              )} ผู้ส่ง:${a.firtName}\n`
          );
          return Items.toString();
        } else {
          return Items;
        }
      },
      render: rowData => {
        let Items = "";
        if (rowData.type_7) {
          Items = rowData.type_7.map((x, index) => (
            <ul key={index}>
              <li>
                <Typography>ครั้งที่ {x.sendNo}: </Typography>
                {x.fileUUID ? (
                  <Typography
                    style={{ cursor: "pointer", color: "purple" }}
                    onClick={() =>
                      downloadFile(
                        getFileDownloadUrl("online-reports", x.fileUUID),
                        x.fileName
                      )
                    }
                  >
                    Download
                  </Typography>
                ) : (
                  ""
                )}

                <Typography>วันที่ :{ConvertDate(x.sendDatetime)}</Typography>
                <Typography>ผู้ส่ง : {x.firtName}</Typography>
              </li>
            </ul>
          ));
          return Items;
        } else {
          return Items;
        }
      }
    },
    {
      title: "รายงานผู้ถูกเพิกถอนที่คดีอยู่ในระหว่างฏีกา",
      field: "type_8",
      cellStyle: {
        minWidth: "100px"
      },
      exportData: rowData => {
        // return "dd";
        let Items = "";
        if (rowData.type_8) {
          Items = rowData.type_8.map(
            (a, index) =>
              `ครั้งที่:${index + 1} วันที่:${ConvertDate(
                a.sendDatetime
              )} ผู้ส่ง:${a.firtName}\n`
          );
          return Items.toString();
        } else {
          return Items;
        }
      },
      render: rowData => {
        let Items = "";
        if (rowData.type_8) {
          Items = rowData.type_8.map((x, index) => (
            <ul key={index}>
              <li>
                <Typography>ครั้งที่ {x.sendNo}: </Typography>
                {x.fileUUID ? (
                  <Typography
                    style={{ cursor: "pointer", color: "purple" }}
                    onClick={() =>
                      downloadFile(
                        getFileDownloadUrl("online-reports", x.fileUUID),
                        x.fileName
                      )
                    }
                  >
                    Download
                  </Typography>
                ) : (
                  ""
                )}

                <Typography>วันที่ :{ConvertDate(x.sendDatetime)}</Typography>
                <Typography>ผู้ส่ง : {x.firtName}</Typography>
              </li>
            </ul>
          ));
          return Items;
        } else {
          return Items;
        }
      }
    },
    {
      title: "รายงานผู้ถูกเพิกถอนที่ย้ายเข้า-ย้ายออก",
      field: "type_12",
      cellStyle: {
        minWidth: "100px"
      },
      exportData: rowData => {
        // return "dd";
        let Items = "";
        if (rowData.type_12) {
          Items = rowData.type_12.map(
            (a, index) =>
              `ครั้งที่:${index + 1} วันที่:${ConvertDate(
                a.sendDatetime
              )} ผู้ส่ง:${a.firtName}\n`
          );
          return Items.toString();
        } else {
          return Items;
        }
      },
      render: rowData => {
        let Items = "";
        if (rowData.type_12) {
          Items = rowData.type_12.map((x, index) => (
            <ul key={index}>
              <li>
                <p>
                  <Typography>ครั้งที่ {x.sendNo}: </Typography>
                  {x.fileUUID ? (
                    <Typography
                      style={{ cursor: "pointer", color: "purple" }}
                      onClick={() =>
                        downloadFile(
                          getFileDownloadUrl("online-reports", x.fileUUID),
                          x.fileName
                        )
                      }
                    >
                      Download
                    </Typography>
                  ) : (
                    ""
                  )}
                </p>
                <Typography>วันที่ :{ConvertDate(x.sendDatetime)}</Typography>
                <Typography>ผู้ส่ง : {x.firtName}</Typography>
              </li>
            </ul>
          ));
          return Items;
        } else {
          return Items;
        }
      }
    }
  ]);

  const classes = useStyles();
  const { provinceSelected, onProvinceSelected } = useContext(WtdContext);
  const { userInfo } = useContext(UserInfoContext);
  var date = new Date();
  const new_date = {
    year: "numeric",
    month: "short",
    day: "numeric"
  };

  const today = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  ).toLocaleDateString("th-TH", new_date);
  // var firstOfnextMonth = new Date(
  //   date.getFullYear(),
  //   date.getMonth() + 1,
  //   1
  // ).toLocaleDateString("th-TH", new_date);
  // var lastOfnextMonth = new Date(
  //   date.getFullYear(),
  //   date.getMonth() + 2,
  //   0
  // ).toLocaleDateString("th-TH", new_date);

  const openViewerReport = url => {
    // const openurl = `${url}province_id=${userInfo.provinceId}`;
    window.open(url, "_blank");
  };
  const sendReport = (fileName, reportTypeId, url) => {
    // let _tmp = `province_id=${userInfo.provinceId}&rpt_send_date=${moment(
    //   new Date()
    // ).format("YYYY-MM-D")}`;
    // let reportUrl = url + _tmp;
    // let reportUrl =
    //   "ReportServer?%2freport_online%2fopt%2fopt_rpt11_1&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&province_id=40&rpt_send_date=2014-03-05";
    //console.log(url);
    saveOnlineReport(fileName, encodeURIComponent(url))
      .then(response => {
        let res = response.data.data;
        console.log(res);
        if (res.fileId) {
          sendOnlineReport(
            provinceSelected.id,
            reportTypeId,
            res.fileId,
            "pdf"
          ).then(r => {
            sweetAlerts.success("ส่งข้อมูลสำเร็จ");
          });
        } else {
          sweetAlerts.danger("ขออภัยส่งข้อมูลไม่สำเร็จ");
        }
      })
      .catch(err => {
        sweetAlerts.danger("ขออภัยส่งข้อมูลไม่สำเร็จ");
      });
  };

  // useEffect(() => {
  //   //console.log("userInfo>>",userInfo)
  //   if (provinceSelected === null) {
  //     setProvinceSelected({
  //       id: userInfo.provinceId !== null ? userInfo.provinceId : 0
  //     });
  //   }
  // }, [userInfo]);

  return (
    <>
      {!userInfo.iscenter ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem
                    xs={4}
                    sm={4}
                    md={4}
                    style={{ alignItems: "center" }}
                  >
                    <h4 className={classes.cardTitle}>รายการรายงานออนไลน์</h4>
                  </GridItem>

                  <br />
                  <br />
                  <br />
                  <GridContainer style={{ paddingLeft: "20px" }}>
                    <GridItem
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ textAlign: "right" }}
                    >
                      รายงานประจำเดือน/ปี :
                    </GridItem>
                    <GridItem
                      xs={9}
                      sm={9}
                      md={9}
                      style={{ textAlign: "left" }}
                    >
                      {/* {let formattedDate = format(date, "MMMM do, yyyy H:mma")

console.log(formattedDate);} */}
                      {new Date().getMonth() + 1}/
                      {new Date().getFullYear() + 543}
                    </GridItem>
                  </GridContainer>
                  {/* <GridContainer style={{ paddingLeft: "20px" }}>
                  <GridItem xs={3} sm={3} md={3} style={{ textAlign: "right" }}>
                    ปี :
                  </GridItem>
                  <GridItem xs={9} sm={9} md={9} style={{ textAlign: "left" }}>
                    {new Date().getFullYear() + 543}
                  </GridItem>
                </GridContainer> */}

                  <GridContainer
                    style={{ paddingLeft: "20px", paddingTop: "10px" }}
                  >
                    <GridItem
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ textAlign: "right" }}
                    >
                      วันที่ส่งรายงาน :
                    </GridItem>
                    <GridItem
                      xs={9}
                      sm={9}
                      md={9}
                      style={{ textAlign: "left" }}
                    >
                      {ConvertDate(new Date())}
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    style={{ paddingLeft: "20px", alignItems: "center" }}
                  >
                    <GridItem
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ textAlign: "right" }}
                    >
                      จังหวัด :
                    </GridItem>
                    <GridItem
                      xs={9}
                      sm={9}
                      md={9}
                      style={{ textAlign: "left" }}
                    >
                      <ListboxProvince
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                      />
                    </GridItem>
                  </GridContainer>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      รายงานภายในวันที่ 1 {moment(new Date()).format("MMM")}
                      {new Date().getFullYear() + 543} - 15{" "}
                      {moment(new Date()).format("MMM")}
                      {new Date().getFullYear() + 543}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px"
                  }}
                >
                  <GridItem xs={12} sm={12} md={8}>
                    <div>
                      - รายงานผู้ถูกเพิกถอนสิทธิเลือกตั้ง ณ วันที่ {today}
                      {/* //  {ConvertDate(new Date())} */}
                    </div>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      disabled={new Date().getDate() > 18 ? true : false}
                      onClick={() =>
                        sendReport(
                          `รายงานผู้ถูกเพิกถอนสิทธิเลือกตั้ง ${today}.pdf`,
                          5,
                          // "ReportServer?%2freport_online%2fopt%2fopt_rpt11_1&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&"
                          `ReportServer?/report_online/wtd/wtd_rptWTDPerson&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&province_id=${userInfo.provinceId}`
                        )
                      }
                    >
                      ส่งรายงาน
                    </Button>

                    <Button
                      type="submit"
                      onClick={() =>
                        openViewerReport(
                          `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?/report_online/wtd/wtd_rptWTDPerson&rs:Command=Render&rc:Parameters=Collapsed&province_id=${userInfo.provinceId}`
                        )
                      }
                    >
                      แสดงตัวอย่างรายงาน
                    </Button>
                  </GridItem>
                </GridContainer>
                {/* -------------2 -------------------*/}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      รายงานภายในวันที่ 1 {moment(new Date()).format("MMM")}
                      {new Date().getFullYear() + 543} - 15{" "}
                      {moment(new Date()).format("MMM")}
                      {new Date().getFullYear() + 543}
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px"
                  }}
                >
                  <GridItem xs={12} sm={12} md={8}>
                    <div>
                      - รายงานผู้พ้นจากการถูกเพิกถอนสิทธิการเลือกตั้งฯ
                      ระหว่างวันที่{" "}
                      {new Date(
                        date.getFullYear(),
                        date.getMonth() - 1,
                        1
                      ).toLocaleDateString("th-TH", new_date)}
                      {" - "}
                      {new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        0
                      ).toLocaleDateString("th-TH", new_date)}
                      {/* (เฉพาะเดือน มี.ค. 56 ให้ส่งได้ถึงวันที่ 25 มี.ค. 56) */}
                    </div>
                  </GridItem>

                  <GridItem>
                    <Button
                      color="success"
                      disabled={new Date().getDate() > 18 ? true : false}
                      onClick={() =>
                        sendReport(
                          `รายงานผู้พ้นจากการถูกเพิกถอนสิทธิการเลือกตั้งฯ${new Date(
                            date.getFullYear(),
                            date.getMonth() - 1,
                            1
                          ).toLocaleDateString("th-TH", new_date)}-${new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            0
                          ).toLocaleDateString("th-TH", new_date)}.pdf`,
                          6,
                          //  "ReportServer?%2freport_online%2fopt%2fopt_rpt11_2&rs:Command=Render&rc:Parameters=Collapsed&rs:Format=PDF&"
                          `ReportServer?%2freport_online%2fwtd%2fwtd_rptWTDPersonPass&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&province_id=${userInfo.provinceId
                          }&rpt_send_date=${moment(new Date()).format(
                            "YYYY-MM-D"
                          )}`
                        )
                      }
                    >
                      ส่งรายงาน
                    </Button>
                    <Button
                      onClick={() =>
                        openViewerReport(
                          `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freport_online%2fwtd%2fwtd_rptWTDPersonPass&rs:Command=Render&rc:Parameters=Collapsed&province_id=${userInfo.provinceId
                          }&rpt_send_date=${moment(new Date()).format(
                            "YYYY-MM-D"
                          )}`
                        )
                      }
                    >
                      แสดงตัวอย่างรายงาน
                    </Button>
                  </GridItem>
                </GridContainer>
                {/* -------------3 -------------------*/}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      รายงานภายในวันที่ 1 {moment(new Date()).format("MMM")}
                      {new Date().getFullYear() + 543} - 15{" "}
                      {moment(new Date()).format("MMM")}
                      {new Date().getFullYear() + 543}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px"
                  }}
                >
                  <GridItem xs={12} sm={12} md={8}>
                    <div>
                      - รายงานผู้ถูกเพิกถอนที่คดีอยู่ในระหว่างอุทธรณ์ ณ วันที่{" "}
                      {today}
                    </div>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      disabled={new Date().getDate() > 18 ? true : false}
                      onClick={() =>
                        sendReport(
                          `รายงานผู้ถูกเพิกถอนที่คดีอยู่ในระหว่างอุทธรณ์${today}}.pdf`,
                          7,

                          //  "ReportServer?%2freport_online%2fopt%2fopt_rpt11_4&rs:Command=Render&rc:Parameters=Collapsed&rs:Format=PDF&"
                          // `ReportServer/?%2freport_online%2fwtd%2fwtd_rptWTDPersonAppeal&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&province_id=${
                          //   userInfo.provinceId
                          // }&rpt_send_date=${moment(new Date()).format(
                          //   "YYYY-MM-D"
                          // )}`
                          `ReportServer/?%2freport_online%2fwtd%2fwtd_rptWTDPersonAppeal&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&province_id=${userInfo.provinceId
                          }&rpt_send_date=${moment(new Date()).format(
                            "YYYY-MM-D"
                          )}`
                        )
                      }
                    >
                      ส่งรายงาน
                    </Button>
                    <Button
                      onClick={() =>
                        openViewerReport(
                          `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freport_online%2fwtd%2fwtd_rptWTDPersonAppeal&rs:Command=Render&rc:Parameters=Collapsed&province_id=${userInfo.provinceId
                          }&rpt_send_date=${moment(new Date()).format(
                            "YYYY-MM-D"
                          )}`
                        )
                      }
                    >
                      แสดงตัวอย่างรายงาน
                    </Button>
                  </GridItem>
                </GridContainer>
                {/* -------------4 -------------------*/}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      รายงานภายในวันที่ 1 {moment(new Date()).format("MMM")}
                      {new Date().getFullYear() + 543} - 15{" "}
                      {moment(new Date()).format("MMM")}
                      {new Date().getFullYear() + 543}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px"
                  }}
                >
                  <GridItem xs={12} sm={12} md={8}>
                    <div>
                      - รายงานผู้ถูกเพิกถอนที่คดีอยู่ในระหว่างฏีกา ณ วันที่{" "}
                      {today}
                    </div>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      disabled={new Date().getDate() > 18 ? true : false}
                      onClick={() =>
                        sendReport(
                          `รายงานผู้ถูกเพิกถอนที่คดีอยู่ในระหว่างฏีกา${today}.pdf`,
                          8,
                          `ReportServer/Pages?%2freport_online%2fwtd%2fwtd_rptWTDPersonSupreme&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&province_id=${userInfo.provinceId
                          }&rpt_send_date=${moment(new Date()).format(
                            "YYYY-MM-D"
                          )}`
                        )
                      }
                    >
                      ส่งรายงาน
                    </Button>
                    <Button
                      onClick={() =>
                        openViewerReport(
                          `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freport_online%2fwtd%2fwtd_rptWTDPersonSupreme&rs:Command=Render&rc:Parameters=Collapsed&province_id=${userInfo.provinceId
                          }&rpt_send_date=${moment(new Date()).format(
                            "YYYY-MM-D"
                          )}`
                        )
                      }
                    >
                      แสดงตัวอย่างรายงาน
                    </Button>
                  </GridItem>
                </GridContainer>

                {/* -------------5 -------------------*/}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      รายงานภายในวันที่ 1 {moment(new Date()).format("MMM")}
                      {new Date().getFullYear() + 543} - 15{" "}
                      {moment(new Date()).format("MMM")}
                      {new Date().getFullYear() + 543}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px"
                  }}
                >
                  <GridItem xs={12} sm={12} md={8}>
                    <div>- รายงานผู้ถูกเพิกถอนที่ย้ายเข้า - ย้ายออก</div>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      disabled={new Date().getDate() > 18 ? true : false}
                      onClick={() =>
                        sendReport(
                          `รายงานผู้ถูกเพิกถอนที่ย้ายเข้า - ย้ายออก.pdf`,
                          12,
                          // "ReportServer?%2freport_online%2fopt%2fopt_rpt11_4&rs:Command=Render&rc:Parameters=Collapsed&rs:Format=PDF&"
                          `ReportServer/?%2freport_online%2fwtd%2fwtd_rptWTDPersonMove&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&province_id=${userInfo.provinceId}`
                          // &rpt_send_date=${moment(new Date()).format(
                          //   "YYYY-MM-D"
                          // )}`
                          // http://ectpl-be.scd-dga.com/ReportServer/ReportViewer.aspx?/report_online/wtd/wtd_rptWTDPerson&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&province_id=40
                          //  /ReportServer/?%2freport_online%2fwtd%2fwtd_rptWTDPersonMove&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&province_id=40
                        )
                      }
                    >
                      ส่งรายงาน
                    </Button>
                    <Button
                      onClick={() =>
                        openViewerReport(
                          `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freport_online%2fwtd%2fwtd_rptWTDPersonMove&rs:Command=Render&rc:Parameters=Collapsed&province_id=${userInfo.provinceId}`
                        )
                      }
                    >
                      แสดงตัวอย่างรายงาน
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4 className={classes.cardTitle}>
                    ค้นหาประวัติการส่งรายงานออนไลน์
                  </h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxProvince
                    disabled={!userInfo.iscenter}
                    defaultValue={{
                      id:
                        userInfo.provinceId !== null && !userInfo.iscenter
                          ? userInfo.provinceId
                          : null
                    }}
                    //value={provinceSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxMonth
                    value={monthSelected}
                    onChange={onMonthSelected}
                  ></ListboxMonth>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxFullYear
                    value={yearSelected}
                    onChange={onYearSelected}
                  ></ListboxFullYear>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer justify="space-between" alignItems="center">
                <GridItem xs={12} sm={12} md={8}></GridItem>
                <GridItem>
                  <Button color="info" onClick={getReport}>
                    ดูประวัติ
                  </Button>
                  {/* <Button
                    color="twitter"
                    className={classes.buttoncustom}
                    type="submit"
                  >
                    Export Report
                  </Button> */}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title={"ประวัติการส่งรายงานออนไลน์"}
          columns={columns}
          data={data}
          components={{
            Container: props => <Paper {...props} elevation={0} />
          }}
          localization={{}}
          options={optionsTable}
        />
      )}
    </>
  );
};

export default RptOnlineWtd;
