import React, { useEffect, useState } from "react";
// Context APIs
import {
  addOneOptArea,
  deleteOptArea,
  editOptArea,
  getAllOptAreaByOptId
} from "contexts/API/OPT/OptArea";

import Button from "components/CustomButtons/Button.js";
// Material Core
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// Core Component
import ListboxAumphurAreaofTable from "components/Common/ListboxAumphurAreaofTable";
import MaterialTable from "components/Table/MaterialTable";
import { getAmphurs } from "contexts/API/MS/MS";
//import { useSnackBar } from 'components/Alert/Snackbar';
import { useSweetAlert } from "components/Alert/SweetAlert";

const ModalTableaddArea = props => {
  const { showModaladdArea, closeModaladdArea, optSubtypeSelected } = props;

  //const { snackBars } = useSnackBar();
  const { sweetAlerts } = useSweetAlert();

  const [data, setData] = useState([]);
  const [opt, setOpt] = useState(null);
  const [amphurLists, setAmphurLists] = useState([]);

  const getOptAreaData = () => {
    getAllOptAreaByOptId(props.optSelected.id)
      .then(result => {
        if (
          result.data.data !== undefined ||
          result.data.data.length > 0 ||
          result.data.data.opt_area
        ) {
          //   console.log(result.data.data[0].opt_area);
          setOpt(result.data.data[0]);
          setData(result.data.data[0].opt_area);
        } else {
          //TODO: do somethings
        }
      })
      .catch(err => {
        //sweetAlerts.error('ไม่สามารถดึงข้อมูลเขตได้', err.toString());
      });
  };

  // if (data.length === 0 && props.optSelected !== null) {
  //   // getOptAreaData();
  // }

  useEffect(() => {
    if (data.length === 0 && props.optSelected !== null) {
      getOptAreaData();
    }
    return () => {
      setData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.provinceSelected !== null && amphurLists.length === 0) {
      getAmphurs(props.provinceSelected.id)
        .then(r => {
          let amphureData = r.data.data.map(v => {
            return { id: v.amphur_id, value: v.amphur_name };
          });
          setAmphurLists(amphureData);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [props.provinceSelected, amphurLists]);

  /* Material Table Setting */
  const columns = [
    {
      align: "center",
      editable: "onAdd",
      field: "amphur_id",
      title: "เขตอำเภอ",
      type: "numeric",
      render: rawData => {
        const v = amphurLists.find(e => e.id === rawData.amphur_id);
        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        if (optSubtypeSelected.id === 1) {
          return (
            <ListboxAumphurAreaofTable
              rowId={0}
              customProps={{
                disabled:
                  props.rowData.tableData !== undefined &&
                  props.rowData.tableData.editing === "update"
              }}
              defaultValue={{ id: props.value }}
              data={amphurLists}
              onChange={(i, e, v) => {
                if (v !== null) {
                  props.onChange(v.id);
                }
              }}
            />
          );
        } else {
          return null;
        }
      }
    },
    {
      hidden: true,
      field: "opt_area_id",
      title: "opt_area_id",
      type: "numeric"
    },
    {
      align: "center",
      editable: "onAdd",
      field: "area_no",
      title: "เขตเลือกตั้ง",
      type: "numeric",
      validate: rowData =>
        rowData.area_no <= 0
          ? { isValid: true, helperText: "ต้องมากกว่า 0" }
          : true
    },
    {
      align: "center",
      field: "num_member",
      title: "จำนวนสมาชิก",
      type: "numeric",
      validate: rowData =>
        rowData.num_member <= 0
          ? { isValid: true, helperText: "ต้องมากกว่า 0" }
          : true
    },
    {
      align: "center",
      field: "num_station",
      title: "จำนวนหน่วย",
      type: "numeric",
      validate: rowData =>
        rowData.num_station <= 0
          ? { isValid: true, helperText: "ต้องมากกว่า 0" }
          : true
    }
  ];

  const tableOptions = {};

  const editAble = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        if (opt.ms_opt_sub_type_id === 1 && newData.amphur_id === undefined) {
          sweetAlerts.warning("กรุณาระบุอำเภอ");
          reject();
        } else if (newData.area_no === undefined || NaN) {
          sweetAlerts.warning("กรุณาระบุเขต");
          reject();
        } else if (newData.num_member === undefined) {
          sweetAlerts.warning("กรุณาระบุจำนวนสมาชิก");
          reject();
        } else if (newData.num_station === undefined) {
          sweetAlerts.warning("กรุณาระบุจำนวนหน่วย");
          reject();
        } else {
          setTimeout(() => {
            addOneOptArea(
              props.optSelected.id,
              optSubtypeSelected.id,
              newData.area_no,
              newData.amphur_id || props.amphurId.id,
              newData.num_member,
              newData.num_station
            )
              .then(result => {
                newData.opt_area_id = result.data.data.optAreaId;
                sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
                setData([...data, newData]);
                props.onaddSuccess(result.data.data.optAreaId);
                resolve();
              })
              .catch(err => {
                sweetAlerts.danger("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
                reject();
              });
          }, 1000);
        }
      }),

    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        if (newData.num_member === undefined) {
          sweetAlerts.warning("กรุณาระบุจำนวนสมาชิก");
          reject();
        } else if (newData.num_station === undefined) {
          sweetAlerts.warning("กรุณาระบุจำนวนหน่วย");
          reject();
        } else {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          editOptArea(
            props.optSelected.id,
            newData.opt_area_id,
            newData.area_no,
            newData.amphur_id,
            newData.num_member,
            newData.num_station
          )
            .then(result => {
              setData([...dataUpdate]);
              sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
              resolve();
            })
            .catch(err => {
              if (err.response.data.code === 40301) {
                sweetAlerts.danger(
                  `หมายเลขเขตที่ ${newData.area_no} มีอยู่แล้ว`
                );
              }
              reject();
            });
        }
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);

        deleteOptArea(props.optSelected.id, oldData.opt_area_id, "")
          .then(result => {
            setData([...dataDelete]);
            sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
            resolve();
          })
          .catch(err => {
            sweetAlerts.danger(`ลบข้อมูลไม่สำเร็จ`, err.response.data.desc);
          });
      })
  };

  return (
    <div className={showModaladdArea ? "modal" : "hide"}>
      <Dialog
        maxWidth="md"
        open={showModaladdArea}
        onClose={closeModaladdArea}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">เพิ่มเขตเลือกตั้ง</DialogTitle>
        <DialogContent>
          <MaterialTable
            data={data}
            columns={columns}
            options={tableOptions}
            title="เขตเลือกตั้ง"
            editable={editAble}
          />
          <DialogActions>
            <Button onClick={closeModaladdArea}>ปิดหน้าต่าง</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalTableaddArea;
