import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from 'axios';
const cookies = new Cookies();

export function wsWTDAddWtdPerson(
    idCard, status, startDate, endDate
) {
    const isAuth = cookies.get("userInfo");
    const url = `${END_POINT_URL}/wsDopa/addWtdPerson`;
    const body = {
        citizenID: idCard,
        status: status,
        startDate: startDate,
        endDate: endDate
    };
    return axios.post(url, body, {
        headers: {
            Authorization: `Bearer ${isAuth.token}`
        }
    });
}

export function wsWTDUpdWtdPerson(
    idCard, status, startDate, endDate
) {
    const isAuth = cookies.get("userInfo");
    const url = `${END_POINT_URL}/wsDopa/updWtdPerson`;
    const body = {
        citizenID: idCard,
        status: status,
        startDate: startDate,
        endDate: endDate
    };
    return axios.put(url, body, {
        headers: {
            Authorization: `Bearer ${isAuth.token}`
        }
    });
}


export function wsWTDDelWtdPerson(idCard) {
    const url = `${END_POINT_URL}/wsDopa/delWtdPerson?citizenID=${idCard}`;
    const isAuth = cookies.get("userInfo");
    return axios.delete(url, {
        headers: {
            Authorization: `Bearer ${isAuth.token}`
        }
    });
}


