import {Breadcrumbs} from "@material-ui/core";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import TodayIcon from "@material-ui/icons/Today";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import UnreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import React, {useState} from "react";
import CustomButtons from "components/CustomButtons/Button"

export const SortBy = {
    Alphabet: "alphabet",
    Province: "province",
    Date: "date",
    Unread: "unread",
}

const Sortable = ({currentSortBy, onSortBy}) => {

    const [sortBy, setSortBy] = useState(currentSortBy || SortBy.Unread)

    const Button = (props) => {
        if ( props.sortBy && props.sortBy === sortBy) {
            return <CustomButtons onClick={props.onClick} color={props.color} size={props.size} round children={props.children}/>
        }
        return <CustomButtons onClick={props.onClick} size={props.size} round children={props.children}/>
    }

    return (
        <div className="sortable">
            <span className="sortable__sort-type">จัดเรียง: </span>
            <Breadcrumbs aria-label="breadcrumb">
                <Button onClick={()=>{onSortBy(SortBy.Alphabet); setSortBy(SortBy.Alphabet)}} sortBy={SortBy.Alphabet} color="primary" size="sm" round><SortByAlphaIcon/>ตัวอักษร</Button>
                <Button onClick={()=>{onSortBy(SortBy.Province); setSortBy(SortBy.Province)}} sortBy={SortBy.Province} color="info" size="sm" round><TodayIcon/>จังหวัด</Button>
                <Button onClick={()=>{onSortBy(SortBy.Date);  setSortBy(SortBy.Date)}} sortBy={SortBy.Date} color="warning" size="sm" round><LocationCityIcon/>วันที่</Button>
                <Button onClick={()=>{onSortBy(SortBy.Unread); setSortBy(SortBy.Unread)}} sortBy={SortBy.Unread} color="rose" size="sm" round><UnreadMailboxIcon/>ยังไม่อ่าน</Button>
            </Breadcrumbs>
        </div>)
}

export default Sortable;