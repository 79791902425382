import React, { useContext, useState } from "react";
import {
  addQualification,
  delQualification,
  getQualification
} from "contexts/API/INSPEC/Qualification";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputWithValidator from "components/CustomInput/CustomInputWithValidator";
import { FileUpload } from "components/Common/FileUpload";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import { InspectorContext } from "contexts/Inspector";
import LinkDownload from "components/Common/LinkDownload";
import ListboxInspectApplyDate from "components/Common/ListboxInspectApplyDate";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import { Paper } from "@material-ui/core";
import { REPORTING_SERVICE } from "config/env.conf";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const Qualification = props => {
  const {
    provinceSelected,
    onProvinceSelected,
    onInspectApplyDateSelected,
    inspectApplyDateSelected
  } = useContext(InspectorContext);
  const { sweetAlerts } = useSweetAlert();
  const [loading, setLoading] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [validOrg, setValidOrg] = useState(true);
  const [orgForExport, setOrgForExport] = useState(null);
  const [description, setDescription] = useState("");
  const [orgNameHelptext, setOrgNameHelptext] = useState("*กรุณาระบุ");
  const [descriptionHelptext, setDescriptionHelptext] = useState("*กรุณาระบุ");
  const [fileUUID, setFileUUID] = useState(null);
  const [urlFile, setUrlFile] = useState();
  const [uploadFileName, setUploadFileName] = useState(null);
  const [columns] = useState([
    {
      title: "หน่วยงานที่ตรวจสอบ",
      field: "orgName",
      cellStyle: {
        minWidth: "200px",
        alignItems: "center"
      }
    },
    {
      title: "คำอธิบาย",
      field: "description",
      cellStyle: {
        minWidth: "200px"
      }
    },
    {
      title: "วันที่อัปโหลดไฟล์",
      field: "createDatetime",
      cellStyle: {
        minWidth: "150px"
      },
      render: rowData => {
        return ConvertDate(rowData.createDatetime);
      }
    },
    {
      title: "ข้อมูลรายละเอียดไฟล์",
      field: "fileName",
      cellStyle: {
        minWidth: "200px"
      },
      render: rowData => {
        return rowData.fileUUID ? (
          <LinkDownload
            url={getFileDownloadUrl("inpsect", rowData.fileUUID)}
            fileId={rowData.fileUUID}
            fileName={rowData.fileName}
          />
        ) : (
          ""
        );
      }
    }
  ]);

  const [data, setData] = useState([]);
  const optionsTable = {};

  const clickSearch = () => {
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }
    if (inspectApplyDateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันที่สมัคร");
      return;
    }
    if (orgForExport === null) {
      sweetAlerts.warning("กรุณาเลือกหน่วยงานที่ตรวจสอบข้อมูล");
      return;
    } else {
      setLoading(true);
      getQualification(provinceSelected.id, inspectApplyDateSelected.id)
        .then(result => {
          setData(result.data.data);
          setLoading(false);
        })
        .catch(error => {
          HandelError(error);
          setLoading(true);
          //   sweetAlerts.error("ไม่สามารถดึงข้อมูล อปท.", err.toString());
        });
    }
  };

  const handelSubmit = () => {
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }

    if (inspectApplyDateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกช่วงวันที่สมัคร");
      return;
    }
    if (orgName === "") {
      sweetAlerts.warning("กรุณาเลือกหน่วยงานที่ตรวจสอบข้อมูล");
      return;
    }

    if (description === "") {
      sweetAlerts.warning("กรุณาระบุคำอธิบายไฟล์");
      return;
    }
    if (fileUUID === null) {
      sweetAlerts.warning("กรุณาอัพโหลดไฟล์");
      return;
    } else {
      setLoading(false);
      addQualification(
        provinceSelected.id,
        inspectApplyDateSelected.id,
        orgName,
        description,
        fileUUID
      )
        .then(result => {
          if (result.data.success) {
            sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");
            clickSearch();
            setUploadFileName("");
            setUrlFile("");
            setDescription("");
            setOrgName("");
          } else {
            sweetAlerts.danger("ไม่สามารถบันทึกข้อมูลได้", result.data.desc);
          }
        })
        .catch(error => {
          // HandelError(error.response.status);
          setLoading(false);
          sweetAlerts.danger(
            "ไม่สามารถบันทึกข้อมูลได้",
            error.response.data.desc
          );
        });
    }
  };

  const handleSubmitExport = e => {
    e.preventDefault();
    window.open(
      `${REPORTING_SERVICE}/ReportServer/ReportViewer.aspx?%2freports%2finspector%2finspector_rpt12_7&rs:Command=Render&rs:Format=EXCELOPENXML&rc:Parameters=Collapsed&applyDateId=${inspectApplyDateSelected.id}&provinceId=${provinceSelected.id}&orgName=${orgForExport}`,
      "_blank"
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="danger" text>
          <CardText color="danger">
            <h4>บันทึกหลักฐานการตรวจสอบคุณสมบัติผู้สมัคร</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <ListboxProvince
                    required={true}
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <ListboxInspectApplyDate
                    value={inspectApplyDateSelected}
                    required={true}
                    onChange={onInspectApplyDateSelected}
                  ></ListboxInspectApplyDate>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="หน่วยงานตรวจสอบ"
                    error={validOrg}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: orgForExport || "",
                      onChange: e => {
                        setOrgForExport(e.target.value);
                        setValidOrg(false);
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}>
                  <Button
                    color="success"
                    disabled={
                      !(
                        provinceSelected &&
                        inspectApplyDateSelected &&
                        orgForExport
                      )
                    }
                    onClick={handleSubmitExport}
                  >
                    ดาวน์โหลดรายชื่อผู้สมัคร
                  </Button>
                </GridItem>
                <GridItem>
                  <Button onClick={e => setData([])}>ยกเลิก</Button>
                  <Button color="info" onClick={clickSearch}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <MaterialTable
                title={"หลักฐานการตรวจสอบคุณสมบัติผู้สมัคร"}
                options={optionsTable}
                columns={columns}
                data={data}
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}
                editable={{
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...data];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        delQualification(oldData.qualificationFileuploadId)
                          .then(result => {
                            setData([...dataDelete]);
                            sweetAlerts.success("ลบข้อมูลเรียบร้อย");
                            //resolve();
                          })
                          .catch(err => {
                            //HandelError(err);
                            sweetAlerts.error(
                              "ไม่สามารถลบข้อมูลได้",
                              err.response.data.desc
                            );
                          });

                        resolve();
                      }, 1000);
                    })
                }}
              />
            </Card>
          </GridItem>
        </GridContainer>
      )}
      <GridContainer>
        {provinceSelected && inspectApplyDateSelected ? (
          <Card>
            <CardHeader color="danger" text>
              <CardText color="danger">
                <h4>
                  แบบฟอร์มบันทึกไฟล์ผลการตรวจสอบคุณสมบัติและลักษณะต้องห้าม
                </h4>
              </CardText>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FileUpload
                        id="file-upload-1"
                        label="แนบไฟล์ (PDF หรือ Excel เท่านั้น)"
                        remove
                        download
                        fileName={uploadFileName}
                        onFileRemove={() => {
                          setUploadFileName("");
                          setUrlFile("");
                        }}
                        //url={getFileDownloadUrl("inspector", fileUUID)}
                        url={urlFile}
                        onFileHandler={(e, f, p) => {
                          uploadFile("inspect", f, p)
                            .then(r => {
                              const file = r.data.data;
                              sweetAlerts.success("แนบไฟล์เรียบร้อย");
                              setUploadFileName(file.fileName);
                              setFileUUID(file.fileId);
                              setUrlFile(
                                getFileDownloadUrl("inspect", file.fileId)
                              );
                            })
                            .catch(err => {
                              console.log(err);
                            });
                        }}
                        fileType="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        btnName={<>Browse</>}
                        btnProps={{
                          color: "primary"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>
                  <br></br>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInputWithValidator
                        labelText="คำอธิบายไฟล์"
                        formControlProps={{
                          fullWidth: true,
                          setError: true
                        }}
                        inputProps={{
                          value: description,
                          onChange: e => {
                            if (e.target.value) {
                              setDescription(e.target.value);

                              setDescriptionHelptext("");
                            } else setDescriptionHelptext("กรุณาระบุ");
                          }
                        }}
                      />
                      <FormHelperText
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {descriptionHelptext}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInputWithValidator
                        labelText="หน่วยงานที่ตรวจสอบข้อมูล"
                        formControlProps={{
                          fullWidth: true
                        }}
                        // validators={[onlyNumber, ThaiCitizenID]}
                        inputProps={{
                          value: orgName,
                          onChange: e => {
                            if (e.target.value) {
                              setOrgName(e.target.value);
                              setOrgNameHelptext("");
                            } else {
                              setOrgName(null);
                              setOrgNameHelptext("กรุณาระบุ");
                            }
                          }
                        }}
                      />
                      <FormHelperText
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {orgNameHelptext}
                      </FormHelperText>
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <GridItem xs={6} sm={6} md={6}></GridItem>
                    <GridItem>
                      {/* <Button onClick={onClear}>ยกเลิก</Button>  */}
                      <Button color="info" onClick={handelSubmit}>
                        บันทึกไฟล์
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          ""
        )}
      </GridContainer>
    </div>
  );
};
export default Qualification;
