import React  from "react";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
import { VoteDateContextProvider } from "contexts/VoteDate";

// core components
import CompleteOptData from "./CompleteOptData";


//const useStyles = makeStyles(styles);

const StatusIndex = props => {
      //const classes = useStyles();
      return (
            <VoteDateContextProvider >
                  <CompleteOptData />
            </VoteDateContextProvider>
      );
};
export default StatusIndex;
