import React, { useState } from "react";

import Stock from "./Stock";

import { VoteDateContextProvider } from "contexts/VoteDate";
import VotecardDetail from "./VotecardDetail";
const Index = props => {
  // Local State

  const [pageProps, setPageProps] = useState({ active: "Stock", props: null });
  const SwitchComponents = ({ active, children }) => {
    return children.filter(child => child.props.name === active);
  };

  return (
    <VoteDateContextProvider>
      <SwitchComponents active={pageProps.active}>
        <Stock name="Stock" pageProps={pageProps} setPageProps={setPageProps} />
        <VotecardDetail
          name="VotecardDetail"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
      </SwitchComponents>
    </VoteDateContextProvider>
  );
};

export default Index;
