import React, { useState } from "react";

import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import ConvertDate from "utils/ConvertDate";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FileUpload } from "components/Common/FileUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import Slide from "@material-ui/core/Slide";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
/* API */
import { updateVoteCardPurchaseDetailAssignFile } from "contexts/API/VoteCard/Purchase";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },
  modalBody: {
    fontSize: "15px"
  },
  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Modalattachment = props => {
  const { sweetAlerts } = useSweetAlert();
  const { showModalAttachment, closeModalAttachment, areaAttachement } = props;
  const classes = useStyles();

  //const [purchaseDetailID, setPurchaseDetailID] = useState(props.areaAttachement.VoteCardPurchaseDetailID);
  const [fileUUID, setFileUUID] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const onSubmit = () => {
    updateVoteCardPurchaseDetailAssignFile({
      voteCardPurchaseDetailID: props.areaAttachement.VoteCardPurchaseDetailID,
      assignFileUuid: fileUUID
    })
      .then(result => {
        sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");
        closeModalAttachment();
      })
      .catch(err => {
        HandelError(err);
        sweetAlerts.error("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
      });
  };

  return (
    <div className={showModalAttachment ? "modal" : "hide"}>
      <Dialog
        open={showModalAttachment}
        fullWidth={true}
        transition={Transition}
        keepMounted
        onClose={closeModalAttachment}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>
              แนบคำสั่งแต่งตั้งคณะกรรมการการรับบัตร
            </h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <br></br>
          <form className={classes.form}>
            <GridContainer>
              <GridItem xs={12} md={4} style={{ textAlign: "right" }}>
                <b>ชื่อ อปท. :</b>
              </GridItem>
              <GridItem xs={12} md={8}>
                {areaAttachement.OptName}
              </GridItem>
            </GridContainer>
            <br></br>
            <GridContainer>
              <GridItem xs={12} md={4} style={{ textAlign: "right" }}>
                <b> วันที่เลือกตั้ง :</b>
              </GridItem>
              <GridItem xs={12} md={8}>
                {ConvertDate(areaAttachement.VoteDate)}
              </GridItem>
            </GridContainer>
            <br></br>
            <GridContainer>
              <GridItem xs={12} md={4} style={{ textAlign: "right" }}>
                <b>จำนวนผู้มีสิทธิ : </b>
              </GridItem>
              <GridItem xs={12} md={8}>
                {areaAttachement.VotePerson}
              </GridItem>
            </GridContainer>
            <br></br>
            <GridContainer>
              <GridItem xs={12} md={4} style={{ textAlign: "right" }}>
                <b>แนบคำสั่งแต่งตั้ง :</b>
              </GridItem>
              <GridItem xs={12} md={8}>
                <FileUpload
                  id="file-upload-2"
                  label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                  remove
                  download
                  fileName={fileName}
                  url={fileUrl}
                  onFileRemove={() => {
                    setFileUrl("");
                    setFileName("");
                  }}
                  onFileHandler={(e, f, progressCb) => {
                    uploadFile("opt", f, progressCb)
                      .then(r => {
                        const data = r.data.data;

                        if (data.fileId) {
                          setFileUrl(getFileDownloadUrl("opt", data.fileId));

                          setFileUUID(data.fileId);
                          setFileName(data.fileName);
                        }

                        sweetAlerts.success("แนบไฟล์เรียบร้อย");
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  }}
                  fileType="application/pdf"
                  btnName={<>Browse</>}
                  btnProps={{
                    color: "primary"
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
          <br></br>
          <hr></hr>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={closeModalAttachment}>ยกเลิก</Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              onClick={onSubmit}
            >
              บันทึก
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default Modalattachment;
