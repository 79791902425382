import { AlertUtilsContext, useSweetAlert } from "components/Alert/SweetAlert";
import React, { useContext, useEffect, useState } from "react";
import {
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

import AtLeastOne from "utils/Validators/AtLeastOne";
import Button from "components/CustomButtons/Button.js";
import ConfirmLoginDialog from "components/Common/ConfirmLoginDialog";
import CustomInputWithValidator from "components/CustomInput/CustomInputWithValidator";
import { FileUpload } from "components/Common/FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// material-ui icons
import ImageIcon from "@material-ui/icons/Image";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import MustIncludes from "utils/Validators/MustIncludes";
//import AttachFileIcon from '@material-ui/icons/AttachFile';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import XLSX from "xlsx";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { onlyNumber } from "utils/Validators/Numbers";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSnackBar } from "components/Alert/Snackbar";
import SiteMap from "components/Sitemap/Sitemap";
import Popups from "components/Popups/Popups";

// this is used for the special classes
//import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
const useStyles = makeStyles();
export default function Test(props) {
  const classes = useStyles();
  const [urlFile, setUrlFile] = React.useState("");
  const [, setValue] = React.useState("");
  const [error, setError] = React.useState(true);
  const { sweetAlert } = useContext(AlertUtilsContext);
  const { sweetAlerts } = useSweetAlert();
  const { snackBars } = useSnackBar();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = newValue => {
    setOpen(false);
  };

  const onValueChange = e => {
    setValue(e.target.value);
    setError(
      e.target.value === null ||
      e.target.value === undefined ||
      e.target.value === ""
    );
  };
  const handelClick = () => {
    setLoading(!loading);
  };

  // const isError = () => {
  //     return value === null || value === undefined;
  // }

  const uploadSuccess = affectedRows => {
    console.log(affectedRows);
  };
  const data = [
    { c1: 1, c2: "a", c3: true },
    { c1: 2, c2: "b", c3: false },
    { c1: 3, c2: "c", c3: false },
    { c1: 4, c2: "d", c3: false },
    { c1: 1, c2: "a", c3: true },
    { c1: 1, c2: "a", c3: true },
    { c1: 1, c2: "a", c3: true },
    { c1: 2, c2: "b", c3: true },
    { c1: 3, c2: "c", c3: false },
    { c1: 4, c2: "d", c3: false },
    { c1: 1, c2: "a" },
    { c1: 2, c2: "b", c3: false },
    { c1: 3, c2: "c", c3: false },
    { c1: 4, c2: "d", c3: true },
    { c1: 1, c2: "a", c3: false },
    { c1: 2, c2: "b", c3: true },
    { c1: 3, c2: "c", c3: false },
    { c1: 4, c2: "d", c3: true },
    { c1: 1, c2: "a", c3: false },
    { c1: 2, c2: "b", c3: true },
    { c1: 3, c2: "c", c3: false },
    { c1: 4, c2: "d" }
  ];

  const columns = [
    { title: "c1", field: "c1" },
    { title: "c2", field: "c2" },
    {
      hidden: false,
      export: true,
      title: "c3",
      field: "c3",
      exportData: row => {
        return row.c3 ? "เรียบร้อย" : "ยังไม่เรียบร้อย";
      },
      render: row => {
        return row.c3 ? (
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: "green", fontSize: 20 }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ color: "red", fontSize: 20 }}
          />
        );
      }
    }
  ];

  const options = {
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "650px",
    actionsCellStyle: {
      backgroundColor: "transparent"
    },
    exportAllData: true
    /* 
      exportCsv: exportCsv, or (columns, data) => {exportCsv(columns, data, filename, delimeter)}
    */
  };

  const [openSiteMap, setOpenSiteMap] = useState(false)

  return (
    <div>
      <Popups></Popups>
      <div>
        <Button variant="outlined" color="primary" onClick={() => setOpenSiteMap(true)}>
          Sitemap
        </Button>
        <SiteMap open={openSiteMap} setOpen={setOpenSiteMap} />
        <ConfirmLoginDialog
          classes={{
            paper: classes.paper
          }}
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
        />
        <Button color="success" onClick={handleClickListItem}>
          เข้าสู่ระบบใหม่นะจ๊ะ
        </Button>
        {loading && <LoadingData />}

        <Button color="info" onClick={handelClick}>
          LOading
        </Button>
        <br></br>

        <Button
          color="primary"
          onClick={() => {
            sweetAlerts.primary("ประกาศครับ", "ข้อมูลสำหรับการประกาศ");
            //sweetAlert("primary", 'ประกาศ', 'ข้อมูลสำหรับการประกาศ');
          }}
        >
          primary
        </Button>
        <Button
          color="success"
          onClick={() => {
            sweetAlert(
              "success",
              "บันทึกข้อมูลสำเร็จ",
              "ได้ทำการบันทึกข้อมูลเรียบร้อยแล้ว"
            );
          }}
        >
          success
        </Button>
        <Button
          color="danger"
          onClick={() => {
            sweetAlert(
              "danger",
              "ยืนการลบข้อมูล",
              "คุณต้องการลบข้อมูลสำคัญใช่หรือไม่",
              () => {
                snackBars.success("เรียบร้อย", 5000);
              },
              true
            );
          }}
        >
          danger
        </Button>
        <Button
          color="warning"
          onClick={() => {
            sweetAlert("warning", "แจ้งเตือน", "แจ้งเตือนข้อมูล", null, true);
          }}
        >
          warning
        </Button>
        <Button
          color="info"
          onClick={() => {
            sweetAlert("info", "แจ้งเพื่อทราบ", "", null);
          }}
        >
          Info
        </Button>
      </div>
      <TextField
        onChange={onValueChange}
        //success={!error}
        error={error}
        id="standard-error"
        label="Error"
        defaultValue="CustomTextField"
        helperText="กรุณาระบุ*"
      />
      <ListboxProvince
        validators={[AtLeastOne, MustIncludes(["สระบุรี", "นครนายก"])]}
      />
      <div>
        <Button
          color="primary"
          onClick={() => {
            snackBars.primary("แจ้งเพื่อทราบ");
          }}
        >
          SnackBar
        </Button>
        <Button
          color="success"
          onClick={() => {
            snackBars.success("แจ้งเพื่อทราบ");
          }}
        >
          SnackBar
        </Button>
        <Button
          color="danger"
          onClick={() => {
            snackBars.error("แจ้งเพื่อทราบ");
          }}
        >
          SnackBar
        </Button>
        <Button
          color="warning"
          onClick={() => {
            snackBars.warning("แจ้งเพื่อทราบ");
          }}
        >
          SnackBar
        </Button>
        <Button
          color="info"
          onClick={() => {
            snackBars.info("แจ้งเพื่อทราบ");
          }}
        >
          SnackBar
        </Button>

        <Button
          color="primary"
          onClick={() => {
            snackBars.primary("แจ้งเพื่อทราบ 5 วิ", 5000);
          }}
        >
          SnackBar close in 5 s
        </Button>

        <Button
          color="danger"
          onClick={() => {
            snackBars.error(
              "SnackBar close in 3 s on buttom center(bc)",
              3000,
              "bc"
            );
          }}
        >
          SnackBar close in 3 s on buttom center(bc)
        </Button>
      </div>
      <div style={{ width: "50%" }}>
        <FileUpload
          id="file-upload-1"
          url={getFileDownloadUrl("wtd", "ff3bbe478ac55264cebd09284d87154e")}
          download
          remove
          fileName="ff3bbe478ac55264cebd09284d87154e"
          // fileName="test"
          onFileHandler={(e, f, p) => {
            uploadFile("wtd", f, p)
              .then(r => {
                const data = r.data.data;
                sweetAlerts.success(
                  "แนบไฟล์เรียบร้อย",
                  <div>
                    <p>fileName: {data.fileName}</p>
                    <br />
                    <p>fileId: {data.fileId}</p>
                    <br />
                    <p>fileType: {data.fileType}</p>
                    <br />
                    <p>size: {data.size}</p>
                  </div>
                );
              })
              .catch(err => {
                console.log(err);
              });
          }}
          fileType="image/*"
          btnName={
            <>
              <ImageIcon />
              แนบรูปภาพ
            </>
          }
          btnProps={{
            color: "info"
          }}
        />

        {/* <LinearProgress color="secondary" /> */}
      </div>
      <div style={{ width: "50%" }}>
        <FileUpload
          label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
          id="file-upload-2"
          remove
          download
          url={urlFile}
          onFileHandler={(e, f, progressCb) => {
            uploadFile("wtd", f, progressCb)
              .then(r => {
                const data = r.data.data;
                sweetAlerts.success(
                  "แนบไฟล์เรียบร้อย",
                  <div>
                    <p>fileName: {data.fileName}</p>
                    <br />
                    <p>fileId: {data.fileId}</p>
                    <br />
                    <p>fileType: {data.fileType}</p>
                    <br />
                    <p>size: {data.size}</p>
                  </div>
                );
                setUrlFile(getFileDownloadUrl("wtd", data.fileId));
              })
              .catch(err => {
                console.log(err);
              });
          }}
          fileType="application/pdf"
          btnName={<>Browse</>}
          btnProps={{
            color: "primary"
          }}
        />
      </div>

      <div style={{ width: "50%" }}>
        <FileUpload
          id="file-upload-3"
          fileName="default-file.png"
          remove
          onFileHandler={(e, f) => {
            sweetAlerts.success("แนบไฟล์เรียบร้อย");
            let data = new Uint8Array(e);
            let workbook = XLSX.read(data, { type: "array" });
            console.log("sheet_name: ", workbook.SheetNames[0]);
            let sheet = workbook.Sheets[workbook.SheetNames[0]];

            console.log("data", XLSX.utils.sheet_to_json(sheet));
          }}
          fileType="*"
          btnName={
            <>
              <PictureAsPdfIcon />
              .pdf
            </>
          }
          btnProps={{
            color: "success"
          }}
        />
      </div>

      <MaterialTable
        title="override action"
        data={data}
        columns={columns}
        options={options}
        actions={[
          {
            icon: "edit",
            tooltip: "Save User",
            onClick: (event, rowData) => alert("You saved " + rowData.c1)
          },
          {
            icon: "del",
            tooltip: "Save User",
            onClick: (event, rowData) => alert("You saved " + rowData.c2)
          }
        ]}
        components={{
          Action: props => {
            if (props.action.icon === "edit") {
              return (
                <Button
                  onClick={event => props.action.onClick(event, props.data)}
                  color="warning"
                  variant="contained"
                  style={{ textTransform: "none" }}
                  size="sm"
                >
                  แก้ไข
                </Button>
              );
            }
            if (props.action.icon === "del") {
              return (
                <Button
                  onClick={event => props.action.onClick(event, props.data)}
                  color="danger"
                  variant="contained"
                  style={{ textTransform: "none" }}
                  size="sm"
                >
                  ลบ
                </Button>
              );
            }
          }
        }}
      // editable={{
      //   onRowAdd: newData =>
      //     new Promise((resolve, reject) => {
      //       setTimeout(() => {
      //         resolve();
      //       }, 1000);
      //     }),
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise((resolve, reject) => {
      //       setTimeout(() => {
      //         resolve();
      //       }, 1000);
      //     }),
      //   onRowDelete: oldData =>
      //     new Promise((resolve, reject) => {
      //       setTimeout(() => {
      //         resolve();
      //       }, 1000);
      //     })
      // }}
      />

      <MaterialTable
        title="default action"
        data={data}
        columns={columns}
        options={options}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve();
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve();
              }, 1000);
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve();
              }, 1000);
            })
        }}
      />
      <CustomInputWithValidator
        validators={[onlyNumber, ThaiCitizenID]}
        inputProps={{}}
      />
    </div>
  );
}
