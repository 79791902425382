import React, { useEffect, useState } from "react";

import Button from "components/CustomButtons/Button.js";
//import Card from "components/Card/Card.js";
import ConvertDate from "utils/ConvertDate";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import LoadingData from "components/Common/LoadingData";
import ModalEditDetail from "./ModalEditDetail";
import Modalallocate from "./Modalallocate";
//import Modalattachment from "./Modalattachment";
import NumberFormat from "react-number-format";
import TableComponent from "components/Table/MaterialTable";
/* API */
import { getSearchDetailLists } from "contexts/API/VoteCard/Allocate";
import { useSweetAlert } from "components/Alert/SweetAlert";

const EditAllocate = ({ pageProps, setPageProps }) => {
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showChild, setShowChild] = useState();

  const [data, setData] = useState([]);
  const { sweetAlerts } = useSweetAlert();
  const optionsTable = {
    exportButton: false,
    paging: false,
    //pageSize: 5,
    //pageSizeOptions: [5, 10, 20]
  };

  const [showModaladdCardallocate, setshowModaladdCardallocate] = useState(
    false
  );
  const openModaladdCardallocate = () => setshowModaladdCardallocate(true);
  const closeModaladdCardallocate = () => setshowModaladdCardallocate(false);

  //---attachement
  //const [showModalAttachment, setshowModalAttachment] = useState(false);
  //const openModalAttachment = () => setshowModalAttachment(true);
  //const closeModalAttachment = () => setshowModalAttachment(false);

  const [showModalEditDetail, setShowModalEditDetail] = useState(false);
  const openModalEditDetail = () => setShowModalEditDetail(true);
  const closeModalEditDetail = () => setShowModalEditDetail(false);
  const [purchaseID, setPurchaseID] = useState(null);
  //const [areaAttachement,] = useState([]);
  const [areaAllocate, setAreaAllocate] = useState([]);

  function getDetailVoteCard(rowData) {
    return rowData.map((post, index) => (
      <div key={index}>
        <p>
          {post.cardNumber} สี{post.colorName} เล่มที่ {post.allocateStartNo}{" "}
          ถึง {post.allocateEndNo} จำนวน {post.volumnAllocate} เล่ม
        </p>
      </div>
    ));
  }

  useEffect(() => {
    if (pageProps.props.PurchaseID) {
      setPurchaseID(pageProps.props.PurchaseID);
    }
  }, [pageProps.props.PurchaseID]);

  useEffect(() => {
    if (purchaseID) {
      const loadData = async () => {
        try {
          setLoading(true);
          getSearchDetailLists("?VotePurchaseID=" + purchaseID, "")
            .then(result => {
              const columns = [
                {
                  title: "อำเภอ",
                  field: "AmphurName",
                  editable: "never",
                  cellStyle: {
                    minWidth: "150px",
                    textAlign: "center"
                  },
                  headerStyle: {
                    textAlign: "center"
                  }
                },
                /*  {
                   title: "ประเภท อปท.",
                   field: "OptTypeName",
                   editable: "never",
                   cellStyle: {
                     textAlign: "center"
                   },
                   headerStyle: {
                     textAlign: "center"
                   }
                 }, */
                {
                  title: "ชื่อ อปท.",
                  field: "OptName",
                  editable: "never",
                  cellStyle: {
                    minWidth: "150px",
                    textAlign: "center"
                  },
                  headerStyle: {
                    textAlign: "center"
                  },
                  render: rowData => {
                    return rowData.OptTypeName + rowData.OptName;
                  }
                },
                {
                  title: "วันที่เลือกตั้ง",
                  field: "VoteDate",
                  editable: "never",
                  cellStyle: {
                    minWidth: "150px",
                    textAlign: "center"
                  },
                  headerStyle: {
                    textAlign: "center"
                  },
                  render: rowData => {
                    return ConvertDate(rowData.VoteDate);
                  }
                },
                {
                  title: "ตำแหน่ง",
                  field: "PositionTypeName",
                  editable: "never",
                  cellStyle: {
                    minWidth: "50px",
                    textAlign: "center"
                  },
                  headerStyle: {
                    textAlign: "center"
                  }
                },
                {
                  title: "เขต",
                  field: "AreaNo",
                  editable: "never",
                  cellStyle: {
                    minWidth: "50px",
                    textAlign: "center"
                  },
                  headerStyle: {
                    textAlign: "center"
                  }
                },
                {
                  title: "จำนวนผู้สมัครสูงสุด",
                  field: "VoteRegister",
                  editable: "never",
                  cellStyle: {
                    minWidth: "50px",
                    textAlign: "center"
                  },
                  headerStyle: {
                    textAlign: "center"
                  },
                  render: rowData => {
                    return (
                      <NumberFormat
                        thousandSeparator={true}
                        // customInput={TextField}
                        disabled={true}
                        style={{
                          borderStyle: "none none none none",
                          color: "black",
                          textAlign: "center",
                          background: "transparent"
                        }}
                        // thousandsGroupStyle="thousand"
                        value={rowData.VoteRegister}
                      />
                    );
                  }
                },
                {
                  title: "จำนวนผู้มีสิทธิ",
                  field: "VotePerson",
                  editable: "never",
                  cellStyle: {
                    minWidth: "70px",
                    textAlign: "center"
                  },
                  headerStyle: {
                    textAlign: "center"
                  },
                  render: rowData => {
                    return (
                      <NumberFormat
                        thousandSeparator={true}
                        // customInput={TextField}
                        disabled={true}
                        style={{
                          borderStyle: "none none none none",
                          color: "black",
                          textAlign: "center",
                          background: "transparent"
                        }}
                        // thousandsGroupStyle="thousand"
                        value={rowData.VotePerson}
                      />
                    );
                  }
                },
                {
                  title: "จำนวนบัตรเลือกตั้ง (เล่ม)",
                  field: "VoteCard",
                  editable: "never",
                  cellStyle: {
                    minWidth: "100px",
                    textAlign: "center"
                  },
                  headerStyle: {
                    textAlign: "center"
                  },
                  render: rowData => {
                    return (
                      <NumberFormat
                        thousandSeparator={true}
                        // customInput={TextField}
                        disabled={true}
                        style={{
                          borderStyle: "none none none none",
                          color: "black",
                          textAlign: "center",
                          width: "100px",
                          background: "transparent"
                        }}
                        // thousandsGroupStyle="thousand"
                        value={rowData.VoteCard}
                      />
                    );
                  }
                },
                {
                  title: "โรงพิมพ์",
                  field: "printingHouseName",
                  editable: "never",
                  cellStyle: {
                    minWidth: "120px",
                    textAlign: "center"
                  },
                  headerStyle: {
                    textAlign: "center"
                  }
                },
                {
                  title: "รายละเอียดการจัดสรรบัตรเลือกตั้ง",
                  field: "",
                  editable: "never",
                  cellStyle: {
                    textAlign: "center",
                    minWidth: "300px"
                  },
                  headerStyle: {
                    textAlign: "center"
                  },
                  render: rowData => {
                    return getDetailVoteCard(rowData.voteCardAllocate);
                  }
                }
              ];

              setColumns(columns);

              setData(result.data.data);
            })
            .catch(err => {
              sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", err.toString());
            });

          setLoading(false);
        } catch (error) {
          HandelError(error);
          setLoading(false);
        }
      };
      loadData();
    }
  }, [purchaseID, showModaladdCardallocate]);

  const backtoMainHandler = rowData => {
    setPageProps({ active: "CardAllocate", action: "cancel", props: rowData });
  };
  const addSuccess = id => {
    const loadData = async () => {
      try {
        setLoading(true);
        getSearchDetailLists("?VotePurchaseID=" + id, "")
          .then(result => {
            const columns = [
              {
                title: "อำเภอ",
                field: "AmphurName",
                editable: "never",
                cellStyle: {
                  minWidth: "150px",
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                }
              },
              {
                field: "ProvinceName",
                title: "ProvinceName",
                hidden: true
              },
              {
                field: "OptTypeId",
                title: "OptTypeId",
                hidden: true
              },
              /*  {
                 title: "ประเภท อปท.",
                 field: "OptTypeName",
                 editable: "never",
                 cellStyle: {
                   textAlign: "center"
                 },
                 headerStyle: {
                   textAlign: "center"
                 }
               }, */
              {
                title: "ชื่อ อปท.",
                field: "OptName",
                editable: "never",
                cellStyle: {
                  minWidth: "150px",
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                },
                render: rowData => {
                  return rowData.OptTypeName + rowData.OptName;
                }
              },
              {
                title: "วันที่เลือกตั้ง",
                field: "VoteDate",
                editable: "never",
                cellStyle: {
                  minWidth: "150px",
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                },
                render: rowData => {
                  return ConvertDate(rowData.VoteDate);
                }
              },
              {
                title: "ตำแหน่ง",
                field: "PositionTypeName",
                editable: "never",
                cellStyle: {
                  minWidth: "50px",
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                }
              },
              {
                title: "เขต",
                field: "AreaNo",
                editable: "never",
                cellStyle: {
                  minWidth: "50px",
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                }
              },
              {
                title: "จำนวนผู้สมัครสูงสุด",
                field: "VoteRegister",
                editable: "never",
                cellStyle: {
                  minWidth: "50px",
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                },
                render: rowData => {
                  return new Intl.NumberFormat().format(rowData.VoteRegister);
                }
              },
              {
                title: "จำนวนผู้มีสิทธิ",
                field: "VotePerson",
                editable: "never",
                cellStyle: {
                  minWidth: "70px",
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                },
                render: rowData => {
                  return new Intl.NumberFormat().format(rowData.VotePerson);
                }
              },
              {
                title: "จำนวนบัตรเลือกตั้ง (เล่ม)",
                field: "VoteCard",
                editable: "never",
                cellStyle: {
                  minWidth: "100px",
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                },
                render: rowData => {
                  return new Intl.NumberFormat().format(rowData.VoteCard);
                }
              },
              {
                title: "โรงพิมพ์",
                field: "printingHouseName",
                editable: "never",
                cellStyle: {
                  minWidth: "120px",
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                }
              },
              {
                title: "รายละเอียดการจัดสรรบัตรเลือกตั้ง",
                field: "",
                editable: "never",
                cellStyle: {
                  textAlign: "center",
                  minWidth: "300px"
                },
                headerStyle: {
                  textAlign: "center"
                },
                render: rowData => {
                  return getDetailVoteCard(rowData.voteCardAllocate);
                }
              }
            ];

            setColumns(columns);

            setData(result.data.data);
          })
          .catch(err => {
            sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", err.toString());
          });

        setLoading(false);
      } catch (error) {
        HandelError(error);
        setLoading(false);
      }
    };
    loadData();
  };

  return (

    <div>
      <Modalallocate
        closeModaladdCardallocate={closeModaladdCardallocate}
        showModaladdCardallocate={showModaladdCardallocate}
        areaAllocate={areaAllocate}
        onaddSuccess={addSuccess}
      ></Modalallocate>
      {/*  <Modalattachment
        closeModalAttachment={closeModalAttachment}
        showModalAttachment={showModalAttachment}
        areaAttachement={areaAttachement}
      ></Modalattachment> */}
      <ModalEditDetail
        closeModalEditDetail={closeModalEditDetail}
        showModalEditDetail={showModalEditDetail}
        showChild={showChild}
        onaddSuccess={addSuccess}
      ></ModalEditDetail>
      {loading ? (
        <LoadingData />
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>

            <TableComponent
              title="รายการจัดสรรบัตรเลือกตั้ง"
              columns={columns}
              data={data}
              localization={{}}
              options={optionsTable}
              actions={[
                rowData => ({
                  icon: () => (

                    <Button
                      variant="contained"
                      color="info"
                      size="sm"
                      disabled={rowData.VoteCardPurchaseStatusID > 3}
                      onClick={() => {
                        openModaladdCardallocate();
                        setAreaAllocate(rowData);
                      }}
                    >
                      จัดสรรบัตร
                    </Button>
                  ),
                  tooltip: "จัดสรรบัตร"
                }),
                rowData => ({
                  icon: () => (
                    <Button
                      variant="contained"
                      color="warning"
                      size="sm"
                      disabled={rowData.VoteCardPurchaseStatusID !== 4}
                      onClick={() => {
                        openModalEditDetail();
                        setShowChild(rowData);
                      }}
                    >
                      แก้ไข
                    </Button>
                  ),
                  tooltip: "แก้ไข",
                })
              ]}
            />
          </GridItem>
        </GridContainer>
      )}
      <br></br>
      <GridContainer
        justify="space-between"
        direction="column"
        alignItems="flex-end"
      >
        <GridItem>
          <Button onClick={backtoMainHandler}>กลับสู่หน้าหลัก</Button>
        </GridItem>
      </GridContainer>

    </div>

  );
};
export default EditAllocate;
