import React, { useContext, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectVoteDate from "components/Common/ListboxInspectVoteDate";
import ListboxInspectVoteDateInquire from "components/Common/ListboxInspectVoteDateInquire";
import ListboxPositionInspect from "components/Common/ListboxPositionInspect";
import NumberFormat from "react-number-format";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";
//Surachai.k 2023-07-07 Add ListboxProvince
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxInspectApplyDate from "components/Common/ListboxInspectApplyDate";
// core components

// @material-ui/icons

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalReport = props => {
  const { 
	 votedateSelected
	,onVotedateSelected
	,provinceSelected
    	,onProvinceSelected
    	,onInspectApplyDateSelected
    	,inspectApplyDateSelected
	 } = useContext(InspectorContext);
  const [ isIdCardValid, setIsIdCardValid] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  const { showModalReport, closeModalReport } = props;
  const classes = useStyles();

  const [, setHelpertextidCard] = useState(true);
  const [fullname, setFullname] = useState(null);

  const [idCard, setIdCard] = useState(null);

  const [inspectorPositionTypeId, setInspectorPositionTypeId] = useState(null);
  const [voteDate, setVoteDate] = useState(null);
  const onIDCardHandler = v => {
    setIdCard(v.value);
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      if (isError) {
        sweetAlerts.warning("เลขประจำตัวประชาชนผิด");
        if (isIdCardValid === true) {
          setIsIdCardValid(false);
        }
        setIdCard(null);
      } else {
        if (isIdCardValid === false) {
          setIsIdCardValid(true);
          setHelpertextidCard("");
        }
      }
    } else {
      if (isIdCardValid === true) {
        setIsIdCardValid(false);
        setHelpertextidCard("กรุณาระบุ*");
      }
    }
  };

  const getQuery = () => {
    let endPoint = "";

    if (["1"].includes(props.indexReport)) {
      endPoint += `&citizenid=${idCard !== null ? idCard : 0}&name=${
        fullname !== null ? fullname : "0"
      }&voteDateId=${votedateSelected !== null ? votedateSelected.id : -1}`;
    }

    if (["2", "3"].includes(props.indexReport)) {
      endPoint += `&voteDateId=${voteDate !== null ? voteDate.id : -1}`;
    }

    //Surachai.k 2023-07-07 Add Parameter for report
    if (["4", "5"].includes(props.indexReport)) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0}&apply_date_id=${inspectApplyDateSelected !== null ? inspectApplyDateSelected.id : 0}`;
    }

    return endPoint;
  };
  const handleSubmitReport1 = e => {
    e.preventDefault();

    const query = getQuery();
    //  console.log(query);
    window.open(`${props.linkReport}${query}`, "_blank");
  };

  return (
    <div className={showModalReport ? "modal" : "hide"}>
      <Dialog
        open={showModalReport}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModalReport}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>{props.titleReport}</h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {" "}
          {["1"].includes(props.indexReport) ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <NumberFormat
                    style={{
                      width: "100%",
                      margin: "0 0 17px 0",
                      paddingTop: "27px"
                    }}
                    value={idCard}
                    placeholder="เลขประจำตัวประชาชน"
                    customInput={TextField}
                    format="# #### ##### ## #"
                    mask="X"
                    fullWidth
                    onValueChange={onIDCardHandler}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    default
                    required
                    labelText="ชื่อ สกุล"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpertext="กรุณาระบุ*"
                    inputProps={{
                      autoComplete: "new-password",
                      defaultValue: fullname,
                      onChange: e => {
                        setFullname(e.target.value);
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxInspectVoteDateInquire
                    value={votedateSelected}
                    onChange={onVotedateSelected}
                  />
                </GridItem>
              </GridContainer>
            </>
          ) : (
            ""
          )}
          {["2", "3"].includes(props.indexReport) ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <ListboxPositionInspect
                    value={inspectorPositionTypeId}
                    onChange={(i, e, v) => {
                      if (e !== null) {
                        console.log(e);
                        setInspectorPositionTypeId(e);
                      } else {
                        setInspectorPositionTypeId(null);
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  {inspectorPositionTypeId === null ? (
                    <CustomInput
                      default
                      required
                      labelText="วันเลือกตั้งในระบบ"
                      formControlProps={{
                        fullWidth: true,
                        disabled: true
                      }}
                      inputProps={{
                        autoComplete: "new-password"
                      }}
                    />
                  ) : (
                    <ListboxInspectVoteDate
                      value={voteDate}
                      positionTypeId={
                        inspectorPositionTypeId
                          ? inspectorPositionTypeId.id
                          : null
                      }
                      //   areaEctSelected={areaEctSelected}
                      onChange={(i, e, v) => {
                        if (e !== null) {
                          setVoteDate(e);
                        } else {
                          setVoteDate(null);
                        }
                      }}
                    ></ListboxInspectVoteDate>
                  )}
                </GridItem>
              </GridContainer>
            </>
          ) : (
            ""
          )}

        {["4", "5"].includes(props.indexReport) ? (
        <>
        <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <ListboxProvince
            required={false}
            value={provinceSelected}
            // defaultValue={provinceSelected || { id: 0 }}
            onChange={onProvinceSelected}
          ></ListboxProvince>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <ListboxInspectApplyDate
            value={inspectApplyDateSelected}
            required={false}
            onChange={onInspectApplyDateSelected}
          ></ListboxInspectApplyDate>
        </GridItem>
        </GridContainer>
        </>
        ):("")
        }


          <br></br>
          <GridContainer
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem xs={12} sm={12} md={6}>
              <Button onClick={closeModalReport}>ปิดหน้าต่าง</Button>
              <Button color="success" onClick={handleSubmitReport1}>
                ออกรายงาน
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalReport;
