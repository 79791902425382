import React, { useEffect, useState } from "react";
import {
  addRoles,
  delRoles,
  editRoles,
  getRoles
} from "contexts/API/UserManagement/roles";

import MaterialTable from "components/Table/MaterialTable";
import { useSweetAlert } from "components/Alert/SweetAlert";

const RolesList = props => {
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);

  useEffect(() => {
    getRoles()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "roleId",
      field: "roleId",
      hidden: true
    },
    {
      title: "ชื่อกลุ่มผู้ใช้งาน",
      field: "roleName",
      cellStyle: {
        minWidth: "600px"
      }
    }
  ];

  const optionsTable = { pageSize: 10 };
  return (
    <MaterialTable
      title={"กลุ่มผู้ใช้งาน"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            addRoles(newData.roleName)
              .then(result => {
                //console.log("result>>",result);
                newData.roleId = result.data.data.roleId;
                setData([...data, newData]);
                sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
              });

            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;

            editRoles(oldData.roleId, newData.roleName)
              .then(result => {
                sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                setData([...dataUpdate]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
              });
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);

            delRoles(oldData.roleId)
              .then(result => {
                sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                setData([...dataDelete]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
              });
            resolve();
          })
      }}
    />
  );
};
export default RolesList;
