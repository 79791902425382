import React, { createContext, useState } from "react";

export const VotecardContext = createContext();

export const VotecardContextProvider = ({ children }) => {
  const [provinceSelected, setProvinceSelected] = useState(null);
  const [optVoteTypeSelected, setOptVoteTypeSelected] = useState(null);
  const [titleSelected, setTitleSelected] = useState(null);
  const [amphurSelected, setAmphurSelected] = useState(null);
  const [tambolSelected, setTambolSelected] = useState(null);
  const [colorSelected, setColorSelected] = useState(null);
  const [sizeSelected, setSizeSelected] = useState(null);
  const [yearSelected, setYearSelected] = useState(null);
  const [numberSelected, setNumberSelected] = useState(null);
  const [positionTypeSelected, setPositionTypeSelected] = useState(null);

  const [votecardNumberSelected, setVotecardNumberSelected] = useState(null);
  const [votecardSizeSelected, setVotecardSizeSelected] = useState(null);
  const [votecardColorSelected, setVotecardColorSelected] = useState(null);
  const [votecardPrintingSelected, setVotecardPrintingSelected] = useState(
    null
  );

  const [voteCardColorAllSelected, setVoteCardColorAllSelected] = useState(
    null
  );

  const defaultOptTypeSelected = {
    1: { checked: false, value: 1 },
    2: { checked: false, value: 2 },
    3: { checked: false, value: 3 },
    4: { checked: false, value: 4 },
    5: { checked: false, value: 5 },
    6: { checked: false, value: 6 },
    7: { checked: false, value: 7 },
    8: { checked: false, value: 8 },
    9: { checked: false, value: 9 },
    10: { checked: false, value: 10 }
  };

  const defaultOptForVotecardSelected = {
    1: { checked: false, value: 1 },
    2: { checked: false, value: 2 },
    3: { checked: false, value: 3 },
    4: { checked: false, value: 4 },
    5: { checked: false, value: 5 }
  };
  const [
    votecardCheckBoxOptSelected,
    setVotecardCheckBoxOptSelected
  ] = useState(defaultOptForVotecardSelected);

  const [optTypeSelected, setOptTypeSelected] = useState(
    defaultOptTypeSelected
  );

  const value = {
    votecardPrintingSelected: votecardPrintingSelected,
    setVotecardPrintingSelected: setVotecardPrintingSelected,
    onVotecardPrintingSelected: (evt, newValue) => {
      setVotecardPrintingSelected(newValue);
      setVotecardSizeSelected(null);
      setVotecardColorSelected(null);
      setVotecardNumberSelected(null);
    },

    votecardSizeSelected: votecardSizeSelected,
    setVotecardSizeSelected: setVotecardSizeSelected,
    onVotecardSizeSelected: (evt, newValue) => {
      setVotecardSizeSelected(newValue);
      setVotecardColorSelected(null);
      setVotecardNumberSelected(null);
    },
    votecardColorSelected: votecardColorSelected,
    setVotecardColorSelected: setVotecardColorSelected,
    onVotecardColorSelected: (evt, newValue) => {
      setVotecardColorSelected(newValue);
      setVotecardNumberSelected(null);
    },

    votecardNumberSelected: votecardNumberSelected,
    setVotecardNumberSelected: setVotecardNumberSelected,
    onVotecardNumberSelected: (evt, newValue) => {
      setVotecardNumberSelected(newValue);
    },

    voteCardColorAllSelected: voteCardColorAllSelected,
    setVoteCardColorAllSelected: setVoteCardColorAllSelected,
    onVoteCardColorAllSelected: (evt, newValue) => {
      setVoteCardColorAllSelected(newValue);
    },

    //---อปท---
    votecardCheckBoxOptSelected: votecardCheckBoxOptSelected,
    setVotecardCheckBoxOptSelected: setVotecardCheckBoxOptSelected,

    onVotecardCheckBoxOptSelected: (evt, newChecked) => {
      if (
        votecardCheckBoxOptSelected[`${evt.target.name}`] === undefined ||
        newChecked !== votecardCheckBoxOptSelected[`${evt.target.name}`].checked
      ) {
        setVotecardCheckBoxOptSelected({
          ...votecardCheckBoxOptSelected,
          [`${evt.target.name}`]: {
            checked: evt.target.checked,
            value: evt.target.value
          }
        });
      }
    },
    getVotecardCheckBoxOptList: () => {
      let optforVotecardLists = [];
      for (const [, v] of Object.entries(votecardCheckBoxOptSelected)) {
        if (v.checked) {
          optforVotecardLists.push(v.value);
        }
      }
      return optforVotecardLists;
    },

    optTypeSelected: optTypeSelected,
    setOptTypeSelected: setOptTypeSelected,
    onOptTypeSingleSelected: (evt, newChecked) => {
      if (
        optTypeSelected[`${evt.target.name}`] === undefined ||
        newChecked !== optTypeSelected[`${evt.target.name}`].checked
      ) {
        setOptTypeSelected({
          ...defaultOptTypeSelected,
          [`${evt.target.name}`]: {
            checked: evt.target.checked,
            value: evt.target.value
          }
        });
        //  setOptSelected(null);
      }
    },
    onOptTypeSelected: (evt, newChecked) => {
      if (
        optTypeSelected[`${evt.target.name}`] === undefined ||
        newChecked !== optTypeSelected[`${evt.target.name}`].checked
      ) {
        setOptTypeSelected({
          ...optTypeSelected,
          [`${evt.target.name}`]: {
            checked: evt.target.checked,
            value: evt.target.value
          }
        });
        // setOptSelected(null);
      }
    },

    provinceSelected: provinceSelected,
    setProvinceSelected: setProvinceSelected,
    onProvinceSelected: (evt, newValue) => {
      setProvinceSelected(newValue);
    },
    amphurSelected: amphurSelected,
    setAmphurSelected: setAmphurSelected,
    onAmphurSelected: (evt, newValue) => {
      setAmphurSelected(newValue);
      setTambolSelected(null);
    },
    tambolSelected: tambolSelected,
    setTambolSelected: setTambolSelected,
    onTambolSelected: (evt, newValue) => {
      setTambolSelected(newValue);
    },
    yearSelected: yearSelected,
    setYearSelected: setYearSelected,
    onYearSelected: (evt, newValue) => {
      setYearSelected(newValue);
    },
    titleSelected: titleSelected,
    setTitleSelected: setTitleSelected,
    onTitleSelected: (evt, newValue) => {
      setTitleSelected(null);
    },

    positionTypeSelected: positionTypeSelected,
    setPositionTypeSelected: setPositionTypeSelected,
    onPositionTypeSelected: (evt, newValue) => {
      setPositionTypeSelected(newValue);
    },

    optVoteTypeSelected: optVoteTypeSelected, //เลือกประเภทการเลือกตั้ง
    setOptVoteTypeSelected: setOptVoteTypeSelected,
    onOptVoteTypeSelected: (evt, newValue) => {
      setOptVoteTypeSelected(newValue);
    },
    colorSelected: colorSelected, //เลือกประเภทการเลือกตั้ง
    setColorSelected: setColorSelected,
    onColorSelected: (evt, newValue) => {
      setColorSelected(newValue);
    },
    sizeSelected: sizeSelected, //เลือกประเภทการเลือกตั้ง
    setSizeSelected: setSizeSelected,
    onSizeSelected: (evt, newValue) => {
      setSizeSelected(newValue);
    },
    numberSelected: numberSelected, //เลือกประเภทการเลือกตั้ง
    setNumberSelected: setNumberSelected,
    onNumberSelected: (evt, newValue) => {
      setNumberSelected(newValue);
    },
    getOptTypeSelectedLists: () => {
      let optIdLists = [];
      for (const [, v] of Object.entries(optTypeSelected)) {
        if (v.checked) {
          optIdLists.push(v.value);
        }
      }
      return optIdLists;
    }
  };
  return (
    <VotecardContext.Provider value={value}>
      {children}
    </VotecardContext.Provider>
  );
};
