import React, { createContext, useContext, useState } from "react";

import { UserInfoContext } from "contexts/users";

export const InspectorContext = createContext();

export const InspectorContextProvider = ({ children }) => {
  const { userInfo } = useContext(UserInfoContext);
  let userProvinceId = null;
  // let userProvinceId = {
  //   id: 0,
  //   value: ""
  // };
  let isCenter = userInfo.iscenter;
  if (!isCenter) {
    userProvinceId = {
      id: userInfo.provinceId,
      value: userInfo.provinceName
    };
  }

  const [areaEctSelected, setAreaEctSelected] = useState(null);
  const [provinceGroupSelected, setProvinceGroupSelected] = useState(null);
  const [provinceSelected, setProvinceSelected] = useState(userProvinceId);
  // const [provinceSelected, setProvinceSelected] = useState(
  //   userProvinceId ? userProvinceId : { id: 0 }
  // );
  const [amphurSelected, setAmphurSelected] = useState(null);
  const [tambolSelected, setTambolSelected] = useState(null);
  const [positionStatusSelected, setPostionStatusSelected] = useState(null); //สาเหตุการจัดการเลือกตั้ง
  const [votedateSelected, setVotedateSelected] = useState(null);
  const [optVoteTypeSelected, setOptVoteTypeSelected] = useState(null);
  const [listboxOptSelected, setListboxOptSelected] = useState(null);
  const [aumphurAreaSelected, setAumphurAreaSelected] = useState(null); //เขตอำเภอ
  const [areaSelected, setAreaSelected] = useState(null);
  const [positionTypeSelected, setPostionTypeSelected] = useState(null); //สถ ผถ
  const [votecardNumberSelected, setVotecardNumberSelected] = useState(null);
  const [votecardSizeSelected, setVotecardSizeSelected] = useState(null);
  const [votecardColorSelected, setVotecardColorSelected] = useState(null);
  const [inspectVotedateSelected, setInspectVotedateSelected] = useState(null);
  const [
    inspectProvinceGroupSelected,
    setInspectProvinceGroupSelected
  ] = useState(null);
  const [inspectSelect8Selected, setInspectSelect8Selected] = useState(null);

  const [bachelorMajorSelected, setBachelorMajorSelected] = useState(null);
  const [masterMajorSelected, setMasterMajorSelected] = useState(null);
  const [doctorMajorSelected, setDoctorMajorSelected] = useState(null);

  const [
    inspectCadidateDateSelected,
    setInspectCadidateDateSelected
  ] = useState(null);

  const [inspectApplyDateSelected, setInspectApplyDateSelected] = useState(
    null
  );

  const [
    inspectApplyDateSelectedSingle,
    setInspectApplyDateSelectedSingle
  ] = useState(null);
  const [votecardPrintingSelected, setVotecardPrintingSelected] = useState(
    null
  );
  const [titleSelected, setTitleSelected] = useState(null);
  const [universitySelected, setUniversitySelected] = useState(null);
  const [optTypeSelected, setOptTypeSelected] = useState({
    1: { checked: false, value: 1 },
    2: { checked: false, value: 2 },
    3: { checked: false, value: 3 },
    4: { checked: false, value: 4 },
    5: { checked: false, value: 5 },
    6: { checked: false, value: 6 },
    7: { checked: false, value: 7 },
    8: { checked: false, value: 8 },
    9: { checked: false, value: 9 },
    10: { checked: false, value: 10 }
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [voteStatusSelected, setVoteStatusSelected] = useState(null);
  const [optSelected, setOptSelected] = useState(null);

  const value = {
    universitySelected: universitySelected,
    setUniversitySelected: setUniversitySelected,
    onUniversitySelected: (evt, newValue) => {
      setUniversitySelected(null);
    },

    titleSelected: titleSelected,
    setTitleSelected: setTitleSelected,
    onTitleSelected: (evt, newValue) => {
      setTitleSelected(null);
    },

    bachelorMajorSelected: bachelorMajorSelected,
    setBachelorMajorSelected: setBachelorMajorSelected,
    onBachelorMajorSelected: (evt, newValue) => {
      setBachelorMajorSelected(null);
    },
    masterMajorSelected: masterMajorSelected,
    setMasterMajorSelected: setMasterMajorSelected,
    onMasterMajorSelected: (evt, newValue) => {
      setMasterMajorSelected(null);
    },

    doctorMajorSelected: doctorMajorSelected,
    setDoctorMajorSelected: setDoctorMajorSelected,
    onDoctorMajorSelected: (evt, newValue) => {
      setDoctorMajorSelected(null);
    },

    provinceGroupSelected: provinceGroupSelected,
    setProvinceGroupSelected: setProvinceGroupSelected,
    onProvinceGroupSelected: (evt, newValue) => {
      setProvinceGroupSelected(newValue);
    },
    provinceSelected: provinceSelected,
    setProvinceSelected: setProvinceSelected,
    onProvinceSelected: (evt, newValue) => {
      setProvinceSelected(newValue || { id: 0 });
      setAmphurSelected(null);
      setTambolSelected(null);
      setOptSelected(null);
    },
    amphurSelected: amphurSelected,
    setAmphurSelected: setAmphurSelected,
    onAmphurSelected: (evt, newValue) => {
      setAmphurSelected(newValue || { id: 0 });
      setTambolSelected(null);
      setOptSelected(null);
    },
    tambolSelected: tambolSelected,
    setTambolSelected: setTambolSelected,
    onTambolSelected: (evt, newValue) => {
      setTambolSelected(newValue || { id: 0 });
      setOptSelected(null);
    },
    optVoteTypeSelected: optVoteTypeSelected, //เลือกประเภทการเลือกตั้ง
    setOptVoteTypeSelected: setOptVoteTypeSelected,
    onOptVoteTypeSelected: (evt, newValue) => {
      setOptVoteTypeSelected(newValue);
    },
    optTypeSelected: optTypeSelected,
    setOptTypeSelected: setOptTypeSelected,
    onOptTypeSelected: (evt, newChecked) => {
      if (
        optTypeSelected[`${evt.target.name}`] === undefined ||
        newChecked !== optTypeSelected[`${evt.target.name}`].checked
      ) {
        setOptTypeSelected({
          ...optTypeSelected,
          [`${evt.target.name}`]: {
            checked: evt.target.checked,
            value: evt.target.value
          }
        });
        setOptSelected(null);
      }
    },
    optSelected: optSelected,
    setOptSelected: setOptSelected,
    onOptSelected: (evt, newValue) => {
      setOptSelected(newValue);
      setVotedateSelected(null);
    },

    listboxOptSelected: listboxOptSelected, //เลือกประเภทอปท แบบ dropdown
    setListboxOptSelected: setListboxOptSelected,
    onListboxOptSelected: (evt, newValue) => {
      setListboxOptSelected(newValue);
    },

    startDate: startDate,
    setStartDate: setStartDate,
    endDate: endDate,
    setEndDate: setEndDate,
    voteStatusSelected: voteStatusSelected,
    setVoteStatusSelected: setVoteStatusSelected,
    onVoteStatusSelected: (evt, newValue) => {
      //สถานะการจัดการเลือกตั้ง อยู่ระหว่างดำเนินการ,ดำเนินกรเสร็จเ้น , ไม่สามารถดำเนิการได้
      if (newValue) {
        setVoteStatusSelected(newValue);
      }
    },

    inspectVotedateSelected: inspectVotedateSelected,
    setInspectVotedateSelected: setInspectVotedateSelected,
    onInspectVotedateSelected: (evt, newValue) => {
      setInspectVotedateSelected(newValue);
    },

    inspectProvinceGroupSelected: inspectProvinceGroupSelected,
    setInspectProvinceGroupSelected: setInspectProvinceGroupSelected,
    onInspectProvinceGroupSelected: (evt, newValue) => {
      setInspectProvinceGroupSelected(newValue);
    },

    inspectSelect8Selected: inspectSelect8Selected,
    setInspectSelect8Selected: setInspectSelect8Selected,
    onInspectSelect8Selected: (evt, newValue) => {
      setInspectSelect8Selected(newValue);
    },

    inspectCadidateDateSelected: inspectCadidateDateSelected,
    setInspectCadidateDateSelected: setInspectCadidateDateSelected,
    onInspectCadidateDateSelected: (evt, newValue) => {
      setInspectCadidateDateSelected(newValue);
    },

    inspectApplyDateSelected: inspectApplyDateSelected,
    setInspectApplyDateSelected: setInspectApplyDateSelected,
    onInspectApplyDateSelected: (evt, newValue) => {
      setInspectApplyDateSelected(newValue);
      setInspectApplyDateSelectedSingle(null);
    },

    inspectApplyDateSelectedSingle: inspectApplyDateSelectedSingle,
    setInspectApplyDateSelectedSingle: setInspectApplyDateSelectedSingle,
    onInspectApplyDateSelectedSingle: (evt, newValue) => {
      setInspectApplyDateSelectedSingle(newValue);
    },

    votedateSelected: votedateSelected,
    setVotedateSelected: setVotedateSelected,
    onVotedateSelected: (evt, newValue) => {
      setVotedateSelected(newValue);
    },
    aumphurAreaSelected: aumphurAreaSelected,
    setAumphurAreaSelected: setAumphurAreaSelected,
    onAumphurAreaSelected: (evt, newValue) => {
      setAumphurAreaSelected(newValue);
    },
    areaSelected: areaSelected,
    setAreaSelected: setAreaSelected,
    onAreaSelected: (evt, newValue) => {
      setAreaSelected(newValue);
    },
    positionStatusSelected: positionStatusSelected,
    setPostionStatusSelected: setPostionStatusSelected,
    onPositionStatusSelected: (evt, newValue) => {
      setPostionStatusSelected(newValue);
    },
    positionTypeSelected: positionTypeSelected,
    setPostionTypeSelected: setPostionTypeSelected,
    onPostionTypeSelected: (evt, newValue) => {
      setPostionTypeSelected(newValue);
      setAreaSelected(null);
    },
    areaEctSelected: areaEctSelected,
    setAreaEctSelected: setAreaEctSelected,
    onAreaEctSelected: (evt, newValue) => {
      setAreaEctSelected(newValue);
      setProvinceSelected(null);
    },

    //---จัดสรรบัตร

    votecardPrintingSelected: votecardPrintingSelected,
    setVotecardPrintingSelected: setVotecardPrintingSelected,
    onVotecardPrintingSelected: (evt, newValue) => {
      setVotecardPrintingSelected(newValue);
      setVotecardSizeSelected(null);
      setVotecardColorSelected(null);
      setVotecardNumberSelected(null);
    },

    votecardSizeSelected: votecardSizeSelected,
    setVotecardSizeSelected: setVotecardSizeSelected,
    onVotecardSizeSelected: (evt, newValue) => {
      setVotecardSizeSelected(newValue);
      setVotecardColorSelected(null);
      setVotecardNumberSelected(null);
    },
    votecardColorSelected: votecardColorSelected,
    setVotecardColorSelected: setVotecardColorSelected,
    onVotecardColorSelected: (evt, newValue) => {
      setVotecardColorSelected(newValue);
      setVotecardNumberSelected(null);
    },

    votecardNumberSelected: votecardNumberSelected,
    setVotecardNumberSelected: setVotecardNumberSelected,
    onVotecardNumberSelected: (evt, newValue) => {
      setVotecardNumberSelected(newValue);
    },

    //---จัดสรรบัตร
    // utilities
    getOptTypeSelectedLists: () => {
      let optIdLists = [];
      for (const [, v] of Object.entries(optTypeSelected)) {
        if (v.checked) {
          optIdLists.push(v.value);
        }
      }
      return optIdLists;
    }
  };
  return (
    <InspectorContext.Provider value={value}>
      {children}
    </InspectorContext.Provider>
  );
};
