import Autocomplete from "@material-ui/lab/Autocomplete";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxDate = props => {
  let optId = props.optSelected !== null ? props.optSelected.id : 0;

  let voteType = props.optVoteTypeSelected !== null ? props.optVoteTypeSelected.id : 1;

  let isEnable = false;
  let query = "";

  if (optId) {
    //query += `?page=1&optId=${optId}&voteType=1&optPositionTypeId=2`;
    if (voteType !== 1) {
      if (props.mode === 'Add') {
        if (voteType === 2) {
          query += `?page=1&optId=${optId}&voteType=[1]&optPositionTypeId=2`;
        } else if (voteType === 3) {
          query += `?page=1&optId=${optId}&voteType=[1,2]&optPositionTypeId=2`;
        }
        else if (voteType === 4) {
          query += `?page=1&optId=${optId}&voteType=[1]&optPositionTypeId=2`;
        }
      } else {
        query += `?page=1&optId=${optId}&optPositionTypeId=2`;
      }
    } else {
      query += `?page=1&optId=${optId}&voteType=[1]&optPositionTypeId=2`;
    }
    isEnable = true;
  }

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + `/opt/vote/list${query}`,
    [props.optSelected],
    isEnable
  );

  const customVoteDate = old => {
    let d = new Date(old);
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric"
    };
    return d.toLocaleDateString("th-TH", options);
  };

  let content = (
    <TextField disabled label="วันเลือกตั้งครบวาระ/แทนตำแหน่งที่ว่าง" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {

    const valA = fetchData.data.map(v => {
      return { id: v.optVoteId, value: v.voteDate, raw: v};
    });

    if (props.mode === 'Add' && valA.length > 0) {
      const maxId = valA.reduce(
        (max, v) => (v.value > max ? v.value : max),
        valA[0].id
      );

      const newData = valA.filter(d => (d.id === maxId));
      data = newData;
    } else if (props.mode === 'Edit' && valA.length > 0) {
      data = valA;
    } else {
      data = valA;
    }


    //----------------
    /* data = fetchData.data.map(v => {
      return { id: v.optVoteId, value: v.voteDate };
    }); */
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        id="ListboxDate"
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        options={data}
        value={props.value}
        getOptionLabel={option => customVoteDate(option.value)}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={props.onChange}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={props.title ? props.title : "วันเลือกตั้งครบวาระ"}
              margin="normal"
              fullWidth
            />
          );
        }}
      />
    );
  }
  return content;
};
export default ListboxDate;
