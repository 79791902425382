import React from "react";
import NotificationList from "./NotificationList";
import NotificationDetails from "./NotificationDetails";
import "./Notifications.scss";
import {SortBy} from "./Sortable";
import {
    markReadNotifications,
} from "../../../contexts/API/Notifications/Notifications";
import {useDispatch, useSelector} from "react-redux";

const Notifications = props => {

    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notifications).notifications;
    const [currentSection, ] = React.useState('inbox')
    const [selectedNotificationId, setSelectedNotificationId] = React.useState(0)
    const [sortBy, ] = React.useState(SortBy.Alphabet)

    const onSortBy = (sort) => {
        if ( !notifications ) return;
        let sorted;
        switch (sort) {
            case SortBy.Alphabet:
                sorted = [...notifications].sort((a, b) => {

                    if (a.header > b.header) {
                        return 1;
                    }
                    if (a.header < b.header) {
                        return -1;
                    }

                    return 0;
                })
                dispatch({type: 'notifications/insert', payload: sorted});
                break;
            case SortBy.Province:
                sorted = [...notifications].sort((a, b) => {

                    if (a.provinceName > b.provinceName) {
                        return 1;
                    }
                    if (a.provinceName < b.provinceName) {
                        return -1;
                    }

                    return 0;
                })
                dispatch({type: 'notifications/insert', payload: sorted});
                break;
            case SortBy.Date:
                sorted = [...notifications].sort((a, b) => {
                    const aValue = new Date(a.updateDatetime);
                    const bValue = new Date(b.updateDatetime);

                    if (aValue > bValue) {
                        return -1;
                    }
                    if (aValue < bValue) {
                        return 1;
                    }

                    return 0;
                })
                dispatch({type: 'notifications/insert', payload: sorted});
                break;
            case SortBy.Unread:
                sorted = [...notifications].sort((a, b) => {
                    if (a.isRead === false) {
                        return -1;
                    }
                    if (b.isRead === false) {
                        return 1;
                    }
                    return 0;
                })
                dispatch({type: 'notifications/insert', payload: sorted});
        }
    }

    const openMessage = (id) => {
        markReadNotifications(id).then(r => {
            setSelectedNotificationId(id)
            dispatch({type: 'notifications/markRead', payload: id});
        }).catch(err => console.error(err))
    }

    const deleteMessage = (id) => {
        const index = notifications.findIndex(x => x.id === id);

        const updatedNotifications = [...notifications]

        notifications[index].tag = 'deleted';
        dispatch({type: 'notifications/insert', payload: updatedNotifications});
        // Select the next message in the list
        let selectedNotificationId = 0;
        for (const n of notifications) {
            if (n.tag === currentSection) {
                selectedNotificationId = notifications.id;
                break;
            }
        }

        setSelectedNotificationId(selectedNotificationId)
    }

    const currentNotification = (notifications) ? notifications.find(x => x.id === selectedNotificationId):null;

    return (
        <div>
            <div className="inbox-container">
                <NotificationList
                    sortBy={sortBy}
                    onSortBy={(sortBy) => onSortBy(sortBy)}
                    notifications={notifications}
                    onNotificationSelected={(id) => {
                        openMessage(id);
                    }}
                    selectedNotificationId={selectedNotificationId}
                    />
                <NotificationDetails
                    email={currentNotification}
                    onDelete={(id) => {
                        deleteMessage(id);
                    }}/>
            </div>
        </div>
    );
};

export default Notifications;
