import React, { useContext, useEffect, useState } from "react";
import {
  deloptPersonId,
  editoptPersonIdresultVote,
  getPersonOpt
} from "contexts/API/OPT/OptPerson";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import FileSaver from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import ListboxArea from "components/Common/ListboxArea";
import ListboxAumphurArea from "components/Common/ListboxAumphurArea";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxPositionFollowVoteDate from "components/Common/ListboxPositionFollowVoteDate";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxVotedate from "components/Common/ListboxVotedate";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import Paper from "@material-ui/core/Paper";
import { Switch } from "@material-ui/core";
import TableComponent from "components/Table/MaterialTable";
import TextField from "@material-ui/core/TextField";
import { VoteDateContext } from "contexts/VoteDate";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { withStyles } from "@material-ui/core/styles";

const OptManager = ({ pageProps, setPageProps }) => {
  const {
    optVoteTypeSelected,
    onOptVoteTypeSelected,
    onOptTypeSelected,
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,
    tambolSelected,
    optTypeSelected,
    onOptSelected,
    optSelected,

    onVotedateSelected,
    onAumphurAreaSelected,
    positionFollowVoteDateSelected,
    onPositionFollowVoteDateSelected,
    aumphurAreaSelected,
    areaSelected,
    onAreaSelected,
    votedateSelected,
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);
  const AntSwitch = withStyles(theme => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex"
    },
    switchBase: {
      padding: 2,

      color: theme.palette.common.white,
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.success.main,
          borderColor: theme.palette.success.main
        }
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none"
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#ff1228"
      //backgroundColor: theme.palette.common.red
    },
    checked: {}
  }))(Switch);

  const useStyles = makeStyles({
    buttoncustom: {
      fontSize: 14,
      fontWeight: 500
      //color: props => props.color
    }
  });

  //variable
  const [columns, setColumns] = React.useState([]);
  const [data, setData] = useState([]);
  const { sweetAlerts } = useSweetAlert();

  const [loading, setLoading] = useState(false);
  const [grouped, setGrouped] = useState([]);
  const editPersondata = rowData => {
    setPageProps({
      active: "EditOptPerson",
      props: {
        rowData: rowData,
        action: "edit"
      }
    });
  };
  useEffect(() => {
    if (pageProps.action === "cancel") {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps]);

  React.useEffect(() => {
    loadData();
  }, []);
  const tableRef = React.useRef();
  const loadData = async () => { };

  async function handleSubmit() {
    if (optVoteTypeSelected == null) {
      sweetAlerts.warning("กรุณาเลือกประเภทการเลือกตั้ง");
    }
    if (optSelected == null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
    }
    if (votedateSelected == null) {
      sweetAlerts.warning("กรุณาระบุวันเลือกตั้ง");
    } else {
      let query = "?";
      if (positionFollowVoteDateSelected) {
        query += `&positionTypeId=${positionFollowVoteDateSelected.id}`;
      }
      if (aumphurAreaSelected) {
        query += `&aumphurAreaId=${aumphurAreaSelected.id}`;
      }
      if (areaSelected) {
        query += `&areaId=${areaSelected.id}`;
      }
      if (optVoteTypeSelected) {
        query += `&optVoteTypeId=${optVoteTypeSelected.id}`;
      }

      setLoading(true);
      const data = await getPersonOpt(optSelected, votedateSelected, query)
        .then(result => {
          if (Object.keys(result.data.data).length > 0) {
            setData(result.data.data);

            let group = result.data.data.reduce((r, a) => {
              console.log("aaa>>", a);

              r[a.positionAreaName] = [...(r[a.positionAreaName] || []), a];
              console.log("r[a.positionAreaName] >>", r[a.positionAreaName]);
              return r;
            }, {});

            //console.log(Object.entries(group));
            setGrouped(Object.entries(group));
            const columnConfig = [
              {
                title: "ตำแหน่ง",
                field: "positionAreaName",
                exportData: row => {
                  return row.positionAreaName;
                }
              },
              {
                title: "",
                field: "PKID",
                hidden: true
              },
              {
                title: "",
                field: "optPositionTypeId",
                hidden: true
              },
              {
                title: "หมายเลขผู้สมัคร",
                field: "applyNo",
                editable: "never"
              },
              {
                title: "ชื่อ สกุล",
                field: "fullName",
                editable: "never",
                cellStyle: { minWidth: "220px" },
                render: rowData => <div>{rowData.fullName}</div>
                // exportData: row => {
                //   return row.fullName;
                // }
              },
              {
                title: "เลขประจำตัวประชาชน",
                field: "idCard",
                editable: "never",
                cellStyle: { minWidth: "190px" },
                render: rowData => (
                  <NumberFormat
                    style={{
                      width: "100%",
                      margin: "0 0 17px 0",
                      paddingTop: "27px",
                      border: "none"
                    }}
                    value={rowData.idCard}
                    placeholder="เลขประจำตัวประชาชน"
                    customInput={TextField}
                    format="# #### ##### ## #"
                    mask="X"
                    fullWidth
                  />
                )
              },
              {
                title: "ประเภท อปท.",
                field: "optSubTypeShortName",
                editable: "never",
                cellStyle: { minWidth: "100px" }
              },
              {
                title: "ชื่อ อปท.",
                field: "optName",
                editable: "never",
                cellStyle: { minWidth: "100px" }
              },
              // {
              //   title: "เขต",
              //   field: "areaNo",
              //   editable: "never",
              //   cellStyle: { minWidth: "50px", textAlign: "center" }
              // },

              {
                title: "ใบสมัคร",
                field: "fileName",
                editable: "never",
                cellStyle: { minWidth: "50px" },
                render: rowData => {
                  return rowData.from4_1UUID ? (
                    //   <LinkDownload
                    //     url={getFileDownloadUrl("opt", rowData.from4_1UUID)}
                    //     fileId={rowData.from4_1UUID}
                    //     fileName={rowData.fileName}
                    // //  >
                    // <FontAwesomeIcon
                    //   icon={faFilePdf}
                    //   style={{ color: "red" }}

                    // >

                    <button
                      onClick={() => {
                        FileSaver(
                          getFileDownloadUrl("opt", rowData.from4_1UUID),
                          rowData.fileName
                        );
                      }}
                      style={{
                        cursor: "pointer",
                        border: "none",
                        background: "transparent"
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        style={{ color: "red", fontSize: 20 }}
                      />
                    </button>
                  ) : (
                    // </LinkDownload>
                    ""
                  );
                }
              }
            ];
            setColumns(columnConfig);
            setLoading(false);
          } else {
            setData(null);

            setLoading(false);
          }
        })
        .catch(e => {
          HandelError(e);
          setLoading(false);
          sweetAlerts.error("ไม่สามารถดึงข้อมูล", e.toString());
        });
      return data;
    }
  }

  function compareNumbers(a, b) {
    return a - b;
  }
  const classes = useStyles();
  const ManageOptPersonHandler = data => {
    //s console.log(data);
    setPageProps({
      active: "ManageOptPerson",
      props: { rowData: data, action: "add" }
    });
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CheckboxOpttype
                        optTypeSelected={optTypeSelected}
                        onOptTypeSelected={onOptTypeSelected}
                        required={true}
                      ></CheckboxOpttype>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxOptVotetype
                        required={true}
                        value={optVoteTypeSelected}
                        onChange={onOptVoteTypeSelected}
                        validators={[MustNotNull]}
                      ></ListboxOptVotetype>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxProvince
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                        required={true}
                        validators={[MustNotNull]}
                      ></ListboxProvince>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxDistinct
                        value={amphurSelected}
                        provinceSelected={provinceSelected}
                        onChange={onAmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxOpts
                        value={optSelected}
                        getOptTypeSelectedLists={getOptTypeSelectedLists}
                        optTypeSelected={optTypeSelected}
                        provinceSelected={provinceSelected}
                        amphurSelected={amphurSelected}
                        tambolSelected={tambolSelected}
                        onChange={onOptSelected}
                        required={true}
                        validators={[MustNotNull]}
                      ></ListboxOpts>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxVotedate
                        value={votedateSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onVotedateSelected}
                        required={true}
                        validators={[MustNotNull]}
                      ></ListboxVotedate>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxPositionFollowVoteDate
                        value={positionFollowVoteDateSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        votedateSelected={votedateSelected}
                        onChange={onPositionFollowVoteDateSelected}
                      ></ListboxPositionFollowVoteDate>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxAumphurArea
                        value={aumphurAreaSelected}
                        optSelected={optSelected}
                        votedateSelected={votedateSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onAumphurAreaSelected}
                      ></ListboxAumphurArea>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxArea
                        value={areaSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        votedateSelected={votedateSelected}
                        aumphurAreaSelected={aumphurAreaSelected}
                        positionFollowVoteDateSelected={
                          positionFollowVoteDateSelected
                        }
                        onChange={onAreaSelected}
                      ></ListboxArea>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <br /> <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}>
                  {/* <Button
                    className={classes.buttoncustom}
                    color="success"
                    onClick={ManageOptPersonHandler}
                    // disabled={!isValid}
                  >
                    เพิ่มผู้สมัคร ส.ถ./ผ.ถ.
                  </Button> */}
                </GridItem>

                <GridItem>
                  <Button className={classes.buttoncustom}>ยกเลิก</Button>
                  <Button color="info" type="button" onClick={handleSubmit}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* <FadeIn> */}
      {loading ? (
        <LoadingData />
      ) : (
        //grouped.sort(compareNumbers).map((cate, index) => {
        grouped.sort().map((cate, index) => {
          return (
            <div key={index}>
              <TableComponent
                key={index}
                tableRef={tableRef}
                title={cate[0]}
                columns={columns}
                //  data={grouped[0][category]}
                data={cate[1][0].PKID ? cate[1] : []}
                enableInitColumns={false}
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}
                localization={{}}
                options={{
                  paging: false,
                  search: false,
                  export: false,
                  exportButton: false
                }}
                actions={[
                  rowData => ({
                    icon: props => (
                      <>
                        <p
                          style={{
                            fontWeight: "400",
                            fontFamily: "Athiti",
                            paddingRight: "5px",
                            paddingTop: "10px"
                          }}
                        >
                          ไม่รับ
                        </p>
                        <AntSwitch
                          checked={rowData.registerStatus === true}
                          name="registerStatus"
                        />
                        <p
                          style={{
                            fontWeight: "400",
                            fontFamily: "Athiti",
                            paddingLeft: "5px",
                            paddingTop: "10px"
                          }}
                        >
                          รับ
                        </p>
                      </>
                    ),
                    onClick: (event, rowData) => {
                      const dataUpdate = cate[1];

                      //console.log("dataUpdate>>>", dataUpdate);
                      const changeData = {
                        [event.target.name]: event.target.checked
                      };
                      //console.log("changeData>>", changeData);

                      const newData = { ...rowData, ...changeData };
                      const _PK = newData.PKID;
                      //console.log("newData>>", newData);

                      sweetAlerts.danger(
                        "ยืนยันข้อมูล",
                        "ยืนยันการเปลี่ยนแปลงผลการรับสมัคร",
                        () => {
                          editoptPersonIdresultVote(
                            newData.PKID,
                            newData.optPositionTypeId,
                            newData.registerStatus
                          )
                            .then(result => {
                              const objIndex = data.findIndex(
                                newData => newData.PKID === _PK
                              );

                              data[objIndex] = newData;

                              let group = data.reduce((r, a) => {
                                r[a.positionAreaName] = [
                                  ...(r[a.positionAreaName] || []),
                                  a
                                ];
                                return r;
                              }, {});

                              setGrouped(Object.entries(group));
                              sweetAlerts.success(`แก้ไขข้อมูลเรียบร้อยแล้ว`);
                            })
                            .catch(err => {
                              if (err.response.data.code !== 20000) {
                                sweetAlerts.danger(
                                  `ไม่สามารถแก้ไขข้อมูลได้`,
                                  err.response.data.desc
                                );
                              }
                            });
                        },
                        true
                      );
                    }
                  }),

                  rowData => ({
                    icon: () => (
                      <div>
                        <Button
                          size="sm"
                          color="warning"
                          onClick={() => editPersondata(rowData, optSelected)}
                        >
                          แก้ไข
                        </Button>
                      </div>
                    )
                  }),
                  rowData => ({
                    icon: () => (
                      <div>
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => {
                            //  const dataDelete = [...data];

                            sweetAlerts.error(
                              "ยืนการลบข้อมูล",
                              "คุณต้องการลบข้อมูลใช่หรือไม่",
                              () => {
                                deloptPersonId(
                                  rowData.PKID,
                                  rowData.optPositionTypeId
                                )
                                  .then(result => {
                                    if (cate[1].length > 1) {
                                      const _PK = rowData.PKID;
                                      const objIndex = data.findIndex(
                                        rowData => rowData.PKID === _PK
                                      );
                                      data[objIndex] = rowData;
                                      data.splice(objIndex, 1);
                                    }
                                    if (cate[1].length === 1) {
                                      const _PK = rowData.PKID;
                                      const objIndex = data.findIndex(
                                        rowData => rowData.PKID === _PK
                                      );

                                      const _newData = {
                                        PKID: null,
                                        applyNo: null,
                                        positionAreaName:
                                          rowData.positionAreaName,
                                        areaId: rowData.areaId,
                                        msOptId: rowData.msOptId,
                                        optPositionTypeId:
                                          rowData.optPositionTypeId,
                                        optSubTypeShortId:
                                          rowData.optSubTypeShortId,
                                        optAreaVote: rowData.optAreaVote,
                                        optVoteId: rowData.optVoteId
                                      };
                                      data[objIndex] = _newData;
                                    }

                                    let group = data.reduce((r, a) => {
                                      r[a.positionAreaName] = [
                                        ...(r[a.positionAreaName] || []),
                                        a
                                      ];
                                      return r;
                                    }, {});

                                    setGrouped(Object.entries(group));

                                    sweetAlerts.success(`ลบข้อมูลสำเร็จ`);
                                  })
                                  .catch(err => {
                                    if (err.response.data.code !== 20000) {
                                      sweetAlerts.danger(
                                        `ไม่สามารถลบข้อมูลได้`,
                                        err.response.data.desc
                                      );
                                    }
                                  });
                              },
                              true
                            );
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    )
                  })
                ]}
              />

              <Button
                size="sm"
                color="success"
                onClick={() => ManageOptPersonHandler(cate[1][0])}
              >
                เพิ่มผู้สมัคร {cate[0]}
              </Button>
              <br></br>
            </div>
          );
        })
      )}
      {/* </FadeIn> */}
    </>
  );
};
export default OptManager;
