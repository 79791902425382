import React, { useState } from "react";

import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxProvince from "components/Common/ListboxProvince.js";
import ListboxTitle from "components/Common/ListboxTitle";
//import NumberFormat from "react-number-format";
import Slide from "@material-ui/core/Slide";
//import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { addUser } from "contexts/API/UserManagement/roles";
// Material Core
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModaladdUser = props => {
  let _initialState = {
    titleId: null,
    firstName: null,
    lastName: null,
    //citizenId: null,
    citizenId: "1088888888886",
    isCenter: false,
    userName: null,
    provinceId: 0,
    msOptId: 0,
    isApproved: 1
  };
  const [data, setData] = useState(_initialState);
  const [titleId, setTitleId] = useState({
    id: 0,
    value: ""
  });
  const [msOptId, setMsOptId] = useState({ id: 0 });
  const { sweetAlerts } = useSweetAlert();


  const { showModal2, closeModal2 } = props;
  const [helpertextidCard, setHelpertextidCard] = useState("");
  const [helpertexttitle, setHelpertexttitle] = useState("");
  const [helpertextfname, setHelpertextfname] = useState("");
  const [helpertextlname, setHelpertextlname] = useState("");
  const [helpertextProvince, setHelpertextProvince] = useState("");
  const [helpertextpass, setHelpertextpass] = useState("");
  const [helpertextpass2, setHelpertextpass2] = useState("");
  const [helpertextans, setHelpertextans] = useState("");
  const [helpertextques, setHelpertextques] = useState("");
  const [helpertextmail, setHelpertextmail] = useState("");
  const [helpertextUser, setHelpertextUser] = useState("");
  const [helpertexttel, setHelpertexttel] = useState("");
  const [provinceId, setProvinceId] = useState({
    id: 0,
    value: ""
  });
  const [isIdCardValid, setIsIdCardValid] = useState(false);
  //   const [helpertextUser, setHelpertextUer] = useState("");
  const classes = useStyles();

  const onIDCardHandler = v => {
    setData({ ...data, citizenId: v.value });
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      console.log(isError);
      if (isError) {
        sweetAlerts.warning("เลขประจำตัวประชาชนผิด");
        if (isIdCardValid === true) {
          setIsIdCardValid(false);
        }
        setHelpertextidCard("กรุณาระบุ");
      } else {
        if (isIdCardValid === false) {
          setIsIdCardValid(true);

          //
        }
        setHelpertextidCard("");
      }
    } else {
      if (isIdCardValid === true) {
        setIsIdCardValid(false);
      }
      setHelpertextidCard("กรุณาระบุ");
    }
  };

  const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const onProvinceId = (evt, newValue) => {
    if (newValue) {
      setProvinceId(newValue);
      setData({ ...data, provinceId: newValue.id });
      setHelpertextProvince("");
    } else {
      setHelpertextProvince("กรุณาระบุ");
    }
  };
  const onTitleId = (evt, newValue) => {
    if (newValue) {
      setTitleId(newValue);
      setData({ ...data, titleId: newValue.id });
      setHelpertexttitle("");
    } else {
      setHelpertexttitle("กรุณาระบุ");
    }
  };
  const onOptSelected = (evt, newValue) => {
    if (newValue) {
      setMsOptId(newValue);
      setData({ ...data, msOptId: newValue.id });
    }
  };
  // useEffect(() => {
  //   if (detail) {
  //     getUserDetail(detail)
  //       .then(result => {
  //         console.log(result);
  //         let data = result.data.data;
  //         setData(data);
  //       })
  //       .catch(err => {
  //         sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
  //       });
  //   }
  // }, [detail]);

  const submit = () => {
    if (data.provinceId === 0) {
      setHelpertextProvince("กรุณาระบุจังหวัด*");
    }
    if (data.userName === null) {
      setHelpertextUser("กรุณาระบุชื่อผู้ใช้งาน*");
    }
    if (!data.titleId) {
      setHelpertexttitle("กรุณาระบุคำนำหน้าชื่อ*");
    }
    if (!data.firstName) {
      setHelpertextfname("กรุณาระบุชื่อ*");
    }
    if (!data.lastName) {
      setHelpertextlname("กรุณาระบุนามสกุล*");
    }
    if (!data.telephone) {
      setHelpertexttel("กรุณาระบุเบอร์โทรศัพท์*");
    }
    if (!data.email) {
      setHelpertextmail("กรุณาระบุให้ถูกต้อง (abc@email.com)*");
    }
    if (!data.password) {
      setHelpertextpass("กรุณาระบุ*");
    }
    if (!data.passwordConfirm) {
      setHelpertextpass2("กรุณาระบุ*");
    }
    if (!data.passwordQuestion) {
      setHelpertextques("กรุณาระบุ*");
    }
    if (!data.passwordAnswer) {
      setHelpertextans("กรุณาระบุ*");
    }
    if (data.pass !== data.pass2) {
      setHelpertextpass2("รหัสผ่านไม่ตรงกัน");
    }
    if (!data.citizenId) {
      setHelpertextidCard("กรุณาระบุ*");
    }
    if (
      data.firstName &&
      data.lastName &&
      data.email &&
      data.telephone &&
      data.provinceId &&
      data.userName &&
      data.password &&
      data.passwordConfirm &&
      data.passwordQuestion &&
      data.passwordAnswer &&
      helpertextmail === "" &&
      data.citizenId &&
      helpertextidCard === ""
    ) {
      //   console.log(data);
      addUser(data)
        .then(result => {
          if (result.data.success) {
            closeModal2();
            sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");
            setData(_initialState);
            setTitleId({
              id: 0,
              value: ""
            });
            setProvinceId({
              id: 0,
              value: ""
            });
            //fileSuccess(result.data.success);
          }
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
        });
    } else {
      sweetAlerts.warning(`กรุณากรอกข้อมูลให้ครบถ้วน`);
    }
  };
  //   const fileSuccess = data => {
  //     props.onaddSuccess(data);
  //   };

  return (
    <div className={showModal2 ? "modal" : "hide"}>
      <Dialog
        open={showModal2}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModal2}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="warning">
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
              เพิ่มข้อมูลบัญชีผู้ใช้งาน
            </div>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <br></br>

          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="ชื่อผู้ใช้งาน"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.userName || "",

                  autoComplete: "new-password",
                  onChange: e => {
                    if (e.target.value) {
                      setData({ ...data, userName: e.target.value });
                      setHelpertextUser("");
                    } else {
                      setData({
                        ...data,
                        userName: null
                      });
                      setHelpertextUser("กรุณาระบุ*");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextUser}
              </FormHelperText>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="อีเมล"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.email || "",

                  autoComplete: "new-password",
                  onChange: e => {
                    if (e.target.value) {
                      if (
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                          e.target.value
                        )
                      ) {
                        setData({ ...data, email: e.target.value });
                        setHelpertextmail("");
                      } else {
                        setData({ ...data, email: e.target.value });
                        setHelpertextmail(
                          "กรุณาระบุให้ถูกต้อง (abc@email.com)*"
                        );
                      }
                    } else {
                      setData({
                        ...data,
                        email: null
                      });
                      setHelpertextmail("กรุณาระบุให้ถูกต้อง (abc@email.com)*");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextmail}
              </FormHelperText>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="รหัสผ่าน"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.password || "",
                  type: "password",
                  autoComplete: "new-password",
                  onChange: e => {
                    if (e.target.value) {
                      setData({ ...data, password: e.target.value });
                      setHelpertextpass("");
                    } else {
                      setData({
                        ...data,
                        password: null
                      });
                      setHelpertextpass("กรุณาระบุ*");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextpass}
              </FormHelperText>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="ยืนยันรหัสผ่าน"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.passwordConfirm || "",
                  type: "password",
                  autoComplete: "new-password",
                  onChange: e => {
                    if (e.target.value) {
                      setData({ ...data, passwordConfirm: e.target.value });
                      setHelpertextpass2("");
                      if (data.password !== e.target.value) {
                        setHelpertextpass2("รหัสผ่านไม่ตรงกัน");
                      } else {
                        setHelpertextpass2("");
                      }
                    } else {
                      setData({
                        ...data,
                        passwordConfirm: null
                      });
                      setHelpertextpass2("กรุณาระบุ*");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextpass2}
              </FormHelperText>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="คำถามรหัสผ่าน"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: data.passwordQuestion || "",

                  autoComplete: "new-password",
                  onChange: e => {
                    if (e.target.value) {
                      setData({ ...data, passwordQuestion: e.target.value });
                      setHelpertextques("");
                    } else {
                      setData({
                        ...data,
                        passwordQuestion: null
                      });
                      setHelpertextques("กรุณาระบุ*");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextques}
              </FormHelperText>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="คำตอบรหัสผ่าน"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.passwordAnswer || "",

                  autoComplete: "new-password",
                  onChange: e => {
                    if (e.target.value) {
                      setData({ ...data, passwordAnswer: e.target.value });
                      setHelpertextans("");
                    } else {
                      setData({
                        ...data,
                        passwordAnswer: null
                      });
                      setHelpertextans("กรุณาระบุ*");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextans}
              </FormHelperText>
            </GridItem>
          </GridContainer>
          <DialogTitle id="classic-modal-slide-title">
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
              ข้อมูลรายละเอียดผู้ใช้งาน
            </div>
          </DialogTitle>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxTitle
                value={titleId}
                onChange={onTitleId}>
              </ListboxTitle>
              <FormHelperText style={{ color: "red" }}>
                {helpertexttitle}
              </FormHelperText>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="ชื่อ"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.firstName || "",

                  autoComplete: "new-password",
                  onChange: e => {
                    if (e.target.value) {
                      setData({
                        ...data,
                        firstName: e.target.value
                      });
                      setHelpertextfname("");
                    } else {
                      setData({
                        ...data,
                        firstName: null
                      });
                      setHelpertextfname("กรุณาระบุ");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextfname}
              </FormHelperText>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="สกุล"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.lastName || "",

                  onChange: e => {
                    if (e.target.value) {
                      setData({
                        ...data,
                        lastName: e.target.value
                      });
                      setHelpertextlname("");
                    } else {
                      setData({
                        ...data,
                        lastName: null
                      });
                      setHelpertextlname("กรุณาระบุ");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextlname}
              </FormHelperText>
            </GridItem>

            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="เบอร์โทรศัพท์"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: data.telephone || "",

                  autoComplete: "new-password",
                  onChange: e => {
                    if (verifyNumber(e.target.value)) {
                      setData({ ...data, telephone: e.target.value });
                      setHelpertexttel("");
                    } else {
                      setData({
                        ...data,
                        telephone: null
                      });
                      setHelpertexttel("กรุณาระบุ");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertexttel}
              </FormHelperText>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxProvince
                value={provinceId}
                onChange={onProvinceId}
              ></ListboxProvince>
              <FormHelperText style={{ color: "red" }}>
                {helpertextProvince}
              </FormHelperText>
            </GridItem>

            <GridItem xs={6} sm={6} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.isCenter}
                    onClick={e => {
                      if (e.target.checked) {
                        setData({
                          ...data,
                          isCenter: true
                        });
                      } else {
                        setData({
                          ...data,
                          isCenter: false
                        });
                      }
                    }}
                    color="primary"
                  />
                }
                label="สังกัดส่วนกลาง"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxOpts
                value={msOptId}
                provinceSelected={provinceId}
                onChange={onOptSelected}
              ></ListboxOpts>
            </GridItem>
            {/* <GridItem xs={6} sm={6} md={6}>
              <NumberFormat
                style={{
                  width: "100%",
                  margin: "0 0 17px 0",
                  paddingTop: "27px"
                }}
                value={data.citizenId}
                placeholder="เลขประจำตัวประชาชน"
                customInput={TextField}
                format="# #### ##### ## #"
                mask="X"
                fullWidth
                onValueChange={onIDCardHandler}
              />
              <span style={{ color: "red" }}>{helpertextidCard}</span>
            </GridItem>
             */}
          </GridContainer>

          <br></br>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <div>
              <Button onClick={closeModal2} size="sm">
                ปิดหน้าต่าง
              </Button>
            </div>
            <div>
              <Button size="sm" color="success" onClick={submit}>
                บันทึกข้อมูล
              </Button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModaladdUser;
