import React, { useState } from "react";

import EditOptPerson from "./EditOptPerson";
import ManageOptPerson from "./ManageOptPerson";
import OptPerson from "./OptPerson";
import { VoteDateContextProvider } from "contexts/VoteDate";

const SwitchComponents = ({ active, children }) => {
  return children.filter(child => child.props.name === active);
};

const PersonIndex = props => {
  // Local State
  //const [activeComponent, setActiveComponent] = useState("OptPerson");

  const [pageProps, setPageProps] = useState({
    active: "OptPerson",
    props: null
  });

  return (
    <VoteDateContextProvider>
      {window.scrollTo(0, 0)}
      <SwitchComponents active={pageProps.active}>
        <OptPerson
          name="OptPerson"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <ManageOptPerson
          name="ManageOptPerson"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <EditOptPerson
          name="EditOptPerson"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        {/* <OptPerson name="OptPerson" setActive={setActiveComponent} />
        <ManageOptPerson
          name="ManageOptPerson"
          setActive={setActiveComponent}
        /> */}
      </SwitchComponents>
    </VoteDateContextProvider>
  );
};

export default PersonIndex;
