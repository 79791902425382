import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import OPT_VOTE_PUBLISH from "./OptVotepublish";
import OPT_VOTE_PUBLISH_EXCEL from "./ObjtVotepublishExcel";
import { VoteDateContextProvider } from "contexts/VoteDate";
import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";
const styles = {};
const useStyles = makeStyles(styles);

const VotePublishIndex = props => {
  const classes = useStyles();
  return (
    <div className={classes.tabcustom}>
      <VoteDateContextProvider>
        <CustomTabs
          styles={customTabsStyles}
          headerColor="primary"
          tabs={[
            {
              tabName: "จัดการผู้ที่ได้รับการเลือกตั้ง",
              tabContent: <OPT_VOTE_PUBLISH />
            },
            {
              tabName: " นำข้อมูลเข้าจากไฟล์",
              tabContent: <OPT_VOTE_PUBLISH_EXCEL />
            }
          ]}
        />
      </VoteDateContextProvider>
    </div>
  );
};
export default VotePublishIndex;
