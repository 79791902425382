import React, { useState, useEffect } from "react";

import {
  getPurchase,
  getPurchaseDetailByOptLists,
  updateVoteCardPurchase,
  updateVoteCardPurchaseDetailStatus,
  deleteVoteCardPurchase
} from "contexts/API/VoteCard/Purchase";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import LoadingData from "components/Common/LoadingData";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import TableComponent from "components/Table/MaterialTable";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import ConvertDate from "utils/ConvertDate";
import DatepickerVotedate from "components/Common/Datepicker";
import { useSweetAlert } from "components/Alert/SweetAlert";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FileUpload } from "components/Common/FileUpload";

import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";

import { uploadFile } from "contexts/API/FileManagement/upload";

const useStyles = makeStyles(styles);

const DetailPurchase = ({ pageProps, setPageProps }) => {
  const detail = pageProps;
  console.log("detail", detail);
  const [loading, setLoading] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const classes = useStyles();

  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadFileUUID, setUploadFileUUID] = useState("");
  const optionsTable = { exportButton: false, paging: false };
  const [purchaseID] = useState(detail.props.PurchaeID);
  //const [provinceID, setProvinceID] = useState(detail.props.ProvinceID);
  const [disablePurchaseStatus] = useState(
    detail.props.PurchaseStatusID > 2 ? true : false
  );
  const [voteCardSendType, setVoteCardSendType] = useState(0);
  useEffect(() => {
    setVoteCardSendType(detail.props.PurchaseStatusID);
    setUploadFileName(detail.props.FileName);
    setUploadFileUUID(detail.props.FileUUID);
  }, [detail.props]);
  console.log("voteCardSendType", voteCardSendType);
  const [
    voteCardPurchaseReferenceNo,
    setVoteCardPurchaseReferenceNo
  ] = useState("");
  const [voteCardPurchaseDateTime,] = useState(
    new Date().toISOString()
  );
  const [voteCardPurchaseRemark, setVoteCardPurchaseRemark] = useState("");

  const [columns] = useState([
    {
      title: "อำเภอ",
      field: "amphurName",
      cellStyle: {
        minWidth: "120px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "ชื่อ อปท.",
      field: "optName",
      cellStyle: {
        minWidth: "120px",
        textAlign: "left"
      },
      headerStyle: {
        textAlign: "left"
      }
    },
    {
      title: "วันที่เลือกตั้ง",
      field: "voteDate",
      cellStyle: {
        textAlign: "center",
        minWidth: "150px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.voteDate);
      }
    },
    {
      title: "จำนวนผู้สมัคร ผ.ถ.",
      field: "numberRegister1",
      cellStyle: {
        textAlign: "center",
        minWidth: "100px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberRegister1 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberRegister1);
      }
    },
    {
      title: "จำนวนผู้มีสิทธิ ผ.ถ.",
      field: "numberPersonVote1",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberPersonVote1 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberPersonVote1);
      }
    },
    {
      title: "จำนวนเล่ม ผ.ถ.",
      field: "",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberVoteCard1 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberVoteCard1);
      }
    },
    {
      title: "จำนวนฉบับ ผ.ถ.",
      field: "numberVoteCard1",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberVoteCard1 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberVoteCard1 * 20);
      }
    },
    {
      title: "เขตเลือกตั้ง",
      field: "areaNo",
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "จำนวนผู้สมัคร ส.ถ.",
      field: "numberRegister2",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberRegister2 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberRegister2);
      }
    },
    {
      title: "จำนวนผู้มีสิทธิ ส.ถ.",
      field: "numberPersonVote2",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberPersonVote2 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberPersonVote2);
      }
    },
    {
      title: "จำนวนเล่ม ส.ถ.",
      field: "numberVoteCard2",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberVoteCard2 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberVoteCard2);
      }
    },
    {
      title: "จำนวนฉบับ ส.ถ.",
      field: "numberVoteCard2",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberVoteCard2 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberVoteCard2 * 20);
      }
    },
    {
      title: "วันที่ส่ง/รับบัตร",
      field: "receiveDatetime",
      cellStyle: {
        minWidth: "120px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.receiveDatetime);
      }
    },
    {
      title: "วิธีการรับบัตร",
      field: "receiveTypeName",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    }
  ]);

  useEffect(() => {
    const optQueryUrl = () => {
      let endPoint = "?";

      if (purchaseID !== null) {
        endPoint += `PurchaseID=${purchaseID}`;
      }
      /*  if (provinceID !== null) {
         endPoint += `ProvinceID=${provinceID}&`;
       } */
      return endPoint;
    };

    getPurchase(optQueryUrl(), "")
      .then(result => {
        if (typeof result.data.data != "undefined") {
          if (result.data.data.length > 0) {
            setVoteCardPurchaseReferenceNo(
              result.data.data[0].reference_no != null
                ? result.data.data[0].reference_no
                : ""
            );
            setVoteCardPurchaseRemark(
              result.data.data[0].purchase_remark != null
                ? result.data.data[0].purchase_remark
                : ""
            );

            setUploadFileUUID(result.data.data[0].FileUUID);
            setUploadFileName(result.data.data[0].FileName);
          }
        }
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
      });

    getPurchaseDetailByOptLists(optQueryUrl(), "")
      .then(result => {
        setLoading(true);
        console.log(result.data.data);
        setData(result.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
      });
  }, []);

  /*   const handleChangeVoteCardSendType = event => {
      setVoteCardSendType(event.target.value);
    }; */
  const backtoMainHandler = () => {
    setPageProps({ active: "PurChase", action: "cancel" });
  };
  const updatePurchaseHandler = () => {
    //let _voteCardPurchaseStatusID = 1;
    if (
      voteCardPurchaseReferenceNo === null ||
      voteCardPurchaseReferenceNo === ""
    ) {
      sweetAlerts.warning("กรุณาระบุเลขที่หนังสืออ้างอิง");
      return;
    } else if (voteCardSendType == null) {
      sweetAlerts.warning("กรุณาระบุประเภทอัพโหลด");
      return;
    }
    if (voteCardSendType === 3 && uploadFileUUID === null) {
      sweetAlerts.warning("กรุณาอัพโหลดไฟล์");
      return false;
    }
    // if (voteCardSendType == "1") {
    //   _voteCardPurchaseStatusID = 2;
    // } else if (voteCardSendType == "2") {
    //   _voteCardPurchaseStatusID = 3;
    // }

    const bodyVoteCardPurchaes = {
      /*  :: vote_card_purchase :: */
      voteCardPurchaseID: detail.props.PurchaeID,
      referenceNo: voteCardPurchaseReferenceNo,
      purchaseDatetime: voteCardPurchaseDateTime.split("T")[0],
      purchaseRemark: voteCardPurchaseRemark,
      voteCardPurchaseStatusID: voteCardSendType === 1 ? 2 : voteCardSendType,
      FileUUID: uploadFileUUID
    };

    updateVoteCardPurchase(bodyVoteCardPurchaes)
      .then(r => {
        let retVoteCardPurchaes = r.data;
        if (retVoteCardPurchaes.success == true) {
          updateVoteCardPurchaseDetailStatus(bodyVoteCardPurchaes)
            .then(r => {
              let retVoteCardPurchaes = r.data;
              if (retVoteCardPurchaes.success == true) {
                sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
                backtoMainHandler();
              } else {
                sweetAlerts.error("ไม่สามารถบันทึกข้อมูล : ", "");
              }
            })
            .catch(error => {
              if (error.response) {
                sweetAlerts.error(
                  "ไม่สามารถบันทึกข้อมูล : ",
                  error.response.data.desc
                );
              }
            });
        } else {
          sweetAlerts.error("ไม่สามารถบันทึกข้อมูล : ", "");
        }
      })
      .catch(error => {
        if (error.response) {
          sweetAlerts.error(
            "ไม่สามารถบันทึกข้อมูล : ",
            error.response.data.desc
          );
        }
      });
  };
  return (
    <>
      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title="รายการสั่งซื้อบัตรเลือกตั้งแต่ละ อปท. ที่บันทึกเข้าสู่ระบบ"
          columns={columns}
          data={data}
          localization={{}}
          options={optionsTable}
          editable={{
            isDeleteHidden: rowData => rowData.vote_card_purchase_status_id > 2,
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                //console.log(oldData);

                deleteVoteCardPurchase({
                  voteCardPurchaseID: oldData.vote_card_purchase_id
                })
                  .then(result => {
                    sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                    dataDelete.splice(index, 1);
                    setData(dataDelete);
                    resolve();
                  })
                  .catch(err => {
                    sweetAlerts.danger(
                      `ลบข้อมูลไม่สำเร็จ`,
                      err.response.data.desc
                    );
                  });
              })
          }}
          components={{
            Container: props => <Paper {...props} elevation={0} />,
            Header: props => {
              return (
                <TableHead>
                  <TableRow>
                    <TableCell rowSpan={2}></TableCell>
                    <TableCell rowSpan={2}>อำเภอ</TableCell>
                    <TableCell rowSpan={2}>ชื่อ อปท.</TableCell>
                    <TableCell rowSpan={2}>วันเลือกตั้ง</TableCell>

                    <TableCell colSpan={4} align="center">
                      ผ.ถ.
                    </TableCell>
                    <TableCell rowSpan={2}>เขตเลือกตั้ง</TableCell>
                    <TableCell colSpan={4} align="center">
                      ส.ถ.
                    </TableCell>
                    <TableCell rowSpan={2}>วันที่ส่ง/รับบัตร</TableCell>
                    <TableCell rowSpan={2}>วิธีการรับบัตร</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">จำนวนผู้สมัคร</TableCell>
                    <TableCell align="center">จำนวนผู้มีสิทธิ</TableCell>
                    <TableCell align="center">จำนวนเล่ม</TableCell>
                    <TableCell align="center">จำนวนฉบับ</TableCell>
                    <TableCell align="center">จำนวนผู้สมัคร</TableCell>
                    <TableCell align="center">จำนวนผู้มีสิทธิ</TableCell>
                    <TableCell align="center">จำนวนเล่ม</TableCell>
                    <TableCell align="center">จำนวนฉบับ</TableCell>
                  </TableRow>
                </TableHead>
              );
            }
          }}
        //  onRowUpdate={onRowUpdate}
        ></TableComponent>
      )}

      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h5>อัพโหลดเอกสารส่งไปยังส่วนกลาง</h5>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer
                justify="space-around"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    label="เลขที่หนังสืออ้างอิง"
                    helperText="กรุณาระบุ *"
                    required
                    fullWidth
                    style={{ marginTop: "30px" }}
                    value={voteCardPurchaseReferenceNo}
                    defaultValue={voteCardPurchaseReferenceNo}
                    onChange={e => {
                      setVoteCardPurchaseReferenceNo(e.target.value);
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <DatepickerVotedate
                    label="วันที่ส่งข้อมูลการสั่งซื้อ"
                    required
                    InputProps={{
                      readOnly: true
                    }}
                    disabled
                    value={voteCardPurchaseDateTime}
                    defaultValue={voteCardPurchaseDateTime}
                  ></DatepickerVotedate>
                </GridItem>
              </GridContainer>
              <GridContainer
                justify="space-around"
                alignItems="center"
                direction="row"
                style={{ marginTop: "20px" }}
              >
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    label="รายละเอียด"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    value={voteCardPurchaseRemark}
                    defaultValue={voteCardPurchaseRemark}
                    onChange={e => {
                      setVoteCardPurchaseRemark(e.target.value);
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          voteCardSendType === 1 || voteCardSendType === 2
                          //  voteCardSendType === 2
                        }
                        value={2}
                        name="radio-voteCardSendType"
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                        onClick={e => {
                          //console.log(e.target.value);
                          if (e.target.checked) {
                            setVoteCardSendType(2);
                          }
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="อัพโหลดอย่างเดียว"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={voteCardSendType === 3}
                        value={3}
                        name="radio-voteCardSendType"
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                        onClick={e => {
                          console.log(e.target.value);
                          if (e.target.checked) {
                            setVoteCardSendType(3);
                          }
                          console.log(voteCardSendType);
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="อัพโหลดและส่งข้อมูลไปยังส่วนกลาง"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} md={6} style={{ marginTop: "30px" }}>
                  <FileUpload
                    id="file-upload-2"
                    label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                    fileName={uploadFileName}
                    remove
                    onFileRemove={() => {
                      setUploadFileName("");
                      setUploadFileUUID("");
                    }}
                    url={getFileDownloadUrl("votecard", uploadFileUUID)}
                    download
                    onFileHandler={(e, f, p) => {
                      uploadFile("votecard", f, p)
                        .then(r => {
                          const file = r.data.data;
                          sweetAlerts.success("แนบไฟล์เรียบร้อย");
                          setUploadFileName(file.fileName);
                          setUploadFileUUID(file.fileId);
                        })
                        .catch(err => {
                          console.log(err);
                        });
                    }}
                    fileType="application/pdf"
                    btnName={<>Browse</>}
                    btnProps={{
                      color: "primary"
                    }}
                  />
                </GridItem>
              </GridContainer>
              <br /> <br /> <br />
              <GridContainer
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <GridItem>
                  <Button
                    disabled={disablePurchaseStatus}
                    color="info"
                    className={classes.buttoncustom}
                    onClick={updatePurchaseHandler}
                  >
                    บันทึกข้อมูล
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </form>
        </CardBody>
      </Card>
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <GridItem xs={6} sm={6} md={6}>
          <Button onClick={backtoMainHandler}>ย้อนกลับ</Button>
        </GridItem>
      </GridContainer>
    </>
  );
};
export default DetailPurchase;
