import React, { useContext, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.js";
import Slide from '@material-ui/core/Slide';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";


import { useSweetAlert } from "components/Alert/SweetAlert";
import { UserInfoContext } from "contexts/users";
import { changePassword } from "contexts/API/Login";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});



export default function ChangePassword({ open, setOpen }) {
    const classes = useStyles();
    const { sweetAlerts } = useSweetAlert();
    const { userInfo } = useContext(UserInfoContext);
    const [isShow, setIsShow] = useState(false);
    const [helpertextpass2, setHelpertextpass2] = useState("");
    const [helpertextoldpass, setHelpertextoldpass] = useState("");


    const [data, setData] = useState({
        password: "",
        oldPassword: "",
        confirmPassword: "",
        showPassword: false,
        showOldPassword: false
    });


    const handleClose = () => {
        setOpen(false);
    };

    const handleClickShowPassword = () => {
        setData({ ...data, showPassword: !data.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const handleClickShowOldPassword = () => {
        setData({ ...data, showOldPassword: !data.showOldPassword });
    };

    const handleMouseDownOldPassword = event => {
        event.preventDefault();
    };

    const changePass = () => {

        if (!data.oldPassword) {
            setHelpertextoldpass("กรุณาระบุ*");
        }
        if (!data.confirmPassword) {
            setHelpertextpass2("กรุณาระบุ*");
        }
        if (
            data.oldPassword &&
            data.password &&
            data.confirmPassword &&
            helpertextpass2 === ""
        ) {
            changePassword(data.oldPassword, data.password, userInfo.tokenInfo.data.uid)
                .then(r => {
                    sweetAlerts.success("เปลี่ยนรหัสผ่านเรียบร้อย");
                    setOpen(false);
                })
                .catch(err => {
                    console.log(err);
                    sweetAlerts.error("เกิดข้อผิดพลาด", err.response.data.desc);
                });
        }
    };


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"xs"}
                transition={Transition}
                keepMounted
                aria-labelledby="form-dialog-title"
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                        >
                            <VpnKeyIcon />
                        </IconButton>  <h4 >เปลี่ยนรหัสผ่าน</h4>
                    </Toolbar>
                </AppBar>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <TextField
                                        id="standard-full-width"
                                        className={classes.textfield}
                                        variant="outlined"
                                        type="password"
                                        placeholder="รหัสผ่านเดิม"
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: "Athiti, sans-serif",
                                                fontSize: 16
                                            }
                                        }}
                                        InputProps={{
                                            type: data.showOldPassword ? "text" : "password",
                                            value: data.oldPassword || "",
                                            autoComplete: "new-password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowOldPassword}
                                                        onMouseDown={handleMouseDownOldPassword}
                                                        edge="end"
                                                    >
                                                        {data.showOldPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            style: {
                                                fontSize: 16
                                            },
                                            onChange: e => {
                                                if (e.target.value) {
                                                    setData({ ...data, oldPassword: e.target.value });
                                                    setHelpertextoldpass("");
                                                } else {
                                                    setData({
                                                        ...data,
                                                        oldPassword: ""
                                                    });
                                                    setHelpertextoldpass("กรุณาระบุ*");
                                                }
                                            }
                                        }}
                                    />
                                    <p style={{ color: "red", textAlign: "left" }}>
                                        {helpertextoldpass}
                                    </p>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <TextField
                                        id="standard-full-width"
                                        className={classes.textfield}
                                        type={isShow ? "text" : "password"}
                                        variant="outlined"
                                        placeholder="รหัสผ่านใหม่"
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: "Athiti, sans-serif",
                                                fontSize: 16
                                            }
                                        }}
                                        InputProps={{
                                            type: data.showPassword ? "text" : "password",
                                            autoComplete: "new-password",
                                            value: data.password,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {data.showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            style: {
                                                fontSize: 16
                                            },
                                            onChange: e => {
                                                if (e.target.value) {
                                                    setData({ ...data, password: e.target.value });
                                                } else {
                                                    setData({
                                                        ...data,
                                                        password: ""
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <TextField
                                        id="standard-full-width"
                                        className={classes.textfield}
                                        // error={passError}
                                        type="password"
                                        variant="outlined"
                                        placeholder="ยืนยันรหัสผ่านใหม่"
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: "Athiti, sans-serif",
                                                fontSize: 16
                                            }
                                        }}
                                        InputProps={{
                                            autoComplete: "new-password",
                                            style: {
                                                fontSize: 16
                                            },
                                            onChange: e => {
                                                if (e.target.value) {
                                                    setData({ ...data, confirmPassword: e.target.value });
                                                    setHelpertextpass2("");
                                                    if (data.password !== e.target.value) {
                                                        setHelpertextpass2("รหัสผ่านไม่ตรงกัน");
                                                    } else {
                                                        setHelpertextpass2("");
                                                    }
                                                } else {
                                                    setData({
                                                        ...data,
                                                        confirmPassword: ""
                                                    });
                                                    setHelpertextpass2("กรุณาระบุ*");
                                                }
                                            }
                                        }}
                                    />
                                    <p style={{ color: "red", textAlign: "left" }}>{helpertextpass2}</p>
                                </GridItem>
                            </GridContainer>
                            <br></br>
                            <GridContainer
                                direction="column"
                                justify="flex-end"
                                alignItems="flex-end"
                            >
                                <GridItem xs={12} sm={12} md={12}>
                                    <Button onClick={handleClose}>ปิดหน้าต่าง</Button>
                                    <Button color="success" onClick={changePass}>
                                        เปลี่ยนรหัสผ่าน
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>

                </CardBody>
            </Dialog>
        </div>
    );
}
