import React, { useContext, useEffect, useState } from "react";
import { addoptPerson, checkDubApplyNo } from "contexts/API/OPT/OptPerson";

import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DatepickerVotedate from "components/Common/Datepicker";
import { FileUpload } from "components/Common/FileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxArea from "components/Common/ListboxArea";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxEducation from "components/Common/ListboxEducation";
import ListboxOptCareer from "components/Common/ListboxOptCareer";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxOpttype from "components/Common/ListboxOpttype";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxProvince from "components/Common/ListboxProvince.js";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
// Common
import ListboxTitle from "components/Common/ListboxOptTitleList";
import ListboxVotedate from "components/Common/ListboxVotedate";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import Radio from "@material-ui/core/Radio";
import TableHistoryAddr from "./TableHistoryAddr";
import TableHistoryName from "./TableHistoryName";
import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { VoteDateContext } from "contexts/VoteDate";
import { checkcitizenId } from "contexts/API/OPT/OptPerson";
//import getAge from "utils/CalculateAge";
import getAgePerson from "utils/CalculateAgeOpt";
//import common
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
//import { useSnackBar } from "components/Alert/Snackbar";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTextField-root": {
      //margin: theme.spacing(1),
      fontSize: "16px"
    }
  }
}));

const ManageOptPerson = ({ pageProps, setPageProps }) => {
  const { rowData, action } = pageProps.props;

  const { sweetAlerts } = useSweetAlert();

  const classes = useStyles();

  const backtoMainHandler = () => {
    setPageProps({ active: "OptPerson", action: "cancel" });
  };
  const [uploadFileName, setUploadFileName] = useState(null);
  let initialState = {
    applyNo: "",

    idCard: "",
    birthdate: null,
    registerDatetime: null,
    age: "",
    firstName: "",
    lastName: "",
    titleId: null,
    tel: "",
    addr1: "",
    careerTypeId: 0,
    addr1Postcode: null,
    // addr1ProvinceId: null,
    addr1AmphurId: null,
    addr1TambolId: null,
    educationalId: 0,
    genderId: null,
    from4_1UUID: null,
    personNameHistory: null,
    personAddrHistory: null,
    optPerson: {
      optVoteTypeId: null,
      optAreaId: null,
      voteDate: null,
      applyNo: null,
      registerDatetime: null,
      msOptId: null,
      from4_1UUID: null
    }
  };
  const {
    provinceSelected,
    amphurSelected,
    tambolSelected,

    onListboxOptSelected,

    onProvinceSelected,
    onAmphurSelected,
    onTambolSelected,

    optSelected,
    votedateSelected,
    optVoteTypeSelected,
    onVotedateSelected,

    onAreaSelected,
    onPostionTypeSelected,
    onOptSelected,
    onOptVoteTypeSelected,
    getOptTypeSelectedLists,
    optTypeSelected
  } = useContext(VoteDateContext);
  const optIdLists = getOptTypeSelectedLists();

  const [data, setData] = useState(initialState);
  const [namehistory, setNamehistory] = useState(null);
  const [addrhistory, setAddrhistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [oldData, setOldData] = useState([]);
  const [helpertextcareer, setHelpertextcareer] = useState("");
  const [postCodeState, setPostCodeState] = useState("");
  const [addr1ProvinceId, setAddr1ProvinceId] = useState({
    id: 0,
    value: ""
  });
  const [addr1AmphurId, setAddr1AmphurId] = useState({
    id: 0,
    value: ""
  });
  const [addr1TambolId, setAddr1TambolId] = useState({
    id: 0,
    value: ""
  });

  const [careerType, setCareerType] = useState({
    id: 0,
    value: ""
  });

  const [, setTitleId] = useState(null);
  const [isIdCardValid, setIsIdCardValid] = React.useState(false);
  const [applyNoState, setApplyNoState] = useState(false);

  const [lastNameState, setLastNameState] = useState("");
  const [firstNameState, setFirstNameState] = useState("");
  const [telState, setTelState] = useState("");
  const [addr1State, setAddr1State] = useState("");

  const [_position] = useState("");

  const [helpertext, setHelpertext] = useState("");
  const [helpertexttitle, setHelpertexttitle] = useState("");
  const [helpertexteducate, setHelpertexteducate] = useState("");
  const [helpertextGender, setHelpertextGender] = useState("");
  const onCareerType = (evt, newValue) => {
    setCareerType(newValue);
    setData({ ...data, careerTypeId: newValue ? newValue.id : 0 });
  };
  const onadd1ProvinceSelected = (evt, newValue) => {
    setAddr1ProvinceId(newValue);
    setAddr1AmphurId(null);
    setAddr1TambolId(null);
    setData({
      ...data,
      addr1ProvinceId: newValue ? newValue.id : 0,
      addr1ProvinceName: newValue ? newValue.value : ""
    });
  };
  const onadd1AmphurSelected = (evt, newValue) => {
    setAddr1AmphurId(newValue);
    setAddr1TambolId(null);
    setData({
      ...data,
      addr1AmphurId: newValue ? newValue.id : 0,
      addr1AmphurName: newValue ? newValue.value : ""
    });

    //  setData({ ...data, addr1AmphurId: newValue ? newValue.id : 0 });
  };
  const onadd1TambolSelected = (evt, newValue) => {
    setAddr1TambolId(newValue);

    setData({
      ...data,
      addr1TambolId: newValue ? newValue.id : 0,
      addr1TambolName: newValue ? newValue.value : ""
    });

    //  setData({ ...data, addr1TambolId: newValue.id });
  };

  useEffect(() => {
    if (action === "add") {
      setData({
        ...data,
        optPerson: {
          ...data.optPerson,
          optAreaId: rowData.areaId,
          optVoteTypeId: optVoteTypeSelected ? optVoteTypeSelected.id : 0,

          voteDate: votedateSelected ? votedateSelected.id : "",
          msOptId: rowData.msOptId ? rowData.msOptId : ""
        }
      });

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  async function fetchData(idCard) {
    try {
      setLoading(true);
      const person = await checkcitizenId(idCard);

      let chkdata = person.data.data;
      let data = person.data.data[0];
      //console.log("data >>", data);

      if (chkdata.length > 0 && chkdata[0] != null) {
        setLoading(false);
        setOldData(data);

        setData({
          ...data,
          applyNo: data.applyNo,
          idCard: idCard,
          registerDatetime: new Date(),
          age: getAgePerson(
            data.birthdate !== undefined ? data.birthdate : new Date(), votedateSelected.id
          ),
          optPerson: {
            ...data.optPerson,
            applyNo: data.applyNo,
            optAreaId: rowData.areaId,
            optVoteTypeId: optVoteTypeSelected ? optVoteTypeSelected.id : 0,
            registerDatetime: new Date(),
            voteDate: votedateSelected ? votedateSelected.id : "",
            msOptId: optSelected ? optSelected.id : ""
          }
        });
        setNamehistory(data.personNameHistory);
        setAddrhistory(data.personAddrHistory);
        setTitleId({ id: data.titleId });
        setAddr1ProvinceId({
          id: data.addr1ProvinceId,
          value: data.addr1ProvinceName
        });
        setAddr1AmphurId({
          id: data.addr1AmphurId,
          value: data.addr1AmphurName
        });
        setAddr1TambolId({
          id: data.addr1TambolId,
          value: data.addr1TambolName
        });
        setCareerType({ id: data.careerTypeId, value: data.careerTypeName });
        // setLoading(false);
      } else {
        sweetAlerts.warning("ไม่พบเลขประจำตัวประชาชนนี้ในระบบ");
        setData({
          ...initialState,
          idCard: idCard,
          registerDatetime: new Date(),

          optPerson: {
            // ...data.optPerson,
            optAreaId: rowData.areaId,
            optVoteTypeId: optVoteTypeSelected ? optVoteTypeSelected.id : 0,
            registerDatetime: new Date(),
            voteDate: votedateSelected ? votedateSelected.id : "",
            msOptId: optSelected ? optSelected.id : ""
          }
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      sweetAlerts.error("ไม่สามารถดึงข้อมูล", error.toString());
    }
  }

  // console.log("getOptTypeSelectedLists", getOptTypeSelectedLists);
  // let optTypes = getOptTypeSelectedLists();
  // console.log(getOptTypeSelectedLists().toString());
  useEffect(() => {
    if (Object.keys(oldData).length !== 0) {
      if (
        oldData.firstName !== data.firstName ||
        oldData.lastName !== data.lastName ||
        oldData.titleId !== data.titleId
      ) {
        let _personNameHistory = {
          oldFirstName: oldData.firstName,
          oldLastName: oldData.lastName,
          oldTitleId: oldData.titleId
        };
        setData({
          ...data,
          personNameHist: { ..._personNameHistory }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.firstName, data.lastName, data.titleId]);

  useEffect(() => {
    if (Object.keys(oldData).length !== 0) {
      if (
        oldData.addr1 !== data.addr1 ||
        oldData.addr1TambolId !== data.addr1TambolId
      ) {
        let _addr1History = {
          oldAddr1: oldData.addr1,
          oldAddr1TambolId: oldData.addr1TambolId
        };
        setData({
          ...data,
          addr1Hist: _addr1History
        });
        //  console.log("addr1Hist", data);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.addr1, data.addr1TambolId]);

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  const editOptPerson = () => {

    let age = getAgePerson(data.birthdate, votedateSelected.id);

    if (applyNoState === "") {
      setApplyNoState(false);
    }

    if (data.careerTypeId === 0) {
      setHelpertextcareer("กรุณาระบุอาชีพ*");
    }
    if (data.addr1Postcode === null) {
      setPostCodeState("กรุณาระบุรหัสไปรษณีย์*");
    }

    if (data.genderId === null) {
      setHelpertextGender("กรุณาระบุเพศ*");
    }
    if (lastNameState === "" && data.lastName === "") {
      setLastNameState(false);
    }
    if (firstNameState === "" && data.firstName === "") {
      setFirstNameState(false);
    }
    if (telState === "" && data.tel === "") {
      setTelState(false);
    }
    if (addr1State === "" && data.addr1 === "") {
      setAddr1State(false);
    }
    if (data.titleId === 0) {
      setHelpertexttitle("กรุณาระบุคำนำหน้าชื่อ*");
    }

    if (data.educationalId === 0 || data.educationalId === null) {
      setHelpertexteducate("กรุณาระบุระดับการศึกษา*");
    }

    if (telState === false) {
      sweetAlerts.warning(`กรุณาระบุเบอร์โทรศัพท์เป็นตัวเลขและความยาวมากกว่า 8 หลัก`);
      return false;
    }

    //if (getAge(data.birthdate) < 25) {
    if (age < 25 && rowData.optPositionTypeId === 2) {
      sweetAlerts.danger(`อายุต้องมากกว่า 25 ปี`);
      return false;
    }


    if (age < 35 && rowData.optPositionTypeId === 1) {
      sweetAlerts.danger(`อายุต้องมากกว่า 35 ปี`);
      return false;
    }

    if (!applyNoState) {
      sweetAlerts.warning(`หมายเลขผู้สมัครไม่ถูกต้อง`);
      return false;
    }
    // if (
    //   data.optPerson.from4_1UUID === undefined ||
    //   data.optPerson.from4_1UUID === null
    // ) {
    //   sweetAlerts.danger(`กรุณาแนบไฟล์ผู้สมัคร`);
    // }
    // console.log(data);

    /*console.log(applyNoState);
    console.log(helpertexttitle);
    console.log(helpertexteducate);

    console.log(data.addr1);
    console.log(data.tel);
    console.log(data.lastName);
    console.log(data.firstName);
    console.log(data.optPerson.applyNo);
    console.log(data.educationalId);
    console.log(data.addr1Postcode);*/
    console.log("console.log>", data.genderId)
    console.log("helpertextGender>", helpertextGender)
    if (
      applyNoState &&
      data.genderId !== null &&
      helpertexttitle === "" &&
      helpertexteducate === "" &&
      data.titleId &&
      data.addr1 &&
      data.tel &&
      data.lastName &&
      data.firstName &&
      data.optPerson.applyNo &&
      //getAge(data.birthdate) >= 25 &&
      data.educationalId &&
      // data.optPerson.from4_1UUID !== undefined &&
      data.addr1Postcode !== null
    ) {
      let tmp = data;

      if (rowData.optPositionTypeId === 1) {
        tmp.optPersonPres = tmp.optPerson;
        delete tmp.optPerson;
      }
      if (rowData.optPositionTypeId === 2) {
        tmp.optPersonMem = tmp.optPerson;
        delete tmp.optPerson;
      }
      // console.log("after----", tmp);

      // console.log("oldData", oldData);

      addoptPerson(data, oldData, optSelected.id, rowData.optPositionTypeId)
        .then(result => {
          sweetAlerts.success(`เพิ่มข้อมูลสำเร็จแล้ว`);
          setData(initialState);
          setAddr1ProvinceId(null);
          setData(initialState);
          backtoMainHandler();

          // resolve();
        })
        .catch(err => {
          // if (err.response.data.code !== 20000) {
          sweetAlerts.danger(`ไม่สามารถเพิ่มข้อมูลได้`, err.response.data.desc);
          // }
        });
    } else {
      console.log("applyNoState >> ", applyNoState);
      console.log("helpertexttitle >> ", helpertexttitle);
      console.log("helpertexteducate >> ", helpertexteducate);

      console.log("data.addr1 >> ", data.addr1);
      console.log("data.tel >> ", data.tel);
      console.log("data.lastName >> ", data.lastName);
      console.log("data.firstName >> ", data.firstName);
      console.log("data.optPerson.applyNo >> ", data.optPerson.applyNo);
      console.log("data.educationalId >> ", data.educationalId);
      console.log("data.addr1Postcode >> ", data.addr1Postcode);

      console.log("age 2 >> ", (age >= 25 && rowData.optPositionTypeId === 2));
      console.log("age 1 >> ", (age >= 35 && rowData.optPositionTypeId === 1));

      sweetAlerts.warning(`กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน`);
    }
  };

  async function checkDuplicate(e) {
    if (
      verifyNumber(e.target.value) &&
      e.target.value >= 1 &&
      e.target.value <= 99
    ) {
      let _applyNo = e.target.value;
      let param = "";

      if (rowData.areaNo) { //case ส.ถ.
        //param = `/opt/checkDubApplyNo?optId=${rowData.msOptId === null ? optSelected.id : rowData.msOptId
        //  }&voteDate=${votedateSelected.id}&positionType=${rowData.optPositionTypeId
        //  }&applyNo=${e.target.value}&areaNo=${rowData.areaNo}&voteTypeId=${optVoteTypeSelected.id
        //  }`;
        param = `/opt/checkDubApplyNo?optId=${rowData.msOptId === null ? optSelected.id : rowData.msOptId
          }&voteDate=${votedateSelected.id}&positionType=${rowData.optPositionTypeId
          }&applyNo=${e.target.value}&areaId=${rowData.areaId}&voteTypeId=${optVoteTypeSelected.id
          }`;
      } else {  //case ผ.ถ.
        param = `/opt/checkDubApplyNo?optId=${rowData.msOptId === null ? optSelected.id : rowData.msOptId
          }&voteDate=${votedateSelected.id}&positionType=${rowData.optPositionTypeId
          }&applyNo=${e.target.value}&voteTypeId=${optVoteTypeSelected.id}`;
      }

      const chk = await checkDubApplyNo(param);

      if (chk.data.data) {
        sweetAlerts.warning("หมายเลขซ้ำ");
        setApplyNoState(false);
        // setData({
        //   ...data,
        //   optPerson: {
        //     ...data.optPerson,
        //     applyNo: ""
        //   }
        // });
      } else {
        setApplyNoState(true);
        setData({
          ...data,
          applyNo: _applyNo,
          optPerson: {
            ...data.optPerson,
            applyNo: _applyNo
          }
        });
        // console.log("chkdup", data);
      }
    } else {
      setApplyNoState(false);
    }
  }

  const onIDCardHandler = v => {
    setData({ ...data, idCard: v.value });
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      if (isError) {
        sweetAlerts.danger("เลขประจำตัวประชาชนผิด");
        if (isIdCardValid === true) {
          setIsIdCardValid(false);
        }
      } else {
        if (isIdCardValid === false) {
          setIsIdCardValid(true);
        }
      }
      sweetAlerts.warning("กรุณากดปุ่ม ตรวจสอบ เพื่อตรวจสอบเลขประจำตัวประชาชนทุกครั้ง");
    } else {
      if (isIdCardValid === true) {
        setIsIdCardValid(false);
      }
    }
  };
  let content = <LoadingData />;
  if (loading === false) {
    content = (
      <>
        <form className={classes.root} noValidate autoComplete="off">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ width: "100%" }}>
                <CardHeader color="primary" text>
                  <CardText color="primary">
                    <h4>จัดการข้อมูลผู้สมัคร</h4>
                  </CardText>
                </CardHeader>

                <CardBody style={{ width: "100%" }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#2d00b3"
                            }}
                          >
                            ข้อมูล อปท.
                          </span>
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxOptVotetype
                        // value={[(id: "1")]}
                        disabled={true}
                        value={optVoteTypeSelected}
                        onChange={onOptVoteTypeSelected}
                      ></ListboxOptVotetype>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxOpttype
                        disabled={true}
                        // defaultValue={{ id: rowData.optSubTypeShortId }}
                        defaultValue={
                          rowData.optSubTypeShortId === null
                            ? { id: parseInt(optIdLists.toString()) }
                            : { id: rowData.optSubTypeShortId }
                        }
                        onChange={onListboxOptSelected}
                      ></ListboxOpttype>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxProvince
                        disabled={true}
                        controll={true}
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                      ></ListboxProvince>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxDistinct
                        disabled={true}
                        value={amphurSelected}
                        provinceSelected={provinceSelected}
                        onChange={onAmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxSubdistinct
                        disabled={true}
                        controll={true}
                        amphurSelected={amphurSelected}
                        value={tambolSelected}
                        onChange={onTambolSelected}
                      ></ListboxSubdistinct>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      {/* <ListboxOpts
                        value={optSelected}
                        listboxOptSelected={listboxOptSelected}
                        provinceSelected={provinceSelected}
                        amphurSelected={amphurSelected}
                        tambolSelected={tambolSelected}
                        onChange={onOptSelected}
                      ></ListboxOpts> */}

                      <ListboxOpts
                        disabled={true}
                        getOptTypeSelectedLists={getOptTypeSelectedLists}
                        optTypeSelected={optTypeSelected}
                        defaultValue={optSelected}
                        provinceSelected={provinceSelected}
                        amphurSelected={amphurSelected}
                        tambolSelected={tambolSelected}
                        onChange={onOptSelected}
                      ></ListboxOpts>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxPositionType
                        disabled={true}
                        defaultValue={{ id: rowData.optPositionTypeId }}
                        onChange={onPostionTypeSelected}
                      ></ListboxPositionType>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxVotedate
                        disabled={true}
                        value={votedateSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onVotedateSelected}
                      ></ListboxVotedate>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxArea
                        disabled={true}
                        defaultValue={{ id: `${rowData.areaId}` }}
                        optSelected={optSelected}
                        positionTypeSelected={_position}
                        optVoteTypeSelected={optVoteTypeSelected}
                        votedateSelected={votedateSelected}
                        onChange={onAreaSelected}
                      ></ListboxArea>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>
                </CardBody>

                <CardBody style={{ width: "100%" }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#2d00b3"
                            }}
                          >
                            ข้อมูลส่วนบุคคล{" "}
                          </span>
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <NumberFormat
                        style={{
                          width: "100%",
                          margin: "0 0 17px 0",
                          paddingTop: "27px"
                        }}
                        value={data.idCard}
                        placeholder="เลขประจำตัวประชาชน"
                        customInput={TextField}
                        format="# #### ##### ## #"
                        mask="X"
                        fullWidth
                        onValueChange={onIDCardHandler}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <Button
                        disabled={!isIdCardValid}
                        variant="contained"
                        color="success"
                        onClick={() => fetchData(data.idCard)}
                      >
                        ตรวจสอบ
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="applyNo"
                        success={applyNoState === true || data.applyNo !== ""}
                        error={applyNoState === false}
                        default
                        required
                        labelText="หมายเลขผู้สมัคร"
                        formControlProps={{
                          fullWidth: true
                        }}
                        helpertext="กรุณาระบุหมายเลขผู้สมัคร*"
                        inputProps={{
                          autoComplete: "new-password",
                          defaultValue: data.applyNo,
                          onChange: checkDuplicate
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <DatepickerVotedate
                        name="registerDatetime"
                        label="วันที่รับสมัคร"
                        value={data.registerDatetime}
                        onChange={date => {
                          let arr = date.format().split("T");
                          setData({
                            ...data,
                            registerDatetime: arr[0],
                            optPerson: {
                              ...data.optPerson,
                              registerDatetime: arr[0]
                            }
                          });
                        }}
                      ></DatepickerVotedate>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <DatepickerVotedate
                        name="birthday"
                        label="วัน/เดือน/ปี เกิด"
                        value={data.birthdate}
                        onChange={date => {
                          if (date) {
                            let arr = date.format().split("T");
                            let calAge = getAgePerson(date.format(), votedateSelected.id);
                            console.log("rowData.optPositionTypeId >> ", rowData.optPositionTypeId);
                            console.log("calAge >> ", calAge);

                            if (calAge < 25 && rowData.optPositionTypeId === 2) {
                              sweetAlerts.warning(`อายุต้องมากกว่า 25 ปี`);
                            } else if (calAge < 35 && rowData.optPositionTypeId === 1) {
                              sweetAlerts.warning(`อายุต้องมากกว่า 35 ปี`);
                            } else {
                              setData({
                                ...data,
                                birthdate: arr[0],
                                age: calAge
                              });
                            }
                          }
                        }}
                      ></DatepickerVotedate>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="age"
                        labelText="อายุ"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: data.age || "",
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                  >

                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              data.genderId === "true" || data.genderId === true
                            }
                            value={true}
                            onClick={e => {
                              if (e.target.checked) {
                                setHelpertextGender("");
                              } else {
                                setHelpertextGender("กรุณาระบุเพศ*");
                              }
                              setData({ ...data, genderId: e.target.value });
                            }}
                            // onClick={
                            //   e =>
                            //     setData({ ...data, genderId: e.target.value })
                            //   //  handleGenderChange(e, "genderId")
                            // }
                            id="genderId"
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        label="ชาย"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            onClick={e => {
                              if (e.target.checked) {
                                setHelpertextGender("");
                                //setGenderState(true);
                              } else {
                                setHelpertextGender("กรุณาระบุเพศ*");
                                // setGenderState(false);
                              }
                              setData({ ...data, genderId: e.target.value });
                            }}
                            // onClick={
                            //   e =>
                            //     setData({ ...data, genderId: e.target.value })
                            //   // e => handleGenderChange(e, "genderId")
                            // }
                            checked={
                              data.genderId === "false" ||
                              data.genderId === false
                            }
                            value={false}
                            id="genderId"
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        label="หญิง"
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextGender}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxTitle
                        defaultValue={{ id: data.titleId }}
                        onChange={(evt, newValue) => {
                          setTitleId(newValue);

                          if (newValue) {
                            setHelpertexttitle("");

                            setData({
                              ...data,
                              titleId: newValue ? newValue.id : 0
                            });
                          } else {
                            setHelpertexttitle("กรุณาระบุคำนำหน้าชื่อ*");
                          }
                        }}
                      ></ListboxTitle>

                      <FormHelperText style={{ color: "red" }}>
                        {helpertexttitle}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          firstNameState === true || data.lastName !== ""
                        }
                        error={firstNameState === false}
                        id="firstName"
                        default
                        required
                        labelText="ชื่อ"
                        formControlProps={{
                          fullWidth: true
                        }}
                        helpertext="กรุณาระบุชื่อ*"
                        inputProps={{
                          value: data.firstName,
                          // value: "ddddd",
                          onChange: e => {
                            if (verifyLength(e.target.value, 1)) {
                              setFirstNameState(true);
                            } else {
                              setFirstNameState(false);
                            }
                            setData({ ...data, firstName: e.target.value });
                          }
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="lastName"
                        success={lastNameState === true || data.lastName !== ""}
                        error={lastNameState === false}
                        default
                        required
                        labelText="นามสกุล"
                        helpertext="กรุณาระบุนามสกุล*"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // value: "sss",
                          value: data.lastName,
                          onChange: e => {
                            if (verifyLength(e.target.value, 1)) {
                              setLastNameState(true);
                            } else {
                              setLastNameState(false);
                            }
                            setData({ ...data, lastName: e.target.value });
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="tel"
                        success={telState === true || data.tel !== ""}
                        error={telState === false}
                        default
                        required
                        labelText="เบอร์โทรศัพท์"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: data.tel,
                          //  value: "0123456789",
                          onChange: e => {
                            if (
                              verifyNumber(e.target.value) &&
                              verifyLength(e.target.value, 9)
                            ) {
                              setTelState(true);
                            } else {
                              setTelState(false);
                            }
                            setData({ ...data, tel: e.target.value });
                          }
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={addr1State === true || data.addr1 !== ""}
                        error={addr1State === false}
                        helpertext="กรุณาระบุที่อยู่ตามทะเบียนบ้าน*"
                        id="addr1"
                        default
                        required
                        labelText="ที่อยู่ตามทะเบียนบ้าน"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: data.addr1,
                          // value: "ddddd",
                          onChange: e => {
                            if (verifyLength(e.target.value, 1)) {
                              setAddr1State(true);
                            } else {
                              setAddr1State(false);
                            }
                            setData({ ...data, addr1: e.target.value });
                            //  handleChange(e, "addr1");
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxProvince
                        validators={[MustNotNull]}
                        value={addr1ProvinceId}
                        controll={true}
                        disabled={false}
                        required={true}
                        // defaultValue={{
                        //   id: data.addr1ProvinceId || "" || undefined
                        // }}
                        onChange={onadd1ProvinceSelected}
                      ></ListboxProvince>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxDistinct
                        validators={[MustNotNull]}
                        value={addr1AmphurId}
                        controll={true}
                        required={true}
                        // defaultValue={{
                        //   id: data.addr1AmphurId || "" || undefined
                        // }}
                        provinceSelected={addr1ProvinceId}
                        onChange={onadd1AmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxSubdistinct
                        validators={[MustNotNull]}
                        value={addr1TambolId}
                        required={true}
                        // defaultValue={{
                        //   id: data.addr1TambolId || "" || undefined
                        // }}
                        provinceSelected={addr1ProvinceId}
                        amphurSelected={addr1AmphurId}
                        onChange={onadd1TambolSelected}
                      ></ListboxSubdistinct>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <NumberFormat
                        style={{
                          width: "100%",
                          margin: "0 0 17px 0",
                          paddingTop: "27px"
                        }}
                        value={data.addr1Postcode}
                        placeholder="รหัสไปรษณีย์"
                        customInput={TextField}
                        format="#####"
                        mask="X"
                        fullWidth
                        onValueChange={v => {
                          if (v.value) {
                            setPostCodeState("");
                          } else {
                            setPostCodeState("กรุณาระบุรหัสไปรษณีย์");
                          }
                          setData({ ...data, addr1Postcode: v.value });
                        }}
                      />
                      <label style={{ color: "red" }}>{postCodeState}</label>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxOptCareer
                        validators={[MustNotNull]}
                        value={careerType}
                        onChange={onCareerType}
                        controll={true}
                      ></ListboxOptCareer>
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextcareer}
                      </FormHelperText>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#2d00b3"
                            }}
                          >
                            วุฒิการศึกษาสูงสุด
                          </span>
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxEducation
                        defaultValue={{ id: data.educationalId }}
                        onChange={(evt, newValue) => {
                          if (newValue) {
                            setHelpertexteducate("");

                            setData({
                              ...data,
                              educationalId: newValue ? newValue.id : 0
                            });
                          } else {
                            setHelpertexteducate("กรุณาระบุระดับการศึกษา");
                          }
                        }}
                      ></ListboxEducation>
                      <FormHelperText style={{ color: "red" }}>
                        {helpertexteducate}
                      </FormHelperText>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#2d00b3"
                            }}
                          >
                            {" "}
                            เอกสารผู้สมัคร{" "}
                          </span>
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <FileUpload
                        id="file-upload-1"
                        label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                        fileName={uploadFileName}
                        remove
                        onFileRemove={() => {
                          setUploadFileName("");
                        }}
                        url={getFileDownloadUrl("opt", data.from4_1UUID)}
                        download
                        onFileHandler={(e, f, p) => {
                          uploadFile("opt", f, p)
                            .then(r => {
                              const file = r.data.data;
                              sweetAlerts.success("แนบไฟล์เรียบร้อย");
                              setUploadFileName(file.fileName);
                              //  setData({ ...data, from4_1UUID: file.fileId });

                              setData({
                                ...data,
                                optPerson: {
                                  ...data.optPerson,
                                  from4_1UUID: file.fileId
                                }
                              });
                            })
                            .catch(err => {
                              console.log(err);
                              sweetAlerts.danger(`ไม่สามารถแนบไฟล์ได้`, err.response.data.desc);
                            });
                        }}
                        fileType="application/pdf"
                        btnName={<>Browse</>}
                        btnProps={{
                          color: "primary"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <GridItem xs={6} sm={6} md={6}></GridItem>
                    <GridItem>
                      <Button onClick={backtoMainHandler}>
                        กลับไปหน้าจัดการข้อมูลผู้สมัคร
                      </Button>
                      <Button color="success" onClick={editOptPerson}>
                        บันทึกข้อมูลผู้สมัคร
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <h4>ประวัติการเปลี่ยนแปลงชื่อ</h4>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TableHistoryName history={namehistory} />
            </GridItem>
          </GridContainer>

          <h4>ประวัติการเปลี่ยนแปลงที่อยู่</h4>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TableHistoryAddr history={addrhistory} />
            </GridItem>
          </GridContainer>
        </form>
      </>
    );
  }
  return content;
};
export default ManageOptPerson;
