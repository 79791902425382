import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
//-------------มหาลัย-----------------//

export function getCountry() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/country`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getUniversity() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/university`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editUniversity(universityId, universityName, countryId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/university/${universityId}`;

  const body = {
    universityName: universityName,
    countryId: countryId
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteUniversity(universityId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/university/${universityId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addUniversity(universityName, countryId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/university`;

  const body = {
    universityName: universityName,
    countryId: countryId
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//-------------สาขาวิชา-----------------//

export function getEducationMajor() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/educationMajor`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editEducationMajor(eduMajorId, eduMajorName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/educationMajor/${eduMajorId}`;

  const body = {
    eduMajorName: eduMajorName,
    shortTH: null,
    shortEN: null,
    eduLevel: 0
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteEducationMajor(eduMajorId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/educationMajor/${eduMajorId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addEducationMajor(eduMajorName, orgName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/educationMajor`;

  const body = {
    eduMajorName: eduMajorName,
    shortTH: null,
    shortEN: null,
    eduLevel: 0
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//------------วุฒิการศกษา-----------------//

export function getEducation() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/education`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editEducation(educationId, detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/education/${educationId}`;

  const body = {
    detail: detail
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteEducation(educationId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/education/${educationId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addEducation(detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/education`;

  const body = {
    detail: detail
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//---------------อายุ-----------

export function getAge() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/ageRank`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editAge(ageRankId, startAge, endAge) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/ageRank/${ageRankId}`;

  const body = {
    startAge: startAge,
    endAge: endAge,
    detail: startAge + "-" + endAge
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteAge(ageRankId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/ageRank/${ageRankId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addAge(startAge, endAge) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/ageRank`;

  const body = {
    startAge: startAge,
    endAge: endAge,
    detail: startAge + "-" + endAge
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//--------------อาชีพ------------------//

export function getCareer() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/careerlist`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editCareer(careerTypeId, detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/career/${careerTypeId}`;

  const body = {
    detail: detail,
    systemTypeId: 4
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteCareer(careerTypeId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/career/${careerTypeId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addCareer(detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/career`;

  const body = {
    detail: detail,
    systemTypeId: 4
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

//--------------คำนำหน้าชื่อ------------------//
export function getSystem() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/systemType`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getTitle() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/titlelist`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editTitle(
  titleId,
  titleShortName,
  titleLongName,
  gender,
  system
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/title/${titleId}`;

  const body = {
    titleShortName: titleShortName,
    titleLongName: titleLongName,
    gender: gender === undefined ? 3 : gender,
    system: system === undefined ? 0 : system
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteTitle(titleId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/title/${titleId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addTitle(titleShortName, titleLongName, gender, system) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/title`;

  const body = {
    titleShortName: titleShortName,
    titleLongName: titleLongName,
    gender: gender === undefined ? 3 : gender,
    system: system === undefined ? 0 : system
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

//----
