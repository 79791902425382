import React, { useState } from "react";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import Checkbox from "@material-ui/core/Checkbox";
//import CustomInput from "components/CustomInput/CustomInput.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import MultipleSelectionProvince from "components/Common/MultipleSelectionProvince";
import TextField from "@material-ui/core/TextField";
import { addPopup } from "contexts/API/CommonSetting/Notification";
import { useSweetAlert } from "components/Alert/SweetAlert";

// import ModaladdUser from "./ModaladdUser";
// import ModaleditUser from "./ModaleditUser";

const PopUpAdd = props => {
  const { onaddSuccess } = props;
  const { sweetAlerts } = useSweetAlert();
  const initialFormState = {
    id: null,
    header: "",
    body: "",
    provinceId: "",
    redirectUrl: "#",
    systemTypeId: 1,
    pageId: "1",
    allProvince: false,
    isCenter: false
  };
  const [project, setProject] = useState(initialFormState);

  const [selectedProvince, setSelectedProvince] = useState([]);
  const [allProince, setAllProince] = useState(false);
  const [, setSystemSelected] = useState(null);

  // const onSystemSelected = (e, newValue) => {
  //   if (newValue.id !== 0) {
  //     setSystemSelected(newValue);
  //     setProject({ ...project, systemTypeId: newValue.id });
  //   }
  // };

  const handelSubmit = () => {
    if (selectedProvince.length === 0 && allProince === false) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return false;
    }
    // if (!systemSelected) {
    //   sweetAlerts.warning("กรุณาเลือกระบบ");
    //   return false;
    // }
    if (!project.body) {
      sweetAlerts.warning("กรุณาระบุข้อความ");
      return false;
    }
    if (!project.header) {
      sweetAlerts.warning("กรุณาระบุหัวข้อ");
      return false;
    } else {
      addPopup(project)
        .then(result => {
          if (result.data.success) {
            onaddSuccess(result.data.data);
            setProject(initialFormState);
            setSelectedProvince([]);
            //setSelectedProvince({ id: 0 });

            setSystemSelected(null);

            //  onfile4Success(result.data.data.command_upload_id);
            sweetAlerts.success("เพิ่มข้อมูลเรียบร้อย");
          }
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
        });
    }
  };

  return (
    <Card>
      <CardHeader color="warning" text>
        <CardText color="warning">
          <h4>Popup Setup</h4>
        </CardText>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              name="header"
              label="หัวข้อ"
              fullWidth
              formControlProps={{
                fullWidth: true
              }}
              InputProps={{
                value: project.header || "",
                onChange: e => {
                  setProject({
                    ...project,
                    header: e.target.value
                  });
                }
                // disabled: true
              }}
            />

            {/* <CustomInput
              name="header"
              labelText="หัวข้อ"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: project.header || "",
                onChange: e => {
                  setProject({
                    ...project,
                    header: e.target.value
                  });
                }
              }}
            /> */}
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <TextField
              name="body"
              label="รายละเอียด"
              fullWidth
              formControlProps={{
                fullWidth: true
              }}
              InputProps={{
                multiline: true,
                rows: 4,

                value: project.body || "",
                onChange: e => {
                  setProject({
                    ...project,
                    body: e.target.value
                  });
                }
                // disabled: true
              }}
            />

            {/* <CustomInput
              name="body"
              labelText="ข้อความ"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                multiline: true,
                rows: 4,

                value: project.body || "",
                onChange: e => {
                  setProject({
                    ...project,
                    body: e.target.value
                  });
                }
                // disabled: true
              }}
            /> */}
          </GridItem>

          <GridItem xs={12} sm={12} md={12} style={{ marginTop: "30px" }}>
            <MultipleSelectionProvince
              disabled={allProince}
              value={selectedProvince}
              onChange={(event, index, values) => {
                setProject({
                  ...project,
                  provinceId: index.map(x => x.id)
                });
                setSelectedProvince(index.map(x => x.id));
              }}
            ></MultipleSelectionProvince>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "start" }}>
            <FormControlLabel
              label="เลือกทุกจังหวัด"
              control={
                <Checkbox
                  checked={project.allProvince}
                  color="primary"
                  onClick={e => {
                    if (e.target.checked) {
                      setProject({
                        ...project,
                        allProvince: e.target.checked,
                        provinceId: "all"
                      });
                      setAllProince(true);
                    } else {
                      setProject({
                        ...project,
                        allProvince: e.target.checked,
                        provinceId: selectedProvince
                      });
                      setAllProince(false);
                    }
                  }}
                />
              }
              // labelPlacement="start"
              styles={{ paddingTop: "20px" }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "start" }}>
            <FormControlLabel
              label="ส่วนกลาง"
              control={
                <Checkbox
                  checked={project.isCenter}
                  color="primary"
                  onClick={e => {
                    setProject({
                      ...project,
                      isCenter: e.target.checked
                    });
                  }}
                />
              }
              // labelPlacement="start"
              styles={{ paddingTop: "20px" }}
            />
          </GridItem>

          {/* <GridItem xs={12} sm={12} md={12} style={{ marginTop: "30px" }}>
            <ListboxSystemList
              value={systemSelected}
              onChange={onSystemSelected}
            />
          </GridItem> */}
        </GridContainer>
        <GridContainer
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <GridItem xs={6} sm={6} md={6}></GridItem>
          <GridItem>
            <Button color="success" onClick={handelSubmit}>
              เพิ่ม
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};
export default PopUpAdd;
