/* core components */
import React, { useContext, useEffect, useState } from "react";

import AddFault from "./AddFault";
import AddFile from "./AddFile";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputWithValidator from "components/CustomInput/CustomInputWithValidator";
import Datepicker from "components/Common/Datepicker";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import InputLabel from "@material-ui/core/InputLabel";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import ListboxTitle from "components/Common/ListboxTitle";
import ListboxWtdOrg from "components/Common/ListboxWtdOrg";
import ListboxWtdStatus from "components/Common/ListboxWtdStatus";
import ListboxWtdVoteType from "components/Common/ListboxWtdVoteType";
import ListboxWtdYear from "components/Common/ListboxWtdYear";
import LoadingData from "components/Common/LoadingData";
import MenuItem from "@material-ui/core/MenuItem";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { UserInfoContext } from "contexts/users";
import WtdEndDate from "utils/WtdEndDate";
//import { allGridColumnsSelector } from "@material-ui/data-grid";
import { editWtdPerson } from "contexts/API/WTD/Listwtd";
import getAge from "utils/CalculateAge";
import { getWtdPerson } from "contexts/API/WTD/Listwtd";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { wsWTDUpdWtdPerson } from "contexts/API/DOPA/WS";
import ConvertDateFormat from "utils/ConvertDateFormat";

const EditWtdPerson = ({ pageProps, setPageProps }) => {
  const useStyles = makeStyles({});

  //const { sweetAlerts } = useSweetAlert();
  const { sweetAlerts } = useSweetAlert();

  const [data, setData] = useState([]);
  const [dataLaw, setDataLaw] = useState([]);
  const [dataFile, setDataFile] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [helpertextGender, setHelpertextGender] = useState(null);
  const [, setHelpertexttitle] = useState("");
  const classes = useStyles();

  const [addr1ProvinceId, setAddr1ProvinceId] = useState({
    id: 0,
    value: ""
  });
  const [addr1AmphurId, setAddr1AmphurId] = useState({
    id: 0,
    value: ""
  });
  const [addr1TambolId, setAddr1TambolId] = useState({
    id: 0,
    value: ""
  });

  const { userInfo } = useContext(UserInfoContext);

  const onadd1ProvinceSelected = (evt, newValue) => {
    setAddr1ProvinceId(newValue);
    setAddr1AmphurId(null);
    setAddr1TambolId(null);
    setData({ ...data, provinceId: newValue ? newValue.id : 0 });
  };
  const onadd1AmphurSelected = (evt, newValue) => {
    setAddr1AmphurId(newValue);
    setAddr1TambolId(null);
    setData({ ...data, amphurId: newValue ? newValue.id : 0 });
  };
  const onadd1TambolSelected = (evt, newValue) => {
    setAddr1TambolId(newValue);
    setData({ ...data, addr1TambolId: newValue ? newValue.id : 0 });
  };

  const backtoMainHandler = () => {
    setPageProps({ active: "ListWtdPerson", action: "cancel" });
  };

  useEffect(() => {
    setIsLoading(true);
    getWtdPerson(pageProps.props.rowData)
      .then(result => {
        const value = result.data.data.wtdPerson[0];
        // console.log(value);
        if (Object.keys(value).length > 0) {
          // console.log(value);
          setIsLoading(false);
          setData(result.data.data.wtdPerson[0]);

          setDataLaw(result.data.data.wtdLaw);
          setDataFile(result.data.data.wtdAttatchFile);
          setAddr1ProvinceId({
            id: result.data.data.wtdPerson[0].provinceId,
            value: result.data.data.wtdPerson[0].provinceName
          });
          setAddr1AmphurId({
            id: result.data.data.wtdPerson[0].amphur_id,
            value: result.data.data.wtdPerson[0].amphurName
          });
          setAddr1TambolId({
            id: result.data.data.wtdPerson[0].addr1TambolId,
            value: result.data.data.wtdPerson[0].tambolName
          });
        }
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      let _wtdPersonInfo = {
        voteTypeId: data.voteTypeId,
        wtdSdate: data.wtdSdate,
        wtdEdate: data.wtdEdate,
        wtdYear: data.wtdYear,
        wtdStatusId: data.wtdStatusId,
        wtdOrgId: data.wtdOrgId,
        wtdCommandDetail: data.wtdCommandDetail,
        earlyExpireDetail: data.earlyExpireDetail,
        earlyExpireDate: data.earlyExpireDate,
        lawsuitNumber: data.lawsuitNumber
      };
      setData({
        ...data,
        wtdPersonInfo: { ..._wtdPersonInfo }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.voteTypeId,
    data.wtdSdate,
    data.wtdEdate,
    data.wtdYear,
    data.wtdCommandDetail,
    data.wtdStatusId,
    data.wtdOrgId,
    data.earlyExpireDetail,
    data.earlyExpireDate,
    data.lawsuitNumber
  ]);

  const handelSubmit = () => {

    console.log("data >>>>", data);

    if (data.genderId === null) {
      setHelpertextGender("กรุณาระบุ*");
      return false;
    }
    if (
      data.wtdStatusId === 2 &&
      (data.earlyExpireDetail === null || data.earlyExpireDate === null)
    ) {
      sweetAlerts.warning("กรุณาระบุหมายเหตุ และ วันที่เสียชีวิตหรือยกคำร้อง");
      return false;
    }
    if (
      data.genderId !== null &&
      data.addr1TambolId &&
      data.firstName &&
      data.lastName &&
      data.titleId &&
      data.provinceId &&
      data.amphurId &&
      data.birthdate
    ) {
      editWtdPerson(data.wtdPersonId, data)
        .then(r => {
          // sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
          console.log("wtdSdate >>>", ConvertDateFormat(data.wtdSdate));
          console.log("wtdEdate >>>", ConvertDateFormat(data.wtdEdate));

          /*---- start call web service Dopa -----*/
          console.log("Call service wsWTDUpdWtdPerson success ");
          wsWTDUpdWtdPerson(data.idCard,
            data.wtdStatusId,
            ConvertDateFormat(data.wtdSdate),
            ConvertDateFormat(data.wtdEdate)
          ).then(r => {
            console.log("Call service wsWTDUpdWtdPerson success ");
          })
            .catch(err => {
              console.log("Call service wsWTDUpdWtdPerson Error :" + err);
            });
          /*---- end call web service Dopa -----*/
          let desc =
            "*กรุณาระบุ พ.ร.บ.และมาตราที่กระทำผิด และเอกสารที่กระทำผิด";

          sweetAlerts.success(
            `บันทึกข้อมูลสำเร็จ`,
            <p style={{ color: "red", fontSize: "24px", fontWeight: "bolder" }}>
              {desc}
            </p>
          );
          //setPageProps({ active: "ListWtdPerson", props: "editsuccess" });
          //setPageProps({ active: "ListWtdPerson", action: "cancel" });
        })
        .catch(error => {
          if (error.response) {
            sweetAlerts.error(
              "ไม่สามารแก้ไขข้อมูลได้ ",
              error.response.data.desc
            );
          }
        });
    } else {
      sweetAlerts.warning("กรุณากรอกข้อมูลให้ครบถ้วน");
    }
  };

  let content = <LoadingData />;
  if (isLoading === false) {
    content = (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>
                    แก้ไขข้อมูลผู้ถูกเพิกถอน
                  </h4>
                </CardText>
              </CardHeader>

              <CardHeader>
                <h4 className={classes.cardTitle}>ข้อมูลส่วนบุคคล</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <NumberFormat
                            placeholder="เลขประจำตัวประชาชน"
                            customInput={TextField}
                            format="# #### ##### ## #"
                            mask="X"
                            fullWidth
                            value={data.idCard}
                            readOnly={true}
                            disabled={true}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Button color="info" disabled={true}>
                            ตรวจสอบ
                          </Button>
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControlLabel
                            disabled={
                              [2, 5].includes(data.wtdStatusId) ? true : false
                            }
                            control={
                              <Radio
                                checked={
                                  data.genderId === "true" ||
                                  data.genderId === true
                                }
                                value={true}
                                onClick={e => {
                                  if (e.target.checked) {
                                    setHelpertextGender("");
                                  } else {
                                    setHelpertextGender("กรุณาระบุ*");
                                  }
                                  setData({
                                    ...data,
                                    genderId: e.target.value
                                  });
                                }}
                                name="gender_id"
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot
                            }}
                            label="ชาย"
                          />
                          <FormControlLabel
                            disabled={
                              [2, 5].includes(data.wtdStatusDetail)
                                ? true
                                : false
                            }
                            control={
                              <Radio
                                onClick={e => {
                                  if (e.target.checked) {
                                    setHelpertextGender("");
                                  } else {
                                    setHelpertextGender("กรุณาระบุ*");
                                  }
                                  setData({
                                    ...data,
                                    genderId: e.target.value
                                  });
                                }}
                                checked={
                                  data.genderId === "false" ||
                                  data.genderId === false
                                }
                                value={false}
                                name="genderId"
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot
                            }}
                            label="หญิง"
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {helpertextGender}
                          </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          {/* คำนำหน้าชื่อ */}
                          <ListboxTitle
                            disabled={
                              [2, 5].includes(data.wtdStatusId) ? true : false
                            }
                            defaultValue={{ id: data.titleId }}
                            onChange={(evt, newValue) => {
                              if (newValue) {
                                setData({
                                  ...data,
                                  titleId: newValue ? newValue.id : 0
                                });
                              } else {
                                setHelpertexttitle("กรุณาระบุ");
                              }
                            }}
                          ></ListboxTitle>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInputWithValidator
                            validators={[MustNotNull]}
                            labelText="ชื่อ"
                            formControlProps={{
                              fullWidth: true
                            }}
                            style={{ fontSize: "18px !important " }}
                            inputProps={{
                              disabled: [2, 5].includes(data.wtdStatusId)
                                ? true
                                : false,
                              value: data.firstName || "",
                              onChange: e => {
                                setData({
                                  ...data,
                                  firstName: e.target.value
                                });
                              }
                              // disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInputWithValidator
                            validators={[MustNotNull]}
                            labelText="นามสกุล"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: [2, 5].includes(data.wtdStatusId)
                                ? true
                                : false,
                              value: data.lastName || "",
                              onChange: e => {
                                setData({ ...data, lastName: e.target.value });
                              }
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <br></br>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInputWithValidator
                            validators={[MustNotNull]}
                            labelText="ที่อยู่ตามบัตรประชาขน"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: [2, 5].includes(data.wtdStatusId)
                                ? true
                                : false,
                              value: data.addr1 || "",
                              onChange: e => {
                                setData({ ...data, addr1: e.target.value });
                              }
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxProvince
                            disabled={
                              [2, 5].includes(data.wtdStatusId) ? true : false
                            }
                            required={true}
                            validators={[MustNotNull]}
                            value={addr1ProvinceId}
                            // defaultValue={{
                            //   id: data.provinceId
                            // }}
                            onChange={onadd1ProvinceSelected}
                          ></ListboxProvince>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxDistinct
                            required={true}
                            // controll={true}
                            disabled={
                              [2, 5].includes(data.wtdStatusId) ? true : false
                            }
                            validators={[MustNotNull]}
                            value={addr1AmphurId}
                            // defaultValue={{
                            //   id: data.amphurId
                            // }}
                            // value={{
                            //   id: data.amphurId,
                            //   value: data.amphurName
                            // }}
                            provinceSelected={{ id: data.provinceId }}
                            onChange={onadd1AmphurSelected}
                          ></ListboxDistinct>
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxSubdistinct
                            required={true}
                            // controll={true}
                            disabled={
                              [2, 5].includes(data.wtdStatusId) ? true : false
                            }
                            amphurSelected={{ id: data.amphurId }}
                            // provinceSelected={{ id: data.provinceId }}
                            validators={[MustNotNull]}
                            value={addr1TambolId}
                            // defaultValue={{
                            //   id: data.addr1TambolId
                            // }}
                            onChange={onadd1TambolSelected}
                          ></ListboxSubdistinct>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}></GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <Datepicker
                            disabled={
                              [2, 5].includes(data.wtdStatusId) ? true : false
                            }
                            id="begin"
                            label="วันเดือนปีเกิด"
                            value={data.birthdate}
                            onChange={date => {
                              if (date) {
                                let arr = date.format().split("T");
                                setData({
                                  ...data,
                                  birthdate: arr[0],
                                  age: getAge(date.format())
                                });
                              }
                            }}
                          ></Datepicker>{" "}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          {/* <div style={{ marginTop: "15px" }}> */}

                          <CustomInput
                            labelText="อายุ"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              value: getAge(data.birthdate) || "",
                              disabled: true
                            }}
                          />

                          {/* </div> */}
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                      </GridContainer>
                      <br /> <br />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/* ข้อมูลเพิกถอนสิทธิ */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <h4 className={classes.cardTitle}>ข้อมูลเพิกถอนสิทธิ</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="หมายเลขคดีอุทธรณ์/ฏีกา"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: [2, 5].includes(data.wtdStatusId) ? true : false,
                            autoComplete: "new-password",
                            value: data.lawsuitNumber,
                            onChange: e => {
                              if (e.target.value) {
                                setData({
                                  ...data,
                                  lawsuitNumber: e.target.value
                                });
                              }
                            }
                          }}
                        /* inputProps={{
                          value: data.lawsuitNumber,
                          disabled: false
                        }}
                        onChange={e => {
                          if (e) {
                            setData({
                              ...data,
                              lawsuitNumber: e.target.value
                            });
                          }
                        }} */
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <ListboxWtdVoteType
                          disabled={
                            [2, 5].includes(data.wtdStatusId) ? true : false
                          }
                          validators={[MustNotNull]}
                          defaultValue={{
                            id: data.voteTypeId
                          }}
                          onChange={(evt, newValue) => {
                            if (newValue) {
                              setData({
                                ...data,
                                voteTypeId: newValue.id
                              });
                            }
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <ListboxProvince
                          validators={[MustNotNull]}
                          defaultValue={{
                            id: data.wtdProvinceId
                          }}
                          disabled={true}
                        /* onChange={value => {
                          if (value) {
                            setData({
                              ...data,
                              provinceId: value.id
                            });
                          }
                        }} */
                        ></ListboxProvince>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Datepicker
                          disabled={
                            [2, 5].includes(data.wtdStatusId) ||
                              !userInfo.isadminwtd
                              ? true
                              : false
                          }
                          id="wtdSdate"
                          label="วันที่เริ่มการถูกเพิกถอน"
                          value={data.wtdSdate}
                          onChange={date => {
                            if (date) {
                              let arr = date.format().split("T");
                              setData({
                                ...data,
                                wtdSdate: arr[0]
                              });
                              if (date && data.wtdYear) {
                                const end_date = WtdEndDate(
                                  date,
                                  data.wtdYear
                                );

                                setData({
                                  ...data,
                                  wtdEdate: moment(
                                    new Date(end_date)
                                  ).format("YYYY-MM-DD"),
                                  wtdSdate: arr[0]
                                });
                              }
                            }
                          }}
                        /* onChange={date => {
                          if (date) {
                            let arr = date.format().split("T");
                            setData({
                              ...data,
                              wtdSdate: arr[0]
                            });
                          }
                        }} */
                        ></Datepicker>{" "}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <ListboxWtdYear
                          disabled={
                            [2, 5].includes(data.wtdStatusId) ||
                              !userInfo.isadminwtd
                              ? true
                              : false
                          }
                          validators={[MustNotNull]}
                          defaultValue={{ id: data.wtdYear }}
                          onChange={(evt, newValue) => {
                            if (newValue) {
                              // setData({
                              //   ...data,
                              //   wtdPersonInfo: {
                              //     ...wtdPersonInfo,
                              //     wtdYear: newValue.id
                              //   }
                              // });

                              // setData({
                              //   ...data,
                              //   wtdYear: newValue.id
                              // });
                              if (data.wtdPersonInfo.wtdSdate && newValue.id) {
                                const end_date = WtdEndDate(
                                  data.wtdPersonInfo.wtdSdate,
                                  newValue.id
                                );

                                setData({
                                  ...data,
                                  wtdYear: newValue.id,
                                  wtdEdate: moment(new Date(end_date)).format(
                                    "YYYY-MM-DD"
                                  )
                                });
                              }
                            }
                          }}
                        ></ListboxWtdYear>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Datepicker
                          id="wtdEdate"
                          label="วันที่พ้นฯ"
                          disabled={
                            [2, 5].includes(data.wtdStatusId) ||
                              !userInfo.isadminwtd
                              ? true
                              : false
                          }
                          value={data.wtdEdate}
                          onChange={date => {
                            if (date) {
                              let arr = date.format().split("T");
                              setData({
                                ...data,
                                wtdEdate: arr[0]
                              });
                            }
                          }}
                        ></Datepicker>
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    // alignItems="flex-end"
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <ListboxWtdOrg
                          disabled={
                            [2, 5].includes(data.wtdStatusId) ? true : false
                          }
                          validators={[MustNotNull]}
                          defaultValue={{
                            id: data.wtdOrgId
                          }}
                          onChange={(evt, newValue) => {
                            if (newValue) {
                              setData({
                                ...data,
                                wtdOrgId: newValue.id
                              });
                            }
                          }}
                        ></ListboxWtdOrg>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <ListboxWtdStatus
                              validators={[MustNotNull]}
                              defaultValue={{ id: data.wtdStatusId }}
                              /* disabled={
                                [2, 5].includes(data.wtdStatusId) ? true : false
                              } */
                              onChange={(evt, value) => {
                                if (value) {
                                  setData({
                                    ...data,
                                    wtdStatusId: value.id
                                  });
                                }
                              }}
                            ></ListboxWtdStatus>
                          </GridItem>

                          {data.wtdStatusId === 2 ? (
                            <GridItem xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <InputLabel>หมายเหตุ</InputLabel>
                                <Select
                                  value={data.earlyExpireDetail}
                                  onChange={event => {
                                    setData({
                                      ...data,
                                      earlyExpireDetail: event.target.value
                                    });
                                  }}
                                  inputProps={{
                                    name: "earlyExpireDetail"
                                  }}
                                >
                                  <MenuItem value={"เสียชีวิต"}>
                                    เสียชีวิต
                                  </MenuItem>
                                  <MenuItem value={"ยกคำร้อง"}>
                                    ยกคำร้อง
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </GridItem>
                          ) : (
                            ""
                          )}

                          {[2].includes(data.wtdStatusId) ? (
                            <GridItem xs={12} sm={12} md={12}>
                              <Datepicker
                                id="earlyExpireDate"
                                label="วันที่ เสียชีวิตหรือยกคำร้อง"
                                value={data.earlyExpireDate}
                                onChange={date => {
                                  if (date) {
                                    let arr = date.format().split("T");
                                    setData({
                                      ...data,
                                      earlyExpireDate: arr[0]
                                    });
                                  }
                                }}
                              ></Datepicker>
                            </GridItem>
                          ) : (
                            ""
                          )}
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInputWithValidator
                          labelText="รายละเอียดการเพิกถอนสิทธิ/ฏีกา"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: [2, 5].includes(data.wtdStatusId)
                              ? true
                              : false,
                            value: data.wtdCommandDetail || "",
                            onChange: e => {
                              setData({
                                ...data,
                                wtdCommandDetail: e.target.value
                              });
                            }
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <p style={{ color: "red", fontSize: "18px", fontWeight: "bolder" }}>
                          {"ข้อควรระวัง !! "}
                        </p>
                        <p style={{ color: "red", fontSize: "18px" }}>
                          {"กรุณาแนบไฟล์ *เอกสารที่เกี่ยวข้อง* ให้เรียบร้อย ก่อนการ *เปลี่ยนสถานะการถูกเพิกถอน* ทุกครั้ง"}
                        </p>
                      </GridItem>
                    </GridContainer>
                    <br /> <br />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="space-between" alignItems="center">
          <GridItem xs={6} sm={6} md={6}></GridItem>
          <GridItem>
            <Button onClick={backtoMainHandler}>ย้อนกลับ</Button>
            <Button color="success" onClick={handelSubmit}>
              บันทึกข้อมูล
            </Button>
          </GridItem>
        </GridContainer>
        {/* พ.ร.บ. */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <AddFault
              dataLaw={dataLaw}
              wtdPersonId={data.wtdPersonId}
              status={data.wtdStatusId}
            />
          </GridItem>
        </GridContainer>
        {/* แนบเอกสารที่เกี่ยวข้อง */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <AddFile
              dataFile={dataFile}
              wtdPersonId={data.wtdPersonId}
              wtdProvinceId={data.wtdProvinceId}
              status={data.wtdStatusId}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
  return content;
};
export default EditWtdPerson;
