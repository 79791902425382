import Color from "./Color";
// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import ManageMasterdata from "./ManageMasterdata";
import Number from "./Number";
import Printing from "./Printing";
import React from "react";
// import VoteType from "./VoteType";
import { VotecardContextProvider } from "contexts/Votecard";
import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

const styles = {
  textCenter: {
    textAlign: "center"
  },
  //tabRoot: { backgroundColor: "#0a6" },
  span: {
    backgroundColor: "#aa0",
    fontSize: "22px"
  }
};

const useStyles = makeStyles(styles);

const Index = props => {
  const classes = useStyles();

  return (
    <VotecardContextProvider>
      <div className={classes.tabcustom}>
        <CustomTabs
          styles={customTabsStyles}
          headerColor="success"
          tabs={[
            {
              tabName: "รายละเอียดข้อมูลบัตรเลือกตั้ง",
              tabContent: <ManageMasterdata />
            },
            {
              tabName: "โรงพิมพ์",
              tabContent: <Printing />
            },
            {
              tabName: "สีบัตรเลือกตั้ง",
              tabContent: <Color />
            },
            {
              tabName: "ชนิดหมายเลข",
              tabContent: <Number />
            }
          ]}
        />
      </div>
    </VotecardContextProvider>
  );
};

export default Index;
