import React, { createContext, useContext, useState } from "react";

import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { UserInfoContext } from "contexts/users";
import { useSweetAlert } from "components/Alert/SweetAlert";

export const WtdContext = createContext();
export const WtdContextProvider = ({ children }) => {
  const { userInfo } = useContext(UserInfoContext);
  let userProvinceId = null;
  // let userProvinceId = {
  //   id: 0,
  //   value: ""
  // };
  let isCenter = userInfo.iscenter;
  if (!isCenter) {
    userProvinceId = {
      id: userInfo.provinceId,
      value: userInfo.provinceName
    };
  }

  //console.log(userProvinceId);
  const [areaEctSelected, setAreaEctSelected] = useState(null);
  const [nameSelected, setNameSelected] = useState(null);
  const [fileNameSelected, setFileNameSelected] = useState(null);
  const [idCardSelected, setIdCardSelected] = useState(null);
  const [provinceSelected, setProvinceSelected] = useState(userProvinceId);
  const [yearSelected, setYearSelected] = useState(null);
  const [wtdStatusSelected, setWtdStatusSelected] = useState(null);
  const [wtdOrgSelected, setWtdOrgSelected] = useState(null);
  const [optVoteTypeSelected, setOptVoteTypeSelected] = useState(null);
  const [titleSelected, setTitleSelected] = useState(null);
  const [amphurSelected, setAmphurSelected] = useState(null);
  const [tambolSelected, setTambolSelected] = useState(null);
  const [optSelected, setOptSelected] = useState(null);
  const [voteTypeSelected, setVoteTypeSelected] = useState(null);
  const [idCardValid, setIdCardValid] = useState(true);

  const { sweetAlerts } = useSweetAlert();
  const defaultWtdSelected = {
    1: { checked: false, value: 1 },
    2: { checked: false, value: 2 },
    3: { checked: false, value: 3 },
    4: { checked: false, value: 4 },
    5: { checked: false, value: 5 }
  };
  const [wtdCheckBoxSelected, setWtdCheckBoxSelected] = useState(
    defaultWtdSelected
  );

  const value = {
    fileNameSelected: fileNameSelected,
    setFileNameSelected: setFileNameSelected,
    onFileNameSelected: evt => {
      setFileNameSelected(evt.target.value);
    },
    nameSelected: nameSelected,
    setNameSelected: setNameSelected,
    onNameSelected: evt => {
      setNameSelected(evt.target.value);
    },
    idCardSelected: idCardSelected,
    idCardValid: idCardValid,
    setIdCardValid: setIdCardValid,
    setIdCardSelected: setIdCardSelected,
    onIdCardHandler: evt => {
      if (
        evt !== null &&
        evt !== undefined &&
        evt.value !== null &&
        evt.value !== undefined &&
        evt.value.length === 13
      ) {
        const [isError] = ThaiCitizenID(evt.value);
        if (isError) {
          sweetAlerts.danger("เลขประจำตัวประชาชนผิด");
          setIdCardValid(false);
        } else {
          setIdCardValid(true);
        }
      } else {
        setIdCardValid(true);
      }
      setIdCardSelected(evt.value);
    },

    wtdCheckBoxSelected: wtdCheckBoxSelected,
    setWtdCheckBoxSelected: setWtdCheckBoxSelected,

    onWtdCheckBoxSelected: (evt, newChecked) => {
      if (
        wtdCheckBoxSelected[`${evt.target.name}`] === undefined ||
        newChecked !== wtdCheckBoxSelected[`${evt.target.name}`].checked
      ) {
        setWtdCheckBoxSelected({
          ...wtdCheckBoxSelected,
          [`${evt.target.name}`]: {
            checked: evt.target.checked,
            value: evt.target.value
          }
        });
      }
    },
    getWtdCheckboxLists: () => {
      let wtdIdLists = [];
      for (const [, v] of Object.entries(wtdCheckBoxSelected)) {
        if (v.checked) {
          wtdIdLists.push(parseInt(v.value));
        }
      }
      return wtdIdLists;
    },

    areaEctSelected: areaEctSelected,
    setAreaEctSelected: setAreaEctSelected,
    onAreaEctSelected: (evt, newValue) => {
      setAreaEctSelected(newValue);
      // setProvinceSelected(null);
    },

    provinceSelected: provinceSelected,
    setProvinceSelected: setProvinceSelected,
    onProvinceSelected: (evt, newValue) => {
      // console.log(newValue);
      setProvinceSelected(newValue);
      setAmphurSelected(null);
      setTambolSelected(null);
      setOptSelected(null);
    },
    amphurSelected: amphurSelected,
    setAmphurSelected: setAmphurSelected,
    onAmphurSelected: (evt, newValue) => {
      setAmphurSelected(newValue);
      setTambolSelected(null);
    },
    tambolSelected: tambolSelected,
    setTambolSelected: setTambolSelected,
    onTambolSelected: (evt, newValue) => {
      setTambolSelected(newValue);
    },
    optSelected: optSelected,
    setOptSelected: setOptSelected,
    onOptSelected: (evt, newValue) => {
      setOptSelected(newValue);
    },
    yearSelected: yearSelected,
    setYearSelected: setYearSelected,
    onYearSelected: (evt, newValue) => {
      setYearSelected(newValue);
    },
    titleSelected: titleSelected,
    setTitleSelected: setTitleSelected,
    onTitleSelected: (evt, newValue) => {
      setTitleSelected(null);
    },

    voteTypeSelected: voteTypeSelected,
    setVoteTypeSelected: setVoteTypeSelected,
    onVoteTypeSelected: (evt, newValue) => {
      setVoteTypeSelected(newValue);
    },

    wtdStatusSelected: wtdStatusSelected,
    setWtdStatusSelected: setWtdStatusSelected,
    onWtdStatusSelected: (evt, newValue) => {
      setWtdStatusSelected(newValue);
    },
    optVoteTypeSelected: optVoteTypeSelected, //เลือกประเภทการเลือกตั้ง
    setOptVoteTypeSelected: setOptVoteTypeSelected,
    onOptVoteTypeSelected: (evt, newValue) => {
      setOptVoteTypeSelected(newValue);
    },
    wtdOrgSelected: wtdOrgSelected,
    setWtdOrgSelected: setWtdOrgSelected,
    onWtdOrgSelected: (evt, newValue) => {
      setWtdOrgSelected(newValue);
    }
  };
  return <WtdContext.Provider value={value}>{children}</WtdContext.Provider>;
};
