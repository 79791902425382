import React, { useContext, useEffect, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import DatepickerVotedate from "components/Common/Datepicker";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxArea from "components/Common/ListboxArea";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import ListboxVotedate from "components/Common/ListboxVotedate";
import ListboxZone from "components/Common/ListboxZone";
import Slide from "@material-ui/core/Slide";
import { VoteDateContext } from "contexts/VoteDate";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalReport03 = props => {
  const {
    zoneSelected,
    onZoneSelected,
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    tambolSelected,
    onTambolSelected,
    amphurSelected,
    optVoteTypeSelected,
    onOptVoteTypeSelected,
    positionTypeSelected,
    onPostionTypeSelected,
    optSelected,
    onOptSelected,
    optTypeSelected,
    onOptTypeSelected,
    getOptTypeSelectedLists,
    votedateSelected,
    onVotedateSelected,
    areaSelected,
    onAreaSelected
  } = useContext(VoteDateContext);

  const { sweetAlerts } = useSweetAlert();
  const { showModalReport3, closeModalReport3 } = props;
  const classes = useStyles();
  const [signName, setSignName] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [isFromValid, setIsFromValid] = useState(true);
  const [isToValid, setIsToValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);

  useEffect(() => {
    if (fromDate) {
      setIsFromValid(false);
    } else {
      setIsFromValid(true);
    }

    if (toDate) {
      setIsToValid(false);
    } else {
      setIsToValid(true);
    }

    if (signName) {
      setIsNameValid(false);
    } else {
      setIsNameValid(true);
    }
  }, [fromDate, toDate, signName]);

  const getQuery = () => {
    let optTypes = getOptTypeSelectedLists();
    let endPoint = "";

    if (["9_1", "9_9", "9_10"].includes(props.indexReport)) {
      if (optTypes.length > 0) {
        endPoint += `&opt_sub_type=${optTypes.toString()}`;
      } else {
        endPoint += `&opt_sub_type=0`;
      }
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&amphur_id=${amphurSelected !== null ? amphurSelected.id : 0
        }&position_type=${positionTypeSelected !== null ? positionTypeSelected.id : 0
        }&vote_type=${optVoteTypeSelected !== null ? optVoteTypeSelected.id : 0
        }&opt_id=${optSelected !== null ? optSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate}`;
    }
    if (["9_8"].includes(props.indexReport)) {
      if (optTypes.length > 0) {
        endPoint += `&opt_sub_type=${optTypes.toString()}`;
      } else {
        endPoint += `&opt_sub_type=0`;
      }
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&position_type=${positionTypeSelected !== null ? positionTypeSelected.id : 0
        }&vote_type=${optVoteTypeSelected !== null ? optVoteTypeSelected.id : 0
        }&opt_id=${optSelected !== null ? optSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate}`;
    }

    return endPoint;
  };

  const handleSubmitReport3 = e => {
    e.preventDefault();

    // console.log(areaSelected);
    // console.log(votedateSelected);
    // console.log(zoneSelected);
    // console.log(provinceSelected);
    //  if (["9_1"].includes(props.indexReport)) {
    // if (optTypes.length === 0 && ["9_1"].includes(props.indexReport)) {
    //   sweetAlerts.warning("กรุณาเลือกประเภทอปท.");
    //   return;
    // }

    if (
      (fromDate === null || toDate === null) &&
      ["9_1", "9_8", "9_9", "9_10"].includes(props.indexReport)
    ) {
      sweetAlerts.warning("กรุณาระบุช่วงวันเลือกตั้ง");
      return;
    } else {
      const query = getQuery();
      // console.log(query);
      window.open(`${props.linkReport}${query}`, "_blank");
    }
    // }
    //   return;
  };

  return (
    <div className={showModalReport3 ? "modal" : "hide"}>
      <Dialog
        open={showModalReport3}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModalReport3}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>{props.titleReport}</h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {["9_1", "9_8", "9_9", "9_10"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CheckboxOpttype
                  optTypeSelected={optTypeSelected}
                  onOptTypeSelected={onOptTypeSelected}
                ></CheckboxOpttype>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          <GridContainer>
            {["9_3", "9_4", "9_5", "9_6", "9_7"].includes(props.indexReport) ? (
              <>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxOptVotetype
                    required={true}
                    value={optVoteTypeSelected}
                    onChange={onOptVoteTypeSelected}
                  ></ListboxOptVotetype>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxProvince
                    value={provinceSelected}
                    required={true}
                    //   zoneSelected={zoneSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxZone
                    provinceSelected={provinceSelected}
                    required={true}
                    value={zoneSelected}
                    onChange={onZoneSelected}
                  ></ListboxZone>
                </GridItem>
              </>
            ) : (
              ""
            )}
          </GridContainer>
          {["9_1", "9_8", "9_9", "9_10"].includes(props.indexReport) ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxOptVotetype
                    value={optVoteTypeSelected}
                    onChange={onOptVoteTypeSelected}
                  ></ListboxOptVotetype>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxProvince
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxDistinct
                    title={"เลือกทุกอำเภอ"}
                    provinceSelected={provinceSelected}
                    onChange={onAmphurSelected}
                  ></ListboxDistinct>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxOpts
                    value={optSelected}
                    getOptTypeSelectedLists={getOptTypeSelectedLists}
                    optTypeSelected={optTypeSelected}
                    provinceSelected={provinceSelected}
                    amphurSelected={amphurSelected}
                    onChange={onOptSelected}
                  ></ListboxOpts>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxPositionType
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <DatepickerVotedate
                    id="begin"
                    label="ตั้งแต่วันที่"
                    value={fromDate}
                    valid={isFromValid}
                    onChange={date => {
                      if (date) {
                        let arr = date.format().split("T");
                        setFromDate(arr[0]);
                        setIsFromValid(false);
                      } else {
                        setFromDate(null);
                        setIsFromValid(true);
                      }
                    }}
                  ></DatepickerVotedate>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <DatepickerVotedate
                    id="endDate"
                    label="ถึงวันที่"
                    value={toDate}
                    valid={isToValid}
                    disabled={fromDate === null ? true : false}
                    onChange={date => {
                      if (date) {
                        let arr = date.format().split("T");
                        setToDate(arr[0]);
                        setIsToValid(false);
                      } else {
                        setToDate(null);
                        setIsToValid(true);
                      }
                    }}
                  ></DatepickerVotedate>
                </GridItem>
              </GridContainer>
            </>
          ) : (
            ""
          )}

          {["9_3"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <ListboxDistinct
                  title={"เลือกทุกอำเภอ"}
                  provinceSelected={provinceSelected}
                  onChange={onAmphurSelected}
                ></ListboxDistinct>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <ListboxSubdistinct
                  required={true}
                  disabled={["9_1"].includes(props.indexReport) ? true : false}
                  amphurSelected={amphurSelected}
                  value={tambolSelected}
                  onChange={onTambolSelected}
                ></ListboxSubdistinct>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <ListboxOpts
                  required={true}
                  value={optSelected}
                  getOptTypeSelectedLists={getOptTypeSelectedLists}
                  optTypeSelected={optTypeSelected}
                  provinceSelected={provinceSelected}
                  amphurSelected={amphurSelected}
                  tambolSelected={tambolSelected}
                  onChange={onOptSelected}
                ></ListboxOpts>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}

          {["9_4", "9_5", "9_6", "9_7"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <ListboxDistinct
                  // required={true}
                  title={"เลือกทุกอำเภอ"}
                  provinceSelected={provinceSelected}
                  onChange={onAmphurSelected}
                ></ListboxDistinct>
              </GridItem>

              <GridItem xs={12} sm={6} md={4}>
                <ListboxOpts
                  required={true}
                  value={optSelected}
                  getOptTypeSelectedLists={getOptTypeSelectedLists}
                  optTypeSelected={optTypeSelected}
                  provinceSelected={provinceSelected}
                  amphurSelected={amphurSelected}
                  tambolSelected={tambolSelected}
                  onChange={onOptSelected}
                ></ListboxOpts>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <ListboxVotedate
                  required={true}
                  value={votedateSelected}
                  optVoteTypeSelected={optVoteTypeSelected}
                  optSelected={optSelected}
                  onChange={onVotedateSelected}
                ></ListboxVotedate>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}

          {["9_3"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <ListboxVotedate
                  required={true}
                  value={votedateSelected}
                  optVoteTypeSelected={optVoteTypeSelected}
                  optSelected={optSelected}
                  onChange={onVotedateSelected}
                ></ListboxVotedate>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <ListboxPositionType
                  required={true}
                  value={positionTypeSelected}
                  onChange={onPostionTypeSelected}
                ></ListboxPositionType>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <ListboxArea
                  required={true}
                  isreport={true}
                  value={areaSelected}
                  optSelected={optSelected}
                  positionTypeSelected={positionTypeSelected}
                  optVoteTypeSelected={optVoteTypeSelected}
                  votedateSelected={votedateSelected}
                  onChange={onAreaSelected}
                />
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          {/* 
          {["9_1"].includes(props.indexReport) ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxPositionType
                    required={true}
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <DatepickerVotedate
                    id="begin"
                    label="วันที่ประกาศ"
                    value={fromDate}
                    valid={isFromValid}
                    onChange={date => {
                      if (date) {
                        let arr = date.format().split("T");
                        setFromDate(arr[0]);
                        setIsFromValid(false);
                      }
                      setIsFromValid(true);
                    }}
                  ></DatepickerVotedate>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxArea
                    // required={true}
                    isreport={true}
                    value={areaSelected}
                    optSelected={optSelected}
                    positionTypeSelected={positionTypeSelected}
                    optVoteTypeSelected={optVoteTypeSelected}
                    votedateSelected={votedateSelected}
                    onChange={onAreaSelected}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText="ชื่อ สกุล ผู้อำนวยการเลือกตั้ง"
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={isNameValid}
                    inputProps={{
                      value: signName || "",
                      error: isNameValid,
                      onChange: e => {
                        if (e.target.value) {
                          setIsNameValid(false);
                        } else {
                          setIsNameValid(true);
                        }
                        setSignName(e.target.value);
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
            </>
          ) : (
            ""
          )} */}

          <GridContainer>
            {["9_4", "9_5", "9_6", "9_7"].includes(props.indexReport) ? (
              <>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxPositionType
                    required={true}
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText="กรรมการการเลือกตั้งที่ได้รับมอบหมาย"
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={isNameValid}
                    inputProps={{
                      value: signName || "",
                      error: isNameValid,
                      onChange: e => {
                        if (e.target.value) {
                          setIsNameValid(false);
                        } else {
                          setIsNameValid(true);
                        }
                        setSignName(e.target.value);
                      }
                    }}
                  />
                </GridItem>
              </>
            ) : (
              ""
            )}
          </GridContainer>

          <GridContainer>
            {/* {[ "9_7"].includes(props.indexReport) ? (
              <>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxPositionType
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <CustomInput
                    labelText="กรรมการการเลือกตั้งที่ได้รับมอบหมาย"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: signName || "",
                      error: isNameValid,
                      onChange: e => {
                        if (e.target.value) {
                          setIsNameValid(false);
                        } else {
                          setIsNameValid(true);
                        }
                        setSignName(e.target.value);
                      }
                    }}
                  />
                </GridItem>
              </>
            ) : (
              ""
            )} */}
            {/*  {["9_10"].includes(props.indexReport) ? (
              <>
               <GridItem xs={12} sm={6} md={4}>
                  <ListboxPositionType
                    required={true}
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <ListboxArea
                    required={true}
                    isreport={true}
                    value={areaSelected}
                    optSelected={optSelected}
                    positionTypeSelected={positionTypeSelected}
                    optVoteTypeSelected={optVoteTypeSelected}
                    votedateSelected={votedateSelected}
                    onChange={onAreaSelected}
                  />
                 <CustomInput
                    labelText="เขต"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: area || "",
                      error: true,
                      onChange: e => {
                        if (e.target.value) setArea(e.target.value);
                        else {
                          setArea(null);
                        }
                      }
                    }}
                  /> 
                </GridItem>
              </>
            ) : (
              ""
            )}*/}
          </GridContainer>

          <br></br>
          <GridContainer
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem xs={12} sm={12} md={6}>
              <Button onClick={closeModalReport3}>ปิดหน้าต่าง</Button>
              <Button color="success" onClick={handleSubmitReport3}>
                ออกรายงาน
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalReport03;
