import React, { useContext, useEffect, useState } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import CheckboxVoteCardType from "components/Common/CheckboxVoteCardType";
import DatepickerVotedate from "components/Common/Datepicker";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxVoteCardColorAll from "components/Common/ListboxVoteCardColorAll";
import ListboxVoteCardNumAll from "components/Common/ListboxVoteCardNumAll";
import ListboxVotecardPrinting from "components/Common/ListboxVotecardPrinting";
import Slide from "@material-ui/core/Slide";
import { VotecardContext } from "contexts/Votecard";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalReport = props => {
  const {
    votecardPrintingSelected,
    //setVotecardPrintingSelected,
    onVotecardPrintingSelected,
    positionTypeSelected,
    onPositionTypeSelected,
    numberSelected,
    onNumberSelected,
    colorSelected,
    onColorSelected,
    votecardCheckBoxOptSelected,
    onVotecardCheckBoxOptSelected,
    getVotecardCheckBoxOptList
  } = useContext(VotecardContext);

  const { sweetAlerts } = useSweetAlert();
  const { showModalReport, closeModalReport } = props;
  const classes = useStyles();

  const [fromDate, setFromDate] = useState(null);

  const [toDate, setToDate] = useState(null);
  const [isFromValid, setIsFromValid] = useState(true);
  const [isToValid, setIsToValid] = useState(true);

  useEffect(() => {
    if (fromDate) {
      setIsFromValid(false);
    } else {
      setIsFromValid(true);
    }
    if (toDate) {
      setIsToValid(false);
    } else {
      setIsToValid(true);
    }
  }, [toDate, fromDate]);

  const getQuery = () => {
    let endPoint = "";
    let optTypes = getVotecardCheckBoxOptList();

    if (["3_1", "4_1", "5_1"].includes(props.indexReport)) {
      endPoint += `&printing_houses_name=${votecardPrintingSelected !== null ? votecardPrintingSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate}`;
    }

    if (["1_1", "2_1", "6_1"].includes(props.indexReport)) {
      endPoint += `&vote_card_opt_type=${optTypes.length > 0 ? optTypes.toString() : 0
        }&position_type=${positionTypeSelected !== null ? positionTypeSelected.id : 0
        }&printing_houses_name=${votecardPrintingSelected !== null ? votecardPrintingSelected.id : 0
        }&number_type=${numberSelected !== null ? numberSelected.id : 0
        }&vote_card_color=${colorSelected !== null ? colorSelected.id : 0}`;
    }

    return endPoint;
  };
  const handleSubmitReport1 = e => {
    e.preventDefault();

    if (["1_1", "2_1", "6_1"].includes(props.indexReport)) {
      let optTypes = getVotecardCheckBoxOptList();

      // if (optTypes.length === 0) {
      //   sweetAlerts.warning("กรุณาเลือกประเภท อปท.");
      //   return false;
      //   //   endPoint += `&opt_sub_type=${optTypes.toString()}`;
      // } else {
      const query = getQuery();
      //  console.log(query);
      window.open(`${props.linkReport}${query}`, "_blank");
      // }
    }

    if (["4_1", "3_1", "5_1"].includes(props.indexReport)) {
      if (fromDate === null || toDate === null) {
        sweetAlerts.warning("กรุณาระบุช่วงวันเริ่มต้น/สิ้นสุด");
      } else {
        const query = getQuery();
        //  console.log(query);
        window.open(`${props.linkReport}${query}`, "_blank");
      }
    }
  };

  return (
    <div className={showModalReport ? "modal" : "hide"}>
      <Dialog
        open={showModalReport}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModalReport}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>{props.titleReport}</h4>
          </CardHeader>
        </DialogTitle>
        <GridContainer>
          {["1_1", "2_1", "6_1"].includes(props.indexReport) ? (
            <GridItem xs={12} sm={12} md={12}>
              <CheckboxVoteCardType
                votecardCheckBoxOptSelected={votecardCheckBoxOptSelected}
                onVotecardCheckBoxOptSelected={onVotecardCheckBoxOptSelected}
              ></CheckboxVoteCardType>
            </GridItem>
          ) : (
            ""
          )}
        </GridContainer>

        <DialogContent>
          {["1_1", "2_1", "6_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxPositionType
                  value={positionTypeSelected}
                  onChange={onPositionTypeSelected}
                ></ListboxPositionType>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <ListboxVotecardPrinting
                  votecardPrintingSelected={votecardPrintingSelected}
                  onChange={onVotecardPrintingSelected}
                ></ListboxVotecardPrinting>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxVoteCardNumAll
                  value={numberSelected}
                  onChange={onNumberSelected}
                ></ListboxVoteCardNumAll>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}

          {["1_1", "2_1", "6_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxVoteCardColorAll
                  value={colorSelected}
                  onChange={onColorSelected}
                ></ListboxVoteCardColorAll>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}

          {["3_1", "4_1", "5_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxVotecardPrinting
                  votecardPrintingSelected={votecardPrintingSelected}
                  onChange={onVotecardPrintingSelected}
                ></ListboxVotecardPrinting>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <DatepickerVotedate
                  id="begin"
                  label="ตั้งแต่วันที่"
                  value={fromDate}
                  valid={isFromValid}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setFromDate(arr[0]);
                      setIsFromValid(false);
                    } else {
                      setFromDate(null);
                      setIsFromValid(true);
                    }
                  }}
                ></DatepickerVotedate>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <DatepickerVotedate
                  id="endDate"
                  label="ถึงวันที่"
                  value={toDate}
                  valid={isToValid}
                  disabled={fromDate === null ? true : false}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setToDate(arr[0]);
                      setIsToValid(false);
                    } else {
                      setToDate(null);
                      setIsToValid(true);
                    }
                  }}
                ></DatepickerVotedate>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}

          <br></br>
          <GridContainer
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem xs={12} sm={12} md={6}>
              <Button onClick={closeModalReport}>ปิดหน้าต่าง</Button>
              <Button color="success" onClick={handleSubmitReport1}>
                ออกรายงาน
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalReport;
