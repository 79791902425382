import React, { useContext, useEffect, useState } from "react";
import {
  addVoteCardPurchaseAll,
  addVoteCardPurchasePopup,
  updateVoteCardPurchase
} from "contexts/API/VoteCard/Purchase";

import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import ConvertDate from "utils/ConvertDate";
import DatepickerVotedate from "components/Common/Datepicker";
import { FileUpload } from "components/Common/FileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxVotedate from "components/Common/ListboxVotedate";
/* Material Components */
import LoadingData from "components/Common/LoadingData";
import NativeSelect from "@material-ui/core/NativeSelect";
import NumberFormat from "react-number-format";
import { Paper } from "@material-ui/core";
/* API */
import PurChaseDetail from "./PurChaseDetail";
import Radio from "@material-ui/core/Radio";
import TableComponent from "components/Table/MaterialTable";
import TextField from "@material-ui/core/TextField";
import { VoteDateContext } from "contexts/VoteDate";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { getSearchLists } from "contexts/API/VoteCard/Position";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles(styles);

const AddPurChase = ({ pageProps, setPageProps }) => {
  const [loading, setLoading] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  const [, setClickSearch] = useState(false);
  const [data, setData] = useState([]);

  // const [, setUploadValue] = useState(null);

  const classes = useStyles();
  const {
    optVoteTypeSelected,
    onOptVoteTypeSelected,
    provinceSelected,
    onProvinceSelected,
    optTypeSelected,
    onOptTypeSelected,
    amphurSelected,
    onAmphurSelected,
    tambolSelected,

    optSelected,
    onOptSelected,
    votedateSelected,
    onVotedateSelected,
    setOptSelected,
    //uttils
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);

  const [showPurchase, setShowPurchase] = useState(false);
  const [showListofPurchase, setShowListofPurchase] = useState(false);

  const optionsTable = { exportButton: false, paging: false, search: false };

  const [fileUUID, setFileUUID] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [receiveType, setReceiveType] = useState(null);
  const [receiveDate, setReceiveDate] = useState(null);
  const [receiveAddress, setReceiveAddress] = useState(null);

  const [_voteCardPurchaseID, _setVoteCardPurchaseID] = useState(null);
  //const [voteCardPurchaseDetailID, setVoteCardPurchaseDetailID] = useState(null);
  const [
    voteCardPurchaseReferenceNo,
    setVoteCardPurchaseReferenceNo
  ] = useState(null);
  const [voteCardPurchaseDateTime] = useState(new Date().toISOString());
  const [voteCardPurchaseRemark, setVoteCardPurchaseRemark] = useState(null);
  const [voteCardSendType, setVoteCardSendType] = useState(null);

  const columnsPurchase = [
    {
      title: "ตำแหน่ง",
      field: "positionTypeDetailShortName",
      cellStyle: {
        minWidth: "30px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "เขตเลือกตั้ง",
      field: "areaNoText",
      cellStyle: {
        minWidth: "30px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "จำนวนหน่วย",
      field: "NumStationDefault",
      cellStyle: {
        textAlign: "center",
        minWidth: "100px"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "จำนวนผู้สมัครสูงสุด",
      field: "numStationDefault",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => (
        <NumberFormat
          id={"numberRegister" + rowData.tableData.id}
          name="numberRegister[]"
          thousandSeparator={true}
          style={{
            borderStyle: "none none dotted none",
            textAlign: "center",
            width: "70px"
          }}
          value={!rowData.numberRegister ? 0 : parseInt(rowData.numberRegister)}
          onValueChange={values => {
            const { value } = values;

            rowData.numberRegister = parseInt(value);
          }}
        />
      )
    },
    {
      title: "จำนวนผู้มีสิทธิ",
      field: "numberPersonVote",
      cellStyle: {
        textAlign: "center",
        minWidth: "100px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => (
        <NumberFormat
          id={"numberPersonVote" + rowData.tableData.id}
          name="numberPersonVote[]"
          thousandSeparator={true}
          style={{
            borderStyle: "none none dotted none",
            textAlign: "center",
            width: "70px"
          }}
          value={
            !rowData.numberPersonVote ? 0 : parseInt(rowData.numberPersonVote)
          }
          onValueChange={values => {
            const { value } = values;

            rowData.numberPersonVote = parseInt(value);
            handleChangedInputNumber(
              rowData.tableData.id,
              "numberPersonVote",
              value
            );
            rowData.numberVoteCard = Math.ceil(
              rowData.numberPersonVote / 20 +
              (rowData.numberPersonVote / 20) *
              (rowData.inNumberPercent / 100)
            );
            rowData.inNumberBookAmount = rowData.numberVoteCard * 20;
          }}
        />
      )
    },
    {
      title: "ร้อยละ",
      field: "inNumberPercent",
      cellStyle: {
        textAlign: "center",
        minWidth: "50px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => (
        <NativeSelect
          id={"inNumberPercent" + rowData.tableData.id}
          name="inNumberPercent[]"
          onChange={event => {
            handleChangedInputNumber(
              rowData.tableData.id,
              "numberPercent",
              event.target.value
            );
            rowData.inNumberPercent = parseInt(event.target.value);
            rowData.numberVoteCard = Math.ceil(
              rowData.numberPersonVote / 20 +
              (rowData.numberPersonVote / 20) *
              (parseInt(event.target.value) / 100)
            );
            rowData.inNumberBookAmount = rowData.numberVoteCard * 20;
          }}
        >
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="0">กำหนดเอง</option>
        </NativeSelect>
      )
    },
    {
      title: "จำนวนเล่ม",
      field: "numberVoteCard",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => (
        <NumberFormat
          id={"numberVoteCard" + rowData.tableData.id}
          name="numberVoteCard[]"
          thousandSeparator={true}
          style={{
            borderStyle: "none none dotted none",
            textAlign: "center",
            width: "70px"
          }}
          value={!rowData.numberVoteCard ? 0 : parseInt(rowData.numberVoteCard)}
          onValueChange={values => {
            const { value } = values;

            rowData.numberVoteCard = parseInt(value);
            handleChangedInputNumber(rowData.tableData.id, "numberBook", value);
          }}
        />
      )
    },
    {
      title: "จำนวนฉบับ",
      field: "inNumberBookAmount",
      editable: "never",
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => (
        <NumberFormat
          id={"inNumberBookAmount" + rowData.tableData.id}
          name="inNumberBookAmount[]"
          thousandSeparator={true}
          readOnly={true}
          style={{
            borderStyle: "none none dotted none",
            textAlign: "center",
            width: "70px"
          }}
          value={
            !rowData.inNumberBookAmount
              ? 0
              : parseInt(rowData.inNumberBookAmount)
          }
          onValueChange={values => {
            const { value } = values;

            rowData.inNumberBookAmount = parseInt(value);
            handleChangedInputNumber(
              rowData.tableData.id,
              "numberBookAmount",
              value
            );
          }}
        />
      )
    }
  ];

  const handleChangeReceiveType = event => {
    setReceiveType(event.target.value);
  };
  const handleChangedInputNumber = (id, e, v) => {
    /* 
        numberRegister
        numberPersonVote
        inNumberPercent
        numberVoteCard
        inNumberBookAmount
     */
    //จำนวนเล่ม (((จำนวนผู้มีสิทธิ * ร้อยละ) + จำนวนผู้มีสิทธิ) / 20)) กรณีที่มีเศษให้ปัดขึ้น, จำนวนฉบับ (จำนวนเล่ม * 20) เป็น Text Number
    let numberRegister = document.getElementById("numberRegister" + id);
    let numberPersonVote = document.getElementById("numberPersonVote" + id);
    let inNumberPercent = document.getElementById("inNumberPercent" + id);
    let numberVoteCard = document.getElementById("numberVoteCard" + id);
    let inNumberBookAmount = document.getElementById("inNumberBookAmount" + id);

    if (
      e === "numberRegister" ||
      e === "numberPersonVote" ||
      e === "numberPercent"
    ) {
      var _numberRegister =
        numberRegister.value === ""
          ? 0
          : parseInt(numberRegister.value.replace(",", ""));
      var _numberPersonVote =
        numberPersonVote.value === ""
          ? 0
          : parseInt(numberPersonVote.value.replace(",", ""));
      var _numberPercent =
        inNumberPercent.value === ""
          ? 0
          : parseInt(inNumberPercent.value.replace(",", ""));
      //var _numberBook = Math.ceil(((_numberPersonVote * _numberPercent) + _numberPersonVote) / 20);
      var _numberBook = Math.ceil(
        _numberPersonVote / 20 +
        (_numberPersonVote / 20) * (_numberPercent / 100)
      );
      var _numberBookAmount = _numberBook * 20;

      numberRegister.value = Intl.NumberFormat().format(_numberRegister);
      numberPersonVote.value = Intl.NumberFormat().format(_numberPersonVote);
      numberVoteCard.value = Intl.NumberFormat().format(_numberBook);
      inNumberBookAmount.value = Intl.NumberFormat().format(_numberBookAmount);

      //   inNumberBookAmount.value = Intl.NumberFormat().format(_numberBookAmount);
    } else if (e === "numberBook") {
      var _numberBook =
        numberVoteCard.value === ""
          ? 0
          : parseInt(numberVoteCard.value.replace(",", ""));
      var _numberBookAmount = _numberBook * 20;
      inNumberBookAmount.value = Intl.NumberFormat().format(_numberBookAmount);
    }
  };
  const handleChangeVoteCardSendType = event => {
    setVoteCardSendType(event.target.value);
  };
  const cancelVoteDateHandler = rowData => {
    setPageProps({ active: "PurChase", props: rowData, action: "cancel" });
  };
  const onClickSearch = e => {
    setClickSearch(false);
    setLoading(true);

    setReceiveType(null);
    setReceiveDate(new Date().toISOString());
    setReceiveAddress("");

    if (optSelected === null) {
      sweetAlerts.warning("กรุณาเลือก อปท. ");
      return;
    }

    if (optVoteTypeSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภทการเลือกตั้ง");
      return;
    }
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }

    if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันที่เลือกตั้ง");
      return;
    }

    const optQueryUrl = () => {
      let endPoint = "?";
      /*
        1) ประเภทการเลือกตั้ง (OptVoteTypeID)
        2) ประเภท อปท. (OptSubTypeID)
        3) จังหวัด (ProvinceID)
        4) อำเภอ (AmphurID)
        5) ตำบล (TambolID)
        6) วันที่เลือกตั้ง (VoteDate)
        */
      if (optVoteTypeSelected !== null) {
        endPoint += `OptVoteTypeID=${optVoteTypeSelected.id}&`;
      }

      if (optSelected !== null) {
        endPoint += `OptID=${optSelected.id}&`;
      }

      if (provinceSelected !== null) {
        endPoint += `ProvinceID=${provinceSelected.id}&`;
      }

      if (amphurSelected !== null) {
        endPoint += `AmphurID=${amphurSelected.id}&`;
      }

      if (tambolSelected !== null) {
        endPoint += `TambolID=${tambolSelected.id}&`;
      }

      if (votedateSelected !== null) {
        endPoint += `VoteDate=${votedateSelected.id.split("T")[0]}&`;
      }
      return endPoint;
    };

    getSearchLists(optQueryUrl(), "")
      .then(result => {
        let newArr1 = result.data.data.map(v => ({
          ...v,
          numberRegister: 0,
          numberPersonVote: 0,
          numberVoteCard: 0,
          inNumberBookAmount: 0,
          inNumberPercent: 5
        }));
        setData(newArr1);
        setShowPurchase(true);
        setLoading(false);
        setClickSearch(true);
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
      });

    /*
    onPurchsaeDetail(voteCardPurchaseID, 3);
    setListofPurchase(true);
    */
  };

  const authenData = data => {
    const chk_regis = data.filter(function (t) {
      return (
        t.numberRegister === 0 ||
        t.numberRegister === NaN ||
        t.numberRegister === ""
      );
    });

    const chk_person = data.filter(function (t) {
      return (
        t.numberPersonVote === 0 ||
        t.numberPersonVote === NaN ||
        t.numberPersonVote === ""
      );
    });

    const chk_votecard = data.filter(function (t) {
      return (
        t.numberVoteCard === 0 ||
        t.numberVoteCard === NaN ||
        t.numberVoteCard === ""
      );
    });
    if (chk_regis.length > 0) {
      sweetAlerts.warning("กรุณาระบุจำนวนผู้สมัครสูงสุด");
      return false;
    }
    if (chk_person.length > 0) {
      sweetAlerts.warning("กรุณาระบุจำนวนผู้มีสิทธิ");
      return false;
    }
    if (chk_votecard.length > 0) {
      sweetAlerts.warning("กรุณาระบุจำนวนเล่มบัตรเลือกตั้ง");
      return false;
    } else {
      return true;
    }
  };

  const addPurchaseHandler = async () => {
    const t = authenData(data);

    if (t) {
      if (receiveType === null || receiveType === "") {
        sweetAlerts.warning("กรุณาระบุวิธีการรับบัตรเลือกตั้ง");
        return;
      }

      if (receiveType === "1") {
        if (receiveDate === null) {
          sweetAlerts.warning("กรุณาระบุวันที่ไปรับ");
          return;
        }
        let _receiveDate = receiveDate.split("T")[0];
        if (
          parseInt(votedateSelected.id.split("T")[0].replace("-", "")) <
          parseInt(_receiveDate.replace("-", ""))
        ) {
          sweetAlerts.warning("วันที่รับบัตรต้องน้อยกว่าวันที่เลือกตั้ง");
          return;
        }
        if (receiveType === "1" && receiveDate) {
          data.map(item => {
            item.receiveTypeCode = "MYSELF";
            item.receiveMyselfDateTime = receiveDate;
            item.voteCardPurchaseStatusID = 1;
          });
        }
      }

      if (receiveType === "2") {
        if (receiveAddress === null || receiveAddress === "") {
          sweetAlerts.warning("กรุณาระบุที่อยู่สำหรับจัดส่ง");
          return;
        }
        if (receiveType === "2" && receiveAddress) {
          data.map(item => {
            item.receiveTypeCode = "POSTOFFICE";
            item.receivePostOfficeAddress = receiveAddress;
            item.voteCardPurchaseStatusID = 1;
          });
        }
      }

      let bodyVoteCardPurchaes = {
        voteCardPurchaseID: _voteCardPurchaseID
          ? _voteCardPurchaseID.voteCardPurchaseID
          : null,
        provinceID: provinceSelected ? provinceSelected.id : 0,
        voteCardPurchaesDetail: data
      };

      //console.log(bodyVoteCardPurchaes);

      try {
        const response = await addVoteCardPurchaseAll(bodyVoteCardPurchaes);
        // console.log(response.data.data.voteCardPurchaseID);
        sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
        _setVoteCardPurchaseID(response.data.data);
        setShowListofPurchase(true);
        setData([]);
        setReceiveType(null);
        setOptSelected(null);
        setReceiveAddress("");
      } catch (error) {
        setLoading(false);

        sweetAlerts.error(
          "ไม่สามารถบันทึกข้อมูลได้",
          error.response.data.desc.toString()
        );
      }
    }
  };

  const updatePurchaseHandler = async () => {
    let _voteCardPurchaseStatusID = 1;
    if (
      voteCardPurchaseReferenceNo === null ||
      voteCardPurchaseReferenceNo === ""
    ) {
      sweetAlerts.warning("กรุณาระบุเลขที่หนังสืออ้างอิง");
      return;
    } else if (voteCardSendType === null) {
      sweetAlerts.warning("กรุณาระบุประเภทอัพโหลด");
      return;
    }

    if (voteCardSendType === "1") {
      _voteCardPurchaseStatusID = 2;
    } else if (voteCardSendType === "2") {
      _voteCardPurchaseStatusID = 3;
    }

    if (voteCardSendType === "2" && fileUUID === null) {
      sweetAlerts.warning("กรุณาแนบไฟล์เลขที่หนังสืออ้างอิง");
      return false;
    }
    const updateBodyVoteCardPurchaes = {
      voteCardPurchaseID: _voteCardPurchaseID.voteCardPurchaseID,
      referenceNo: voteCardPurchaseReferenceNo,
      purchaseDatetime: voteCardPurchaseDateTime.split("T")[0],
      purchaseRemark: voteCardPurchaseRemark,
      voteCardPurchaseStatusID: _voteCardPurchaseStatusID,
      FileUUID: fileUUID
    };

    try {
      const response = await updateVoteCardPurchase(updateBodyVoteCardPurchaes);
      // console.log(response.data.success);
      // console.log(_voteCardPurchaseStatusID);
      if (response.data.success && _voteCardPurchaseStatusID === 2) {
        sweetAlerts.success(`บันทึกข้อมูลสำเร็จ`);
        cancelVoteDateHandler();
      }
      if (response.data.success && _voteCardPurchaseStatusID === 3) {
        addVoteCardPurchasePopup({
          provinceId: provinceSelected.id,
          isCenter: true,
          header: "แจ้งเตือน",
          body:
            "มีคำสั่งซื้อวันที่ " +
            ConvertDate(voteCardPurchaseDateTime.split("T")[0]) +
            " จากจังหวัด " +
            provinceSelected.value +
            " อ้างอิงหนังสือ " +
            voteCardPurchaseReferenceNo +
            " มายัง สนง.กกต",
          pageId: 304,
          systemTypeId: 5
        }).then(result => {
          sweetAlerts.success(
            `บันทึกข้อมูลสำเร็จ `,
            "ระบบได้ทำการส่งคำร้องขอไปยัง กกต.กลาง แล้ว"
          );
          cancelVoteDateHandler();
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      sweetAlerts.error("ไม่สามารถบันทึกข้อมูลได้", error.response.toString());
    }
  };

  return (
    <>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4>สั่งซื้อบัตรเลือกตั้ง</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {/* second section */}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CheckboxOpttype
                    optTypeSelected={optTypeSelected}
                    onOptTypeSelected={onOptTypeSelected}
                    required={true}
                  ></CheckboxOpttype>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxOptVotetype
                    value={optVoteTypeSelected}
                    onChange={onOptVoteTypeSelected}
                    required={true}
                  ></ListboxOptVotetype>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxProvince
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                    required={true}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxDistinct
                    provinceSelected={provinceSelected}
                    value={amphurSelected}
                    onChange={onAmphurSelected}
                    required={false}
                  ></ListboxDistinct>
                </GridItem>
              </GridContainer>
              {/* third section */}
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxOpts
                    value={optSelected}
                    getOptTypeSelectedLists={getOptTypeSelectedLists}
                    optTypeSelected={optTypeSelected}
                    provinceSelected={provinceSelected}
                    amphurSelected={amphurSelected}
                    tambolSelected={tambolSelected}
                    onChange={onOptSelected}
                    required={true}
                  ></ListboxOpts>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxVotedate
                    value={votedateSelected}
                    optSelected={optSelected}
                    optVoteTypeSelected={optVoteTypeSelected}
                    onChange={onVotedateSelected}
                    required={true}
                  ></ListboxVotedate>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}>
                  <Button onClick={cancelVoteDateHandler}>ย้อนกลับ</Button>
                </GridItem>
                <GridItem>
                  <Button>ยกเลิก</Button>
                  <Button color="info" type="button" onClick={onClickSearch}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
        {showPurchase ? (
          <>
            <CardBody>
              <h4>วิธีการรับบัตรเลือกตั้ง</h4>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                  >
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={receiveType === "1"}
                            value="1"
                            name="radio-ReceiveType"
                            onChange={handleChangeReceiveType}
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="รับด้วยตนเอง"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            onChange={handleChangeReceiveType}
                            checked={receiveType === "2"}
                            value="2"
                            name="radio-ReceiveType"
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="รับทางไปรษณีย์"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      {receiveType ? (
                        receiveType === "1" ? (
                          <DatepickerVotedate
                            label="วันที่ไปรับด้วยตนเอง"
                            value={receiveDate}
                            defaultValue={receiveDate}
                            onChange={date => {
                              setReceiveDate(date.format());
                            }}
                          ></DatepickerVotedate>
                        ) : (
                          <TextField
                            label="ที่อยู่สำหรับจัดส่ง"
                            defaultValue={receiveAddress}
                            onChange={e => {
                              setReceiveAddress(e.target.value);
                            }}
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                          />
                        )
                      ) : (
                        ""
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              {loading ? (
                <LoadingData />
              ) : (
                <TableComponent
                  title="การสั่งซื้อบัตรเลือกตั้ง"
                  columns={columnsPurchase}
                  data={data}
                  localization={{}}
                  defaultExpanded={true}
                  options={optionsTable}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />
                  }}
                //  onRowUpdate={onRowUpdate}
                ></TableComponent>
              )}
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button
                    color="success"
                    className={classes.buttoncustom}
                    onClick={addPurchaseHandler}
                  >
                    เพิ่มการสั่งบัตร
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </>
        ) : (
          ""
        )}
      </Card>
      {showListofPurchase ? (
        //<ListforPurChase />
        <>
          <PurChaseDetail purchaseID={_voteCardPurchaseID} />
          <Card>
            <CardHeader color="success" text>
              <CardText color="success">
                <h5>อัพโหลดเอกสารส่งไปยังส่วนกลาง</h5>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer
                    justify="space-around"
                    alignItems="center"
                    direction="row"
                  >
                    <GridItem xs={6} sm={6} md={6}>
                      <TextField
                        label="เลขที่หนังสืออ้างอิง"
                        helperText="กรุณาระบุ *"
                        required
                        fullWidth
                        style={{ marginTop: "30px" }}
                        defaultValue={voteCardPurchaseReferenceNo}
                        onChange={e => {
                          setVoteCardPurchaseReferenceNo(e.target.value);
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <DatepickerVotedate
                        label="วันที่ส่งข้อมูลการสั่งซื้อ"
                        required
                        InputProps={{
                          readOnly: true
                        }}
                        disabled
                        value={voteCardPurchaseDateTime}
                        defaultValue={voteCardPurchaseDateTime}
                      ></DatepickerVotedate>
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    justify="space-around"
                    alignItems="center"
                    direction="row"
                    style={{ marginTop: "20px" }}
                  >
                    <GridItem xs={6} sm={6} md={6}>
                      <TextField
                        label="รายละเอียด"
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        defaultValue={voteCardPurchaseRemark}
                        onChange={e => {
                          setVoteCardPurchaseRemark(e.target.value);
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <FormControlLabel
                        control={
                          <Radio
                            onChange={handleChangeVoteCardSendType}
                            checked={voteCardSendType === "1"}
                            value="1"
                            name="radio-voteCardSendType"
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="อัพโหลดอย่างเดียว"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            onChange={handleChangeVoteCardSendType}
                            checked={voteCardSendType === "2"}
                            value="2"
                            name="radio-voteCardSendType"
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="อัพโหลดและส่งข้อมูลไปยังส่วนกลาง"
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={6} md={6} style={{ marginTop: "30px" }}>
                      <FileUpload
                        id="file-upload-fileuuid"
                        label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                        fileName={fileName}
                        remove
                        onFileRemove={() => {
                          setFileName(null);

                          setFileUUID(null);
                        }}
                        url={getFileDownloadUrl("votecard", fileUUID)}
                        download
                        onFileHandler={(e, f, p) => {
                          uploadFile("votecard", f, p)
                            .then(r => {
                              const file = r.data.data;
                              sweetAlerts.success("แนบไฟล์เรียบร้อย");
                              setFileName(file.fileName);
                              setFileUUID(file.fileId);
                            })
                            .catch(err => {
                              console.log(err);
                            });
                        }}
                        fileType="application/pdf"
                        btnName={<>Browse</>}
                        btnProps={{
                          color: "primary"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br /> <br /> <br />
                  <GridContainer
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <GridItem>
                      {/*
                      <Button color="danger"
                        onClick={deletePurchaseHandler}
                      >ลบรายการสั่งซื้อทั้งหมด</Button>
                      */}
                      <Button color="default" onClick={cancelVoteDateHandler}>
                        กลับ
                      </Button>
                      <Button
                        color="info"
                        className={classes.buttoncustom}
                        onClick={updatePurchaseHandler}
                      >
                        บันทึกข้อมูล
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </form>
            </CardBody>
          </Card>
        </>
      ) : (
        ""
      )}
      <br />
    </>
  );
};
export default AddPurChase;
