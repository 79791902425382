import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";

const cookies = new Cookies();

export function getProvinceGroup(voteDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/loadProvinceGroup/${voteDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function putPromoteEffectiveDateAll(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/promoteEffectiveDateAll`;
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editEffectiveDate(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/promoteEffectiveDate`;

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
