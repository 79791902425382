import React, { useState, useEffect } from "react";

import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput";
import FileSaver from "file-saver";
import { FileUpload } from "components/Common/FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ImportCheckIdFile from "assets/resources/opt/import_check_id.xlsx";
import InputLabel from "@material-ui/core/InputLabel";
import MUItable from "components/Table/MaterialTable";
import MenuItem from "@material-ui/core/MenuItem";
import NumberFormat from "react-number-format";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import LoadingData from "components/Common/LoadingData";
import XLSX from "xlsx";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { getCheckPerson } from "contexts/API/OPT/OptcheckPerson";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";
import validateId from "utils/Validators/ThaiCitizenID.js";
import { REPORTING_SERVICE } from "config/env.conf";

const useStyles = makeStyles(theme => ({
  buttoncustom: {
    fontSize: 14,
    fontWeight: 500
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      //width: '99%',
      fontSize: "16px"
    }
  }
}));

const CheckPositionStatus = () => {
  const { sweetAlerts } = useSweetAlert();
  const [year, setYear] = useState(0);
  const [orgName, setOrgName] = useState("");

  const [AllIDs, setAllIDs] = useState(null);
  const [IDs, setIDs] = useState(null);
  const [ListIDs, setListIDs] = useState(null);

  const [InvalidIDs, setInvalidIDs] = useState([]);
  const [keyTempIdCard, setKeyTempIdCard] = useState(null);
  const [disableStatus, setDisableStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [orgNameState, setOrgNameState] = useState(true);

  //material table
  const [data, setData] = useState([]);
  const options_table = {
    paging: false,
    search: false,
    exportButton: false
    //maxBodyHeight: "650px"
  };
  const [columns, setColumns] = useState([
    {
      title: 'ลำดับ',
      field: 'rowId',
      cellStyle: {
        minWidth: "25px",
        textAlign: "center"
      },
    },
    {
      title: "เลขประจำตัวประชาชน",
      field: "idCard",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      }
    },
    {
      title: "ผลการตรวจ",
      field: "result",
      cellStyle: {
        textAlign: "center"
      }
    }
  ]);

  const classes = useStyles();

  const onExceclFileHandler = e => {
    try {
      setData([])
      let errStr = [];
      let AllIDLists = [];
      let IDLists = [];
      let ValitIDLists = [];
      let InvIDLists = [];
      let data = new Uint8Array(e);
      let workbook = XLSX.read(data, { type: "array" });
      let ws = workbook.Sheets[workbook.SheetNames[0]];
      let rows = XLSX.utils.sheet_to_json(ws, { header: ["A"] });

      if (rows.length <= 1) {
        throw new Error("ไม่มีข้อมูล");
      }

      if (rows[0].A !== "เลขประจำตัวประชาชน") {
        throw new Error("กรุณาใช้ไฟล์ที่ export ออกจากระบบเท่านั้น");
      }

      for (let i = 1; i < rows.length; i++) {
        const [isError, errDetail] = validateId(rows[i].A.toString());
        //console.log('verify : ', rows[i]);
        if (isError) {
          let error = "เซลล์ที่ A" + (rows[i].__rowNum__ + 1) + " " + errDetail;

          errStr.push(
            <div key={"err" + i} style={{ textAlign: "left" }}>
              เซลล์ที่ A{rows[i].__rowNum__ + 1} '{rows[i].A}'{" "}
              <u
                style={{
                  borderBottom: "1px  dotted red",
                  textDecoration: "none"
                }}
              >
                {errDetail}
              </u>
            </div>
          );
          InvIDLists.push({
            rowId: rows[i].__rowNum__,
            idCard: rows[i].A.toString(),
            result: [error]
          });
          AllIDLists.push(rows[i].A.toString());
        } else {
          AllIDLists.push(rows[i].A.toString());
          IDLists.push(rows[i].A.toString());
          ValitIDLists.push({
            rowId: rows[i].__rowNum__,
            idCard: rows[i].A.toString()
          });
        }
      }
      setAllIDs(AllIDLists);
      setListIDs(ValitIDLists);
      setIDs(IDLists);
      setInvalidIDs(InvIDLists);

      if (errStr.length > 0) {
        sweetAlerts.error(
          "เกิดข้อผิดพลาดสำหรับไฟล์ที่นำเข้า ต้องการดำเนินการต่อหรือไม่",
          <>
            <div style={{ textAlign: "left" }}>
              {" "}
              <b>มีข้อผิดพลาดดังนี้: </b>
            </div>
            <div style={{ overflow: "auto", height: "150px" }}>{errStr}</div>,
            <div style={{ textAlign: "left" }}>
              <b>
                จำนวนทั้งหมด: <Badge color="success">{rows.length - 1} </Badge>{" "}
                กรอกข้อมูลผิดพลาด: <Badge color="danger">{errStr.length}</Badge>
              </b>
            </div>
          </>,
          () => {
            //onConfirmed
            //do nothing
          },
          true,
          () => {
            //onCanceled
            setIDs(null);
          }
        );
      } else {
        sweetAlerts.success(
          "นำเข้าข้อมูลสำเร็จ",
          <>
            <div style={{ textAlign: "center" }}>
              <b>
                จำนวนทั้งหมด: <Badge color="success">{rows.length - 1} </Badge>{" "}
                กรอกข้อมูลผิดพลาด: <Badge color="danger">{errStr.length}</Badge>
              </b>
            </div>
          </>,
          () => {
            //onConfirmed
            //do nothing
          },
          false
        );
      }
    } catch (err) {
      sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
    }
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const handleChangeYear = event => {
    setYear(event.target.value);
  };

  const onSearchHandler = () => {
    if (IDs === null) {
      sweetAlerts.warning("กรุณาระบุหมายเลขประจำตัวประชาชน");
      return;
    }
    if (year === null) {
      sweetAlerts.warning("กรุณาระบุวันที่ต้องการตรวจสอบ");
      return;
    }
    if (orgName === null) {
      sweetAlerts.warning("กรุณาระบุหน่วยงาน");
      return;
    }

    const today = new Date();
    today.setFullYear(today.getFullYear() - year);
    const checkDate = today.toISOString().split("T")[0];
    setLoading(true);

    getCheckPerson(IDs, checkDate, AllIDs.toString())
      .then(result => {
        let ListID_temp = ListIDs;
        setKeyTempIdCard(result.data.data.keyTempIdCard);
        const dataUniqKey = {};

        //console.log("length >>>", AllIDs.length)

        result.data.data.result.forEach(v => {

          let postionStatus = "";
          let __level__ = 0;


          let getRowId = ListID_temp.find(x => x.idCard === v.idCard);

          const v_rowId = getRowId !== undefined ? getRowId.rowId : null;
          if (v_rowId !== null) {
            ListID_temp = ListID_temp.filter(r => {
              return r.rowId !== v_rowId;
            });
          }

          if (
            !v.startEffectiveDate ||
            !v.optFullname /* ถ้าไม่มี startEffectiveDate ให้ถือว่ายังไม่เคยดำรงตำแหน่ง */
          ) {
            __level__ = 0;
            postionStatus = "--ไม่พบข้อมูล--";
          } else {
            __level__ = 1;
            postionStatus = `ตำแหน่ง ${v.optPosition} วันที่ ${ConvertDate(
              v.startEffectiveDate
            )} ถึงวันที่ ${ConvertDate(v.endEffectiveDate)} ${v.optShortName}${v.optFullname
              } จ.${v.provinceName}`;
          }


          if (dataUniqKey[v.idCard + v_rowId] === undefined) {
            /* บันทึกสถานะ */
            dataUniqKey[v.idCard + v_rowId] = {
              rowId: v_rowId,
              idCard: v.idCard,
              result: [postionStatus],
              __level__: __level__
            };
          } else if (__level__ >= 1) {
            /* เพิ่มประวัติการดำรงตำแหน่ง */

            if (dataUniqKey[v.idCard + v_rowId].__level__ === 0) {
              dataUniqKey[v.idCard + v_rowId].result = [postionStatus];
              dataUniqKey[v.idCard + v_rowId].__level__ = __level__;
            } else {
              dataUniqKey[v.idCard + v_rowId].result = [
                ...dataUniqKey[v.idCard + v_rowId].result,
                postionStatus
              ];
              dataUniqKey[v.idCard + v_rowId].__level__ = __level__;
            }
          } else {
            //do nothing
          }
        });
        setData(InvalidIDs.concat(Object.values(dataUniqKey)));

        setLoading(false);
        setColumns([
          {
            title: 'ลำดับ',
            field: 'rowId',
            cellStyle: {
              minWidth: "25px",
              textAlign: "center"
            },
            defaultSort: "asc",
          },
          {
            title: "เลขประจำตัวประชาชน",
            field: "idCard",
            cellStyle: {
              minWidth: "150px"
            },
            render: rawData => {
              return (
                <NumberFormat
                  value={rawData.idCard}
                  format="# #### ##### ## #"
                  style={{
                    fontSize: "15px",
                    width: "100%",
                    border: "0"
                  }}
                />
              );
            }
          },

          {
            title: "ผลการตรวจสอบ",
            field: "result",
            render: rawData => {
              return rawData.result.map((e, i) => {
                return <div key={i}>{e}</div>;
              });
            },
            cellStyle: {
              minWidth: "600px"
            }
          }
        ]);
      })
      .catch(err => {
        console.log("error >>", err)
        sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
      });
  };

  const exportUrl = () => {
    let endPointExp = "";

    const today = new Date();
    today.setFullYear(today.getFullYear() - year);
    const checkDate = today.toISOString().split("T")[0];


    endPointExp += `&keyTempIdCard=${keyTempIdCard}&date=${checkDate}&orgName=${orgName}&year=${year !== null ? year : 0}&total=${AllIDs.length}`;


    return endPointExp;
  };

  useEffect(() => {
    if (IDs !== null && orgNameState === false) {
      setDisableStatus(false);
    } else {
      setDisableStatus(true);
    }
  }, [IDs, orgName]);

  const handleSubmitExport = e => {
    e.preventDefault();
    window.open(
      `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt_exp_checkperson&rs:Command=Render&rc:Parameters=Collapsed${exportUrl()}`,
      "_blank"
    );
  };

  return (
    <form className={classes.root}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br /> <br />
          <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={12} sm={12} md={12}>
              ดาวน์โหลดแบบฟอร์มการตรวจสอบ :
              <Button
                size="sm"
                color="success"
                onClick={() => {
                  FileSaver.saveAs(ImportCheckIdFile, "import_check_id.xlsx");
                }}
              >
                <FontAwesomeIcon icon={faFileExcel} />
                แบบฟอร์มการตรวจสอบ
              </Button>
              <span style={{ fontSize: "14px", color: "red", paddingLeft: "10px" }}>
                ตรวจสอบข้อมูลได้ไม่เกิน 5,000 รายการ
              </span>

            </GridItem>
          </GridContainer>
          <br />
          <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={12} sm={12} md={6}>
              <FileUpload
                id="file-upload-2"
                label="แนบไฟล์ (เฉพาะ Excel เท่านั้น)"
                remove
                onFileHandler={onExceclFileHandler}
                fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                btnName={<>Browse</>}
                btnProps={{
                  color: "primary"
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>ระยะเวลาที่ตรวจสอบย้อนหลัง</InputLabel>
                <Select
                  value={year}
                  onChange={handleChangeYear}
                  inputProps={{
                    name: "checkDate"
                  }}
                >
                  <MenuItem aria-label="None" value={0}>
                    วันปัจจุบัน
                  </MenuItem>
                  <MenuItem value={1}>1 ปี</MenuItem>
                  <MenuItem value={2}>2 ปี</MenuItem>
                  <MenuItem value={3}>3 ปี</MenuItem>
                  <MenuItem value={4}>4 ปี</MenuItem>
                  <MenuItem value={5}>5 ปี</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                id="orgName"
                required
                labelText="หน่วยงานที่ขอตรวจสอบ (โปรดระบุ)"
                error={orgNameState}
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  autoComplete: "new-password",
                  onChange: e => {
                    if (verifyLength(e.target.value, 1)) {
                      setOrgNameState(false);
                    } else {
                      setOrgNameState(true);
                    }
                    setOrgName(e.target.value);
                  }
                }}
              />
            </GridItem>
          </GridContainer>
          <br /> <br />
          <GridContainer justify="space-between" alignItems="center">
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button
                onClick={() => {
                  setData([]);
                }}
              >
                ยกเลิก
              </Button>
              <Button
                color="info"
                disabled={disableStatus}
                onClick={onSearchHandler}
              >
                ตรวจสอบ
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <br />
      {data.length !== 0 ? (
        <GridContainer justify="space-between" alignItems="center">
          <GridItem xs={6} sm={6} md={6}></GridItem>
          <GridItem>
            นำออกข้อมูล:{" "}
            <Button justIcon round color="success" onClick={handleSubmitExport}>
              <FontAwesomeIcon icon={faFileExcel} />
            </Button>
          </GridItem>
        </GridContainer>
      ) : null}

      {loading === true ? (
        <LoadingData />
      ) : (
        <MUItable
          data={data}
          columns={columns}
          title={"ผลการตรวจสอบข้อมูล"}
          options={options_table}
          enableInitColumns={false}
          components={{
            Container: props => <Paper {...props} elevation={0} />,
            /* Header: props => {
              return (
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ลำดับ</TableCell>
                    <TableCell>เลขประจำตัวประชาชน</TableCell>
                    <TableCell>ผลการตรวจสอบ</TableCell>
                  </TableRow>
                </TableHead>
              );
            } */
          }}
        />
      )}
    </form>
  );
};

export default CheckPositionStatus;
