import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxPositionStatus = props => {
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/opt/position/status",
    []
  );

  let content = (
    <TextField
      disabled
      label="เลือกสาเหตุจัดเลือกตั้ง"
      margin="normal"
      fullWidth
    />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return { id: v.statusId, value: v.detail };
    });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        name={props.name}
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        options={data}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={props.title ? props.title : "เลือกสาเหตุจัดเลือกตั้ง"}
              margin="normal"
              error={error}
              fullWidth
              helperText={helperText}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }

  return content;
};
export default ListboxPositionStatus;
