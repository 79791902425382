import React, { useState } from "react";

import Button from "components/CustomButtons/Button.js";
import ConvertDate from "utils/ConvertDate";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FileUpload } from "components/Common/FileUpload";
import Slide from "@material-ui/core/Slide";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { uploadFile6_1 } from "contexts/API/OPT/Form61";
import { useSweetAlert } from "components/Alert/SweetAlert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const ModalupLoadFile = props => {
  const { sweetAlerts } = useSweetAlert();

  const [uploadFileName, setUploadFileName] = useState(null);
  const [uploadFileUUID, setUploadFileUUID] = useState(null);
  const { showupLoad, closeModalupLoad, children, onUploadSuccess } = props;
  const useStyles = makeStyles();
  const classes = useStyles();
  const handelSubmit = () => {
    if (uploadFileUUID !== null) {
      uploadFile6_1(children.optForm6Id, uploadFileUUID)
        .then(result => {
          sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
          closeModalupLoad();
          addSucess(result.data.data.affectedRows);
          // setData([...dataUpdate]);
        })
        .catch(err => {
          console.log(err);
          sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
        });
    } else {
      sweetAlerts.warning("กรุณาเลือกเอกสารแนบ");
    }
  };
  const addSucess = affectedRows => {
    onUploadSuccess(affectedRows);
  };
  return (
    <div className={showupLoad ? "modal" : "hide"}>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        maxWidth="md"
        open={showupLoad}
        fullWidth={true}
        keepMounted
        transition={Transition}
        onClose={closeModalupLoad}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          className={classes.titlemodal}
        >
          นำข้อมูลเข้า ประจำวันเลือกตั้งที่ {ConvertDate(children.voteDate)}
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <div style={{ width: "100%" }}>
            <FileUpload
              id="file-upload-1"
              label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
              fileName={uploadFileName}
              remove
              onFileRemove={() => {
                setUploadFileName("");
              }}
              url={getFileDownloadUrl("opt", uploadFileUUID)}
              download
              onFileHandler={(e, f, p) => {
                uploadFile("opt", f, p)
                  .then(r => {
                    const file = r.data.data;
                    sweetAlerts.success("แนบไฟล์เรียบร้อย");
                    setUploadFileName(file.fileName);
                    setUploadFileUUID(file.fileId);
                    //setData({ ...data, from4_1UUID: file.fileId });
                  })
                  .catch(err => {
                    console.log(err);
                  });
              }}
              fileType="application/pdf"
              btnName={<>Browse</>}
              btnProps={{
                color: "primary"
              }}
            />
          </div>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={closeModalupLoad}>ปิดหน้าต่าง</Button>{" "}
            <Button onClick={handelSubmit} variant="contained" color="success">
              บันทึกข้อมูล
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalupLoadFile;
