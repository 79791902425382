import AddCandidate from "components/pages/inspector/AddCandidate/index";
import AddSuspendIndex from "components/pages/suspend/AddSuspend/index";
import AddWtdIndex from "components/pages/wtd/AddWtd/index";
import AdminManagement from "components/pages/userManagement/User/index";
import AllocateCardtoBranchIndex from "components/pages/votecard/AllocateCardtoBranch/index";
import AllocateIndex from "components/pages/votecard/VoteCardAllocate/index";
import AllocatePrintingIndex from "components/pages/votecard/AllocateCardtoPrinting/index";
import CheckOptPage from "components/pages/opt/CheckOpt/index";
import CheckSuspendIndex from "components/pages/suspend/CheckSuspend/index";
import CheckWtdIndex from "components/pages/wtd/CheckWtd/index";
import Command from "components/pages/inspector/UploadCommand/index";
//import ControlAllocateIndex from "components/pages/votecard/ControlAllocateCard/index";
import EduSettingIndex from "components/pages/commondSetting/EduMasterData/index";
import EffectiveDate from "components/pages/inspector/EffectiveDate/index";
import Filter1Icon from "@material-ui/icons/LooksOne";
import Filter2Icon from "@material-ui/icons/LooksTwo";
import Filter3Icon from "@material-ui/icons/Looks3";
import Filter4Icon from "@material-ui/icons/Looks4";
import Filter5Icon from "@material-ui/icons/Looks5";
import FollowCardIndex from "components/pages/votecard/FollowCardStatus/index";
import Form61Index from "components/pages/opt/Form61/index";
import GroupIcon from "@material-ui/icons/Group";
/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Home from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import HomePage from "components/pages/home/index";
import InspectorPage from "components/pages/inspector/Inspector";
import KeyboardArrowRightTwoToneIcon from "@material-ui/icons/KeyboardArrowRightTwoTone";
import ListInspector from "components/pages/inspector/ListInspector/index";
import ListSuspendIndex from "components/pages/suspend/ListSuspend";
import ListWtdIndex from "components/pages/wtd/ListWtd";
//votecard
import MasterDataIndex from "components/pages/votecard/MasterData/index";
import Moniter from "components/pages/inspector/Moniter/index";
import NotiSettupPageIndex from "components/pages/userManagement/NotiSetup/index";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsIndex from "components/pages/Notifications/index";
import OptHandler from "components/pages/opt/MsOpt/OptHandler";
import OutPositionIndex from "components/pages/opt/OutPosition/index";
import PersonIndex from "components/pages/opt/optPerson/index";
import PersonSettingIndex from "components/pages/commondSetting/personMasterData/index";
import PopUpSettupPageIndex from "components/pages/userManagement/PopUpSetup/index";
import PromotePerson from "components/pages/inspector/PromotePerson/index";
import PurchaseIndex from "components/pages/votecard/PurChaseVotecard/index";
import Qualification from "components/pages/inspector/QualificationUpload/index";
import ReplaceBrance from "components/pages/inspector/SelectionReplaceBrance/index";
import ReplaceCentral from "components/pages/inspector/SelectionReplaceCentral/index";
import ReportInspect from "components/pages/inspector/Report/index";
//opt
import ReportPage from "components/pages/opt/Report/index";
import ReportSuspendIndex from "components/pages/suspend/Report/index";
import ReportVotecardIndex from "components/pages/votecard/Report/index";
import ReportWtdIndex from "components/pages/wtd/Report/index";
import RolesInPageIndex from "components/pages/userManagement/PageInRoles/index";
import RolesManagement from "components/pages/userManagement/Role/index";
import Selection16 from "components/pages/inspector/Selection16/index";
import Selection8 from "components/pages/inspector/Selection8/index";
import SetRecruitIndex from "components/pages/inspector/Recruit/index";
//Inspector
import SetVoteDateIndex from "components/pages/inspector/SetDate/index";
import SettingOpt from "components/pages/opt/Setting/index";
import SettingSuspendIndex from "components/pages/suspend/SettingData/index";
//wtd
import SettingWtdIndex from "components/pages/wtd/SettingData/index";
import Settings from "@material-ui/icons/Settings";
import StatusOfSaveIndex from "components/pages/opt/Status/index";
import StockIndex from "components/pages/votecard/StockCard/index";
import UseVoteIndex from "components/pages/opt/UseVote/index";
//Suspend
import UserInRolesIndex from "components/pages/userManagement/UserInRoles/index";
import UserIndex from "components/pages/userManagement/User/index";
import VoteCardPublishIndex from "components/pages/votecard/VoteCardPublish/index";
import VoteDateIndex from "components/pages/opt/VoteDate/index";
import VotePublishIndex from "components/pages/opt/VotePublish/index";

const Routes = [
  {
    pageId: 1,
    path: "/home",
    name: "ข้อมูลทั่วไป",
    icon: Home,
    component: HomePage,
    layout: "/main",
    hide: true
  },
  {
    pageId: 2,
    path: "/opt",
    name: "ทะเบียนสมาชิก ส.ถ./ผ.ถ.",
    icon: Filter1Icon,
    collapse: true,
    //state: "componentsCollapse",
    component: VoteDateIndex,
    layout: "/main",
    state: "multiCollapse",
    color: "#8e24aa",
    views: [
      {
        pageId: 201,
        path: "/complete_opt_data",
        name: "สถานะการบันทึกข้อมูล  ",
        icon: KeyboardArrowRightTwoToneIcon,
        component: StatusOfSaveIndex,
        color: "#8e24aa",
        layout: "/main"
      },
      {
        pageId: 202,
        path: "/opthandler",
        name: "จัดการข้อมูล อปท.",
        icon: KeyboardArrowRightTwoToneIcon,
        component: OptHandler,
        color: "#8e24aa",
        layout: "/main"
      },
      {
        pageId: 203,
        path: "/opt_index_outposition",
        name: "จัดการพ้นจากตำแหน่ง",
        icon: KeyboardArrowRightTwoToneIcon,
        component: OutPositionIndex,
        color: "#8e24aa",
        layout: "/main"
      },
      {
        pageId: 204,
        path: "/vote_date_index",
        name: "จัดการวันเลือกตั้ง",
        icon: KeyboardArrowRightTwoToneIcon,
        component: VoteDateIndex,
        color: "#8e24aa",
        layout: "/main"
      },
      {
        pageId: 205,
        path: "/opt_index_person",
        name: "จัดการข้อมูลผู้สมัคร",
        icon: KeyboardArrowRightTwoToneIcon,
        component: PersonIndex,
        color: "#8e24aa",
        layout: "/main"
      },
      {
        pageId: 206,
        path: "/opt_use_vote",
        name: "จัดการข้อมูลการใช้สิทธิ",
        icon: KeyboardArrowRightTwoToneIcon,
        component: UseVoteIndex,
        color: "#8e24aa",
        layout: "/main"
      },
      {
        pageId: 207,
        path: "/opt_vote_publish",
        name: "จัดการคะแนน/ผู้ได้รับเลือกตั้ง",
        icon: KeyboardArrowRightTwoToneIcon,
        component: VotePublishIndex,
        color: "#8e24aa",
        layout: "/main"
      },
      {
        pageId: 208,
        path: "/form_index_61",
        name: "จัดการแบบ ส.ถ./ผ.ถ. 6/1",
        icon: KeyboardArrowRightTwoToneIcon,
        component: Form61Index,
        color: "#8e24aa",
        layout: "/main"
      },

      {
        pageId: 209,
        path: "/report_opt",
        name: "รายงานออนไลน์/ทั่วไป",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ReportPage,
        color: "#8e24aa",
        layout: "/main"
      },
      {
        pageId: 210,
        path: "/checkc_opt",
        name: "ตรวจสอบข้อมูล",
        icon: KeyboardArrowRightTwoToneIcon,
        component: CheckOptPage,
        color: "#8e24aa",
        layout: "/main"
      },
      {
        pageId: 211,
        path: "/setting_opt",
        name: "ข้อมูลพื้นฐาน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: SettingOpt,
        color: "#8e24aa",
        layout: "/main"
      }
    ]
  },
  {
    pageId: 3,
    path: "/votecard",
    name: "ควบคุมการจัดสรรบัตรเลือกตั้ง",
    icon: Filter2Icon,
    collapse: true,
    component: MasterDataIndex,
    state: "votedateCollapse",
    layout: "/main",
    color: "#4caf50",
    views: [
      {
        pageId: 301,
        path: "/stock",
        name: "คลังบัตร",
        icon: KeyboardArrowRightTwoToneIcon,
        component: StockIndex,
        color: "#4caf50",
        layout: "/main"
      },
      {
        pageId: 302,
        path: "/votecardpub",
        name: "สั่งพิมพ์บัตรเลือกตั้ง",
        icon: KeyboardArrowRightTwoToneIcon,
        component: VoteCardPublishIndex,
        color: "#4caf50",
        layout: "/main"
      },
      {
        pageId: 303,
        path: "/purchase",
        name: "สั่งซื้อบัตรเลือกตั้ง",
        icon: KeyboardArrowRightTwoToneIcon,
        component: PurchaseIndex,
        color: "#4caf50",
        layout: "/main"
      },
      {
        pageId: 304,
        path: "/allocate",
        name: "จัดสรรบัตรเลือกตั้ง",
        icon: KeyboardArrowRightTwoToneIcon,
        component: AllocateIndex,
        color: "#4caf50",
        layout: "/main"
      },
      {
        pageId: 305,
        path: "/SendDataToPrinter",
        name: "ส่งข้อมูลจัดสรรบัตรไปโรงพิมพ์",
        icon: KeyboardArrowRightTwoToneIcon,
        component: AllocatePrintingIndex,
        color: "#4caf50",
        layout: "/main"
      },
      {
        pageId: 306,
        path: "/manageCardToBranch",
        name: "จัดการบัตรเลือกตั้งให้ อปท.",
        icon: KeyboardArrowRightTwoToneIcon,
        component: AllocateCardtoBranchIndex,
        color: "#4caf50",
        layout: "/main"
      },
      /* {
        pageId: 307,
        path: "/controlAllocateCard",
        name: "ควบคุมการจ่ายบัตรเลือกตั้ง",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ControlAllocateIndex,
        color: "#4caf50",
        layout: "/main"
      }, */
      {
        pageId: 308,
        path: "/followcard",
        name: "รายงานการรับบัตรเลือกตั้ง",
        icon: KeyboardArrowRightTwoToneIcon,
        component: FollowCardIndex,
        color: "#4caf50",
        layout: "/main"
      },
      {
        pageId: 309,
        path: "/report_votecard",
        name: "รายงาน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ReportVotecardIndex,
        color: "#4caf50",
        layout: "/main"
      },
      {
        pageId: 310,
        path: "/masterdata_index",
        name: "ข้อมูลพื้นฐาน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: MasterDataIndex,
        color: "#4caf50",
        layout: "/main"
      }
    ]
  },
  {
    pageId: 4,
    path: "/wtd",
    name: "ผู้ถูกเพิกถอนสิทธิเลือกตั้ง",
    icon: Filter3Icon,
    component: CheckWtdIndex,
    state: "wtdCollapse",
    collapse: true,
    layout: "/main",
    color: "#00acc1",
    views: [
      {
        pageId: 401,
        path: "/listwtd",
        name: "รายชื่อผู้ถูกเพิกถอนสิทธิเลือกตั้ง",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ListWtdIndex,
        color: "#00acc1",
        layout: "/main"
      },
      {
        pageId: 402,
        path: "/addwtd",
        name: "บันทึกข้อมูลผู้ถูกเพิกถอน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: AddWtdIndex,
        color: "#00acc1",
        layout: "/main"
      },
      {
        pageId: 403,
        path: "/report_wtd",
        name: "รายงานออนไลน์/ทั่วไป",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ReportWtdIndex,
        color: "#00acc1",
        layout: "/main"
      },
      {
        pageId: 404,
        path: "/checkwtd",
        name: "ตรวจสอบผู้ถูกเพิกถอน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: CheckWtdIndex,
        color: "#00acc1",
        layout: "/main"
      },
      {
        pageId: 405,
        path: "/settingwtd",
        name: "ข้อมูลพื้นฐาน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: SettingWtdIndex,
        color: "#00acc1",
        layout: "/main"
      }
    ]
  },
  {
    pageId: 5,
    path: "/suspend",
    name: "ผู้ถูกระงับ/เพิกถอนสิทธิสมัครฯ",
    icon: Filter4Icon,
    component: CheckSuspendIndex,
    collapse: true,
    state: "suspendCollapse",
    layout: "/main",
    color: "#e91e63",
    views: [
      {
        pageId: 501,
        path: "/listSuspend",
        name: "รายชื่อผู้ถูกระงับ/เพิกถอนสิทธิ",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ListSuspendIndex,
        color: "#e91e63",
        layout: "/main"
      },
      {
        pageId: 502,
        path: "/addsuspend",
        name: "บันทึกข้อมูลผู้ถูกระงับ/เพิกถอนสิทธิ",
        icon: KeyboardArrowRightTwoToneIcon,
        component: AddSuspendIndex,
        color: "#e91e63",
        layout: "/main"
      },
      {
        pageId: 503,
        path: "/report_suspend",
        name: "รายงาน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ReportSuspendIndex,
        color: "#e91e63",
        layout: "/main"
      },
      {
        pageId: 504,
        path: "/checksuspend",
        name: "ตรวจสอบสิทธิสมัครฯ",
        icon: KeyboardArrowRightTwoToneIcon,
        component: CheckSuspendIndex,
        color: "#e91e63",
        layout: "/main"
      },
      {
        pageId: 505,
        path: "/settingsuspend_index",
        name: "ข้อมูลพื้นฐาน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: SettingSuspendIndex,
        color: "#e91e63",
        layout: "/main"
      }
    ]
  },
  {
    pageId: 6,
    path: "/inspector",
    name: "ผู้ตรวจการเลือกตั้ง",
    icon: Filter5Icon,
    collapse: true,
    component: InspectorPage,
    state: "inspectorCollapse",
    layout: "/main",
    color: "#f44336",
    views: [
      {
        pageId: 601,
        path: "/recruitdate",
        name: "บันทึกวันรับสมัคร",
        icon: KeyboardArrowRightTwoToneIcon,
        component: SetRecruitIndex,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 602,
        path: "/setvotedate",
        name: "บันทึกวันเลือกตั้ง",
        icon: KeyboardArrowRightTwoToneIcon,
        component: SetVoteDateIndex,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 603,
        path: "/candidate",
        name: "ข้อมูลผู้สมัคร",
        icon: KeyboardArrowRightTwoToneIcon,
        component: AddCandidate,
        color: "#f44336",
        layout: "/main"
      },

      {
        pageId: 604,
        path: "/qualification_fileupload",
        name: "หลักฐานการตรวจสอบคุณสมบัติผู้สมัคร",
        icon: KeyboardArrowRightTwoToneIcon,
        component: Qualification,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 605,
        path: "/selection16",
        name: "รายชื่อที่ผ่านการคัดเลือก16คน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: Selection16,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 606,
        path: "/selection8",
        name: "รายชื่อที่ผ่านการคัดเลือก8คน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: Selection8,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 607,
        path: "/upload_command",
        name: "คำสั่งแต่งตั้ง ผตล.",
        icon: KeyboardArrowRightTwoToneIcon,
        component: Command,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 608,
        path: "/replace_central",
        name: "ผู้ดำรงตำแหน่งแทน ผตล.",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ReplaceCentral,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 609,
        path: "/promote_person",
        name: "ผลการจับสลากตำแหน่งผตล.จว.",
        icon: KeyboardArrowRightTwoToneIcon,
        component: PromotePerson,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 610,
        path: "/replace_brance",
        name: "ผู้ดำรงตำแหน่งแทน ผตล.จว.",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ReplaceBrance,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 611,
        path: "/effective_date",
        name: "วาระการดำรงตำแหน่ง ผตล.จว.",
        icon: KeyboardArrowRightTwoToneIcon,
        component: EffectiveDate,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 612,
        path: "/report_inspector",
        name: "การติดตามข้อมูล ผตล.",
        icon: KeyboardArrowRightTwoToneIcon,
        component: Moniter,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 614,
        path: "/report_page_inspect",
        name: "รายงาน",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ReportInspect,
        color: "#f44336",
        layout: "/main"
      },
      {
        pageId: 613,
        path: "/check_inspector",
        name: "ตรวจสอบรายชื่อ ผตล.",
        icon: KeyboardArrowRightTwoToneIcon,
        component: ListInspector,
        color: "#f44336",
        layout: "/main"
      }
    ]
  },
  {
    pageId: 7,
    path: "/commond",
    name: "ข้อมูลพื้นฐาน",
    icon: Settings,
    collapse: true,
    component: EduSettingIndex,
    state: "commondCollapse",
    layout: "/main",
    color: "#9c27b0",
    hide: true,
    views: [
      {
        pageId: 701,
        path: "/personsetting",
        name: "เกี่ยวกับบุคคล",
        icon: KeyboardArrowRightTwoToneIcon,
        component: PersonSettingIndex,
        color: "#9c27b0",
        layout: "/main"
      },
      {
        pageId: 702,
        path: "/edusetting",
        name: "เกี่ยวกับการศึกษา",
        icon: KeyboardArrowRightTwoToneIcon,
        component: EduSettingIndex,
        color: "#9c27b0",
        layout: "/main"
      }
    ]
  },
  {
    pageId: 8,
    path: "/admin",
    name: "ตั้งค่าระบบ",
    icon: GroupIcon,
    collapse: true,
    component: AdminManagement,
    state: "AdminCollapse",
    layout: "/main/admin",
    color: "#9c27b0",
    hide: true,
    views: [
      {
        pageId: 801,
        path: "/roles_manangement",
        name: "	Roles Management",
        icon: KeyboardArrowRightTwoToneIcon,
        component: RolesManagement,
        color: "#9c27b0",
        layout: "/main"
      },
      {
        pageId: 802,
        path: "/user_manangement",
        name: "	Users Management",
        icon: KeyboardArrowRightTwoToneIcon,
        component: UserIndex,
        color: "#9c27b0",
        layout: "/main"
      },
      {
        pageId: 803,
        path: "/userinrole",
        name: "	Users In Role",
        icon: KeyboardArrowRightTwoToneIcon,
        component: UserInRolesIndex,
        color: "#9c27b0",
        layout: "/main"
      },
      {
        pageId: 804,
        path: "/rolesinpage",
        name: "	Role In Page",
        icon: KeyboardArrowRightTwoToneIcon,
        component: RolesInPageIndex,
        color: "#9c27b0",
        layout: "/main"
      },
      {
        pageId: 805,
        path: "/notisetup",
        name: "	Notification Setup",
        icon: KeyboardArrowRightTwoToneIcon,
        component: NotiSettupPageIndex,
        color: "#9c27b0",
        layout: "/main"
      },
      {
        pageId: 806,
        path: "/popupsetup",
        name: "	PopUp Setup",
        icon: KeyboardArrowRightTwoToneIcon,
        component: PopUpSettupPageIndex,
        color: "#9c27b0",
        layout: "/main"
      }
    ]
  },
  {
    pageId: 9,
    path: "/notification",
    name: "ข้อความแจ้งเตือน",
    icon: NotificationsIcon,
    component: NotificationsIndex,
    layout: "/main",
    nonRestrict: true,
    hide: true
  }
];

export const getRoutes = pagePermission => {
  const routes = [];

  if (pagePermission === undefined) return routes;

  Routes.forEach((v, k) => {
    if (v.nonRestrict || pagePermission.includes(v.pageId)) {
      let viewInPage = [];
      if (v.views && v.views.length > 0) {
        v.views.forEach(item => {
          if (item.nonRestrict || pagePermission.includes(item.pageId)) {
            viewInPage.push(item);
          }
        });
        v.views = viewInPage;
      }
      routes.push(v);
    }
  });

  return routes;
};

export default Routes;
