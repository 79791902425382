import React, { useState } from "react";

import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useSweetAlert } from "components/Alert/SweetAlert";

const ConfirmLoginDialog = props => {
  const { sweetAlerts } = useSweetAlert();
  const [password, setPassword] = useState(null);

  //const { showupLoad, closeModalupLoad, children, onUploadSuccess } = props;
  const { onClose, open } = props;
  const [pass, setPass] = useState("");
  const [toggle, setToggle] = useState(false);
  const handleClickShowPassword = () => {
    setToggle(!toggle);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    if (password === null) {
      sweetAlerts.warning("กรุณาระบุรหัสผ่าน");
    } else {
      onClose();
    }
  };

  // const addSucess = affectedRows => {
  //   onUploadSuccess(affectedRows);
  // };
  return (
    <Dialog
      fullWidth={true}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
    >
      {/* <DialogTitle id="classic-modal-slide-title">
        <GridContainer
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <GridItem xs={12} sm={12} md={12}>
            <h4 style={{ textAlign: "center" }}>เข้าสู่ระบบ</h4>
          </GridItem>
        </GridContainer>
      </DialogTitle> */}
      <DialogTitle id="customized-dialog-title">
        กรุณาระบุรหัสผ่านเพื่อทำการเข้าสู่ระบบใหม่
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ width: "100%" }}>
          {/* <DialogContentText id="alert-dialog-description">
            session expired กรุณาระบุรหัสผ่านเพื่อทำการเข้าสู่ระบบใหม่
          </DialogContentText> */}

          {/* <CustomInput
            labelText="ชื่อผู้ใช้งาน"
            id="username"
            formControlProps={{ fullWidth: true, autoComplete: "off" }}
            inputProps={{
              value: user,
              autoComplete: "new-password",
              onChange: event => {
                userHandler(event.target.value);
              }
            }}
          /> */}
          <CustomInput
            labelText="ระบุรหัสผ่าน"
            id="password"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: toggle ? "text" : "password",
              autoComplete: "new-password",
              value: password,
              onChange: event => {
                setPassword(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {toggle ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <DialogActions>
          <Button onClick={handleCancel}>ยกเลิก</Button>
          <Button autoFocus onClick={handleOk} color="success">
            เข้าสู่ระบบ
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
export default ConfirmLoginDialog;
