import NumberFormat from "react-number-format";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";

const TextfieldIdCard = props => {
  let content = (
    <NumberFormat
      style={{
        width: "100%",
        margin: "0 0 17px 0",
        paddingTop: "27px"
      }}
      value={props.value}
      placeholder="เลขประจำตัวประชาชนที่ต้องการตรวจสอบ"
      customInput={TextField}
      format="# #### ##### ## #"
      mask="X"
      fullWidth
      // error={idValid}
      onValueChange={props.onChange}
    />

    // <CustomInput
    //   name={props.name}
    //   disabled={props.disabled}
    //   default
    //   required
    //   labelText={props.labelText}
    //   formControlProps={{
    //     fullWidth: true
    //   }}
    //   inputProps={{
    //     value: props.value || "",
    //     onChange: AutocompleteValidator(
    //       props.onChange,
    //       props.validators,
    //       setError,
    //       setHelperText
    //     )
    //   }}
    // />
  );

  return content;
};
export default TextfieldIdCard;
