import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
//-------------สี-----------------//

export function getColor() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardColorAll`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editColor(colorId, colorName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardColor/${colorId}`;

  const body = {
    colorName: colorName
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteColor(colorId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardColor/${colorId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addColor(colorName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardColor/`;

  const body = {
    colorName: colorName
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//-------------หมายเลข-----------------//

export function getNumber() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardNumberTypeAll`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editNumber(cardNumberId, startNumber, endNumber) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardNumberType/${cardNumberId}`;

  const body = {
    startNumber: startNumber,
    endNumber: endNumber,
    numbertext: startNumber + "-" + endNumber
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteNumber(cardNumberId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardNumberType/${cardNumberId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addNumber(startNumber, endNumber) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardNumberType`;

  const body = {
    startNumber: startNumber,
    endNumber: endNumber,
    numbertext: startNumber + "-" + endNumber
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//----------โรงพิมพ์-------------------

export function getPrinting() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/votePrintingHousesAll`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editPrinting(colorId, printingHousesName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/printingHouses/${colorId}`;

  const body = {
    printingHousesName: printingHousesName
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deletePrinting(printingHousesId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/printingHouses/${printingHousesId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addPrinting(printingHousesName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/printingHouses/`;

  const body = {
    printingHousesName: printingHousesName
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

//---------------------------------
export function addVoteCardDetail(printingHousesId, cardColorId, cardNumberId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardDetail`;

  const body = {
    printingHousesId: printingHousesId,
    cardNumberId: cardNumberId,
    cardColorId: cardColorId
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editVoteCardDetail(
  voteCardDetailId,
  printingHousesId,
  cardColorId,
  cardNumberId
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardDetail/${voteCardDetailId}`;

  const body = {
    printingHousesId: printingHousesId,
    cardNumberId: cardNumberId,
    cardColorId: cardColorId
  };

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delVoteCardDetail(voteCardDetailId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/voteCardDetail/${voteCardDetailId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
