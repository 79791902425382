import React, { useContext, useEffect, useState } from "react";
import {
  bulkUpdateOptPersonScore,
  getVotePublish
} from "contexts/API/OPT/OptVotePublish";

import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import FileSaver from "file-saver";
//import KeyValueSplit from "utils/KeyValueSplit";
import { FileUpload } from "components/Common/FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ImportOptPerson from "assets/resources/opt/import_opt_person.xlsx";
import ListboxArea from "components/Common/ListboxArea";
import ListboxAumphurArea from "components/Common/ListboxAumphurArea";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxVotedate from "components/Common/ListboxVotedate";
import MaterialTable from "components/Table/MaterialTable";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { VoteDateContext } from "contexts/VoteDate";
import XLSX from "xlsx";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import { onlyNumber } from "utils/Validators/Numbers";
//import parseValueWithID from "utils/Parser";
import { useSweetAlert } from "components/Alert/SweetAlert";

const OptVotepublishExcel = props => {
  const [columns, setColumns] = useState([]);
  const { sweetAlerts } = useSweetAlert();
  const {
    optVoteTypeSelected,
    onOptVoteTypeSelected,
    onOptTypeSingleSelected,
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,

    tambolSelected,
    optTypeSelected,
    onOptSelected,
    optSelected,

    onVotedateSelected,
    onAumphurAreaSelected,
    positionTypeSelected,
    aumphurAreaSelected,
    areaSelected,
    onAreaSelected,
    votedateSelected,

    getOptTypeSelectedLists
  } = useContext(VoteDateContext);
  const useStyles = makeStyles({
    buttoncustom: {
      fontSize: 14,
      fontWeight: 500
      //color: props => props.color
    }
  });
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [enableImport, setEnableImport] = useState(false);

  const getOptSubTypeId = () => {
    for (const [, v] of Object.entries(optTypeSelected)) {
      if (v.checked) {
        return parseInt(v.value);
      }
    }
    return -1;
  };

  const onClickSave = () => {
    if (optVoteTypeSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภทการเลืือกตั้ง");
      return;
    }

    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }

    if (optSelected === null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
      return;
    }

    if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันที่เลือกตั้ง");
      return;
    }

    /*if (getOptSubTypeId() === 1 && !aumphurAreaSelected) {
      sweetAlerts.warning("กรุณาเลือกเขตอำเภอ");
      return;
    }*/

    if (data.length <= 0 || !enableImport) {
      sweetAlerts.warning("กรุณานำเข้าข้อมูลก่อน");
      return;
    }

    bulkUpdateOptPersonScore(data)
      .then(result => {
        if (result.data !== null && result.data.data !== undefined) {
          const affected = result.data.data.reduce((t, c) => {
            return (t = t + c.affectedRows);
          }, 0);
          sweetAlerts.success(
            "นำเข้าข้อมูลสำเร็จ",
            <>
              <div style={{ textAlign: "center" }}>
                <b>
                  จำนวนทั้งหมด: <Badge color="success">{affected} </Badge>{" "}
                </b>
              </div>
            </>
          );
          cleanUp();
        }
      })
      .catch(err => {
        sweetAlerts.error("เกิดข้อผิดพลาดขึ้น", err.response.data.desc);
      });
  };

  const initRow = {
    A: "",
    B: "",
    C: "",
    D: "",
    W: "",
    X: ""
  };

  const onExcelFileHandler = e => {
    try {
      let personList = [];
      let data = new Uint8Array(e);
      let workbook = XLSX.read(data, { type: "array" });
      let ws = workbook.Sheets[workbook.SheetNames[0]];
      let rows = XLSX.utils.sheet_to_json(ws, {
        header: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "AA",
          "AB",
          "AC",
          "AD",
          "AE"
        ]
      });

      if (rows.length <= 1) {
        throw new Error("ไม่มีข้อมูล");
      }

      const headAt = 7; //เริ่มอ่าน header column
      console.log(rows);

      if (
        rows[headAt].A !== "ตำแหน่ง(ส.ถ./ผ.ถ.)" ||
        rows[headAt].B !== "เขตเลือกตั้งที่" ||
        rows[headAt].C !== "หมายเลขผู้สมัคร" ||
        rows[headAt].D !== "เลขประจำตัวประชาชน" ||
        rows[headAt].E !== "เพศ(ช/ญ)" ||
        rows[headAt].F !== "คำนำหน้าชื่อ" ||
        rows[headAt].G !== "ชื่อ" ||
        rows[headAt].H !== "นามสกุล" ||
        rows[headAt].W !== "รับสมัคร/ไม่รับสมัคร" ||
        rows[headAt].X !== "คะแนน"
      ) {
        throw new Error("กรุณาใช้ไฟล์ที่ export ออกจากระบบเท่านั้น");
      }

      return getVotePublish(
        optSelected,
        votedateSelected,
        optVoteTypeSelected,
        areaSelected,
        positionTypeSelected,
        aumphurAreaSelected
      )
        .then(r => {
          if (r.data.data && r.data.data.length <= 0) {
            throw Error("ไม่มีการจัดการเลือกตั้ง");
          }

          let memList = {};
          let presList = {};

          //map array to object
          if (
            r.data.data &&
            r.data.data.member &&
            r.data.data.member.length > 0
          ) {
            r.data.data.member.forEach(e => {
              memList[`${e.idCard}`] = e;
            });
          }
          //map array to object
          if (r.data.data && r.data.data.pres && r.data.data.pres.length > 0) {
            r.data.data.pres.forEach(e => {
              presList[`${e.idCard}`] = e;
            });
          }
          for (let i = headAt + 1; i < rows.length; i++) {
            let optPositionTypeId = 2;
            let row = Object.assign(initRow, rows[i]);
            let rowNum = rows[i].__rowNum__ + 1;
            //const optPositionKeyId = parseValueWithID(row.A);
            let optPositionKeyId = row.A.toString().trim();

            if (row.Y === "" && row.Z === "" && row.AA === "" && row.AB === "" && row.AC === "" && row.AD === "" && row.AE === "") {
              break;
            }

            console.log("optPositionKeyId >>", optPositionKeyId)
            if ("ผ.ถ." === optPositionKeyId) {
              optPositionTypeId = 1;
            } else if ("ส.ถ." === optPositionKeyId) {
              optPositionTypeId = 2;
            } else {
              throw new Error(
                `เซลล์ที่ A${rowNum} ข้อมูลไม่ถูกต้อง ต้องระบุ 'ส.ถ.' หรือ 'ผ.ถ.' เท่านั้น`
              );
            }

            if (optPositionTypeId === 2 && onlyNumber(row.B)[0]) {
              throw new Error(
                `เซลล์ที่ B${rowNum} เขตเลือกตั้งที่ ต้องเป็นตัวเลขเท่านั้น`
              );
            }

            if (onlyNumber(row.C)[0]) {
              throw new Error(
                `เซลล์ที่ C${rowNum} หมายเลขผู้สมัคร ต้องเป็นตัวเลขเท่านั้น`
              );
            }

            //let KVTitleName = KeyValueSplit(row.G.toString());
            let KVTitleName = row.F.toString();


            const idCard = row.D.toString();
            const isError = ThaiCitizenID(row.D.toString());

            if (isError[0]) {
              throw new Error(`เซลล์ที่ D${rowNum} ${isError[1]}`);
            }

            let p =
              optPositionTypeId === 1 ? presList[idCard] : memList[idCard];

            if (!p) {
              throw new Error(
                `แถวที่ ${rowNum} ไม่พบข้อมูลผู้สมัครที่นำเข้า สำหรับวันเลือกตั้งนี้`
              );
            }

            // if (MustIncludes(["รับ", "ไม่รับ"])(row.W)[0]) {
            //   throw new Error(
            //     `เซลล์ที่ W${rowNum} ข้อมูลไม่ถูกต้อง ต้องระบุ 'รับ' หรือ 'ไม่รับ' เท่านั้น`
            //   );
            // }

            if (onlyNumber(row.X)[0]) {
              throw new Error(
                `เซลล์ที่ X${rowNum} คะแนน ต้องเป็นตัวเลขเท่านั้น`
              );
            }

            // const voteSelectedStatusId = row.W === "รับ" ? 2 : 3;
            //console.log("row.W >>", row.W)
            //console.log("row.X >>", row.X)
            //console.log("row.X >>", parseInt(row.X))

            let score;
            if (row.W === "ไม่รับ") {
              score = 0;
            } else {
              score = parseInt(row.X);
            }

            if (optPositionTypeId === 1) {
              personList.push({
                FullName: KVTitleName + " " + row.G + " " + row.H,
                areaNo: row.B,
                NoOpt: row.C,
                optPersonId: p.optPersonPresId,
                score: score,
                optPositionTypeId: optPositionTypeId
                //   optVoteSelectedStatusId: voteSelectedStatusId
              });
            } else if (optPositionTypeId === 2) {
              personList.push({
                FullName: KVTitleName + " " + row.G + " " + row.H,
                areaNo: row.B,
                NoOpt: row.C,
                optPersonId: p.optPersonMemId,
                score: score,
                optPositionTypeId: optPositionTypeId
                //      optVoteSelectedStatusId: voteSelectedStatusId,
              });
            }
          }
          setData(personList);
          setColumns([
            {
              title: "ตำแหน่ง",
              field: "optPositionTypeId",
              render: row => (row.optPositionTypeId === 1 ? "ผ.ถ." : "ส.ถ."),
              width: "16px"
            },
            {
              title: "เขต",
              field: "areaNo",
              width: "2px"
            },
            {
              title: "หมายเลขผู้สมัคร",
              field: "NoOpt",
              type: "numeric",
              cellStyle: {
                minWidth: "5px",
                textAlign: "center"
              }
            },
            {
              title: "ชื่อ สกุล",
              field: "FullName",
              cellStyle: {
                minWidth: "100px",
                paddingRight: "25px"
              }
            },
            {
              title: "คะแนน",
              field: "score",
              type: "numeric",
              cellStyle: {
                minWidth: "50px"
              },
              render: row => {
                return !row.score ? 0 : row.score.toLocaleString();
              },

            }
          ]);
        })
        .catch(err => {
          sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
          //sweetAlerts.error("เกิดข้อผิดพลาด", err.response.data.desc);
        });
    } catch (err) {
      //sweetAlerts.error("เกิดข้อผิดพลาด", err.response.data.desc);
      sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
    }
  };


  const cleanUp = e => {
    setData([]);
  };

  useEffect(() => {
    if (!enableImport) {
      const getOptSubTypeId = () => {
        for (const [, v] of Object.entries(optTypeSelected)) {
          if (v.checked) {
            return parseInt(v.value);
          }
        }
        return -1;
      };

      if (
        enableImport &&
        (!(optSelected && votedateSelected && optVoteTypeSelected) ||
          (getOptSubTypeId() === 1 && !aumphurAreaSelected))
      ) {
        setEnableImport(false);
      }
      if (
        !enableImport &&
        optSelected &&
        votedateSelected &&
        optVoteTypeSelected
      ) {
        if (getOptSubTypeId() === 1) {
          //1 === อบจ.
          if (aumphurAreaSelected) {
            setEnableImport(true);
          } else {
            //setEnableImport(false);
            setEnableImport(true);
          }
        } else {
          setEnableImport(true);
        }
      }
    }
  }, [
    optSelected,
    votedateSelected,
    optVoteTypeSelected,
    aumphurAreaSelected,
    enableImport,
    optTypeSelected
  ]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CheckboxOpttype
                        optTypeSelected={optTypeSelected}
                        onOptTypeSelected={onOptTypeSingleSelected}
                      ></CheckboxOpttype>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxOptVotetype
                        value={optVoteTypeSelected}
                        onChange={onOptVoteTypeSelected}
                      ></ListboxOptVotetype>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxProvince
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                      ></ListboxProvince>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxDistinct
                        value={amphurSelected}
                        provinceSelected={provinceSelected}
                        onChange={onAmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxOpts
                        value={optSelected}
                        getOptTypeSelectedLists={getOptTypeSelectedLists}
                        optTypeSelected={optTypeSelected}
                        provinceSelected={provinceSelected}
                        amphurSelected={amphurSelected}
                        tambolSelected={tambolSelected}
                        onChange={onOptSelected}
                      ></ListboxOpts>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxVotedate
                        value={votedateSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onVotedateSelected}
                      ></ListboxVotedate>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxAumphurArea
                        value={aumphurAreaSelected}
                        optSelected={optSelected}
                        votedateSelected={votedateSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onAumphurAreaSelected}
                      ></ListboxAumphurArea>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxArea
                        value={areaSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        votedateSelected={votedateSelected}
                        onChange={onAreaSelected}
                      ></ListboxArea>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer
                justify="space-between"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={12} sm={12} md={6}>
                  <FileUpload
                    id="file-upload-2"
                    label="แนบไฟล์ (เฉพาะ Excel เท่านั้น)"
                    remove
                    onFileHandler={onExcelFileHandler}
                    fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    btnName={<>Browse</>}
                    btnProps={
                      enableImport
                        ? {
                          color: "primary"
                        }
                        : { disabled: true, color: "primary" }
                    }
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  ดาวน์โหลดแบบฟอร์มการนำเข้าข้อมูล:{" "}
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      FileSaver.saveAs(
                        ImportOptPerson,
                        "import_opt_person_score.xlsx"
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faFileExcel} /> แบบฟอร์ม
                  </Button>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={12} sm={12} md={12}>
                  {data.length > 0 ? (
                    <MaterialTable
                      title="ยืนยันข้อมูลผลคะแนนที่นำเข้า"
                      columns={columns}
                      data={data}
                      enableInitColumns={false}
                      options={{
                        headerStyle: {
                          textAlign: "center"
                        },
                        exportButton: false
                      }}
                    />
                  ) : null}
                </GridItem>
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button className={classes.buttoncustom}>ยกเลิก</Button>
                  <Button
                    disabled={data.length === 0}
                    color="success"
                    className={classes.buttoncustom}
                    onClick={onClickSave}
                  >
                    ยืนยันนำเข้าข้อมูล
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default OptVotepublishExcel;
