import React, { useContext, useState } from "react";
/* API */
import {
  getCheckingInspector,
  getCheckingProvinceInspector
} from "contexts/API/INSPEC/Checking";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectApplyDate from "components/Common/ListboxInspectApplyDate";
import ListboxInspectVoteDateInquire from "components/Common/ListboxInspectVoteDateInquire";

import ListboxProvince from "components/Common/ListboxProvince";
/* Material Components */
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import { Paper } from "@material-ui/core";
import { useSweetAlert } from "components/Alert/SweetAlert";

const ListInspector = ({ pageProps, setPageProps }) => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [, setClickSearch] = useState(false);
  const { sweetAlerts } = useSweetAlert();

  const {
    provinceSelected,
    onProvinceSelected,

    // inspectVotedateSelected,
    // onInspectVotedateSelected,
    onVotedateSelected,
    votedateSelected,

    inspectApplyDateSelected,
    onInspectApplyDateSelected,
    //setInspectApplyDateSelected
  } = useContext(InspectorContext);

  const columns = [
    {
      title: "ชื่อ - สกุล",
      field: "fullname",
      exportData: row => {
        return row.title + " " + row.firstname + " " + row.lastname;
      },
      render: rowData => {
        return rowData.title + " " + rowData.firstname + " " + rowData.lastname;
      }
    },
    {
      title: "สถานะ",
      field: "position",
      cellStyle: {
        minwidth: "200px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "ผู้ดำรงตำแหน่งแทน",
      field: "replacePerson",
      cellStyle: {
        minwidth: "150px"
      }
    }
  ];
  const columns1 = [
    {
      title: "ชื่อ - สกุล",
      field: "fullname",
      exportData: row => {
        return row.titleName + " " + row.firstname + " " + row.lastname;
      },
      render: rowData => {
        return (
          rowData.titleName + " " + rowData.firstname + " " + rowData.lastname
        );
      }
    },
    {
      title: "ภูมิลำเนาเดิม",
      field: "provinceCommand",
      cellStyle: {
        minwidth: "200px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      editable: "never"
    },
    {
      title: "สถานะ",
      field: "promoteStatusName",
      cellStyle: {
        minwidth: "200px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      editable: "never"
    },
    {
      title: "ผู้ดำรงตำแหน่งแทน",
      field: "replacePerson",
      cellStyle: {
        minwidth: "150px"
      },
      editable: "never"
    }
  ];
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const optionsTable = {};

  const handelCancel = () => {
    setData([]);
  };
  const handelCancel1 = () => {
    setData1([]);
  };
  const onClickgetInspect = e => {
    setClickSearch(false);

    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }
    if (inspectApplyDateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันที่สมัคร");
      return;
    } else {
      setLoading(true);
      getCheckingInspector(provinceSelected.id, inspectApplyDateSelected.id)
        .then(result => {
          setData(result.data.data);

          setLoading(false);
        })
        .catch(err => {
          //setLoading(false);
          sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
        });
    }
  };

  const onClickgetProvinceInspect = e => {
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }
    if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันเลือกตั้งในระบบ");
      return;
    } else {
      setLoading1(true);
      getCheckingProvinceInspector(
        provinceSelected.id,
        votedateSelected.id
      )
        .then(result => {
          setData1(result.data.data);

          setLoading1(false);
        })
        .catch(err => {
          setLoading1(false);
          sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
        });
    }
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="danger" text>
              <CardText color="danger">
                <h4>ตรวจสอบรายชื่อผู้ตรวจการเลือกตั้ง</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxProvince
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                        required={true}
                      ></ListboxProvince>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxInspectApplyDate
                        value={inspectApplyDateSelected}
                        onChange={onInspectApplyDateSelected}
                      //required={true}
                      ></ListboxInspectApplyDate>

                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <GridItem xs={6} sm={6} md={6}></GridItem>
                    <GridItem>
                      <Button onClick={handelCancel}>ยกเลิก</Button>
                      <Button color="info" onClick={onClickgetInspect}>
                        ค้นหา
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="danger" text>
              <CardText color="danger">
                <h4>บัญชีรายชื่อผู้ตรวจการเลือกตั้งประจำจังหวัด</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                      <ListboxInspectVoteDateInquire
                        title={"วันที่เลือกตั้ง"}
                        value={votedateSelected}
                        required={true}
                        onChange={onVotedateSelected}
                      ></ListboxInspectVoteDateInquire>

                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <GridItem xs={6} sm={6} md={6}></GridItem>
                    <GridItem>
                      <Button onClick={handelCancel1}>ยกเลิก</Button>
                      <Button color="info" onClick={onClickgetProvinceInspect}>
                        ค้นหา
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {loading ? (
              <LoadingData />
            ) : (
              <MaterialTable
                title={
                  "รายชื่อผู้สมัครที่ได้รับการขึ้นบัญชีเป็นผู้ตรวจการเลือกตั้ง (บัญชี ผตล. 8 คน)"
                }
                options={optionsTable}
                columns={columns}
                data={data}
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}
              />
            )}
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {loading1 ? (
              <LoadingData />
            ) : (
              <MaterialTable
                title={
                  "บัญชีรายชื่อผู้ตรวจการเลือกตั้งประจำจังหวัด (บัญชี ผตล.จว.)"
                }
                options={optionsTable}
                columns={columns1}
                data={data1}
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}
              />
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default ListInspector;
