import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
/* custom components */
import { Validate } from "utils/Validators/Validate";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
import useFetch from "hooks/useFetch";

const ListboxSystemList = props => {
  const [, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [valid, setValid] = React.useState();
  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/um/systemList",
    []
  );

  let content = (
    <TextField disabled label="เลือกระบบ" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return { id: v.id, value: v.deteil };
    });
    data = [{ id: 0, value: "" }, ...data];
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        name={props.name}
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        options={data}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={props.title ? props.title : "เลือกระบบ"}
              margin="normal"
              error={valid}
              fullWidth
              helperText={helperText}
              onChange={Validate(
                params.inputProps.value,
                props.required,
                setValid
              )}
              required={props.required}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }

  return content;
};
export default ListboxSystemList;
