import React, { useContext, useState } from "react";

//วันที่รับสมัคร
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectApplyDate from "components/Common/ListboxInspectApplyDate";
import ListboxInspectApplyDateSingle from "components/Common/ListboxInspectApplyDateSingle";
import LoadingData from "components/Common/LoadingData";
//import { InspectorContext } from "contexts/Inspector";
import MaterialTable from "components/Table/MaterialTable";
import { Paper } from "@material-ui/core";
import { getReport02 } from "contexts/API/INSPEC/Moniter";
import { useSweetAlert } from "components/Alert/SweetAlert";

const StatInspectorofDay = props => {
  const [data, setData] = useState();
  const { sweetAlerts } = useSweetAlert();
  const [loading, setLoading] = useState(false);
  const optionsTable = {};
  const {
    onInspectApplyDateSelected,
    inspectApplyDateSelected,
    onInspectApplyDateSelectedSingle,
    inspectApplyDateSelectedSingle
  } = useContext(InspectorContext);
  // const [
  //   onInspectApplyDateSelected,
  //   setInspectCadidateDateOneSelected
  // ] = useState(null);
  // const onInspectCadidateDateOneSelected = (evt, newValue) => {
  //   setInspectCadidateDateOneSelected(newValue);
  // };

  const columns = [
    {
      title: "จังหวัด",
      field: "province_name"
    },
    {
      title: "จำนวนผู้สมัคร",
      field: "applicants",
      cellStyle: {
        minWidth: "150px",
        alignItems: "center"
      }
    }
  ];

  const handelSubmit = async () => {
    if (!inspectApplyDateSelected || !inspectApplyDateSelectedSingle) {
      sweetAlerts.warning("กรุณาเลือกวันที่สมัคร");
    } else {
      setLoading(true);
      try {
        const res = await getReport02(
          inspectApplyDateSelected.id,
          inspectApplyDateSelectedSingle.id
        );
        setData(res.data.data);

        setLoading(false);
      } catch (error) {
        HandelError(error);
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", error.toString());
      }
    }
  };
  return (
    <div>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <ListboxInspectApplyDate
                  title={"ช่วงวันที่รับสมัคร"}
                  period={true}
                  required={true}
                  inspectApplyDateSelected={inspectApplyDateSelected}
                  onChange={onInspectApplyDateSelected}
                ></ListboxInspectApplyDate>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <ListboxInspectApplyDateSingle
                  required={true}
                  value={inspectApplyDateSelectedSingle}
                  inspectApplyDateSelected={inspectApplyDateSelected}
                  onChange={onInspectApplyDateSelectedSingle}
                ></ListboxInspectApplyDateSingle>
              </GridItem>
            </GridContainer>
            <br /> <br />
            <GridContainer
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <GridItem xs={6} sm={6} md={6}></GridItem>
              <GridItem>
                <Button>ยกเลิก</Button>
                <Button color="info" onClick={handelSubmit}>
                  ค้นหา
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>

      <GridContainer>
        {loading ? (
          <LoadingData />
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <MaterialTable
              title={"สรุปข้อมูสถิติการรับสมัครผู้ตรวจการเลือกตั้ง"}
              options={optionsTable}
              columns={columns}
              data={data}
              components={{
                Container: props => <Paper {...props} elevation={0} />
              }}
            />
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
};
export default StatInspectorofDay;
