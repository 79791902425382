import React, { useState } from "react";

import FileSaver from "file-saver";
import PropTypes from "prop-types";
import { downloadFileFromUrl } from "contexts/API/FileManagement/download";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    // alignItems: "center",
    width: "100%",
    "box-shadow": "none"
  },

  divider: {
    height: 28,
    margin: 4
  }
}));

export function LinkDownload(props) {
  const classes = useStyles();

  const [fileName] = useState(props.fileName || props.label || "ไม่มีไฟล์");

  const [url] = useState(props.url || null);
  //let fileInputRef = React.createRef();
  const downloadFile = () => {
    if (url && fileName) {
      downloadFileFromUrl(url)
        .then(res => {
          //   console.log(res);
          FileSaver.saveAs(res.data, fileName);
        })
        .catch(err => {
          console.log("download file failed.");
        });
    } else {
      throw Error(
        "url not found, see https://github.com/DGA-SCD/ECTPL/issues/463"
      );
    }
  };

  return (
    <div className={classes.root}>
      <li>
        <a
          role="button"
          href="#/"
          onClick={downloadFile}
          style={{ cursor: "pointer" }}
        >
          {props.fileName}
        </a>
        {/* <p onClick={downloadFile}>{props.fileName}</p> */}
      </li>
    </div>
  );
}

LinkDownload.prototype = {
  fileName: PropTypes.string /* filename */,
  fileId: PropTypes.string,
  url:
    PropTypes.string /* url to download file, shoul get it from getFileDownloadUrl() */
  //   onFileHandler: PropTypes.func /* callback function to manage file */
};

export default LinkDownload;
