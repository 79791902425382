import React, { useContext, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datepicker from "components/Common/Datepicker";
import DatepickerVotedate from "components/Common/Datepicker";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { InspectorContext } from "contexts/Inspector";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxInspectPersonSelect16 from "components/Common/ListboxInspectPersonSelect16";
import ListboxInspectPositionStatus from "components/Common/ListboxInspectPositionStatus";
import ListboxInspectTitleList from "components/Common/ListboxInspectTitleList";
import ListboxProvince from "components/Common/ListboxProvince.js";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import NumberFormat from "react-number-format";
import Radio from "@material-ui/core/Radio";
import Slide from "@material-ui/core/Slide";
// material-ui components
import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
// core components
import getAge from "utils/CalculateAge";
import { getCitized } from "contexts/API/INSPEC/Candidate";
import { makeStyles } from "@material-ui/core/styles";
import { updatePersonForReplace } from "contexts/API/INSPEC/ReplaceBrance";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalReplacement = props => {
  const { sweetAlerts } = useSweetAlert();
  const { inspectApplyDateSelected, provinceSelected } = useContext(
    InspectorContext
  );

  let initialState = {
    idCard: null,

    birthdate: null,

    personAge: null,
    firstName: null,
    lastName: null,
    title: { id: 0 },

    addr1: null,
    addr1AmphurId: 0,
    addr1ProvinceId: 0,
    addr1TambolId: 0,
    addr1Province: { id: 0 },
    addr1Amphur: { id: 0 },
    addr1Tambol: { id: 0 }
  };
  const { showModalReplacement, closeModalReplacement, childDetail } = props;
  const [inspectPositionStatus, setInspectPositionStatus] = useState(null);
  const [
    personReplaceStartEffectiveDate,
    setPersonReplaceStartEffectiveDate
  ] = useState(null);
  const [replaceRemarkOther, setReplaceRemarkOther] = useState(null);
  const [helpertextGender, setHelpertextGender] = useState("");
  const [helpertextaddr1, setHelpertextaddr1] = useState("");
  const [inspectPersonSelected, setInspectPersonSelected] = useState(null);
  const [endEffectiveDatetime, setEndEffectiveDatetime] = useState(null);
  const [data, setData] = useState(initialState);
  const [isSelectOtherPerson, setIsSelectOtherPerson] = useState(false);
  const [isIdCardValid, setIsIdCardValid] = useState(false);
  const [helpertextFirstName, setHelpertextFirstName] = useState("");
  const [helpertextLastName, setHelpertextLastName] = useState("");
  const [helpertextTitle, setHelpertextTitle] = useState("");
  const [helpertextProvince1, setHelpertextProvince1] = useState("");
  const [helpertextIdCard, setHelpertextIdCard] = useState("");
  const [helpertextBirthDate, setHelpertextBirthDate] = useState(null);
  const [helpertextTambol1, setHelpertextTambol1] = useState("");
  const [helpertextAmphur1, setHelpertextAmphur1] = useState("");
  const [addr1Province, setAddr1Province] = useState({ id: 0 });
  const [addr1Amphur, setAddr1Amphur] = useState({ id: 0 });
  const [addr1Tambol, setAddr1Tambol] = useState({ id: 0 });
  const [title, setTitle] = useState({ id: 0 });
  const classes = useStyles();
  const onadd1ProvinceSelected = (evt, newValue) => {
    setAddr1Province(newValue);
    setAddr1Amphur({ id: 0 });
    setAddr1Tambol({ id: 0 });

    setData({
      ...data,
      addr1Province: newValue ? newValue : { id: 0 },
      addr1ProvinceId: newValue ? newValue.id : 0
    });

    if (newValue) {
      if (newValue.id === 0) {
        setHelpertextProvince1("กรุณาระบุ*");
      } else {
        setHelpertextProvince1("");
      }
    }
  };
  const onTilte = (evt, newValue) => {
    setTitle(newValue);
    setData({ ...data, titleId: newValue ? newValue.id : 0 });
  };

  const onadd1AmphurSelected = (evt, newValue) => {
    setAddr1Amphur(newValue);
    setAddr1Tambol({ id: 0 });

    setData({
      ...data,
      addr1Amphur: newValue ? newValue : { id: 0 },
      addr1AmphurId: newValue ? newValue.id : 0
    });

    if (newValue) {
      if (newValue.id === 0) {
        setHelpertextAmphur1("กรุณาระบุ*");
      } else {
        setHelpertextAmphur1("");
      }
    }
  };

  const onadd1TambolSelected = (evt, newValue) => {
    setAddr1Tambol(newValue);

    setData({
      ...data,
      addr1Tambol: newValue ? newValue.id : { id: 0 },
      addr1TambolId: newValue ? newValue.id : 0
    });
  };

  const onInspectPositionStatus = (evt, newValue) => {
    setInspectPositionStatus(newValue);
  };
  const onInspectPersonSelected = (evt, newValue) => {
    setInspectPersonSelected(newValue);
  };
  const rePlacetPerson = () => {
    let body = "";

    if (
      endEffectiveDatetime === null ||
      personReplaceStartEffectiveDate === null
    ) {
      sweetAlerts.warning("กรุณาระบุวันที่ให้ครบถ้วน");
      return;
    }
    if (inspectPositionStatus === null) {
      sweetAlerts.warning("กรุณาระบุสาเหตุ");
      return;
    }
    if (inspectPersonSelected === null && isSelectOtherPerson === false) {
      sweetAlerts.warning("กรุณาระบุเลือกผู้สมัคร");
      return;
    } else {
      body = {
        isSelectOtherPerson: isSelectOtherPerson,
        commandApplyDateId: inspectApplyDateSelected.id,
        provinceId: provinceSelected.id,
        personApplyCommandId: childDetail.personApplyCommandId,
        oldReplacePersonApplyCommandId: childDetail.replacePersonApplyCommandId,
        positionStatusId: inspectPositionStatus
          ? inspectPositionStatus.id
          : null,
        newReplacePersonApplyCommandId: inspectPersonSelected
          ? inspectPersonSelected.id
          : null,
        oldPositionStatusId: childDetail.positionStatusId,
        replaceRemarkOther: replaceRemarkOther,
        endEffectiveDatetime: endEffectiveDatetime,
        personReplaceStartEffectiveDate: personReplaceStartEffectiveDate
      };
    }
    // if (inspectPersonSelected && inspectPositionStatus) {
    //   body = {
    //     newReplacePersonApplyCommandId: inspectPersonSelected.id,
    //     positionStatusId: inspectPositionStatus.id
    //   };
    // }

    if (isSelectOtherPerson) {
      if (data.idCard === null) {
        setHelpertextIdCard("กรุณาระบุ*");
      }
      if (data.birthdate === null) {
        setHelpertextBirthDate("กรุณาระบุ*");
      }
      if (data.firstName === null || data.firstName === "") {
        setHelpertextFirstName("กรุณาระบุ*");
      }
      if (data.lastName === null || data.lastName === "") {
        setHelpertextLastName("กรุณาระบุ*");
      }
      if (data.genderId === null) {
        setHelpertextGender("กรุณาระบุ*");
      }

      if (data.addr1 === null || data.addr1 === "") {
        setHelpertextaddr1("กรุณาระบุ*");
      }
      if (data.addr1TambolId === 0) {
        setHelpertextTambol1("กรุณาระบุ*");
      }
      if (data.addr1AmphurId === 0) {
        setHelpertextAmphur1("กรุณาระบุ*");
      }
      if (data.titleId === 0) {
        setHelpertextTitle("กรุณาระบุ*");
      }

      // console.log(data);
      if (
        data.idCard &&
        data.addr1 &&
        data.titleId &&
        data.lastName &&
        data.firstName &&
        data.genderId !== null &&
        data.addr1AmphurId !== 0 &&
        data.addr1TambolId !== 0 &&
        data.addr1ProvinceId !== 0
      ) {
        body.person = data;

        // console.log(body);

        updatePersonForReplace(body)
          .then(result => {
            // dataUpdate[index] = newData;
            // setData([...dataUpdate]);
            setData(initialState);
            setIsSelectOtherPerson(false);
            setInspectPositionStatus(null);
            setInspectPersonSelected(null);
            setEndEffectiveDatetime(null);
            setAddr1Province({ id: 0 });
            setPersonReplaceStartEffectiveDate(null);
            closeModalReplacement();
            props.onaddSuccess(result.data.success);

            sweetAlerts.success("บันทึกข้อมูลเรียบร้อยแล้ว");
          })
          .catch(err => {
            sweetAlerts.danger(
              "ไม่สามารถบันทึกข้อมูลได้",
              err.response.data.desc
            );
          });
      } else {
        sweetAlerts.warning("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
    } else {
      updatePersonForReplace(body)
        .then(result => {
          // dataUpdate[index] = newData;
          // setData([...dataUpdate]);
          closeModalReplacement();
          props.onaddSuccess(result.data.success);

          sweetAlerts.success("บันทึกข้อมูลเรียบร้อยแล้ว");
        })
        .catch(err => {
          sweetAlerts.danger("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
        });
    }
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const onIDCardHandler = v => {
    setData({ ...data, idCard: v.value });
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      if (isError) {
        sweetAlerts.warning("เลขประจำตัวประชาชนผิด");
        if (isIdCardValid === true) {
          setIsIdCardValid(false);
        }
      } else {
        if (isIdCardValid === false) {
          setIsIdCardValid(true);
        }
      }
    } else {
      if (isIdCardValid === true) {
        setIsIdCardValid(false);
      }
    }
  };
  async function fetchData(idCard) {
    try {
      const person = await getCitized(idCard);
      let chkdata = person.data.data;
      let data = person.data.data[0];
      if (chkdata.length > 0 && chkdata[0] != null) {
        setData({
          ...data,

          personAge: getAge(
            data.birthdate !== undefined ? data.birthdate : new Date()
          )
        });
        setTitle({ id: data.titleId, value: data.titleDetail });
        setAddr1Province({
          id: data.addr1ProvinceId,
          value: data.addr1ProvinceName
        });
        setAddr1Amphur({
          id: data.addr1AmphurId,
          value: data.addr1AmphurName
        });
        setAddr1Tambol({
          id: data.addr1TambolId,
          value: data.addr1TambolName
        });
      } else {
        sweetAlerts.warning("ไม่พบเลขประจำตัวประชาชนนี้ในระบบ");

        setData({
          ...initialState,
          idCard: idCard
        });
        setAddr1Province({ id: 0 });
        setAddr1Tambol({ id: 0 });
      }
    } catch (error) {
      sweetAlerts.error("ไม่สามารถดึงข้อมูล", error.toString());
    }
  }
  return (
    <div className={showModalReplacement ? "modal" : "hide"}>
      <Dialog
        open={showModalReplacement}
        fullWidth={true}
        transition={Transition}
        keepMounted
        onClose={closeModalReplacement}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          {/* <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            เพิ่มข้อมูลบัตรเลือกตั้ง
          </div> */}
          <CardHeader color="danger">
            <h4 className={classes.cardTitle}>รายชื่อผู้ดำรงตำแหน่งแทน</h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxInspectPositionStatus
                title={"สาเหตุการสิ้นสุดการดำรงตำแหน่ง"}
                isFilter={true}
                value={inspectPositionStatus}
                onChange={onInspectPositionStatus}
              ></ListboxInspectPositionStatus>
            </GridItem>
            {inspectPositionStatus && inspectPositionStatus.id === 5 ? (
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  default
                  required
                  labelText="ระบุสาเหตุ"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpertext="กรุณาระบุ*"
                  inputProps={{
                    value: replaceRemarkOther,

                    onChange: e => {
                      setReplaceRemarkOther(e.target.value);
                    }
                  }}
                />
              </GridItem>
            ) : (
              ""
            )}
            <GridItem xs={6} sm={6} md={6}>
              <Datepicker
                label="วันที่สิ้นสุดการดำรงตำแหน่ง"
                value={endEffectiveDatetime}
                onChange={date => {
                  let arr = date.format().split("T");
                  setEndEffectiveDatetime(arr[0]);
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxInspectPersonSelect16
                provinceId={provinceSelected}
                // cmtReplace={true}
                disabled={isSelectOtherPerson}
                inspectApplyDateSelected={inspectApplyDateSelected}
                onChange={onInspectPersonSelected}
              ></ListboxInspectPersonSelect16>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Datepicker
                id="date-picker-president"
                label="วันที่เริ่มการดำรงตำแหน่ง"
                value={personReplaceStartEffectiveDate}
                onChange={date => {
                  let arr = date.format().split("T");
                  setPersonReplaceStartEffectiveDate(arr[0]);
                }}
              />
            </GridItem>
          </GridContainer>
          <br></br>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSelectOtherPerson}
                    onChange={event => {
                      setIsSelectOtherPerson(event.target.checked);
                      if (!event.target.checked) {
                        setData(initialState);
                        setAddr1Province({ id: 0 });
                        setAddr1Tambol({ id: 0 });
                      }
                    }}
                    color="primary"
                  />
                }
              // label="ผตล. 2/1"
              />
              <span>เพิ่มผู้ดำรงตำแหน่งนอกเหนือจากรายชื่อที่มีอยู่</span>
            </GridItem>
          </GridContainer>
          <br></br>

          <div style={{ display: !isSelectOtherPerson ? "none" : "block" }}>
            <DialogTitle id="classic-modal-slide-title">
              {/* <Badge color="rose">
              {" "}
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                ข้อมูลการดำรงตำแหน่งแทน{" "}
              </div>
            </Badge> */}
              <Button disabled={true}>ข้อมูลการดำรงตำแหน่งแทน</Button>
            </DialogTitle>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <NumberFormat
                  style={{
                    width: "100%",
                    margin: "0 0 17px 0",
                    paddingTop: "27px"
                  }}
                  value={data.idCard}
                  placeholder="เลขประจำตัวประชาชน"
                  customInput={TextField}
                  format="# #### ##### ## #"
                  mask="X"
                  fullWidth
                  onValueChange={onIDCardHandler}
                />
                <span style={{ color: "red" }}>{helpertextIdCard}</span>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Button
                  disabled={!isIdCardValid}
                  variant="contained"
                  color="success"
                  onClick={() => fetchData(data.idCard)}
                >
                  ตรวจสอบ
                </Button>
              </GridItem>

              <GridItem xs={6} sm={6} md={6}>
                <ListboxInspectTitleList
                  disabled={isIdCardValid === false}
                  value={title}
                  controll={true}
                  onChange={onTilte}
                ></ListboxInspectTitleList>
                <span style={{ color: "red" }}>{helpertextTitle}</span>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  id="firstName"
                  default
                  required
                  labelText="ชื่อ"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpertext="กรุณาระบุ*"
                  inputProps={{
                    disabled: isIdCardValid === false,
                    value: data.firstName || "",
                    autoComplete: "new-password",
                    onChange: e => {
                      if (verifyLength(e.target.value, 2)) {
                        setHelpertextFirstName("");
                      } else {
                        setHelpertextFirstName("กรุณาระบุ");
                      }
                      setData({ ...data, firstName: e.target.value });
                    }
                  }}
                />
                <span style={{ color: "red" }}>{helpertextFirstName}</span>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  id="lastName"
                  default
                  required
                  labelText="นามสกุล"
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpertext="กรุณาระบุ*"
                  inputProps={{
                    autoComplete: "new-password",
                    disabled: isIdCardValid === false,
                    value: data.lastName || "",
                    onChange: e => {
                      if (verifyLength(e.target.value, 2)) {
                        setHelpertextLastName("");
                      } else {
                        setHelpertextLastName("กรุณาระบุ");
                      }
                      setData({ ...data, lastName: e.target.value });
                    }
                  }}
                />
                <span style={{ color: "red" }}>{helpertextLastName}</span>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={6}
                style={{ verticalAlign: "middle" }}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={
                        data.genderId === "true" || data.genderId === true
                      }
                      value={true}
                      onClick={e => {
                        if (e.target.checked) {
                          setHelpertextGender("");
                        } else {
                          setHelpertextGender("กรุณาระบุ*");
                        }
                        setData({ ...data, genderId: e.target.value });
                      }}
                      // onClick={
                      //   e =>
                      //     setData({ ...data, genderId: e.target.value })
                      //   //  handleGenderChange(e, "genderId")
                      // }
                      disabled={isIdCardValid === false}
                      id="genderId"
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  label="ชาย"
                />
                <FormControlLabel
                  control={
                    <Radio
                      onClick={e => {
                        if (e.target.checked) {
                          setHelpertextGender("");
                          //setGenderState(true);
                        } else {
                          setHelpertextGender("กรุณาระบุ*");
                          // setGenderState(false);
                        }
                        setData({ ...data, genderId: e.target.value });
                      }}
                      checked={
                        data.genderId === "false" || data.genderId === false
                      }
                      disabled={isIdCardValid === false}
                      value={false}
                      id="genderId"
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  label="หญิง"
                />
                <span style={{ color: "red" }}>{helpertextGender}</span>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <DatepickerVotedate
                  name="birthday"
                  label="วัน/เดือน/ปี เกิด"
                  value={data.birthdate}
                  disabled={isIdCardValid === false}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      if (
                        getAge(date.format()) < 45 ||
                        getAge(date.format()) > 70
                      ) {
                        sweetAlerts.warning(
                          `อายุต้องไม่ต่ำว่า 45 ปี และไม่เกิน 70ปี`
                        );
                        setHelpertextBirthDate("กรุณาระบุ*");
                        setData({
                          ...data,
                          birthdate: null,
                          personAge: null
                        });
                      } else {
                        setData({
                          ...data,
                          birthdate: arr[0],
                          personAge: getAge(date.format())
                        });
                        setHelpertextBirthDate("");
                      }
                    }
                  }}
                ></DatepickerVotedate>
                <span style={{ color: "red" }}>{helpertextBirthDate}</span>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  id="personAge"
                  labelText="อายุ"
                  disabled={isIdCardValid === false}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: data.personAge || "",
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  id="addr1"
                  default
                  required
                  labelText="ที่อยู่ตามทะเบียนบ้าน"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: isIdCardValid === false,
                    autoComplete: "new-password",
                    value: data.addr1 || "",
                    onChange: e => {
                      if (verifyLength(e.target.value, 2)) {
                        setHelpertextaddr1("");
                      } else {
                        setHelpertextaddr1("กรุณาระบุ*");
                      }
                      setData({ ...data, addr1: e.target.value });
                    }
                  }}
                />
                <span style={{ color: "red" }}>{helpertextaddr1}</span>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ListboxProvince
                  controll={true}
                  value={addr1Province}
                  onChange={onadd1ProvinceSelected}
                ></ListboxProvince>
                <span style={{ color: "red" }}>{helpertextProvince1}</span>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ListboxDistinct
                  controll={true}
                  disabled={isIdCardValid === false}
                  provinceSelected={addr1Province}
                  value={addr1Amphur}
                  onChange={onadd1AmphurSelected}
                ></ListboxDistinct>
                <span style={{ color: "red" }}>{helpertextAmphur1}</span>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <ListboxSubdistinct
                  controll={true}
                  disabled={isIdCardValid === false}
                  amphurSelected={addr1Amphur}
                  value={addr1Tambol}
                  onChange={onadd1TambolSelected}
                ></ListboxSubdistinct>
                <span style={{ color: "red" }}>{helpertextTambol1}</span>
              </GridItem>
            </GridContainer>
            <br></br>
          </div>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={closeModalReplacement}>ปิดหน้าต่าง</Button>
            <Button
              color="success"
              onClick={rePlacetPerson}
              disabled={isIdCardValid === false && isSelectOtherPerson}
            >
              ดำรงตำแหน่งแทน
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalReplacement;
