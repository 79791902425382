import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";

const cookies = new Cookies();

export function getPromoteLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/promote${queryStr}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getPromotePersonLists(
  inspectProvinceGroupSelected,
  votedateSelected
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/inspectoList/provinceGroup/${inspectProvinceGroupSelected}/voteDateId/${votedateSelected}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function updatePromotePerson(
  inspectProvinceGroupSelected,
  votedateSelected,
  provinceName,
  provinceId,
  provinceGroupId,
  voteDateDetailId,
  arr3
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/personPromote/group/${inspectProvinceGroupSelected.id}/voteDateId/${votedateSelected.id}`;
  const body = {
    provinceId: provinceId,
    provinceName: provinceName,
    provinceGroupId: provinceGroupId,
    voteDateDetailId: voteDateDetailId,
    personPromoteList: arr3
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
