import React from "react";
import Notifications from "./Notifications"
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardText from "../../Card/CardText";
import CardBody from "../../Card/CardBody";

const NotificationsIndex = props => {
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card>
                        <CardHeader color="warning" text>
                            <CardText color="warning">
                                <h4>ข้อความแจ้งเตือน</h4>
                            </CardText>
                        </CardHeader>

                        <CardBody>
                            <Notifications />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default NotificationsIndex;
