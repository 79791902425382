import { React, useState, useEffect, useContext } from "react";
import {UserInfoContext} from "../contexts/users";

export const useFetch = (url, dependencies, enable = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const {userInfo, setIsReLogin} = useContext(UserInfoContext)


  useEffect(() => {
    const fetchData = async () => {
      const obj = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userInfo.token
        }
      };
      setIsLoading(true);
      fetch(url, obj)
        .then(function (response) {
          if (response.ok) {
            return response.json();
          }
          if (!response.ok) {
            setIsReLogin(true);
            throw Error(response.statusText);
          }
        })
        .then(result => {
          setFetchedData(result);
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    if (enable && userInfo && userInfo.token)  {
      fetchData();
    }
    
    return () => {
      setFetchedData(null);
      setIsLoading(false);
      enable = false;
    };
  }, [url, enable, userInfo]);

  return [isLoading, fetchedData];
};

export default useFetch;
