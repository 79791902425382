export default function getAgePerson(dob, votedate) {
  console.log("votedate >>", votedate)
  var today = new Date(votedate);
  console.log("today >>", today)
  var birthDate = new Date(dob); // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
  if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
    age_now--;
  }

  return age_now;
}
