import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getDashboard(queryStr) {
  console.log(queryStr);
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/dashboardvotecard${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}