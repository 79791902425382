import React, { useContext, useEffect, useState } from "react";
import {
  addCandidate,
  chkDubApplyNo,
  getCitized
} from "contexts/API/INSPEC/Candidate";

import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.js";
import DatepickerVotedate from "components/Common/Datepicker";
// import TableHistoryAddr from "./TableHistoryAddr";
// import TableHistoryName from "./TableHistoryName";
import { END_POINT_URL } from "config/env.conf";
import { FileUpload } from "components/Common/FileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import { InspectorContext } from "contexts/Inspector";
import ListboxBachelorMajor from "components/Common/ListboxBachelorMajor";
import ListboxCareer from "components/Common/ListboxCareer";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxDoctorMajor from "components/Common/ListboxDoctorMajor";
import ListboxInspectApplyDateSingle from "components/Common/ListboxInspectApplyDateSingle";
// Common
import ListboxInspectTitleList from "components/Common/ListboxInspectTitleList";
import ListboxMasterMajor from "components/Common/ListboxMasterMajor";
import ListboxProvince from "components/Common/ListboxProvince.js";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import ListboxUniversity from "components/Common/ListboxUniversity";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import getAge from "utils/CalculateAge";
//import common
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTextField-root": {
      //margin: theme.spacing(1),
      fontSize: "16px"
    }
  }
}));

const AddCandidate = ({ pageProps, setPageProps }) => {
  // const { rowData, action } = pageProps.props;

  const { sweetAlerts } = useSweetAlert();

  const classes = useStyles();

  const backtoMainHandler = () => {
    setPageProps({ active: "Candidate", action: "cancel" });
  };

  const { provinceSelected, inspectApplyDateSelected } = useContext(
    InspectorContext
  );

  const [uploadFileName, setUploadFileName] = useState(null);
  const [uploadFileNamePerson, setUploadFileNamePerson] = useState(null);
  const [uploadFileUUID, setUploadFileUUID] = useState(null);
  const [uploadFileUUIDPerson, setUploadFileUUIDPerson] = useState(null);
  const [uploadFileOther, setUploadFileOther] = useState(null);
  const [uploadFileOtherName, setUploadFileOtherName] = useState(null);

  let initialState = {
    idCard: "",
    birthdate: null,
    registerDatetime: null,
    fileUUID: null,
    fileName: null,
    personAge: null,
    firstName: null,
    lastName: null,
    titleId: null,
    tel: null,
    addr1: null,
    duplicate: false,
    addr1ProvinceId: { id: 0 },
    addr1AmphurId: { id: 0 },
    addr1TambolId: { id: 0 },
    addr2: null,
    addr2ProvinceId: { id: 0 },
    addr2AmphurId: { id: 0 },
    addr2TambolId: { id: 0 },
    careerAddrTambolId: { id: 0 },
    careerAmphurId: { id: 0 },
    careerProvinceId: { id: 0 },
    addr2ProvinceName: null,
    genderId: null,
    from4_1UUID: null,
    bachelorUid: null,
    masterUid: null,
    phdUid: null,
    bachelorMajorId: null,
    careerTypeId: null,
    careerExperience: null,
    masterMajorId: null,
    phdrDegree: false,
    phdMajorId: null,
    masterDegree: false,
    bachelorDegree: false,
    applyNo: null,
    personApplyCmd: {
      applyNo: null,
      personAge: 0,
      applyDatetime: null
    }
  };

  const [docs, setDocs] = useState({
    docType1: false,
    docType2: false,

    docType3: false,
    docType4: false,
    docType5: false,
    docType11: false
  });

  const [data, setData] = useState(initialState);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addr1ProvinceId, setAddr1ProvinceId] = useState({ id: 0 });
  const [addr2ProvinceId, setAddr2ProvinceId] = useState({ id: 0 });
  const [addr1AmphurId, setAddr1AmphurId] = useState({ id: 0 });
  const [addr2AmphurId, setAddr2AmphurId] = useState({ id: 0 });
  const [careerProvinceId, setCareerProvinceId] = useState({ id: 0 });
  const [careerAmphurId, setCareerAmphurId] = useState({ id: 0 });
  const [careerAddrTambolId, setCareerAddrTambolId] = useState({ id: 0 });
  const [, setAddr1TambolId] = useState({ id: 0 });
  const [, setAddr2TambolId] = useState({ id: 0 });
  const [, setTitleId] = useState(null);
  const [isIdCardValid, setIsIdCardValid] = React.useState(false);
  const [, setFileUUID] = useState("");
  const [fileName, setFileName] = useState("");
  const [helpertextTel, setHelpertextTel] = useState("");
  const [helpertextTel1, setHelpertextTel1] = useState("");
  const [helpertextApplyNo, setHelpertextApplyNo] = useState("");
  const [helpertextBirthDate, setHelpertextBirthDate] = useState("");
  const [helpertextAddr1, setHelpertextAddr1] = useState("");
  const [helpertexttitle, setHelpertexttitle] = useState("");
  const [helpertextProvince1, setHelpertextProvince1] = useState("");
  const [helpertextAmphur1, setHelpertextAmphur1] = useState("");
  const [helpertextTambol1, setHelpertextTambol1] = useState("");
  const [helpertextidCard, setHelpertextidCard] = useState("");
  const [helpertextFirstName, setHelpertextFirstName] = useState("");
  const [helpertextLastName, setHelpertextLastName] = useState("");
  const [helpertexteducate, setHelpertexteducate] = useState("");
  const [helpertexteducate1, setHelpertexteducate1] = useState("");
  const [helpertextGender, setHelpertextGender] = useState("");
  const [
    helperinspectApplyDateSelectedSingle,
    setHelperinspectApplyDateSelectedSingle
  ] = useState("");

  const onadd1ProvinceSelected = (evt, newValue) => {
    setAddr1ProvinceId(newValue);
    setAddr1AmphurId({ id: 0 });
    setAddr1TambolId({ id: 0 });

    setData({
      ...data,
      addr1ProvinceId: newValue ? newValue : { id: 0 },
      addr1AmphurId: { id: 0 },
      addr1TambolId: { id: 0 }
    });
    if (newValue) {
      if (newValue.id === 0) {
        setHelpertextProvince1("กรุณาระบุ*");
      } else {
        setHelpertextProvince1("");
      }
    }
  };
  const onCareerProvinceId = (evt, newValue) => {
    setCareerProvinceId(newValue);
    setCareerAmphurId({ id: 0 });
    setCareerAddrTambolId({ id: 0 });

    setData({ ...data, careerProvinceId: newValue ? newValue : { id: 0 } });
  };

  const onCareerAmphurId = (evt, newValue) => {
    setCareerAmphurId(newValue);

    setCareerAddrTambolId({ id: 0 });

    setData({ ...data, careerAmphurId: newValue ? newValue : { id: 0 } });
  };

  const onCareerAddrTambolId = (evt, newValue) => {
    setCareerAddrTambolId(newValue);

    setData({
      ...data,
      careerAddrTambolId: newValue ? newValue : { id: 0 }
    });
  };

  const onadd2ProvinceSelected = (evt, newValue) => {
    setAddr2ProvinceId(newValue);
    setAddr2AmphurId({ id: 0 });
    setAddr2TambolId({ id: 0 });

    setData({ ...data, addr2ProvinceId: newValue ? newValue : { id: 0 } });
  };
  const onadd1AmphurSelected = (evt, newValue) => {
    setAddr1AmphurId(newValue);
    setAddr1TambolId({ id: 0 });
    setData({
      ...data,
      addr1AmphurId: newValue ? newValue : { id: 0 },
      addr1TambolId: { id: 0 }
    });
    if (newValue) {
      if (newValue.id === 0) {
        setHelpertextAmphur1("กรุณาระบุ*");
      } else {
        setHelpertextAmphur1("");
      }
    }
  };
  const onadd2AmphurSelected = (evt, newValue) => {
    setAddr2AmphurId(newValue);
    setAddr2TambolId({ id: 0 });
    setData({ ...data, addr2AmphurId: newValue ? newValue : { id: 0 } });
  };
  const onadd1TambolSelected = (evt, newValue) => {
    setAddr1TambolId(newValue);
    setData({ ...data, addr1TambolId: newValue ? newValue : { id: 0 } });
    if (newValue) {
      if (newValue.id === 0) {
        setHelpertextTambol1("กรุณาระบุ*");
      } else {
        setHelpertextTambol1("");
      }
    }
  };
  const onadd2TambolSelected = (evt, newValue) => {
    setAddr2TambolId(newValue);
    setData({ ...data, addr2TambolId: newValue ? newValue : { id: 0 } });
  };
  const [checkeddub, setCheckeddub] = useState(false);
  useEffect(() => {
    if (checkeddub === true) {
      setData({
        ...data,
        addr2: data.addr1,
        addr2ProvinceId: data.addr1ProvinceId,
        addr2AmphurId: data.addr1AmphurId,
        addr2TambolId: data.addr1TambolId
      });
      setAddr2ProvinceId(
        data.addr1ProvinceId ? data.addr1ProvinceId : { id: 0 }
      );

      setAddr2AmphurId(data.addr1AmphurId ? data.addr1AmphurId : { id: 0 });
      setAddr2TambolId(data.addr1TambolId ? data.addr1TambolId : { id: 0 });
    }
    if (checkeddub === false) {
      setData({
        ...data,
        addr2: null,
        addr2ProvinceId: { id: 0 },
        addr2AmphurId: { id: 0 },
        addr2TambolId: { id: 0 }
      });
      setAddr2ProvinceId({ id: 0 });

      setAddr2AmphurId({ id: 0 });
      setAddr2TambolId({ id: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkeddub]);

  async function fetchData(idCard) {
    let _number = data.personApplyCmd.applyNo;
    try {
      setLoading(true);
      const person = await getCitized(idCard);

      let chkdata = person.data.data;
      let data = person.data.data[0];
      if (chkdata.length > 0 && chkdata[0] != null) {
        setLoading(false);

        setData({
          ...data,
          personAge: getAge(
            data.birthdate !== undefined ? data.birthdate : new Date()
          ),
          personApplyCmd: {
            ...data.personApplyCmd,
            personAge: getAge(
              data.birthdate !== undefined ? data.birthdate : new Date()
            ),
            applyDatetime: null
          },
          addr1ProvinceId: {
            id: data.addr1ProvinceId,
            value: data.addr1ProvinceName
          },
          addr2ProvinceId: data.addr2ProvinceId
            ? {
                id: data.addr2ProvinceId,
                value: data.addr2ProvinceName
              }
            : { id: 0 },
          addr1AmphurId: {
            id: data.addr1AmphurId,
            value: data.addr1AmphurName
          },
          addr2AmphurId: data.addr2AmphurId
            ? {
                id: data.addr2AmphurId,
                value: data.addr1AmphurName
              }
            : { id: 0 },
          addr1TambolId: {
            id: data.addr1TambolId,
            value: data.addr1TambolName
          },
          addr2TambolId: data.addr2TambolId
            ? {
                id: data.addr2TambolId,
                value: data.addr2TambolName
              }
            : { id: 0 },

          registerDatetime: new Date()
        });

        setTitleId({ id: data.titleId });
        setAddr1ProvinceId({
          id: data.addr1ProvinceId ? data.addr1ProvinceId : 0
        });

        setAddr1AmphurId({
          id: data.addr1AmphurId ? data.addr1AmphurId : 0
        });
        setAddr1TambolId({
          id: data.addr1TambolId ? data.addr1TambolId : 0
        });
        setAddr2ProvinceId({
          id: data.addr2ProvinceId ? data.addr2ProvinceId : 0
        });
        setAddr2AmphurId({
          id: data.addr2AmphurId ? data.addr2AmphurId : 0
        });
        setAddr1TambolId({
          id: data.addr1TambolId ? data.addr1TambolId : 0
        });
        setAddr2TambolId({
          id: data.addr2TambolId ? data.addr2TambolId : 0
        });
        setLoading(false);
      } else {
        sweetAlerts.warning("ไม่พบเลขประจำตัวประชาชนนี้ในระบบ");

        setData({
          ...initialState,
          idCard: idCard,
          personApplyCmd: {
            applyNo: _number
          }
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      sweetAlerts.error("ไม่สามารถดึงข้อมูล", error.toString());
    }
  }

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const editOptPerson = () => {
    // console.log(data);
    const docsLists = [];

    if (docs.docType1 === false && docs.docType2 === false && uploadFileName) {
      sweetAlerts.warning(`กรุณาเลือกประเภทเอกสารแบบพิมพ์`);
      return false;
    }
    if (
      docs.docType3 === false &&
      docs.docType4 === false &&
      docs.docType5 === false &&
      uploadFileNamePerson
    ) {
      sweetAlerts.warning(`กรุณาเลือกประเภทเอกสารแบบพิมพ์`);
      return false;
    }

    if (docs.docType11 === false && uploadFileOtherName) {
      sweetAlerts.warning(`กรุณาเลือกประเภทเอกสาร`);
      return false;
    }

    if ((docs.docType1 === true || docs.docType2 === true) && !uploadFileUUID) {
      sweetAlerts.warning(`กรุณาแนบเอกสารแบบพิมพ์`);
      return false;
    }

    if (
      (docs.docType3 === true ||
        docs.docType4 === true ||
        docs.docType5 === true) &&
      !uploadFileUUIDPerson
    ) {
      sweetAlerts.warning(`กรุณาแนบเอกสารผู้สมัคร`);
      return false;
    }

    if (docs.docType11 === true && !uploadFileOther) {
      sweetAlerts.warning(`กรุณาแนบเอกสารอื่นๆ`);
      return false;
    }

    if (docs.docType1 && uploadFileUUID) {
      docsLists.push({ docTypeId: 9, fileUUID: uploadFileUUID });
    }
    if (docs.docType2 && uploadFileUUID) {
      docsLists.push({ docTypeId: 8, fileUUID: uploadFileUUID });
    }
    if (docs.docType3 && uploadFileUUIDPerson) {
      docsLists.push({ docTypeId: 1, fileUUID: uploadFileUUIDPerson });
    }
    if (docs.docType4 && uploadFileUUIDPerson) {
      docsLists.push({ docTypeId: 2, fileUUID: uploadFileUUIDPerson });
    }
    if (docs.docType5 && uploadFileUUIDPerson) {
      docsLists.push({ docTypeId: 3, fileUUID: uploadFileUUIDPerson });
    }

    if (docs.docType11 && uploadFileOther) {
      docsLists.push({ docTypeId: 11, fileUUID: uploadFileOther });
    }
    // let body = data;
    // body.documents = docsLists;

    // console.log(data);
    // console.log("data-->", docsLists);
    if (data.personApplyCmd.applyDatetime === null) {
      setHelperinspectApplyDateSelectedSingle("กรุณาระบุ*");
    }
    if (data.idCard === "") {
      setHelpertextidCard("กรุณาระบุ*");
    }
    if (data.addr1ProvinceId.id === 0 || undefined) {
      setHelpertextProvince1("กรุณาระบุ*");
    }
    if (data.addr1 === null) {
      setHelpertextAddr1("กรุณาระบุ*");
    }
    if (data.addr1AmphurId.id === 0 || undefined) {
      setHelpertextAmphur1("กรุณาระบุ*");
    }
    if (data.addr1TambolId.id === 0 || undefined) {
      setHelpertextTambol1("กรุณาระบุ*");
    }
    if (
      data.personApplyCmd.applyNo === null ||
      data.personApplyCmd.applyNo === undefined
    ) {
      setHelpertextApplyNo("กรุณาระบุ*");
    }
    if (data.tel === null || data.tel === "") {
      setHelpertextTel("กรุณาระบุ*");
    }
    if (data.birthdate === null) {
      setHelpertextBirthDate("กรุณาระบุ*");
    }
    if (data.firstName === null) {
      setHelpertextFirstName("กรุณาระบุ*");
    }
    if (data.lastName === null) {
      setHelpertextLastName("กรุณาระบุ*");
    }
    if (data.genderId === null) {
      setHelpertextGender("กรุณาระบุ*");
    }

    if (
      data.bachelorDegree === false &&
      data.masterDegree === false &&
      data.phdrDegree === false
    ) {
      setHelpertexteducate("กรุณาระบุวุฒิการศึกษา*");
    }

    if (
      data.bachelorUid === null &&
      data.masterUid === null &&
      data.phdUid === null
    ) {
      setHelpertexteducate1("กรุณาระบุวุฒิการศึกษา*");
    }

    if (
      data.bachelorMajorId === null &&
      data.masterMajorId === null &&
      data.phdMajorId === null
    ) {
      setHelpertexteducate1("กรุณาระบุวุฒิการศึกษา*");
    }
    if (data.titleId === null) {
      setHelpertexttitle("กรุณาระบุ*");
    }
    if (
      ((data.bachelorDegree === true ||
        data.masterDegree === true ||
        data.phdrDegree === true) &&
        (data.bachelorMajorId !== null ||
          data.masterMajorId !== null ||
          data.phdMajorId !== null) &&
        data.bachelorUid !== null) ||
      data.masterUid !== null ||
      data.phdUid !== null
    ) {
      setHelpertexteducate1("");
    }
    // console.log(helpertexteducate);
    // console.log(helpertexteducate1);
    // console.log(data.addr1);
    // console.log(data.tel);
    // console.log(data.titleId);
    // console.log(data.lastName);
    // console.log(data.firstName);
    // console.log(data.personApplyCmd.applyNo);
    // console.log(data.genderId);
    // console.log(data.addr1AmphurId.id);
    // console.log(data.addr1TambolId.id);
    // console.log(data.addr1ProvinceId.id);
    // console.log(data.bachelorDegree);
    // console.log(data.masterDegree);
    // console.log(data.phdrDegree);
    // console.log(data.bachelorMajorId);
    // console.log(data.masterMajorId);
    // console.log(data.phdMajorId);
    if (
      data.addr1 &&
      data.tel &&
      data.titleId &&
      data.lastName &&
      data.firstName &&
      data.personApplyCmd.applyNo &&
      data.personApplyCmd.applyDatetime &&
      data.genderId !== null &&
      data.addr1AmphurId.id !== 0 &&
      data.addr1TambolId.id !== 0 &&
      data.addr1ProvinceId !== 0 &&
      (data.bachelorDegree === true ||
        data.masterDegree === true ||
        data.phdrDegree === true) &&
      (data.bachelorMajorId !== null ||
        data.masterMajorId !== null ||
        data.phdMajorId !== null) &&
      (data.bachelorUid !== null ||
        data.masterUid !== null ||
        data.phdUid !== null)
    ) {
      // if (action === "add") {
      data.documents = docsLists;
      let dataUpdate = data;

      dataUpdate.addr1ProvinceId = data.addr1ProvinceId.id;
      dataUpdate.addr1AmphurId = data.addr1AmphurId.id;
      dataUpdate.addr1TambolId = data.addr1TambolId.id;

      dataUpdate.addr2ProvinceId = data.addr2ProvinceId.id;
      dataUpdate.addr2AmphurId = data.addr2AmphurId.id;
      dataUpdate.addr2TambolId = data.addr2TambolId.id;

      dataUpdate.careerProvinceId = data.careerProvinceId
        ? data.careerProvinceId.id
        : null;
      dataUpdate.careerAmphurId = data.careerAmphurId
        ? data.careerAmphurId.id
        : null;
      dataUpdate.careerAddrTambolId = data.careerAddrTambolId
        ? data.careerAddrTambolId.id
        : null;

      setData({ ...data, dataUpdate });

      // console.log(data);
      // console.log(provinceSelected);
      addCandidate(data, provinceSelected.id, inspectApplyDateSelected.id)
        .then(result => {
          sweetAlerts.success(`เพิ่มข้อมูลสำเร็จแล้ว`);
          setData(initialState);
          setFileUUID("");
          setFileName("");
          setUploadFileUUID("");
          setUploadFileUUIDPerson("");
          setUploadFileNamePerson("");

          setUploadFileOther("");
          setUploadFileOtherName("");

          setUploadFileName("");
          setCheckeddub(false);
          backtoMainHandler();
          //  setData({ ...data, from4_1UUID: null });
          // resolve();
        })
        .catch(err => {
          //if (err.response.data.code !== 20000) {
          sweetAlerts.danger(`ไม่สามารถเพิ่มข้อมูลได้`, err.response.data.desc);
          //   }
        });
    } else {
      sweetAlerts.warning(`กรุณากรอกข้อมูลให้ครบถ้วน`);
    }
  };

  async function checkDuplicate(e) {
    if (
      verifyNumber(e.target.value) &&
      e.target.value >= 1 &&
      e.target.value <= 225
    ) {
      let _applyNo = e.target.value;
      let param = "";

      param = `inspector/inspectCheckDubApplyNo?&provinceId=${provinceSelected.id}&applyDateId=${inspectApplyDateSelected.id}&applyNo=${e.target.value}`;

      const chk = await chkDubApplyNo(param);

      if (chk.data.data) {
        sweetAlerts.warning("หมายเลขซ้ำ");
        setHelpertextApplyNo("กรุณาระบุ");
      } else {
        setHelpertextApplyNo("");
        setData({
          ...data,

          personApplyCmd: {
            ...data.personApplyCmd,
            applyNo: _applyNo
          }
        });
        // console.log("chkdup", data);
      }
    } else {
      setHelpertextApplyNo("กรุณาระบุ");
    }
  }

  const onIDCardHandler = v => {
    setData({ ...data, idCard: v.value });
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      if (isError) {
        sweetAlerts.warning("เลขประจำตัวประชาชนผิด");
        if (isIdCardValid === true) {
          setIsIdCardValid(false);
        }
      } else {
        if (isIdCardValid === false) {
          setIsIdCardValid(true);
          setHelpertextidCard("");
        }
      }
    } else {
      if (isIdCardValid === true) {
        setIsIdCardValid(false);
        setHelpertextidCard("กรุณาระบุ*");
      }
    }
  };
  let content = <LoadingData />;
  if (loading === false) {
    content = (
      <>
        <form className={classes.root} noValidate autoComplete="off">
          <Card>
            <CardBody>
              <CardHeader color="primary" text>
                <CardText color="primary">
                  <h4>จัดการข้อมูลผู้สมัคร</h4>
                </CardText>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer
                      direction="row"
                      justifyContent="center"
                      // alignItems="flex-end"
                    >
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          id="applyNo"
                          default
                          required
                          labelText="หมายเลขผู้สมัคร"
                          formControlProps={{
                            fullWidth: true
                          }}
                          helpertext="กรุณาระบุ*"
                          inputProps={{
                            maxLength: 3,
                            autoComplete: "new-password",
                            defaultValue: data.personApplyCmd.applyNo,
                            onBlur: checkDuplicate
                          }}
                        />

                        {/* <CustomInput
                          id="applyNo"
                          default
                          required
                          labelText="หมายเลขผู้สมัคร"
                          formControlProps={{
                            fullWidth: true
                          }}
                          type="number"
                          inputProps={{
                            maxLength: 2,

                            autoComplete: "off",
                            defaultValue: data.personApplyCmd.applyNo,
                            onChange: e => {
                              if (
                                verifyNumber(e.target.value) &&
                                e.target.value >= 1 &&
                                e.target.value <= 99
                              ) {
                                setHelpertextApplyNo("");
                              } else {
                                setHelpertextApplyNo("กรุณาระบุเฉพาะตัวเลข");
                              }
                              setData({
                                ...data,
                                personApplyCmd: {
                                  ...data.personApplyCmd,
                                  applyNo: e.target.value
                                }
                              });
                            }
                          }}
                        /> */}
                        <FormHelperText style={{ color: "red" }}>
                          {helpertextApplyNo}
                        </FormHelperText>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <FileUpload
                          id="file-upload-fileuuid"
                          label="แนบรูปผู้สมัคร"
                          fileName={fileName}
                          remove
                          onFileRemove={() => {
                            setFileName("");
                            setPreviewImage("");
                            setFileUUID("");
                            setData({
                              ...data,
                              fileUUID: null,
                              fileName: null
                            });
                          }}
                          url={getFileDownloadUrl("inspect", data.fileUUID)}
                          download
                          onFileHandler={(e, f, p) => {
                            uploadFile("inspect", f, p)
                              .then(r => {
                                const file = r.data.data;
                                sweetAlerts.success("แนบรูปเรียบร้อย");
                                setFileName(file.fileName);
                                setFileUUID(file.fileId);
                                setData({
                                  ...data,
                                  fileUUID: file.fileId
                                });
                                setPreviewImage(
                                  `${END_POINT_URL}/file/inspect/download/${file.fileId}`
                                );
                              })
                              .catch(err => {
                                console.log(err);
                              });
                          }}
                          fileType="image/jpeg"
                          btnName={<>Browse</>}
                          btnProps={{
                            color: "primary"
                          }}
                        />
                        {previewImage && (
                          <img
                            className="preview"
                            src={previewImage}
                            alt="image"
                            style={{ width: "40%", height: "auto" }}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ width: "100%" }}>
                <CardBody style={{ width: "100%" }}>
                  <h4 className={classes.cardTitle}>ข้อมูลส่วนบุคคล</h4>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <NumberFormat
                        style={{
                          width: "100%",
                          margin: "0 0 17px 0",
                          paddingTop: "27px"
                        }}
                        value={data.idCard}
                        placeholder="เลขประจำตัวประชาชน"
                        customInput={TextField}
                        format="# #### ##### ## #"
                        mask="X"
                        fullWidth
                        onValueChange={onIDCardHandler}
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextidCard}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <Button
                        disabled={!isIdCardValid}
                        variant="contained"
                        color="success"
                        onClick={() => fetchData(data.idCard)}
                      >
                        ตรวจสอบ
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxInspectApplyDateSingle
                        value={data.applyDatetime}
                        inspectApplyDateSelected={inspectApplyDateSelected}
                        onChange={(evt, newValue) => {
                          if (newValue) {
                            setHelperinspectApplyDateSelectedSingle("");

                            setData({
                              ...data,

                              personApplyCmd: {
                                ...data.personApplyCmd,
                                applyDatetime: newValue ? newValue.id : null
                              }
                            });
                          } else {
                            setHelperinspectApplyDateSelectedSingle(
                              "กรุณาระบุ"
                            );
                          }
                        }}
                      ></ListboxInspectApplyDateSingle>
                      <FormHelperText style={{ color: "red" }}>
                        {helperinspectApplyDateSelectedSingle}
                      </FormHelperText>

                      {/* <DatepickerVotedate
                        name="registerDatetime"
                        label="วันที่รับสมัคร"
                        disabled={isIdCardValid === false}
                        value={data.registerDatetime}
                        onChange={date => {
                          let arr = date.format().split("T");

                          setData({
                            ...data,
                            registerDatetime: arr[0]
                          });
                          console.log(data);
                        }}
                      ></DatepickerVotedate> */}
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <DatepickerVotedate
                        name="birthday"
                        label="วัน/เดือน/ปี เกิด"
                        value={data.birthdate}
                        disabled={isIdCardValid === false}
                        onChange={date => {
                          if (date) {
                            let arr = date.format().split("T");
                            if (
                              getAge(date.format()) < 45 ||
                              getAge(date.format()) > 70
                            ) {
                              sweetAlerts.warning(
                                `อายุต้องไม่ต่ำว่า 45 ปี และไม่เกิน 70ปี`
                              );
                              setHelpertextBirthDate("กรุณาระบุ*");
                              setData({
                                ...data,
                                birthdate: null,
                                personApplyCmd: {
                                  ...data.personApplyCmd,
                                  personAge: null
                                }
                              });
                            } else {
                              setData({
                                ...data,
                                birthdate: arr[0],
                                personApplyCmd: {
                                  ...data.personApplyCmd,
                                  personAge: getAge(date.format())
                                }
                              });
                              setHelpertextBirthDate("");
                            }
                          }
                        }}
                      ></DatepickerVotedate>
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextBirthDate}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="personAge"
                        labelText="อายุ"
                        disabled={isIdCardValid === false}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: data.personApplyCmd.personAge || "",
                          disabled: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                  >
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              data.genderId === "true" || data.genderId === true
                            }
                            disabled={isIdCardValid === false}
                            value={true}
                            onClick={e => {
                              if (e.target.checked) {
                                setHelpertextGender("");
                              } else {
                                setHelpertextGender("กรุณาระบุ*");
                              }
                              setData({ ...data, genderId: e.target.value });
                            }}
                            // onClick={
                            //   e =>
                            //     setData({ ...data, genderId: e.target.value })
                            //   //  handleGenderChange(e, "genderId")
                            // }
                            id="genderId"
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        label="ชาย"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            onClick={e => {
                              if (e.target.checked) {
                                setHelpertextGender("");
                                //setGenderState(true);
                              } else {
                                setHelpertextGender("กรุณาระบุ*");
                                // setGenderState(false);
                              }
                              setData({ ...data, genderId: e.target.value });
                            }}
                            disabled={isIdCardValid === false}
                            checked={
                              data.genderId === "false" ||
                              data.genderId === false
                            }
                            value={false}
                            id="genderId"
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot
                            }}
                          />
                        }
                        label="หญิง"
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextGender}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxInspectTitleList
                        disabled={isIdCardValid === false}
                        defaultValue={{ id: data.titleId }}
                        onChange={(evt, newValue) => {
                          if (newValue) {
                            setHelpertexttitle("");

                            setData({
                              ...data,
                              titleId: newValue ? newValue.id : 0
                            });
                          } else {
                            setHelpertexttitle("กรุณาระบุ");
                          }
                        }}
                      ></ListboxInspectTitleList>

                      <FormHelperText style={{ color: "red" }}>
                        {helpertexttitle}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="firstName"
                        default
                        required
                        labelText="ชื่อ"
                        disabled={isIdCardValid === false}
                        formControlProps={{
                          fullWidth: true
                        }}
                        helpertext="กรุณาระบุ*"
                        inputProps={{
                          value: data.firstName || "",
                          // value: "ddddd",
                          onChange: e => {
                            if (verifyLength(e.target.value, 2)) {
                              setHelpertextFirstName("");
                            } else {
                              setHelpertextFirstName("กรุณาระบุ");
                            }
                            setData({ ...data, firstName: e.target.value });
                          }
                        }}
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextFirstName}
                      </FormHelperText>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="lastName"
                        default
                        required
                        disabled={isIdCardValid === false}
                        labelText="นามสกุล"
                        helpertext="กรุณาระบุ*"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // value: "sss",
                          value: data.lastName || "",
                          onChange: e => {
                            if (verifyLength(e.target.value, 2)) {
                              setHelpertextLastName("");
                            } else {
                              setHelpertextLastName("กรุณาระบุ");
                            }
                            setData({ ...data, lastName: e.target.value });
                          }
                        }}
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextLastName}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="tel"
                        default
                        required
                        labelText="เบอร์โทรศัพท์"
                        disabled={isIdCardValid === false}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: data.tel || "",
                          maxLength: 10,
                          onChange: e => {
                            if (
                              verifyNumber(e.target.value) &&
                              verifyLength(e.target.value, 9)
                            ) {
                              setHelpertextTel("");
                            } else {
                              setHelpertextTel("กรุณาระบุ*");
                            }
                            setData({ ...data, tel: e.target.value });
                          }
                        }}
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextTel}
                      </FormHelperText>
                    </GridItem>
                  </GridContainer>
                  <h4 style={{ fontWeight: "bolder", paddingTop: "15px" }}>
                    ที่อยู่ตามทะเบียนบ้าน
                  </h4>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="addr1"
                        labelText="ที่อยู่ตามทะเบียนบ้าน"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: isIdCardValid === false,
                          value: data.addr1 || "",
                          onChange: e => {
                            if (verifyLength(e.target.value, 2)) {
                              setHelpertextAddr1("");
                            } else {
                              setHelpertextAddr1("กรุณาระบุ*");
                            }
                            setData({ ...data, addr1: e.target.value });
                            //  handleChange(e, "addr1");
                          }
                        }}
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextAddr1}
                      </FormHelperText>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxProvince
                        validators={[MustNotNull]}
                        controll={true}
                        disabled={isIdCardValid === false}
                        value={data.addr1ProvinceId}
                        onChange={onadd1ProvinceSelected}
                      ></ListboxProvince>
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextProvince1}
                      </FormHelperText>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxDistinct
                        controll={true}
                        validators={[MustNotNull]}
                        value={data.addr1AmphurId}
                        disabled={isIdCardValid === false}
                        // defaultValue={{
                        //   id: data.addr1AmphurId || ""
                        // }}
                        provinceSelected={addr1ProvinceId}
                        onChange={onadd1AmphurSelected}
                      ></ListboxDistinct>
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextAmphur1}
                      </FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <ListboxSubdistinct
                        controll={true}
                        disabled={isIdCardValid === false}
                        validators={[MustNotNull]}
                        value={data.addr1TambolId}
                        // defaultValue={{
                        //   id: data.addr1TambolId || "" || undefined
                        // }}
                        provinceSelected={addr1ProvinceId}
                        amphurSelected={addr1AmphurId}
                        onChange={onadd1TambolSelected}
                      ></ListboxSubdistinct>
                      <FormHelperText style={{ color: "red" }}>
                        {helpertextTambol1}
                      </FormHelperText>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <h4 style={{ fontWeight: "bolder", paddingTop: "15px" }}>
                        ที่อยู่ที่ติดต่อได้
                      </h4>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={isIdCardValid === false}
                            value={true}
                            checked={checkeddub}
                            onChange={e => {
                              setCheckeddub(!checkeddub);
                            }}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        styles={{ paddingTop: "20px" }}
                        label="เหมือนกับที่อยู่ตามทะเบียนบ้าน"
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        id="addr2"
                        default
                        required
                        labelText="ที่อยู่ที่ติดต่อได้"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          // value: data.addr2,
                          value: data.addr2 || "",
                          autoComplete: "new-password",
                          onChange: e => {
                            setData({ ...data, addr2: e.target.value });
                            // if (verifyLength(e.target.value, 1)) {
                            //   setData({ ...data, addr2: e.target.value });
                            //   //  handleChange(e, "addr1");
                            // }else{

                            // }
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <ListboxProvince
                        controll={true}
                        disabled={false}
                        value={data.addr2ProvinceId}
                        onChange={onadd2ProvinceSelected}
                      ></ListboxProvince>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <ListboxDistinct
                        controll={true}
                        validators={[MustNotNull]}
                        value={data.addr2AmphurId}
                        // defaultValue={{
                        //   id: data.addr2AmphurId || "" || undefined
                        // }}
                        provinceSelected={addr2ProvinceId}
                        onChange={onadd2AmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <ListboxSubdistinct
                        controll={true}
                        validators={[MustNotNull]}
                        value={data.addr2TambolId}
                        // defaultValue={{
                        //   id: data.addr2TambolId || "" || undefined
                        // }}
                        provinceSelected={addr2ProvinceId}
                        amphurSelected={addr2AmphurId}
                        onChange={onadd2TambolSelected}
                      ></ListboxSubdistinct>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h4 className={classes.cardTitle}>ประวัติการศึกษา</h4>
                  <FormHelperText style={{ color: "red", fontSize: "15px" }}>
                    {helpertexteducate}
                    {helpertexteducate1}
                  </FormHelperText>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <br></br>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={data.bachelorDegree === true}
                                onChange={event => {
                                  setData({
                                    ...data,
                                    bachelorDegree: event.target.checked
                                  });
                                  if (event.target.checked) {
                                    setHelpertexteducate("");
                                  } else {
                                    setHelpertexteducate(
                                      "กรุณาระบุวุฒิการศึกษา"
                                    );
                                  }
                                  //   console.log(docs.docType2);
                                }}
                                name="bachelorDegree"
                                color="primary"
                              />
                            }
                            label="ปริญญาตรี"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <ListboxUniversity
                            defaultValue={{
                              id: data.bachelorUid
                            }}
                            onChange={(e, v) => {
                              if (v) {
                                setData({ ...data, bachelorUid: v.id });
                                setHelpertexteducate("");
                              } else {
                                setData({ ...data, bachelorUid: null });
                                setHelpertexteducate("กรุณาระบุวุฒิการศึกษา");
                              }
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <ListboxBachelorMajor
                            defaultValue={{
                              id: data.bachelorMajorId
                            }}
                            onChange={(e, v) => {
                              if (v) {
                                setData({ ...data, bachelorMajorId: v.id });
                                setHelpertexteducate("");
                              } else {
                                setData({ ...data, bachelorMajorId: null });
                                setHelpertexteducate("กรุณาระบุวุฒิการศึกษา");
                              }
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <br></br>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={data.masterDegree === true}
                                onChange={event => {
                                  setData({
                                    ...data,
                                    masterDegree: event.target.checked
                                  });
                                  if (event.target.checked) {
                                    setHelpertexteducate("");
                                  } else {
                                    setHelpertexteducate(
                                      "กรุณาระบุวุฒิการศึกษา"
                                    );
                                  }
                                  //   console.log(docs.docType2);
                                }}
                                name="masterDegree"
                                color="primary"
                              />
                            }
                            label="ปริญญาโท"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <ListboxUniversity
                            defaultValue={{
                              id: data.masterUid
                            }}
                            onChange={(e, v) => {
                              if (v) {
                                setData({ ...data, masterUid: v.id });
                              } else {
                                setData({ ...data, masterUid: null });
                              }
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <ListboxMasterMajor
                            defaultValue={{
                              id: data.masterMajorId
                            }}
                            onChange={(e, v) => {
                              if (v) {
                                setData({ ...data, masterMajorId: v.id });
                                setHelpertexteducate("");
                              } else {
                                setData({ ...data, masterMajorId: null });
                                setHelpertexteducate("กรุณาระบุวุฒิการศึกษา");
                              }
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <br></br>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={data.phdrDegree === true}
                                onChange={event => {
                                  setData({
                                    ...data,
                                    phdrDegree: event.target.checked
                                  });
                                  if (event.target.checked) {
                                    setHelpertexteducate("");
                                  } else {
                                    setHelpertexteducate(
                                      "กรุณาระบุวุฒิการศึกษา"
                                    );
                                  }
                                  //   console.log(docs.docType2);
                                }}
                                name="phdDegree"
                                color="primary"
                              />
                            }
                            label="ปริญญาเอก"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <ListboxUniversity
                            defaultValue={{
                              id: data.phdUid
                            }}
                            onChange={(e, v) => {
                              if (v) {
                                setData({ ...data, phdUid: v.id });
                                setHelpertexteducate("");
                              } else {
                                setData({ ...data, phdUid: null });
                                setHelpertexteducate("กรุณาระบุวุฒิการศึกษา*");
                              }
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <ListboxDoctorMajor
                            defaultValue={{
                              id: data.phdMajorId
                            }}
                            onChange={(e, v) => {
                              if (v) {
                                setData({ ...data, phdMajorId: v.id });
                                setHelpertexteducate("");
                              } else {
                                setData({ ...data, phdMajorId: null });
                                setHelpertexteducate("กรุณาระบุวุฒิการศึกษา");
                              }
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className={classes.cardTitle}>ข้อมูลการทำงาน</h4>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <br></br>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <ListboxCareer
                            defaultValue={{
                              id: data.careerTypeId
                            }}
                            onChange={(e, v) => {
                              if (v) {
                                setData({ ...data, careerTypeId: v.id });
                              }
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            id="careerPosition"
                            default
                            required
                            labelText="ตำแหน่ง"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              value: data.careerPosition,

                              onChange: e => {
                                //   if (verifyLength(e.target.value, 1)) {
                                setData({
                                  ...data,
                                  careerPosition: e.target.value
                                });
                                //  }
                              }
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            id="careerAddr"
                            default
                            required
                            labelText="สถานที่ทำงาน"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              value: data.careerAddr || "",

                              onChange: e => {
                                // if (verifyLength(e.target.value, 1)) {
                                setData({
                                  ...data,
                                  careerAddr: e.target.value
                                });
                                //   }
                              }
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <ListboxProvince
                            disabled={false}
                            value={careerProvinceId}
                            onChange={onCareerProvinceId}
                          ></ListboxProvince>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <ListboxDistinct
                            provinceSelected={careerProvinceId}
                            value={careerAmphurId}
                            onChange={onCareerAmphurId}
                          ></ListboxDistinct>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <ListboxSubdistinct
                            amphurSelected={careerAmphurId}
                            value={careerAddrTambolId}
                            onChange={onCareerAddrTambolId}
                          ></ListboxSubdistinct>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            id="careerTelephone"
                            default
                            required
                            labelText="เบอร์โทรศัพท์"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              value: data.careerTelephone || "",
                              //  value: "0123456789",
                              onChange: e => {
                                if (
                                  verifyNumber(e.target.value) &&
                                  verifyLength(e.target.value, 9)
                                ) {
                                  setHelpertextTel1("");
                                } else {
                                  setHelpertextTel1("กรุณาระบุ*");
                                }
                                setData({
                                  ...data,
                                  careerTelephone: e.target.value
                                });
                              }
                            }}
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {helpertextTel1}
                          </FormHelperText>
                        </GridItem>
                      </GridContainer>
                      <br></br> <br></br> <br></br>
                      <h4 className={classes.cardTitle}>
                        ข้อมูลและประสบการณ์การเลือกตั้ง
                      </h4>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            id="careerExperience"
                            default
                            required
                            labelText="ประสบการณ์การทำงานที่สำคัญ(โดยสังเขป)"
                            formControlProps={{
                              fullWidth: true
                            }}
                            style={{ fontFamily: "Athiti" }}
                            inputProps={{
                              multiline: true,
                              rows: 4,
                              value: data.careerExperience
                                ? data.careerExperience.replace(
                                    /<[^>]*>?/gm,
                                    ""
                                  )
                                : "",

                              onChange: e => {
                                // if (verifyLength(e.target.value, 1)) {
                                setData({
                                  ...data,
                                  careerExperience: e.target.value
                                });
                                //  }
                              }
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            id="voteExperience"
                            default
                            required
                            labelText="ประสบการณ์เกี่ยวกับการเลือกตั้ง"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              value: data.voteExperience || "",
                              multiline: true,
                              rows: 4,
                              onChange: e => {
                                // if (verifyLength(e.target.value, 1)) {
                                // setData({
                                //   ...data,
                                //   voteExperience: e.target.value
                                // });
                                setData({
                                  ...data,
                                  voteExperience: e.target.value
                                });
                                //    }
                              }
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className={classes.cardTitle}>เอกสารประกอบการสมัคร</h4>
                  <hr></hr>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      เอกสารผู้สมัคร
                    </GridItem>

                    <GridItem xs={3} sm={3}>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              //   onChange={handleChange}
                              name="checkedB"
                              color="primary"
                              onChange={event => {
                                setDocs({
                                  ...docs,
                                  docType3: event.target.checked
                                });
                                //   console.log(docs.docType2);
                              }}
                            />
                          }
                          label="สำเนาบัตรประชาชน"
                        />
                      </div>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              //    onChange={handleChange}
                              name="checkedB"
                              color="primary"
                              onChange={event => {
                                setDocs({
                                  ...docs,
                                  docType4: event.target.checked
                                });
                                //   console.log(docs.docType2);
                              }}
                            />
                          }
                          label="สำเนาทะเบียนบ้าน"
                        />
                      </div>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      ></div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={event => {
                              setDocs({
                                ...docs,
                                docType5: event.target.checked
                              });
                              //   console.log(docs.docType2);
                            }}
                            color="primary"
                          />
                        }
                        label="ใบรับรองแพทย์"
                      />
                    </GridItem>
                    <GridItem xs={9} sm={6}>
                      <div style={{ width: "100%" }}>
                        <FileUpload
                          id="file-upload-2"
                          label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                          fileName={uploadFileNamePerson}
                          remove
                          onFileRemove={() => {
                            setUploadFileNamePerson("");
                            setUploadFileUUIDPerson("");
                          }}
                          url={getFileDownloadUrl(
                            "inspect",
                            uploadFileUUIDPerson
                          )}
                          download
                          onFileHandler={(e, f, p) => {
                            uploadFile("inspect", f, p)
                              .then(r => {
                                const file = r.data.data;
                                sweetAlerts.success("แนบไฟล์เรียบร้อย");
                                setUploadFileNamePerson(file.fileName);
                                setUploadFileUUIDPerson(file.fileId);
                              })
                              .catch(err => {
                                console.log(err);
                              });
                          }}
                          fileType="application/pdf"
                          btnName={<>Browse</>}
                          btnProps={{
                            color: "primary"
                          }}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <br></br>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      เอกสารแบบพิมพ์
                    </GridItem>

                    <GridItem xs={3} sm={3}>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={event => {
                                setDocs({
                                  ...docs,
                                  docType2: event.target.checked
                                });
                                //   console.log(docs.docType2);
                              }}
                              color="primary"
                            />
                          }
                          label="ผตล. 2/1"
                        />
                      </div>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              onChange={event => {
                                setDocs({
                                  ...docs,
                                  docType1: event.target.checked
                                });
                              }}
                            />
                          }
                          label="ผตล. 2"
                        />
                      </div>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      ></div>
                    </GridItem>
                    <GridItem xs={9} sm={6}>
                      <div style={{ width: "100%" }}>
                        <FileUpload
                          id="file-upload-1"
                          label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                          fileName={uploadFileName}
                          remove
                          onFileRemove={() => {
                            setUploadFileName("");
                            setUploadFileUUID("");
                          }}
                          url={getFileDownloadUrl("inspect", uploadFileUUID)}
                          download
                          onFileHandler={(e, f, p) => {
                            uploadFile("inspect", f, p)
                              .then(r => {
                                const file = r.data.data;
                                sweetAlerts.success("แนบไฟล์เรียบร้อย");
                                setUploadFileName(file.fileName);
                                setUploadFileUUID(file.fileId);
                              })
                              .catch(err => {
                                console.log(err);
                              });
                          }}
                          fileType="application/pdf"
                          btnName={<>Browse</>}
                          btnProps={{
                            color: "primary"
                          }}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <hr></hr>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      เอกสารอื่นๆ
                    </GridItem>

                    <GridItem xs={3} sm={3}>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              onChange={event => {
                                setDocs({
                                  ...docs,
                                  docType11: event.target.checked
                                });
                              }}
                            />
                          }
                          label="อื่นๆ"
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={9} sm={6}>
                      <div style={{ width: "100%" }}>
                        <FileUpload
                          id="file-upload-2"
                          label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                          fileName={uploadFileOtherName}
                          remove
                          onFileRemove={() => {
                            setUploadFileOther("");
                            setUploadFileOtherName("");
                          }}
                          url={getFileDownloadUrl("inspect", uploadFileOther)}
                          download
                          onFileHandler={(e, f, p) => {
                            uploadFile("inspect", f, p)
                              .then(r => {
                                const file = r.data.data;
                                sweetAlerts.success("แนบไฟล์เรียบร้อย");
                                setUploadFileOtherName(file.fileName);
                                setUploadFileOther(file.fileId);
                              })
                              .catch(err => {
                                console.log(err);
                              });
                          }}
                          fileType="application/pdf"
                          btnName={<>Browse</>}
                          btnProps={{
                            color: "primary"
                          }}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button onClick={backtoMainHandler}>
                กลับไปหน้าจัดการข้อมูลผู้สมัคร
              </Button>
              <Button
                color="success"
                onClick={editOptPerson}
                disabled={isIdCardValid === false}
              >
                บันทึกข้อมูลผู้สมัคร
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </>
    );
  }
  return content;
};
export default AddCandidate;
