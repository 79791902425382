import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function editWtdPerson(wtdPersonId, body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/${wtdPersonId}/wtdPerson`;
  // const body = {
  //   body
  // };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getListwtd(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdListPerson${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addWtdPersonLaw(wtdPerson, wtdLawSectionId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdPerson/${wtdPerson}/Law`;
  const body = {
    wtdLawSectionId: wtdLawSectionId
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editWtdPersonLaw(wtdPersonId, wtdPersonLawId, wtdLawSectionId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdPersonLaw/${wtdPersonLawId}`;
  const body = {
    wtdPersonId: wtdPersonId,
    wtdLawSectionId: wtdLawSectionId
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delWtdPersonLaw(wtdPersonLawId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdPersonLaw/${wtdPersonLawId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getWtdPerson(wtdPersonId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/${wtdPersonId}/wtdPerson`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delWtdPerson(wtdPersonId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdPerson/${wtdPersonId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getCitizenWtd(citizenId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdPerson/citizenId/${citizenId}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addWtdPerson(data) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdPerson`;
  const body = data;
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addFile(wtdPersonId, wtdProvinceId, fileName, fileUUID) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdPerson/${wtdPersonId}/attatchFile`;
  const body = {
    wtdFileTypeId: 2,
    contentTypeId: 2,
    fileName: fileName,
    wtdProvinceId: wtdProvinceId,
    fileUUID: fileUUID
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delFile(wtdAttatchFileId, wtdPersonId, wtdPersonFileId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdAttatchFile/${wtdAttatchFileId}`;
  const body = {
    wtdPersonId: wtdPersonId,
    wtdPersonFileId: wtdPersonFileId
  };
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    },
    data: body
  });
}
export function editFile(wtdAttatchFileId, fileName, fileUUID) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdAttatchFile/${wtdAttatchFileId}`;
  const body = {
    fileName: fileName,

    fileUUID: fileUUID
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
