import Cookies from 'universal-cookie';
import { END_POINT_URL } from 'config/env.conf';
import axios from 'axios';
const cookies = new Cookies();

export function fileInfo(systemName, fileId) {
  const isAuth = cookies.get('userInfo');
  const url = `${END_POINT_URL}/file/${systemName}/info/${fileId}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`,
    },
  });
}
