import React, { useContext, useState } from "react";
/* API */
import {
  addVoteCardPublish,
  getStartNewNo
} from "contexts/API/VoteCard/Publish";

import Button from "components/CustomButtons/Button.js";
/* Layout */
import CardHeader from "components/Card/CardHeader.js";
/* Material Components */
import Datepicker from "components/Common/Datepicker";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//import ExcelUpload from "components/Common/ExcelUpload";
import { FileUpload } from "components/Common/FileUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxVotecardColor from "components/Common/ListboxVotecardColor";
import ListboxVotecardNumber from "components/Common/ListboxVotecardNumber";
import ListboxVotecardOptType from "components/Common/ListboxVotecardOptType";
import ListboxVotecardPrinting from "components/Common/ListboxVotecardPrinting";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import { VoteDateContext } from "contexts/VoteDate";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModaladdCardPublish = props => {
  const { sweetAlerts } = useSweetAlert();
  const {
    votecardPrintingSelected, //โรงพิมพ์
    onVotecardPrintingSelected,
    votecardNumberSelected, //หมายเลขบัตร
    onVotecardNumberSelected,
    votecardOptTypeSelected, //ประเภท อปท.
    onVotecardOptTypeSelected,
    positionTypeSelected, //ตำแหน่ง
    onPostionTypeSelected,
    votecardColorSelected, //สีบัตร
    onVotecardColorSelected
  } = useContext(VoteDateContext);

  const { showModaladdCard, closeModaladdCard } = props;
  //const [printingDate, setPrintingDate] = useState(null);
  const [, setClickSearch] = useState(false);
  const classes = useStyles();

  const [voteCardLot, setVoteCardLot] = useState(1);
  const [voteCardCategory, setVoteCardCategory] = useState("ก");
  const [voteCardVolumnQuantity, setVoteCardVolumnQuantity] = useState(1);
  const [voteCardVolumnAmount, setVoteCardVolumnAmount] = useState(20);
  const [voteCardVolumnStartNo, setVoteCardVolumnStartNo] = useState(1);
  const [voteCardVolumnEndNo, setVoteCardVolumnEndNo] = useState(20);
  const [voteCardPublishDocumentNo, setVoteCardPublishDocumentNo] = useState(
    ""
  );
  const [voteCardPublishDateTime, setVoteCardPublishDateTime] = useState(
    new Date().toISOString()
  );
  const [voteCardDetailID, setVoteCardDetailID] = useState(null);
  const [fileUUID, setFileUUID] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(true);

  const handleClose = () => {
    closeModaladdCard();

    setVoteCardLot(1);
    setVoteCardCategory("ก");
    setVoteCardVolumnQuantity(1);
    setVoteCardVolumnAmount(20);
    setVoteCardVolumnStartNo(1);
    setVoteCardVolumnEndNo(20);
    setVoteCardPublishDocumentNo("");
    setVoteCardPublishDateTime(new Date().toISOString());
    setFileUUID("");
    setFileName("");
    setFileUrl("");
  };

  const onClickValidation = e => {
    setClickSearch(false);

    if (votecardPrintingSelected == null) {
      sweetAlerts.warning("กรุณาเลือกโรงพิมพ์");
      return;
    } else if (votecardOptTypeSelected == null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
      return;
    } else if (positionTypeSelected == null) {
      sweetAlerts.warning("กรุณาเลือกตำแหน่ง");
      return;
    } else if (votecardColorSelected == null) {
      sweetAlerts.warning("กรุณาเลือกสีบัตร");
      return;
    } else if (votecardNumberSelected == null) {
      sweetAlerts.warning("กรุณาเลือกหมายเลขบัตร");
      return;
    }

    const optQueryUrl = () => {
      let endPoint = "?";

      if (votecardPrintingSelected !== null) {
        //
        endPoint += `PrintHouseID=${votecardPrintingSelected.id}&`;
      }

      if (votecardOptTypeSelected !== null) {
        endPoint += `OptTypeID=${votecardOptTypeSelected.id}&`;
      }

      if (positionTypeSelected !== null) {
        endPoint += `PositionTypeID=${positionTypeSelected.id}&`;
      }

      if (votecardColorSelected !== null) {
        endPoint += `CardColorID=${votecardColorSelected.id}&`;
      }

      if (votecardNumberSelected !== null) {
        endPoint += `CardNumberType=${votecardNumberSelected.id}&`;
      }

      return endPoint;
    };

    getStartNewNo(optQueryUrl(), "")
      .then(result => {
        if (typeof result.data.data != "undefined") {
          if (result.data.data.length > 0) {
            setVoteCardVolumnStartNo(
              result.data.data[0].VoteCardNumberNextStart != null
                ? result.data.data[0].VoteCardNumberNextStart
                : 1
            );
            setVoteCardVolumnEndNo(result.data.data[0].VoteCardNumberNextStart);

            setVoteCardDetailID(result.data.data[0].VoteCardDetailID);
            setVoteCardLot(parseInt(result.data.data[0].lot_number) + 1);

            setSaveDisabled(false);
          } else {
            sweetAlerts.warning("ไม่มีบัตรในคลัง");
            setVoteCardVolumnStartNo(1);
            setVoteCardVolumnEndNo(20);
            setSaveDisabled(true);
            setVoteCardLot(1);
          }
        } else {
          setVoteCardVolumnStartNo(1);
          setVoteCardVolumnEndNo(20);
        }

        setVoteCardVolumnQuantity(1);
        setVoteCardVolumnAmount(20);
        setClickSearch(true);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถตรวจสอบข้อมูลได้", err.toString());
      });
  };

  const onChangeVolumnQuantity = e => {
    let _newVolumn = parseInt(e.target.value);
    if (_newVolumn < 0) {
      sweetAlerts.warning("จำนวนเล่มต้องไม่น้อยกว่า 0");
      _newVolumn = 1;

      setVoteCardVolumnStartNo(1);
    }

    let _newVolumnAmount = _newVolumn * 20;
    //let _newVolumnEndNo = parseInt(voteCardVolumnEndNo) + _newVolumnAmount;

    setVoteCardVolumnQuantity(_newVolumn);
    setVoteCardVolumnAmount(_newVolumnAmount);
    setVoteCardVolumnEndNo(voteCardVolumnStartNo + _newVolumn - 1);
  };

  const onClickSaveHandler = () => {
    if (votecardPrintingSelected === null) {
      sweetAlerts.warning("กรุณาเลือกโรงพิมพ์");
      return;
    } else if (votecardOptTypeSelected === null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
      return;
    } else if (positionTypeSelected === null) {
      sweetAlerts.warning("กรุณาเลือกตำแหน่ง");
      return;
    } else if (votecardColorSelected === null) {
      sweetAlerts.warning("กรุณาเลือกสีบัตร");
      return;
    } else if (votecardNumberSelected === null) {
      sweetAlerts.warning("กรุณาเลือกหมายเลขบัตร");
      return;
    } else if (voteCardLot === null) {
      sweetAlerts.warning("กรุณาระบุ Lot");
      return;
    } else if (voteCardCategory === null) {
      sweetAlerts.warning("กรุณาระบุหมวดหมู่");
      return;
    } else if (voteCardVolumnQuantity === "" || voteCardVolumnQuantity === "0") {
      sweetAlerts.warning("กรุณาระบุจำนวนเล่ม");
      return;
    } else if (voteCardPublishDocumentNo === "") {
      sweetAlerts.warning("กรุณาระบุเลขที่หนังสือสั่งพิมพ์");
      return;
    } else if (voteCardPublishDateTime === null) {
      sweetAlerts.warning("กรุณาระบุวันที่สั่งพิมพ์");
      return;
    }

    const body = {
      votePrintingHouseID: votecardPrintingSelected.id,
      voteCardColorID: votecardColorSelected.id,
      voteCardNumberTypeID: parseInt(votecardNumberSelected.id),
      voteCardDetailID: voteCardDetailID,
      voteCardOptTypeID: votecardOptTypeSelected.id,
      optPositionTypeID: positionTypeSelected.id,
      voteCardPublishDocumentNo: voteCardPublishDocumentNo,
      voteCardPublishDocumentDate: voteCardPublishDateTime.split("T")[0],
      volumnQuantity: voteCardVolumnQuantity, //(parseInt(voteCardVolumnQuantity) * 20),
      volumnStartNo: voteCardVolumnStartNo,
      volumnEndNo: voteCardVolumnEndNo,
      lotNumber: voteCardLot,
      category: voteCardCategory,
      fileUUID: fileUUID
    };

    _add(body);
  };

  const _add = body => {
    addVoteCardPublish(body)
      .then(r => {
        sweetAlerts.success("บันทึกข้อมูลสำเร็จ");

        closeModaladdCard();

        setVoteCardLot(1);
        setVoteCardCategory("ก");
        setVoteCardVolumnQuantity(1);
        setVoteCardVolumnAmount(20);
        setVoteCardVolumnStartNo(1);
        setVoteCardVolumnEndNo(20);
        setVoteCardPublishDocumentNo("");
        setVoteCardPublishDateTime(new Date().toISOString());
        setFileUUID("");
        setFileName("");
        setFileUrl("");
      })
      .catch(error => {
        if (error.response) {
          sweetAlerts.error(
            "ไม่สามารถบันทึกข้อมูล : ",
            error.response.data.desc
          );
        }
      });
  };

  return (
    <div className={showModaladdCard ? "modal" : "hide"}>
      <Dialog
        open={showModaladdCard}
        fullWidth={true}
        transition={Transition}
        keepMounted
        onClose={closeModaladdCard}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>
              เพิ่มรายการสั่งพิมพ์บัตรเลือกตั้ง
            </h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <ListboxVotecardPrinting
                value={votecardPrintingSelected}
                onChange={onVotecardPrintingSelected}
              ></ListboxVotecardPrinting>
              <ListboxVotecardOptType
                value={votecardOptTypeSelected}
                onChange={onVotecardOptTypeSelected}
              ></ListboxVotecardOptType>
              <ListboxPositionType
                value={positionTypeSelected}
                onChange={onPostionTypeSelected}
              ></ListboxPositionType>
              <ListboxVotecardColor
                votecardPrintingSelected={votecardPrintingSelected}
                value={votecardColorSelected}
                onChange={onVotecardColorSelected}
              ></ListboxVotecardColor>
              <ListboxVotecardNumber
                votecardPrintingSelected={votecardPrintingSelected}
                votecardColorSelected={votecardColorSelected}
                value={votecardNumberSelected}
                onChange={onVotecardNumberSelected}
              ></ListboxVotecardNumber>
              <br />
              <br />
              <TextField
                id="voteCardCategory"
                label="หมวด"
                required
                fullWidth
                value={voteCardCategory}
                defaultValue={voteCardCategory}
                onChange={e => {
                  setVoteCardCategory(e.target.value);
                }}
              />
              <br />
              <br />
              <TextField
                id="voteCardLot"
                label="ครั้งที่"
                required
                fullWidth
                value={voteCardLot}
                onChange={e => {
                  setVoteCardLot(e.target.value);
                }}
              />
            </GridItem>
          </GridContainer>
          <br></br>
          <GridContainer
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem xs={12} sm={12} md={6}>
              <Button onClick={onClickValidation}>ตรวจสอบเล่มล่าสุด</Button>
            </GridItem>
          </GridContainer>
          <br></br>
          <form className={classes.form}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <TextField
                  id="volumnQuantity"
                  type="number"
                  label="จำนวนเล่ม"
                  fullWidth
                  required
                  value={voteCardVolumnQuantity}
                  onChange={e => {
                    onChangeVolumnQuantity(e);
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  id="volumnAmount"
                  name=""
                  label="จำนวนฉบับ"
                  InputProps={{
                    readOnly: true
                  }}
                  fullWidth
                  value={voteCardVolumnAmount}
                />
              </GridItem>
            </GridContainer>
            <br></br>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <TextField
                  id="volumnStartNo"
                  label="เริ่มจากเล่มที่"
                  InputProps={{
                    readOnly: true
                  }}
                  fullWidth
                  value={voteCardVolumnStartNo}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  id="volumnEndNo"
                  label="ถึงเล่มที่"
                  InputProps={{
                    readOnly: true
                  }}
                  fullWidth
                  value={voteCardVolumnEndNo}
                />
              </GridItem>
            </GridContainer>
            <br></br>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <TextField
                  id="voteCardPublishDocumentNo"
                  label="เลขที่หนังสือสั่งพิมพ์"
                  required
                  fullWidth
                  value={voteCardPublishDocumentNo}
                  defaultValue={voteCardPublishDocumentNo}
                  onChange={e => {
                    setVoteCardPublishDocumentNo(e.target.value);
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6} className={classes.marginNormal1}>
                <Datepicker
                  id="publishDateTime"
                  label="วันที่หนังสือ"
                  required
                  value={voteCardPublishDateTime}
                  defaultValue={voteCardPublishDateTime}
                  onChange={date => {
                    setVoteCardPublishDateTime(date.format());
                  }}
                ></Datepicker>
              </GridItem>
            </GridContainer>
            <br></br>
            <GridContainer>
              <GridItem xs={12} md={12}>
                <FileUpload
                  id="file-upload-2"
                  label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                  remove
                  download
                  fileName={fileName}
                  url={fileUrl}
                  onFileRemove={() => {
                    setFileUrl("");
                    setFileName("");
                  }}
                  onFileHandler={(e, f, progressCb) => {
                    uploadFile("votecard", f, progressCb)
                      .then(r => {
                        const data = r.data.data;

                        if (data.fileId) {
                          setFileUrl(
                            getFileDownloadUrl("votecard", data.fileId)
                          );

                          setFileUUID(data.fileId);
                          setFileName(data.fileName);
                        }

                        sweetAlerts.success("แนบไฟล์เรียบร้อย");
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  }}
                  fileType="application/pdf"
                  btnName={<>Browse</>}
                  btnProps={{
                    color: "primary"
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
          <br></br>
          <hr></hr>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button size="sm" onClick={handleClose}>
              ยกเลิก
            </Button>
            <Button
              size="sm"
              color="success"
              disabled={saveDisabled}
              onClick={onClickSaveHandler}
            >
              บันทึก
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModaladdCardPublish;
