/*
  (เลือกตั้ง ทั้ง ผ.ถ./ส.ถ.) = 3
  (เลือกตั้ง ผ.ถ. อย่างเดียว) = 2
  (เลือกตั้ง ส.ถ. อย่างเดียว) = 1
 */
import {VoteDateEnum} from "../Constants/strings";

export const getVoteDateTypePrivateIdFromString = (str) => {
    if( str.includes(VoteDateEnum.PRES_AND_MEM.name) ) {
        return VoteDateEnum.PRES_AND_MEM;
    }else if( str.includes(VoteDateEnum.ONLY_PRES.name) ) {
        return VoteDateEnum.ONLY_PRES;
    }else if( str.includes(VoteDateEnum.ONLY_MEM.name) ) {
        return VoteDateEnum.ONLY_MEM;
    }else {
        return VoteDateEnum.UNKNOWN
    }
}