import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxreplacePerson = props => {
  let optid = props.optSelected ? props.optSelected.id : 0;
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + `/opt/${optid}/publish/vote/replacedpersonpresList`,
    []
  );

  let content = (
    <TextField disabled label="เลือกผู้สมัครเดิม" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return {
        id: v.optPersonPresId,
        value: v.detail + " " + v.firstName + " " + v.lastName
      };
    });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        name={props.name}
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        options={data}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              error={error}
              helperText={helperText}
              label="เลือกผู้สมัครเดิม"
              margin="normal"
              fullWidth
            />
          );
        }}
        {...props.customProps}
      />
    );
  }

  return content;
};
export default ListboxreplacePerson;
