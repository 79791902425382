const ConvertDateFormat = props => {
  if (props) {

    const date = new Date(props);
    let day = date.getDate();
    let month = (date.getMonth() + 1);
    let year = date.getFullYear() + 543;

    return year.toString() + month.toString().padStart(2, '0') + day.toString().padStart(2, '0');

  } else return null;
};
export default ConvertDateFormat;
