/**
 * MustIncludes
 *
 * validate value must be in constraint
 *
 * @param {Array} constraint constraint array
 *
 * @return {Array} return [bool, desc]
 */

export default function MustIncludes(constraint) {
  return function (v) {
    if (v) {
      if (typeof v === 'string') {
        if (!constraint.includes(v)) {
          return [true, `ต้องประกอบด้วย (${constraint.toString()})`];
        }else {
          return [false, ""]; 
        }
      }
      if (v.value) {
        if (!constraint.includes(v.value)) {
          return [true, `ต้องประกอบด้วย (${constraint.toString()})`];
        }else {
          return [false, ""]; 
        }
      }
    }
    
    return [true, `ต้องประกอบด้วย (${constraint.toString()})`];
  };
}
