import MaterialTable from "components/Table/MaterialTable";
import React, { useState, useEffect } from "react";
import { getVoteType, editVoteType } from "contexts/API/OPT/Setting";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { useSnackBar } from "components/Alert/Snackbar";

const Votetype = props => {
  const { sweetAlerts } = useSweetAlert();
  const { snackBars } = useSnackBar();
  const [data, setData] = useState([]);
  const optionsTable = { pageSize: 10 };
  const [columns] = useState([
    {
      title: "voteTypeId",
      field: "voteTypeId",
      hidden: true
    },
    {
      title: "ประเภทการเลือกตั้ง",
      field: "detail",
      cellStyle: {
        minWidth: "700px"
      }
    }
  ]);
  useEffect(() => {
    getVoteType()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaterialTable
      title={"บริหารจัดการประเภทการเลือกตั้ง"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              editVoteType(oldData.voteTypeId, newData.detail)
                .then(result => {
                  snackBars.success("แก้ไขข้อมูลเรียบร้อยแล้ว", 3000);
                  setData([...dataUpdate]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });

              resolve();
            }, 1000);
          })
      }}
    />
  );
};
export default Votetype;
