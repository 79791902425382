import React, { useContext } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { InspectorContext } from "contexts/Inspector";
import Slide from "@material-ui/core/Slide";
//import TableComponent from "components/Table/MaterialTable";
//import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//import TextField from "@material-ui/core/TextField";
//import Select from "@material-ui/core/Select";
import { useSweetAlert } from "components/Alert/SweetAlert";

/* API */
//import { getCandidate8 } from "contexts/API/INSPEC/Selection8";

//import ListboxInspectPersonSelect8 from "components/Common/ListboxInspectPersonSelect8";


const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalPromote = props => {
  const { showModalPromote, closeModalPromote } = props;
  const { } = useContext(InspectorContext);
  //const { sweetAlerts } = useSweetAlert();
  const classes = useStyles();

  const onSubmit = e => {
    //sweetAlerts.warning("กรุณาเลือกจังหวัด");
    //return;
  };

  return (
    <div className={showModalPromote ? "modal" : "hide"}>
      <Dialog
        open={showModalPromote}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModalPromote}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>ผลการจับสลาก</h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer
            justify="space-around"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={4} sm={4} md={4}>
              ภูมิลำเนาเดิม (1)
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {/* <ListboxInspectPersonSelect8
                provinceId={10}
                inspectApplyDateSelected={9}
                defaultValue={{
                  //id: props.rowData.personApplyCmdId
                }}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    //props.rowData.personApplyCmdId = e.id;
                  }
                }}
              /> */}
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="space-around"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={4} sm={4} md={4}>
              ภูมิลำเนาเดิม (2)
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {/* <ListboxInspectPersonSelect8
                provinceId={provinceId}
                inspectApplyDateSelected={inspectApplyDateSelected}
                defaultValue={{
                  //id: props.rowData.personApplyCmdId
                }}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    //props.rowData.personApplyCmdId = e.id;
                  }
                }}
              /> */}
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="space-around"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={4} sm={4} md={4}>
              จังหวัด (1)
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {/* <ListboxInspectPersonSelect8
                provinceId={provinceId}
                inspectApplyDateSelected={inspectApplyDateSelected}
                defaultValue={{
                  //id: props.rowData.personApplyCmdId
                }}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    //props.rowData.personApplyCmdId = e.id;
                  }
                }}
              /> */}
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="space-around"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={4} sm={4} md={4}>
              จังหวัด (2)
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {/* <ListboxInspectPersonSelect8
                provinceId={provinceId}
                inspectApplyDateSelected={inspectApplyDateSelected}
                defaultValue={{
                  //id: props.rowData.personApplyCmdId
                }}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    //props.rowData.personApplyCmdId = e.id;
                  }
                }}
              /> */}
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="space-around"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={4} sm={4} md={4}>
              จังหวัด (3)
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {/* <ListboxInspectPersonSelect8
                provinceId={provinceId}
                inspectApplyDateSelected={inspectApplyDateSelected}
                defaultValue={{
                  //id: props.rowData.personApplyCmdId
                }}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    //props.rowData.personApplyCmdId = e.id;
                  }
                }}
              /> */}
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="space-around"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={4} sm={4} md={4}>
              จังหวัด (4)
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {/* <ListboxInspectPersonSelect8
                provinceId={provinceId}
                inspectApplyDateSelected={inspectApplyDateSelected}
                defaultValue={{
                  //id: props.rowData.personApplyCmdId
                }}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    //props.rowData.personApplyCmdId = e.id;
                  }
                }}
              /> */}
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="space-around"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={4} sm={4} md={4}>
              จังหวัด (5)
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {/* <ListboxInspectPersonSelect8
                provinceId={provinceId}
                inspectApplyDateSelected={inspectApplyDateSelected}
                defaultValue={{
                  //id: props.rowData.personApplyCmdId
                }}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    //props.rowData.personApplyCmdId = e.id;
                  }
                }}
              /> */}
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="space-around"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={4} sm={4} md={4}>
              จังหวัด (6)
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {/*  <ListboxInspectPersonSelect8
                provinceId={provinceId}
                inspectApplyDateSelected={inspectApplyDateSelected}
                defaultValue={{
                  //id: props.rowData.personApplyCmdId
                }}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    //props.rowData.personApplyCmdId = e.id;
                  }
                }}
              /> */}
            </GridItem>
          </GridContainer>
          <br></br>
          <hr></hr>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={closeModalPromote}>ยกเลิก</Button>
            <Button
              type="button"
              variant="contained"
              color="success"
              onClick={onSubmit()}
            >
              บันทึกผลการจับสลาก
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalPromote;
