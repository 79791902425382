import React, { useContext, useState } from "react";
import { deletedOptForm, getFormLists } from "contexts/API/OPT/Form61";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import ConvertDate from "utils/ConvertDate";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LinkDownload from "components/Common/LinkDownload";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
//import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxPositionFollowVoteDate from "components/Common/ListboxPositionFollowVoteDate";
import ListboxProvince from "components/Common/ListboxProvince.js";
//import ListboxVotedate from "components/Common/ListboxVotedateMax";
import ListboxVotedate from "components/Common/ListboxVotedate";
import MUItable from "components/Table/MaterialTable";
import ModalImportFile from "./ModalImportFIle";
import ModaleditForm from "./ModaleditForm";
import ModalupLoadFile from "./ModalupLoadFile";
import Paper from "@material-ui/core/Paper";
import { REPORTING_SERVICE } from "config/env.conf";
import { VoteDateContext } from "contexts/VoteDate";
// core components
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const FormManage61 = ({ pageProps, setPageProps }) => {
  const [showModal, setshowModal] = useState(false);
  const openModal = () => setshowModal(true);
  //const closeModal = () => setshowModal(false);

  const [showeditModal, setshoweditModal] = useState(false);
  //const openeditModal = () => setshoweditModal(true);
  const closeeditModal = () => setshoweditModal(false);
  const closeModal = () => {
    onClickSearch();
    setshowModal(false);
  };
  const [showupLoad, setShowupLoad] = useState(false);
  const openModalupLoad = () => setShowupLoad(true);
  const closeModalupLoad = () => setShowupLoad(false);

  const [data, setData] = useState([]);
  const [issearch, setIssearch] = useState(false);

  const { sweetAlerts } = useSweetAlert();
  const useStyles = makeStyles({
    buttoncustom: {
      fontSize: 14,
      fontWeight: 500
      //color: props => props.color
    }
  });
  const {
    onPositionFollowVoteDateSelected,
    positionFollowVoteDateSelected,
    optVoteTypeSelected,
    provinceSelected,
    optTypeSelected,
    amphurSelected,
    tambolSelected,
    optSelected,

    onOptVoteTypeSelected,
    onProvinceSelected,
    onOptTypeSingleSelected,
    onAmphurSelected,

    onOptSelected,
    votedateSelected,
    onVotedateSelected,
    //uttils
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);
  const classes = useStyles();
  const [children, setchildren] = useState([]);

  /*  const addImportFileHandler = () => {
    setPageProps({ active: "ImportFile61", props: null });
  };
 */
  const addSuccess = optName => {
    if (optName) {
      // setLoading(false);
      // setClickSearch(null);
      onClickSearch();
    }
  };

  const uploadSuccess = affectedRows => {
    if (affectedRows) {
      onClickSearch();
    }
  };
  const onClickSearch = e => {
    const optTypes = getOptTypeSelectedLists();

    if (optTypes.length === 0) {
      sweetAlerts.warning("กรุณาเลือกประเภท อปท.");
      return;
    }
    if (optVoteTypeSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภทการเลือกตั้ง");
      return;
    }
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }
    if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันเลือกตั้ง");
      return;
    }
    if (positionFollowVoteDateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกตำแหน่ง");
      return;
    }

    getFormLists(optSelected, votedateSelected, positionFollowVoteDateSelected)
      .then(result => {
        if (result === null) {
          setData([]);
        } else {
          console.log(">>>>", result.data.data);
          setData(result.data.data);
        }
        setIssearch(true);
      })
      .catch(err => {
        sweetAlerts.error("การถดึงข้อมูล อปท.", err.response.data.desc);
      });
  };

  const callReport = (
    provinceSelectedId,
    positionTypeId,
    voteDate,
    optSelectedId,
    optVoteTypeSelectedId,
    frm6typeId,
    optForm6Id
  ) => {
    let url = "";
    let voteDateId = voteDate.split("T")[0];

    if (frm6typeId === 1) {
      //"ผ.ถ. 6/1 //มันคือ สถ6/1
      url = `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2Freport_online%2Fopt%2Fopt_frm9_4_61_Grouping&rs:Command=Render&rc:Parameters=Collapsed&province_id=${provinceSelectedId}&position_type=${positionTypeId}&vote_date=${voteDateId}&opt_id=${optSelectedId}&vote_type=${optVoteTypeSelectedId}&form_id=${optForm6Id}`;
    }
    if (frm6typeId === 2) {
      //ส.ถ. 6/1 //d
      url = `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2Freport_online%2Fopt%2Fopt_frm9_4_61_Grouping&rs:Command=Render&rc:Parameters=Collapsed&province_id=${provinceSelectedId}&position_type=${positionTypeId}&vote_date=${voteDateId}&opt_id=${optSelectedId}&vote_type=${optVoteTypeSelectedId}&form_id=${optForm6Id}`;
    }
    if (frm6typeId === 3) {
      //ผ.ถ. 6/1 (น)
      url = `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2Freport_online%2Fopt%2Fopt_frm9_5_61n_Grouping&rs:Command=Render&rc:Parameters=Collapsed&province_id=${provinceSelectedId}&position_type=${positionTypeId}&vote_date=${voteDateId}&opt_id=${optSelectedId}&vote_type=${optVoteTypeSelectedId}&form_id=${optForm6Id}`;
    }
    if (frm6typeId === 4) {
      //ส.ถ. 6/1 (น) //d
      url = `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2Freport_online%2Fopt%2Fopt_frm9_5_61n_Grouping&rs:Command=Render&rc:Parameters=Collapsed&province_id=${provinceSelectedId}&position_type=${positionTypeId}&vote_date=${voteDateId}&opt_id=${optSelectedId}&vote_type=${optVoteTypeSelectedId}&form_id=${optForm6Id}`;
    }
    if (frm6typeId === 5) {
      //"ส.ถ. 6/2" //d
      url = `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2Freport_online%2Fopt%2Fopt_frm9_6_62_Grouping&rs:Command=Render&rc:Parameters=Collapsed&province_id=${provinceSelectedId}&position_type=${positionTypeId}&vote_date=${voteDateId}&opt_id=${optSelectedId}&vote_type=${optVoteTypeSelectedId}&form_id=${optForm6Id}`;
    }
    if (frm6typeId === 6) {
      //"ส.ถ. 6/3" //d
      url = `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2Freport_online%2Fopt%2Fopt_frm9_7_63_Grouping&rs:Command=Render&rc:Parameters=Collapsed&province_id=${provinceSelectedId}&position_type=${positionTypeId}&vote_date=${voteDateId}&opt_id=${optSelectedId}&vote_type=${optVoteTypeSelectedId}&form_id=${optForm6Id}`;
    }
    // const openurl = `${url}province_id=${
    //   provinceSelected.id
    // }&rpt_send_date=${moment(new Date()).format("YYYY-MM-D")}`;
    window.open(url, "_blank");
  };
  const columns = [
    {
      field: "optForm6Id",
      title: "optForm6Id",
      hidden: true
    },
    {
      field: "frm6typeId",
      title: "frm6typeId",
      hidden: true
    },
    {
      field: "positionTypeId",
      title: "positionTypeId",
      hidden: true
    },
    {
      field: "frm6type",
      title: "ประเภทแบบ",
      cellStyle: {
        minWidth: "150px"
      }
    },
    {
      field: "voteDate",
      title: "วันที่เลือกตั้ง",
      cellStyle: {
        minWidth: "150px"
      },
      render: rowData => {
        return ConvertDate(rowData.voteDate);
      }
    },
    {
      field: "positionType",
      title: "ตำแหน่ง",
      cellStyle: {
        minWidth: "100px"
      }
    },
    {
      field: "qtyPostPublish",
      title: "จำนวนที่ประกาศ",
      cellStyle: {
        minWidth: "120px"
      }
    },
    /* {
      field: "sinceDate",
      title: "วันที่มีผลบังคับ",
      cellStyle: {
        minWidth: "200px"
      },
      render: rowData => {
        return ConvertDate(rowData.sinceDate);
      }
    }, */
    {
      field: "noticeDate",
      title: "วันที่ประกาศ",
      cellStyle: {
        minWidth: "200px"
      },
      render: rowData => {
        return ConvertDate(rowData.noticeDate);
      }
    },
    {
      field: "",
      title: "ประเภทการเลือกตั้ง",
      cellStyle: {
        minWidth: "200px"
      },
      render: rowData => {
        return optVoteTypeSelected.value;
      }
    },

    {
      field: "filename",
      title: "เอกสารแนบ",
      cellStyle: {
        minWidth: "200px"
      },
      render: rowData => {
        return rowData.fileUUID ? (
          <LinkDownload
            url={getFileDownloadUrl("opt", rowData.fileUUID)}
            fileId={rowData.fileUUID}
            fileName={rowData.filename}
          />
        ) : (
          ""
        );
      }
    },

    {
      field: "",
      export: false,
      cellStyle: {
        minWidth: "320px"
      },
      render: rowData => {
        return (
          <>
            {/*  <Button
              size="sm"
              color="warning"
              onClick={() => {
                openeditModal();
                setchildren(rowData);
                // settitleModal("แก้ไขรายชื่อ อปท.");
              }}
            >
              แก้ไข
            </Button> */}
            <Button
              size="sm"
              color="danger"
              onClick={() => {
                // console.log(rowData);
                const dataDelete = [...data];
                const index = rowData.tableData.id;
                dataDelete.splice(index, 1);

                sweetAlerts.error(
                  "ยืนการลบข้อมูล",
                  "คุณต้องการลบข้อมูลใช่หรือไม่",
                  () => {
                    deletedOptForm(rowData.optForm6Id, rowData.positionTypeId)
                      .then(result => {
                        setData([...dataDelete]);
                        sweetAlerts.success(`ลบข้อมูลสำเร็จแล้ว`);
                      })
                      .catch(err => {
                        if (err.response.data.code !== 20000) {
                          sweetAlerts.danger(
                            `ไม่สามารถลบข้อมูลได้`,
                            err.response.data.desc
                          );
                        }
                      });
                  },
                  true
                );
              }}
            >
              ลบ
            </Button>
            <Button
              size="sm"
              color="info"
              onClick={() =>
                callReport(
                  provinceSelected.id,
                  rowData.positionTypeId,
                  rowData.voteDate,
                  optSelected.id,

                  optVoteTypeSelected.id,
                  rowData.frm6typeId,
                  rowData.optForm6Id
                )
              }
            >
              พิมพ์แบบ
            </Button>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                openModalupLoad();
                setchildren(rowData);
              }}
            >
              นำข้อมูลเข้า
            </Button>
          </>
        );
      }
    }
  ];

  const options_table = {};

  return (
    <>
      {showModal ? (
        <ModalImportFile
          closeModal={closeModal}
          showModal={showModal}
          onaddSuccess={addSuccess}
        >
          {children ? children : ""}
        </ModalImportFile>
      ) : null}
      {showupLoad ? (
        <ModalupLoadFile
          closeModalupLoad={closeModalupLoad}
          showupLoad={showupLoad}
          onUploadSuccess={uploadSuccess}
        >
          {children ? children : ""}
        </ModalupLoadFile>
      ) : null}

      {showeditModal ? (
        <ModaleditForm
          closeeditModal={closeeditModal}
          showeditModal={showeditModal}
          onaddSuccess={addSuccess}
        >
          {children ? children : ""}
        </ModaleditForm>
      ) : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" text>
              <CardText color="primary">
                <h4 className={classes.cardTitle}>ค้นหาแบบ ส.ถ./ผ.ถ. 6/1</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <br /> <br />
                  <GridContainer
                    justify="space-between"
                    alignItems="center"
                    direction="row"
                  >
                    <GridItem xs={12} sm={12} md={12}>
                      <CheckboxOpttype
                        optTypeSelected={optTypeSelected}
                        onOptTypeSelected={onOptTypeSingleSelected}
                        required={true}
                      ></CheckboxOpttype>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxOptVotetype
                        value={optVoteTypeSelected}
                        onChange={onOptVoteTypeSelected}
                        required={true}
                      ></ListboxOptVotetype>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxProvince
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                        required={true}
                      ></ListboxProvince>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxDistinct
                        provinceSelected={provinceSelected}
                        value={amphurSelected}
                        onChange={onAmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxOpts
                        value={optSelected}
                        getOptTypeSelectedLists={getOptTypeSelectedLists}
                        optTypeSelected={optTypeSelected}
                        provinceSelected={provinceSelected}
                        amphurSelected={amphurSelected}
                        tambolSelected={tambolSelected}
                        onChange={onOptSelected}
                      ></ListboxOpts>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxVotedate
                        value={votedateSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onVotedateSelected}
                        required={true}
                      ></ListboxVotedate>

                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxPositionFollowVoteDate
                        value={positionFollowVoteDateSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        votedateSelected={votedateSelected}
                        onChange={onPositionFollowVoteDateSelected}
                      ></ListboxPositionFollowVoteDate>
                      {/* <ListboxPositionType
                        value={positionTypeSelected}
                        onChange={onPostionTypeSelected}
                        required={true}
                      ></ListboxPositionType> */}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>

              <GridContainer
                direction="column"
                justify="flex-end"
                alignItems="flex-end"
                style={{ paddingTop: "30px" }}
              >
                <GridItem xs={6} sm={6} md={6}>
                  {/* <Button
                      className={classes.buttoncustom}
                      color="success"
                      onClick={addImportFileHandler}
                    >
                      เพิ่มแบบส.ถ./ผ.ถ. 6/1
                    </Button> */}
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Button className={classes.buttoncustom}>ยกเลิก</Button>

                  <Button
                    color="info"
                    className={classes.buttoncustom}
                    onClick={onClickSearch}
                  >
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {issearch ? (
        <Card>
          <CardBody>
            <GridContainer
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <GridItem xs={6} sm={6} md={6}>
                <Button
                  color="success"
                  onClick={() => {
                    openModal();
                  }}
                >
                  จัดการรายชื่อ
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
          <MUItable
            data={data}
            columns={columns}
            title={"รายการ  ส.ถ./ผ.ถ. 6/1 ที่ได้บันทึกไว้ในระบบ"}
            options={options_table}
            components={{
              Container: props => <Paper {...props} elevation={0} />
            }}
          />
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export default FormManage61;
