import MaterialTable from "components/Table/MaterialTable";
import React, { useState, useEffect } from "react";
import { getSubOpttype, editSubOpttype } from "contexts/API/OPT/Setting";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { useSnackBar } from "components/Alert/Snackbar";

const OptSubtype = props => {
  const { sweetAlerts } = useSweetAlert();
  const { snackBars } = useSnackBar();
  const [data, setData] = useState([]);
  const optionsTable = { pageSize: 10 };
  const [columns] = useState([
    {
      title: "ms_opt_sub_type_id",
      field: "ms_opt_type_id",
      hidden: true
    },
    {
      title: "ชื่อ อปท.ย่อย แบบเต็ม",
      field: "opt_name",
      cellStyle: {
        minWidth: "300px"
      }
    },
    {
      title: "ชื่อ อปท.ย่อย แบบสั้น",
      field: "opt_short_name",
      cellStyle: {
        minWidth: "300px"
      }
    }
  ]);
  useEffect(() => {
    getSubOpttype()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaterialTable
      title={"บริหารจัดการประเภท อปท.ย่อย"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        // onRowAdd: newData =>
        //   new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       addsuspendLawType(newData.Name, newData.ShortName)
        //         .then(result => {
        //           snackBars.success("เพิ่มข้อมูลเรียบร้อยแล้ว", 3000);
        //           setData([...data, newData]);
        //         })
        //         .catch(err => {
        //           sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
        //         });

        //       resolve();
        //     }, 1000);
        //   }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              editSubOpttype(
                oldData.ms_opt_sub_type_id,
                newData.opt_name,
                newData.opt_short_name
              )
                .then(result => {
                  snackBars.success("แก้ไขข้อมูลเรียบร้อยแล้ว", 3000);
                  setData([...dataUpdate]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });

              resolve();
            }, 1000);
          })
        // onRowDelete: oldData =>
        //   new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       const dataDelete = [...data];
        //       const index = oldData.tableData.id;
        //       dataDelete.splice(index, 1);
        //       deletesuspendLawType(oldData.SuspendLawTypeId)
        //         .then(result => {
        //           snackBars.success("ลบข้อมูลเรียบร้อยแล้ว", 3000);
        //           setData([...dataDelete]);
        //         })
        //         .catch(err => {
        //           sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
        //         });

        //       resolve();
        //     }, 1000);
        //   })
      }}
    />
  );
};
export default OptSubtype;
