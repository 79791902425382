import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getSearchLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchallocate${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getSearchDetailLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchallocatedetail${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getSearchDetailGroupAreaLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchallocatedetailgrouparea${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getSearchDetailInfoLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchallocatedetailinfo${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getSearchValidateByPurchaseDetailIDLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchallocatevalidatebypurchasedetailid${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getSearchComponentLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/voteCard/searchallocatecomponent${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getSearchNewLists(queryStr) {
  console.log(queryStr);
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchNewAllocate${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addVoteCardAllocate(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/addallocate`;

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function updateVoteCardAllocate(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/updatevotecardallocate}`;

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteVoteCardAllocateByID(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/deleteallocate`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    },
    data: body
  });
}


export function addVoteCardAllocateNoti(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/notification`;

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
