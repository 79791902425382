import React, { useState } from "react";

//import FormControl from "@material-ui/core/FormControl";
//import Button from "components/CustomButtons/Button.js";
import Card from "../Card/Card.js";
// @material-ui/icons
//import Check from "@material-ui/icons/Check";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";
import Checkbox from "@material-ui/core/Checkbox";
import Datepicker from "components/Common/Datepicker";
//import { VoteDateContext } from "contexts/VoteDate";
import { FileUpload } from "components/Common/FileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem.js";
import ListboxPositionStatus from "components/Common/ListboxPositionStatus";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
// import TextField from "@material-ui/core/TextField";
// import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
//import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useSweetAlert } from "components/Alert/SweetAlert";

const MemberDetailVotedate = props => {
  const useStyles = makeStyles({
    buttoncustom: {
      fontSize: 14,
      fontWeight: 500
      //color: props => props.color
    }
  });

  const classes = useStyles();
  const { sweetAlerts } = useSweetAlert();
  //const mindate = new Date();
  const [memCheckedUncontrolled, setMemCheckedUncontrolled] = useState(false);
  const [memUrlFile, setMemUrlFile] = useState();
  const [memNameFile, setMemNameFile] = useState();

  const memChecked = props.memChecked
    ? props.memChecked
    : memCheckedUncontrolled;
  const onMemChecked = props.onMemChecked
    ? props.onMemChecked
    : e => {
        setMemCheckedUncontrolled(e.target.checked);
      };

  return (
    <Card>
      <CardHeader>
        <h4 className={classes.cardTitle}>รายละเอียดจัดเลือกตั้งของ ส.ถ.</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="postionTypeId"
                  onChange={onMemChecked}
                  checked={memChecked}
                  inputRef={props.memCheckedRef}
                />
              }
              label="เลือก ส.ถ."
            />
          </GridItem>

          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={
              props.optVoteTypeSelected.id === 2 ? { display: "none" } : {}
            }
          >
            <ListboxPositionStatus
              disabled={!memChecked}
              name="memberPositionStatus"
              value={props.memPositionStatusSelected}
              // defaultValue={
              //   props.optVoteTypeSelected.id === 1 ? { id: 2 } : null
              // }
              //  disabled={props.optVoteTypeSelected.id === 1 ? true : false}
              onChange={props.onMemPositionStatusSelected}
            ></ListboxPositionStatus>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={
              props.optVoteTypeSelected.id === 2 ? { display: "none" } : {}
            }
          >
            <Datepicker
              disabled={!memChecked}
              // minDate={mindate}
              id="date-picker-member"
              label="วันที่มีเหตุต้องจัดการเลือกตั้ง"
              value={props.memEffectedDate}
              onChange={props.onMemEffectedDate}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <FileUpload
              disabled={!memChecked}
              id="file-upload-2"
              label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
              download
              remove
              fileName={memNameFile}
              url={memUrlFile}
              onFileRemove={() => {
                setMemUrlFile("");
                setMemNameFile("");
              }}
              onFileHandler={(e, f, progressCb) => {
                uploadFile("opt", f, progressCb)
                  .then(r => {
                    const data = r.data.data;

                    if (data.fileId) {
                      setMemUrlFile(getFileDownloadUrl("opt", data.fileId));
                      setMemNameFile(data.fileName);
                      props.setMemUUIDFile(data.fileId);
                      // props.onFileMemUpload();
                    }

                    sweetAlerts.success("แนบไฟล์เรียบร้อย");
                  })
                  .catch(err => {
                    console.log(err);
                  });
              }}
              fileType="application/pdf"
              btnName={<>Browse</>}
              btnProps={{
                color: "primary"
              }}
            />
          </GridItem>
          {/* <GridItem
            xs={12}
            sm={12}
            md={12}
            style={
              props.optVoteTypeSelected.id === 2 ? { display: "none" } : {}
            }
          >
            <CustomInput
              labelText="หมายเหตุ"
              id="remarkMem"
              // value={props.remarkMem}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: props.memRemark || "",
                disabled: !memChecked
                //  inputRef: props.memRemarkRef
              }}
            />
          </GridItem> */}
        </GridContainer>
      </CardBody>
    </Card>
  );
};
export default MemberDetailVotedate;
