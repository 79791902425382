import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
//import { UserInfoContext } from "contexts/users";
import { Validate } from "utils/Validators/Validate";
import conf from "config/env.conf.js";
//import { UserInfoContext } from "contexts/users";
import getDefaultValue from "utils/DefaultValue";
import useFetch from "hooks/useFetch";

const ListboxOpts = props => {
  let isEnable = false;
  let optIdLists = [];
  let query = "optsubtype=[]";
  const [, setError] = React.useState();
  const [valid, setValid] = React.useState();
  const [helperText, setHelperText] = React.useState("");
  const getOptTypeSelectedLists = () => {
    let optIdLists = [];
    for (const [, v] of Object.entries(props.optTypeSelected)) {
      if (v.checked) {
        optIdLists.push(v.value);
      }
    }
    return optIdLists;
  };

  if (props.optTypeSelected !== null && props.optTypeSelected !== undefined) {
    optIdLists = getOptTypeSelectedLists();
  } else if (
    props.listboxOptSelected !== null &&
    props.listboxOptSelected !== undefined
  ) {
    optIdLists = [props.listboxOptSelected.id];
  }

  if (optIdLists.length > 0) {
    query = `optsubtype=[${optIdLists.toString()}]`;
  }

  if (props.provinceSelected) {
    query += `&provinceId=${props.provinceSelected.id}`;
  }

  if (props.amphurSelected) {
    query += `&amphurId=${props.amphurSelected.id}`;
  }

  if (props.tambolSelected) {
    query += `&tambolId=${props.tambolSelected.id}`;
  }

  if (
    optIdLists.length > 0 ||
    props.listboxOptSelected ||
    (props.provinceSelected && props.provinceSelected.id !== 0)
  ) {
    isEnable = true;
  }

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/ms/opt/names?" + query,
    [
      props.listboxOptSelected,
      props.optTypeSelected,
      props.provinceSelected,
      props.amphurSelected,
      props.tambolSelected
    ],
    isEnable
  );

  let content = (
    <TextField disabled label="เลือกทุก อปท." margin="normal" fullWidth />
  );

  let data = [];

  if (
    isEnable &&
    !isLoading &&
    fetchData !== null &&
    fetchData.data !== undefined
  ) {
    data = fetchData.data.map(v => {
      return {
        id: v.ms_opt_id,
        value: v.optsubtype.opt_short_name + " " + v.opt_name
      };
    });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        disabled={props.disabled}
        name="opts"
        options={data}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={props.title ? props.title : "เลือก อปท."}
              margin="normal"
              error={valid}
              fullWidth
              helperText={helperText}
              inputProps={{
                ...params.inputProps
              }}
              onChange={Validate(
                params.inputProps.value,
                props.required,
                setValid
              )}
              required={props.required}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }

  return content;
};

export default ListboxOpts;
