import Autocomplete from "@material-ui/lab/Autocomplete";
/* core components */
import React from "react";
//import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxSuspendOrg = props => {

  let suspendOrgTypeId = "/";
  if (props.suspendOrgTypeIdSelected) {
    suspendOrgTypeId += props.suspendOrgTypeIdSelected.id;
  }

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/suspend/suspendOrg" + suspendOrgTypeId,
    []
  );

  let content = (<TextField
    disabled
    label="หน่วยงาน"
    margin="normal"
    fullWidth
  />);

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    fetchData.data = fetchData.data.filter(e => e.suspendOrgTypeId === props.orgTypeId);
    data = fetchData.data.map((v) => {
      return { id: v.suspendOrgId, value: v.orgName }
    });
    
  }

  if (data.length > 0) {
  
    content = (
      <Autocomplete
        name={props.name}
        disabled={props.disabled}
        id={props.id}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        options={data}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={props.onChange}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={
                props.title ? props.title : "หน่วยงาน"
              }
              margin="normal"
              fullWidth
            />
          );
        }}
        {...props.customProps}
      />
    );
  }
  return content;
};
export default ListboxSuspendOrg;
