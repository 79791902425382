import React, { useContext, useState } from "react";
//import Switch from "@material-ui/core/Switch";
//import { withStyles } from "@material-ui/core/styles";
//import Cookies from "universal-cookie";
import {
  editUseVoteMember,
  editUseVotePresident,
  getUseVote
} from "contexts/API/OPT/OptUseVote";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ListboxArea from "components/Common/ListboxArea";
import ListboxAumphurArea from "components/Common/ListboxAumphurArea";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxVotedate from "components/Common/ListboxVotedate";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { VoteDateContext } from "contexts/VoteDate";
import { useSweetAlert } from "components/Alert/SweetAlert";

//const cookies = new Cookies();
const OptVotepublish = props => {
  // console.log(props, "dd");
  const { sweetAlerts } = useSweetAlert();

  const {
    optVoteTypeSelected,
    onOptVoteTypeSelected,

    onProvinceSelected,
    provinceSelected,
    setProvinceSelected,
    onAmphurSelected,
    amphurSelected,

    tambolSelected,
    optTypeSelected,
    onOptSelected,
    optSelected,

    onVotedateSelected,
    onAumphurAreaSelected,
    onPostionTypeSelected,
    positionTypeSelected,
    aumphurAreaSelected,
    areaSelected,
    onAreaSelected,
    votedateSelected,
    onOptTypeSelected,
    setOptTypeSelected,

    getOptTypeSelectedLists
  } = useContext(VoteDateContext);
  const [loading, setLoading] = useState(false);
  const [columns] = React.useState([
    {
      title: "ลำดับ",
      field: "No",
      editable: "never",
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        if (
          rowData &&
          rowData.tableData !== undefined &&
          rowData.tableData.id !== undefined
        )
          return rowData.tableData.id + 1;
      }
    },
    {
      title: "optVoteId",
      field: "optVoteId",
      hidden: true
    },
    {
      title: "optAreaId",
      field: "optAreaId",
      hidden: true
    },
    {
      title: "ตำแหน่ง",
      field: "prositionTypeId",
      editable: "never",
      headerStyle: {
        textAlign: "center"
      },
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      render: rowData => {
        if (rowData.optPositionTypeId === 1) {
          return "ผ.ถ.";
        } else if (rowData.optPositionTypeId === 2) {
          return "ส.ถ.";
        }
      }
    },
    {
      title: "จำนวน ส.ถ.ปัจจุบัน",
      field: "numMemberNow",
      hidden: true
    },
    {
      title: "จำนวนหน่วยเลือกตั้ง",
      field: "numStationNow",
      hidden: true
    },
    {
      title: "อำเภอ",
      field: "amphurName",
      editable: "never",
      headerStyle: {
        textAlign: "center"
      },
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      }
    },
    {
      title: "เขต",
      field: "areaNo",
      editable: "never",
      headerStyle: {
        textAlign: "center"
      },
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      }
    },
    {
      //title: "ผู้มีสิทธิในบัญชีรายชื่อ",
      title: "ผู้มีสิทธิ",
      field: "numRightVote",
      cellStyle: {
        minWidth: "90px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },

      render: rowData => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={!rowData.numRightVote ? 0 : parseInt(rowData.numRightVote)}
          />
        );
      },
      editComponent: props => {
        console.log(props.rowData);
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={
              !props.rowData.numRightVote
                ? 0
                : parseInt(props.rowData.numRightVote)
            }
            onValueChange={values => {
              const { value } = values;

              props.rowData.numRightVote = parseInt(value);

              //this.setState({profit: formattedValue})
            }}
          />
        );
      }
    },
    {
      //title: "ผู้มีสิทธิที่มาแสดงตน",
      title: "ผู้มาแสดงตน",
      field: "numAppearCard",
      cellStyle: {
        minWidth: "90px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      exportData: row => {
        return !row.numAppearCard ? 0 : parseInt(row.numAppearCard);
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={!rowData.numAppearCard ? 0 : parseInt(rowData.numAppearCard)}
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={
              !props.rowData.numAppearCard
                ? 0
                : parseInt(props.rowData.numAppearCard)
            }
            onValueChange={values => {
              const { value } = values;

              props.rowData.numAppearCard = parseInt(value);

              // if (
              //   parseInt(props.rowData.numAppearCard) !==
              //   parseInt(props.rowData.numRightVote)
              // ) {
              //   sweetAlerts.warning("กรุณาใส่ตัวเลขให้ถูกต้อง");
              // }
              //this.setState({profit: formattedValue})
            }}
          />
        );
      }
    },
    {
      //title: "บัตรเลือกตั้งที่ได้รับมา",
      title: "บัตรที่ได้รับ",
      field: "numCard",
      type: "numeric",
      cellStyle: {
        minWidth: "90px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      exportData: row => {
        return !row.numCard ? 0 : parseInt(row.numCard);
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={!rowData.numCard ? 0 : parseInt(rowData.numCard)}
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={!props.rowData.numCard ? 0 : parseInt(props.rowData.numCard)}
            onValueChange={values => {
              const { value } = values;

              props.rowData.numCard = parseInt(value);
              //this.setState({profit: formattedValue})
            }}
          />
        );
      }
    },
    {
      //title: "บัตรเลือกตั้งที่ใช้",
      title: "บัตรที่ใช้",
      field: "numRealVote",
      type: "numeric",
      headerStyle: {
        textAlign: "center"
      },
      cellStyle: {
        minWidth: "90px",
        textAlign: "center"
      },
      exportData: row => {
        return !row.numRealVote ? 0 : parseInt(row.numRealVote);
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={!rowData.numRealVote ? 0 : parseInt(rowData.numRealVote)}
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={
              !props.rowData.numRealVote
                ? 0
                : parseInt(props.rowData.numRealVote)
            }
            onValueChange={values => {
              const { value } = values;
              props.rowData.numRealVote = parseInt(value);

              // if (
              //   parseInt(props.rowData.numRealVote) !==
              //   parseInt(props.rowData.numAppearCard)
              // ) {
              //   sweetAlerts.warning(
              //     "กรุณาระบุเหตุผล ",
              //     "บัตรเลือกตั้งที่ใช้ลงคะแนน ไม่เท่ากับ บัตรเลือกตั้งที่รับมา"
              //   );
              // }
            }}
          />
        );
      }
    },

    {
      title: "บัตรดี",
      field: "numGoodCard",
      type: "numeric",
      headerStyle: {
        textAlign: "center"
      },
      cellStyle: {
        minWidth: "90px"
      },
      exportData: row => {
        return !row.numGoodCard ? 0 : parseInt(row.numGoodCard);
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={!rowData.numGoodCard ? 0 : parseInt(rowData.numGoodCard)}
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={!props.rowData.numGoodCard ? 0 : parseInt(props.rowData.numGoodCard)}
            onValueChange={values => {
              const { value } = values;

              props.rowData.numGoodCard = parseInt(value);
            }}
          />
        );
      }
    },
    {
      title: "บัตรเสีย",
      field: "numBadCard",
      type: "numeric",
      headerStyle: {
        textAlign: "center"
      },
      cellStyle: {
        minWidth: "90px"
      },
      exportData: row => {
        return !row.numBadCard ? 0 : parseInt(row.numBadCard);
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={!rowData.numBadCard ? 0 : parseInt(rowData.numBadCard)}
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={
              !props.rowData.numBadCard ? 0 : parseInt(props.rowData.numBadCard)
            }
            onValueChange={values => {
              const { value } = values;

              props.rowData.numBadCard = parseInt(value);
            }}
          />
        );
      }
    },
    {
      //title: "บัตรไม่เลือกผู้สมัครผู้ใด",
      title: "บัตรไม่เลือกผู้ใด",
      field: "numNoSelectedCard",
      type: "numeric",
      headerStyle: {
        textAlign: "center"
      },
      cellStyle: {
        minWidth: "90px"
      },
      exportData: row => {
        return !row.numNoSelectedCard ? 0 : parseInt(row.numNoSelectedCard);
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={
              !rowData.numNoSelectedCard
                ? 0
                : parseInt(rowData.numNoSelectedCard)
            }
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={
              !props.rowData.numNoSelectedCard
                ? 0
                : parseInt(props.rowData.numNoSelectedCard)
            }
            onValueChange={values => {
              const { value } = values;

              props.rowData.numNoSelectedCard = parseInt(value);
              // const x =
              //   parseInt(props.rowData.numGoodCard) +
              //     parseInt(props.rowData.numBadCard) +
              //     parseInt(props.rowData.numNoSelectedCard) !==
              //     parseInt(props.rowData.numRealVote) &&
              //   !props.rowData.diffCardRemark;
              // if (x) {
              //   setError("dd");
              // }
            }}
          />
        );
      }
    },
    {
      //title: "บัตรเลือกตั้งที่เหลือ",
      title: "บัตรที่เหลือ",
      field: "numRemainCard",
      type: "numeric",
      headerStyle: {
        textAlign: "center"
      },
      cellStyle: {
        minWidth: "90px"
      },
      exportData: row => {
        return !row.numRemainCard ? 0 : parseInt(row.numRemainCard);
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={!rowData.numRemainCard ? 0 : parseInt(rowData.numRemainCard)}
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandSeparator={true}
            customInput={TextField}
            thousandsGroupStyle="thousand"
            value={
              !props.rowData.numRemainCard
                ? 0
                : parseInt(props.rowData.numRemainCard)
            }
            onValueChange={values => {
              const { value } = values;

              props.rowData.numRemainCard = parseInt(value);
              //this.setState({profit: formattedValue})
            }}
          />
        );
      }
    },
    {
      title: "หมายเหตุ",
      field: "diffCardRemark",
      headerStyle: {
        textAlign: "center"
      },
      cellStyle: {
        minWidth: "120px",
        textAlign: "center"
      }

      // validate: rowData => {
      //   console.log(rowData);
      //   return (parseInt(rowData.numGoodCard) +
      //     parseInt(rowData.numBadCard) +
      //     parseInt(rowData.numNoSelectedCard) !==
      //     parseInt(rowData.numRealVote) ||
      //     parseInt(rowData.numAppearCard) !== parseInt(rowData.numRealVote)) &&
      //     !rowData.diffCardRemark
      //     ? {
      //         isValid: false,
      //         helperText:
      //           "กรุณาระบุเหตุผล บัตรเลือกตั้งที่ใช้ลงคะแนนไม่เท่ากับบัตรเลือกตั้งที่รับมา/จำนวนบัตรที่ใช้ไม่ถูกต้อง"
      //       }
      //     : true;
      // }
      // validate: data => {
      //   const [isValid, helperText] = MustNotNull(data);
      //   return { isValid: !isValid, helperText: helperText };
      // }
    }
  ]);
  const [data, setData] = useState([]);

  const clearValue = () => {
    setProvinceSelected(null);
    setOptTypeSelected({
      1: { checked: false, value: 1 },
      2: { checked: false, value: 2 },
      3: { checked: false, value: 3 },
      4: { checked: false, value: 4 },
      5: { checked: false, value: 5 },
      6: { checked: false, value: 6 },
      7: { checked: false, value: 7 },
      8: { checked: false, value: 8 },
      9: { checked: false, value: 9 },
      10: { checked: false, value: 10 }
    });
  };
  const onClickSearch = () => {
    if (optVoteTypeSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภทการเลืือกตั้ง");
      return;
    }

    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }

    if (optSelected === null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
      return;
    }

    if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันที่เลือกตั้ง");
      return;
    }
    // let query = "voteTypeId=1";
    let query = "voteTypeId=" + optVoteTypeSelected.id;

    console.log("areaSelected ==>", areaSelected);

    if (aumphurAreaSelected !== null) {
      query += "&areaAmphurId=" + aumphurAreaSelected.id;
    }

    if (areaSelected !== null) {
      query += "&optAreaId=" + areaSelected.id;
    }

    if (positionTypeSelected !== null) {
      query += "&positionTypeId=" + positionTypeSelected.id;
    }

    //api/v1/opt/1259/vote/date/2009-09-06/usevote?voteTypeId=1
    getUseVote(optSelected.id, votedateSelected.id, query)
      .then(result => {
        setLoading(true);
        if (result.data !== null && result.data.data !== undefined) {
          setData(result.data.data);
          setLoading(false);
          //   props.setTabvalue(1);
        }
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ขออภัย เกิดข้อผิดพลาดขึ้น", err.response.data.desc);
      });
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CheckboxOpttype
                        optTypeSelected={optTypeSelected}
                        onOptTypeSelected={onOptTypeSelected}
                        required={true}
                      ></CheckboxOpttype>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxOptVotetype
                        value={optVoteTypeSelected}
                        onChange={onOptVoteTypeSelected}
                        required={true}
                      ></ListboxOptVotetype>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxProvince
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                        validators={[MustNotNull]}
                        required={true}
                      ></ListboxProvince>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxDistinct
                        value={amphurSelected}
                        provinceSelected={provinceSelected}
                        onChange={onAmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxOpts
                        value={optSelected}
                        getOptTypeSelectedLists={getOptTypeSelectedLists}
                        optTypeSelected={optTypeSelected}
                        provinceSelected={provinceSelected}
                        amphurSelected={amphurSelected}
                        tambolSelected={tambolSelected}
                        onChange={onOptSelected}
                        required={true}
                      ></ListboxOpts>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxVotedate
                        required={true}
                        value={votedateSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onVotedateSelected}
                      ></ListboxVotedate>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxAumphurArea
                        value={aumphurAreaSelected}
                        optSelected={optSelected}
                        votedateSelected={votedateSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onAumphurAreaSelected}
                      ></ListboxAumphurArea>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxPositionType
                        value={positionTypeSelected}
                        onChange={onPostionTypeSelected}
                      ></ListboxPositionType>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <ListboxArea
                        value={areaSelected}
                        optSelected={optSelected}
                        positionTypeSelected={positionTypeSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        votedateSelected={votedateSelected}
                        aumphurAreaSelected={aumphurAreaSelected}
                        onChange={onAreaSelected}
                      ></ListboxArea>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <br /> <br /> <br />
              <GridContainer justify="space-between" alignItems="center">
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button onClick={clearValue}>ยกเลิก</Button>
                  <Button color="info" onClick={onClickSearch}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {loading === true ? (
        <LoadingData />
      ) : (
        <MaterialTable
          title="บันทึกข้อมูลผู้มาใช้สิทธิเลือกตั้ง"
          columns={columns}
          data={data}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  console.log(newData);

                  console.log("newData.numCard  >>", newData.numCard);
                  console.log("newData.numRightVote  >>", newData.numRightVote);
                  console.log("newData.diffCardRemark  >>", newData.diffCardRemark);

                  let remark = "";
                  if (
                    newData.numCard < newData.numRightVote &&
                    (newData.diffCardRemark === null || newData.diffCardRemark === "")
                  ) {
                    remark +=
                      "บัตรที่ได้รับมา จำนวนต้องมากกว่าหรือเท่ากับ จำนวนผู้มีสิทธิ หากไม่ถูกต้องกรุณาระบุหมายเหตุ";
                    alert(remark);
                    return reject();
                  } else if (
                    newData.numBadCard +
                    newData.numGoodCard +
                    newData.numNoSelectedCard !==
                    newData.numRealVote &&
                    (newData.diffCardRemark === null || newData.diffCardRemark === "")
                  ) {
                    remark +=
                      "บัตรเลือกตั้งที่ใช้ เท่ากับ บัตรดี+บัตรเสีย+บัตรไม่เลือกผู้สมัครผู้ใด หากไม่ถูกต้องกรุณาระบุหมายเหตุ";

                    alert(remark);
                    return reject();
                  } else if (
                    newData.numRealVote > newData.numRightVote &&
                    (newData.diffCardRemark === null || newData.diffCardRemark === "")
                  ) {
                    remark +=
                      " บัตรเลือกตั้งที่ใช้ จำนวนต้องน้อยกว่าหรือเท่ากับ จำนวนผู้มีสิทธิเลือกตั้ง หากไม่ถูกต้องกรุณาระบุหมายเหตุ";
                    alert(remark);
                    return reject();
                  } else if (
                    newData.numRealVote + newData.numRemainCard !==
                    newData.numCard &&
                    (newData.diffCardRemark === null || newData.diffCardRemark === "")
                  ) {
                    remark +=
                      "บัตรที่ได้รับการจัดสรร เท่ากับ บัตรเลือกตั้งที่ใช้ + บัตรที่เหลือ  หากไม่ถูกต้องกรุณาระบุหมายเหตุ";
                    alert(remark);
                    return reject();
                  } else {
                    const dataUpdate = [...data];

                    const index = oldData.tableData.id;

                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);

                    if (oldData.optPositionTypeId === 1) {
                      editUseVotePresident(
                        optSelected.id,
                        votedateSelected.id,
                        oldData.optVoteId,
                        oldData.optVotePresInfoId,
                        newData.numRealVote,
                        newData.numRightVote,

                        newData.numCard,
                        newData.numAppearCard,
                        newData.numGoodCard,
                        newData.numBadCard,
                        newData.numNoSelectedCard,
                        newData.numRemainCard,
                        newData.diffCardRemark
                      )
                        .then(result => {
                          sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                          setData([...dataUpdate]);
                          resolve();
                        })
                        .catch(err => {
                          sweetAlerts.error(
                            "ไม่สามารถแก้ไขข้อมูลผู้มาใช้สิทธิได้",
                            err.toString()
                          );
                        });
                    }
                    if (oldData.optPositionTypeId === 2) {
                      //console.log("newData.numRemainCard>", newData.numRemainCard)
                      //console.log("newData.diffCardRemark>", newData.diffCardRemark)
                      editUseVoteMember(
                        optSelected.id,
                        votedateSelected.id,
                        oldData.optAreaVoteId,
                        oldData.optAreaId,
                        oldData.optVoteId,
                        oldData.optVotePresInfoId,
                        newData.numRealVote,
                        newData.numRightVote,

                        newData.numCard,
                        newData.numAppearCard,
                        newData.numGoodCard,
                        newData.numBadCard,
                        newData.numNoSelectedCard,
                        newData.numRemainCard,
                        newData.diffCardRemark
                      )
                        .then(result => {
                          sweetAlerts.success(
                            "แก้ไขข้อมูลผู้มาใช้สิทธิเรียบร้อยแล้ว"
                          );
                          setData([...dataUpdate]);
                          resolve();
                        })
                        .catch(err => {
                          sweetAlerts.error(
                            "ไม่สามารถแก้ไขข้อมูลผู้มาใช้สิทธิได้",
                            err.toString()
                          );
                        });
                    }
                  }
                }, 1000);
              })
          }}
          components={{
            Container: props => <Paper {...props} elevation={0} />
          }}
          enableInitColumns={false}
          options={{
            pageSize: 10,
            headerStyle: {
              backgroundColor: "#ddd",
              color: "#000",
              zIndex: 99,
              font: "Athiti",
              position: "sticky",
              top: 0,
              //paddingLeft: "10px",
              textAlign: "center"
            },
            cellStyle: {
              padding: "5"
            }
          }}
        />
      )}
    </>
  );
};

export default OptVotepublish;
