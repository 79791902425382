import React, { useContext, useEffect, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InputLabel from "@material-ui/core/InputLabel";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxVotecardPrinting from "components/Common/ListboxVotecardPrinting";
/* Material Components */
import LoadingData from "components/Common/LoadingData";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TableComponent from "components/Table/MaterialTable";
import { VoteDateContext } from "contexts/VoteDate";
import { addVoteCardDetail } from "contexts/API/VoteCard/Setting";
/* API */
import { getSearchLists } from "contexts/API/VoteCard/AllocateToBranch";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { useSweetAlert } from "components/Alert/SweetAlert";

//import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles(styles);

const ManangeAllocate = ({ pageProps, setPageProps }) => {
  const [loading, setLoading] = useState(false);
  const [clickSearch, setClickSearch] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  const classes = useStyles();

  const {
    provinceSelected,

    votecardPrintingSelected,
    onVotecardPrintingSelected,

    onProvinceSelected,

    setTambolSelected,
    setAmphurSelected,
    setProvinceSelected,
    setVotecardPrintingSelected,
    setOptSelected
    //uttils
  } = useContext(VoteDateContext);
  const optionsTable = { exportButton: false, paging: true };

  const [voteCardCentralReferenceNo, setVoteCardCentralReferenceNo] = useState(
    null
  );
  const [voteCardReceiveType, setVoteCardReceiveType] = useState("");

  const ActionWayHandler = rowData => {
    rowData.receiveTypeCode === "POSTOFFICE"
      ? setPageProps({ active: "PostWay", props: rowData })
      : setPageProps({ active: "OnhandWay", props: rowData });
  };

  const [data, setData] = useState([]);
  function getPerson(rowData) {
    return rowData.map((post, index) => (
      <div key={index}>
        <p>
          {post.firstName} {post.lastName} {post.idCard}
        </p>
      </div>
    ));
  }
  function getDetailVoteCard(rowData) {
    let _t = "";
    _t = (
      <ul>
        {rowData.map((post, index) => (
          <li key={index}>
            <p>
              {post.cardNumber} สี{post.colorName} เล่มที่{" "}
              {post.allocateStartNo} ถึง {post.allocateEndNo} จำนวน{" "}
              {post.volumnAllocate} เล่ม
            </p>
          </li>
        ))}
      </ul>
    );
    return _t;
  }

  const [columns] = useState([
    {
      title: "โรงพิมพ์",
      field: "printingHouseName",
      editable: "never",
      cellStyle: {
        minWidth: "120px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "120px",
        textAlign: "center"
      }
    },
    // {
    //   title: "สถานะ",
    //   field: "Status",
    //   editable: "never"
    // },
    {
      title: "เลขที่หนังสือคำสั่งแนบท้าย(สนง.กกต)",
      field: "centralDocumentNo",
      editable: "never",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "200px",
        textAlign: "center"
      }
    },
    {
      title: "จังหวัด",
      field: "provinceName",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      }
    },
    {
      title: "อำเภอ",
      field: "amphurName",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      }
    },
    /*   {
        title: "ประเภท",
        field: "optTypeName",
        cellStyle: {
          minWidth: "150px",
          textAlign: "center"
        },
        headerStyle: {
          minWidth: "150px",
          textAlign: "center"
        }
      }, */
    {
      title: "ชื่อ อปท.",
      field: "optName",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return rowData.optTypeName + rowData.optName;
      }
    },
    {
      title: "ตำแหน่ง",
      field: "positionTypeShortName",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      }
    },
    {
      title: "วันที่เลือกตั้ง",
      field: "voteDate",
      editable: "never",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.voteDate);
      }
    },
    {
      title: "รายละเอียดการจัดสรรบัตรเลือกตั้ง",
      field: "",
      cellStyle: {
        minWidth: "400px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "400px",
        textAlign: "center"
      },
      render: rowData => {
        return getDetailVoteCard(rowData.voteCardAllocate);
      }
    },
    {
      title: "วิธีการรับบัตรเลือกตั้ง",
      field: "receiveTypeName",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "200px",
        textAlign: "center"
      }
    },
    {
      title: "วันที่ส่ง/รับบัตร",
      field: "receiveDate",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.receiveDate);
      }
    },
    {
      title: "รายละเอียดผู้มารับบัตร",
      field: "voteCardPersonReceive",

      headerStyle: {
        minWidth: "300px",
        textAlign: "center"
      },
      render: rowData => {
        return getPerson(rowData.voteCardPersonReceive);
      }
    },
    {
      title: "",
      field: "",
      render: rowData => (
        <Button
          size="sm"
          color="warning"
          disabled={
            Object.keys(rowData.voteCardPersonReceive).length === 0
              ? false
              : true
          }
          onClick={() => {
            ActionWayHandler(rowData);
          }}
        >
          บันทึกการส่ง/รับบัตรเลือกตั้ง
        </Button>
      )
    }
  ]);

  useEffect(() => {
    if (pageProps.action === "Cancel") {
      onClickSearch();
    }
  }, [pageProps.action]);

  const onClickCancel = e => {
    setVotecardPrintingSelected(null);
    setTambolSelected(null);
    setAmphurSelected(null);
    setProvinceSelected(null);
    setVotecardPrintingSelected(null);
    setOptSelected(null);
    setData([]);
  };
  const onClickSearch = e => {
    setClickSearch(false);

    if (provinceSelected === null || provinceSelected.id < 1) {
      //
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }

    const optQueryUrl = () => {
      /*  FN-0601
      1) ประเภท อปท. (OptTypeID)
      2) โรงพิมพ์ (PrintHouseID)
      3) จังหวัด	(ProvinceID)
      4) อำเภอ	(AmphurID)
      5) ตำบล	(TambolID)
      6) อปท. (OptID)
      7) วันที่เลือกตั้ง	(VoteDate)
      7) ประเภทการรับบัตร	(ReceiveType)
      */
      let endPoint = "?";

      if (votecardPrintingSelected !== null) {
        //
        endPoint += `printHouseID=${votecardPrintingSelected.id}`;
      }

      if (provinceSelected !== null) {
        //
        endPoint += `&provinceID=${provinceSelected.id}`;
      }

      if (voteCardCentralReferenceNo !== null) {
        //
        endPoint += `&centralReferenceNo=${voteCardCentralReferenceNo}`;
      }

      if (voteCardReceiveType !== null) {
        //
        if (voteCardReceiveType !== "ALL") {
          endPoint += `&receiveType=${voteCardReceiveType}`;
        }
      }

      return endPoint;
    };
    setLoading(true);
    getSearchLists(optQueryUrl(), optExportUrl())
      .then(result => {
        setData(result.data.data);

        setLoading(false);
        setClickSearch(true);
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้.", err.toString());
      });
  };
  const optExportUrl = () => { };

  return (
    <>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4>ค้นหารายการจ่ายบัตรเลือกตั้งให้ อปท.</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {/*
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CheckboxOpttype
                      optTypeSelected={optTypeSelected}
                      onOptTypeSelected={onOptTypeSelected}
                    ></CheckboxOpttype>
                  </GridItem>
                </GridContainer>
                */}
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <ListboxVotecardPrinting
                    votecardPrintingSelected={votecardPrintingSelected}
                    onChange={onVotecardPrintingSelected}
                  ></ListboxVotecardPrinting>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <ListboxProvince
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                    required
                  ></ListboxProvince>
                </GridItem>
                {/*
                  <GridItem xs={12} sm={4} md={4}>
                    <ListboxDistinct
                      provinceSelected={provinceSelected}
                      value={amphurSelected}
                      onChange={onAmphurSelected}
                    ></ListboxDistinct>
                  </GridItem>
                  */}
              </GridContainer>
              <GridContainer
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    default
                    required
                    labelText="เลขที่หนังสือคำสั่งแนบท้าย (สนง.กกต.)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpertext="กรุณาระบุ*"
                    inputProps={{
                      value: voteCardCentralReferenceNo,
                      onChange: e => {
                        setVoteCardCentralReferenceNo(e.target.value);
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>วิธีการรับบัตรเลือกตั้ง</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={voteCardReceiveType}
                      onChange={e => {
                        setVoteCardReceiveType(e.target.value);
                      }}
                    >
                      {/* <MenuItem value={"ALL"} selected>
                        วิธีการรับบัตรเลือกตั้ง
                      </MenuItem> */}
                      <MenuItem value={"MYSELF"}>รับบัตรด้วยตนเอง</MenuItem>
                      <MenuItem value={"POSTOFFICE"}>รับทางไปรษณีย์</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button onClick={onClickCancel}>ยกเลิก</Button>
                  <Button color="info" type="button" onClick={onClickSearch}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title="รายละเอียดการจ่ายบัตรเลือกตั้งให้ อปท."
          columns={columns}
          data={data}
          localization={{}}
          options={optionsTable}
          defaultExpanded={true}
        />
      )}
    </>
  );
};
export default ManangeAllocate;
