import MaterialTable from "components/Table/MaterialTable";
import React, { useState, useEffect } from "react";
import {
  getsuspendLawType,
  editsuspendLawType,
  addsuspendLawType,
  deletesuspendLawType
} from "contexts/API/SUSPEND/common";
import { useSweetAlert } from "components/Alert/SweetAlert";

const LawType = props => {
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const optionsTable = { pageSize: 10 };
  const [columns] = useState([
    {
      title: "suspendLawTypeId",
      field: "suspendLawTypeId",
      hidden: true
    },
    {
      title: "ประเภท พ.ร.บ.",
      field: "name",
      cellStyle: {
        minWidth: "500px"
      }
    },
    {
      title: "ประเภท พ.ร.บ. (ชื่อย่อ)",
      field: "shortName",
      cellStyle: {
        minWidth: "300px"
      }
    }
  ]);
  useEffect(() => {
    getsuspendLawType()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaterialTable
      title={"จัดการกฎหมายที่เกี่ยวข้องกับการระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
              addsuspendLawType(newData.name, newData.shortName)
                .then(result => {
                  newData.SuspendLawTypeId=result.data.data.suspendLawTypeId
                  sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
                  setData([...data, newData]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
                });

              resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              editsuspendLawType(
                oldData.suspendLawTypeId,
                newData.name,
                newData.shortName
              )
                .then(result => {
                  sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                  setData([...dataUpdate]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });

              resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              deletesuspendLawType(oldData.suspendLawTypeId)
                .then(result => {
                  sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                  setData([...dataDelete]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
                });

              resolve();
          })
      }}
    />
  );
};
export default LawType;
