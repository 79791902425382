export const VoteDateEnum = Object.freeze({
    PRES_AND_MEM: {
        name: "(เลือกตั้ง ทั้ง ผ.ถ./ส.ถ.)",
        value: 3
    },
    ONLY_MEM: {
        name: "(เลือกตั้ง ส.ถ. อย่างเดียว)",
        value: 2
    },
    ONLY_PRES: {
        name: "(เลือกตั้ง ผ.ถ. อย่างเดียว)",
        value: 1
    },
    UNKNOWN: {
        name: "ไม่ทราบประเภทการเลือกตั้ง",
        value: 0
    },
});

export const Password = "รหัสผ่าน"
export const ConfirmPassword = "เข้าสู่ระบบ"
export const ChangeUser = "เปลี่ยนผู้ใช้"
export const ShowPassword = "แสดงรหัสผ่าน"
export const WrongUserOrPassword = " ชื่อผู้ใช้งาน หรือ รหัสผ่านผิด"