import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getQualification(provinceId, cmdApplyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/qualification/provinceId/${provinceId}/cmdApplyDateId/${cmdApplyDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delQualification(cmdApplyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/qualification/${cmdApplyDateId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addQualification(
  provinceId,
  cmdApplyDateId,
  orgName,
  description,
  fileUUID
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/qualification/provinceId/${provinceId}/cmdApplyDateId/${cmdApplyDateId}`;
  const body = {
    orgName: orgName,
    description: description,
    fileUUID: fileUUID
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
