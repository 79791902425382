import React, { useEffect, useState } from "react";
/* API */
import {
  getSearchComponentLists,
  getSearchNewLists
} from "contexts/API/VoteCard/Allocate";

import Button from "components/CustomButtons/Button";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import { addVoteCardAllocateManual } from "contexts/API/VoteCard/AllocateManual";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const ModalEditDetail = props => {
  const useStyles = makeStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto"
      //  width: "fit-content"
    },

    titlemodal: {
      background: "info",
      fontFamily: `"Athiti", sans-serif`
    },
    error: {
      color: "red"
    },
    marginNormal1: {
      marginTop: "-15px !important"
    }
  });

  const classes = useStyles();
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  const { showModalEditDetail, closeModalEditDetail } = props;
  const [, setNewReason] = useState(0);
  const [oldAllocateValue, setOldAllocateValue] = useState(null);

  const [child, setChild] = useState("");
  const [dataOptions, setDataOptions] = useState([]);
  const [dataOptionPops, setDataOptionPops] = useState([]);
  const initialData = {
    oldVolumnChange: "",
    oldStartVolumn: "",
    oldEndVolumn: "",
    changeType: 0,
    newStartVolumn: "",
    newEndVolumn: "",
    newVolumnChange: ""
  };
  const initialDataAllocate = {
    newStartVolumn: "",
    newEndVolumn: "",
    newVolumnChange: ""
  };
  const [data, setData] = useState(initialData);
  const [dataAllocate, setDataAllocate] = useState(initialDataAllocate);

  const [_data, _setData] = useState();
  const { sweetAlerts } = useSweetAlert();

  const [isCanSumbit, setIsCanSumbit] = useState(false);

  useEffect(() => {
    if (props.showChild !== undefined) {
      setChild(props.showChild);
      setData(initialData);

      setNewReason(0);
      setDataAllocate({
        newStartVolumn: "",
        newEndVolumn: "",
        newVolumnChange: ""
      });
      let optQueryUrl =
        "?VoteCardPurchaseDetailID=" +
        props.showChild.VoteCardPurchaseDetailID +
        "&VoteCardPublishDetailID=" +
        props.showChild.VoteCardPublishDetailID;

      getSearchComponentLists(optQueryUrl)
        .then(result => {
          let _pop = [];
          let optionTemplate = result.data.data.map(v => (
            <MenuItem key={v.voteCardAllocateId} value={v.voteCardAllocateId}>
              {v.label}
            </MenuItem>
          ));

          for (let i = 0; i < result.data.data.length; i++) {
            _pop[result.data.data[i].voteCardAllocateId] = {
              id: result.data.data[i].voteCardAllocateId,
              start: result.data.data[i].startVolumnNo,
              end: result.data.data[i].endVolumnNo,
              allocate: result.data.data[i].volumnAllocate,
              numbertext: result.data.data[i].numbertext,
              colorName: result.data.data[i].colorName
            };
          }

          setDataOptionPops(_pop);

          setDataOptions(optionTemplate);
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
        });
    }
  }, [props.showChild]);

  useEffect(() => {
    if (_data) {
      setDataAllocate({
        ...dataAllocate,
        newStartVolumn: _data.newStartVolumn,
        newVolumnChange: _data.newVolumnAllocate
      });
    }
  }, [_data]);

  useEffect(() => {
    if (data.changeType === 1) {
      //ขอลด
      setData({
        ...data,
        oldVolumnChange: "",
        oldStartVolumn: "",
        oldEndVolumn: "",
        newStartVolumn: 0,
        newVolumnChange: 0,
        newEndVolumn: 0
      });
    }
    if (data.changeType !== 1) {
      setData({
        ...data,
        oldVolumnChange: "",
        oldStartVolumn: "",
        oldEndVolumn: "",

        newStartVolumn: "",
        newEndVolumn: "",
        newVolumnChange: ""
      });
      setDataAllocate({
        newStartVolumn: "",
        newVolumnChange: "",
        newEndVolumn: ""
      });
      setIsCanSumbit(false);
      // console.log("data", data);
      // console.log("dataAllocate", dataAllocate);
    }
  }, [data.changeType]);

  useEffect(() => {
    if (data.oldStartVolumn !== "" || data.oldVolumnChange !== "") {
      setData({
        ...data,
        oldVolumnChange: "",
        oldStartVolumn: "",
        oldEndVolumn: "",
        changeType: 0,
        newStartVolumn: "",
        newEndVolumn: "",
        newVolumnChange: ""
      });
      setDataAllocate({
        newStartVolumn: "",
        newEndVolumn: "",
        newVolumnChange: ""
      });
      setIsCanSumbit(false);
    }
  }, [data.voteCardAllocateID]);
  useEffect(() => {
    if (data.oldVolumnChange !== "" && data.changeType === 1) {
      setIsCanSumbit(true);
    }
    if (data.oldVolumnChange === "") {
      setIsCanSumbit(false);
    }
  }, [data.oldVolumnChange]);
  async function getData(oldVolumnChange, changeType) {
    let _tmpCard = data.oldStartVolumn + data.oldVolumnChange + 1;

    if (changeType !== 3) {
      if (
        data.rangeAllocateStart <= data.oldStartVolumn &&
        data.oldStartVolumn <= data.rangeAllocateEnd &&
        _tmpCard < data.rangeAllocateEnd
      ) {
        try {
          if (oldVolumnChange) {
            let optQueryUrl =
              "/publishDetailID/" +
              props.showChild.VoteCardPublishDetailID +
              "/volumnAllocate/" +
              oldVolumnChange;
            const voteCard = await getSearchNewLists(optQueryUrl);

            _setData(voteCard.data.data);
            setData({
              ...data,
              newStartVolumn: voteCard.data.data.newStartVolumn,
              newEndVolumn: voteCard.data.data.newEndVolumn,
              newVolumnChange: voteCard.data.data.newVolumnAllocate
            });
            setIsCanSumbit(true);
          }
        } catch (error) {
          sweetAlerts.error("ไม่สามารถดึงข้อมูล", error.toString());
        }
      } else {
        sweetAlerts.warning(
          "กรุณาระบุเล่มเดิมที่จัดสรรให้ถูกต้อง หรือ จำนวนเล่มให้ถูกต้อง"
        );
        setData({
          ...data,
          newStartVolumn: 0,
          newVolumnChange: 0
        });
      }
    } else {
      try {
        if (oldVolumnChange) {
          let optQueryUrl =
            "/publishDetailID/" +
            props.showChild.VoteCardPublishDetailID +
            "/volumnAllocate/" +
            oldVolumnChange;
          const voteCard = await getSearchNewLists(optQueryUrl);

          _setData(voteCard.data.data);
          setData({
            ...data,
            newStartVolumn: voteCard.data.data.newStartVolumn,
            newEndVolumn: voteCard.data.data.newEndVolumn,
            newVolumnChange: voteCard.data.data.newVolumnAllocate
          });
          setIsCanSumbit(true);
        }
      } catch (error) {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", error.toString());
      }
    }
  }

  const handleChange = event => {
    setData({
      ...data,
      voteCardAllocateID: event.target.value,
      rangeAllocateStart: dataOptionPops[event.target.value].start,
      rangeAllocateEnd: dataOptionPops[event.target.value].end,
      rangeAllocateAmount: dataOptionPops[event.target.value].allocate,
      numbertext: dataOptionPops[event.target.value].numbertext,
      colorName: dataOptionPops[event.target.value].colorName
    });

    setOldAllocateValue(event.target.value);
    setDataAllocate({
      newStartVolumn: "",
      newVolumnChange: "",
      newEndVolumn: ""
    });
  };

  const checkUp = data => {
    let _tmpCard = (data.oldStartVolumn + data.oldVolumnChange) - 1;
    /*    console.log("data.rangeAllocateStart >>", data.rangeAllocateStart)
       console.log("data.oldStartVolumn >>", data.oldStartVolumn)
       console.log("data.oldVolumnChange >>", data.oldVolumnChange)
       console.log("data.rangeAllocateEnd >>", data.rangeAllocateEnd)
       console.log("_tmpCard >>", _tmpCard) */
    if (
      data.rangeAllocateStart <= data.oldStartVolumn &&
      data.oldStartVolumn <= data.rangeAllocateEnd &&
      _tmpCard <= data.rangeAllocateEnd
    ) {
      return true;
    } else {
      return false;
    }
  };
  const updateAllocateManualHandler = () => {
    // console.log(data);

    if (data.changeType === 1) {
      let t = checkUp(data);
      //  console.log("t,,", t);
      if (t) {
        addVoteCardAllocateManual(data)
          .then(r => {
            let retVoteCardAllocateManual = r.data;
            if (retVoteCardAllocateManual.success === true) {
              sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
              closeModalEditDetail();
              props.onaddSuccess(props.showChild.VoteCardPurchaselID);
            } else {
              sweetAlerts.error("ไม่สามารถบันทึกข้อมูล : ", "");
            }
          })
          .catch(error => {
            if (error.response) {
              sweetAlerts.error(
                "ไม่สามารถบันทึกข้อมูล : ",
                error.response.data.desc
              );
            }
          });
      } else {
        sweetAlerts.warning(
          "กรุณาระบุเล่มเดิมที่จัดสรรให้ถูกต้อง หรือ จำนวนเล่มให้ถูกต้อง"
        );
        return false;
      }
    }
    if (data.changeType === 2 || data.changeType === 3) {
      addVoteCardAllocateManual(data)
        .then(r => {
          let retVoteCardAllocateManual = r.data;
          if (retVoteCardAllocateManual.success === true) {
            sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
            closeModalEditDetail();
            props.onaddSuccess(props.showChild.VoteCardPurchaselID);
          } else {
            sweetAlerts.error("ไม่สามารถบันทึกข้อมูล : ", "");
          }
        })
        .catch(error => {
          if (error.response) {
            sweetAlerts.error(
              "ไม่สามารถบันทึกข้อมูล : ",
              error.response.data.desc
            );
          }
        });
    }
  };
  return (
    <div className={showModalEditDetail ? "modal" : "hide"}>
      <Dialog
        open={showModalEditDetail}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModalEditDetail}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>
              แก้ไขรายการบัตรเลือกตั้งที่ได้ทำการจัดสรรบัตรเลือกตั้งแล้ว
            </h4>
          </CardHeader>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="ชื่อ อปท"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: child.OptTypeName + child.OptName || "",
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="จังหวัดที่สั่งซื้อ"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: (child.OptTypeId != 6 ? "จ. " + child.ProvinceName + " " + (child.AmphurName === null ? "" : "อ. " + child.AmphurName || "")
                    : (child.ProvinceName + (child.AmphurName === null ? "" : "ข. " + child.AmphurName))) || "",
                  disabled: true
                }}
              />
            </GridItem>
          </GridContainer>{" "}
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="ตำแหน่งที่จัดเลือกตั้ง"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: child.PositionTypeName || "",
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="จำนวนผู้สมัคร"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: new Intl.NumberFormat().format(child.VoteRegister) || "",
                  disabled: true
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="จำนวนผู้มีสิทธิเลือกตั้ง"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: new Intl.NumberFormat().format(child.VotePerson) || "",
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="จำนวนบัตรเลือกตั้ง"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: new Intl.NumberFormat().format(child.VoteCard) || "",
                  disabled: true
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="โรงพิมพ์"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: child.printingHouseName || "",
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <FormControl fullWidth style={{ paddingTop: "10px" }}>
                <InputLabel>เล่มที่จัดสรรแล้ว</InputLabel>
                <Select
                  onChange={e => handleChange(e)}
                  value={oldAllocateValue}
                >
                  {dataOptions}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <FormControl fullWidth style={{ paddingTop: "5px" }}>
                <InputLabel>สาเหตุที่จัดสรรใหม่</InputLabel>
                <Select
                  value={data.changeType}
                  onChange={e => {
                    setData({ ...data, changeType: e.target.value });
                    //  setNewReason(e.target.value);
                  }}
                >
                  <MenuItem aria-label="None" value={0}>
                    เลือกสาเหตุที่จัดสรรใหม่
                  </MenuItem>
                  <MenuItem value={1}>ขอลดจำนวนลง</MenuItem>
                  <MenuItem value={2}>เปลี่ยนเล่มเนื่องจากชำรุด</MenuItem>
                  <MenuItem value={3}>ขอเพิ่มเนื่องจากเปลี่ยนแปลง</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          {data.changeType === 1 || data.changeType === 2 ? (
            <>
              <div
                style={{
                  paddingTop: "30px",
                  paddingBottom: "10px",
                  fontSize: "15px",
                  fontWeight: "bolder"
                }}
              >
                เล่มเดิมที่จัดสรร
              </div>

              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    default
                    required
                    label="ตั้งแต่เล่มที่"
                    fullWidth
                    type="number"
                    value={data.oldStartVolumn}
                    onChange={e => {
                      if (data.voteCardAllocateID) {
                        setData({
                          ...data,
                          oldStartVolumn: parseInt(e.target.value)
                        });
                      } else {
                        sweetAlerts.warning("กรุณาเลือกเล่มที่จัดสรรแล้ว");
                      }
                    }}
                    helpertext="กรุณาระบุ*"
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    default
                    required
                    label="จำนวนเล่ม"
                    fullWidth
                    type="number"
                    value={data.oldVolumnChange}
                    onChange={e => {
                      if (data.voteCardAllocateID) {
                        setData({
                          ...data,
                          oldVolumnChange: parseInt(e.target.value),
                          oldEndVolumn:
                            parseInt(data.oldStartVolumn) +
                            parseInt(e.target.value) -
                            1
                        });
                      } else {
                        sweetAlerts.warning("กรุณาเลือกเล่มที่จัดสรรแล้ว");
                      }
                    }}
                    helpertext="กรุณาระบุ*"
                    inputProps={{}}
                  />
                </GridItem>
              </GridContainer>
            </>
          ) : data.changeType === 3 ? (
            <GridContainer style={{ paddingTop: "15px" }}>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  id="oldVolumnChange"
                  default
                  required
                  labelText="จำนวนเล่มที่ต้องการเพิ่ม"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: data.oldVolumnChange || "",
                    onChange: e => {
                      if (data.voteCardAllocateID) {
                        setData({
                          ...data,
                          oldStartVolumn: 0,
                          oldVolumnChange: parseInt(e.target.value),
                          oldEndVolumn: 0
                        });
                      } else {
                        sweetAlerts.warning("กรุณาเลือกเล่มที่จัดสรรแล้ว");
                      }
                    }
                  }}
                />
                {/* <TextField
                  default
                  required
                  label="จำนวนเล่มที่ต้องการเพิ่ม"
                  fullWidth
                  type="number"
                  value={data.oldVolumnChange}
                  onChange={e => {
                    if (data.voteCardAllocateID) {
                      setData({
                        ...data,
                        oldStartVolumn: 0,
                        oldVolumnChange: parseInt(e.target.value),
                        oldEndVolumn: 0
                      });
                    } else {
                      sweetAlerts.warning("กรุณาเลือกเล่มที่จัดสรรแล้ว");
                    }
                  }}
                  helpertext="กรุณาระบุ*"
                  inputProps={{}}
                /> */}
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          <br></br>
          <br></br>
          {data.changeType === 2 || data.changeType === 3 ? (
            <>
              <GridContainer style={{ paddingLeft: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    direction: "row",
                    flexWrap: "wrap"
                  }}
                >
                  <Button
                    color="info"
                    disabled={
                      data.oldVolumnChange && data.changeType !== 0
                        ? false
                        : true
                    }
                    onClick={() =>
                      getData(data.oldVolumnChange, data.changeType)
                    }
                  >
                    เลือกเล่มที่ต้องการจัดสรรใหม่
                  </Button>
                </div>
              </GridContainer>

              <GridContainer>
                <div
                  style={{
                    display: "flex",
                    direction: "row",
                    flexWrap: "wrap"
                  }}
                >
                  <div
                    style={{
                      padding: "15px",
                      fontSize: "15px",
                      fontWeight: "bolder"
                    }}
                  >
                    เล่มใหม่ที่จัดสรร{" "}
                  </div>
                </div>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  {/* {_data ? "_data" + JSON.stringify(_data) : ""} */}

                  <CustomInput
                    id="newStartVolumn"
                    default
                    required
                    labelText="ตั้งแต่เล่มที่"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: dataAllocate.newStartVolumn || ""
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    id="newVolumnChange"
                    required
                    // value={dataAllocate.newVolumnAllocate}
                    labelText="จำนวนเล่ม"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: dataAllocate.newVolumnChange || ""
                    }}
                  />
                </GridItem>
              </GridContainer>
            </>
          ) : (
            ""
          )}

          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={closeModalEditDetail}>ปิดหน้าต่าง</Button>
            <Button
              color="success"
              disabled={isCanSumbit === true ? false : true}
              onClick={updateAllocateManualHandler}
            >
              บันทึก
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalEditDetail;
