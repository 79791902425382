import React, { useContext, useState } from "react";
import {
  delUsers,
  getUsers,
  resetPass
} from "contexts/API/UserManagement/roles";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxProvince from "components/Common/ListboxProvince.js";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import ModaladdUser from "./ModaladdUser";
import ModaleditUser from "./ModaleditUser";
import { Paper } from "@material-ui/core";
import { VoteDateContext } from "contexts/VoteDate";
import { useSweetAlert } from "components/Alert/SweetAlert";

const UsersList = props => {
  const {
    provinceSelected,

    onProvinceSelected
  } = useContext(VoteDateContext);
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState("");
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);

  const [showModal, setshowModal] = useState(false);
  const openModal = () => setshowModal(true);
  const closeModal = () => setshowModal(false);

  const [showModal2, setshowModal2] = useState(false);
  const openModal2 = () => setshowModal2(true);
  const closeModal2 = () => setshowModal2(false);

  const searchHandler = () => {
    let query = `?`;
    if (provinceSelected) {
      query += `provinceId=${provinceSelected.id}`;
    }
    if (user) {
      query += `&username=${user}`;
    }
    setLoading(true);
    getUsers(query)
      .then(result => {
        setData(result.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const columns = [
    {
      title: "userId",
      field: "userId",
      hidden: true
    },
    {
      title: "ชื่อผู้ใช้งาน",
      field: "userName"
    },
    {
      title: "ชื่อ-สกุล",
      field: "fullname"
    },
    {
      title: "สังกัด",
      field: "center"
    },
    {
      title: "สถานะการเปิดใช้งาน",
      field: "approved"
    }
  ];
  const fileSuccess = success => {
    console.log(success);
    searchHandler();
  };
  const optionsTable = { pageSize: 5 };
  return (
    <>
      <ModaleditUser
        closeModal={closeModal}
        showModal={showModal}
        detail={detail}
        onaddSuccess={fileSuccess}
      //  onaddfileSuccess={fileSuccess}
      ></ModaleditUser>
      <ModaladdUser
        closeModal2={closeModal2}
        showModal2={showModal2}
        detail={detail}
      // onaddSuccess={fileSuccess}
      //  onaddfileSuccess={fileSuccess}
      ></ModaladdUser>
      <Card>
        <CardHeader color="warning" text>
          <CardText color="warning">
            <h4>บริหารจัดการผู้ใช้งานระบบ</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="ชื่อผู้ใช้งาน"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: user || "",

                  autoComplete: "new-password",
                  onChange: e => {
                    // if (e.target.value) {
                    setUser(e.target.value);
                    //  setData({ ...data, addr2: e.target.value });
                    //  handleChange(e, "addr1");
                    //  }
                  }
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxProvince
                value={provinceSelected}
                onChange={onProvinceSelected}
              ></ListboxProvince>
            </GridItem>
          </GridContainer>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem xs={6} sm={6} md={6}>
              <Button color="success" onClick={openModal2}>
                เพิ่มผู้ใช้งาน
              </Button>
            </GridItem>
            <GridItem>
              <Button color="info" onClick={searchHandler}>
                ค้นหา
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <MaterialTable
          title={"ระบบบริหารจัดการผู้ใช้งานระบบ"}
          options={optionsTable}
          columns={columns}
          data={data}
          actions={[
            {
              icon: "edit",
              tooltip: "Save User",
              onClick: (event, rowData) => {
                setDetail(rowData.userId);
                openModal();
              }
            },
            {
              icon: "info",
              tooltip: "Save User",
              onClick: (event, rowData) => {
                sweetAlerts.warning(
                  `ยืนการเปลี่ยนรหัสผ่าน ${rowData.fullname} `,
                  `หากเปลี่ยนแล้วจะไม่สามารถกู้คืนได้ทุกกรณี`,
                  () => {
                    resetPass(rowData.userId)
                      .then(result => {
                        const title = "รหัสผ่านใหม่ : ";
                        sweetAlerts.success(
                          `แจ้งผลการเปลี่นรหัสผ่าน Username:${rowData.userName}`,
                          <p>
                            <span
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              {title}
                            </span>
                            <span
                              style={{
                                color: "red",
                                fontSize: "20px",
                                fontWeight: "bold"
                              }}
                            >
                              {result.data.data.password}
                            </span>
                          </p>
                        );
                        //setData([...dataDelete]);
                      })
                      .catch(err => {
                        sweetAlerts.error(
                          "ไม่สามารถลบข้อมูลได้",
                          err.toString()
                        );
                      });
                  },
                  true
                );
              }
            },
            {
              icon: "del",
              tooltip: "Save User",
              onClick: (event, rowData) => {
                const dataDelete = [...data];
                const index = rowData.tableData.id;
                dataDelete.splice(index, 1);

                sweetAlerts.error(
                  `ยืนการลบข้อมูล ${rowData.fullname} `,
                  `หากลบแล้วจะไม่สามารถกู้คืนได้ทุกกรณี`,
                  () => {
                    delUsers(rowData.userId)
                      .then(result => {
                        sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                        setData([...dataDelete]);
                      })
                      .catch(err => {
                        sweetAlerts.error(
                          "ไม่สามารถลบข้อมูลได้",
                          err.toString()
                        );
                      });
                  },
                  true
                );
              }
            }
          ]}
          components={{
            Container: props => <Paper {...props} elevation={0} />,
            Action: props => {
              if (props.action.icon === "edit") {
                return (
                  <Button
                    //   disabled
                    onClick={event => props.action.onClick(event, props.data)}
                    color="warning"
                    variant="contained"
                    style={{ textTransform: "none" }}
                    size="sm"
                  >
                    แก้ไข
                  </Button>
                );
              }
              if (props.action.icon === "info") {
                return (
                  <Button
                    onClick={event => props.action.onClick(event, props.data)}
                    color="info"
                    variant="contained"
                    style={{ textTransform: "none" }}
                    size="sm"
                  >
                    รับรหัสผ่านใหม่
                  </Button>
                );
              }
              if (props.action.icon === "del") {
                return (
                  <Button
                    onClick={event => props.action.onClick(event, props.data)}
                    color="danger"
                    variant="contained"
                    style={{ textTransform: "none" }}
                    size="sm"
                  >
                    ลบ
                  </Button>
                );
              }
            }
          }}
        />
      )}
    </>
  );
};
export default UsersList;
