import React, { useContext, useEffect, useState } from "react";
import { addOptArea, editOptArea } from "contexts/API/OPT/OptArea";
import { onlyNumber, positiveNumber } from "utils/Validators/Numbers";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInputWithValidator from "components/CustomInput/CustomInputWithValidator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxOpttype from "components/Common/ListboxOpttype.js";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import Slide from "@material-ui/core/Slide";
// Context APIs
import { VoteDateContext } from "contexts/VoteDate";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "primary",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "danger"
  },
  textField: {
    paddingTop: "12px",
    paddingBottom: "12px",
    "& label": {
      top: "10px"
    }
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModaladdArea = props => {
  const { sweetAlerts } = useSweetAlert();
  const [numStation, setNumStation] = useState(null);
  const [numMember, setNumMember] = useState(null);
  const [areaNo, setAreaNo] = useState(null);
  const { show, closeModal, onaddSuccess, children, action } = props;
  const {
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,
    onTamboldSelected,
    tambolSelected,

    onOptSelected,
    optSelected,
    listboxOptSelected,
    onListboxOptSelected
  } = useContext(VoteDateContext);
  //const [amphurAreaSelected, setAmphurAreaSelected] = useState(amphurSelected);
  const [amphurAreaSelected, setAmphurAreaSelected] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    if (show === true && children !== null) {
      setNumStation(children.num_station);
      setNumMember(children.num_member);
      setAreaNo(children.area_no);
      // setAmphurSelected(children.ms_amphur_id);
      // setTambolSelected(children.tambol_id);
      //console.log("amphurSelected >>>>" + amphurSelected);
    }
    return () => {
      setNumStation(null);
      setNumMember(null);
      setAreaNo(null);
    };
  }, [show, children, action]);

  //const areaNoRef = createRef();
  // const numStationRef = createRef();
  // const numMemberRef = createRef();

  // const _numStationRef = useRef();
  // useEffect(() => {
  //   setValue(1);
  //   console.log("log1", valueRef.current);
  // });
  const onSubmit = e => {
    //const areaNo = areaNoRef.current.value;
    // const numMember = numMemberRef.current.value;
    // const numStation = numStationRef.current.value;
    const amphurAreaId = amphurAreaSelected && listboxOptSelected.id === 1 ? amphurAreaSelected.id : null;

    if (listboxOptSelected === null && action === "add") {
      sweetAlerts.warning("กรุณาเลือกประเภท อปท.");
      return;
    }
    if (optSelected === null && action === "add") {
      sweetAlerts.warning("กรุณาเลือก อปท.");
      return;
    } else if (
      listboxOptSelected.id === 1 &&
      amphurAreaId === null &&
      action === "add"
    ) {
      sweetAlerts.warning("กรุณาระบุเขตอำเภอ");
      return;
    } else if (areaNo === undefined || areaNo === "") {
      sweetAlerts.warning("กรุณาระบุเขต");
      return;
    } else if (numMember === undefined || numMember === "") {
      sweetAlerts.warning("กรุณาระบุจำนวนสมาชิก");
      return;
    } else if (numStation === undefined || numStation === "") {
      sweetAlerts.warning("กรุณาระบุจำนวนหน่วย");
      return;
    } else {
      if (action === "add") {
        addOptArea(
          optSelected.id,
          listboxOptSelected.id,
          areaNo,
          amphurAreaId,
          numMember,
          numStation
        )
          .then(result => {
            closeModal();
            sweetAlerts.success(`เพิ่มข้อมูลเรียบร้อยแล้ว`);
            addSucess(areaNo);

            return;
          })
          .catch(err => {
            sweetAlerts.danger("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
            /* if (err.response.data.code === 40301) {
              sweetAlerts.danger(`หมายเลขเขตที่ ${areaNo} มีอยู่แล้ว`);
            }
            return; */
          });
      }
      if (action === "edit") {
        editOptArea(
          optSelected.id,
          children.opt_area_id,
          areaNo,

          amphurAreaId,

          numMember,
          numStation
        )
          .then(result => {
            closeModal();
            addSucess(areaNo);
            sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
            return;
          })
          .catch(err => {
            sweetAlerts.danger("ไม่สามารถแก้ไขเขตได้");
            return;
          });
      }
    }
  };
  const addSucess = areaNo => {
    onaddSuccess(areaNo);
  };
  return (
    <div className={show ? "modal" : "hide"}>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={show}
        fullWidth
        transition={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.titlemodal}
        >
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            {" " + props.title}
          </div>
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {action === "edit" && show === true && children !== null ? (
            <>
              <form className={classes.form}>
                <FormControl>
                  <ListboxOpts
                    listboxOptSelected={listboxOptSelected}
                    defaultValue={{ id: children.ms_opt_id }}
                    provinceSelected={provinceSelected}
                    customProps={{
                      disabled: true
                    }}
                  // amphurSelected={amphurSelected}
                  // tambolSelected={tambolSelected}
                  // onChange={onOptSelected}
                  ></ListboxOpts>
                  <CustomInputWithValidator
                    labelText="เขตที่ *"
                    id="area_no"
                    validators={[onlyNumber, positiveNumber]}
                    inputProps={{
                      disabled: action === "edit" ? true : false,
                      // inputRef: areaNoRef,
                      value: areaNo !== null ? areaNo : "",
                      onChange: e => {
                        setAreaNo(e.target.value);
                      }
                    }}
                  />
                  <CustomInputWithValidator
                    labelText="จำนวนสมาชิก *"
                    id="num-memeber"
                    validators={[onlyNumber, positiveNumber]}
                    inputProps={{
                      // inputRef: numMemberRef,
                      value: numMember !== null ? numMember : "",
                      onChange: e => {
                        console.log(e.target.value);
                        if (e.target.value === 0) {
                          alert("dd");
                        } else {
                          setNumMember(e.target.value);
                        }
                      }
                    }}
                  />
                  <CustomInputWithValidator
                    labelText="จำนวนหน่วยเลือกตั้ง *"
                    id="num_station"
                    validators={[onlyNumber, positiveNumber]}
                    inputProps={{
                      //inputRef: numStationRef,
                      value: numStation !== null ? numStation : "",

                      onChange: e => {
                        // console.log(e.target.value);
                        setNumStation(e.target.value);
                      }
                    }}
                  />
                </FormControl>
              </form>
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
              >
                <Button onClick={closeModal}>ยกเลิก</Button>
                <Button onClick={onSubmit} variant="contained" color="success">
                  บันทึกข้อมูล..
                </Button>
              </DialogActions>
            </>
          ) : (
            <form className={classes.form}>
              <FormControl>
                <ListboxOpttype
                  value={listboxOptSelected}
                  onChange={onListboxOptSelected}
                ></ListboxOpttype>
                <ListboxProvince
                  value={provinceSelected}
                  title={"เลือกจังหวัด"}
                  onChange={onProvinceSelected}
                ></ListboxProvince>
                {listboxOptSelected &&
                  [2, 3, 4, 5, 6, 7].includes(listboxOptSelected.id) ? (
                  <ListboxDistinct
                    value={amphurSelected}
                    provinceSelected={provinceSelected}
                    title={"เลือกอำเภอ"}
                    onChange={onAmphurSelected}
                  ></ListboxDistinct>
                ) : null}
                {listboxOptSelected &&
                  [5, 6, 7].includes(listboxOptSelected.id) ? (
                  <ListboxSubdistinct
                    provinceSelected={provinceSelected}
                    amphurSelected={amphurSelected}
                    value={tambolSelected}
                    title={"เลือกตำบล"}
                    onChange={onTamboldSelected}
                  ></ListboxSubdistinct>
                ) : null}
                {listboxOptSelected &&
                  [1, 2, 3, 4, 5].includes(listboxOptSelected.id) ? (
                  <ListboxOpts
                    listboxOptSelected={listboxOptSelected}
                    value={optSelected}
                    provinceSelected={provinceSelected}
                    amphurSelected={amphurSelected}
                    tambolSelected={tambolSelected}
                    onChange={onOptSelected}
                  ></ListboxOpts>
                ) : null}
                {listboxOptSelected && [1].includes(listboxOptSelected.id) ? (
                  <ListboxDistinct
                    title="เลือกเขตอำเภอ (เฉพาะ อบจ. เท่านั้น)"
                    value={amphurAreaSelected}
                    provinceSelected={provinceSelected}
                    onChange={(evt, newValue) =>
                      setAmphurAreaSelected(newValue)
                    }
                  ></ListboxDistinct>
                ) : null}
                <CustomInputWithValidator
                  labelText="เขตที่ *"
                  id="area_no"
                  validators={[onlyNumber, positiveNumber]}
                  inputProps={{
                    disabled: action === "edit" ? true : false,
                    // inputRef: areaNoRef,
                    value: areaNo !== null ? areaNo : "",
                    onChange: e => {
                      setAreaNo(e.target.value);
                    }
                  }}
                />
                <CustomInputWithValidator
                  labelText="จำนวนสมาชิก *"
                  id="num-memeber"
                  validators={[onlyNumber, positiveNumber]}
                  inputProps={{
                    // inputRef: numMemberRef,
                    value: numMember !== null ? numMember : "",
                    onChange: e => {
                      setNumMember(e.target.value);
                    }
                  }}
                />
                <CustomInputWithValidator
                  labelText="จำนวนหน่วยเลือกตั้ง *"
                  id="num_station"
                  validators={[onlyNumber, positiveNumber]}
                  inputProps={{
                    //inputRef: numStationRef,
                    value: numStation !== null ? numStation : "",

                    onChange: e => {
                      //   console.log(e.target.value);
                      setNumStation(e.target.value);
                    }
                  }}
                />
              </FormControl>
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
              >
                <Button onClick={closeModal}>ยกเลิก</Button>
                <Button onClick={onSubmit} variant="contained" color="success">
                  บันทึกข้อมูล
                </Button>
              </DialogActions>
            </form>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModaladdArea;
