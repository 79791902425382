import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getSearchLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchpurchase${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getPurchase(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/getpurchase${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getPurchaseDetailByOptLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchpurchasedetailbyopt${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getPurchaseDetailByPurchaseDetailIDLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchallocatebypurchasedetailid${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addVoteCardPurchasePopup(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/popup`;

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addVoteCardPurchase(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/addpurchase`;

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addVoteCardPurchaseAll(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/addpurchaseAll`;

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addVoteCardPurchaseDetail(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/addpurchasedetail`;

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function updateVoteCardPurchase(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/updatepurchase`;

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function updateVoteCardPurchaseDetailStatus(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/updatepurchasedetailstatus`;

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function updateVoteCardPurchaseDetailAssignFile(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/updatepurchasedetailassignfile`;

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteVoteCardPurchase(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/deletepurchase`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    },
    data: body
  });
}
export function deleteVoteCardPurchaseDetailByID(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/deletepurchasedetailbypurchaseid`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    },
    data: body
  });
}
