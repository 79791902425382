import AgeRank from "./AgeRank";
import CarrerList from "./CarrerList";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import React from "react";
import Title from "./Title";
import { VoteDateContextProvider } from "contexts/VoteDate";
import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

const styles = {
  textCenter: {
    textAlign: "center"
  },
  //tabRoot: { backgroundColor: "#0a6" },
  span: {
    backgroundColor: "#aa0",
    fontSize: "22px"
  }
};

const useStyles = makeStyles(styles);

const Index = props => {
  const classes = useStyles();
  /* 
  const addOptSchema = yup.object().shape({
    provinceId: yup.string().required("This field is required."),
    amphurId: yup.string().required("This field is required.")
  }); */

  //   const addOptForm = useForm({
  //     validationSchema: addOptSchema
  //   });

  return (
    <VoteDateContextProvider>
      <div className={classes.tabcustom}>
        <form>
          <CustomTabs
            styles={customTabsStyles}
            headerColor="warning"
            tabs={[
              {
                tabName: "อาชีพ",
                tabContent: <CarrerList />
              },
              {
                tabName: "ช่วงอายุ",
                tabContent: <AgeRank />
              },

              {
                tabName: "คำนำหน้าชื่อ",
                tabContent: <Title />
              }
            ]}
          />
        </form>
      </div>
    </VoteDateContextProvider>
  );
};

export default Index;
