function _ThaiCitizenID(id) {

  if (id === undefined || id === null) {
    //return [true, 'เลขประจำตัวประชาชนผิดรูปแบบ'];
    return [true, 'เลขประจำตัวประชาชนผิด'];
  }

  if (id.charAt(0).match(/[09]/)) {
    //return [true, 'เลขประจำตัวประชาชนไม่ถูกต้อง'];
    return [true, 'เลขประจำตัวประชาชนผิด'];
  }

  //if (id.length !== 13 || id.charAt(0).match(/[09]/)) {
  if (id.length !== 13) {  
    //return [true, 'เลขประจำตัวประชาชนไม่ครบ 13 หลัก'];
    return [true, 'เลขประจำตัวประชาชนผิด'];
  }

  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(id.charAt(i)) * (13 - i);
  }

  if ((11 - (sum % 11)) % 10 !== parseInt(id.charAt(12))) {
    //return [true, 'เลขประจำตัวประชาชนไม่ถูกต้อง'];
    return [true, 'เลขประจำตัวประชาชนผิด'];
  }

  return [false, ''];
}

export default function ThaiCitizenID(v) {
  return _ThaiCitizenID(v);
}
