import MaterialTable from "components/Table/MaterialTable";
import React, { useState, useEffect } from "react";
import { useSweetAlert } from "components/Alert/SweetAlert";
import {
  getsuspendVoteType,
  editsuspendVoteType,
  deletesuspendVoteType,
  addsuspendVoteType
} from "contexts/API/SUSPEND/common";

const VoteType = props => {
  const { sweetAlerts } = useSweetAlert();
  const [columns] = useState([
    {
      title: "suspendVoteTypeId",
      field: "suspendVoteTypeId",
      hidden: true
    },
    {
      title: "ประเภทการเลือกตั้ง",
      field: "detail",
      cellStyle: {
        minWidth: "700px"
      }
    }
  ]);

  const [data, setData] = useState([]);
  useEffect(() => {
    getsuspendVoteType()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
  }, [sweetAlerts]);
  const optionsTable = {};
  return (
    <MaterialTable
      title={"ประเภทการเลือกตั้ง"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            addsuspendVoteType(newData.detail)
              .then(result => {
                newData.suspendVoteTypeId = result.data.data.suspendVoteTypeId;
                setData([...data, newData]);
                sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");

              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
              });
            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              editsuspendVoteType(oldData.suspendVoteTypeId, newData.detail)
                .then(result => {
                  
                  sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                  setData([...dataUpdate]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });
              resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              deletesuspendVoteType(oldData.suspendVoteTypeId)
                .then(result => {
                  sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                  setData([...dataDelete]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
                });

              resolve();
          })
      }}
    />
  );
};
export default VoteType;
