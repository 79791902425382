import React, { useEffect, useState } from "react";
import { addFile, delFile } from "contexts/API/WTD/Listwtd";

import { FileUpload } from "components/Common/FileUpload";
import { LinkDownload } from "components/Common/LinkDownload";
import MaterialTable from "components/Table/MaterialTable";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

//import Cookies from "universal-cookie";

//const cookies = new Cookies();
const AddFile = props => {
  const { sweetAlerts } = useSweetAlert();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const optionsTable = [{}];
  const [urlFile, setUrlFile] = useState("");
  useEffect(() => {
    if (props.dataFile !== null) {
      setData(props.dataFile);
      //setWtdPersonId(props.dataLaw.)
    }
  }, [props.dataFile]);

  useEffect(() => {
    const loadData = async () => {
      const columns = [
        {
          title: "wtdAttatchFileId",
          field: "wtdAttatchFileId",
          hidden: true
        },
        {
          title: "wtdPersonId",
          field: "wtdPersonId",
          hidden: true
        },
        {
          title: "wtdPersonFileId",
          field: "wtdPersonFileId",
          hidden: true
        },

        {
          title: "เอกสารที่เกี่ยวข้อง",
          field: "fileUUID",
          cellStyle: {
            minWidth: "1000px"
          },
          editComponent: props => {
            return (
              <FileUpload
                label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                // download
                fileName={props.rowData.fileName}
                url={urlFile}
                onFileHandler={(e, f, progressCb) => {
                  uploadFile("wtd", f, progressCb)
                    .then(r => {
                      const data = r.data.data;

                      if (data.fileId) {
                        // console.log("data---", data);
                        setUrlFile(getFileDownloadUrl("wtd", data.fileId));
                        props.rowData.fileName = data.fileName;
                        props.onChange(data.fileId);
                      }

                      sweetAlerts.success("แนบไฟล์เรียบร้อย");
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }}
                fileType="application/pdf"
                btnName={<>Browse</>}
                btnProps={{
                  color: "primary"
                }}
              />
            );
          },

          render: rowData => (
            <LinkDownload
              url={getFileDownloadUrl("wtd", rowData.fileUUID)}
              fileId={rowData.fileUUID}
              fileName={rowData.fileName}
            />
          )
        }
      ];
      setColumns(columns);
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData]);
  const edit = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        if (!newData.fileUUID) {
          sweetAlerts.warning("กรุณาแนบเอกสารที่เกี่ยวข้อง");
          reject();
        } else {
          addFile(
            props.wtdPersonId,
            props.wtdProvinceId,
            newData.fileName,
            newData.fileUUID
          )
            .then(result => {
              newData.wtdAttatchFileId = result.data.data.wtdAttatchFileId;
              newData.wtdPersonFileId = result.data.data.wtdPersonFileId;
              setData([...data, newData]);
              sweetAlerts.success("เพิ่มข้อมูลเรียบร้อย");
              resolve();
            })
            .catch(err => {
              if (err.response.data && err.response.data.desc) {
                sweetAlerts.error(
                  "เพิ่มข้อมูลไม่สำเร็จ",
                  err.response.data.desc
                );
              } else {
                sweetAlerts.error("เพิ่มข้อมูลไม่สำเร็จ");
              }

              reject();
            });
          resolve();
        }
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataDelete = [...data];
          const index = oldData.tableData.id;

          delFile(
            oldData.wtdAttatchFileId,
            props.wtdPersonId,
            oldData.wtdPersonFileId
          )
            .then(result => {
              dataDelete.splice(index, 1);
              setData([...dataDelete]);

              sweetAlerts.success("ลบข้อมูลเรียบร้อย");

              //  setData([...dataUpdate]);
            })
            .catch(err => {
              sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
            });

          resolve();
        }, 1000);
      })
  };
  return (
    <>
      <MaterialTable
        title={"เอกสารที่เกี่ยวข้อง"}
        columns={columns}
        data={data}
        editable={[2, 5].includes(props.status) ? {} : edit}
        options={{
          optionsTable,
          paging: false,
          search: false,
          exportButton: false
        }}
      />
    </>
  );
};
export default AddFile;
