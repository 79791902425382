import React, { useState, useEffect } from "react";
import MaterialTable from "components/Table/MaterialTable";
import ListboxWtdLawType from "components/Common/ListboxWtdLawType";
import {
  getwtdLawType,
  getwtdLawSection,
  editwtdLawSection,
  addwtdLawSection,
  deletewtdLawSection
} from "contexts/API/WTD/common";
import { useSweetAlert } from "components/Alert/SweetAlert";
const LawSection = props => {
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const [lawtypeList, setLawtypeList] = useState([]);
  const optionsTable = { pageSize: 10 };
  useEffect(() => {
    getwtdLawType()
      .then(r => {
        let LawtypeData = r.data.data.map(v => {
          console.log("v.wtdLawTypeId>>",v.wtdLawTypeId)
          return { id: v.wtdLawTypeId, value: v.detail };
        });
        console.log("LawtypeData>>",LawtypeData);
        setLawtypeList(LawtypeData);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      title: "wtdLawSectionId",
      field: "wtdLawSectionId",
      hidden: true
    },
    {
      title: "ประเภท พ.ร.บ",
      field: "wtdLawTypeId",
      cellStyle: {
        minWidth: "600px"
      },
      render: rowData => {
        const v = lawtypeList.find(e => e.id === rowData.wtdLawTypeId);
        console.log("v>>>",v);
        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        console.log("props >>>>" ,props.rowData.wtdLawTypeId);

        console.log("props.value >>>>" ,props.value);
        return (
          <ListboxWtdLawType
            rowId={0}
            //defaultValue={{ id: props.rowData.wtdLawTypeId }}
            defaultValue={{ id: props.value }}
            data={lawtypeList}
            onChange={(i, e, v) => {
              console.log("v >>>> " ,v);
              console.log("v.id >>>> " ,v.id);
              if (v !== null) {
                props.rowData.wtdLawTypeId = e.id;

                console.log("props.rowData.wtdLawTypeId >>>> " ,props.rowData.wtdLawTypeId);
              }
            }}
          />
        );
      }
    },
    {
      title: "มาตรา",
      field: "detail",
      cellStyle: {
        minWidth: "250px"
      }
    }
  ];

  useEffect(() => {
    getwtdLawSection()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaterialTable
      title={"จัดการมาตราตามกฎหมายของการเพิกถอนสิทธิเลือกตั้ง"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
              addwtdLawSection(newData.wtdLawTypeId, newData.detail)
                .then(result => {
                  newData.wtdLawSectionId = result.data.data.wtdLawSectionId;
                  setData([...data, newData]);
                  sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
                  
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
                });

              resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              console.log("oldData>>",oldData);
              console.log("newData>>",newData);
              editwtdLawSection(
                oldData.wtdLawSectionId,
                newData.wtdLawTypeId,
                newData.detail
              )
                .then(result => {
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });

              resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              deletewtdLawSection(oldData.wtdLawTypeId)
                .then(result => {
                  sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                  setData([...dataDelete]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
                });
              resolve();
          })
      }}
    />
  );
};
export default LawSection;
