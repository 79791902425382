import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
//import Cookies from "universal-cookie";
//const cookies = new Cookies();

export function postWtdPerson(IDCard) {
  const url = `${END_POINT_URL}/noAuth/checkWtdPerson/`;
  const body = {
    idCard: [`${IDCard}`],
    listIdCard: IDCard
  };
  return axios.post(url, body, {});
}
