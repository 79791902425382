import Autocomplete from "@material-ui/lab/Autocomplete";
/* core components */
import React from "react";
//import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxWtdLawSection = props => {
  let lawTypeId = props.wtdLawTypeIdSelected
    ? props.wtdLawTypeIdSelected.id
    : 0;
  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/wtd/wtdLawSectionByType/" + lawTypeId,
    []
  );

  let content = (
    <TextField disabled label="มาตรา" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return { id: v.WtdLawSectionId, value: v.detail };
    });
  }
  if (data.length > 0) {
    content = (
      <Autocomplete
        name={props.name}
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        options={data}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={props.onChange}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={
                props.titlelawtype ? props.titlelawtype : "เลือกประเภท พ.ร.บ."
              }
              margin="normal"
              fullWidth
            />
          );
        }}
        {...props.customProps}
      />
    );
  }
  return content;
};
export default ListboxWtdLawSection;
