import React, { useState } from "react";

import EditWtdPerson from "components/pages/wtd/AddWtd/EditWtdPerson";
import ListWtdPerson from "./ListWtdPerson";
import { WtdContextProvider } from "contexts/Wtd";
// @material-ui/core components

// core components


const SwitchComponents = ({ active, children }) => {
  return children.filter(child => child.props.name === active);
};

const ListWtdIndex = props => {
  const [pageProps, setPageProps] = useState({
    active: "ListWtdPerson",
    props: null
  });

  //const classes = useStyles();
  return (
    // <div className={classes.tabcustom}>
    <WtdContextProvider active={pageProps.active}>
      <SwitchComponents active={pageProps.active}>
        <ListWtdPerson
          name="ListWtdPerson"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <EditWtdPerson
          name="EditWtdPerson"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
      </SwitchComponents>
    </WtdContextProvider>
    // </div>
  );
};
export default ListWtdIndex;
