import React, { useState } from "react";
import {
  editEffectiveDate,
  getProvinceGroup,
  putPromoteEffectiveDateAll
} from "contexts/API/INSPEC/Effective";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import Checkbox from "@material-ui/core/Checkbox";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import DateVoteDate from "components/Common/Datepicker";
import Datepicker from "components/Common/Datepicker";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxInspectVoteDateInquire from "components/Common/ListboxInspectVoteDateInquire";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import { Paper } from "@material-ui/core";
import { useSweetAlert } from "components/Alert/SweetAlert";

const Effective = props => {
  //const cookies = new Cookies();
  const { sweetAlerts } = useSweetAlert();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [promote, setPromote] = useState({
    inspectorVotedateId: null,
    startEffectiveDate: null,
    endEffectiveDate: null
  });

  //const { sweetAlerts } = useSweetAlert();
  const [votedateSelected, setVotedateSelected] = useState(null);
  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  const handleSubmit = () => {
    if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันเลือกตั้งในระบบ");
      return;
    } else {
      setLoading(true);
      getProvinceGroup(votedateSelected.id)
        .then(result => {
          setData(result.data.data);

          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.response.data.desc);
        });
    }
  };

  const savePromote = () => {
    if (
      promote.startEffectiveDate &&
      promote.endEffectiveDate &&
      promote.inspectorVotedateId
    ) {
      putPromoteEffectiveDateAll(promote)
        .then(result => {
          sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");

          //setLoading(false);
        })
        .catch(err => {
          //setLoading(false);
          sweetAlerts.error("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
        });
    } else {
      sweetAlerts.warning("กรุณาเลือกวันที่");
    }
  };
  const columns = [
    {
      title: "provinceGroupId",
      field: "provinceGroupId",
      hidden: true
    },
    {
      title: "กลุ่มจังหวัด",
      field: "group_no",

      defaultGroupOrder: 0,
      cellStyle: {
        backgroundColor: "#039be5",
        color: "#FFF",
        fontWeight: 500
      },
      headerStyle: {
        fontWeight: 500
      }
    },
    {
      title: "จังหวัด",
      field: "provinceName",
      cellStyle: {
        minwidth: "200px"
      },
      editable: "never"
      // render: rowData => {
      //   return `[#${rowData.province_group_id}] ${rowData.province_name}`;
      // }
    },
    {
      title: "บันทึกผลการจับสลาก ผตล.จว.",
      field: "result",
      cellStyle: {
        minwidth: "150px"
      },
      editable: "never",
      render: rowData => {
        return rowData.votedateDetailId === null
          ? "ยังไม่บันทึกรายชื่อ"
          : "บันทึกรายชื่อแล้ว";
      }
    },

    {
      title: "วาระการดำรงตำแหน่งเดิม",
      field: "votedateDetailId",
      hidden: true
    },

    {
      title: "ตั้งแต่วันที่",
      field: "startEffectiveDate",
      cellStyle: {
        minwidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return (
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ConvertDate(rowData.startEffectiveDate),
              disabled: true
            }}
          />
        );
      },
      editComponent: props => {
        const dateValue = props.rowData.startEffectiveDate
          ? props.rowData.startEffectiveDate
          : null;
        return (
          <DateVoteDate
            label=""
            value={dateValue}
            onChange={date =>
              props.onRowDataChange({
                ...props.rowData,
                startEffectiveDate: date
              })
            }
          />
        );
      }
    },

    {
      title: "ถึงวันที่",
      field: "endEffectiveDate",
      cellStyle: {
        minwidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return (
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ConvertDate(rowData.endEffectiveDate),
              disabled: true
            }}
          />
        );
      },
      editComponent: props => {
        const dateValue = props.rowData.endEffectiveDate
          ? props.rowData.endEffectiveDate
          : null;
        return (
          <DateVoteDate
            label=""
            value={dateValue}
            onChange={date =>
              props.onRowDataChange({
                ...props.rowData,
                endEffectiveDate: date
              })
            }
          />
        );
      }
    }
  ];

  const optionsTable = { paging: false };
  return (
    <div>
      <Card>
        <CardHeader color="danger" text>
          <CardText color="danger">
            <h4>บันทึกวาระการดำรงตำแหน่ง ผตล.จว.</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxInspectVoteDateInquire
                    value={votedateSelected}
                    required={true}
                    onChange={(evt, newValue) => {
                      setVotedateSelected(newValue);
                      setPromote({
                        ...promote,
                        inspectorVotedateId: newValue.id
                      });
                    }}
                  ></ListboxInspectVoteDateInquire>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}></GridItem>
              </GridContainer>
              <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button>ยกเลิก</Button>
                  <Button color="info" onClick={handleSubmit}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={12} sm={12} md={4}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  บันทึกวันครบวาระเหมือนกันทั่วประเทศ
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <Datepicker
                    label="ตั้งแต่วันที่"
                    value={promote.startEffectiveDate}
                    onChange={date => {
                      let arr = date.format().split("T");
                      setPromote({
                        ...promote,
                        startEffectiveDate: arr[0]
                      });
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <Datepicker
                    label="ถึงวันที่"
                    value={promote.endEffectiveDate}
                    onChange={date => {
                      let arr = date.format().split("T");
                      setPromote({
                        ...promote,
                        endEffectiveDate: arr[0]
                      });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button color="success" disabled={!checked} onClick={savePromote}>
                บันทึก
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}>
                  <span style={{ color: "red", paddingLeft: "20px" }}>
                    ** บันทึกได้เฉพาะจังหวัดที่บันทึกผลการจับสลาก ผตล.จว.
                    แล้วเท่านั้น **
                  </span>
                </GridItem>
              </GridContainer>
            </CardBody>
            {loading ? (
              <LoadingData />
            ) : (
              <MaterialTable
                title={"รายชื่อผู้ตรวจการเลือกตั้งประจำจังหวัด"}
                options={optionsTable}
                columns={columns}
                data={data}
                editable={{
                  isEditHidden: rowData => rowData.votedateDetailId === null,

                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...data];
                        const index = oldData.tableData.id;

                        editEffectiveDate({
                          votedateDetailId: oldData.votedateDetailId,
                          provinceGroupId: oldData.provinceGroupId,
                          personApplyCommandId: newData.personApplyCmdId,
                          startEffectiveDate: newData.startEffectiveDate,
                          endEffectiveDate: newData.endEffectiveDate
                        })
                          .then(result => {
                            dataUpdate[index] = newData;
                            setData([...dataUpdate]);

                            sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");

                            //  setData([...dataUpdate]);
                          })
                          .catch(err => {
                            sweetAlerts.error(
                              "ไม่สามารถแก้ไขข้อมูลได้",

                              "ไม่สามารถบันทึกวันครบวาระได้ เนื่องจากยังไม่มีจังหวัดใดบันทึกผลการจับสลาก"
                            );
                          });

                        dataUpdate[index] = newData;
                        setData([...dataUpdate]);

                        resolve();
                      }, 1000);
                    })
                }}
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}
              />
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default Effective;
