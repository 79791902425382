import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";

const cookies = new Cookies();

export function getCitized(idCard) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/person/citizenId/${idCard}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function chkDubApplyNo(param) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/${param}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getCandidateById(Id) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/person/applyCmd/${Id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addCandidateDate(
  startApplyDate,
  endApplyDate,
  startEffectiveDate
) {
  const isAuth = cookies.get("userInfo");
  const body = {
    startApplyDate: startApplyDate,
    endApplyDate: endApplyDate,
    startEffectiveDate: startEffectiveDate
  };
  const url = `${END_POINT_URL}/inspector/cadidateDate`;
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delCandidate(personApplyCmdId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/person/applyCmd/${personApplyCmdId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addCandidate(data, provinceId, cmdApplyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/person/applyCmd/provinceId/${provinceId}/cmdApplyDateId/${cmdApplyDateId}`;
  const body = data;
  // console.log("body");
  // console.log(body);
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editCandidatePerson(data, Id) {
  const isAuth = cookies.get("userInfo");
  const body = data;
  const url = `${END_POINT_URL}/inspector/person/applyCmd/${Id}`;
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editCandidate(
  provinceId,
  startApplyDate,
  endApplyDate,
  startEffectiveDate
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/cadidateDate/`;
  const body = {
    startApplyDate: startApplyDate,
    endApplyDate: endApplyDate,
    startEffectiveDate: startEffectiveDate
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getCadidate(provinceId, commandApplyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/person/list/provinceId/${provinceId}/cmdApplyDateId/${commandApplyDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
