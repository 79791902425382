import React, { useState, useEffect, useContext } from "react";

/* API */
import { getSearchLists } from "contexts/API/VoteCard/Stock";
import { getDashboard } from "contexts/API/VoteCard/Dashboard";

/* Material Components */
import LoadingData from "components/Common/LoadingData";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import TableComponent from "components/Table/MaterialTable";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import { useSnackBar } from "components/Alert/Snackbar";
import { useSweetAlert } from "components/Alert/SweetAlert";
//import NumberFormat from "react-number-format";

import StoreIcon from "@material-ui/icons/Store";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StorageIcon from "@material-ui/icons/Storage";

import style from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { VoteDateContext } from "contexts/VoteDate";
import { makeStyles } from "@material-ui/core/styles";

import ListboxVotecardPrinting from "components/Common/ListboxVotecardPrinting";
import ListboxVotecardOptType from "components/Common/ListboxVotecardOptType";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxVotecardNumber from "components/Common/ListboxVotecardNumber";
import ListboxVotecardColor from "components/Common/ListboxVotecardColor";

const useStyles = makeStyles(style);

const Stock = ({ pageProps, setPageProps }) => {

  const [loading, setLoading] = useState(false);
  const [, setClickSearch] = useState(false);
  // const { snackBars } = useSnackBar();
  const { sweetAlerts } = useSweetAlert();

  const {
    votecardNumberSelected, //หมายเลขบัตร    
    setVotecardNumberSelected,
    onVotecardNumberSelected,
    votecardOptTypeSelected, //ประเภท อปท.
    setVotecardOptTypeSelected,
    onVotecardOptTypeSelected,
    positionTypeSelected, //ตำแหน่ง
    setPostionTypeSelected,
    onPostionTypeSelected,
    votecardColorSelected, //สีบัตร
    setVotecardColorSelected,
    onVotecardColorSelected,
    votecardPrintingSelected, //โรงพิมพ์
    setVotecardPrintingSelected,
    onVotecardPrintingSelected
  } = useContext(VoteDateContext);

  const [VoteCardVolumn, setVoteCardVolumn] = useState(0);
  const [VoteCardDisburse, setVoteCardDisburse] = useState(0);
  const [VoteCardBalance, setVoteCardBalance] = useState(0);

  const classes = useStyles();
  const optionsTable = { exportButton: true, paging: true };

  const [data, setData] = useState([]);
  /*  const [children, setchildren] = useState(null);
   const [titleModal, settitleModal] = useState(null); */
  const [, setShowEditModal] = useState(false);

  const columns = [
    {
      title: "โรงพิมพ์",
      field: "PrintHouseName",
      cellStyle: {
        minWidth: "150px",
        textAlign: "left"
      },
      headerStyle: {
        textAlign: "center"
      },
    },
    {
      title: "ประเภท",
      field: "OptName",
      cellStyle: {
        minWidth: "70px",
        textAlign: "left"
      },
      headerStyle: {
        textAlign: "center"
      },
    },
    {
      title: "ตำแหน่ง",
      field: "PositionTypeDetail",
      cellStyle: {
        minWidth: "50px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
    },
    {
      title: "สี",
      field: "CardColorName",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
    },
    {
      title: "ชนิดหมายเลข",
      field: "CardNumberName",
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return " " + rowData.CardNumberName.toLocaleString()
      }
    },
    {
      title: "หมวด",
      field: "category",
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
    },
    {
      title: "ยอดยกมา (เล่ม)",
      field: "CardPublishAmount",
      cellStyle: {
        minWidth: "120px",
        textAlign: "right",
        paddingRight: "20px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.CardPublishAmount);
      }
    },
    {
      title: "เบิกแล้ว (เล่ม)",
      field: "CardLostAmount",
      cellStyle: {
        minWidth: "100px",
        textAlign: "right",
        paddingRight: "20px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.CardLostAmount);
      }
    },
    {
      title: "ชำรุด (เล่ม)",
      field: "CardNotUsed",
      cellStyle: {
        minWidth: "70px",
        textAlign: "right"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.CardNotUsed);
      }
    },
    {
      title: "คงเหลือ (เล่ม)",
      field: "CardBalanceAmount",
      cellStyle: {
        minWidth: "100px",
        textAlign: "right",
        paddingRight: "20px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.CardBalanceAmount);
      }
    },
    {
      title: "",
      field: "",
      cellStyle: {
        minWidth: "100px"
      },
      render: rowData => {
        return (
          <>
            <Button
              size="sm"
              color="info"
              onClick={() => {
                detailVoteCardHandler(rowData);
              }}
            >
              รายละเอียด
            </Button>
          </>
        );
      }
    }
  ];

  useEffect(() => {

    getDashboard('', '')
      .then(result => {
        if (typeof result.data.data != "undefined") {
          if (result.data.data.length > 0) {
            setVoteCardVolumn(result.data.data[0].VoteCardVolumn != null ? (result.data.data[0].VoteCardVolumn).toLocaleString(0, { maximumFractionDigits: 2 }) : 0);
            setVoteCardDisburse(result.data.data[0].VoteCardDisburse != null ? (result.data.data[0].VoteCardDisburse).toLocaleString(0, { maximumFractionDigits: 2 }) : 0);
            setVoteCardBalance(result.data.data[0].VoteCardBalance != null ? (result.data.data[0].VoteCardBalance).toLocaleString(0, { maximumFractionDigits: 2 }) : 0);
          } else {
            setVoteCardVolumn(0);
            setVoteCardDisburse(0);
            setVoteCardBalance(0);
          }
        } else {
          setVoteCardVolumn(0);
          setVoteCardDisburse(0);
          setVoteCardBalance(0);
        }
      }).catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล Dashboard ได้", err.toString());
      });

    if (pageProps.props != null) {
      setClickSearch(false);
      setLoading(true);

      const optQueryUrl = () => {
        let endPoint = "?";

        if (votecardPrintingSelected !== null) {
          endPoint += `VoteCardPrintHouseID=${votecardPrintingSelected.id}&`;
        }

        if (votecardOptTypeSelected !== null) {
          endPoint += `VoteCardOptTypeID=${votecardOptTypeSelected.id}&`;
        }

        if (positionTypeSelected !== null) {
          endPoint += `PositionTypeID=${positionTypeSelected.id}&`;
        }

        if (votecardColorSelected !== null) {
          endPoint += `VoteCardColorID=${votecardColorSelected.id}&`;
        }

        if (votecardNumberSelected !== null) {
          endPoint += `VoteCardNo=${votecardNumberSelected.value}&`;
        }

        return endPoint;
      };

      getSearchLists(optQueryUrl(), optExportUrl())
        .then(result => {
          setData(result.data.data);

          setLoading(false);
          setClickSearch(true);
        }).catch(err => {
          setLoading(false);
          sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
        });
    }
  }, []);

  const openEditModal = () => setShowEditModal(true);

  const onClickCancel = e => {
    setVotecardPrintingSelected(null);
    setVotecardOptTypeSelected(null);
    setPostionTypeSelected(null);
    setVotecardColorSelected(null);
    setVotecardNumberSelected(null);
    setData([]);
  };
  const onClickSearch = e => {
    setClickSearch(false);
    setLoading(true);

    const optQueryUrl = () => {
      let endPoint = "?";

      if (votecardPrintingSelected !== null) {
        endPoint += `VoteCardPrintHouseID=${votecardPrintingSelected.id}&`;
      }

      if (votecardOptTypeSelected !== null) {
        endPoint += `VoteCardOptTypeID=${votecardOptTypeSelected.id}&`;
      }

      if (positionTypeSelected !== null) {
        endPoint += `PositionTypeID=${positionTypeSelected.id}&`;
      }

      if (votecardColorSelected !== null) {
        endPoint += `VoteCardColorID=${votecardColorSelected.id}&`;
      }

      if (votecardNumberSelected !== null) {
        endPoint += `VoteCardNo=${votecardNumberSelected.value}&`;
      }

      return endPoint;
    };

    getSearchLists(optQueryUrl(), optExportUrl())
      .then(result => {
        setData(result.data.data);

        setLoading(false);
        setClickSearch(true);
      }).catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
      });
  };
  const optExportUrl = () => { };
  const detailVoteCardHandler = rowData => {
    setPageProps({ active: "VotecardDetail", props: rowData });
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4}>
          <Card>
            <CardBody style={{ background: "#4361ff" }}>
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ paddingBottom: "20px", background: "#4361ff" }}
              >
                <GridItem xs={3} sm={3} md={3}>
                  <StoreIcon style={{ fontSize: 80, color: "white" }} />
                </GridItem>
                <GridItem xs={9} sm={9} md={9}>
                  <p
                    style={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "500",
                      textAlign: "right"
                    }}
                  >
                    จำนวนบัตรเลือกตั้งทั้งหมด
                  </p>
                  <p
                    style={{
                      paddingTop: "10px",
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "500",
                      textAlign: "right"
                    }}
                  >
                    {VoteCardVolumn}
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4}>
          <Card>
            <CardBody style={{ background: "#00b542" }}>
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ paddingBottom: "20px", background: "#00b542" }}
              >
                <GridItem xs={3} sm={3} md={3}>
                  <ShoppingCartIcon style={{ fontSize: 80, color: "white" }} />
                </GridItem>
                <GridItem xs={9} sm={9} md={9}>
                  <p
                    style={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "500",
                      textAlign: "right"
                    }}
                  >
                    จำนวนบัตรที่เบิกไปแล้ว
                  </p>
                  <p
                    style={{
                      paddingTop: "10px",
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "500",
                      textAlign: "right"
                    }}
                  >
                    {VoteCardDisburse}
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4}>
          <Card>
            <CardBody style={{ background: "#fd4545" }}>
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ paddingBottom: "20px", background: "#fd4545" }}
              >
                <GridItem xs={3} sm={3} md={3}>
                  <StorageIcon style={{ fontSize: 80, color: "white" }} />
                </GridItem>
                <GridItem xs={9} sm={9} md={9}>
                  <p
                    style={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "500",
                      textAlign: "right"
                    }}
                  >
                    จำนวนบัตรคงเหลือ
                  </p>

                  <p
                    style={{
                      paddingTop: "10px",
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "500",
                      textAlign: "right"
                    }}
                  >
                    {VoteCardBalance}
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Card>
        <CardBody>
          <form>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer
                justify="space-around"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={12} sm={12} md={2}>
                  <ListboxVotecardPrinting
                    value={votecardPrintingSelected}
                    onChange={onVotecardPrintingSelected}
                  ></ListboxVotecardPrinting>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <ListboxVotecardOptType
                    value={votecardOptTypeSelected}
                    onChange={onVotecardOptTypeSelected}
                  ></ListboxVotecardOptType>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <ListboxPositionType
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <ListboxVotecardColor
                    votecardPrintingSelected={votecardPrintingSelected}
                    value={votecardColorSelected}
                    onChange={onVotecardColorSelected}
                  ></ListboxVotecardColor>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <ListboxVotecardNumber
                    label="เลือกชนิดหมายเลข"
                    votecardPrintingSelected={votecardPrintingSelected}
                    votecardColorSelected={votecardColorSelected}
                    value={votecardNumberSelected}
                    onChange={onVotecardNumberSelected}
                  ></ListboxVotecardNumber>
                </GridItem>
              </GridContainer>
              <br /> <br /> <br />
              <GridContainer
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <GridItem >
                  <Button className={classes.buttoncustom} onClick={onClickCancel}>ยกเลิก</Button>
                  <Button
                    type="button"
                    color="info"
                    className={classes.buttoncustom}
                    onClick={onClickSearch}
                  >
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </form>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title="รายละเอียดข้อมูลบัตรเลือกตั้งในระบบ"
          columns={columns}
          data={data}
          localization={{}}
          options={optionsTable}
          defaultExpanded={true}
        ></TableComponent>
      )}
    </>
  );
};

export default Stock;
