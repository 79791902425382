import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
//-------------มหาลัย-----------------//

export function getAllNoti() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/notification`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delNoti(id) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/notification/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addNoti(data) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/notification`;
  const body = data;
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getAllPopup() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/popup`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delPopup(id) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/popup/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addPopup(data) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/popup`;
  const body = data;
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
