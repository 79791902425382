import React from "react";
import {getPrettyDate, getPrettyTime} from "./common"
import Badge from "../../Badge/Badge";
import 'font-awesome/css/font-awesome.min.css';
import {useHistory} from "react-router-dom";

export const NotificationDetails = ({email, onDelete}) => {
    let history  = useHistory()

    if (!email) {
        return (
            <div className="email-content empty"/>
        );
    }

    const date = `${getPrettyDate(email.updateDatetime)} · ${getPrettyTime(email.updateDatetime)}`;

    const getDeleteButton = () => {
        if (email.isDelete !== false) {
            return <span onClick={() => {onDelete(email.id);}} className="delete-btn fa fa-trash-o"/>;
        }
        return undefined;
    }

    return (
        <div className="email-content">
            <div className="email-content__header">
                <h3 className="email-content__subject">{email.header}</h3>
                {getDeleteButton()}
                <div className="email-content__time">{date}</div>
                <div className="email-content__from">{email.provinceName}</div>
            </div>
            <div className="email-content__menu-link">
                {email.redirectUrl ? <Badge color="success"><a onClick={() =>history.push(email.redirectUrl)}>ไปยังเมนู</a></Badge> : ""}
            </div>
            <div className="email-content__message">{email.body}</div>
        </div>
    );
};

export default NotificationDetails;
