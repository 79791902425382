import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getSearchLists(queryStr) {
  console.log(queryStr);
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/searchpublish${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getStartNewNo(queryStr) {
  console.log(queryStr);
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/startnewno${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addVoteCardPublish(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/addpublish`;

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function updateVoteCardPublish(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/updatepublish}`;

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteVoteCardPublishDetail(body) { 
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/deletepublishdetail`;
  
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    },
    data: body
  });
}