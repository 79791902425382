import React, { useContext, useState } from "react";

import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOpttype from "components/Common/ListboxOpttype.js";
import ListboxProvince from "components/Common/ListboxProvince.js";
import MaterialTable from "components/Table/MaterialTable";
import ModalUpgradOpt from "./ModalUpgradeOpt";
import MustNotNull from "utils/Validators/MustNotNull";
import Paper from "@material-ui/core/Paper";
import { VoteDateContext } from "contexts/VoteDate";
// Context APIs
import { getOptLists } from "contexts/API/OPT/Opt";
import { useSweetAlert } from "components/Alert/SweetAlert";

const MsOptUplevel = props => {
  const {
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,

    tambolSelected,
    optSelected,
    listboxOptSelected,
    onListboxOptSelected
  } = useContext(VoteDateContext);

  const { sweetAlerts } = useSweetAlert();
  const [show, setShow] = useState(false);
  const [upLevelDetail, setUpLevelDetail] = useState(null);
  const [data, setData] = useState([]);

  const onClickSearch = e => {
    let endPoint = "?";
    //endPoint += `per_page=${per_page}&page=${page}`;

    if (listboxOptSelected !== null) {
      endPoint += `&optsubtype=[${listboxOptSelected.id}]`;
    } else {
      sweetAlerts.warning("กรุณาเลือกประเภท อปท.");
      return;
    }
    if (optSelected !== null) {
      endPoint += `&optId=${optSelected.id}`;
    }
    if (tambolSelected !== null) {
      endPoint += `&tambolId=${tambolSelected.id}`;
    }
    if (amphurSelected !== null) {
      endPoint += `&amphurId=${amphurSelected.id}`;
    }
    if (provinceSelected !== null) {
      endPoint += `&provinceId=${provinceSelected.id}`;
    }

    getOptLists(endPoint)
      .then(result => {
        if (result.data !== null && result.data.data.length > 0) {
          setData(result.data.data);
        } else {
          sweetAlerts.info("ไม่พบข้อมูล");
        }
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้ในขณะนี้");
      });
  };

  const openModal = () => setShow(true);

  const closeModal = () => {
    setShow(false);
    onClickSearch();
  };

  const columns = [
    {
      field: "ms_opt_id",
      export: false,
      hidden: true
    },
    {
      field: "province_id",
      export: false,
      hidden: true
    },
    {
      field: "province_name",
      title: "จังหวัด",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "amphur_name",
      title: "อำเภอ",
      cellStyle: {
        textAlign: "center"
      }
    },

    {
      field: "opt_sub_type_short_name",
      title: "ประเภท อปท.",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "name",
      title: "ชื่อ อปท.",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "countarea",
      title: "จำนวนเขต",
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      field: "",
      render: rowData => {
        return (
          <>
            <Button
              size="sm"
              color="warning"
              onClick={() => {
                openModal();
                setUpLevelDetail({
                  oldOptId: rowData.ms_opt_id,
                  optSubTypeId: rowData.ms_opt_sub_type_id,
                  provinceId: rowData.province_id,
                  amphurId: rowData.amphur_id,
                  tambolId: rowData.tambol_id
                });
              }}
            >
              ยกฐานะ
            </Button>
          </>
        );
      }
    }
  ];

  const options_table = {};

  return (
    <>
      {show ? (
        <ModalUpgradOpt
          show={show}
          closeModal={closeModal}
          title={"ยกฐานะ"}
          data={upLevelDetail}
        />
      ) : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br /> <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <ListboxOpttype
                value={listboxOptSelected}
                onChange={onListboxOptSelected}
                required={true}
                validators={[MustNotNull]}
              ></ListboxOpttype>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <ListboxProvince
                value={provinceSelected}
                onChange={onProvinceSelected}
              ></ListboxProvince>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <ListboxDistinct
                value={amphurSelected}
                provinceSelected={provinceSelected}
                onChange={onAmphurSelected}
              ></ListboxDistinct>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={6}>
              <ListboxSubdistinct
                amphurSelected={amphurSelected}
                value={tambolSelected}
                onChange={onTambolSelected}
              ></ListboxSubdistinct>
            </GridItem> */}
          </GridContainer>
          <br /> <br /> <br />
          <GridContainer
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem>
              <Button onClick={e => setData([])}>ยกเลิก</Button>
              <Button color="info" onClick={onClickSearch}>
                ค้นหา
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <br /> <br /> <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <MaterialTable
            data={data}
            columns={columns}
            title={"รายชื่อองค์กรปกครองส่วนท้องถิ่น"}
            options={options_table}
            components={{
              Container: props => <Paper {...props} elevation={0} />
            }}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};
export default MsOptUplevel;
