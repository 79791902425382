import React, { forwardRef } from "react";

// Material Icon
//import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Button from "components/CustomButtons/Button.js";
//import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
//import DeleteOutline from "@material-ui/icons/DeleteOutline";
//import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
// Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LastPage from "@material-ui/icons/LastPage";
// Overiding Components
import MTableAction from "./patches/MTableAction";
import MTableToolbar from "./patches/MTableToolbar";
import MUITable from "material-table";
import Remove from "@material-ui/icons/Remove";
//import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import exportCsv from "components/Table/Utils/ExportCsv";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
// Material Core
//import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

const MaterialTable = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      marginTop: theme.spacing(2),

      "& > .fa": {
        margin: theme.spacing(2)
      },
      "& td": {
        "& button.MuiIconButton-root": {
          marginTop: theme.spacing(1)
        },
        "& button.MuiIconButton-root:hover": {
          backgroundColor: "transparent"
        }
      }
    },
    toolbar: {
      "& button.MuiButton-root": {
        marginLeft: "8px"
      },
      "& button.MuiIconButton-root:hover": {
        backgroundColor: "transparent"
      }
    }
  }));

  const classes = useStyles();

  const withOveridingIconButton = icon => {
    return {
      isOveridingButton: true,
      render: icon
    };
  };

  const tableIcons = {
    Check: withOveridingIconButton(
      forwardRef((props, ref) => (
        <Button size="sm" color="info" {...props} ref={ref}>
          ตกลง
        </Button>
      ))
    ),
    Clear: withOveridingIconButton(
      forwardRef((props, ref) => (
        <Button size="sm" {...props} ref={ref}>
          ยกเลิก
        </Button>
      ))
    ),
    Edit: withOveridingIconButton(
      forwardRef((props, ref) => (
        <Button size="sm" color="warning" {...props} ref={ref}>
          แก้ไข
        </Button>
      ))
    ),
    Add: withOveridingIconButton(
      forwardRef((props, ref) => (
        <Button size="sm" color="success" {...props} ref={ref}>
          เพิ่ม
        </Button>
      ))
    ),
    Delete: withOveridingIconButton(
      forwardRef((props, ref) => (
        <Button size="sm" color="danger" {...props} ref={ref}>
          ลบ
        </Button>
      ))
    ),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Export: withOveridingIconButton(
      forwardRef((props, ref) => (
        <Button justIcon round color="success" {...props} ref={ref}>
          <FontAwesomeIcon icon={faFileExcel} />
        </Button>
      ))
    ),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const local = {
    body: {
      editRow: {
        deleteText: "ยืนยันการลบข้อมูล",
        cancelTooltip: "ยกเลิก",
        saveTooltip: "ยืนยัน"
      },
      deleteTooltip: "ลบ",
      addTooltip: "เพิ่ม",
      emptyDataSourceMessage: "ยังไม่มีข้อมูล ณ ขณะนี้"
    },
    header: {
      actions: ""
    },
    toolbar: {
      //  searchPlaceholder: "ค้นหาเขตเลือกตั้ง",
      exportName: "ดึงข้อมูลเป็นcsv",
      exportAriaLabel: "นำข้อมูลออก",
      exportTitle: "นำข้อมุลออก",
      nRowsSelected: "เลือกทั้งหมด {0} อปท."
    },
    pagination: {
      nextTooltip: "หน้าถัดไป",
      previousTooltip: "หน้าก่อนหน้า",
      lastTooltip: "หน้าสุดท้าย",
      firstTooltip: "หน้าแรก",
      labelRowsSelect: "แถว",
      labelDisplayedRows: "{from}-{to} จาก {count}"
    }
  };

  const sumLocalization = Object.assign(local, props.localization || {});

  const optionSet = {
    actionsColumnIndex: -1,
    sorting: true,
    addRowPosition: "first",
    showFirstLastPageButtons: true,
    exportButton: true,
    exportAllData: true,
    exportCsv: (columns, data) => {
      exportCsv(columns, data, props.title);
    },
    pageSize: 10,
    paging: true,
    pageSizeOptions: [10, 20, 100, 200, 500],
    doubleHorizontalScroll: true,
    headerStyle: {
      backgroundColor: "#ddd",
      color: "#000",
      zIndex: 99,
      font: "Athiti",
      position: "sticky",
      top: 0,
      // minWidth: "120%",

      textAlign: "center"
    },
    maxBodyHeight: "650px",
    rowStyle: {
      color: "#000",
      fontWeight: 400
    }
  };

  const initColumn = [
    {
      title: "ลำดับ",
      field: "rowId",
      export: true,
      //editable: "onAdd",
      editable: "never",
      cellStyle: {
        minWidth: "20px",
        textAlign: "center"
      },
      render: rowData => {
        if (
          rowData &&
          rowData.tableData !== undefined &&
          rowData.tableData.id !== undefined
        )
          return rowData.tableData.id + 1;
      }
    }
  ];

  const sumOption = Object.assign(optionSet, props.options);

  let columns = [];

  if (
    props.enableInitColumns === undefined ||
    props.enableInitColumns === true
  ) {
    columns = [...initColumn, ...props.columns];
  } else {
    columns = props.columns;
  }

  const overidingComponents = {
    Action: props => <MTableAction {...props} />,
    Toolbar: props => (
      <div className={classes.toolbar}>
        <MTableToolbar {...props} />
      </div>
    )
    // Cell: props => <MTableCell {...props} />
  };
  return (
    <div className={classes.root}>
      <MUITable
        icons={
          props.icons ? Object.assign(tableIcons, props.icons) : tableIcons
        }
        components={
          props.components
            ? Object.assign(overidingComponents, props.components)
            : overidingComponents
        }
        title={props.title || "หัวข้อ"}
        columns={columns}
        data={props.data || []}
        editable={props.editable || {}}
        actions={props.actions || []}
        options={sumOption}
        localization={sumLocalization}
        detailPanel={props.detailPanel}
        initialFormData={props.initialFormData}
        tableRef={props.tableRef}
        onSelectionChange={props.onSelectionChange}
      />
    </div>
  );
};

export default MaterialTable;
