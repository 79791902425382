import React, { useContext, useEffect, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import Datepicker from "components/Common/Datepicker";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxForm6Type from "components/Common/ListboxForm6Type";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
//import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxPositionFollowVoteDate from "components/Common/ListboxPositionFollowVoteDate";
import ListboxVotedate from "components/Common/ListboxVotedate";
import Slide from "@material-ui/core/Slide";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Success from "components/Typography/Success.js";
import Typography from "@material-ui/core/Typography";
import { VoteDateContext } from "contexts/VoteDate";
import { editOptForm } from "contexts/API/OPT/Form61";
// Material Core
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModaleditForm = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    },
    backButton: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }));

  const { sweetAlerts } = useSweetAlert();
  const [activeStep] = React.useState(0);
  const [sinceDate] = useState(null);
  const [noticeDate, setNoticeDate] = useState(null);

  const {
    onOptVoteTypeSelected,
    optVoteTypeSelected,
    provinceSelected,
    optTypeSelected,
    amphurSelected,
    tambolSelected,
    optSelected,
    positionFollowVoteDateSelected,
    onPositionFollowVoteDateSelected,
    onOptSelected,
    votedateSelected,
    onVotedateSelected,
    getOptTypeSelectedLists,
    form6TypeSelected,
    onForm6TypeSelected
  } = useContext(VoteDateContext);
  //const optVoteIdRef = createRef();
  // const onChange = newValue => {
  //   setData({ ...data, form6TypeSelected: newValue.id });
  // };
  const { showeditModal, closeeditModal, children, onaddSuccess } = props;
  useEffect(() => {
    setNoticeDate(children.noticeDate);
  }, [props.children]);
  const classes = useStyles();

  const steps = getSteps();

  const handleSubmit = () => {
    editOptForm(
      children,
      optVoteTypeSelected,
      form6TypeSelected,
      sinceDate,
      noticeDate
    )
      .then(result => {
        sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
        closeeditModal();
        addSucess(1);
        // setData([...dataUpdate]);
      })
      .catch(err => {
        console.log(err);
        sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.response.data.desc);
      });
  };
  const addSucess = form6TypeSelected => {
    closeeditModal();
    console.log(form6TypeSelected);
    onaddSuccess(form6TypeSelected);
  };
  function getSteps() {
    return ["แก้ไขแบบฟอร์มมติที่ประชุม"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxForm6Type
                disabled
                positionTypeSelected={positionFollowVoteDateSelected}
                defaultValue={{ id: children.frm6typeId }}
                onChange={onForm6TypeSelected}
              ></ListboxForm6Type>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Datepicker
                id="noticeDate"
                label="วันที่ประกาศ"
                value={noticeDate}
                onChange={date => setNoticeDate(date)}

              //     handleDateChange(date, "dateRegister")
              ></Datepicker>
            </GridItem>
            {/* <GridItem xs={12} sm={4} md={4}>
              <Datepicker
                id="sinceDate"
                label="วันที่มีผลบังคับ"
                value={sinceDate}
                onChange={date => setSinceDate(date)}

                //     handleDateChange(date, "dateRegister")
              ></Datepicker>
            </GridItem> */}
          </GridContainer>
        );

      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <div className={showeditModal ? "modal" : "hide"}>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        maxWidth="md"
        open={showeditModal}
        fullWidth={true}
        keepMounted
        transition={Transition}
        onClose={closeeditModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          className={classes.titlemodal}
        >
          แก้ไขประเภทแบบ
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <ListboxOptVotetype
                disabled
                value={optVoteTypeSelected}
                onChange={onOptVoteTypeSelected}
              ></ListboxOptVotetype>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <ListboxOpts
                disabled
                value={optSelected}
                getOptTypeSelectedLists={getOptTypeSelectedLists}
                optTypeSelected={optTypeSelected}
                provinceSelected={provinceSelected}
                amphurSelected={amphurSelected}
                tambolSelected={tambolSelected}
                onChange={onOptSelected}
              ></ListboxOpts>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <ListboxVotedate
                disabled
                value={votedateSelected}
                optSelected={optSelected}
                optVoteTypeSelected={optVoteTypeSelected}
                onChange={onVotedateSelected}
              ></ListboxVotedate>
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
              <ListboxPositionFollowVoteDate
                disabled
                value={positionFollowVoteDateSelected}
                optSelected={optSelected}
                optVoteTypeSelected={optVoteTypeSelected}
                votedateSelected={votedateSelected}
                onChange={onPositionFollowVoteDateSelected}
              ></ListboxPositionFollowVoteDate>
              {/*    <ListboxPositionType
                disabled
                value={positionTypeSelected}
                onChange={onPostionTypeSelected}
              ></ListboxPositionType> */}
            </GridItem>
          </GridContainer>
          <br></br>
          <hr></hr>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography component={"span"}>
                  <Success
                    style={{
                      paddingTop: "20px",
                      textAlign: "center",
                      fontSize: "30px",
                      fontWeight: "bolder"
                    }}
                  >
                    บันทึกข้อมูลเรียบร้อย
                  </Success>
                </Typography>
                {/* <Button onClick={handleReset}>ยกเลิก</Button> */}
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions} component={"span"}>
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <GridContainer
                    direction="column"
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        //  disabled={form6TypeSelected !== null ? false : true}
                        variant="contained"
                        color="success"
                        onClick={handleSubmit}
                      >
                        บันทึก
                      </Button>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            )}
          </div>
          <br></br>
          <br></br>
          <hr></hr>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={closeeditModal}>ปิดหน้าต่าง</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModaleditForm;
