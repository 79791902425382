import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
//-------------roles-----------------//

export function getRoles() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/rolesList`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getUserInRole(roleId, provinceId, userName) {
  const isAuth = cookies.get("userInfo");
  let query = `?`;
  if (userName) {
    query += `userName=${userName}`;
  }
  const url = `${END_POINT_URL}/um/role/${roleId}/province/${provinceId}${query}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editUserInRole(roleId, data) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/setUsersInRole`;

  const body = {
    roleId: roleId,
    userIdList: data
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getPageInRole(roleId, systemId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/pageList/role/${roleId}?systemId=${systemId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editPageInRole(roleId, data) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/setPageInRole`;

  const body = {
    roleId: roleId,
    pageIdList: data
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editRoles(roleId, roleName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/roles/${roleId}`;

  const body = {
    roleName: roleName
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addRoles(roleName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/roles/`;
  const body = {
    roleName: roleName
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delRoles(roleId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/roles/${roleId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getUserDetail(userId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/user/${userId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addUser(data) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/user`;
  let body = data;
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editUser(detail, data) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/user/${detail}`;
  let body = {
    userName: data.userName,
    firstName: data.firstName,
    email: data.email,

    lastName: data.lastName,
    provinceId: data.provinceId.id,
    isCenter: data.isCenter,
    titleId: data.titleId.id,

    citizenId: data.citizenId,
    telephone: data.telephone,

    msOptId: data.msOptId ? data.msOptId.id : 0
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getUsers(query) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/usersList${query}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function delUsers(userId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/user/${userId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function resetPass(userId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/resetPassword/${userId}`;
  let body = {};
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
