import React, { useContext, useState } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import DatepickerVotedate from "components/Common/Datepicker";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxSuspendVoteType from "components/Common/ListboxSuspendVoteType";
import Slide from "@material-ui/core/Slide";
import { SuspendContext } from "contexts/Suspend";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalReport = props => {
  const {
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,

    amphurSelected,
    suspendVoteTypeSelected,
    onSuspendVoteTypeSelected
  } = useContext(SuspendContext);

  const { sweetAlerts } = useSweetAlert();
  const { showModalReport, closeModalReport } = props;
  const classes = useStyles();
  //const [status, setStatus] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isFromValid, setIsFromValid] = useState(true);
  const [isToValid, setIsToValid] = useState(true);
  /* const handleStatus = event => {
    setStatus(event.target.value);
  }; */
  const getQuery = () => {
    let endPoint = "";

    if (["1", "2"].includes(props.indexReport)) {
      endPoint += `&vote_type_id=${suspendVoteTypeSelected !== null ? suspendVoteTypeSelected.id : 0
        }&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&amphur_id=${amphurSelected !== null ? amphurSelected.id : 0}`;
    }

    if (["3"].includes(props.indexReport)) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate}`;
    }

    return endPoint;
  };
  const handleSubmitReport1 = e => {
    e.preventDefault();

    if (["1", "2"].includes(props.indexReport)) {
      // if (provinceSelected === null) {
      //   sweetAlerts.warning("กรุณาระบุจังหวัด");
      //   return;
      // }
      // if (amphurSelected === null) {
      //   sweetAlerts.warning("กรุณาระบุอำเภอ");
      //   return;
      // }
      // if (tambolSelected === null) {
      //   sweetAlerts.warning("กรุณาระบุตำบล");
      //   return;
      // } else {
      const query = getQuery();

      window.open(`${props.linkReport}${query}`, "_blank");
      //  }
    }
    if (["3"].includes(props.indexReport)) {
      // if (provinceSelected === null) {
      //   sweetAlerts.warning("กรุณาระบุจังหวัด");
      //   return;
      // }
      if (fromDate === null || toDate === null) {
        sweetAlerts.warning("กรุณาระบุช่วงวันเริ่มต้น/สิ้นสุด");
      }
      if (fromDate === null || toDate === null) {
        sweetAlerts.warning("กรุณาระบุช่วงวันเริ่มต้น/สิ้นสุด");
      } else {
        const query = getQuery();
        //  console.log(query);
        window.open(`${props.linkReport}${query}`, "_blank");
      }
    }
  };

  return (
    <div className={showModalReport ? "modal" : "hide"}>
      <Dialog
        open={showModalReport}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModalReport}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>{props.titleReport}</h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {" "}
          {["1", "2"].includes(props.indexReport) ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <ListboxSuspendVoteType
                    value={suspendVoteTypeSelected}
                    onChange={onSuspendVoteTypeSelected}
                  ></ListboxSuspendVoteType>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <ListboxProvince
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <ListboxDistinct
                    provinceSelected={provinceSelected}
                    onChange={onAmphurSelected}
                  ></ListboxDistinct>
                </GridItem>
                {/* <GridItem xs={4} sm={4} md={4}>
                  <ListboxSubdistinct
                    required={true}
                    amphurSelected={amphurSelected}
                    value={tambolSelected}
                    onChange={onTambolSelected}
                  ></ListboxSubdistinct>
                </GridItem> */}
              </GridContainer>
              {/* <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <DatepickerVotedate
                    id="begin"
                    label="ข้อมูล ณ วันที่"
                    value={fromDate}
                    valid={isFromValid}
                    onChange={date => {
                      if (date) {
                        let arr = date.format().split("T");
                        setFromDate(arr[0]);
                        setIsFromValid(false);
                      }
                      setIsFromValid(true);
                    }}
                  ></DatepickerVotedate>
                </GridItem>
              </GridContainer> */}
            </>
          ) : (
            ""
          )}
          {["3"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <ListboxProvince
                  value={provinceSelected}
                  onChange={onProvinceSelected}
                ></ListboxProvince>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <DatepickerVotedate
                  id="begin"
                  label="ตั้งแต่วันที่"
                  value={fromDate}
                  valid={isFromValid}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setFromDate(arr[0]);
                      setIsFromValid(false);
                    } else {
                      setFromDate(null);
                      setIsFromValid(true);
                    }
                  }}
                ></DatepickerVotedate>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <DatepickerVotedate
                  id="endDate"
                  label="ถึงวันที่"
                  value={toDate}
                  valid={isToValid}
                  minDate={fromDate}
                  disabled={fromDate === null ? true : false}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setToDate(arr[0]);
                      setIsToValid(false);
                    } else {
                      setToDate(null);
                      setIsToValid(true);
                    }
                  }}
                ></DatepickerVotedate>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          <br></br>
          <GridContainer
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem xs={12} sm={12} md={6}>
              <Button onClick={closeModalReport}>ปิดหน้าต่าง</Button>
              <Button color="success" onClick={handleSubmitReport1}>
                ออกรายงาน
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalReport;
