/* core components */
import React, { useContext, useState } from "react";
// Context APIs
import { deleteOptArea, getAllOptAreaByOptId } from "contexts/API/OPT/OptArea";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxOpttype from "components/Common/ListboxOpttype.js";
import ListboxProvince from "components/Common/ListboxProvince.js";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import ModaladdArea from "components/pages/opt/MsOpt/ModaladdArea";
import MustNotNull from "utils/Validators/MustNotNull";
/* custom components */
import Paper from "@material-ui/core/Paper";
import { VoteDateContext } from "contexts/VoteDate";
import { useSweetAlert } from "components/Alert/SweetAlert";

const OptArea = props => {
  const [loading, setLoading] = useState(false);
  const [, setClickSearch] = useState(null);

  const { sweetAlerts } = useSweetAlert();

  const {
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,
    tambolSelected,
    onOptSelected,
    optSelected,
    listboxOptSelected,
    onListboxOptSelected
  } = useContext(VoteDateContext);
  const [, setData] = useState([]);
  const [optAreaData, setOptAreaData] = useState([]);
  const [show, setShow] = useState(false);
  const [titleModal, settitleModal] = useState(null);

  const [children, setchildren] = useState(null);
  const [action, setAction] = useState(null);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  const addSuccess = optName => {
    if (optName) {
      setLoading(false);
      setClickSearch(null);
      onClickSearch();
    }
  };
  const onClickSearch = e => {
    setClickSearch(null);
    if (listboxOptSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภท อปท.");

      return;
    }

    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");

      return;
    }
    if (optSelected !== null) {
      setLoading(true);
      getAllOptAreaByOptId(optSelected.id)
        .then(result => {
          if (result.data.data.length > 0) {
            setData(result.data.data[0]);

            setLoading(false);
            setClickSearch(true);
            if (result.data.data[0].opt_area !== undefined) {
              if (result.data.data[0].opt_area.length !== 0) {
                const arr2 = result.data.data[0].opt_area;

                let newArr2 = arr2.map(v => ({
                  ...v,
                  ms_opt_id: optSelected.id,
                  province_name: result.data.data[0].province_name,
                  ms_opt_sub_type: result.data.data[0].ms_opt_sub_type,
                  opt_name: result.data.data[0].opt_name,
                  tambol_id: result.data.data[0].tambol_id,
                  ms_amphur_id: result.data.data[0].amphur_id,
                  optamphur_name: result.data.data[0].amphur_name,
                  is_checked: 1
                }));

                setOptAreaData(newArr2);
              } else {
                setOptAreaData([
                  {
                    province_name: result.data.data[0].province_name,
                    optamphur_name: result.data.data[0].amphur_name,
                    ms_opt_sub_type: result.data.data[0].ms_opt_sub_type,
                    opt_name: result.data.data[0].opt_name,
                    amphur_name: "",
                    area_no: "0",
                    num_member: "0",
                    num_station: "0",
                    is_checked: 0
                  }
                ]);
              }
            }
          } else {
            setData([]);
            setOptAreaData([]);
            setLoading(false);
          }
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
        });
    } else {
      sweetAlerts.warning("กรุณาเลือก อปท.");
    }
  };

  const columns = [
    {
      field: "opt_area_id",
      export: false,
      hidden: true
    },
    {
      field: "ms_opt_id",
      export: false,
      hidden: true
    },
    {
      field: "province_name",
      title: "จังหวัด",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "optamphur_name",
      title: "อำเภอ",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "ms_opt_sub_type",
      title: "ประเภท อปท.",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "opt_name",
      title: "ชื่อ อปท.",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "amphur_name",
      title: "เขตอำเภอ (เฉพาะ อบจ.)",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "area_no",
      title: "เขตเลือกตั้ง",
      cellStyle: {
        minWidth: "50px",
        textAlign: "center"
      }
    },
    {
      field: "num_member",
      title: "จำนวนสมาชิก",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "num_station",
      title: "จำนวนหน่วย",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "is_checked",
      title: "is_checked",
      hidden: "true"
    },
    {
      field: "",
      cellStyle: {
        minWidth: "70px"
      },
      render: rowData => {
        return (
          <Button
            size="sm"
            color="warning"
            style={rowData.is_checked === 0 ? { display: "none" } : {}}
            // style={rowData.is_checked === 0 ? {display:"hidden":"none"}

            onClick={() => {
              openModal(true);
              settitleModal("แก้ไขข้อมูลเขต");
              setchildren(rowData);
              setAction("edit");
            }}
          >
            แก้ไข
          </Button>
          /* <Button
              size="sm"
              color="danger"
              onClick={() => {
                sweetAlerts.danger(
                  "ยืนยันการลบข้อมูล",
                  `คุณต้องการลบข้อมูล เขตที่ ${rowData.area_no} ${
                    rowData.amphur_name ? " อำเภอ " + rowData.amphur_name : ""
                  }`,
                  () => {
                    confirmDeleted(rowData);
                  },
                  true
                );
              }}
            >
              ลบ
            </Button> */
        );
      }
    }
  ];

  const options_table = {};

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br /> <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <ListboxOpttype
                value={listboxOptSelected}
                onChange={onListboxOptSelected}
                required={true}
                validators={[MustNotNull]}
              ></ListboxOpttype>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <ListboxProvince
                value={provinceSelected}
                onChange={onProvinceSelected}
                required={true}
                validators={[MustNotNull]}
              ></ListboxProvince>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <ListboxDistinct
                value={amphurSelected}
                provinceSelected={provinceSelected}
                onChange={onAmphurSelected}
              ></ListboxDistinct>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {/* <GridItem xs={12} sm={12} md={4}>
              <ListboxSubdistinct
                amphurSelected={amphurSelected}
                value={tambolSelected}
                onChange={onTambolSelected}
              ></ListboxSubdistinct>
            </GridItem> */}
            <GridItem xs={12} sm={12} md={4}>
              <ListboxOpts
                value={optSelected}
                listboxOptSelected={listboxOptSelected}
                provinceSelected={provinceSelected}
                amphurSelected={amphurSelected}
                tambolSelected={tambolSelected}
                onChange={onOptSelected}
                required={true}
                validators={[MustNotNull]}
              ></ListboxOpts>
            </GridItem>
          </GridContainer>
          <br /> <br /> <br />
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem xs={6} sm={6} md={6}>
              <Button
                color="success"
                onClick={() => {
                  openModal(true);
                  settitleModal("เพิ่มเขต");
                  setchildren(null);
                  setAction("add");
                }}
              >
                เพิ่มเขต
              </Button>
            </GridItem>
            <GridItem>
              <Button>ยกเลิก</Button>
              <Button color="info" onClick={onClickSearch}>
                ค้นหา
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <br></br> <br></br> <br></br>
      {loading === true ? (
        <LoadingData />
      ) : (
        <MaterialTable
          data={optAreaData}
          columns={columns}
          // enableInitColumns={false}
          title={"ทะเบียนเขตเลือกตั้ง"}
          options={options_table}
          editable={{
            isDeleteHidden: rowData => rowData.is_checked === 0,
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                const dataDelete = [...optAreaData];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);

                deleteOptArea(oldData.ms_opt_id, oldData.opt_area_id, "-")
                  //deleteOpt(oldData.ms_opt_id)
                  .then(result => {
                    sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                    dataDelete.splice(index, 1);
                    setOptAreaData(dataDelete);
                    resolve();
                  })

                  .catch(err => {
                    sweetAlerts.danger(
                      `ลบข้อมูลไม่สำเร็จ`,
                      err.response.data.desc
                    );
                    reject();
                  });
              })
          }}
          components={{
            Container: props => <Paper {...props} elevation={0} />
          }}
        />
      )}
      <ModaladdArea
        closeModal={closeModal}
        show={show}
        title={titleModal}
        children={children}
        onaddSuccess={addSuccess}
        action={action}
      ></ModaladdArea>
    </div>
  );
};
export default OptArea;
