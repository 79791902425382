import CustomTabs from "components/CustomTabs/CustomTabs.js";
import React from "react";
import RptOnlineWtd from "./RptOnlineWtd";
// core components
import RptSummaryWtd from "./RptSummaryWtd";
import { WtdContextProvider } from "contexts/Wtd";
import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
const styles = {};
const useStyles = makeStyles(styles);

const ReportIndex = props => {
  const classes = useStyles();
  return (
    <div className={classes.tabcustom}>
      <WtdContextProvider>
        <CustomTabs
          styles={customTabsStyles}
          headerColor="info"
          tabs={[

            {
              tabName: " รายงาน",
              tabContent: <RptSummaryWtd />
            },
            {
              tabName: " ส่งรายงานออนไลน์",
              tabContent: <RptOnlineWtd />
            }

            //  {

            // {
            //   tabName: (
            //     <span style={{ fontSize: "16px", color: "#fff" }}>
            //       ส่งรายงานออนไลน์
            //     </span>
            //   ),
            //   tabContent: <RptOnlineWtd />
            // },
            // {
            //   tabName: (
            //     <span style={{ fontSize: "16px", color: "#fff" }}>
            //       รายงาน
            //     </span>
            //   ),
            //   tabContent: <RptSummaryWtd />
            // }
          ]}
        />
      </WtdContextProvider>
    </div>
  );
};
export default ReportIndex;
