/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

/* custom components */
import getDefaultValue from "utils/DefaultValue";

const ListboxAumphurAreaofTable = props => {

  const [error] = React.useState(false);
  const [helperText] = React.useState("");

  let content = (
    <TextField disabled label="เลือกเขตอำเภอ" margin="normal" fullWidth />
  );

  const data = props.data;

  if (data && data.length > 0) {
    content = (
      <Autocomplete
        name="area"
        options={data}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(e, v) =>
          props.onChange(props.rowId, e, v)
        }
        renderInput={params => {
          return (
            <TextField
              {...params}
              error={error}
              helperText={helperText}
              label="เลือกเขตอำเภอ"
              margin="normal"
              fullWidth
            />
          );
        }}
        {...props.customProps}
      />
    );
  }

  return content;
};
export default ListboxAumphurAreaofTable;
