import React, { useState, useEffect } from "react";
import MaterialTable from "components/Table/MaterialTable";
import ListboxSuspendLawType from "components/Common/ListboxSuspendLawType";
import {
  getsuspendLawType,
  getsuspendLawSection,
  editsuspendLawSection,
  addsuspendLawSection,
  deletesuspendLawSection
} from "contexts/API/SUSPEND/common";
import { useSweetAlert } from "components/Alert/SweetAlert";
const LawSection = props => {
  const { sweetAlerts } = useSweetAlert();
  const [lawtypeList, setLawtypeList] = useState([]);
  const [data, setData] = useState([]);
  const optionsTable = { pageSize: 10 };
  useEffect(() => {
    getsuspendLawType()
      .then(r => {
        let LawtypeData = r.data.data.map(v => {
          return { id: v.suspendLawTypeId, value: v.shortName };
        });
        console.log(LawtypeData);
        setLawtypeList(LawtypeData);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      title: "suspendLawSectionId",
      field: "suspendLawSectionId",
      hidden: true
    },
    {
      title: "ประเภท พ.ร.บ",
      field: "suspendLawTypeId",
      cellStyle: {
        minWidth: "500px"
      },
      render: rowData => {
        const v = lawtypeList.find(e => e.id === rowData.suspendLawTypeId);
        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        console.log(props);
        return (
          <ListboxSuspendLawType
            rowId={0}
            defaultValue={{ id: props.rowData.suspendLawTypeId }}
            data={lawtypeList}
            onChange={(i, e, v) => {
              console.log(e.id);
              if (v !== null) {
                props.rowData.suspendLawTypeId = e.id;
              }
            }}
          />
        );
      }
    },

    {
      title: "ประเภท พ.ร.บ ย่อย",
      field: "detail",
      cellStyle: {
        minWidth: "300px"
      }
    }
  ];

  useEffect(() => {
    getsuspendLawSection()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaterialTable
      title={"จัดการมาตราตามกฎหมายของการระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
              addsuspendLawSection(newData.suspendLawTypeId, newData.detail)
                .then(result => {
                  newData.suspendLawSectionId=result.data.data.suspendLawSectionId
                  sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
                  setData([...data, newData]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
                });

              resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              editsuspendLawSection(
                oldData.suspendLawSectionId,
                newData.suspendLawTypeId,
                newData.detail
              )
                .then(result => {
                  sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                  setData([...dataUpdate]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });

              resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              deletesuspendLawSection(oldData.suspendLawSectionId)
                .then(result => {
                  sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                  setData([...dataDelete]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
                });
              resolve();
          })
      }}
    />
  );
};
export default LawSection;
