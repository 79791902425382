import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";

const cookies = new Cookies();

export function addCandidateDate(
  startApplyDate,
  endApplyDate,
  startEffectiveDate
) {
  const isAuth = cookies.get("userInfo");
  const body = {
    startApplyDate: startApplyDate,
    endApplyDate: endApplyDate,
    startEffectiveDate: startEffectiveDate
  };
  const url = `${END_POINT_URL}/inspector/cadidateDate`;
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function delCandidateDate(
  commandApplyDateId
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/cadidateDate/${commandApplyDateId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editCandidateDate(
  commandApplyDateId,
  startApplyDate,
  endApplyDate,
  startEffectiveDate,
  endEffectiveDate
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/cadidateDate/${commandApplyDateId}`;
  const body = {
    startApplyDate: startApplyDate,
    endApplyDate: endApplyDate,
    startEffectiveDate: startEffectiveDate,
    endEffectiveDate: endEffectiveDate
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getCadidateDate() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/cadidateDate`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
