import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Opttype from "./Opttype";
import OptSubtype from "./OptSubtype";
import Votetype from "./Votetype";
import PositionStatus from "./PositionStatus";
import VoteStatus from "./VoteStatus";
import PositionType from "./PositionType";
import { VoteDateContextProvider } from "contexts/VoteDate";

import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";

const styles = {
  textCenter: {
    textAlign: "center"
  },
  //tabRoot: { backgroundColor: "#0a6" },
  span: {
    backgroundColor: "#aa0",
    fontSize: "22px"
  }
};

const useStyles = makeStyles(styles);

const Index = props => {
  const classes = useStyles();
  /* 
  const addOptSchema = yup.object().shape({
    provinceId: yup.string().required("This field is required."),
    amphurId: yup.string().required("This field is required.")
  }); */

  //   const addOptForm = useForm({
  //     validationSchema: addOptSchema
  //   });

  return (
    <VoteDateContextProvider>
      <div className={classes.tabcustom}>
        <form>
          <CustomTabs
            styles={customTabsStyles}
            headerColor="primary"
            tabs={[
              {
                tabName: "ประเภท อปท.",
                tabContent: <Opttype />
              },
              {
                tabName: "ประเภท อปท. ย่อย",
                tabContent: <OptSubtype />
              },
              {
                tabName: "ประเภทการเลือกตั้ง",
                tabContent: <Votetype />
              },
              /* {
                tabName: "สถานะการเลือกตั้ง",
                tabContent: <VoteStatus />
              }, */
              {
                tabName: "สถานะ/สาเหตุตำแหน่ง",
                tabContent: <PositionStatus />
              },
              {
                tabName: "ประเภทตำแหน่ง",
                tabContent: <PositionType />
              }
            ]}
          />
        </form>
      </div>
    </VoteDateContextProvider>
  );
};

export default Index;
