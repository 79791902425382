import React, { useContext, useEffect, useState } from "react";
import {
  deleteVoteDateByDate,
  //deleteVoteDateById,
  getVoteDateDetail2, getVoteDateDetailByVoteId
} from "contexts/API/VoteDate/VoteDate";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
//import ListboxVotestatus from "components/Common/ListboxVotestatus";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import ConfirmDelete from "../../../Dialog/ConfirmDelete";
import Cookies from "universal-cookie";
import DatepickerVotedate from "components/Common/Datepicker";
// core components
import GridContainer from "../../../Grid/GridContainer.js";
import GridItem from "../../../Grid/GridItem.js";
import HandelError from "utils/HandleError";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import TableComponent from "components/Table/MaterialTable";
/* import EditeVoteDate from "./EditVoteDate";
import AddVoteDate from "./AddVoteDate";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"; */
//import { UserInfoContext } from "contexts/users";
import { VoteDateContext } from "contexts/VoteDate";
/* import ListboxOpttype from "components/Common/ListboxOpttype";
import { useFetch } from "../../../../hooks/useFetch"; */
import conf from "../../../../config/env.conf.js";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const cookies = new Cookies();

const ManageVoteDate = ({ pageProps, setPageProps }) => {
  const { sweetAlerts } = useSweetAlert();

  const {
    optVoteTypeSelected,
    provinceSelected,
    optTypeSelected,
    amphurSelected,
    tambolSelected,
    optSelected,
    startDate,
    endDate,
    setStartDate,
    setEndDate,

    onOptVoteTypeSelected,
    onProvinceSelected,
    onOptTypeSelected,
    onAmphurSelected,

    onOptSelected,

    //uttils
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);
  //const { setUserInfo, userInfo } = useContext(UserInfoContext);

  const useStyles = makeStyles({
    buttoncustom: {
      fontSize: 14,
      fontWeight: 500
      //color: props => props.color
    }
  });

  const classes = useStyles();

  //local state
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [per_page, setper_page] = useState(50);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const addVoteDateHandler = () => {
    sweetAlerts.warning(
      "แจ้งเตือน",
      "กรณีเลือกตั้งแทนตำแหน่งที่ว่าง ให้บันทึกเมนูพ้นจากตำแหน่งเสียก่อน",
      () => {
        setPageProps({ active: "AddVoteDate", props: null });
      },
      true
    );
  };

  const editHandler = async e => {
    let idx = e.currentTarget.value;
    let row = data[idx];

    const voteDateStr = row.voteDate.split("T")[0];
    let voteData = {};
    try {
      const voteDataRes = await getVoteDateDetail2(row["optId"], voteDateStr, row["optVoteTypeId"])
      let res = voteDataRes.data.data;
      res.map(v => {
        if (v.optPositionTypeId === 1) {
          voteData["pres"] = v;
        } else {
          voteData["mem"] = v;
        }
        return voteData;
      });

      voteData.provinceSelected = row["provinceId"];
      voteData.amphurSelected = row["amphurId"];
      voteData.tambolSelected = row["tambol_id"];
      voteData.optTypeSelected = row["optSubTypeId"];
      voteData.generalOptVoteId = res[0].generalOptVoteId;
      voteData.optId = res[0].optId;
      voteData.optVoteId = res[0].optVoteId;
      voteData.voteDate = row.voteDate;
      voteData.optVoteTypeId = res[0].optVoteTypeId;
      voteData.optVoteTypeSelected = {
        id: row["optVoteTypeId"]
      };

      if (!res[0].generalOptVoteId) {
        voteData.generalOptVoteVoteTypeId = 1
      } else {
        const voteDateDetailByVoteIdRes = await getVoteDateDetailByVoteId(row["optId"], res[0].generalOptVoteId)
        res = voteDateDetailByVoteIdRes.data.data;
        voteData.generalOptVoteVoteTypeId = res[0].optVoteTypeId
      }

      setPageProps({
            active: "EditVoteDate",
            props: voteData
      });
    } catch (err) {
      sweetAlerts.danger("ขออภัยไม่สามารถแก้ไขข้อมูลได้", err.response.data.desc);
    }
  };

  const deleteHandler = e => {
    let idx = e.currentTarget.value;
    let row = data[idx];

    sweetAlerts.danger(
      "ยืนยันการลบข้อมูล",

      "หากยืนยันแล้ว จะไม่สามารถกู้คืนข้อมูลได้",
      () => {
        //console.log(row);
        // console.log(e.currentTarget.value);
        const voteDateStr = row.voteDate.split("T")[0];
        console.log("voteDateStr>>", voteDateStr);
        deleteVoteDateByDate(row["optId"], voteDateStr, row["optVoteTypeId"])
          .then(response => {
            //let newData = data.filter(v => v.optVoteId !== row.optVoteId);
            let newData = data.filter(v => v.voteDate !== row.voteDate);

            setData(newData);
            sweetAlerts.success("ลบข้อมูลเรียบร้อย");
          })
          .catch(err => {
            HandelError(err);
            sweetAlerts.danger("ขออภัยไม่สามารถลบข้อมูลได้");
          });
      },
      true
    );
  };
  const [showComfirmDialog, setShowComfirmDialog] = useState(false);
  //const openModalConfirm = () => setShowComfirmDialog(true);
  const closeModalConfirm = () => setShowComfirmDialog(false);
  const [titleModal] = useState();
  const [children] = useState();

  // TODO:
  const searchValidator = () => {
    return true;
  };

  // TODO:
  const customVoteDate = old => {
    let d = new Date(old);
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric"
    };
    return d.toLocaleDateString("th-TH", options);
  };

  const getOptVoteListUrl = () => {
    //   let endPoint = `http://ectpl-be.scd-dga.com/api/v1/opt/vote/list?per_page=50&page=1&optSubTypeId=[5]&voteType=2&optId=1259`;
    //alert(optVoteTypeSelected);
    if (optSelected === null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
    } else if (optVoteTypeSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภทประเภทการเลือกตั้ง");
    } else {
      let endPoint = conf.END_POINT_URL + "/opt/vote/list?";
      endPoint += `per_page=${per_page}&page=${page}`;

      let optTypes = getOptTypeSelectedLists();

      if (optTypes.length > 0) {
        endPoint += `&optSubTypeId=[${optTypes.toString()}]`;
      }

      if (optVoteTypeSelected !== null) {
        endPoint += `&voteType=[${optVoteTypeSelected.id}]`;
      }

      if (optSelected !== null) {
        endPoint += `&optId=${optSelected.id}`;
      } else if (tambolSelected !== null) {
        endPoint += `&tambolId=${tambolSelected.id}`;
      } else if (amphurSelected !== null) {
        endPoint += `&amphurId=${amphurSelected.id}`;
      } else if (provinceSelected !== null) {
        endPoint += `&provinceId=${provinceSelected.id}`;
      }

      if (startDate !== null) {
        endPoint += `&beginDate=${startDate.toISOString().split("T")[0]}`;
      }
      if (endDate !== null) {
        endPoint += `&endDate=${endDate.toISOString().split("T")[0]}`;
      }
      return endPoint;
    }
  };

  useEffect(() => {
    if (pageProps.action === "cancel" || pageProps.action === "editsuccess") {
      if (optSelected !== null && optVoteTypeSelected !== null) {
        searchHandler();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps.action]);
  const searchHandler = () => {
    if (searchValidator()) {
      setLoading(true);
      const isAuth = cookies.get("userInfo");
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + isAuth.token
        }
      };

      //alert(getOptVoteListUrl());
      var optVoteListValue=getOptVoteListUrl();
      if(optVoteListValue!=undefined){
      fetch(optVoteListValue, options)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(json => {
          let dataCustom = setData(
            json.data.reduce((a, c) => {
              let x = a.find(e => e.voteDate === c.voteDate);
              if (!x) a.push(Object.assign({}, c));
              else x.optPositionTypeName += "/" + c.optPositionTypeName;
              return a;
            }, [])
          );

          setCount(dataCustom);

          // setData(
          //   dataCustom.map((value, idx, arrays) => {
          //     return {
          //       ...arrays[idx],
          //       voteDateTH: customVoteDate(value.voteDate)
          //       //  customOptName: `${value["ms_opt.ms_opt_sub_type.optSubTypeName"]}${value["ms_opt.optName"]}`
          //     };
          //   })
          // );
          setLoading(false);
        })
        .catch(err => {
          HandelError(err);

          setLoading(false);
        });
     } //optVoteListValue!=undefined
    }
  };

  const columns = [
    {
      field: "optVoteTypeName",
      title: "ประเภทการเลือกตั้ง",
      cellStyle: {
        minWidth: "250px"
      }
    },
    {
      field: "optPositionTypeName",
      title: "ตำแหน่ง"
    },
    {
      field: "provinceId",
      title: "จังหวัด",
      hidden: true
    },
    {
      field: "ms_opt.ms_opt_sub_type.ms_opt_sub_type_id",
      title: "จังหวัด",
      hidden: true
    },
    {
      field: "tambolId",
      title: "จังหวัด1",
      hidden: true
    },
    {
      field: "amphurId",
      title: "จังหวัด2",
      hidden: true
    },
    {
      field: "provinceName",
      title: "จังหวัด"
    },
    {
      field: "amphurName",
      title: "อำเภอ"
    },

    {
      field: "optSubTypeName",
      title: "ประเภท อปท."
    },
    {
      field: "optName",
      title: "ชื่อ อปท."
    },
    {
      field: "voteDate",
      title: "วันเลือกตั้ง",
      render: rowData => {
        return customVoteDate(rowData.voteDate);
      },
      cellStyle: {
        minWidth: "180px"
      }
    },

    {
      field: "",
      align: "center",
      cellStyle: {
        minWidth: "180px"
      },
      render: rowData => {
        return (
          <div>
            <Button
              value={rowData.tableData.id}
              size="sm"
              color="warning"
              onClick={editHandler}
            >
              แก้ไข
            </Button>
            <Button
              value={rowData.tableData.id}
              size="sm"
              color="danger"
              onClick={deleteHandler}
            >
              ลบ
            </Button>
          </div>
        );
      }
    }
  ];

  const changePage = (page, per_page, order) => {
    setPage(page);
    setper_page(per_page);
    searchHandler();
  };

  const options_table = {
    responsive: "vertical",
    selectableRows: "none",
    filters: true,
    rowsPerPage: per_page,
    //serverSide: true,
    count: count,
    page: page,

    onTableChange: (action, tableState) => {
      // console.log(action, tableState);
      if (action === "changePage") {
        changePage(
          tableState.page,
          tableState.rowsPerPage,
          tableState.sortOrder
        );
      }
    }
  };

  return (
    <>
      <Card>
        <CardHeader color="primary" text>
          <CardText color="primary">
            <h4 className={classes.cardTitle}>จัดการวันเลือกตั้ง</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CheckboxOpttype
                      optTypeSelected={optTypeSelected}
                      onOptTypeSelected={onOptTypeSelected}
                      required={true}
                    ></CheckboxOpttype>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <ListboxOptVotetype
                      value={optVoteTypeSelected}
                      onChange={onOptVoteTypeSelected}
                      required={true}
                    ></ListboxOptVotetype>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <ListboxProvince
                      value={provinceSelected}
                      onChange={onProvinceSelected}
                      required={true}
                      validators={[MustNotNull]}
                    ></ListboxProvince>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <ListboxDistinct
                      provinceSelected={provinceSelected}
                      value={amphurSelected}
                      onChange={onAmphurSelected}
                    ></ListboxDistinct>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {/* <GridItem xs={12} sm={12} md={4}>
                    <ListboxSubdistinct
                      amphurSelected={amphurSelected}
                      value={tambolSelected}
                      onChange={onTambolSelected}
                    ></ListboxSubdistinct>
                  </GridItem> */}

                  <GridItem xs={12} sm={12} md={4}>
                    <ListboxOpts
                      value={optSelected}
                      getOptTypeSelectedLists={getOptTypeSelectedLists}
                      optTypeSelected={optTypeSelected}
                      provinceSelected={provinceSelected}
                      amphurSelected={amphurSelected}
                      tambolSelected={tambolSelected}
                      onChange={onOptSelected}
                      required={true}
                      validators={[MustNotNull]}
                    ></ListboxOpts>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DatepickerVotedate
                      id="begin"
                      label="ตั้งแต่วันที่"
                      value={startDate}
                      onChange={setStartDate}
                    ></DatepickerVotedate>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DatepickerVotedate
                      id="endDate"
                      label="ถึงวันที่"
                      disabled={startDate === null ? true : false}
                      minDate={startDate}
                      value={endDate}
                      onChange={setEndDate}
                    ></DatepickerVotedate>
                  </GridItem>
                </GridContainer>
                <br /> <br />
                <GridContainer
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <GridItem xs={6} sm={6} md={6}>
                    <Button
                      className={classes.buttoncustom}
                      color="success"
                      onClick={addVoteDateHandler}
                    >
                      เพิ่มวันเลือกตั้ง
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button>ยกเลิก</Button>
                    <Button
                      color="info"
                      className={classes.buttoncustom}
                      //type="submit"
                      onClick={searchHandler}
                    >
                      ค้นหา
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </Card>
      {loading === true ? (
        <LoadingData />
      ) : (
        <TableComponent
          data={data}
          columns={columns}
          title={"รายชื่อองค์กรปกครองส่วนท้องถิ่น"}
          options={options_table}
        />
      )}

      <ConfirmDelete
        closeModalConfirm={closeModalConfirm}
        title={titleModal}
        showComfirmDialog={showComfirmDialog}
      >
        {children ? children : ""}
      </ConfirmDelete>
    </>
  );
};

export default ManageVoteDate;
