import React, { useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CHK_PERSON from "./CheckPerson";
import CHK_AFTER_5Y from "./CheckAfter5Years";
import CHK_IMPORT_FILE from "./CheckImportfile";
import { WtdContextProvider } from "contexts/Wtd";
import { UserInfoContext } from "contexts/users";
const styles = {};
const useStyles = makeStyles(styles);

const VotePublishIndex = props => {
  const { userInfo } = useContext(UserInfoContext);
  const classes = useStyles();
  return (
    <div className={classes.tabcustom}>

      <WtdContextProvider>
        {userInfo.iscenter ? (
          <CustomTabs
            headerColor="info"
            tabs={[
              {
                tabName: (
                  <span style={{ fontSize: "16px", color: "#fff" }}>
                    สืบค้นรายบุคคล
                  </span>
                ),
                tabContent: <CHK_PERSON />
              },
              {
                tabName: (
                  <span style={{ fontSize: "16px", color: "#fff" }}>
                    สืบค้นข้อมูลแบบกลุ่ม (นำเข้าข้อมูล)
                  </span>
                ),
                tabContent: <CHK_IMPORT_FILE />
              },

              {
                tabName: (
                  <span style={{ fontSize: "16px", color: "#fff" }}>
                    สืบค้นข้อมูลแบบกลุ่ม (มาตรา 50(21))
                  </span>
                ),
                tabContent: <CHK_AFTER_5Y />
              }

            ]}
          />
        ) : (
          <CustomTabs
            headerColor="info"
            tabs={[
              {
                tabName: (
                  <span style={{ fontSize: "16px", color: "#fff" }}>
                    สืบค้นรายบุคคล
                  </span>
                ),
                tabContent: <CHK_PERSON />
              },
              {
                tabName: (
                  <span style={{ fontSize: "16px", color: "#fff" }}>
                    สืบค้นข้อมูลแบบกลุ่ม (นำเข้าข้อมูล)
                  </span>
                ),
                tabContent: <CHK_IMPORT_FILE />
              }

            ]}
          />
        )}

      </WtdContextProvider>
    </div>
  );
};
export default VotePublishIndex;
