import React, { useContext, useState } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import DatepickerVotedate from "components/Common/Datepicker";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InputLabel from "@material-ui/core/InputLabel";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxMsAreaEct from "components/Common/ListboxMsAreaEct.js";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxProvince from "components/Common/ListboxProvince";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import { VoteDateContext } from "contexts/VoteDate";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalReport02 = props => {
  const {
    areaEctSelected,
    onAreaEctSelected,

    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,

    amphurSelected,
    optVoteTypeSelected,
    onOptVoteTypeSelected,
    positionTypeSelected,
    onPostionTypeSelected,
    optSelected,
    onOptSelected,
    optTypeSelected,
    onOptTypeSelected,
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);

  const { sweetAlerts } = useSweetAlert();
  const { showModalReport2, closeModalReport2 } = props;
  const classes = useStyles();
  const [status, setStatus] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isFromValid, setIsFromValid] = useState(true);
  const [isToValid, setIsToValid] = useState(true);
  const handleStatus = event => {
    setStatus(event.target.value);
  };
  const getQuery = () => {
    let endPoint = "";

    if (
      [
        "5_1",
        "5_2",
        "5_3",
        "5_6",
        "5_4",
        "5_5",
        "6_1",
        "6_2",
        "7_1",
        "7_2",
        "8_1",
        "8_2",
        "8_3",
        "8_6",
        "8_5",
        "8_4"
      ].includes(props.indexReport)
    ) {
      let optTypes = getOptTypeSelectedLists();
      //  console.log(optTypes.length);

      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&opt_sub_type=${optTypes.length > 0 ? `${optTypes.toString()}` : 0
        }&vote_type=${optVoteTypeSelected !== null ? optVoteTypeSelected.id : 0
        }&position_type=${positionTypeSelected !== null ? positionTypeSelected.id : 0
        }&opt_id=${optSelected !== null ? optSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate}`;
    }

    if (["10_1"].includes(props.indexReport)) {
      let optTypes = getOptTypeSelectedLists();
      endPoint += `&area_ect_id=${areaEctSelected !== null ? areaEctSelected.id : 0
        }&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&opt_sub_type=${optTypes.length > 0 ? `${optTypes.toString()}` : 0}`;
    }

    if (["10_2", "10_3", "10_4"].includes(props.indexReport)) {
      let optTypes = getOptTypeSelectedLists();

      if (optTypes.length > 0) {
        endPoint += `&opt_sub_type=${optTypes.toString()}`;
      } else {
        endPoint += `&opt_sub_type=0`;
      }
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&position_type=${positionTypeSelected !== null ? positionTypeSelected.id : 0
        }&vote_type=${optVoteTypeSelected !== null ? optVoteTypeSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate}&area_ect_id=${areaEctSelected !== null ? areaEctSelected.id : 0
        }`;
    }

    if (["2_1"].includes(props.indexReport)) {
      let optTypes = getOptTypeSelectedLists();

      if (optTypes.length > 0) {
        endPoint += `&opt_sub_type=${optTypes.toString()}`;
      } else {
        endPoint += `&opt_sub_type=0`;
      }
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&amphur_id=${amphurSelected !== null ? amphurSelected.id : 0
        }&position_type=${positionTypeSelected !== null ? positionTypeSelected.id : 0
        }&opt_id=${optSelected !== null ? optSelected.id : 0
        }&position_status=${status}`;
    }
    //  console.log(endPoint);
    return endPoint;
  };
  const handleSubmitReport1 = e => {
    e.preventDefault();
    // let optTypes = getOptTypeSelectedLists();

    if (
      [
        "5_1",
        "5_2",
        "5_3",
        "5_6",
        "5_4",
        "5_5",
        "6_1",
        "6_2",
        "7_1",
        "7_2",
        "8_1",
        "8_2",
        "8_3",
        "8_6",
        "8_5",
        "8_4"
      ].includes(props.indexReport)
    ) {
      if (fromDate === null && toDate === null) {
        sweetAlerts.warning("กรุณาระบุช่วงวันเลือกตั้ง");
        return;
      } else {
        const query = getQuery();
        //  console.log(query);
        window.open(`${props.linkReport}${query}`, "_blank");
      }
    }
    if (["2_1"].includes(props.indexReport)) {
      const query = getQuery();
      //console.log(query);
      window.open(`${props.linkReport}${query}`, "_blank");
    }
    if (["10_1"].includes(props.indexReport)) {
      // if (optTypes.length === 0) {
      //   sweetAlerts.warning("กรุณาเลือกประเภทอปท.");
      //   return;
      // }

      // if (provinceSelected === null) {
      //   sweetAlerts.warning("กรุณาเลือกจังหวัด");
      //   return;
      // } else {
      const query = getQuery();
      //  console.log(query);
      window.open(`${props.linkReport}${query}`, "_blank");
      // }
    }

    if (["10_2", "10_3", "10_4"].includes(props.indexReport)) {
      // if (optTypes.length === 0) {
      //   sweetAlerts.warning("กรุณาเลือกประเภทอปท.");
      //   return;
      // }
      if (fromDate === null && toDate === null) {
        sweetAlerts.warning("กรุณาระบุช่วงวันเลือกตั้ง");
        return;
      } else {
        const query = getQuery();
        //  console.log(query);
        window.open(`${props.linkReport}${query}`, "_blank");
      }
    }
  };

  return (
    <div className={showModalReport2 ? "modal" : "hide"}>
      <Dialog
        open={showModalReport2}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModalReport2}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>{props.titleReport}</h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {["10_1", "10_2", "10_3", "10_4"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CheckboxOpttype
                  optTypeSelected={optTypeSelected}
                  onOptTypeSelected={onOptTypeSelected}
                ></CheckboxOpttype>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}

          <GridContainer>
            {["10_1"].includes(props.indexReport) ? (
              <>
                <GridItem xs={12} sm={6} md={6}>
                  <ListboxMsAreaEct
                    //   required={true}
                    value={areaEctSelected}
                    onChange={onAreaEctSelected}
                  ></ListboxMsAreaEct>
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <ListboxProvince
                    // required={true}
                    value={provinceSelected}
                    // areaEctSelected={areaEctSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
              </>
            ) : (
              ""
            )}
          </GridContainer>

          {[
            "5_1",
            "5_2",
            "5_3",
            "5_6",
            "5_4",
            "5_5",
            "6_1",
            "6_2",
            "7_1",
            "7_2",
            "8_1",
            "8_2",
            "8_3",
            "8_6",
            "8_5",
            "8_4"
          ].includes(props.indexReport) ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CheckboxOpttype
                    optTypeSelected={optTypeSelected}
                    onOptTypeSelected={onOptTypeSelected}
                  ></CheckboxOpttype>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxOptVotetype
                    value={optVoteTypeSelected}
                    onChange={onOptVoteTypeSelected}
                  ></ListboxOptVotetype>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxProvince
                    value={provinceSelected}
                    //   areaEctSelected={areaEctSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxOpts
                    title={"เลือกทุก อปท."}
                    value={optSelected}
                    getOptTypeSelectedLists={getOptTypeSelectedLists}
                    optTypeSelected={optTypeSelected}
                    provinceSelected={provinceSelected}
                    onChange={onOptSelected}
                  ></ListboxOpts>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxPositionType
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <DatepickerVotedate
                    id="begin"
                    label="ตั้งแต่วันที่"
                    value={fromDate}
                    valid={isFromValid}
                    onChange={date => {
                      if (date) {
                        let arr = date.format().split("T");
                        setFromDate(arr[0]);
                        setIsFromValid(false);
                      }
                      setIsFromValid(true);
                    }}
                  ></DatepickerVotedate>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <DatepickerVotedate
                    id="endDate"
                    label="ถึงวันที่"
                    value={toDate}
                    valid={isToValid}
                    disabled={fromDate === null ? true : false}
                    onChange={date => {
                      if (date) {
                        let arr = date.format().split("T");
                        setToDate(arr[0]);
                        setIsToValid(false);
                      }
                      setIsToValid(true);
                    }}
                  ></DatepickerVotedate>
                </GridItem>
              </GridContainer>
            </>
          ) : (
            ""
          )}

          <GridContainer>
            {["10_2", "10_3", "10_4"].includes(props.indexReport) ? (
              <>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxMsAreaEct
                    value={areaEctSelected}
                    onChange={onAreaEctSelected}
                  ></ListboxMsAreaEct>
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                  <ListboxOptVotetype
                    value={optVoteTypeSelected}
                    onChange={onOptVoteTypeSelected}
                  ></ListboxOptVotetype>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxProvince
                    value={provinceSelected}
                    //   areaEctSelected={areaEctSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
              </>
            ) : (
              ""
            )}
          </GridContainer>
          <GridContainer>
            {["10_2", "10_3", "10_4"].includes(props.indexReport) ? (
              <>
                <GridItem xs={12} sm={4} md={4}>
                  <ListboxPositionType
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <DatepickerVotedate
                    id="begin"
                    label="ตั้งแต่วันที่"
                    value={fromDate}
                    valid={isFromValid}
                    onChange={date => {
                      if (date) {
                        let arr = date.format().split("T");
                        setFromDate(arr[0]);
                        setIsFromValid(false);
                      }
                      setIsFromValid(true);
                    }}
                  ></DatepickerVotedate>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <DatepickerVotedate
                    id="endDate"
                    label="ถึงวันที่"
                    value={toDate}
                    valid={isToValid}
                    disabled={fromDate === null ? true : false}
                    onChange={date => {
                      if (date) {
                        let arr = date.format().split("T");
                        setToDate(arr[0]);
                        setIsToValid(false);
                      }
                      setIsToValid(true);
                    }}
                  ></DatepickerVotedate>
                </GridItem>
              </>
            ) : (
              ""
            )}
          </GridContainer>

          {["2_1"].includes(props.indexReport) ? (
            <>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CheckboxOpttype
                    optTypeSelected={optTypeSelected}
                    onOptTypeSelected={onOptTypeSelected}
                  ></CheckboxOpttype>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {/* <GridItem xs={12} sm={6} md={4}>
                  <ListboxOpttype
                    value={listboxOptSelected}
                    onChange={onListboxOptSelected}
                  ></ListboxOpttype>
                </GridItem> */}

                <GridItem xs={12} sm={6} md={4}>
                  <ListboxProvince
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxDistinct
                    title={"เลือกทุกอำเภอ"}
                    provinceSelected={provinceSelected}
                    onChange={onAmphurSelected}
                  ></ListboxDistinct>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxOpts
                    // required={true}
                    value={optSelected}
                    title={"เลือกทุก อปท."}
                    // listboxOptSelected={listboxOptSelected}
                    provinceSelected={provinceSelected}
                    getOptTypeSelectedLists={getOptTypeSelectedLists}
                    optTypeSelected={optTypeSelected}
                    amphurSelected={amphurSelected}
                    onChange={onOptSelected}
                  ></ListboxOpts>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxPositionType
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    style={{ marginTop: "15px" }}
                  >
                    <InputLabel>สถานะการดำรงตำแหน่ง</InputLabel>
                    <Select
                      value={status}
                      defaultValue={0}
                      onChange={handleStatus}
                    >
                      <MenuItem value={0} selected="selected">
                        ทั้งหมด
                      </MenuItem>
                      <MenuItem value={1}>อยู่ระหว่างดำรงตำแหน่ง </MenuItem>
                      <MenuItem value={2}>พ้นจากตำแหน่ง</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </>
          ) : (
            ""
          )}
          <br></br>
          <GridContainer
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem xs={12} sm={12} md={6}>
              <Button onClick={closeModalReport2}>ปิดหน้าต่าง</Button>
              <Button color="success" onClick={handleSubmitReport1}>
                ออกรายงาน
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalReport02;
