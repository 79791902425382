import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

const styles = {
    typo: {
        marginTop: "20%",
        position: "relative",
        textAlign: "center"
    },
    textHearder: {
        fontFamily: 'Athiti, sans-serif ',
        color: '#00acc1',
        fontSize: '50px',
        fontWeight: 600,
        textShadow: '1px 1px #000000'
    }

};
const useStyles = makeStyles(styles);

export default function Inspector() {
    const classes = useStyles();
    return (
        <div className={classes.typo}>
            <div className={classes.textHearder}>
                !!! อยู่ระหว่างดำเนินการ !!!
                 </div>
        </div>

    );
}