const getDefaultValue = (data, v) => {

    let ret = null;

    if( !Array.isArray(data) ) {
        throw Error('data must be array!');
    }

    if (v) {
        data.forEach(e => {
            if (e.id === v.id) {
                ret = e;
                return;
            }
        });
    }
    return ret;
}

export default getDefaultValue;