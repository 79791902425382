import React, { useContext, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ConvertDate from "utils/ConvertDate";
import Datepicker from "components/Common/Datepicker";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TabePersonDetail from "./TablePeronDetail";
import TextField from "@material-ui/core/TextField";
import { UserInfoContext } from "contexts/users";

/* API */
import { addVoteCardAllocateNoti } from "contexts/API/VoteCard/Allocate";
import { addPersonreceivelist } from "contexts/API/VoteCard/AllocateToBranch";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const PostWay = ({ pageProps, setPageProps }) => {
  const { userInfo } = useContext(UserInfoContext);
  const { props } = pageProps;

  const [receiveDate, setReceiveDate] = useState(null);
  const { sweetAlerts } = useSweetAlert();
  const [isFromValid, setIsFromValid] = useState(true);

  const [remark1, setRemark1] = useState("");

  const useStyles = makeStyles(theme => ({
    listbox: {
      marginTop: "-20px"
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto"
      // maxWidth: 600
    },
    text: {
      textAlign: "right"
    },
    space: {
      marginTop: "20px"
    },
    person: {
      textAlign: "left",
      alignItems: "center",

      justifyContent: "center"
    },
    img: {
      display: "flex",

      alignItems: "center",

      justifyContent: "center"
    }
  }));
  const classes = useStyles();

  const [dataTable] = useState([
    {
      idCard: "",
      firstName: "",
      lastName: "",
      fileUUID: "",
      personFileName: ""
    }
  ]);
  function getDetailVoteCard(rowData) {
    let _t = "";
    _t = (
      <ul styles={{ marginTop: "0px !important" }}>
        {rowData.map((post, index) => (
          <li key={index}>
            <p>
              {post.cardNumber} สี{post.colorName} เล่มที่{" "}
              {post.allocateStartNo} ถึง {post.allocateEndNo} จำนวน{" "}
              {post.volumnAllocate} เล่ม
            </p>
          </li>
        ))}
      </ul>
    );
    return _t;
  }
  const backtoMainHandler = rowData => {
    setPageProps({ active: "ManageAllocate", action: "Cancel" });
  };
  const onSubmit = () => {
    const chk = dataTable.filter(function (t) {
      return t.idCard === "" || t.firstName === "" || t.lastName === "";
    });

    if (chk.length !== 0) {
      sweetAlerts.warning(
        "กรุณาระบุข้อมูลผู้ส่งบัตรและต้องระบุข้อมูลเลขประจำตัวประชาชน, ชื่อ-นามสกุลให้ครบถ้วน"
      );
      return;
    }

    if (receiveDate === null) {
      sweetAlerts.warning("กรุณาระบุวันที่รับบัตร");
      return false;
    }

    dataTable[0].trackingNumber = remark1;
    addPersonreceivelist(
      props.voteCardpurchaseDetailId,
      props.receiveTypeCode,
      receiveDate,
      "-",
      dataTable
    )
      .then(result => {
        addVoteCardAllocateNoti({
          provinceId: props.provinceId,
          isCenter: true,
          header: "แจ้งการส่งบัตรทางไปรษณีย์",
          body:
            props.optTypeName + props.optName +
            " ตำแหน่ง " +
            props.positionTypeShortName +
            " อำเภอ " +
            props.amphurName +
            " จังหวัด " +
            props.provinceName +
            " ส่งบัตรทางไปรษณีย์เรียบร้อยแล้ว ",
          redirectUrl: "/main/manageCardToBranch",
          systemTypeId: 5
        }).then(result => {
          sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");
          backtoMainHandler();
        });

      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
      });
  };
  return (
    <>
      <Card>
        <CardBody>
          <div className={classes.cardHeader}>
            <h4 className={classes.cardTitle}>
              บันทึกข้อมูลการจัดส่งบัตรทางไปรษณีย์
            </h4>
          </div>
          <br />

          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  เลขที่หนังคือคำสั่งแนบท้าย (สนง.กกต.) :
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {props.centralDocumentNo}
                </GridItem>
              </GridContainer>
              {/* <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  ลงวันที่ :
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {ConvertDate(props.CentralDocumentDate)}
                </GridItem>
              </GridContainer> */}
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  จังหวัด :
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {props.provinceName}
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  อำเภอ :
                </GridItem>

                <GridItem xs={8} sm={8} md={8}>
                  {props.amphurName}
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  ชื่อ อปท. :
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {props.optName}
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  วันที่เลือกตั้ง :
                </GridItem>
                <GridItem xs={8} sm={8} md={8} styles={{ textAlign: "left" }}>
                  {ConvertDate(props.voteDate)}
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  รายละเอียดการจัดสรรบัตร :
                </GridItem>
                <GridItem
                  xs={8}
                  sm={8}
                  md={8}
                  styles={{ textAlign: "left", marginTop: "0px" }}
                >
                  {getDetailVoteCard(props.voteCardAllocate)}
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  วิธีการรับบัตรเลือกตั้ง :
                </GridItem>
                <GridItem xs={8} sm={4} md={3} styles={{ textAlign: "left" }}>
                  {props.receiveTypeName}
                </GridItem>
              </GridContainer>
              <GridContainer
                className={classes.space}
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  วันที่ส่งบัตร :
                </GridItem>
                <GridItem xs={8} sm={8} md={8} styles={{ textAlign: "left" }}>
                  <Datepicker
                    valid={isFromValid}
                    id="voteDate"
                    label="วันที่ส่งบัตร"
                    value={receiveDate}
                    onChange={date => {
                      setReceiveDate(date.format());
                      if (date) {
                        setIsFromValid(false);
                      } else {
                        setIsFromValid(true);
                      }
                      // let arr = date.format().split("T");
                      //    setVoteDateSeleted(arr[0]);
                    }}
                  ></Datepicker>
                </GridItem>
              </GridContainer>
              <TabePersonDetail data={dataTable} />
              <br></br> <br></br>
              <GridContainer
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <GridItem xs={6} md={6}>
                  <TextField
                    id="standard-basic"
                    label="Tracking Number (ถ้ามี)"
                    fullWidth
                    defaultValue={remark1}
                    onChange={e => {
                      setRemark1(e.target.value);
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  ผู้บันทึกข้อมูล :
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  {userInfo.tokenInfo.data.firstname}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>

          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button onClick={backtoMainHandler}>กลับ</Button>
              <Button
                color="success"
                className={classes.buttoncustom}
                //type="submit"
                onClick={onSubmit}
              >
                บันทึกข้อมูล
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {/*
      <TableComponent
        title="รายละเอียดบัตรเลือกตั้งที่สั่งซื้อ"
        columns={columns}
        data={data}
        localization={{}}
        options={{
          paging: true,
          pageSize: 10
        }}
      />
      */}
    </>
  );
};
export default PostWay;
