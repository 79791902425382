import React, { useContext, useEffect, useState } from "react";
import {
  delSuspendPerson,
  getListSuspend
} from "contexts/API/SUSPEND/ListSuspend";
import {
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import CheckboxSuspendStatus from "components/Common/CheckboxSuspendStatus";
import ConvertDate from "utils/ConvertDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LinkDownload from "components/Common/LinkDownload";
import ListboxProvince from "components/Common/ListboxProvince.js";
import LoadingData from "components/Common/LoadingData";
import NumberFormat from "react-number-format";
import { Paper } from "@material-ui/core";
import { SuspendContext } from "contexts/Suspend";
import TableCell from "@material-ui/core/TableCell";
import TableComponent from "components/Table/MaterialTable";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextfieldIdCard from "components/Common/TextfieldIdCard";
import TextfieldName from "components/Common/TextfieldName";
//Material Core
//import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { useSweetAlert } from "components/Alert/SweetAlert";

const ListSuspendPerson = ({ pageProps, setPageProps }) => {
  const useStyles = makeStyles();
  const classes = useStyles();
  const { sweetAlerts } = useSweetAlert();

  const {
    nameSelected,
    onNameSelected,
    idCardValid,
    idCardSelected,
    onIdCardHandler,
    onProvinceSelected,
    provinceSelected,
    supspendCheckBoxSelected,
    onSupspendCheckBoxSelected,
    getSupspendCheckboxLists
  } = useContext(SuspendContext);
  //const [valid, setValid] = useState(true);
  //const [idValid, setIdValid] = useState(true);
  //const [idCard, setIdCard] = useState(null);
  //const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const optionsTable = {};
  const [data, setData] = useState([]);

  const [columns] = useState([
    {
      title: "คำนำหน้าชื่อ",
      field: "personTitleDetail",
      cellStyle: {
        minWidth: "80px"
      },
      editable: "never"
    },
    {
      title: "ชื่อ สกุล",
      field: "firstName",
      editable: "never",
      cellStyle: {
        minWidth: "250px"
      },
      render: rowData => rowData.firstName + " " + rowData.lastName
    },
    {
      title: "เลขประจำตัวประชาชน",
      field: "idCard",
      editable: "never",
      cellStyle: {
        minWidth: "150px"
      },
      render: rowData => {
        return (
          <NumberFormat
            value={rowData.idCard}
            format="# #### ##### ## #"
            style={{
              width: "100%",
              border: "0"
            }}
          />
        );
      }
    },

    {
      title: "จังหวัด",
      field: "provinceName",
      cellStyle: {
        minWidth: "70px"
      }
    },
    {
      title: "สถานะ",
      field: "suspendStatusDetail",

      exportData: rowData => {
        return rowData.suspendFlag + "/" + rowData.revokeFlag;
      },
      render: rowData => {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              {(rowData.suspendFlag === true && rowData.suspendStatusId!==  5 && rowData.suspendStatusId!==  3) && rowData.suspendEDate !== null ? (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ color: "red", fontSize: 20 }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: "green", fontSize: 20 }}
                />
              )}
            </GridContainer>
            <br></br>
            <GridContainer
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              {(rowData.revokeFlag === true && rowData.revokeStatusId!==  6 && rowData.revokeStatusId!==  4) && rowData.revokeEDate !== null ? (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ color: "red", fontSize: 20 }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: "green", fontSize: 20 }}
                />
              )}
            </GridContainer>
          </GridItem>
        );
      }
    },
    {
      title: "ระยะเวลา",
      field: "suspendYear",
      exportData: rowData => {
        return `${rowData.suspendYear}ปี/${
          rowData.revokeYear === null ? 0 : rowData.revokeYear
        }ปี`;
      },
      render: rowData => {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              <span>
                {rowData.suspendYear}{" "}
                {rowData.suspendYear !== null ? "ปี" : "-"}
              </span>
              <br></br>
              <span>
                {rowData.revokeYear} {rowData.revokeYear !== null ? "ปี" : "-"}
              </span>
            </GridContainer>
          </GridItem>
        );
      }
    },
    {
      title: "วันที่ถูกระงับ/เพิกถอน",
      field: "suspendSdate",
      cellStyle: {
        minWidth: "150px"
      },
      exportData: rowData => {
        return `${ConvertDate(rowData.suspendSDate)}/${
          rowData.revokeSDate !== null ? ConvertDate(rowData.revokeSDate) : "-"
        }`;
      },
      render: rowData => {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              {rowData.suspendSDate !== null
                ? ConvertDate(rowData.suspendSDate)
                : "-"}
            </GridContainer>
            <br></br>
            <GridContainer
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              {rowData.revokeSDate !== null
                ? ConvertDate(rowData.revokeSDate)
                : "-"}
            </GridContainer>
          </GridItem>
        );
      }
    },
    {
      title: "วันที่พ้น",
      field: "wtddStartdate",
      cellStyle: {
        minWidth: "150px"
      },
      exportData: rowData => {
        return `${ConvertDate(rowData.suspendEDate)}/${
          rowData.revokeEDate !== null ? ConvertDate(rowData.revokeEDate) : "-"
        }`;
      },
      render: rowData => {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              {rowData.suspendEDate !== null
                ? ConvertDate(rowData.suspendEDate)
                : "-"}
            </GridContainer>
            <br></br>
            <GridContainer
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              {rowData.revokeEDate !== null
                ? ConvertDate(rowData.revokeEDate)
                : "-"}
            </GridContainer>
          </GridItem>
        );
      }
    },
    {
      title: "สถานะ",
      field: "suspendStatusDetail",
      cellStyle: {
        minWidth: "270px"
      },
      exportData: rowData => {
        return rowData.suspendStatusDetail + "/" + rowData.revokeStatusDetail;
      },
      render: rowData => {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              direction="column"
              justify="space-between"
              /* alignItems="center" */
            >
              <span>
                {rowData.suspendStatusDetail !== null
                  ? rowData.suspendStatusDetail
                  : "-"}
              </span>
              <br></br>
              <span>
                {rowData.revokeStatusDetail !== null
                  ? rowData.revokeStatusDetail
                  : "-"}
              </span>
            </GridContainer>
          </GridItem>
        );
      }
    },

    {
      title: "เอกสารที่เกี่ยวข้อง",
      field: "revokeFileName",
      cellStyle: {
        minWidth: "150px"
      },
      exportData: rowData => {
        return `${rowData.suspendFileName ? rowData.suspendFileName : "-"}/${
          rowData.revokeFileName ? rowData.revokeFileName : "-"
        }`;
      },
      render: rowData => {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              <span>
                {rowData.suspendFileName !== null ? (
                  <LinkDownload
                    url={getFileDownloadUrl("suspend", rowData.suspendFileUUID)}
                    fileId={rowData.suspendFileUUID}
                    fileName={rowData.suspendFileName}
                  />
                ) : (
                  "-"
                )}
              </span>
              <br></br>
              <span>
                {rowData.revokeFileName !== null ? (
                  <LinkDownload
                    url={getFileDownloadUrl("suspend", rowData.revokeFileUUID)}
                    fileId={rowData.revokeFileUUID}
                    fileName={rowData.revokeFileName}
                  />
                ) : (
                  "-"
                )}
              </span>
            </GridContainer>
          </GridItem>
        );
      }
    }
  ]);

  const handelSubmit = () => {
    setLoading(true);
    let endPoint = `?`;

    let suspendTypes = getSupspendCheckboxLists();

    if (suspendTypes.length > 0) {
      endPoint += `&statusId=[${suspendTypes.toString()}]`;
    }
    if (provinceSelected !== null) {
      if (provinceSelected.id !== 0) {
        endPoint += `&provinceId=${provinceSelected.id}`;
      }
    }

    if (idCardSelected !== "" && idCardValid === false) {
      sweetAlerts.warning("กรุณาใส่เลขประจำตัวประชาชนให้ถูกต้อง");
      return;
    }

    if (idCardSelected !== "" && idCardSelected !== null && idCardValid) {
      endPoint += `&idCard=${idCardSelected}`;
    }

    console.log("nameSelected>>", nameSelected);

    if (nameSelected) {
      endPoint += `&name=${nameSelected}`;
    }

    getListSuspend(endPoint)
      .then(result => {
        setData(result.data.data);
        // console.log(result);
        setLoading(false);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
  };

  useEffect(() => {
    if (pageProps.action === "cancel") {
      handelSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps]);

  const editSuspendPersonData = rowData => {
    //alert("แก้ไข");
    setPageProps({
      active: "EditSuspendPerson",
      props: {
        rowData: rowData
      }
    });
  };
  return (
    <>
      <Card>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4 className={classes.cardTitle}>
              ข้อมูลผู้ถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ
            </h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextfieldIdCard
                    value={idCardSelected}
                    onChange={onIdCardHandler}
                  />

                  <TextfieldName
                    id="name"
                    value={nameSelected}
                    labelText={"ชื่อ หรือ นามสกุล"}
                    onChange={onNameSelected}
                  />

                  <ListboxProvince
                    value={provinceSelected}
                    title={"จังหวัดที่ถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ"}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CheckboxSuspendStatus
                    supspendCheckBoxSelected={supspendCheckBoxSelected}
                    onSupspendCheckBoxSelected={onSupspendCheckBoxSelected}
                  ></CheckboxSuspendStatus>
                </GridItem>
              </GridContainer>
              <br /> <br /> <br />
              <GridContainer justify="space-between" alignItems="center">
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button
                    onClick={() => {
                      setData([]);
                    }}
                  >
                    ยกเลิก
                  </Button>
                  <Button color="info" onClick={handelSubmit}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading === true ? (
        <LoadingData />
      ) : (
        <Card>
          <TableComponent
            title={"รายชื่อผู้ถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ"}
            options={optionsTable}
            columns={columns}
            data={data}
            actions={[
              {
                icon: "edit",
                tooltip: "Save User",
                onClick: (event, rowData) =>
                  editSuspendPersonData(rowData.suspendPersonId)
              },
              {
                icon: "del",
                tooltip: "Save User",
                onClick: (event, rowData) => {
                  const dataDelete = [...data];
                  const index = rowData.tableData.id;
                  //   dataDelete.splice(index, 1);
                  sweetAlerts.error(
                    `ยืนการลบข้อมูล ${rowData.firstName} ${rowData.lastName}`,
                    `คุณต้องการลบข้อมูลใช่หรือไม่`,
                    () => {
                      delSuspendPerson(rowData.suspendPersonId)
                        .then(result => {
                          sweetAlerts.success(`ลบข้อมูลสำเร็จแล้ว`);
                          dataDelete.splice(index, 1);
                          setData([...dataDelete]);
                        })
                        .catch(err => {
                          if (err.response.data.code !== 20000) {
                            sweetAlerts.danger(
                              `ไม่สามารถลบข้อมูลได้`,
                              err.response.data.desc
                            );
                          }
                        });
                    },
                    true
                  );
                }
              }
            ]}
            components={{
              Container: props => <Paper {...props} elevation={0} />,
              Header: props => {
                return (
                  <TableHead style={{ background: "rgb(221, 221, 221)" }}>
                    <TableRow>
                      <TableCell align="center" rowSpan={2}>
                        ลำดับ
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        คำนำหน้าชื่อ
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        ชื่อ สกุล
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        เลขประจำตัวประชาชน
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        จังหวัด
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        สถานะ
                      </TableCell>
                      <TableCell colSpan={3} align="center">
                        ข้อมูลการถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ
                      </TableCell>

                      <TableCell align="center" rowSpan={2}>
                        สถานะ
                      </TableCell>
                      <TableCell align="center" rowSpan={2}>
                        เอกสารที่เกี่ยวข้อง
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">ระยะเวลา</TableCell>
                      <TableCell align="center">
                        วันที่ถูกระงับ/เพิกถอน
                      </TableCell>
                      <TableCell align="center">วันที่พ้นฯ</TableCell>
                    </TableRow>
                    {/* <TableRow></TableRow> */}
                  </TableHead>
                );
              },
              Action: props => {
                if (props.action.icon === "edit") {
                  return (
                    <Button
                      onClick={event => props.action.onClick(event, props.data)}
                      color="warning"
                      variant="contained"
                      style={{ textTransform: "none" }}
                      size="sm"
                    >
                      แก้ไข
                    </Button>
                  );
                }
                if (props.action.icon === "del") {
                  return (
                    <Button
                      onClick={event => props.action.onClick(event, props.data)}
                      color="danger"
                      variant="contained"
                      style={{ textTransform: "none" }}
                      size="sm"
                    >
                      ลบ
                    </Button>
                  );
                }
              }
            }}
          />
        </Card>
      )}
    </>
  );
};
export default ListSuspendPerson;
