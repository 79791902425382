import React, { useState } from "react";

import AllocatetoPrinting from "./AllocatetoPrinting";

import { VoteDateContextProvider } from "contexts/VoteDate";
const Index = props => {
  const SwitchComponents = ({ active, children }) => {
    return children.filter(child => child.props.name === active);
  };
  // Local State
  const [pageProps, setPageProps] = useState({
    active: "AllocatetoPrinting",
    props: null
  });

  return (
    <VoteDateContextProvider>
      <SwitchComponents active={pageProps.active}>
        <AllocatetoPrinting
          name="AllocatetoPrinting"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <></>
      </SwitchComponents>
    </VoteDateContextProvider>
  );
};

export default Index;
