import React, { useContext, useState } from "react";
import {
  getOnlineReport,
  saveOnlineReport,
  sendOnlineReport
} from "contexts/API/OPT/Report";

// import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//components
import ConvertDate from "utils/ConvertDate";
import FileSaver from "file-saver";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import HandelError from "utils/HandleError";
import ListboxFullYear from "components/Common/ListboxFullYear";
import ListboxMonth from "components/Common/ListboxMonth";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import { Paper } from "@material-ui/core";
import { REPORTING_SERVICE } from "config/env.conf";
import TableComponent from "components/Table/MaterialTable";
import Typography from "@material-ui/core/Typography";
import { UserInfoContext } from "contexts/users";
import { VoteDateContext } from "contexts/VoteDate";
import { downloadFileFromUrl } from "contexts/API/FileManagement/download";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  buttoncustom: {
    fontSize: 14,
    fontWeight: 500
    //color: props => props.color
  }
});

const RptOnlineOpt = props => {
  const {
    provinceSelected,

    onProvinceSelected
  } = useContext(VoteDateContext);

  const { userInfo } = useContext(UserInfoContext);

  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const optionsTable = {
    pageSize: 20
  };
  const [loading, setLoading] = useState(false);
  const [yearSelected, setYearSelected] = useState(null);
  const onYearSelected = (evt, newValue) => {
    if (newValue) {
      setYearSelected(newValue);
    }
  };
  const [monthSelected, setMonthSelected] = useState(null);
  const onMonthSelected = (evt, newValue) => {
    if (newValue) {
      setMonthSelected(newValue);
    }
  };

  const downloadFile = (url, fileName) => {
    if (url && fileName) {
      downloadFileFromUrl(url)
        .then(res => {
          //   console.log(res);
          FileSaver.saveAs(res.data, fileName);
        })
        .catch(err => {
          console.log("download file failed.");
        });
    } else {
      throw Error(
        "url not found, see https://github.com/DGA-SCD/ECTPL/issues/463"
      );
    }
  };

  const [monthLists] = useState([
    { id: 0, name: "เลือกเดือน" },
    { id: 1, name: "มกราคม" },
    { id: 2, name: "กุมภาพันธ์" },
    { id: 3, name: "มีนาคม" },
    { id: 4, name: "เมษายน" },
    { id: 5, name: "พฤษภาคม" },
    { id: 6, name: "มิถุนายน" },
    { id: 7, name: "กรกฎาคม" },
    { id: 8, name: "สิงหาคม" },
    { id: 9, name: "กันยายน" },
    { id: 10, name: "ตุลาคม" },
    { id: 11, name: "พฤศจิกายน" },
    { id: 12, name: "ธันวาคม" }
  ]);

  const getReport = () => {
    setLoading(true);

    let query = `?`;
    if (provinceSelected) {
      query += `&provinceId=${provinceSelected.id}`;
    }
    if (yearSelected) {
      query += `&year=${yearSelected.id}`;
    }
    if (monthSelected) {
      query += `&month=${monthSelected.id}`;
    }
    query += `&sysName=opt`;
    getOnlineReport(query)
      .then(response => {
        let res = response.data.data;

        let wholeArray = Object.keys(response.data.data).map(key => res[key]);

        setData(wholeArray);
        // console.log(wholeArray);
        setLoading(false);
      })
      .catch(e => {
        HandelError(e);
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", e.toString());
      });
  };
  const [columns] = useState([
    {
      title: "จังหวัด",
      field: "provinceName"
    },
    {
      title: "เดือน",
      field: "month",
      render: rowData => {
        const v = monthLists.find(e => e.id === parseInt(rowData.month));

        return v !== undefined ? v.name : "";
      }
    },
    {
      title: "ปี",
      field: "year",
      editable: "never"
    },
    {
      title: "รายงานวันเลือกตั้งล่วงหน้าของเดือนถัดไป",
      field: "type_2",
      cellStyle: {
        minWidth: "100px"
      },
      exportData: rowData => {
        // return "dd";
        let Items = "";
        if (rowData.type_2) {
          Items = rowData.type_2.map(
            (a, index) =>
              `ครั้งที่:${index + 1} วันที่:${ConvertDate(
                a.sendDatetime
              )} ผู้ส่ง:${a.firtName}\n`
          );
          return Items.toString();
        } else {
          return Items;
        }
      },

      render: rowData => {
        let Items = "";
        if (rowData.type_2) {
          Items = rowData.type_2.map((x, index) => (
            <ul key={index}>
              <li>
                <Typography>ครั้งที่ {x.sendNo}: </Typography>
                {x.fileUUID ? (
                  <Typography
                    style={{ cursor: "pointer", color: "purple" }}
                    onClick={() =>
                      downloadFile(
                        getFileDownloadUrl("online-reports", x.fileUUID),
                        x.fileName
                      )
                    }
                  >
                    Download
                  </Typography>
                ) : (
                  ""
                )}

                <Typography>วันที่ :{ConvertDate(x.sendDatetime)}</Typography>
                <Typography>ผู้ส่ง : {x.firtName}</Typography>
              </li>
            </ul>
          ));
          return Items;
        } else {
          return Items;
        }
      }
    },
    {
      title: "รายงานจำนวนผู้สมัครของ อปท. ที่มีวันเลือกตั้งในเดือนที่ผ่านมา",
      field: "type_3",
      cellStyle: {
        minWidth: "100px"
      },
      exportData: rowData => {
        // return "dd";
        let Items = "";
        if (rowData.type_3) {
          Items = rowData.type_3.map(
            (a, index) =>
              `ครั้งที่:${index + 1} วันที่:${ConvertDate(
                a.sendDatetime
              )} ผู้ส่ง:${a.firtName}\n`
          );
          return Items.toString();
        } else {
          return Items;
        }
      },
      render: rowData => {
        let Items = "";
        if (rowData.type_3) {
          Items = rowData.type_3.map((x, index) => (
            <ul key={index}>
              <li>
                <Typography>ครั้งที่ {x.sendNo}: </Typography>
                {x.fileUUID ? (
                  <Typography
                    style={{ cursor: "pointer", color: "purple" }}
                    onClick={() =>
                      downloadFile(
                        getFileDownloadUrl("online-reports", x.fileUUID),
                        x.fileName
                      )
                    }
                  >
                    Download
                  </Typography>
                ) : (
                  ""
                )}

                <Typography>วันที่ :{ConvertDate(x.sendDatetime)}</Typography>
                <Typography>ผู้ส่ง : {x.firtName}</Typography>
              </li>
            </ul>
          ));
          return Items;
        } else {
          return Items;
        }
      }
    },
    {
      title: "รายงานการใช้สิทธิเลือกตั้งของ อปท. ที่มีวันเลือกตั้งในเดือนที่ผ่านมา",
      field: "type_4",
      cellStyle: {
        minWidth: "100px"
      },
      exportData: rowData => {
        // return "dd";
        let Items = "";
        if (rowData.type_4) {
          Items = rowData.type_4.map(
            (a, index) =>
              `ครั้งที่:${index + 1} วันที่:${ConvertDate(
                a.sendDatetime
              )} ผู้ส่ง:${a.firtName}\n`
          );
          return Items.toString();
        } else {
          return Items;
        }
      },
      render: rowData => {
        let Items = "";
        if (rowData.type_4) {
          Items = rowData.type_4.map((x, index) => (
            <ul key={index}>
              <li>
                <Typography>ครั้งที่ {x.sendNo}: </Typography>
                {x.fileUUID ? (
                  <Typography
                    style={{ cursor: "pointer", color: "purple" }}
                    onClick={() =>
                      downloadFile(
                        getFileDownloadUrl("online-reports", x.fileUUID),
                        x.fileName
                      )
                    }
                  >
                    Download
                  </Typography>
                ) : (
                  ""
                )}

                <Typography>วันที่ :{ConvertDate(x.sendDatetime)}</Typography>
                <Typography>ผู้ส่ง : {x.firtName}</Typography>
              </li>
            </ul>
          ));
          return Items;
        } else {
          return Items;
        }
      }
    },
    {
      title: "รายงานผลคะแนนเลือกตั้งของ อปท. ที่มีวันเลือกตั้งในเดือนที่ผ่านมา",
      field: "type_11",
      cellStyle: {
        minWidth: "100px"
      },
      exportData: rowData => {
        // return "dd";
        let Items = "";
        if (rowData.type_11) {
          Items = rowData.type_11.map(
            (a, index) =>
              `ครั้งที่:${index + 1} วันที่:${ConvertDate(
                a.sendDatetime
              )} ผู้ส่ง:${a.firtName}\n`
          );
          return Items.toString();
        } else {
          return Items;
        }
      },
      render: rowData => {
        let Items = "";
        if (rowData.type_11) {
          Items = rowData.type_11.map((x, index) => (
            <ul key={index}>
              <li>
                <Typography>ครั้งที่ {x.sendNo}: </Typography>
                {x.fileUUID ? (
                  <Typography
                    style={{ cursor: "pointer", color: "purple" }}
                    onClick={() =>
                      downloadFile(
                        getFileDownloadUrl("online-reports", x.fileUUID),
                        x.fileName
                      )
                    }
                  >
                    Download
                  </Typography>
                ) : (
                  ""
                )}

                <Typography>วันที่ :{ConvertDate(x.sendDatetime)}</Typography>
                <Typography>ผู้ส่ง : {x.firtName}</Typography>
              </li>
            </ul>
          ));
          return Items;
        } else {
          return Items;
        }
      }
    }
  ]);

  const classes = useStyles();

  // useEffect(() => {
  //   if (provinceSelected === null) {
  //     setProvinceSelected({
  //       id: userInfo.provinceId !== null ? userInfo.provinceId : 0
  //     });
  //   }
  // }, [userInfo]);

  var date = new Date();
  const new_date = {
    year: "numeric",
    month: "short",
    day: "numeric"
  };
  var firstOfnextMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1
  ).toLocaleDateString("th-TH", new_date);
  var lastOfnextMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 2,
    0
  ).toLocaleDateString("th-TH", new_date);

  function convert(str) {
    let x = new Date(str).toLocaleDateString("th-TH", new_date);
    return x;
    // var date = new Date(str),
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate())
    //     .slice(-2)
    //     .toLocaleDateString("th-TH", new_date);
    // return [date.getFullYear(), mnth, day].join("-");
  }

  const openViewerReport = url => {
    const openurl = `${url}province_id=${provinceSelected.id
      }&rpt_send_date=${moment(new Date()).format("YYYY-MM-D")}`;
    window.open(openurl, "_blank");
  };
  const sendReport = (fileName, reportTypeId, url) => {
    let _tmp = `province_id=${provinceSelected.id}&rpt_send_date=${moment(
      new Date()
    ).format("YYYY-MM-D")}`;
    let reportUrl = url + _tmp;

    console.log(reportUrl);
    // let reportUrl =
    //   "ReportServer?%2freport_online%2fopt%2fopt_rpt11_1&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&province_id=40&rpt_send_date=2014-03-05";
    //console.log(reportUrl);
    saveOnlineReport(fileName, encodeURIComponent(reportUrl))
      .then(response => {
        let res = response.data.data;
        if (res.fileId) {
          sendOnlineReport(
            userInfo.provinceId,
            reportTypeId,
            res.fileId,
            "pdf"
          ).then(r => {
            sweetAlerts.success("ส่งข้อมูลสำเร็จ");
          });
        } else {
          sweetAlerts.danger("ขออภัยไม่แก้ไขข้อมูลได้");
        }
      })
      .catch(err => {
        sweetAlerts.danger("ขออภัยไม่แก้ไขข้อมูลได้", err.response.data.desc);
      });
  };

  return (
    <>
      {!userInfo.iscenter ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem
                    xs={4}
                    sm={4}
                    md={4}
                    style={{ alignItems: "center" }}
                  >
                    <h4 className={classes.cardTitle}>รายการรายงานออนไลน์</h4>
                  </GridItem>

                  <br />
                  <br />
                  <br />
                  <GridContainer style={{ paddingLeft: "20px" }}>
                    <GridItem
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ textAlign: "right" }}
                    >
                      รายงานประจำเดือน/ปี :
                    </GridItem>
                    <GridItem
                      xs={9}
                      sm={9}
                      md={9}
                      style={{ textAlign: "left" }}
                    >
                      {new Date().getMonth() + 1}/
                      {new Date().getFullYear() + 543}
                    </GridItem>
                  </GridContainer>
                  {/* <GridContainer style={{ paddingLeft: "20px" }}>
                  <GridItem xs={3} sm={3} md={3} style={{ textAlign: "right" }}>
                    ปี :
                  </GridItem>
                  <GridItem xs={9} sm={9} md={9} style={{ textAlign: "left" }}>
                    {new Date().getFullYear() + 543}
                  </GridItem>
                </GridContainer> */}

                  <GridContainer
                    style={{ paddingLeft: "20px", paddingTop: "10px" }}
                  >
                    <GridItem
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ textAlign: "right" }}
                    >
                      วันที่ส่งรายงาน :
                    </GridItem>
                    <GridItem
                      xs={9}
                      sm={9}
                      md={9}
                      style={{ textAlign: "left" }}
                    >
                      {ConvertDate(new Date())}
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    style={{ paddingLeft: "20px", alignItems: "center" }}
                  >
                    <GridItem
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ textAlign: "right" }}
                    >
                      จังหวัด :
                    </GridItem>
                    <GridItem
                      xs={9}
                      sm={9}
                      md={9}
                      style={{ textAlign: "left" }}
                    >
                      <ListboxProvince
                        disabled={!userInfo.iscenter}
                        defaultValue={{
                          id:
                            userInfo.provinceId !== null && !userInfo.iscenter
                              ? userInfo.provinceId
                              : null
                        }}
                        onChange={onProvinceSelected}
                      />
                    </GridItem>
                  </GridContainer>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      รายงานวันที่ 21 - 25 {moment(new Date()).format("MMM")}{" "}
                      {new Date().getFullYear() + 543}
                      {/* รายงานวันที่{" "}
                      {new Date().getDate() +
                        1 +
                        " - " +
                        convert(
                          new Date(
                            new Date().getTime() + 5 * 24 * 60 * 60 * 1000
                          )
                        )} */}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <div>
                      - รายงานวันเลือกตั้งล่วงหน้าของเดือนถัดไป ระหว่างวันที่ {firstOfnextMonth}
                      {" - "}
                      {lastOfnextMonth}
                    </div>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      disabled={(new Date().getDate() > 20 && new Date().getDate() < 29) ? false : true}
                      onClick={() =>
                        sendReport(
                          `รายงานการเลือกตั้งล่วงหน้า${firstOfnextMonth}-${lastOfnextMonth}.pdf`,
                          2,
                          "ReportServer?%2freport_online%2fopt%2fopt_rpt11_1&rs:Command=Render&rs:Format=PDF&rc:Parameters=Collapsed&"
                        )
                      }
                    >
                      ส่งรายงาน
                    </Button>

                    <Button
                      type="submit"
                      onClick={() =>
                        openViewerReport(
                          REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freport_online%2fopt%2fopt_rpt11_1&rs:Command=Render&rc:Parameters=Collapsed&"
                        )
                      }
                    >
                      แสดงตัวอย่างรายงาน
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      รายงานวันที่ 1 - 5 {moment(new Date()).format("MMM")}{" "}
                      {new Date().getFullYear() + 543}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <div>
                      - รายงานจำนวนผู้สมัครของ อปท.ที่มีวันเลือกตั้งในเดือนที่ผ่านมา ระหว่างวันที่{" "}
                      {new Date(
                        date.getFullYear(),
                        date.getMonth() - 1,
                        1
                      ).toLocaleDateString("th-TH", new_date)}
                      {" - "}
                      {new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        0
                      ).toLocaleDateString("th-TH", new_date)}
                      {/* (เฉพาะเดือน มี.ค. 56 ให้ส่งได้ถึงวันที่ 25 มี.ค. 56) */}
                    </div>
                  </GridItem>

                  <GridItem>
                    <Button
                      color="success"
                      disabled={new Date().getDate() > 8 ? true : false}
                      onClick={() =>
                        sendReport(
                          `รายงานจำนวนผู้สมัครประจำวันที่${new Date(
                            date.getFullYear(),
                            date.getMonth() - 1,
                            1
                          ).toLocaleDateString("th-TH", new_date)}-${new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            0
                          ).toLocaleDateString("th-TH", new_date)}.pdf`,
                          3,
                          "ReportServer?%2freport_online%2fopt%2fopt_rpt11_2&rs:Command=Render&rc:Parameters=Collapsed&rs:Format=PDF&"
                        )
                      }
                    >
                      ส่งรายงาน
                    </Button>
                    <Button
                      onClick={() =>
                        openViewerReport(
                          REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freport_online%2fopt%2fopt_rpt11_2&rs:Command=Render&rc:Parameters=Collapsed&"
                        )
                      }
                    >
                      แสดงตัวอย่างรายงาน
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      รายงานวันที่ 1 - 5 {moment(new Date()).format("MMM")}{" "}
                      {new Date().getFullYear() + 543}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <div
                      style={{
                        paddingTop: "5px",
                        alignItems: "center",
                        display: "flex"
                      }}
                    >
                      - รายงานการใช้สิทธิเลือกตั้งของ อปท.ที่มีวันเลือกตั้งในเดือนที่ผ่านมา ระหว่างวันที่{" "}
                      {new Date(
                        date.getFullYear(),
                        date.getMonth() - 1,
                        1
                      ).toLocaleDateString("th-TH", new_date)}
                      {" - "}
                      {new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        0
                      ).toLocaleDateString("th-TH", new_date)}
                      {/* (เฉพาะเดือน มี.ค. 56 ให้ส่งได้ถึงวันที่ 25 มี.ค. 56) */}
                    </div>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      disabled={new Date().getDate() > 8 ? true : false}
                      onClick={() =>
                        sendReport(
                          `รายงานการใช้สิทธิประจำวันที่${new Date(
                            date.getFullYear(),
                            date.getMonth() - 1,
                            1
                          ).toLocaleDateString("th-TH", new_date)}-${new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            0
                          ).toLocaleDateString("th-TH", new_date)}.pdf`,
                          4,
                          "ReportServer?%2freport_online%2fopt%2fopt_rpt11_4&rs:Command=Render&rc:Parameters=Collapsed&rs:Format=PDF&"
                        )
                      }
                    >
                      ส่งรายงาน
                    </Button>
                    <Button
                      onClick={() =>
                        openViewerReport(
                          REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freport_online%2fopt%2fopt_rpt11_4&rs:Command=Render&rc:Parameters=Collapsed&"
                        )
                      }
                    >
                      แสดงตัวอย่างรายงาน
                    </Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      รายงานวันที่ 1 - 5 {moment(new Date()).format("MMM")}{" "}
                      {new Date().getFullYear() + 543}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <div
                      style={{
                        paddingTop: "5px",
                        alignItems: "center",
                        display: "flex"
                      }}
                    >
                      - รายงานผลคะแนนเลือกตั้งของ อปท.ที่มีวันเลือกตั้งในเดือนที่ผ่านมา ระหว่างวันที่{" "}
                      {new Date(
                        date.getFullYear(),
                        date.getMonth() - 1,
                        1
                      ).toLocaleDateString("th-TH", new_date)}
                      {" - "}
                      {new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        0
                      ).toLocaleDateString("th-TH", new_date)}
                      {/* (เฉพาะเดือน มี.ค. 56 ให้ส่งได้ถึงวันที่ 25 มี.ค. 56) */}
                    </div>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      disabled={new Date().getDate() > 8 ? true : false}
                      onClick={() =>
                        sendReport(
                          `รายงานผลคะแนน${new Date(
                            date.getFullYear(),
                            date.getMonth() - 1,
                            1
                          ).toLocaleDateString("th-TH", new_date)}-${new Date(
                            date.getFullYear(),
                            date.getMonth(),
                            0
                          ).toLocaleDateString("th-TH", new_date)}.pdf`,
                          11,
                          "ReportServer?%2freport_online%2fopt%2fopt_rpt11_3&rs:Command=Render&rc:Parameters=Collapsed&rs:Format=PDF&"
                        )
                      }
                    >
                      ส่งรายงาน
                    </Button>
                    <Button
                      onClick={() =>
                        openViewerReport(
                          REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freport_online%2fopt%2fopt_rpt11_3&rs:Command=Render&rc:Parameters=Collapsed&"
                        )
                      }
                    >
                      แสดงตัวอย่างรายงาน
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4 className={classes.cardTitle}>
                    ค้นหาประวัติการส่งรายงานออนไลน์
                  </h4>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxProvince
                    // disabled={!userInfo.iscenter}

                    // defaultValue={{
                    //   id:
                    //     userInfo.provinceId !== null && !userInfo.iscenter
                    //       ? userInfo.provinceId
                    //       : null
                    // }}
                    // controll={true}
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxMonth
                    value={monthSelected}
                    onChange={onMonthSelected}
                  ></ListboxMonth>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxFullYear
                    value={yearSelected}
                    onChange={onYearSelected}
                  ></ListboxFullYear>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer justify="space-between" alignItems="center">
                <GridItem xs={12} sm={12} md={8}></GridItem>
                <GridItem>
                  <Button color="info" onClick={getReport}>
                    ดูประวัติ
                  </Button>
                  {/* <Button
                    color="twitter"
                    className={classes.buttoncustom}
                    type="submit"
                  >
                    Export Report
                  </Button> */}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title={"ประวัติการส่งรายงานออนไลน์"}
          columns={columns}
          data={data}
          components={{
            Container: props => <Paper {...props} elevation={0} />
          }}
          localization={{}}
          options={optionsTable}
        />
      )}
    </>
  );
};

export default RptOnlineOpt;
