import React, { useContext, useEffect, useState } from "react";
//import { delWtdPerson, getListwtd } from "contexts/API/WTD/Listwtd";
import { delWtdPerson, getListwtd } from "contexts/API/WTD/Listwtd";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import CheckboxWtdStatus from "components/Common/CheckboxWtdStatus";
import ConvertDate from "utils/ConvertDate";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { LinkDownload } from "components/Common/LinkDownload";
import ListboxProvince from "components/Common/ListboxProvince.js";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import { Paper } from "@material-ui/core";
import TableComponent from "components/Table/MaterialTable";
import TextfieldIdCard from "components/Common/TextfieldIdCard";
import TextfieldName from "components/Common/TextfieldName";
//import conf from "config/env.conf";
import { WtdContext } from "contexts/Wtd";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
//Material Core
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { UserInfoContext } from "contexts/users";
import { wsWTDDelWtdPerson } from "contexts/API/DOPA/WS";
//import Cookies from "universal-cookie";

const ListWtdPerson = ({ pageProps, setPageProps }) => {
  const useStyles = makeStyles();
  const { sweetAlerts } = useSweetAlert();

  const {
    idCardValid,

    onProvinceSelected,
    provinceSelected,
    fileNameSelected,
    onFileNameSelected,
    nameSelected,
    onNameSelected,
    idCardSelected,
    onIdCardHandler,
    wtdCheckBoxSelected,
    onWtdCheckBoxSelected,
    getWtdCheckboxLists
  } = useContext(WtdContext);
  const [loading, setLoading] = useState(false);
  // const [idCard, setIdCard] = useState("");
  // const [idValid, setIdValid] = useState(true);
  const [data, setData] = useState([]);
  const [dataMoveInOut, setDataMoveInOut] = useState([]);
  const { userInfo } = useContext(UserInfoContext);

  const handelSubmit = () => {
    setLoading(true);
    //console.log(nameSelected);
    // let endPoint = "?idCard=3360400564931&provinceId=36";
    let endPoint = `?`;

    let wtdTypes = getWtdCheckboxLists();

    if (wtdTypes.length > 0) {
      endPoint += `&wtdStatusId=[${wtdTypes.toString()}]`;
    }

    if (provinceSelected !== null) {
      if (provinceSelected.id !== 0) {
        endPoint += `&provinceId=${provinceSelected.id}`;
      }
    }

    if (idCardSelected !== "" && idCardValid === false) {
      sweetAlerts.warning("กรุณาใส่เลขประจำตัวประชาชนให้ถูกต้อง");
      return;
    }

    if (idCardSelected !== "" && idCardSelected !== null && idCardValid) {
      endPoint += `&idCard=${idCardSelected}`;
    }
    if (nameSelected) {
      endPoint += `&name=${nameSelected}`;
    }

    if (fileNameSelected) {
      endPoint += `&filename=${fileNameSelected}`;
    }

    getListwtd(endPoint)
      .then(result => {
        let newArr2;
        let newArr3;

        //let x = Object.values(result.data.data);

        const arr1 = result.data.data.wtdPersonSelect;
        setData(arr1);

        if (Object.keys(result.data.data.wtdPersonMoveIn).length > 0) {
          const arr2 = result.data.data.wtdPersonMoveIn;
          let index = 1;
          newArr2 = arr2.map(v => ({
            ...v,
            index: index++,
            groupType: "รายชื่อผู้ถูกย้ายเข้า"
          }));
        }

        if (Object.keys(result.data.data.wtdPersonMoveOut).length > 0) {
          const arr3 = result.data.data.wtdPersonMoveOut;
          let index = 1;
          newArr3 = arr3.map(v => ({
            ...v,
            index: index++,
            groupType: "รายชื่อผู้ถูกย้ายออก"
          }));

          // _setData(...data, newArr2);
        }
        let _data = combineArray(newArr2, newArr3);
        _data = _data.filter(function (element) {
          return element !== undefined;
        });
        setDataMoveInOut(_data);
        setLoading(false);
      })

      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล อปท.", err.toString());
        setLoading(false);
      });
  };

  const combineArray = (newArr2, newArr3) => {
    return [].concat(newArr2, newArr3);
  };

  const classes = useStyles();

  const [columnsPersonSelected] = useState([
    {
      title: "คำนำหน้าชื่อ",
      field: "personTitleDetail",
      editable: "never",
      cellStyle: {
        minWidth: "80px"
      }
    },
    {
      title: "ชื่อ สกุล",
      field: "firstName",
      editable: "never",
      cellStyle: {
        minWidth: "250px"
      },
      exportData: row => {
        return row.firstName + " " + row.lastName;
      },
      render: rowData => {
        return rowData.firstName + " " + rowData.lastName;
      }
    },
    {
      title: "เลขประจำตัวประชาชน",
      field: "idCard",
      editable: "never",
      cellStyle: {
        minWidth: "150px"
      },
      render: rowData => {
        return (
          <NumberFormat
            value={rowData.idCard}
            format="# #### ##### ## #"
            style={{
              width: "100%",
              border: "0",
              paddingTop: "15px"
            }}
          />
        );
      }
    },

    {
      title: "จังหวัดที่ถูกเพิกถอน",
      field: "wtdProvinceName",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      }
    },

    {
      title: "ประเภทการเลือกตั้ง",
      field: "wtdVoteType",
      cellStyle: {
        minWidth: "120px"
      }
    },

    {
      title: "ระยะเวลา",
      field: "wtdYear",
      cellStyle: {
        minWidth: "50px",
        textAlign: "center"
      }
    },

    {
      title: "วันที่ถูกเพิกถอน",
      field: "wtdSdate",
      cellStyle: {
        minWidth: "150px"
      },
      render: rowData => {
        return ConvertDate(rowData.wtdSdate);
      }
    },

    {
      title: "วันที่พ้นฯ",
      field: "wtdEdate",
      cellStyle: {
        minWidth: "150px"
      },
      render: rowData => {
        return ConvertDate(rowData.wtdEdate);
      }
    },
    {
      title: "สถานะ",
      field: "wtdStatusDetail",
      cellStyle: {
        minWidth: "200px"
      }
    },

    {
      title: "เอกสารที่เกี่ยวข้อง",
      field: "wtdPersonFile",
      cellStyle: {
        minWidth: "250px",
        //alignItems: "center"
      },
      exportData: rowData => {
        return `${rowData.fileName ? rowData.fileName : ""}`;
      },


      render: rowData => {
        let Items = "";
        if (Object.keys(rowData.wtdPersonFile).length > 0) {
          Items = rowData.wtdPersonFile.map((list, index) => (
            <LinkDownload
              key={index}
              url={getFileDownloadUrl("wtd", list.fileUUID)}
              fileId={list.fileUUID}
              fileName={list.fileName}
            />
          ));
          return Items;
        } else {
          return Items;
        }
      }
    }
  ]);

  const [columnsMoveInOut] = useState([
    {
      title: "ประเภท",
      field: "groupType",
      defaultGroupOrder: 0,
      cellStyle: {
        backgroundColor: "#039be5",
        color: "#FFF",
        fontWeight: 500
      },
      headerStyle: {
        fontWeight: 500
      }
    },
    {
      title: "wtdPersonId",
      field: "wtdPersonId",
      hidden: true,
      export: true
    },
    {
      title: "ลำดับ",
      field: "id",
      render: rowData => {
        return rowData.index;
      }
    },
    {
      title: "คำนำหน้าชื่อ",
      field: "personTitleDetail",
      editable: "never",
      cellStyle: {
        minWidth: "80px"
      }
    },
    {
      title: "ชื่อ สกุล",
      field: "firstName",
      editable: "never",
      cellStyle: {
        minWidth: "250px"
      },
      render: rowData => {
        return rowData.firstName + " " + rowData.lastName;
      }
    },
    {
      title: "เลขประจำตัวประชาชน",
      field: "idCard",
      editable: "never",
      cellStyle: {
        minWidth: "150px"
      },
      render: rowData => {
        return (
          <NumberFormat
            value={rowData.idCard}
            format="# #### ##### ## #"
            style={{
              width: "100%",
              border: "0",
              paddingTop: "15px"
            }}
          />
        );
      }
    },

    {
      title: "จังหวัดที่ถูกเพิกถอน",
      field: "wtdProvinceName",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      }
    },
    {
      title: "จังหวัดที่ย้ายเข้า",
      field: "addrProvinceName",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      }
    },

    {
      title: "ระยะเวลา",
      field: "wtdYear",
      cellStyle: {
        minWidth: "50px",
        textAlign: "center"
      }
    },

    {
      title: "วันที่ถูกเพิกถอน",
      field: "wtdSdate",
      cellStyle: {
        minWidth: "150px"
      },
      render: rowData => {
        return ConvertDate(rowData.wtdSdate);
      }
    },

    {
      title: "วันที่พ้นฯ",
      field: "wtdEdate",
      cellStyle: {
        minWidth: "150px"
      },
      render: rowData => {
        return ConvertDate(rowData.wtdEdate);
      }
    },
    {
      title: "สถานะ",
      field: "wtdStatusDetail",
      cellStyle: {
        minWidth: "200px"
      }
    }
  ]);

  useEffect(() => {
    if (pageProps.action === "cancel") {
      handelSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps]);

  const editWtdPersonData = rowData => {
    setPageProps({
      active: "EditWtdPerson",
      props: {
        rowData: rowData
      }
    });
  };
  return (
    <>
      <Card>
        <CardHeader color="info" text>
          <CardText color="info">
            <h4 className={classes.cardTitle}>
              ข้อมูลผู้ถูกเพิกถอนสิทธิเลือกตั้ง
            </h4>
          </CardText>
        </CardHeader>

        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer
                justify="space-between"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={12} sm={12} md={6}>
                  <TextfieldIdCard
                    value={idCardSelected}
                    onChange={onIdCardHandler}
                  />

                  <TextfieldName
                    id="name"
                    value={nameSelected}
                    labelText={"ชื่อ หรือ นามสกุล"}
                    onChange={onNameSelected}
                  />
                  <ListboxProvince
                    value={provinceSelected}
                    title={"จังหวัดที่ถูกเพิกถอน"}
                    onChange={onProvinceSelected}
                    // required={true}
                    validators={[MustNotNull]}
                  ></ListboxProvince>

                  <TextfieldName
                    id="fileName"
                    value={fileNameSelected}
                    labelText={"คำพิพากษา"}
                    onChange={onFileNameSelected}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CheckboxWtdStatus
                    wtdCheckBoxSelected={wtdCheckBoxSelected}
                    onWtdCheckBoxSelected={onWtdCheckBoxSelected}
                  ></CheckboxWtdStatus>
                </GridItem>
              </GridContainer>
              {/* <GridContainer
                justify="space-between"
                alignItems="center"
                direction="row"
              >
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxProvince
                    value={provinceSelected}
                    title={"จังหวัดที่ถูกเพิกถอน"}
                    onChange={onProvinceSelected}
                    // required={true}
                    validators={[MustNotNull]}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxWtdStatus
                    value={wtdStatusSelected}
                    onChange={onWtdStatusSelected}
                  ></ListboxWtdStatus>
                </GridItem>
              </GridContainer> */}
              <br /> <br /> <br />
              <GridContainer justify="space-between" alignItems="center">
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button>ยกเลิก</Button>
                  <Button color="info" onClick={handelSubmit}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      {loading === true ? (
        <LoadingData />
      ) : (
        <div>
          <TableComponent
            title={"รายชื่อผู้ถูกเพิกถอนสิทธิเลือกตั้ง"}
            columns={columnsPersonSelected}
            data={data}
            //// enableInitColumns={false}

            actions={[
              {
                icon: "edit",
                tooltip: "แก้ไขข้อมูล",
                onClick: (event, rowData) =>
                  editWtdPersonData(rowData.wtdPersonId)
              },
              {
                icon: "del",
                tooltip: "ลบข้อมูล",
                onClick: (event, rowData) => {
                  const dataDelete = [...data];
                  const index = rowData.tableData.id;
                  //dataDelete.splice(index, 1);

                  sweetAlerts.error(
                    `ยืนการลบข้อมูล ${rowData.firstName} ${rowData.lastName}`,
                    `คุณต้องการลบข้อมูลใช่หรือไม่`,
                    () => {
                      delWtdPerson(rowData.wtdPersonId)
                        .then(result => {
                          wsWTDDelWtdPerson(rowData.idCard)
                            .then(r => {
                              console.log("Call service wsWTDDelWtdPerson success ");
                            })
                            .catch(err => {
                              console.log("Call service wsWTDDelWtdPerson Error :" + err);
                            });
                          sweetAlerts.success(`ลบข้อมูลสำเร็จแล้ว`);
                          dataDelete.splice(index, 1);
                          setData([...dataDelete]);
                        })
                        .catch(err => {
                          if (err.response.data.code !== 20000) {
                            sweetAlerts.danger(`ไม่สามารถลบข้อมูลได้`,
                              err.response.data.desc);
                          }
                        });
                    },
                    true
                  );
                }
              }
            ]}
            options={{}}
            components={{
              Container: props => <Paper {...props} elevation={0} />,
              Action: props => {
                if (props.action.icon === "edit") {
                  return (
                    <Button
                      onClick={event => props.action.onClick(event, props.data)}
                      color="warning"
                      variant="contained"
                      style={{ textTransform: "none" }}
                      size="sm"
                    >
                      แก้ไข
                    </Button>
                  );
                }

                if (props.action.icon === "del") {
                  return (
                    (userInfo.isadminwtd) ? (
                      <Button
                        onClick={event => props.action.onClick(event, props.data)}
                        color="danger"
                        variant="contained"
                        style={{ textTransform: "none" }}
                        size="sm"
                      >
                        ลบ
                      </Button>
                    ) : ('')
                  );
                }

              }
            }}
          />
          <TableComponent
            title={"รายชื่อผู้ถูกเพิกถอนที่ย้ายเข้า/ย้ายออก"}
            columns={columnsMoveInOut}
            data={dataMoveInOut}
            enableInitColumns={false}
            actions={[
              {
                icon: "edit",
                tooltip: "แก้ไขข้อมูล",
                onClick: (event, rowData) =>
                  editWtdPersonData(rowData.wtdPersonId)
              },
              {
                icon: "del",
                tooltip: "ลบข้อมูล",
                onClick: (event, rowData) => {
                  const dataDelete = [...data];
                  const index = rowData.tableData.id;
                  //dataDelete.splice(index, 1);

                  sweetAlerts.error(
                    `ยืนการลบข้อมูล ${rowData.firstName} ${rowData.lastName}`,
                    `คุณต้องการลบข้อมูลใช่หรือไม่`,
                    () => {
                      delWtdPerson(rowData.wtdPersonId)
                        .then(result => {
                          sweetAlerts.success(`ลบข้อมูลสำเร็จแล้ว`);
                          dataDelete.splice(index, 1);
                          setData([...dataDelete]);
                        })
                        .catch(err => {
                          if (err.response.data.code !== 20000) {
                            sweetAlerts.danger(`ไม่สามารถลบข้อมูลได้`,
                              err.response.data.desc);
                          }
                        });
                    },
                    true
                  );
                }
              }
            ]}
            options={{
              paging: false
            }}
            components={{
              Container: props => <Paper {...props} elevation={0} />,
              Action: props => {
                if (props.action.icon === "edit") {
                  return (
                    <Button
                      onClick={event => props.action.onClick(event, props.data)}
                      color="warning"
                      variant="contained"
                      style={{ textTransform: "none" }}
                      size="sm"
                    >
                      แก้ไข
                    </Button>
                  );
                }

                if (props.action.icon === "del") {
                  return (
                    (userInfo.isadminwtd) ? (
                      <Button
                        onClick={event => props.action.onClick(event, props.data)}
                        color="danger"
                        variant="contained"
                        style={{ textTransform: "none" }}
                        size="sm"
                      >
                        ลบ
                      </Button>
                    ) : ('')
                  );
                }

              }
            }}
          />
        </div>
      )}
    </>
  );
};
export default ListWtdPerson;
