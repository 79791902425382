import React, { useEffect, useState } from "react";
import {
  addAge,
  deleteAge,
  editAge,
  getAge
} from "contexts/API/CommonSetting/MasterData";

import MaterialTable from "components/Table/MaterialTable";
import NumberFormat from "react-number-format";
import { useSweetAlert } from "components/Alert/SweetAlert";

const AgeRank = props => {
  const { sweetAlerts } = useSweetAlert();
  const [columns] = useState([
    {
      title: "ageRankId",
      field: "ageRankId",
      hidden: true
    },
    {
      title: "อายุเริ่มต้น",
      field: "startAge",
      cellStyle: {
        minWidth: "300px",
        textAlign: "center"
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandsGroupStyle="thousand"
            style={{
              borderStyle: "none none none none",

              textAlign: "center"
            }}
            value={rowData.startAge}
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandsGroupStyle="thousand"
            style={{
              borderStyle: "none none dotted none",

              textAlign: "center"
            }}
            value={props.rowData.startAge}
            onValueChange={values => {
              const { value } = values;

              props.rowData.startAge = parseInt(value);
            }}
          />
        );
      }
    },
    {
      title: "อายุสิ้นสุด",
      field: "endAge",
      cellStyle: {
        minWidth: "300px",
        textAlign: "center"
      },
      render: rowData => {
        return (
          <NumberFormat
            thousandsGroupStyle="thousand"
            style={{
              borderStyle: "none none none none",

              textAlign: "center"
            }}
            value={rowData.endAge}
          />
        );
      },
      editComponent: props => {
        return (
          <NumberFormat
            thousandsGroupStyle="thousand"
            style={{
              borderStyle: "none none dotted none",

              textAlign: "center"
            }}
            value={props.rowData.endAge}
            onValueChange={values => {
              const { value } = values;

              props.rowData.endAge = parseInt(value);
            }}
          />
        );
      }
    },
    {
      title: "ช่วงอายุ",
      field: "detail",
      editable: "never",
      cellStyle: {
        minWidth: "300px",
        textAlign: "center"
      }
    }
  ]);

  const [data, setData] = useState([]);
  useEffect(() => {
    getAge()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const optionsTable = { pageSize: 10 };
  return (
    <MaterialTable
      title={"ช่วงอายุ"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            console.log(newData);
            addAge(newData.startAge, newData.endAge)
              .then(result => {
                //console.log("result>>",result);
                newData.detail = newData.startAge + "-" + newData.endAge;
                newData.ageRankId = result.data.data.ageRankId;
                setData([...data, newData]);
                sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
              });

            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            newData.detail = newData.startAge + "-" + newData.endAge;
            dataUpdate[index] = newData;

            editAge(oldData.ageRankId, newData.startAge, newData.endAge)
              .then(result => {
                sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");

                setData([...dataUpdate]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
              });
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);

            deleteAge(oldData.ageRankId)
              .then(result => {
                sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                setData([...dataDelete]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
              });
            resolve();
          })
      }}
    />
  );
};
export default AgeRank;
