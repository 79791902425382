import { END_POINT_URL } from 'config/env.conf';

export function getFileDownloadUrl(systemName, fileId) {
    return `${END_POINT_URL}/file/${systemName}/download/${fileId}`;
}

export function getFileUploadUrl(systemName, fileId) {
    return `${END_POINT_URL}/file/${systemName}/upload/${fileId}`;
}

export function getFileInfoUrl(systemName, fileId) {
    return `${END_POINT_URL}/file/${systemName}/info/${fileId}`;
}

