import React, { useEffect, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InputLabel from "@material-ui/core/InputLabel";
import LoadingData from "components/Common/LoadingData";
import TableComponent from "components/Table/MaterialTable";
import MenuItem from "@material-ui/core/MenuItem";
import NumberFormat from "react-number-format";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { getCheckPerson } from "contexts/API/OPT/OptcheckPerson";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { REPORTING_SERVICE } from "config/env.conf";
//import HeaderAuth from "utils/HeaderAuth";

const CheckPositionStatus = props => {
  const { sweetAlerts } = useSweetAlert();
  const options_table = {
    paging: false,
    search: false,
    exportButton: false
    //maxBodyHeight: "650px"
  };
  const [year, setYear] = useState(0);
  const [idValid, setIdValid] = useState(true);
  const [orgName, setOrgName] = useState(null);
  const [idCard, setIdCard] = useState(null);
  const [data, setData] = useState([]);
  const [orgNameState, setOrgNameState] = useState(true);
  const [keyTempIdCard, setKeyTempIdCard] = useState(null);
  const [disableStatus, setDisableStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleChangeYear = event => {
    setYear(event.target.value);
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const [columns, setColumns] = useState([
    {
      title: "เลขประจำตัวประชาชน",
      field: "idCard",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      }
    },
    {
      title: "ผลการตรวจ",
      field: "result",
      cellStyle: {
        textAlign: "center"
      }
    }
  ]);

  const onIDCardHandler = v => {
    setIdCard(v.value);
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      if (isError) {
        sweetAlerts.danger("เลขประจำตัวประชาชนผิด");
        //  setIdValid(!idValid);
      } else {
        setIdValid(!idValid);
      }
    } else {
      setIdValid(true);
    }
  };

  useEffect(() => {
    if (idCard !== null && orgNameState === false) {
      setDisableStatus(false);
    } else {
      setDisableStatus(true);
    }
  }, [idCard, orgNameState]);

  const handelSubmit = () => {
    if (year === null) {
      sweetAlerts.warning("กรุณาระบุวันที่ต้องการตรวจสอบ");
      return;
    }

    if (idCard === null) {
      sweetAlerts.warning("กรุณาระบุหมายเลขประจำตัวประชาชน");
      return;
    }

    if (orgNameState === "" && orgName === null) {
      setOrgNameState(false);
    }

    if (orgName === null) {
      sweetAlerts.warning("กรุณาระบุหน่วยงาน");
      return;
    }

    const today = new Date();
    today.setFullYear(today.getFullYear() - year);
    const checkDate = today.toISOString().split("T")[0];
    setLoading(true);
    setData([]);

    getCheckPerson([idCard], checkDate, idCard.toString())
      .then(result => {

        setKeyTempIdCard(result.data.data.keyTempIdCard);
        const dataUniqKey = {};
        result.data.data.result.forEach(v => {
          let postionStatus = "";
          let __level__ = 0;
          if (
            !v.startEffectiveDate ||
            !v.optFullname /* ถ้าไม่มี startEffectiveDate ให้ถือว่ายังไม่เคยดำรงตำแหน่ง */
          ) {
            __level__ = 0;
            postionStatus = "--ไม่พบข้อมูล--";
          } else {
            __level__ = 1;
            postionStatus = `ดำรงตำแหน่ง ${v.optPosition} วันที่ ${ConvertDate(
              v.startEffectiveDate
            )} - ${ConvertDate(v.endEffectiveDate)} ${v.optShortName}${v.optFullname
              } จ.${v.provinceName}`;
          }

          if (dataUniqKey[v.idCard] === undefined) {
            /* บันทึกสถานะ */
            dataUniqKey[v.idCard] = {
              idCard: v.idCard,
              result: [postionStatus],
              __level__: __level__
            };
          } else if (__level__ >= 1) {
            /* เพิ่มประวัติการดำรงตำแหน่ง */

            if (dataUniqKey[v.idCard].__level__ === 0) {
              dataUniqKey[v.idCard].result = [postionStatus];
              dataUniqKey[v.idCard].__level__ = __level__;
            } else {
              dataUniqKey[v.idCard].result = [
                ...dataUniqKey[v.idCard].result,
                postionStatus
              ];
              dataUniqKey[v.idCard].__level__ = __level__;
            }
          } else {
            //do nothing
          }
        });
        setData(Object.values(dataUniqKey));
        setLoading(false);
        setColumns([
          {
            title: "เลขประจำตัวประชาชน",
            field: "idCard",
            cellStyle: {
              minWidth: "150px"
            },
            render: rawData => {
              return (
                <NumberFormat
                  style={{
                    borderStyle: "none none none none"
                  }}
                  value={rawData.idCard}
                  format="# #### ##### ## #"
                  mask="X"
                />
              );
            }
          },
          {
            title: "ผลการตรวจสอบ",
            field: "result",
            render: rawData => {
              return rawData.result.map((e, i) => {
                return <div key={i}>{e}</div>;
              });
            },
            cellStyle: {
              minWidth: "600px"
            }
          }
        ]);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูล อปท.", err.response.data.desc);
      });
  };
  const exportUrl = () => {
    let endPointExp = "";

    const today = new Date();
    today.setFullYear(today.getFullYear() - year);
    const checkDate = today.toISOString().split("T")[0];

    console.log("keyTempIdCard >>", keyTempIdCard)

    endPointExp += `&keyTempIdCard=${keyTempIdCard}&date=${checkDate}&orgName=${orgName}&year=${year !== null ? year : 0}&total=1`;

    return endPointExp;
  };

  const handleSubmitExport = e => {
    e.preventDefault();
    window.open(
      `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2fopt%2fopt_rpt_exp_checkperson&rs:Command=Render&rc:Parameters=Collapsed${exportUrl()}`,
      "_blank"
    );
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br />
          <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={12} sm={12} md={6}>
              <NumberFormat
                style={{
                  width: "100%",
                  margin: "0 0 17px 0",
                  paddingTop: "27px"
                }}
                placeholder="เลขประจำตัวประชาชนที่ต้องการตรวจสอบ"
                customInput={TextField}
                format="# #### ##### ## #"
                mask="X"
                fullWidth
                error={idValid}
                onValueChange={onIDCardHandler}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}></GridItem>
          </GridContainer>
          <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>ระยะเวลาที่ตรวจสอบย้อนหลัง</InputLabel>
                <Select
                  value={year}
                  onChange={handleChangeYear}
                  inputProps={{
                    name: "checkDate"
                  }}
                >
                  <MenuItem aria-label="None" value={0}>
                    วันปัจจุบัน
                  </MenuItem>
                  <MenuItem value={1}>1 ปี</MenuItem>
                  <MenuItem value={2}>2 ปี</MenuItem>
                  <MenuItem value={3}>3 ปี</MenuItem>
                  <MenuItem value={4}>4 ปี</MenuItem>
                  <MenuItem value={5}>5 ปี</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                id="orgName"
                required
                labelText="หน่วยงานที่ขอตรวจสอบ (โปรดระบุ)"
                error={orgNameState}
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  autoComplete: "new-password",
                  onChange: e => {
                    if (verifyLength(e.target.value, 1)) {
                      setOrgNameState(false);
                    } else {
                      setOrgNameState(true);
                    }
                    setOrgName(e.target.value);
                  }
                }}
              />
            </GridItem>
          </GridContainer>
          <br /> <br />
          <GridContainer justify="space-between" alignItems="center" direction="row">
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button
                onClick={() => {
                  setData([]);
                }}
              >
                ยกเลิก
              </Button>
              <Button
                color="info"
                disabled={disableStatus}
                onClick={handelSubmit}
              >
                ตรวจสอบ
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <br />
      {data.length !== 0 ? (
        <GridContainer justify="space-between" alignItems="center">
          <GridItem xs={6} sm={6} md={6}></GridItem>
          <GridItem>
            นำออกข้อมูล:{" "}
            <Button justIcon round color="success" onClick={handleSubmitExport}>
              <FontAwesomeIcon icon={faFileExcel} />
            </Button>
          </GridItem>
        </GridContainer>
      ) : null}

      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title={"ผลการตรวจสอบข้อมูล"}
          columns={columns}
          data={data}
          components={{
            Container: props => <Paper {...props} elevation={0} />
            /* Header: props => {
              return (
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ลำดับ</TableCell>
                    <TableCell>เลขประจำตัวประชาชน</TableCell>
                    <TableCell>ผลการตรวจสอบ</TableCell>
                  </TableRow>
                </TableHead>
              );
            } */
          }}
          localization={{}}
          options={options_table}
        />
      )}
    </>
  );
};

export default CheckPositionStatus;
