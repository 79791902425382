import React, { createContext, useContext, useState } from "react";

import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { UserInfoContext } from "contexts/users";
import { useSweetAlert } from "components/Alert/SweetAlert";

export const SuspendContext = createContext();

export const SuspendContextProvider = ({ children }) => {
  const { userInfo } = useContext(UserInfoContext);

  let userProvinceId = null;
  let isCenter = userInfo.iscenter;
  if (!isCenter) {
    userProvinceId = {
      id: userInfo.provinceId,
      value: userInfo.provinceName
    };
  }

  const [suspendVoteTypeSelected, setSuspendVoteTypeSelected] = useState(null);
  const [provinceSelected, setProvinceSelected] = useState(userProvinceId);
  const [optVoteTypeSelected, setOptVoteTypeSelected] = useState(null);
  const [yearSelected, setYearSelected] = useState(null);
  const [titleSelected, setTitleSelected] = useState(null);
  const [amphurSelected, setAmphurSelected] = useState(null);
  const [tambolSelected, setTambolSelected] = useState(null);
  const [suspendStatusSelected, setSuspendStatusSelected] = useState(null);
  const [idCardValid, setIdCardValid] = useState(true);
  const [idCardSelected, setIdCardSelected] = useState(null);
  const [nameSelected, setNameSelected] = useState(null);
  const { sweetAlerts } = useSweetAlert();

  const defaultSupspendSelected = {
    1: { checked: false, value: 1 },
    2: { checked: false, value: 2 },
    3: { checked: false, value: 3 },
    4: { checked: false, value: 4 },
    5: { checked: false, value: 5 },
    6: { checked: false, value: 6 }
  };
  const [supspendCheckBoxSelected, setSupspendCheckBoxSelected] = useState(
    defaultSupspendSelected
  );

  const value = {
    nameSelected: nameSelected,
    setNameSelected: setNameSelected,
    onNameSelected: evt => {
      setNameSelected(evt.target.value);
    },
    supspendCheckBoxSelected: supspendCheckBoxSelected,
    setSupspendCheckBoxSelected: setSupspendCheckBoxSelected,

    onSupspendCheckBoxSelected: (evt, newChecked) => {
      if (
        supspendCheckBoxSelected[`${evt.target.name}`] === undefined ||
        newChecked !== supspendCheckBoxSelected[`${evt.target.name}`].checked
      ) {
        setSupspendCheckBoxSelected({
          ...supspendCheckBoxSelected,
          [`${evt.target.name}`]: {
            checked: evt.target.checked,
            value: evt.target.value
          }
        });
      }
    },
    getSupspendCheckboxLists: () => {
      let suspendIdLists = [];
      for (const [, v] of Object.entries(supspendCheckBoxSelected)) {
        if (v.checked) {
          suspendIdLists.push(parseInt(v.value));
        }
      }
      return suspendIdLists;
    },
    idCardSelected: idCardSelected,
    idCardValid: idCardValid,
    setIdCardValid: setIdCardValid,
    setIdCardSelected: setIdCardSelected,
    onIdCardHandler: evt => {
      if (
        evt !== null &&
        evt !== undefined &&
        evt.value !== null &&
        evt.value !== undefined &&
        evt.value.length === 13
      ) {
        const [isError] = ThaiCitizenID(evt.value);
        if (isError) {
          sweetAlerts.danger("เลขประจำตัวประชาชนผิด");
          setIdCardValid(false);
        } else {
          setIdCardValid(true);
        }
      } else {
        setIdCardValid(true);
      }
      setIdCardSelected(evt.value);
    },
    optVoteTypeSelected: optVoteTypeSelected, //เลือกประเภทการเลือกตั้ง
    setOptVoteTypeSelected: setOptVoteTypeSelected,
    onOptVoteTypeSelected: (evt, newValue) => {
      setOptVoteTypeSelected(newValue);
    },
    provinceSelected: provinceSelected,
    setProvinceSelected: setProvinceSelected,
    onProvinceSelected: (evt, newValue) => {
      setProvinceSelected(newValue);
    },
    amphurSelected: amphurSelected,
    setAmphurSelected: setAmphurSelected,
    onAmphurSelected: (evt, newValue) => {
      setAmphurSelected(newValue);
      setTambolSelected(null);
    },
    tambolSelected: tambolSelected,
    setTambolSelected: setTambolSelected,
    onTambolSelected: (evt, newValue) => {
      setTambolSelected(newValue);
    },
    yearSelected: yearSelected,
    setYearSelected: setYearSelected,
    onYearSelected: (evt, newValue) => {
      setYearSelected(newValue);
    },
    titleSelected: titleSelected,
    setTitleSelected: setTitleSelected,
    onTitleSelected: (evt, newValue) => {
      setTitleSelected(null);
    },
    suspendVoteTypeSelected: suspendVoteTypeSelected, //เลือกประเภทการเลือกตั้ง
    setSuspendVoteTypeSelected: setSuspendVoteTypeSelected,
    onSuspendVoteTypeSelected: (evt, newValue) => {
      setSuspendVoteTypeSelected(newValue);
    },
    suspendStatusSelected: suspendStatusSelected,
    setSuspendStatusSelected: setSuspendStatusSelected,
    onSuspendStatusSelected: (evt, newValue) => {
      setSuspendStatusSelected(newValue);
    }
  };

  return (
    <SuspendContext.Provider value={value}>{children}</SuspendContext.Provider>
  );
};
