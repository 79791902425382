import React, { useState, useEffect } from "react";
import MaterialTable from "components/Table/MaterialTable";
import ListboxSuspendOrgtype from "components/Common/ListboxSuspendOrgtype";
import {
  getsuspendOrg,
  getsuspendOrgType,
  editsuspendOrg,
  addsuspendOrg,
  deletesuspendOrg
} from "contexts/API/SUSPEND/common";
import { useSweetAlert } from "components/Alert/SweetAlert";

const Org = props => {
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const [orgTypeList, setOrgTypeList] = useState([]);
  const optionsTable = { pageSize: 10 };
  useEffect(() => {
    getsuspendOrgType()
      .then(r => {
        let OrgData = r.data.data.map(v => {
          return { id: v.suspendOrgTypeId, value: v.detail };
        });
        setOrgTypeList(OrgData);
      })
      .catch(err => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "suspendOrgId",
      field: "suspendOrgId",
      hidden: true
    },
    {
      title: "ประเภทหน่วยงาน",
      field: "suspendOrgTypeId",
      cellStyle: {
        minWidth: "200px"
      },
      render: rowData => {
        const v = orgTypeList.find(e => e.id === rowData.suspendOrgTypeId);
        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        return (
          <ListboxSuspendOrgtype
            rowId={0}
            defaultValue={{ id: props.value }}
            data={orgTypeList}
            onChange={(i, e, v) => {
              if (v !== null) {
                props.rowData.suspendOrgTypeId = e.id;
              }
            }}
          />
        );
      }
    },

    { 
      title: "หน่วยงาน", 
      field: "orgName" ,
      cellStyle: {
        minWidth: "600px"
      }
    }
  ];

  useEffect(() => {
    getsuspendOrg()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaterialTable
      title={"จัดการหน่วยงานที่มีสิทธิระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
              addsuspendOrg(newData.suspendOrgTypeId, newData.orgName)
                .then(result => {
                  newData.suspendOrgId = result.data.data.suspendOrgId;
                  setData([...data, newData]);
                  sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
                });

              resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;

              editsuspendOrg(
                oldData.suspendOrgId,
                newData.suspendOrgTypeId,
                newData.orgName
              )
                .then(result => {
                  sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                  setData([...dataUpdate]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });
              resolve();
            }, 1000);
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              deletesuspendOrg(oldData.suspendOrgId)
                .then(result => {
                  sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                  setData([...dataDelete]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
                });

              resolve();
            }, 1000);
          })
      }}
    />
  );
};
export default Org;
