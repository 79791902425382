import { AutocompleteValidator } from "utils/Validators/CommonValidator";
//import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import CustomInput from "components/CustomInput/CustomInput";
/* core components */
import React from "react";

const TextfieldName = props => {
  const [, setError] = React.useState(false);
  const [, setHelperText] = React.useState("");
  let content = (
    <CustomInput
      name={props.name}
      disabled={props.disabled}
      default
      required
      labelText={props.labelText}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        value: props.value || "",
        onChange: AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )
      }}
    />
  );

  return content;
};
export default TextfieldName;
