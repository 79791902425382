import Cookies from "universal-cookie";
import {END_POINT_URL} from "config/env.conf";
import axios from "axios";

export function findAllNotifications(query = "") {
    const cookies = new Cookies();
    const isAuth = cookies.get("userInfo");
    if( !isAuth ) {
        return Promise.reject("Unauthorized")
    }
    const url = `${END_POINT_URL}/notification`+query;
    return axios.get(url, {
        headers: {
            Authorization: `Bearer ${isAuth.token}`
        }
    });
}

export function markReadNotifications(id) {
    const cookies = new Cookies();
    const isAuth = cookies.get("userInfo");
    if( !isAuth ) {
        return Promise.reject("Unauthorized")
    }
    const url = `${END_POINT_URL}/notification/mark-read`;
    return axios.post(url, {
            id: id
        },
        {
            headers: {
                Authorization: `Bearer ${isAuth.token}`
            }
        });
}

export function markUnReadNotifications(id) {
    const cookies = new Cookies();
    const isAuth = cookies.get("userInfo");
    if( !isAuth ) {
        return Promise.reject("Unauthorized")
    }
    const url = `${END_POINT_URL}/notification/mark-unread`;
    return axios.post(url,
        {id: id},
        {
            headers: {
                Authorization: `Bearer ${isAuth.token}`
            }
        });
}

/**
 * Example
    notification:{
        "provinceId": 55,
        "isCenter": false,
        "header": "หัวข้อ",
        "body": "เนื้อหาแจ้งเตือน",
        "redirectUrl": "/main/complete_opt_data",
        "systemTypeId": 1
    }
 */
export function sendNotifications(notification) {
    const cookies = new Cookies();
    const isAuth = cookies.get("userInfo");
    if( !isAuth ) {
        return Promise.reject("Unauthorized")
    }
    const url = `${END_POINT_URL}/notification`;
    return axios.post(url,
        notification,
        {
            headers: {
                Authorization: `Bearer ${isAuth.token}`
            }
        });
}