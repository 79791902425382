import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getPersonOpt(optSelected, votedateSelected, query) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optSelected.id}/candidature/voteDate/${votedateSelected.id}${query}`;
  // const url =
  //   "http://ectpl-be.scd-dga.com/api/v1/opt/1450/candidature/voteDate/2011-11-13?positionTypeId=2";
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addoptPerson(data, oldData, optSelected, positionTypeSelected) {
  const isAuth = cookies.get("userInfo");
  let query = "?";

  if (Object.keys(oldData).length !== 0) {
    query += `personId=${oldData.personId}`;
  }

  const url = `${END_POINT_URL}/opt/optPerson/optId/${optSelected}/positionTypeId/${positionTypeSelected}${query}`;

  ///opt/optPerson/optId/3563/positionTypeId/2
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getPersonbyId(rowData) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/optPersonId/${rowData.PKID}/pId/${
    rowData.personId
  }/optId/${rowData.msOptId}/areaVoteId/${
    rowData.optAreaVote === null ? "0" : rowData.optAreaVote
  }/positionType/${rowData.optPositionTypeId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function checkDubApplyNo(param) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}${param}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editoptPerson(data, rowData) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/optPerson/pId/${rowData.personId}/optPersonId/${rowData.PKID}/positionTypeId/${rowData.optPositionTypeId}`;
  ///opt/optPerson/pId/288136/optPersonId/325864/positionTypeId/2

  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editoptPersonIdresultVote(
  personId,
  optPositionTypeId,
  registerStatus
) {
  const isAuth = cookies.get("userInfo");
  console.log(registerStatus);
  const regis = registerStatus === true ? 1 : 0;
  const url = `${END_POINT_URL}/opt/optPersonId/${personId}/registerStatus?positionTypeId=${optPositionTypeId}&registerSratus=${regis}`;

  const body = {
    positionTypeId: optPositionTypeId,
    registerSratus: registerStatus
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deloptPersonId(optPersonId, positionTypeId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/optPerson/optPersonId/${optPersonId}/positionTypeId/${positionTypeId}`;

  const body = {
    deleteRemark: "ลบทิ้ง"
  };
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    },
    data: body
  });
}
export function checkcitizenId(citizenId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/optPerson/citizenId/${citizenId}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getPersonbyCitizenIdList(citizenIdList) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/optPerson/citizenIdList`;
  const body = {
    idCard: citizenIdList
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getCandidatureAll(optId, positionTypeId, voteDate, areaNo) {
  const isAuth = cookies.get("userInfo");
  let url = `${END_POINT_URL}/opt/${optId.id}/candidature/voteDate/${voteDate.id}`;

  if (positionTypeId && positionTypeId.id && areaNo) {
    url += `?positionTypeId=${positionTypeId.id}&areaNo=${areaNo}`;
  } else if (positionTypeId && positionTypeId.id) {
    url += `?positionTypeId=${positionTypeId.id}&areaNo=${areaNo}`;
  } else {
    url += `?areaNo=${areaNo}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addMultiOptPerson(
  optId,
  optVoteTypeId,
  voteDate,
  areaAmphurId,
  personList
) {
  const isAuth = cookies.get("userInfo");

  const data = {
    optVoteTypeId: optVoteTypeId,
    voteDate: voteDate,
    areaAmphurId: areaAmphurId,
    candidate: personList
  };

  const url = `${END_POINT_URL}/opt/addMultiOptPerson/optId/${optId}`;

  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
