import React, { useEffect, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import ConvertDate from "utils/ConvertDate";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import LoadingData from "components/Common/LoadingData";
import Modalallocate from "./Modalallocateupdate";
import Modalattachment from "./Modalattachment";
import TableComponent from "components/Table/MaterialTable";
/* API */
import { getSearchDetailLists } from "contexts/API/VoteCard/Allocate";
import { useSweetAlert } from "components/Alert/SweetAlert";

const AddAllocate = ({ pageProps, setPageProps }) => {
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { sweetAlerts } = useSweetAlert();
  const [showModaladdCardallocate, setshowModaladdCardallocate] = useState(
    false
  );
  const openModaladdCardallocate = () => setshowModaladdCardallocate(true);
  const closeModaladdCardallocate = () => setshowModaladdCardallocate(false);
  const [showModalAttachment, setshowModalAttachment] = useState(false);
  //const openModalAttachment = () => setshowModalAttachment(true);
  const closeModalAttachment = () => setshowModalAttachment(false);

  const [purchaseID,] = useState(pageProps.props.PurchaseID);
  const [areaAttachement,] = useState([]);
  const [areaAllocate, setAreaAllocate] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);

        const res = getSearchDetailLists("?VotePurchaseID=" + purchaseID, "")
          .then(result => {
            const columns = [
              {
                title: "ชื่อ อปท.",
                field: "OptName",
                editable: "never",
                cellStyle: {
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                }
              },
              {
                title: "วันที่เลือกตั้ง",
                field: "VoteDate",
                editable: "never",
                cellStyle: {
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                },
                render: rowData => {
                  return ConvertDate(rowData.VoteDate);
                }
              },
              {
                title: "เขต",
                field: "AreaNo",
                editable: "never",
                cellStyle: {
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                }
              },
              {
                title: "จำนวนบัตรเลือกตั้ง",
                field: "VoteCard",
                editable: "never",
                cellStyle: {
                  textAlign: "center"
                },
                headerStyle: {
                  textAlign: "center"
                }
              }
            ];

            setColumns(columns);
            setData([]);
            setData(result.data.data);
          })
          .catch(err => {
            sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", err.toString());
          });

        setLoading(false);
      } catch (error) {
        HandelError(error);
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const backtoMainHandler = rowData => {
    setPageProps({ active: "CardAllocate", props: rowData });
  };
  /*   const delAllocate = rowData => {
      alert("ลบนะจ๊ะ");
    }; */

  return loading ? (
    <LoadingData />
  ) : (
    <div>
      <Modalallocate
        closeModaladdCardallocate={closeModaladdCardallocate}
        showModaladdCardallocate={showModaladdCardallocate}
        areaAllocate={areaAllocate}
      ></Modalallocate>
      <Modalattachment
        closeModalAttachment={closeModalAttachment}
        showModalAttachment={showModalAttachment}
        areaAttachement={areaAttachement}
      ></Modalattachment>
      <TableComponent
        title="รายการจัดสรรบัตรเลือกตั้ง (ADD)"
        columns={columns}
        data={data}
        localization={{}}
        options={{
          paging: false,
          pageSize: 5,
          pageSizeOptions: [5, 10, 20]
        }}
        actions={[
          /*
          {
            icon: () => (
              <Button variant="contained" color="warning" size="sm">แบบคำสั่งแต่งตั้ง</Button>
            ),
            tooltip: "แบบคำสั่งแต่งตั้ง",
            onClick: (event, rowData) => {
              openModalAttachment();
              setAreaAttachement(rowData);
            }
          },
          */
          {
            icon: () => (
              <Button variant="contained" color="info" size="sm">
                แก้ไข
              </Button>
            ),
            tooltip: "แก้ไข",
            onClick: (event, rowData) => {
              openModaladdCardallocate();
              setAreaAllocate(rowData);
            }
          }
        ]}
      />
      <br></br>
      <GridContainer
        justify="space-between"
        direction="column"
        alignItems="flex-end"
      >
        <GridItem>
          <Button color="default" onClick={backtoMainHandler}>
            กลับ
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default AddAllocate;
