import React, { useEffect, useState } from "react";
import {
  addColor,
  deleteColor,
  editColor,
  getColor
} from "contexts/API/VoteCard/Setting";

import MaterialTable from "components/Table/MaterialTable";
import { useSweetAlert } from "components/Alert/SweetAlert";

const Color = props => {
  const { sweetAlerts } = useSweetAlert();
  const [columns] = useState([
    {
      title: "colorId",
      field: "colorId",
      hidden: true
    },
    {
      title: "สี",
      field: "colorName",
      cellStyle: {
        minWidth: "600px"
      }
    }
  ]);

  const [data, setData] = useState([]);
  useEffect(() => {
    getColor()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const optionsTable = { pageSize: 10 };
  return (
    <MaterialTable
      title={"สีบัตรเลือกตั้ง"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            addColor(newData.colorName)
              .then(result => {
                //console.log("result>>",result);
                newData.colorId = result.data.data.colorId;
                setData([...data, newData]);
                sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
              });

            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;

            editColor(oldData.colorId, newData.colorName)
              .then(result => {
                sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                setData([...dataUpdate]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
              });
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);

            deleteColor(oldData.colorId)
              .then(result => {
                sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                setData([...dataDelete]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
              });
            resolve();
          })
      }}
    />
  );
};
export default Color;
