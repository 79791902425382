import React, { useContext, useState } from "react";
import { editCadidate8, getCandidate8 } from "contexts/API/INSPEC/Selection8";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectApplyDate from "components/Common/ListboxInspectApplyDate";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import ModalReplacement from "../SelectionReplaceCentral/ModalReplacement";
import TextField from "@material-ui/core/TextField";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const Selection8People = ({ pageProps, setPageProps }) => {
  const {
    provinceSelected,
    onProvinceSelected,
    onInspectApplyDateSelected,
    inspectApplyDateSelected
  } = useContext(InspectorContext);

  const { sweetAlerts } = useSweetAlert();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [isUploadFile4, setIsUploadFile4] = useState(null);
  const [isUploadFile4_1, setIsUploadFile4_1] = useState(null);
  const [checkPositionStatusIsNull, setCheckPositionStatusIsNull] = useState(
    null
  );
  const [columns, setColumns] = useState([]);
  const [childDetail] = useState(null);
  const handelSubmit = () => {
    const keys_to_keep = [
      "personApplyCmdId",
      "personId",
      "positionStatusId",
      "isSelection8"
    ];

    const _data = data.map(o =>
      keys_to_keep.reduce((acc, curr) => {
        acc[curr] = o[curr];
        return acc;
      }, {})
    );

    editCadidate8(_data)
      .then(result => {
        let obj = _data.filter(o => o.isSelection8 === true).length;
        setCounter(obj);
        sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");
      })
      .catch(err => {
        HandelError(err);
        sweetAlerts.error("ไม่สามารถบันทึกข้อมูลได้", err.toString());
      });
  };

  const [showModalReplacement, setshowModalReplacement] = useState(false);
  //const openModalReplacement = () => setshowModalReplacement(true);
  const closeModalReplacement = () => setshowModalReplacement(false);

  const clickSearch = async () => {
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }

    if (inspectApplyDateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกช่วงวันที่สมัคร");
      return;
    } else {
      setLoading(true);
      try {
        const res = await getCandidate8(
          provinceSelected.id,
          inspectApplyDateSelected.id
        );
        setData(res.data.data.cadidates);
        let obj = res.data.data.cadidates.filter(o => o.isSelection8 === true)
          .length;

        setCounter(obj);

        //console.log(">>>>", res.data.data);

        setIsUploadFile4(res.data.data.isUploadFile4);
        setIsUploadFile4_1(res.data.data.isUploadFile4_1);
        setCheckPositionStatusIsNull(res.data.data.checkPositionStatusIsNull);

        setLoading(false);
        const columns = [
          {
            title: "เลขที่ใบสมัคร",
            field: "applyNo",
            cellStyle: {
              minWidth: "100px",
              textAlign: "center"
            }
          },
          {
            title: "ผ่านการคัดเลือกเป็น ผตล",
            field: "isSelection8",
            cellStyle: {
              minWidth: "200px",
              textAlign: "center"
            },
            render: rowData => {
              return (
                <Checkbox
                  name="isSelection8"
                  checked={rowData.isSelection8}
                  onChange={e => {
                    console.log(res.data.data.cadidates);
                    let applyPerson = res.data.data.cadidates;

                    applyPerson[rowData.tableData.id][e.target.name] =
                      e.target.checked;
                    setData([...applyPerson]);
                  }}
                />
              );
            }
          },
          {
            title: "ชื่อ สกุล",
            field: "firstName",
            cellStyle: {
              minWidth: "200px"
            },
            render: rowData => {
              return (
                rowData.titleDetail +
                " " +
                rowData.firstName +
                " " +
                rowData.lastName
              );
            }
          },
          {
            title: "สถานะการสมัคร",
            field: "applyStatusDetail",
            cellStyle: {
              minWidth: "150px"
            }
          },

          {
            title: "สถานะการดำรงตำแหน่ง",
            field: "positionStatus",
            cellStyle: {
              minWidth: "150px"
            }
          }
        ];
        setColumns(columns);
      } catch (error) {
        HandelError(error);
        setLoading(true);
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", error.toString());
      }
    }
  };

  const optionsTable = {};
  return (
    <div>
      <ModalReplacement
        closeModalReplacement={closeModalReplacement}
        showModalReplacement={showModalReplacement}
        childDetail={childDetail}
      ></ModalReplacement>
      <Card>
        <CardHeader color="danger" text>
          <CardText color="danger">
            <h4>บันทึกรายชื่อที่ผ่านการคัดเลือก 8 คน</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxProvince
                    required={true}
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxInspectApplyDate
                    value={inspectApplyDateSelected}
                    required={true}
                    onChange={onInspectApplyDateSelected}
                  ></ListboxInspectApplyDate>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    label="สถานะการอัพไฟล์ ผตล.4"
                    style={{ marginTop: 15 }}
                    InputProps={{
                      readOnly: true,
                      value: isUploadFile4
                        ? "มีการส่งแบบ ผตล. 4 ในระบบ"
                        : "ไม่พบการส่งแบบ ผตล. 4 ในระบบ"
                    }}
                    fullWidth
                  ></TextField>
                </GridItem>
              </GridContainer>
              <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button>ยกเลิก</Button>
                  <Button color="info" onClick={clickSearch}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <GridItem xs={6} sm={6} md={4}>
                    <span style={{ paddingLeft: "20px" }}>
                      ขาดผู้ได้รับการคัดเลือกอีก
                      <span style={{ color: "red" }}> {8 - counter} </span>คน
                    </span>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={8}></GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {isUploadFile4 === false && isUploadFile4_1 === false ? (
                      <span style={{ color: "red", paddingLeft: "20px" }}>
                        ไม่พบการส่งไฟล์ ผตล.4 และ ผตล.4/1 จากจังหวัด
                        ไม่สามารถบันทึกผลการคัดเลือก 8 คนได้
                      </span>
                    ) : (
                      ""
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <MaterialTable
                title={"รายชื่อผู้ผ่านการคัดเลือก 8 คน"}
                options={optionsTable}
                columns={columns}
                data={data}
                enableInitColumns={false}
              />

              <CardBody>
                <GridContainer
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <GridItem xs={6} sm={6} md={6}></GridItem>
                  <GridItem>
                    {(isUploadFile4 === false && isUploadFile4_1 === false) ||
                      checkPositionStatusIsNull > 0 ? (
                      ""
                    ) : (
                      <Button color="success" onClick={handelSubmit}>
                        บันทึกผลการคัดเลือก 8 คน
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
};
export default Selection8People;
