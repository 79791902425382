/* core components */
import React, { useContext, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { UserInfoContext } from "contexts/users";
import { Validate } from "utils/Validators/Validate";
import conf from "config/env.conf.js";
//import { UserInfoContext } from "contexts/users";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxMsAreaEct = props => {
  /* declare local state */
  //const { setUserInfo, userInfo } = useContext(UserInfoContext);
  const userInfo = useContext(UserInfoContext);
  let isCenter = userInfo.userInfo.iscenter;
  const [, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [valid, setValid] = React.useState();
  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/ms/msAreaEct",
    []
  );

  let content = (
    <TextField disabled label="เลือกภาค" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return { id: v.areaEctId, value: v.detail };
    });
  }

  const onChangeHandler = (e, v) => {
    if (props.onChange !== undefined) {
      props.onChange(e, v);
    }
    const validators = props.validators;
    if (validators !== undefined && validators.length > 0) {
      let isError, helperText;
      for (let i = 0; i < validators.length; i++) {
        let validatorFunc = validators[i];
        [isError, helperText] = validatorFunc(v);
        if (isError) break;
      }
      setError(isError);
      setHelperText(helperText);
    }
  };

  if (data.length > 0) {
    content = (
      <Autocomplete
        disabled={props.disabled === undefined ? !isCenter : props.disabled}
        // disabled={props.disabled}
        id="listboxMsAreaEct"
        options={data}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={onChangeHandler}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label="เลือกทุกภาค"
              margin="normal"
              error={valid}
              fullWidth
              helperText={helperText}
              inputProps={{
                autoComplete: "new-password",
                //   autoComplete: "off",
                ...params.inputProps
              }}
              onChange={Validate(
                params.inputProps.value,
                props.required,
                setValid
              )}
              required={props.required}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }
  return content;
};
export default ListboxMsAreaEct;
