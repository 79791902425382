import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import { Buffer } from "buffer";
import Button from "@material-ui/core/Button";
import Cookies from "universal-cookie";
import CssBaseline from "@material-ui/core/CssBaseline";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import { Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { UserInfoContext } from "contexts/users";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { auth } from "contexts/API/Login";
import dga from "assets/resources/contact/dga.jpg";
import ectpl from "assets/resources/contact/ectpl.jpg";
import logo from "assets/img/ectpl_logo_login.png";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

import UserManualCenter from "assets/usermanual/DGA-คู่มือการใช้โปรแกรมที่พัฒนาและปรับปรุง_ส่วนกลาง.pdf";
import UserManualProvince from "assets/usermanual/DGA-คู่มือการใช้โปรแกรมที่พัฒนาและปรับปรุง_ส่วนภูมิภาค.pdf";


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "1.5%"
  },
  image: {
    margin: theme.spacing(3, 2, 5),
    textShadow: "3px 3px 4px #000000"
  },
  bodyColor: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "15px 15px 15px 15px"
    //position: "fixed",
    //zIndex: "50%"
  },
  bodyLogin: {
    margin: theme.spacing(3, 0, 3),
    borderRadius: "0px 10px 10px 0px"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#3f51b5"
  },
  textHearder: {
    fontSize: "clamp(12px, 1.6vw, 24px)",
    fontFamily: "Athiti, sans-serif ",
    color: "#FFFFFF",
    // fontSize: '24px',
    fontWeight: 600,
    margin: theme.spacing(0, 2, 4),
    textShadow: "1px 1px #000000"
  },
  text: {
    fontFamily: "Athiti, sans-serif ",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 500,
    margin: theme.spacing(0, 2, 1)
  },
  textFooter: {
    fontFamily: "Athiti, sans-serif ",
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: 600,
    margin: theme.spacing(4, 2, 2),
    textAlign: "center",
    textShadow: "0.5px 0.5px #000000"
  },
  text2: {
    fontFamily: "Athiti, sans-serif ",
    color: "#000",
    fontSize: "16px",
    fontWeight: 250,
    textAlign: "right",
    margin: theme.spacing(3, 0, 3)
  },
  textSubmit: {
    color: "#FFFFFF",
    fontSize: "20px",
    margin: theme.spacing(1)
  },
  imageQR: {
    maxWidth: "100%",
    margin: theme.spacing(1, 0, 0),
    textAlign: "center",
    textShadow: "0.5px 0.5px #000000"
  },
  qrText: {
    fontFamily: "Athiti, sans-serif ",
    textAlign: "center",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 500,
    margin: theme.spacing(1, 0, 1)
  },
  qrHeader: {
    fontFamily: "Athiti, sans-serif ",
    textAlign: "center",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 500,
    margin: theme.spacing(3, 0, 0)
  }
}));

export default function Login(props) {
  const { sweetAlerts } = useSweetAlert();
  const cookies = new Cookies();
  const classes = useStyles();
  const [user, setUser] = useState("");
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false
  });
  let history = useHistory();
  const { userInfo, setUserInfo, isExpired } = useContext(UserInfoContext);

  /* Handler Function */
  const userHandler = value => {
    setUser(value);
  };

  const loginSuccess = () => {
    if (userInfo) {
      if (!isExpired()) {
        return <Redirect to="/main" />;
      }
    }
  };

  const getTokenInfo = token => {
    let tokens = token.split(".");
    let payload = Buffer.from(tokens[1], "base64").toString();
    let data = decodeURIComponent(escape(payload));
    return JSON.parse(data);
  };

  const LoginHandler = () => {
    /* if (user === 'sysadmin' && values.password === 'ectpl1234') {
 */
    auth(user, values.password)
      .then(resp => {
        // console.log("resp---------------", resp);
        if (
          resp.status === 200 &&
          resp.data.data !== undefined &&
          resp.data.data.token !== undefined
        ) {
          if (resp.data.data.isfirstchangepassword === false) {
            console.log("dd");

            history.push({
              pathname: "/changepassword",
              state: { userProfile: resp.data.data }
            });
            // history.push("/changepassword", (state: { some: "state" }));
          } else {
            let token = resp.data.data.token;
            let pageIds = resp.data.data.pageIds;
            let tokenInfo = getTokenInfo(token);

            const usrInfo = {
              pageIds: pageIds,
              token: token,
              tokenInfo: tokenInfo,
              provinceId: resp.data.data.provinceId,
              provinceName: resp.data.data.provinceName,
              iscenter: resp.data.data.iscenter,
              isfirstchangepassword: resp.data.data.isfirstchangepassword,
              isadminwtd: resp.data.data.iscenter
            };

            cookies.set("userInfo", usrInfo, { path: "/" });
            setUserInfo(usrInfo);
          }
        } else {
          alert("Authentication failed");
        }
      })
      .catch(err => {
        // console.log(err.response.data.desc);
        sweetAlerts.warning(
          "ชื่อผู้ใช้งาน/รหัสผ่านผิด",
          err.response.data.desc
        );
        // alert("invalid credential: " + err);
      });

    /*  } else {
       sweetAlerts.warning(
         "ปิดระบบชั่วคราว ขออภัยในความไม่สะดวก", "ระบบจะสามารถใช้งานได้ปกติในวันที่ 17 มีนาคม พ.ศ. 2566"
       );
     } */
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={7}>
          <GridContainer
            component="main"
            className={classes.root}
            justify="center"
          >
            <CssBaseline />
            <GridItem xs={12} sm={12} md={6} className={classes.bodyColor}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} className={classes.image}>
                  <img src={logo} alt="" />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem className={classes.textHearder}>
                  โปรแกรมฐานข้อมูลการเลือกตั้งท้องถิ่น
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} className={classes.text}>
                  - ทะเบียนสมาชิก ส.ถ./ผ.ถ.
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.text}>
                  - ควบคุมการจัดสรรบัตรเลือกตั้ง
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.text}>
                  - ผู้ถูกเพิกถอนสิทธิเลือกตั้ง
                </GridItem>
                <GridItem className={classes.text}>
                  - ฐานข้อมูลผู้ถูกระงับสิทธิสมัครรับเลือกตั้ง
                  <br />
                  <div style={{ paddingLeft: "12px" }}>
                    และเพิกถอนสิทธิสมัครรับเลือกตั้ง
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.text}>
                  - ผู้ตรวจการเลือกตั้ง
                </GridItem>
              </GridContainer>
              {/* QR Header */}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} className={classes.qrHeader}>
                  พบปัญหาการใช้งานระบบโปรดติดต่อ
                </GridItem>
              </GridContainer>
              {/* QR Header */}
              {/* QR */}
              <GridContainer>
                <GridItem xs={6} sm={6} md={6} className={classes.imageQR}>
                  <div>
                    <img
                      src={ectpl}
                      alt="..."
                      style={{ maxWidth: "50%", margin: 0 }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} className={classes.imageQR}>
                  <div>
                    <img
                      src={dga}
                      alt="..."
                      style={{ maxWidth: "50%", margin: 0 }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              {/* QR */}

              {/* QR Description */}
              <GridContainer>
                <GridItem xs={6} sm={6} md={6} className={classes.qrText}>
                  ปัญหาการใช้งาน (กกต.)
                </GridItem>
                <GridItem xs={6} sm={6} md={6} className={classes.qrText}>
                  ปัญหาด้านเทคนิค (สพร.)
                </GridItem>
              </GridContainer>
              {/* QR Description */}
              <GridContainer>
                <GridItem xs={6} sm={6} md={6} >
                  <a href={UserManualProvince} without target='_blank' rel='noopener noreferrer'>
                    <div className={classes.textFooter}>คู่มือการใช้งานจังหวัด </div>
                  </a>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} >
                  <a href={UserManualCenter} without target='_blank' rel='noopener noreferrer'>
                    <div className={classes.textFooter}>คู่มือการใช้งานส่วนกลาง </div>
                  </a>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              component={Paper}
              elevation={6}
              square
              className={classes.bodyLogin}
            >
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h4">
                  เข้าสู่ระบบ
                </Typography>
                <form className={classes.form} noValidate>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="ชื่อผู้ใช้งาน"
                        id="username"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          value: user,
                          onChange: event => {
                            userHandler(event.target.value);
                          }
                        }}
                      />
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText='รหัสผ่าน'
                        id='password'
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: 'password',
                          autoComplete: 'off',
                          value: password,
                          onChange: passwordHandler,
                        }}
                      />
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="รหัสผ่าน"
                        id="password"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: values.showPassword ? "text" : "password",
                          autoComplete: "off",
                          value: values.password,
                          onChange: handleChange("password"),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} className={classes.text2}>
                      {/* <Link href="#" variant="body2">ลืมรหัสผ่าน</Link> <br /> */}
                      *ลืมรหัสผ่าน
                      <br />
                      โปรดติดต่อผู้ดูแลระบบของท่าน
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        onClick={LoginHandler}
                      >
                        <div className={classes.textSubmit}>เข้าสู่ระบบ</div>
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {loginSuccess()}
    </>
  );
}
