import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { Validate } from "utils/Validators/Validate";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxInspectProvinceGroup = props => {
    const [valid, setValid] = React.useState();
    const [, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState("");

    const [isLoading, fetchData] = useFetch(
        conf.END_POINT_URL + "/inspector/province/groupno",
        [1]
    );

    let content = (
        <TextField disabled label="เลือกกลุ่มจังหวัด" margin="normal" fullWidth />
    );

    let data = [];

    if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
        data = fetchData.data.map(v => {
            return { id: v.group_no, value: String(v.group_desc) };
        });
    }

    const onChangeHandler = (e, v) => {
        if (props.onChange !== undefined) {
            props.onChange(e, v);
        }
        const validators = props.validators;
        if (validators !== undefined && validators.length > 0) {
            let isError, helperText;
            for (let i = 0; i < validators.length; i++) {
                let validatorFunc = validators[i];
                [isError, helperText] = validatorFunc(v);
                if (isError) break;
            }
            setError(isError);
            setHelperText(helperText);
        }
    };
    if (data.length > 0) {
        content = (
            <Autocomplete
                disabled={props.disabled}
                id="listBoxInspectProvinceGroupo"
                options={data}
                defaultValue={getDefaultValue(data, props.defaultValue)}
                value={props.value}
                getOptionLabel={option => option.value}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={onChangeHandler}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            label="เลือกกลุ่มจังหวัด"
                            margin="normal"
                            error={valid}
                            fullWidth
                            helperText={helperText}
                            onChange={Validate(
                                params.inputProps.value,
                                props.required,
                                setValid
                            )}
                            required={props.required}
                        />
                    );
                }}
                {...props.customProps}
            />
        );
    }
    return content;
};
export default ListboxInspectProvinceGroup;
