import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { Validate } from "utils/Validators/Validate";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
import { useFetch } from "hooks/useFetch";

const ListboxPositionFollowVoteDate = props => {
  const [valid, setValid] = React.useState();
  let query = "";
  if (props.optSelected) {
    query += `/opt/${props.optSelected.id}`;
  }

  if (props.optVoteTypeSelected) {
    query += `/position/voteType/${props.optVoteTypeSelected.id}`;
  }

  if (props.votedateSelected) {
    query += `/date/${props.votedateSelected.id}`;
  }

  const [isLoading, fetchData] = useFetch(conf.END_POINT_URL + query, [
    props.optSelected,
    props.optVoteTypeSelected,
    props.votedateSelected
  ]);
  const [, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  let content = (
    <TextField disabled label="เลือกประเภทตำแหน่ง." margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return { id: v.positionTypeId, value: v.positionTypeShortName };
    });
  }
  if (data.length > 0) {
    content = (
      <div>
        <Autocomplete
          disabled={props.disabled}
          name="positionType"
          options={data}
          defaultValue={getDefaultValue(data, props.defaultValue)}
          value={props.value}
          getOptionLabel={option => option.value}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={AutocompleteValidator(
            props.onChange,
            props.validators,
            setError,
            setHelperText
          )}
          renderInput={params => {
            return (
              <TextField
                {...params}
                label={props.title ? props.title : "เลือกทุกตำแหน่ง"}
                margin="normal"
                error={valid}
                fullWidth
                helperText={helperText}
                inputProps={{
                  autoComplete: "new-password",
                  //   autoComplete: "off",
                  ...params.inputProps
                }}
                onChange={Validate(
                  params.inputProps.value,
                  props.required,
                  setValid
                )}
                required={props.required}
              />
            );
          }}
          {...props.customProps}
        />
      </div>
    );
  }
  return content;
};
export default ListboxPositionFollowVoteDate;
