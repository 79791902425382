// TransposeObj transpose array to object with 'keyName' as key
export default function TransposeObj(objs, keyName) {
  const newObj = {};

  if (objs.length <= 0) return newObj;

  objs.forEach((v) => {
    let key = v[`${keyName}`];
    if (key) {
      newObj[key] = v;
    }
  });

  return newObj;
}
