import React, {createContext, useState} from 'react'
import Cookies from 'universal-cookie/es6';

export const UserInfoContext = createContext({});

export const UserInfoContextProvider = ({ children }) => {
    const ck = new Cookies();
    const [userInfo, setUserInfo] = useState(ck.get("userInfo"));
    const [isReLogin, setIsReLogin] = useState(false);
    const value = {
        isReLogin: isReLogin,
        setIsReLogin: setIsReLogin,
        userInfo: userInfo,
        setUserInfo: setUserInfo,
        canReLogin: () => {
            if( userInfo && userInfo.tokenInfo !== undefined) {
                return true;
            }
            return false;
        },
        isExpired: () => {
            if( userInfo && userInfo.tokenInfo !== undefined) {
                let status = userInfo.tokenInfo.exp < Math.floor(Date.now() / 1000)
                return status;
            }
            return true;
        },
        logout: () => {
            ck.remove("userInfo");
            setUserInfo(null);
        }
    };

    return <UserInfoContext.Provider value={value}>{ children }</UserInfoContext.Provider>
}  