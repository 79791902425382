import React, { useContext, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Cookies from "universal-cookie";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { UserInfoContext } from "contexts/users";
import Visibility from "@material-ui/icons/Visibility";
import { changePassword } from "contexts/API/Login";
import logo from "assets/img/ectpl_logo_login.png";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/changePassScreenPageStyle.js";
import { useSweetAlert } from "components/Alert/SweetAlert";

// core components

const useStyles = makeStyles(styles);

export default function ChangePassword(props) {
  let history = useHistory();
  const cookies = new Cookies();
  console.log(props);

  let profile = "";
  if (props.location.state === undefined) {
    history.push("/login");
  }
  if (props.location.state) {
    profile = props.location.state.userProfile;
  }
  const { sweetAlerts } = useSweetAlert();

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const [isShow, setIsShow] = useState(false);
  const [helpertextpass2, setHelpertextpass2] = useState("");
  const [helpertextoldpass, setHelpertextoldpass] = useState("");
  const getTokenInfo = token => {
    let tokens = token.split(".");
    let payload = Buffer.from(tokens[1], "base64").toString();
    let data = decodeURIComponent(escape(payload));
    return JSON.parse(data);
  };

  const {
    //userInfo,
    setUserInfo,
    //isExpired
  } = useContext(UserInfoContext);
  const [data, setData] = useState({
    password: "",
    oldPassword: "",
    confirmPassword: ""
  });
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  const handelShow = () => {
    setIsShow(!isShow);
  };

  const changePass = () => {
    let token = profile.token;
    let pageIds = profile.pageIds;
    let tokenInfo = getTokenInfo(token);
    const usrInfo = {
      pageIds: pageIds,
      token: token,
      tokenInfo: tokenInfo,
      provinceId: profile.provinceId,
      provinceName: profile.provinceName,
      iscenter: profile.iscenter
    };
    cookies.set("userInfo", usrInfo, { path: "/" });
    setUserInfo(usrInfo);
    if (!data.oldPassword) {
      setHelpertextoldpass("กรุณาระบุ*");
    }
    if (!data.confirmPassword) {
      setHelpertextpass2("กรุณาระบุ*");
    }
    if (
      data.oldPassword &&
      data.password &&
      data.confirmPassword &&
      helpertextpass2 === ""
    ) {
      //history.push("/main");
      changePassword(data.oldPassword, data.password, tokenInfo.data.uid)
        .then(r => {
          history.push("/main");
        })
        .catch(err => {
          console.log(err);
          sweetAlerts.error("เกิดข้อผิดพลาด", err.response.data.desc);
        });
    }
  };
  let content = "";
  if (props.location.state === undefined) {
    content = <Redirect to="/login" />;
  } else {
    content = (
      <div className={classes.container}>
        <Card
          profile
          className={classes.customCardClass + " " + classes[cardAnimaton]}
        >
          <div className={classes.img}>
            <a href="#pablo" onClick={e => e.preventDefault()}>
              <img src={logo} alt="..." />
            </a>
          </div>
          <CardBody profile>
            <h4 className={classes.cardTitle}>กรุณาเปลี่ยนรหัสผ่าน</h4>

            <TextField
              id="standard-full-width"
              className={classes.textfield}
              variant="outlined"
              type="password"
              placeholder="รหัสผ่านเดิม"
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: {
                  fontFamily: "Athiti, sans-serif",
                  fontSize: 16
                }
              }}
              InputProps={{
                value: data.oldPassword || "",
                autoComplete: "off",
                style: {
                  fontSize: 16
                },
                onChange: e => {
                  if (e.target.value) {
                    setData({ ...data, oldPassword: e.target.value });
                    setHelpertextoldpass("");
                  } else {
                    setData({
                      ...data,
                      oldPassword: ""
                    });
                    setHelpertextoldpass("กรุณาระบุ*");
                  }
                }
              }}
            />
            <p style={{ color: "red", textAlign: "left" }}>
              {helpertextoldpass}
            </p>
            <TextField
              id="standard-full-width"
              className={classes.textfield}
              // error={passError}
              autoComplete="current-password"
              type={isShow ? "text" : "password"}
              variant="outlined"
              placeholder="รหัสผ่านใหม่"
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: {
                  fontFamily: "Athiti, sans-serif",
                  fontSize: 16
                }
              }}
              InputProps={{
                value: data.password || "",
                autoComplete: "off",
                endAdornment: (
                  <InputAdornment position="end" onClick={handelShow}>
                    <Visibility />
                  </InputAdornment>
                ),
                style: {
                  fontSize: 16
                },
                onChange: e => {
                  if (e.target.value) {
                    setData({ ...data, password: e.target.value });
                  } else {
                    setData({
                      ...data,
                      password: ""
                    });
                  }
                }
              }}
            />

            <TextField
              id="standard-full-width"
              className={classes.textfield}
              // error={passError}
              type="password"
              variant="outlined"
              placeholder="ยืนยันรหัสผ่านใหม่"
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: {
                  fontFamily: "Athiti, sans-serif",
                  fontSize: 16
                }
              }}
              InputProps={{
                autoComplete: "off",
                style: {
                  fontSize: 16
                },
                onChange: e => {
                  if (e.target.value) {
                    setData({ ...data, confirmPassword: e.target.value });
                    setHelpertextpass2("");
                    if (data.password !== e.target.value) {
                      setHelpertextpass2("รหัสผ่านไม่ตรงกัน");
                    } else {
                      setHelpertextpass2("");
                    }
                  } else {
                    setData({
                      ...data,
                      confirmPassword: ""
                    });
                    setHelpertextpass2("กรุณาระบุ*");
                  }
                }
              }}
            />
            <p style={{ color: "red", textAlign: "left" }}>{helpertextpass2}</p>
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button color="success" onClick={changePass}>
              เปลี่ยนรหัสผ่าน
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  }

  return content;
}
