import React, { useContext, useEffect, useState } from "react";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import { useMediaQuery } from "@material-ui/core";
import logo from "assets/img/ectpl_logo_login.png";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { findAllPopups, markReadPopups } from "../../contexts/API/Notifications/Popups";
import { UserInfoContext } from "../../contexts/users";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '36px',
    },
    subtitle: {
        fontSize: '24px',
    }
}));

const MAX_UNREAD = 40; // only 40 unread messages will be displayed
const Popups = () => {
    const classes = useStyles();
    const { userInfo } = useContext(UserInfoContext);
    const { sweetAlerts } = useSweetAlert();
    const [popups, setPopup] = useState([]);
    const [isEnabled, setEnable] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const isMobile = useMediaQuery("(max-width:600px)");

    const onAccept = () => {
        const id = popups[currentIndex].id;
        const userName = (userInfo && userInfo.tokenInfo && userInfo.tokenInfo.data.firstname) ? userInfo.tokenInfo.data.firstname : "ไม่ระบุชื่อ";
        sweetAlerts.warning("ยืนยันรับทราบประกาศ", `คุณ ${userName} ขอยืนยันว่ารับทราบประกาศดังกล่าวแล้ว ข้อความนี้จะไม่แสดงอีกต่อไป`, () => {
            markReadPopups(id).then(() => {
                setPopup([...popups.slice(0, currentIndex)
                    , ...popups.slice(currentIndex + 1)
                ]);
            }).catch(err => console.error(err))
        }, true,)

    };

    useEffect(() => {
        if (popups.length === 0 && isEnabled === true) {
            //console.log("iscenter >>>>", userInfo.iscenter)
            //console.log("provinceId >>>>", userInfo.provinceId)
            let query = "?isRead=false";
            if (userInfo.iscenter) {
                query += `&isCenter=${userInfo.iscenter}`;
            } else {
                query += `&isCenter=false&provinceId=${userInfo.provinceId}`;
            }
            findAllPopups(query).then(r => {
                if (r.data && r.data.data && r.data.data.length >= 1) {
                    console.log(r.data.data)
                    setPopup(r.data.data);
                }
            }).catch(err => console.error(err))
        }
    }, [popups])

    return (
        <div>
            {
                (popups.length > 0 && isEnabled === true) ?
                    <AutoRotatingCarousel
                        label="รับทราบ"
                        open={isEnabled}
                        onClose={() => setEnable(false)}
                        onChange={(index) => {
                            setCurrentIndex(index);
                        }}
                        onStart={() => onAccept()}
                        autoplay={false}
                        mobile={isMobile}
                        style={{ position: "absolute" }}
                        ButtonProps={{ color: 'secondary' }}
                    >
                        {
                            popups.sort((a, b) => {
                                if (a.createDatetime < b.createDatetime) {
                                    return 1;
                                } else if (a.createDatetime >= b.createDatetime) {
                                    return -1;
                                }
                                return 0;
                            }).slice(0, Math.min(popups.length, MAX_UNREAD))
                                .map((v, i) => (
                                    <Slide
                                        key={i}
                                        media={
                                            <img src={logo} />
                                        }
                                        mediaBackgroundStyle={{
                                            backgroundColor: '#3f51b5',
                                            height: "50%"
                                        }}

                                        style={{ backgroundColor: '#3f51b5' }}
                                        title={v.header}
                                        classes={classes}
                                        subtitle={v.body}
                                    />
                                ))
                        }
                    </AutoRotatingCarousel> : <div />
            }
        </div>
    );
};

export default Popups;