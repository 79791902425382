import React, { createContext, useContext, useState } from "react";

import { UserInfoContext } from "contexts/users";

export const VoteDateContext = createContext();

export const VoteDateContextProvider = ({ children }) => {
  const { userInfo } = useContext(UserInfoContext);
  let userProvinceId = null;
  // let userProvinceId = {
  //   id: 0,
  //   value: ""
  // };
  let isCenter = userInfo.iscenter;
  if (!isCenter) {
    userProvinceId = {
      id: userInfo.provinceId,
      value: userInfo.provinceName
    };
  }

  const [areaEctSelected, setAreaEctSelected] = useState(null);
  const [provinceSelected, setProvinceSelected] = useState(userProvinceId);
  // const [provinceSelected, setProvinceSelected] = useState(
  //   userProvinceId ? userProvinceId : { id: 0 }
  // );
  const [zoneSelected, setZoneSelected] = useState(null);
  const [amphurSelected, setAmphurSelected] = useState(null);
  const [tambolSelected, setTambolSelected] = useState(null);
  const [positionStatusSelected, setPostionStatusSelected] = useState(null); //สาเหตุการจัดการเลือกตั้ง
  const [votedateSelected, setVotedateSelected] = useState(null);
  const [optVoteTypeSelected, setOptVoteTypeSelected] = useState(null);
  const [listboxOptSelected, setListboxOptSelected] = useState(null);
  const [aumphurAreaSelected, setAumphurAreaSelected] = useState(null); //เขตอำเภอ
  const [areaSelected, setAreaSelected] = useState(null);
  const [positionTypeSelected, setPostionTypeSelected] = useState(null); //สถ ผถ
  const [votecardNumberSelected, setVotecardNumberSelected] = useState(null);
  const [votecardColorSelected, setVotecardColorSelected] = useState(null);
  const [votecardPrintingSelected, setVotecardPrintingSelected] = useState(
    null
  );
  const [optChgStatusSelected, setOptChgStatusSelected] = useState(null);
  const [votecardOptTypeSelected, setVotecardOptTypeSelected] = useState(null);
  const [
    votecardPurchaseStatusSelected,
    setVotecardPurchaseStatusSelected
  ] = useState(null);
  const [voteCardPurchaseDocumentNo, setVoteCardPurchaseDocumentNo] = useState(null);
  const [
    positionFollowVoteDateSelected,
    setPositionFollowVoteDateSelected
  ] = useState(null);
  const [replacePesonPresSelected, setReplacePesonPresSelected] = useState(
    null
  );
  const [titleSelected, setTitleSelected] = useState(null);
  const [universitySelected, setUniversitySelected] = useState(null);
  const defaultOptTypeSelected = {
    1: { checked: false, value: 1 },
    2: { checked: false, value: 2 },
    3: { checked: false, value: 3 },
    4: { checked: false, value: 4 },
    5: { checked: false, value: 5 },
    6: { checked: false, value: 6 },
    7: { checked: false, value: 7 },
    8: { checked: false, value: 8 },
    9: { checked: false, value: 9 },
    10: { checked: false, value: 10 }
  };
  const [optTypeSelected, setOptTypeSelected] = useState(
    defaultOptTypeSelected
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [voteStatusSelected, setVoteStatusSelected] = useState(null);
  const [optSelected, setOptSelected] = useState(null);
  const [form6TypeSelected, setForm6TypeSelected] = useState(null);

  const value = {
    optChgStatusSelected: optChgStatusSelected,
    setOptChgStatusSelected: setOptChgStatusSelected,
    onOptChgStatusSelected: (evt, newValue) => {
      setOptChgStatusSelected(newValue);
    },

    zoneSelected: zoneSelected,
    setZoneSelected: setZoneSelected,
    onZoneSelected: (evt, newValue) => {
      setZoneSelected(newValue);
    },
    universitySelected: universitySelected,
    setUniversitySelected: setUniversitySelected,
    onUniversitySelected: (evt, newValue) => {
      setUniversitySelected(null);
    },

    form6TypeSelected: form6TypeSelected,
    setForm6TypeSelected: setForm6TypeSelected,
    onForm6TypeSelected: (evt, newValue) => {
      setForm6TypeSelected(newValue);
    },

    titleSelected: titleSelected,
    setTitleSelected: setTitleSelected,
    onTitleSelected: (evt, newValue) => {
      setTitleSelected(newValue);
    },
    areaSelected: areaSelected,
    setAreaSelected: setAreaSelected,
    onAreaSelected: (evt, newValue) => {
      setAreaSelected(newValue);
      //   setProvinceSelected(null);
    },

    provinceSelected: provinceSelected,
    setProvinceSelected: setProvinceSelected,
    onProvinceSelected: (evt, newValue) => {
      setProvinceSelected(newValue);
      setAmphurSelected(null);
      setTambolSelected(null);
      setOptSelected(null);
      setZoneSelected(null);
    },
    amphurSelected: amphurSelected,
    setAmphurSelected: setAmphurSelected,
    onAmphurSelected: (evt, newValue) => {
      setAmphurSelected(newValue);
      setTambolSelected(null);
      setOptSelected(null);
    },
    tambolSelected: tambolSelected,
    setTambolSelected: setTambolSelected,
    onTambolSelected: (evt, newValue) => {
      setTambolSelected(newValue);
      setOptSelected(null);
    },
    optVoteTypeSelected: optVoteTypeSelected, //เลือกประเภทการเลือกตั้ง
    setOptVoteTypeSelected: setOptVoteTypeSelected,
    onOptVoteTypeSelected: (evt, newValue) => {
      setOptVoteTypeSelected(newValue);
      setVotedateSelected(null);
    },
    optTypeSelected: optTypeSelected,
    setOptTypeSelected: setOptTypeSelected,
    onOptTypeSingleSelected: (evt, newChecked) => {
      if (
        optTypeSelected[`${evt.target.name}`] === undefined ||
        newChecked !== optTypeSelected[`${evt.target.name}`].checked
      ) {
        setOptTypeSelected({
          ...defaultOptTypeSelected,
          [`${evt.target.name}`]: {
            checked: evt.target.checked,
            value: evt.target.value
          }
        });
        setOptSelected(null);
      }
    },
    onOptTypeSelected: (evt, newChecked) => {
      if (
        optTypeSelected[`${evt.target.name}`] === undefined ||
        newChecked !== optTypeSelected[`${evt.target.name}`].checked
      ) {
        setOptTypeSelected({
          ...optTypeSelected,
          [`${evt.target.name}`]: {
            checked: evt.target.checked,
            value: evt.target.value
          }
        });
        setOptSelected(null);
      }
    },
    optSelected: optSelected,
    setOptSelected: setOptSelected,
    onOptSelected: (evt, newValue) => {
      setOptSelected(newValue);
      setAumphurAreaSelected(null);
      setVotedateSelected(null);
      setAreaSelected(null);
    },

    replacePesonPresSelected: replacePesonPresSelected,
    setReplacePesonPresSelected: setReplacePesonPresSelected,
    onReplacePesonPresSelected: (evt, newValue) => {
      setReplacePesonPresSelected(newValue);
    },

    listboxOptSelected: listboxOptSelected, //เลือกประเภทอปท แบบ dropdown
    setListboxOptSelected: setListboxOptSelected,
    onListboxOptSelected: (evt, newValue) => {
      setListboxOptSelected(newValue);
      // setProvinceSelected(null);
    },

    startDate: startDate,
    setStartDate: setStartDate,
    endDate: endDate,
    setEndDate: setEndDate,
    voteStatusSelected: voteStatusSelected,
    setVoteStatusSelected: setVoteStatusSelected,
    onVoteStatusSelected: (evt, newValue) => {
      //สถานะการจัดการเลือกตั้ง อยู่ระหว่างดำเนินการ,ดำเนินกรเสร็จเ้น , ไม่สามารถดำเนิการได้
      if (newValue) {
        setVoteStatusSelected(newValue);
      }
    },

    votedateSelected: votedateSelected,
    setVotedateSelected: setVotedateSelected,
    onVotedateSelected: (evt, newValue) => {
      setVotedateSelected(newValue);
    },
    aumphurAreaSelected: aumphurAreaSelected,
    setAumphurAreaSelected: setAumphurAreaSelected,
    onAumphurAreaSelected: (evt, newValue) => {
      setAumphurAreaSelected(newValue);
      setAreaSelected(null);
    },

    positionStatusSelected: positionStatusSelected,
    setPostionStatusSelected: setPostionStatusSelected,
    onPositionStatusSelected: (evt, newValue) => {
      setPostionStatusSelected(newValue);
    },
    positionTypeSelected: positionTypeSelected,
    setPostionTypeSelected: setPostionTypeSelected,
    onPostionTypeSelected: (evt, newValue) => {
      setPostionTypeSelected(newValue);
      setForm6TypeSelected(null);
      setAreaSelected(null);
    },

    positionFollowVoteDateSelected: positionFollowVoteDateSelected,
    setPositionFollowVoteDateSelected: setPositionFollowVoteDateSelected,
    onPositionFollowVoteDateSelected: (evt, newValue) => {
      setPositionFollowVoteDateSelected(newValue);
      setAreaSelected(null);
    },

    areaEctSelected: areaEctSelected,
    setAreaEctSelected: setAreaEctSelected,
    onAreaEctSelected: (evt, newValue) => {
      setAreaEctSelected(newValue);
      //  setProvinceSelected(null);
    },

    //---จัดสรรบัตร

    votecardPrintingSelected: votecardPrintingSelected,
    setVotecardPrintingSelected: setVotecardPrintingSelected,
    onVotecardPrintingSelected: (evt, newValue) => {
      setVotecardPrintingSelected(newValue);
      setVotecardColorSelected(null);
      setVotecardNumberSelected(null);
    },

    votecardOptTypeSelected: votecardOptTypeSelected,
    setVotecardOptTypeSelected: setVotecardOptTypeSelected,
    onVotecardOptTypeSelected: (evt, newValue) => {
      setVotecardOptTypeSelected(newValue);
      setVotecardColorSelected(null);
      setVotecardNumberSelected(null);
    },

    votecardColorSelected: votecardColorSelected,
    setVotecardColorSelected: setVotecardColorSelected,
    onVotecardColorSelected: (evt, newValue) => {
      setVotecardColorSelected(newValue);
      setVotecardNumberSelected(null);
    },

    votecardNumberSelected: votecardNumberSelected,
    setVotecardNumberSelected: setVotecardNumberSelected,
    onVotecardNumberSelected: (evt, newValue) => {
      setVotecardNumberSelected(newValue);
    },

    votecardPurchaseStatusSelected: votecardPurchaseStatusSelected,
    setVotecardPurchaseStatusSelected: setVotecardPurchaseStatusSelected,
    onVotecardPurchaseStatusSelected: (evt, newValue) => {
      setVotecardPurchaseStatusSelected(newValue);
    },

    //---จัดสรรบัตร
    // utilities
    getOptTypeSelectedLists: () => {
      let optIdLists = [];
      for (const [, v] of Object.entries(optTypeSelected)) {
        //    console.log("optTypeSelected", optTypeSelected);
        if (v.checked) {
          optIdLists.push(v.value);
        }
      }
      return optIdLists;
    },
    getOptTypeSelectedListsArray: () => {
      let optIdLists = [];
      for (const [, v] of Object.entries(optTypeSelected)) {
        //    console.log("optTypeSelected", optTypeSelected);
        if (v.checked) {
          optIdLists.push({ id: v.value });
        }
      }
      return optIdLists;
    }
  };
  return (
    <VoteDateContext.Provider value={value}>
      {children}
    </VoteDateContext.Provider>
  );
};
