import React, {useContext} from "react";
import {UserInfoContext} from "../contexts/users";

const HandelError = error => {
  const {userInfo, setIsReLogin} = useContext(UserInfoContext)

  switch (error.code) {
    case 400:
      break;
    case 401:
      setIsReLogin(true);
      break;
    case 404:
      // Show 404 page
      break;
    case 500:
      // Serveur Error redirect to 500
      break;
    default:
      // Unknow Error
      break;
  }
};

export default HandelError;
