import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
//-------------ประเภทหน่วยงาน-----------------//
export function getwtdOrgType() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdOrgType`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editwtdOrgType(orgId, orgName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdOrgType/${orgId}`;

  const body = {
    detail: orgName
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deletewtdOrgType(orgId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdOrgType/${orgId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addwtdOrgType(orgName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdOrgType/`;

  const body = {
    detail: orgName
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//-------------หน่วยงาน-----------------//

export function getwtdOrg() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdOrg`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editwtdOrg(wtdOrgId, wtdOrgTypeId, orgName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdOrg/${wtdOrgId}`;

  const body = {
    orgName: orgName,
    wtdOrgTypeId: wtdOrgTypeId
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deletewtdOrg(wtdOrgId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdOrg/${wtdOrgId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addwtdOrg(wtdOrgTypeId, orgName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdOrg`;

  const body = {
    orgName: orgName,
    wtdOrgTypeId: wtdOrgTypeId
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//-------------มาตรา-----------------//

export function getwtdLawSection() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdLawSection`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editwtdLawSection(wtdLawSectionId, wtdLawTypeId, detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdLawSection/${wtdLawSectionId}`;

  const body = {
    wtdLawTypeId: wtdLawTypeId,
    detail: detail
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addwtdLawSection(wtdLawTypeId, detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdLawSection`;

  const body = {
    wtdLawTypeId: wtdLawTypeId,
    detail: detail
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deletewtdLawSection(wtdLawSectionId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdLawSection/${wtdLawSectionId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//-------------กฏหมาย-----------------//
export function getwtdLawType() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdLawtype`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editwtdLawType(wtdLawTypeId, detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdLawType/${wtdLawTypeId}`;

  const body = {
    detail: detail
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addwtdLawType(detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdLawType`;

  const body = {
    detail: detail
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deletewtdLawType(wtdLawTypeId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdLawtype/${wtdLawTypeId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//-------------ประเภทการเลือกตั้ง-----------------//

export function getwtdVoteType() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdVoteType`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editwtdVoteType(wtdVoteTypeId, detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdVoteType/${wtdVoteTypeId}`;
  const body = {
    detail: detail
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function deletewtdVoteType(wtdVoteTypeId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdVoteType/${wtdVoteTypeId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addwtdVoteType(detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/wtdVoteType`;

  const body = {
    detail: detail
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
