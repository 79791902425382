import React, { useContext, useEffect, useState } from "react";

import Button from "components/CustomButtons/Button.js";
//import Toolbar from "@material-ui/core/Toolbar";
//import Typography from "@material-ui/core/Typography";
import CardHeader from "components/Card/CardHeader.js";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import DatepickerVotedate from "components/Common/Datepicker";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import ListboxProvince from "components/Common/ListboxProvince";
import MenuItem from "@material-ui/core/MenuItem";
import { Paper } from "@material-ui/core";
import { REPORTING_SERVICE } from "config/env.conf";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import TableComponent from "components/Table/MaterialTable";
import { WtdContext } from "contexts/Wtd";
import { getReport02 } from "contexts/API/WTD/CheckWtd";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalFormReport = props => {
  const { onProvinceSelected, provinceSelected } = useContext(WtdContext);

  const { sweetAlerts } = useSweetAlert();
  const { showModalReport1, closeModalReport1 } = props;
  const classes = useStyles();
  const [, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);

  const [type, setType] = useState(1);
  const [areaDisable, setAreaDisable] = useState(false);
  const [, setIsFromValid] = useState(true);
  const [, setIsAreaValid] = useState(true);
  //const [isChairmanValid, setIsChairmanValid] = useState(true);
  // const [isTypeValid, setIsTypeValid] = useState(true);
  const [area, setArea] = useState("");
  const [data, setData] = useState([]);

  const [chairman, setChairman] = useState("");

  const handleStatus = e => {
    setType(e.target.value);

    // setIsTypeValid(false);
    if (e.target.value === 2) {
      setAreaDisable(true);
      //setIsAreaValid(false);
    } else {
      setAreaDisable(false);
    }
  };
  const columns = [
    {
      title: "wtdPersonId",
      field: "wtdPersonId",
      hidden: true
    },
    {
      title: "ผู้ถูกเพิกถอนสิทธิ",
      field: "wtdName"
    },
    {
      title: "ภูมิลำเนา",
      field: "address"
    },
    {
      title: "ระยะเวลาถูกเพิกถอน",
      field: "wtdYear"
    },
    {
      title: "วันที่สิ้นสุดการถูกเพิกถอน",
      field: "wtdEdate",
      render: rowData => {
        return ConvertDate(rowData.wtdEdate);
      }
    }
  ];

  useEffect(() => {
    if (area) {
      setIsAreaValid(false);
    } else {
      setIsAreaValid(true);
    }

    // if (chairman) {
    //   setIsChairmanValid(false);
    // } else {
    //   setIsChairmanValid(true);
    // }
  }, [area]);

  const handleSubmitReport1 = () => {
    if (provinceSelected.id === 0) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }
    // if (area === "" && type === 1) {
    //   sweetAlerts.warning("กรุณาระบุเขต");
    //   return;
    // }
    // if (fromDaste === "") {
    //   sweetAlerts.warning("กรุณาระบุวันที่");
    //   return;
    // }
    // if (chairman === "") {
    //   sweetAlerts.warning("กรุณาระบุกรรมการเลือกตั้ง");
    //   return;
    // }
    else {
      setLoading(true);
      getReport02(provinceSelected.id).then(result => {
        setData(result.data.data);
      });
      setLoading(false);
    }
  };

  return (
    <div className={showModalReport1 ? "modal" : "hide"}>
      <Dialog
        open={showModalReport1}
        fullWidth={true}
        //fullScreen
        maxWidth={"lg"}
        transition={Transition}
        keepMounted
        onClose={closeModalReport1}
        aria-labelledby="form-dialog-title"
      >
        {/* <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeModalReport1}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.titleReport}
            </Typography>
          </Toolbar>
        </AppBar> */}
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.title}>{props.titleReport}</h4>
          </CardHeader>
        </DialogTitle>

        <DialogContent>
          <>
            {["2_1"].includes(props.indexReport) ? (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      style={{ marginTop: "15px" }}
                    >
                      <InputLabel>ประเภท</InputLabel>
                      <Select
                        value={type}
                        //  error={isTypeValid}
                        onChange={handleStatus}
                      >
                        <MenuItem value={1}>ส.ส. 14 </MenuItem>
                        <MenuItem value={2}>ส.ว. 14 </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <ListboxProvince
                      required={true}
                      value={provinceSelected}
                      onChange={onProvinceSelected}
                    ></ListboxProvince>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="เขต"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: area || "",
                        // error: isAreaValid,
                        disabled: areaDisable,
                        onChange: e => {
                          let num = e.target.value.match(/^[0-9]+$/);
                          if (num !== null) {
                            setIsAreaValid(false);
                            setArea(e.target.value);
                          } else {
                            setIsAreaValid(true);
                          }
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <DatepickerVotedate
                      id="begin"
                      label="ประกาศ ณ วันที่"
                      value={fromDate}
                      //  valid={isFromValid}
                      onChange={date => {
                        if (date) {
                          let arr = date.format().split("T");
                          setFromDate(arr[0]);
                          setIsFromValid(false);
                        } else {
                          setFromDate(null);
                          setIsFromValid(true);
                        }
                      }}
                    ></DatepickerVotedate>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="กรรมการเลือกตั้งประจำเขต"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: chairman,
                        // error: isChairmanValid,
                        onChange: e => {
                          if (e.target.value) setChairman(e.target.value);
                          else {
                            setChairman(null);
                          }
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </>
            ) : (
              ""
            )}

            <br></br>
            <GridContainer
              direction="column"
              justify="flex-end"
              alignItems="flex-end"
            >
              <GridItem xs={12} sm={12} md={6}>
                <Button onClick={closeModalReport1}>ปิดหน้าต่าง</Button>
                <Button color="success" onClick={handleSubmitReport1}>
                  ค้นหา
                </Button>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TableComponent
                  title="รายละเอียดการค้นหา"
                  columns={columns}
                  data={data}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />,
                    Action: props => {
                      if (props.action.icon === "edit") {
                        return (
                          <Button
                            onClick={event =>
                              props.action.onClick(event, props.data)
                            }
                            color="warning"
                            variant="contained"
                            style={{ textTransform: "none" }}
                            size="sm"
                          >
                            แสดงรายงาน
                          </Button>
                        );
                      }
                    }
                  }}
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Save User",

                      onClick: (evt, data) => {
                        let wtdLists = [];
                        for (const [, v] of Object.entries(data)) {
                          wtdLists.push(v.wtdPersonId);
                        }
                        let wtdPersonId = wtdLists.toString();
                        //let url = `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt2_Grouping&rs:Command=Render&rc:Parameters=Collapsed%20&wtdPersonId=${wtdPersonId}&wtd_vote_type=${type}&province_id=${provinceSelected.id}&area_no=${area}&notice_date=${fromDate}&chairman=${chairman}`;
                        let url = `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rpt2_Group&rs:Command=Render&rc:Parameters=Collapsed&wtdPersonId=${wtdPersonId}&wtd_vote_type=${type}&province_id=${
                          provinceSelected.id
                        }&area_no=${area !== "" ? area : 0}&notice_date=${
                          fromDate !== null
                            ? fromDate
                            : moment(new Date()).format("YYYY-MM-D")
                        }&chairman=${chairman !== "" ? chairman : 0}`;
                        window.open(`${url}`, "_blank");
                      }
                    }
                  ]}
                  localization={{
                    toolbar: {
                      nRowsSelected: "เลือกทั้งหมด {0} รายการ."
                    }
                  }}
                  options={{
                    selection: true,
                    exportButton: false
                  }}
                />
              </GridItem>
            </GridContainer>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalFormReport;
