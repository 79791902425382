import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getCandidate8(provinceId, cmdApplyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/cadidates8/list/provinceId/${provinceId}/cmdApplyDateId/${cmdApplyDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function unlockApply(provinceId, cmdApplyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/unlockApplyCadidates16/provinceId/${provinceId}/cmdApplyDateId/${cmdApplyDateId}`;
  const body = "";
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function putUploadFile(provinceId, cmdApplyDateId, docTypeId, fileUUID) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/cadidates16/uploalfile/provinceId/${provinceId}/cmdApplyDateId/${cmdApplyDateId}`;
  const body = {
    docTypeId: docTypeId,
    fileUUID: fileUUID
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editCadidate8(applyPerson) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/cadidates8/list`;
  const body = {
    applyPerson: applyPerson
  };
  console.log(body);
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function delQualification(cmdApplyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/qualification/${cmdApplyDateId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addQualification(
  provinceId,
  cmdApplyDateId,
  orgName,
  description,
  fileUUID
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/qualification/provinceId/${provinceId}/cmdApplyDateId/${cmdApplyDateId}`;
  const body = {
    orgName: orgName,
    description: description,
    fileUUID: fileUUID
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
