import { CsvBuilder } from 'filefy';
import getFieldValue from './DataManger';

const getTableData = (_columns, _data) => {
  console.log('_columns: ', _columns);
  console.log('_data: ', _data);
  const columns = _columns
    .filter(
      (columnDef) =>
        (!columnDef.hidden || columnDef.export === true) &&
        columnDef.export !== false &&
        columnDef.field
    )
    .sort((a, b) =>
      a.tableData.columnOrder > b.tableData.columnOrder ? 1 : -1
    );

  if (_data.data && Array.isArray(_data.data)) {
  }

  let data = [];
  _data.forEach((rowData) => {
    if (rowData.data && Array.isArray(rowData.data)) {
      rowData.data.forEach((groupedData) => {
        data.push(
          columns.map((columnDef) => getFieldValue(groupedData, columnDef))
        );
      });
    } else {
      data.push(columns.map((columnDef) => getFieldValue(rowData, columnDef)));
    }
  });

  return [columns, data];
};

export default function exportCsv(
  _columns,
  _data,
  exportFileName,
  exportDelimiter
) {
  let fileName = 'untitled';
  let delimiter = ',';

  const [columns, data] = getTableData(_columns, _data);

  if (exportDelimiter) {
    delimiter = exportDelimiter;
  }

  if (exportFileName) {
    fileName =
      typeof exportFileName === 'function' ? exportFileName() : exportFileName;
  }

  const builder = new CsvBuilder(fileName + '.csv');
  builder
    .setDelimeter(delimiter)
    .setColumns(columns.map((columnDef) => columnDef.title))
    .addRows(data)
    .exportFile();
}
