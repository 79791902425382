import React, { useEffect, useState } from "react";
import {
  addUniversity,
  deleteUniversity,
  editUniversity,
  getCountry,
  getUniversity
} from "contexts/API/CommonSetting/MasterData";

import ListboxCountry from "components/Common/ListboxCountry";
import MaterialTable from "components/Table/MaterialTable";
import { useSweetAlert } from "components/Alert/SweetAlert";

const University = props => {
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const [country, setCountry] = useState([]);
  useEffect(() => {
    getCountry()
      .then(r => {
        let countryMapper = r.data.data.map(v => {
          return { id: v.countryId, value: v.name };
        });

        setCountry(countryMapper);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getUniversity()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "universityId",
      field: "universityId",
      hidden: true
    },
    {
      title: "ชื่อมหาวิทยาลัย",
      field: "universityName",
      cellStyle: {
        minWidth: "300px"
      }
    },
    {
      title: "ประเทศ",
      field: "countryId",
      cellStyle: {
        minWidth: "300px"
      },

      render: rowData => {
        const v = country.find(e => e.id === parseInt(rowData.countryId));

        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        return (
          <ListboxCountry
            defaultValue={{ id: props.rowData.countryId }}
            data={country}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.onChange(e.id);
              }
            }}
          />
        );
      }
    }
  ];

  const optionsTable = { pageSize: 10 };
  return (
    <MaterialTable
      title={"ชื่อมหาวิทยาลัย"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            addUniversity(newData.universityName, newData.countryId)
              .then(result => {
                //console.log("result>>",result);
                newData.universityId = result.data.data.universityId;
                setData([...data, newData]);
                sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
              });

            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;

            editUniversity(
              oldData.universityId,
              newData.universityName,
              newData.countryId
            )
              .then(result => {
                sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                setData([...dataUpdate]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
              });
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);

            deleteUniversity(oldData.universityId)
              .then(result => {
                sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                setData([...dataDelete]);
              })
              .catch(err => {
                sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
              });
            resolve();
          })
      }}
    />
  );
};
export default University;
