import React, { useState } from "react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ModalReport from "./ModalReport";
import { REPORTING_SERVICE } from "config/env.conf";
import { makeStyles } from "@material-ui/core/styles";

//import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const RptSummarySuspend = props => {
  const classes = useStyles();
  const [showModalReport, setshowModalReport] = useState(false);
  const openModalReport = () => setshowModalReport(true);
  const closeModalReport = () => setshowModalReport(false);
  const [child, setChild] = useState("");
  const [title, setTitle] = useState("");
  const [index, setIndex] = useState("");

  return (
    <form>
      <ModalReport
        closeModalReport={closeModalReport}
        showModalReport={showModalReport}
        linkReport={child}
        titleReport={title}
        indexReport={index}
      ></ModalReport>
      <Card>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4>รายงานระบบผู้ถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer spacing={2}>
            <GridItem xs={12} md={6}>
              <h4 className={classes.cardTitle}>รายงาน</h4>
            </GridItem>
          </GridContainer>
          <GridContainer spacing={2}>
            <GridItem xs={12} md={12}>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
              >
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="1. ทะเบียนเพิกถอนสิทธิสมัครรับเลือกตั้ง "
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fsuspend%2fsuspend_rpt1_Grouping&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "1. ทะเบียนเพิกถอนสิทธิสมัครรับเลือกตั้ง "
                      );
                      setIndex("1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="2. ทะเบียนระงับสิทธิสมัครรับเลือกตั้งเป็นการชั่วคราว "
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fsuspend%2fsuspend_rpt2_Grouping&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "2. ทะเบียนระงับสิทธิสมัครรับเลือกตั้งเป็นการชั่วคราว "
                      );
                      setIndex("2");
                    }}
                  />
                </ListItem>

                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="3. สถิติจำนวนผู้ถูกระงับสิทธิสมัครรับเลือกตั้ง และเพิกถอนสิทธิสมัครรับเลือกตั้ง"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                        "/Pages/ReportViewer.aspx?%2freports%2fsuspend%2fsuspend_rpt3&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "3. สถิติจำนวนผู้ถูกระงับสิทธิสมัครรับเลือกตั้ง และเพิกถอนสิทธิสมัครรับเลือกตั้ง"
                      );
                      setIndex("3");
                    }}
                  />
                </ListItem>
              </List>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </form>
  );
};

export default RptSummarySuspend;
