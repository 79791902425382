import React, { useState } from "react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ModalReport from "./ModalReport";
import { REPORTING_SERVICE } from "config/env.conf";
import { makeStyles } from "@material-ui/core/styles";

//import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const RptSummaryVotecard = props => {
  const classes = useStyles();
  const [showModalReport, setshowModalReport] = useState(false);
  const openModalReport = () => setshowModalReport(true);
  const closeModalReport = () => setshowModalReport(false);
  const [child, setChild] = useState("");
  const [title, setTitle] = useState("");
  const [index, setIndex] = useState("");

  return (
    <form>
      <ModalReport
        closeModalReport={closeModalReport}
        showModalReport={showModalReport}
        linkReport={child}
        titleReport={title}
        indexReport={index}
      ></ModalReport>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4>รายงานระบบควบคุมการจัดสรรบัตรเลือกตั้ง</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer spacing={2}>
            <GridItem xs={12} md={6}>
              <h4 className={classes.cardTitle}>รายงาน</h4>
            </GridItem>
          </GridContainer>
          <GridContainer spacing={2}>
            <GridItem xs={12} md={12}>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
              >
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="1. รายงานสรุปยอดคงเหลือรวมบัตรเลือกตั้งคงคลัง"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2fvotecard%2fvotecard_rpt1_Grouping&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle("1. รายงานสรุปยอดคงเหลือรวมบัตรเลือกตั้งคงคลัง");
                      setIndex("1_1");
                    }}
                  />
                </ListItem>

                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="2. รายงานรายละเอียดบัตรเลือกตั้งคงคลัง"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2fvotecard%2fvotecard_rpt2_Grouping&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle("2. รายงานรายละเอียดบัตรเลือกตั้งคงคลัง");
                      setIndex("2_1");
                    }}
                  />
                </ListItem>

                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="3. รายงานการสั่งพิมพ์บัตรเลือกตั้ง"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2fvotecard%2fvotecard_rpt3_Grouping&rs:Command=Render&rc:Parameters=Collapsed "
                      );
                      setTitle("3. รายงานการสั่งพิมพ์บัตรเลือกตั้ง");
                      setIndex("3_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="4. รายงานการตรวจรับบัตรเลือกตั้ง"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2fvotecard%2fvotecard_rpt4_Grouping&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle("4. รายงานการตรวจรับบัตรเลือกตั้ง");
                      setIndex("4_1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="5. รายงานการขอเปลี่ยนแปลงการสั่งซื้อบัตรเลือกตั้ง (ขอเพิ่ม-ขอลด-ขอคืน)"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2fvotecard%2fvotecard_rpt5_Grouping&rs:Command=Render&rc:Parameters=Collapsed "
                      );
                      setTitle(
                        "5. รายงานการขอเปลี่ยนแปลงการสั่งซื้อบัตรเลือกตั้ง (ขอเพิ่ม-ขอลด-ขอคืน)"
                      );
                      setIndex("5_1");
                    }}
                  />
                </ListItem>

                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="6. รายงานบัตรเลือกตั้งคงคลังที่ยังไม่ได้จ่าย"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2fvotecard%2fvotecard_rpt6_Grouping&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle("6. รายงานบัตรเลือกตั้งคงคลังที่ยังไม่ได้จ่าย");
                      setIndex("6_1");
                    }}
                  />
                </ListItem>
              </List>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </form>
  );
};

export default RptSummaryVotecard;
