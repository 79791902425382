import React, { useContext, useState } from "react";
// Context Apis
import { deleteOpt, getOptLists } from "contexts/API/OPT/Opt";

import Button from "components/CustomButtons/Button.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxProvince from "components/Common/ListboxProvince.js";
// core components
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import ModalEditOpt from "./ModalEditOpt";
import ModalTableaddArea from "./ModalTableaddArea";
import ModaladdOpt from "./ModaladdOpt";
import Paper from "@material-ui/core/Paper";
import { REPORTING_SERVICE } from "config/env.conf";
import { VoteDateContext } from "contexts/VoteDate";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
//Material Core
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  buttoncustom: {
    fontSize: 14,
    fontWeight: 500
    //color: props => props.color
  }
});

const MsOptManage = props => {
  const [clickSearch, setClickSearch] = useState(false);

  const { sweetAlerts } = useSweetAlert();

  const {
    onOptTypeSelected,
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,

    tambolSelected,
    optTypeSelected,
    onOptSelected,
    optSelected,
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);

  //const validateSchema = props.formProps;

  const [, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [data7, setData7] = useState([]);
  const [loading, setLoading] = useState(false);
  const [titleModal, settitleModal] = useState(null);

  //const [bodyTextDelModal, setBodyTextDelModal] = useState(null);
  const [children, setchildren] = useState(null);

  //notice
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  /*   const [deletedvalue, setDeletedvalue] = useState(null);
  const [deletedId, setDeletedId] = useState(null); */

  const [optIdSelected, setOptIdSelected] = useState(null);
  const [provinceId, setProvinceId] = useState(null);
  const [optSubtype, setOptSubtype] = useState(null);
  const [amphurId, setAmphurId] = useState(null);
  //const [optAreaData, setOptAreaData] = useState([]);
  const [showModaladdArea, setshowModaladdArea] = useState(false);
  //const openModaladdArea = () => setshowModaladdArea(true);
  const closeModaladdArea = () => setshowModaladdArea(false);

  const classes = useStyles();

  const optQueryUrl = () => {
    let endPoint = "?";
    let optTypes = getOptTypeSelectedLists();

    if (optTypes.length > 0) {
      endPoint += `&optsubtype=[${optTypes.toString()}]`;
    }

    if (optSelected !== null) {
      endPoint += `&optId=${optSelected.id}`;
    }
    if (tambolSelected !== null) {
      endPoint += `&tambolId=${tambolSelected.id}`;
    }
    if (amphurSelected !== null) {
      endPoint += `&amphurId=${amphurSelected.id}`;
    }
    if (provinceSelected !== null) {
      endPoint += `&provinceId=${provinceSelected.id}`;
    }

    return endPoint;
  };

  const onClickSearch = e => {
    setClickSearch(false);
    setLoading(true);
    const optTypes = getOptTypeSelectedLists();

    if (optTypes.length === 0) {
      sweetAlerts.warning("กรุณาเลือกประเภท อปท.");
      return;
    }

    /* if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    } */

    const groupBy1 = (arr, key) => {
      var newArr = [],
        types = {},
        i,
        j,
        cur;
      for (i = 0, j = arr.length; i < j; i++) {
        cur = arr[i];
        if (!(cur[key] in types)) {
          types[cur[key]] = { type: cur[key], data: [] };
          newArr.push(types[cur[key]]);
        }
        types[cur[key]].data.push(cur);
      }
      return newArr;
    };
    getOptLists(optQueryUrl(), optExportUrl())
      .then(result => {
        setData1([]);
        setData2([]);
        setData3([]);
        setData4([]);
        setData5([]);
        setData6([]);
        setData7([]);
        const filterData = groupBy1(result.data.data, "ms_opt_sub_type_id");

        filterData.map(function (d) {
          if (d.type === 1) {
            setData1(d.data);
          }
          if (d.type === 2) {
            setData2(d.data);
          }
          if (d.type === 3) {
            setData3(d.data);
          }
          if (d.type === 4) {
            setData4(d.data);
          }
          if (d.type === 5) {
            setData5(d.data);
          }
          if (d.type === 6) {
            setData6(d.data);
          }
          if (d.type === 7) {
            setData7(d.data);
          }
        });
        setLoading(false);
        setClickSearch(true);
        // setClickSearch(true);
        // console.log(data1);
        //  const x = grouped.filter(item => item.key === 1);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล อปท.", err.response.data.desc);
      });
  };

  const optExportUrl = () => {
    let endPointExp = "";
    let optTypes = getOptTypeSelectedLists();

    endPointExp += `&province_id=${
      provinceSelected !== null ? provinceSelected.id : 0
    }
      &amphur_id=${amphurSelected !== null ? amphurSelected.id : 0}
      &opt_sub_type=${
        optTypes.length > 0 ? optTypes.toString() : "1,2,3,4,5,6,7,8"
      }
      &opt_id=${optSelected !== null ? optSelected.id : 0}`;

    return endPointExp;
  };
  const handleSubmitExportOpt = e => {
    e.preventDefault();
    window.open(
      `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2Freports%2Fopt%2Fopt_rpt1_2_1_exp&rs:Command=Render&rc:Parameters=Collapsed${optExportUrl()}`,
      "_blank"
    );
  };

  const onClickAddArea = rowData => {
    setOptIdSelected({ id: rowData.ms_opt_id, value: "" });
    setProvinceId({ id: rowData.province_id, value: "" });
    setOptSubtype({ id: rowData.ms_opt_sub_type_id, value: "" });
    setAmphurId({ id: rowData.amphur_id, value: "" });
    setshowModaladdArea(true);
  };

  const openEditModal = () => setShowEditModal(true);
  const closeEditModal = () => {
    onClickSearch();
    setShowEditModal(false);
  };
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  const columns = [
    {
      filed: "ms_opt_id",
      title: "ms_opt_id",
      export: true,
      hidden: true,
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "ms_opt_sub_type_id",
      title: "ms_opt_sub_type_id",
      cellStyle: {
        textAlign: "center"
      },
      hidden: true
    },
    {
      field: "province_name",
      title: "จังหวัด",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "amphur_name",
      title: "อำเภอ",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "amphur_id",
      title: "amphur_id",
      hidden: true,
      export: false,
      cellStyle: {
        textAlign: "center"
      }
    },

    {
      field: "opt_sub_type_short_name",
      title: "ประเภท อปท.",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      }
    },
    {
      field: "name",
      title: "ชื่อ อปท.",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      }
    },
    {
      field: "countarea",
      title: "จำนวนเขต",
      cellStyle: {
        textAlign: "left"
      }
    },

    {
      field: "",
      export: false,
      cellStyle: {
        minWidth: "240px"
      },
      render: rowData => {
        return (
          <>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                onClickAddArea(rowData);
              }}
            >
              เพิ่มเขตเลือกตั้ง
            </Button>

            <Button
              size="sm"
              color="warning"
              onClick={() => {
                openEditModal();
                setchildren(rowData);
                settitleModal("แก้ไขรายชื่อ อปท.");
              }}
            >
              แก้ไข
            </Button>
          </>
        );
      }
    }
  ];

  const optionsTable = { exportButton: false, paging: true };

  const addSuccess = optAreaId => {
    if (optAreaId) {
      // setLoading(false);
      // setClickSearch(null);
      onClickSearch();
    }
  };
  return (
    <>
      {show ? (
        <ModaladdOpt
          show={show}
          closeModal={closeModal}
          title={titleModal}
          // onaddSuccess={addSuccess}
        />
      ) : null}
      {showEditModal ? (
        <ModalEditOpt
          closeModal={closeEditModal}
          title={titleModal}
          show={showEditModal}
          data={children}
        />
      ) : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br /> <br />
          <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={12} sm={12} md={12}>
              <CheckboxOpttype
                optTypeSelected={optTypeSelected}
                onOptTypeSelected={onOptTypeSelected}
                required={true}
              ></CheckboxOpttype>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <ListboxProvince
                value={provinceSelected}
                onChange={onProvinceSelected}
              ></ListboxProvince>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <ListboxDistinct
                value={amphurSelected}
                provinceSelected={provinceSelected}
                onChange={onAmphurSelected}
              ></ListboxDistinct>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={3}>
              <ListboxSubdistinct
                amphurSelected={amphurSelected}
                value={tambolSelected}
                onChange={onTambolSelected}
              ></ListboxSubdistinct>
            </GridItem> */}
            <GridItem xs={12} sm={12} md={4}>
              <ListboxOpts
                value={optSelected}
                getOptTypeSelectedLists={getOptTypeSelectedLists}
                optTypeSelected={optTypeSelected}
                provinceSelected={provinceSelected}
                amphurSelected={amphurSelected}
                tambolSelected={tambolSelected}
                onChange={onOptSelected}
              ></ListboxOpts>
            </GridItem>
          </GridContainer>
          <br /> <br /> <br />
          <GridContainer justify="space-between" alignItems="center">
            <GridItem xs={6} sm={6} md={6}>
              <Button
                className={classes.buttoncustom}
                onClick={() => {
                  openModal();
                  settitleModal("เพิ่มรายชื่อ อปท.");
                }}
                color="success"
              >
                เพิ่มรายชื่อ อปท.
              </Button>
            </GridItem>
            <GridItem>
              <Button
                className={classes.buttoncustom}
                onClick={() => {
                  setData(null);
                }}
              >
                ยกเลิก
              </Button>
              <Button
                color="info"
                className={classes.buttoncustom}
                onClick={onClickSearch}
              >
                ค้นหา
              </Button>
            </GridItem>
          </GridContainer>
          <br />
          {data1.length !== 0 ||
          data2.length !== 0 ||
          data3.length !== 0 ||
          data4.length !== 0 ||
          data5.length !== 0 ||
          data6.length !== 0 ||
          data7.length !== 0 ? (
            <GridContainer justify="space-between" alignItems="center">
              <GridItem xs={6} sm={6} md={6}></GridItem>
              <GridItem>
                นำออกข้อมูล:{" "}
                <Button
                  justIcon
                  round
                  color="success"
                  onClick={handleSubmitExportOpt}
                >
                  <FontAwesomeIcon icon={faFileExcel} />
                </Button>
              </GridItem>
            </GridContainer>
          ) : null}
        </GridItem>
      </GridContainer>

      {showModaladdArea && optIdSelected !== null ? (
        <ModalTableaddArea
          closeModaladdArea={closeModaladdArea}
          showModaladdArea={showModaladdArea}
          optSelected={optIdSelected}
          provinceSelected={provinceId}
          optSubtypeSelected={optSubtype}
          amphurId={amphurId}
          onaddSuccess={addSuccess}
        />
      ) : null}
      {loading === null ? (
        <LoadingData />
      ) : (
        <>
          {optTypeSelected["1"].checked && clickSearch === true ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MaterialTable
                  data={data1}
                  columns={columns}
                  title={"องค์การบริหารส่วนจังหวัด"}
                  options={optionsTable}
                  editable={{
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        const dataDelete = [...data1];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        deleteOpt(oldData.ms_opt_id)
                          .then(result => {
                            sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                            dataDelete.splice(index, 1);
                            setData1(dataDelete);
                            resolve();
                          })
                          .catch(err => {
                            sweetAlerts.danger(
                              `ลบข้อมูลไม่สำเร็จ`,
                              err.response.data.desc
                            );
                          });
                      })
                  }}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />
                  }}
                />
              </GridItem>
              <br></br>
            </GridContainer>
          ) : (
            ""
          )}

          {optTypeSelected["2"].checked && clickSearch === true ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MaterialTable
                  data={data2}
                  columns={columns}
                  title={"เทศบาลนคร"}
                  options={optionsTable}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />
                  }}
                  editable={{
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        const dataDelete = [...data2];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        deleteOpt(oldData.ms_opt_id)
                          .then(result => {
                            sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                            dataDelete.splice(index, 1);
                            setData2(dataDelete);
                            resolve();
                          })
                          .catch(err => {
                            sweetAlerts.danger(
                              `ลบข้อมูลไม่สำเร็จ`,
                              err.response.data.desc
                            );
                          });
                      })
                  }}
                />
              </GridItem>
              <br></br>
            </GridContainer>
          ) : (
            ""
          )}

          {optTypeSelected["3"].checked && clickSearch === true ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MaterialTable
                  data={data3}
                  columns={columns}
                  title={"เทศบาลเมือง"}
                  options={optionsTable}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />
                  }}
                  editable={{
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        const dataDelete = [...data3];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        deleteOpt(oldData.ms_opt_id)
                          .then(result => {
                            sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                            dataDelete.splice(index, 1);
                            setData3(dataDelete);
                            resolve();
                          })
                          .catch(err => {
                            sweetAlerts.danger(
                              `ลบข้อมูลไม่สำเร็จ`,
                              err.response.data.desc
                            );
                          });
                      })
                  }}
                />
              </GridItem>
              <br></br>
            </GridContainer>
          ) : (
            ""
          )}

          {optTypeSelected["4"].checked && clickSearch === true ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MaterialTable
                  data={data4}
                  columns={columns}
                  title={"เทศบาลตำบล"}
                  options={optionsTable}
                  editable={{
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        const dataDelete = [...data4];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        deleteOpt(oldData.ms_opt_id)
                          .then(result => {
                            sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                            dataDelete.splice(index, 1);
                            setData4(dataDelete);
                            resolve();
                          })
                          .catch(err => {
                            sweetAlerts.danger(
                              `ลบข้อมูลไม่สำเร็จ`,
                              err.response.data.desc
                            );
                          });
                      })
                  }}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />
                  }}
                />
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          <br></br>
          {optTypeSelected["5"].checked && clickSearch === true ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MaterialTable
                  data={data5}
                  columns={columns}
                  title={"องค์การบริหารส่วนตำบล"}
                  options={optionsTable}
                  editable={{
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        const dataDelete = [...data5];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        deleteOpt(oldData.ms_opt_id)
                          .then(result => {
                            sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                            dataDelete.splice(index, 1);
                            setData5(dataDelete);
                            resolve();
                          })
                          .catch(err => {
                            sweetAlerts.danger(
                              `ลบข้อมูลไม่สำเร็จ`,
                              err.response.data.desc
                            );
                          });
                      })
                  }}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />
                  }}
                />
              </GridItem>
              <br></br>
            </GridContainer>
          ) : (
            ""
          )}

          {optTypeSelected["6"].checked && clickSearch === true ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MaterialTable
                  data={data6}
                  columns={columns}
                  title={"กรุงเทพมหานคร"}
                  options={optionsTable}
                  editable={{
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        const dataDelete = [...data6];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        deleteOpt(oldData.ms_opt_id)
                          .then(result => {
                            sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                            dataDelete.splice(index, 1);
                            setData6(dataDelete);
                            resolve();
                          })
                          .catch(err => {
                            sweetAlerts.danger(
                              `ลบข้อมูลไม่สำเร็จ`,
                              err.response.data.desc
                            );
                          });
                      })
                  }}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />
                  }}
                />
              </GridItem>
              <br></br>
            </GridContainer>
          ) : (
            ""
          )}

          {optTypeSelected["7"].checked && clickSearch === true ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MaterialTable
                  data={data7}
                  columns={columns}
                  title={"พัทยา"}
                  options={optionsTable}
                  editable={{
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        const dataDelete = [...data7];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        deleteOpt(oldData.ms_opt_id)
                          .then(result => {
                            sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                            dataDelete.splice(index, 1);
                            setData7(dataDelete);
                            resolve();
                          })
                          .catch(err => {
                            sweetAlerts.danger(
                              `ลบข้อมูลไม่สำเร็จ`,
                              err.response.data.desc
                            );
                          });
                      })
                  }}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />
                  }}
                />
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};
export default MsOptManage;
