import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import OPT_USE_VOTE from "./OptUseVote";
import OPT_UPLOAD_EXCEL from "./OptuploadExcel";
import { VoteDateContextProvider } from "contexts/VoteDate";

import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";

const styles = {};
const useStyles = makeStyles(styles);

const UseVoteIndex = props => {
  const classes = useStyles();
  return (
    <div className={classes.tabcustom}>
      <VoteDateContextProvider>
        <CustomTabs
          styles={customTabsStyles}
          headerColor="primary"
          tabs={[
            {
              tabName: "จัดการข้อมูลการใช้สิทธิเลือกตั้ง",
              tabContent: <OPT_USE_VOTE />
            },
            {
              tabName: "นำข้อมูลเข้าจากไฟล์",
              tabContent: <OPT_UPLOAD_EXCEL />
            }
          ]}
        />
      </VoteDateContextProvider>
    </div>
  );
};
export default UseVoteIndex;
