import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import CheckOptStatus from "./CheckOptStatus";
import MultiCheckOptStatus from "./MultiCheckOptStatus";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";
import { VoteDateContextProvider } from "contexts/VoteDate";
const styles = {};
const useStyles = makeStyles(styles);

const CheckOptIndex = props => {
  // Local State
  //const [activeComponent, setActiveComponent] = useState("CheckPositionStatus");
  const classes = useStyles();
  return (
    /*  <CheckOptStatus /> */
    <div className={classes.tabcustom}>
      <VoteDateContextProvider>
        <CustomTabs
          styles={customTabsStyles}
          headerColor="primary"
          tabs={[
            {
              tabName: "ตรวจสอบการดำรงตำแหน่ง ส.ถ./ผ.ถ. รายบุคคล",
              tabContent: <CheckOptStatus />
            },
            {
              tabName: "นำข้อมูลเข้าจากไฟล์",
              tabContent: <MultiCheckOptStatus />
            }
          ]}
        />
      </VoteDateContextProvider>
    </div>
  );
};

export default CheckOptIndex;
