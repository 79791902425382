import React, { useEffect, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TableComponent from "components/Table/MaterialTable";
import TextField from "@material-ui/core/TextField";
import VoteCardChild from "./VoteCardChild";
import { getSearchLists } from "contexts/API/VoteCard/Publish";
//import { makeStyles } from "@material-ui/core/styles";
//import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { useSweetAlert } from "components/Alert/SweetAlert";

//import HeaderAuth from "utils/HeaderAuth";
//const useStyles = makeStyles(styles);
const VotecardDetail = ({ pageProps, setPageProps }) => {
  const { sweetAlerts } = useSweetAlert();
  const detail = pageProps;

  //const classes = useStyles();

  const [showChild, setShowChild] = useState(false);

  const [showData, setShowData] = useState([]);
  const backtoMainHandler = rowData => {
    setPageProps({ active: "Stock", props: rowData });
  };
  const onClick = (rowData, callback) => {
    if (callback === undefined) {
      setShowChild(true);
      setShowData(rowData);
      window.scrollTo(0, document.body.scrollHeight);
    }

    setShowData(rowData);
    if (callback !== undefined) {
      setShowChild(true);
      setShowData(rowData);
      window.scrollTo(0, 0);
    }
  };
  const optionsTable = {
    exportButton: true,
    paging: true,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20]
  };
  const [columns] = useState([
    {
      title: "หมวด",
      field: "Category",
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "ครั้งที่",
      field: "LotNumber",
      cellStyle: {
        minWidth: "80px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "เลขที่เริ่มต้น",
      field: "VoteCardNumberStart",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.VoteCardNumberStart);
      }
    },
    {
      title: "เลขที่สิ้นสุด",
      field: "VoteCardNumberEnd",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.VoteCardNumberEnd);
      }
    },
    {
      title: "จำนวนเล่ม",
      field: "VoteCardTotalAmount",
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(
          1 + rowData.VoteCardNumberEnd - rowData.VoteCardNumberStart
        );
      }
    },
    {
      title: "เบิกแล้ว (เล่ม)",
      field: "VoteCardDisburse",
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.VoteCardDisburse);
      }
    },
    {
      title: "ชำรุด (เล่ม)",
      field: "VoteCardNotUsed",
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.VoteCardNotUsed);
      }
    },
    {
      title: "คงเหลือ (เล่ม)",
      field: "VoteCardBalanceAmount",
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      headerStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.VoteCardBalanceAmount);
      }
    },
    {
      title: "เลขเล่มที่เหลือ เริ่มต้นถึงสิ้นสุด",
      field: "",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return (
          new Intl.NumberFormat().format(rowData.VoteCardCurrentStart) +
          " - " +
          new Intl.NumberFormat().format(rowData.VoteCardCurrentEnd)
        );
      }
    },
    // {
    //   title: "เลขเล่มที่เหลือเริ่มต้น",
    //   field: "VoteCardCurrentStart",
    //   cellStyle: {
    //     minWidth: "70px",
    //     textAlign: "center"
    //   },
    //   headerStyle: {
    //     minWidth: "70px",
    //     textAlign: "center"
    //   },
    //   render: rowData => {
    //     return new Intl.NumberFormat().format(rowData.VoteCardCurrentStart);
    //   }
    // },
    // {
    //   title: "เลขเล่มที่เหลือสิ้นสุด",
    //   field: "VoteCardCurrentEnd",
    //   cellStyle: {
    //     minWidth: "70px",
    //     textAlign: "center"
    //   },
    //   headerStyle: {
    //     minWidth: "70px",
    //     textAlign: "center"
    //   },
    //   render: rowData => {
    //     return new Intl.NumberFormat().format(rowData.VoteCardCurrentEnd);
    //   }
    // },
    {
      title: "",
      field: "",
      render: rowData => (
        <Button size="sm" color="warning" onClick={() => onClick(rowData)}>
          จัดการบัตรเลือกตั้งด้วยตนเอง
        </Button>
      )
    }
  ]);
  const [data, setData] = useState([]);

  const [votecardPrintingHouseID,] = useState(
    detail.props.PrintHouseID
  );
  const [votecardOptTypeID,] = useState(
    detail.props.VoteCareOptTypeID
  );
  const [positionTypeID,] = useState(
    detail.props.PositionTypeID
  );
  const [votecardColorID,] = useState(
    detail.props.CardColorID
  );
  const [votecardNumberID,] = useState(
    detail.props.CardNumberID
  );

  useEffect(() => {
    const optQueryUrl = () => {
      let endPoint = "?";

      if (votecardPrintingHouseID !== null) {
        //
        endPoint += `&PrintHouseID=${votecardPrintingHouseID}`;
      }

      if (votecardOptTypeID !== null) {
        endPoint += `&VoteCareOptTypeID=${votecardOptTypeID}`;
      }

      if (positionTypeID !== null) {
        endPoint += `&PositionTypeID=${positionTypeID}`;
      }

      if (votecardColorID !== null) {
        endPoint += `&CardColorID=${votecardColorID}`;
      }

      if (votecardNumberID !== null) {
        endPoint += `&CardNo=${votecardNumberID}`;
      }

      return endPoint;
    };

    getSearchLists(optQueryUrl(), "")
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล อปท.", err.toString());
      });
  }, []);

  function onshowUpdate(showData) {
    if (showData) {
      let callback = true;
      const optQueryUrl = () => {
        let endPoint = "?";

        if (votecardPrintingHouseID !== null) {
          //
          endPoint += `&PrintHouseID=${votecardPrintingHouseID}`;
        }

        if (votecardOptTypeID !== null) {
          endPoint += `&VoteCareOptTypeID=${votecardOptTypeID}`;
        }

        if (positionTypeID !== null) {
          endPoint += `&PositionTypeID=${positionTypeID}`;
        }

        if (votecardColorID !== null) {
          endPoint += `&CardColorID=${votecardColorID}`;
        }

        if (votecardNumberID !== null) {
          endPoint += `&CardNo=${votecardNumberID}`;
        }

        return endPoint;
      };

      getSearchLists(optQueryUrl(), "")
        .then(result => {
          setData(result.data.data);
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถดึงข้อมูล อปท.", err.toString());
        });
      onClick(showData, callback);
    }
    // ...
  }

  // function onshowUpdate(voteCardAllocate, tableId) {
  //   setDataForUpdate(voteCardAllocate);
  //   setTableId(tableId);
  //   // ...
  // }

  return (
    <>
      {/* <GridItem xs={12} sm={12} md={12}> */}
      <Card>
        <CardHeader color="rose" icon></CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                label="โรงพิมพ์"
                defaultValue={detail.props.PrintHouseName}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                label="ประเภทการเลือกตั้ง"
                defaultValue={detail.props.PositionTypeDetail}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                label="ประเภท อปท."
                defaultValue={detail.props.OptName}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
              />
            </GridItem>
          </GridContainer>
          <div style={{ paddingTop: 20 }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  label="สีบัตรเลือกตั้ง"
                  defaultValue={detail.props.CardColorName}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  label="ชนิดหมายเลข"
                  defaultValue={detail.props.CardNumberName}
                  fullWidth
                  InputProps={{
                    readOnly: true
                  }}
                />
              </GridItem>
            </GridContainer>
          </div>
        </CardBody>
      </Card>
      <br />
      <TableComponent
        title="รายละเอียดข้อมูลบัตรเลือกตั้งในระบบ"
        columns={columns}
        data={data}
        localization={{}}
        options={optionsTable}
        defaultExpanded={true}
      //  onRowUpdate={onRowUpdate}
      ></TableComponent>
      {showChild ? (
        <VoteCardChild showData={showData} showUpdate={onshowUpdate} />
      ) : null}
      <br />
      <GridContainer
        justify="space-between"
        direction="column"
        alignItems="flex-end"
      >
        <GridItem>
          <Button color="default" onClick={backtoMainHandler}>
            กลับ
          </Button>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default VotecardDetail;
