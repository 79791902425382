//หน้าบันทึกวันเลือกตั้งใหม่
/* core components */
import React, { createRef, useContext, useEffect, useState } from "react";
import {
  addReplacedetail,
  deletedReplacedetail,
  editReplacedetail
} from "contexts/API/VoteDate/VoteDate";
import { getAmphurArea, getAreaNoLists } from "contexts/API/OPT/OptArea";

// Context APIs
import AddNewVoteDate from "contexts/API/VoteDate/VoteDate";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInputWithValidator";
import Datepicker from "components/Common/Datepicker";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListBoxDate from "components/Common/ListboxDate";
import ListboxArea from "components/Common/ListboxArea";
import ListboxAumphurAreaofTable from "components/Common/ListboxAumphurAreaofTable";
import ListboxPositionStatus from "components/Common/ListboxPositionStatus";
import MainVotedate from "components/OptComponent/MainVotedate";
import MaterialTable from "components/Table/MaterialTable";
import MemberDetailVotedate from "components/OptComponent/MemberDetailVotedate";
import MustNotNull from "utils/Validators/MustNotNull";
import PresidentDetailVotedate from "components/OptComponent/PresidentDetailVotedate";
import TableComplete from "components/OptComponent/TableComplete";
/* custom components */
import TablePresidentNewVote from "components/OptComponent/TablePresidentNewVote";
import { VoteDateContext } from "contexts/VoteDate";
import { getAllOptAreaByOptId } from "contexts/API/OPT/OptArea";
import { getPositionStatus } from "contexts/API/OPT/Opt";
import { useSnackBar } from "components/Alert/Snackbar";
// Utils
import { useSweetAlert } from "components/Alert/SweetAlert";

const AddVoteDate = ({ pageProps, setPageProps }) => {
  const { sweetAlerts } = useSweetAlert();
  const { snackBars } = useSnackBar();
  const { optVoteTypeSelected, optSelected } = useContext(VoteDateContext);

  // Local State
  const [dataTable, setDataTable] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [optVoteId, setOptVoteId] = useState(null);
  const [replaceDetail, setReplaceDetail] = useState([]);
  const [voteDateSeleted, setVoteDateSeleted] = useState(null);
  const [generalVoteDate, setGeneralVoteDate] = useState(null);
  //const [columns, setColumns] = useState([]);
  const [_areaAmphur, _setAreaAmphur] = useState();
  const [positionList, setPositionList] = useState([]);
  const [areaNoList, setAreaNoList] = useState([]);
  const [areaAmhurList, setAreaAmhurList] = useState([]);

  console.log("addVoteDate [ A ]");

  useEffect(() => {
    setOptVoteId(optVoteId);
    //  console.log("optVoteId", optVoteId);
  }, [optVoteId]);

  useEffect(() => {
    getPositionStatus()
      .then(r => {
        let positionMapper = r.data.data.map(v => {
          return { id: v.statusId, value: v.detail };
        });

        setPositionList(positionMapper);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (optSelected !== null && generalVoteDate !== null) {
      console.log("generalVoteDate", generalVoteDate);
      getAreaNoLists(optSelected.id, generalVoteDate.raw.optVoteTypeId, null, generalVoteDate.id)
        .then(r => {
          let areaMapper = r.data.data[0].opt_area.map(v => {
            return { id: v.opt_area_id, value: `ส.ถ. เขตที่` + v.area_no };
          });

          // 2024-07-24 Narongsak.M เลือกตั้งเขตใหม่
          if (optVoteTypeSelected.id === 4) {
            getAllOptAreaByOptId(optSelected.id)
                .then(r2 => {
                  let areaList = r.data.data[0].opt_area.map(v => {
                    return v.area_no;
                  });

                  let excludeAreaNo = r2.data.data[0].opt_area
                      .filter(f => !areaList.includes(f.area_no))
                      .map(v => {
                        return {id: v.opt_area_id, value: `ส.ถ. เขตที่` + v.area_no};
                      });

                  console.log('##### excludeAreaNo', excludeAreaNo);
                  setAreaNoList(excludeAreaNo);
                });
          }else {
            setAreaNoList(areaMapper);
          }

        })
        .catch(err => {
          console.log(err);
        });
      getAmphurArea(optSelected.id, 1, null, generalVoteDate.id)
        .then(r => {
          let ampuhrMapper = r.data.data.map(v => {
            return { id: v.amphur_id, value: v.amphur_name };
          });

          setAreaAmhurList(ampuhrMapper);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [optSelected, generalVoteDate]);

  console.log("addVoteDate [ B ]");

  const columns = [
    {
      field: "optReplaceDetailId",
      title: "optReplaceDetailId",
      hidden: true
    },
    {
      align: "center",
      field: "amphurId",
      title: "เขตอำเภอ",
      type: "numeric",
      editable: "onAdd",

      render: rowData => {
        const v = areaAmhurList.find(e => e.id === parseInt(rowData.amphurId));
        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        return (
          <ListboxAumphurAreaofTable
            defaultValue={{ id: props.rowData.amphurId }}
            data={areaAmhurList}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.onChange(v.id);

                _setAreaAmphur(v.id);
                //props.onChange({ id: v.id, value: v.value });
              }
            }}
          />
        );
      },
      validate: data => {
        const [isValid, helperText] = MustNotNull(data);
        return { isValid: !isValid, helperText: helperText };
      }
    },

    {
      align: "center",
      field: "optAreaId",
      title: "เขตเลือกตั้ง",
      type: "numeric",
      editable: "onAdd",

      render: rowData => {
        const v = areaNoList.find(e => e.id === parseInt(rowData.optAreaId));
        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        const amphurId =
          _areaAmphur !== undefined || _areaAmphur !== null
            ? _areaAmphur
            : null;

        if (amphurId !== undefined) {
          return (
            <ListboxArea
              defaultValue={{ id: props.rowData.optAreaId }}
              optSelected={{ id: optSelected.id }}
              aumphurAreaSelected={{ id: amphurId }}
              optVoteTypeSelected={{ id: generalVoteDate.raw.optVoteTypeId }}
              optVoteIdSelected={{ id: generalVoteDate.id }}
              onChange={(i, e, v) => {
                if (e !== null) {
                  props.onChange(e.id);
                  // props.onChange({ id: e.id, value: e.value });
                  //props.rowData.optPositionStatusId = e.id;
                }
              }}
            ></ListboxArea>
          );
        } else {
          return (
            <ListboxArea
              defaultValue={{ id: props.rowData.optAreaId }}
              optSelected={{ id: optSelected.id }}
              validators={[MustNotNull]}
              optVoteTypeSelected={{ id: generalVoteDate.raw.optVoteTypeId }}
              optVoteIdSelected={{ id: generalVoteDate.id }}
              data = {areaNoList}
              onChange={(i, e, v) => {
                if (e !== null) {
                  props.onChange(e.id);
                  // props.onChange({ id: e.id, value: e.value });
                  //props.rowData.optPositionStatusId = e.id;
                }
              }}
            ></ListboxArea>
          );
        }
      },
      validate: data => {
        const [isValid, helperText] = MustNotNull(data);
        return { isValid: !isValid, helperText: helperText };
      }
    },
    {
      align: "center",
      field: "optPositionStatusId",
      title: "สาเหตุการจัดเลือกตั้ง",
      render: rowData => {
        const v = positionList.find(e => e.id === rowData.optPositionStatusId);
        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        return (
          <ListboxPositionStatus
            defaultValue={{ id: props.rowData.optPositionStatusId }}
            validators={[MustNotNull]}
            data={positionList}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.onChange(e.id);
                // props.onChange({ id: v.id, value: v.value });
                //props.rowData.optPositionStatusId = e.id;
              }
            }}
          />
        );
      },

      validate: data => {
        const [isValid, helperText] = MustNotNull(data);
        return { isValid: !isValid, helperText: helperText };
      }
    },
    {
      align: "center",
      field: "replaceDate",
      title: "วันที่เกิดเหตุ",
      render: props => {
        return ConvertDate(props.replaceDate);
      },
      editComponent: props => {
        const dateValue = props.value ? props.value : null;
        return (
          <Datepicker
            label="วันที่เกิดเหตุ"
            value={dateValue}
            onChange={date => props.onChange(date)}
          />
        );
      },
      validate: data => {
        const [isValid, helperText] = MustNotNull(data);
        return { isValid: !isValid, helperText: helperText };
      }
    },
    {
      align: "center",
      field: "remark",
      title: "หมายเหตุ",
      editComponent: props => {
        return (
          <CustomInput
            labelText="หมายเหตุ"
            inputProps={{
              onChange: e => props.onChange(e.target.value),
              defaultValue: props.rowData.remark
            }}
          />
        );
      }
    }
  ];
  console.log("addVoteDate [ C ]");
  // const getReplaceDetail = rd => {
  //   // positionId:date:remark:areaNo:amphurId
  //   let v = "";

  //   if (typeof rd.positionStatusId === "string") {
  //     v += rd.positionStatusId + ":";
  //   } else if (typeof rd.positionStatusId === "object") {
  //     v += rd.positionStatusId.id + ":";
  //   } else {
  //     v += ":";
  //   }

  //   if (typeof rd.effectedDate === "string") {
  //     v += rd.effectedDate + ":";
  //   } else if (typeof rd.effectedDate === "object") {
  //     v += rd.effectedDate.toISOString().split("T")[0] + ":";
  //   } else {
  //     v += ":";
  //   }

  //   v += (rd.remark ? rd.remark : "") + ":";

  //   if (typeof rd.areaNo === "string") {
  //     v += rd.areaNo + ":";
  //   } else if (typeof rd.areaNo === "object") {
  //     v += "i" + rd.areaNo.id + ":"; //i specific for Id of areaNo
  //   } else {
  //     v += ":";
  //   }

  //   if (rd.amphurId) {
  //     v += rd.amphurId.id + ";";
  //   } else {
  //     v += ";";
  //   }

  //   return v;
  // };
  console.log("addVoteDate [ D ]");
  const tableOptions = {
    exportButton: false
  };

  const verifyData = newData => {
    if ([1, 4, 5].includes(optVoteTypeSelected.id) && areaAmhurList.length > 0 && !newData.amphurId) {
      sweetAlerts.warning("กรุณาเลือกเขตอำเภอ");
      return false;
    }

    if (!newData.optAreaId) {
      sweetAlerts.warning("กรุณาเลือกเขต");
      return false;
    }

    if (!newData.optPositionStatusId) {
      sweetAlerts.warning("กรุณาเลือกสาเหตุจัดเลือกตั้ง");
      return false;
    }

    if (!newData.replaceDate) {
      sweetAlerts.warning("กรุณาเลือกวันที่");
      return false;
    }

    return true;
  };
  console.log("addVoteDate [ E ]");
  const editAble = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        if (!verifyData(newData)) {
          reject();
        } else {
          setTimeout(() => {
            const obj = replaceDetail.filter(
              e => e.optAreaId === parseInt(newData.optAreaId)
            ).length;
            if (obj === 0) {
              addReplacedetail(
                optSelected.id,
                optVoteId,
                parseInt(newData.optAreaId),
                newData.replaceDate.toISOString().split("T")[0],
                newData.optPositionStatusId,
                newData.remark === undefined ? "-" : newData.remark
              )
                .then(result => {
                  newData.optReplaceDetailId =
                    result.data.data.optReplaceDetailId;
                  newData.optAreaId = newData.optAreaId;
                  setReplaceDetail([...replaceDetail, newData]);
                  sweetAlerts.success("เพิ่มข้อมูลสำเร็จ");
                  resolve();
                })
                .catch(err => {
                  if (err.response.data && err.response.data.desc) {
                    sweetAlerts.error(
                      "เพิ่มเขตเลือกตั้งไม่สำเร็จ",
                      err.response.data.desc
                    );
                  } else {
                    sweetAlerts.error("เพิ่มเขตเลือกตั้งไม่สำเร็จ");
                  }

                  reject();
                });
            } else {
              sweetAlerts.error("เขตเลือกตั้งซ้ำกับที่เลือกไปแล้ว");
              reject();
            }
          }, 1000);

          // setReplaceDetail([...replaceDetail, newData]);
          // resolve();
        }
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        if (!verifyData(newData)) {
          reject();
        } else {
          const dataUpdate = [...replaceDetail];
          const index = oldData.tableData.id;

          editReplacedetail(
            optSelected.id,
            oldData.optReplaceDetailId,

            newData.replaceDate,
            newData.optPositionStatusId,
            newData.remark === undefined ? "-" : newData.remark
          )
            .then(result => {
              snackBars.success("แก้ไขข้อมูลเรียบร้อยแล้ว", 3000);
              dataUpdate[index] = newData;
              setReplaceDetail([...dataUpdate]);
              resolve();
            })
            .catch(err => {
              if (err.response.data && err.response.data.desc) {
                sweetAlerts.error(
                  "เพิ่มเขตเลือกตั้งไม่สำเร็จ",
                  err.response.data.desc
                );
              } else {
                sweetAlerts.error("เพิ่มเขตเลือกตั้งไม่สำเร็จ");
              }

              reject();
            });
        }
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        const dataDelete = [...replaceDetail];
        const index = oldData.tableData.id;

        deletedReplacedetail(optSelected.id, oldData.optReplaceDetailId)
          .then(result => {
            snackBars.success("ลบข้อมูลเรียบร้อยแล้ว", 3000);
            dataDelete.splice(index, 1);
            setReplaceDetail(dataDelete);
            resolve();
          })
          .catch(err => {
            reject();
          });
      })
  };
  console.log("addVoteDate [ F ]");
  // President state and ref
  const presCheckedRef = createRef();
  //const presRemarkRef = createRef();
  const [presRemark, setPresRemark] = useState(null);
  const [presNameFile, setPresNameFile] = useState(null);
  const [presClick, setPresClick] = useState(null);
  const [presUrlFile] = useState(null);
  const [presEffectedDate, setPresEffectedDate] = useState(null);
  const [presPositionStatusSelected, setPresPositionStatusSelected] = useState(
    null
  );
  const [presVoteStatusSelected, setPresVoteStatusSelected] = useState(null);

  // Handler
  const onGeneralVoteDate = (evt, newValue) => {
    setGeneralVoteDate(newValue);
  };

  // President Handler
  const onPresEffectedDate = date => {
    setPresEffectedDate(date);
  };
  const onPresPositionStatusSelected = (evt, newValue) => {
    setPresPositionStatusSelected(newValue);
    if (newValue) {
      newValue.id !== 14 ? setPresRemark(newValue.value) : setPresRemark("");
    }
  };
  const onPresVoteStatusSelected = (evt, newValue) => {
    setPresVoteStatusSelected(newValue);
  };

  // Member state and ref
  const [memRemark, setMemRemark] = useState(null);
  const memCheckedRef = createRef();
  const [memUrlFile] = useState(null);
  const [memUUIDFile, setMemUUIDFile] = useState(null);
  const [memChecked, setMemChecked] = useState(false);
  const [presUUIDFile, setPresUUIDFile] = useState(null);
  //const memRemarkRef = createRef();
  const [memEffectedDate, setMemEffectedDate] = useState(null);
  const [memPositionStatusSelected, setMemPositionStatusSelected] = useState(
    null
  );
  const [memVoteStatusSelected, setMemVoteStatusSelected] = useState(null);

  // Memberr Handler
  const onMemEffectedDate = date => {
    setMemEffectedDate(date);
  };

  const onMemPositionStatusSelected = (evt, newValue) => {
    setMemPositionStatusSelected(newValue);

    if (newValue) {
      newValue.id !== 14 ? setMemRemark(newValue.value) : setMemRemark("");
    }
  };
  const onMemVoteStatusSelected = (evt, newValue) => {
    setMemVoteStatusSelected(newValue);
  };

  const shouldShowDetial = () => {
    if (optSelected === null) return false;

    if (optVoteTypeSelected === null) return false;

    //if (optVoteTypeSelected.id !== 1 && generalVoteDate === null) return false;
    //2023-05-17 Surachai.k add ลเือกตั้งเขตใหม่
    if ((optVoteTypeSelected.id !== 1 && optVoteTypeSelected.id !== 4) && generalVoteDate === null) return false;

    return true;
  };

  console.log("addVoteDate [ G ]");

  const saveVoteDateHandler = () => {
    // console.log("presUrlFile", presUrlFile);
    if (optVoteTypeSelected === null) {
      sweetAlerts.warning("กรุณาประเภทการเลือกตั้ง");
      return;
    }

    if (optSelected === null) {
      sweetAlerts.warning("กรุณาเลือก อทป.");
      return;
    }

    if (voteDateSeleted === null) {
      sweetAlerts.warning("กรุณาระบุวันเลือกตั้ง");
      return;
    }

    if (
      presCheckedRef.current.checked === false &&
      memCheckedRef.current.checked === false
    ) {
      sweetAlerts.warning("กรุณาเลือกประเภทการเลือกตั้ง");
      return;
    }

    console.log("addVoteDate [ G->A ]");

    if (optVoteTypeSelected.id === 2 || optVoteTypeSelected.id === 3) {
      if (voteDateSeleted !== null) {
        if ((voteDateSeleted.toISOString().split("T")[0]) < (generalVoteDate.value.split("T")[0])) {
          if (optVoteTypeSelected.id === 2) {
            sweetAlerts.warning("กรุณาระบุวันเลือกตั้งแทนตำแหน่งที่ว่าง มากกว่าวันเลือกตั้งครบวาระ");
          } else if (optVoteTypeSelected.id === 3) {
            sweetAlerts.warning("กรุณาระบุวันเลือกตั้งใหม่ มากกว่าวันเลือกตั้งครบวาระ/แทนตำแหน่งที่ว่าง");
          } else if (optVoteTypeSelected.id === 4) {
            sweetAlerts.warning("กรุณาระบุวันเลือกตั้งใหม่ มากกว่าวันเลือกตั้งครบวาระ");
          }
          return;
        }
      }
    }

    console.log("addVoteDate [ G->B ]");

    const body = { optVoteTypeId: optVoteTypeSelected.id };

    if (presCheckedRef.current.checked === true) {
      if (!presPositionStatusSelected
        && optVoteTypeSelected.id !== 1
        //2023-05-29 Surachai.k Add เลือกตั้งเขตใหม่
        && optVoteTypeSelected.id !== 4
      ) {
        sweetAlerts.warning("กรุณาระบุสาเหตุการจัดเลือกตั้ง ของ ผ.ถ.");
        return;
      }
      if (!presEffectedDate) {
        sweetAlerts.warning("กรุณาระบุวันที่ของสาเหตุจัดเลือกตั้ง ของ ผ.ถ.");
        return;
      }

      if (presCheckedRef.current.checked === true) {
        if (!presEffectedDate) {
          sweetAlerts.warning("กรุณาระบุวันที่ของสาเหตุจัดเลือกตั้ง ของ ผ.ถ.");
          return;
        }
        //2023-05-17 Surachai.k Add เลือกตั้งเขตใหม่
        if (optVoteTypeSelected.id === 4) {
          body.localAdministrator = {
            startEffectiveDate: voteDateSeleted.toISOString().split("T")[0],
            //startEffectiveDate: presEffectedDate.toISOString().split("T")[0],
            //generalOptVoteId: generalVoteDate === null ? null : generalVoteDate.id,

            replaceDate: voteDateSeleted.toISOString.split("T")[0],
            //replaceDate: presEffectedDate.toISOString().split("T")[0],
            fileUUID: presUUIDFile,
            optVoteStatusId: 2,
            verifyStatusId: 1,
            replaceRemark: "-",
            remark: "-",
            replaceStatusId: presPositionStatusSelected && presPositionStatusSelected.id
          };
        }
        else {
          body.localAdministrator = {
            startEffectiveDate: voteDateSeleted.toISOString().split("T")[0],
            generalOptVoteId: generalVoteDate === null ? null : generalVoteDate.id,
            replaceDate: presEffectedDate.toISOString().split("T")[0],
            fileUUID: presUUIDFile,
            optVoteStatusId: 2,
            // optVoteStatusId: presPositionStatusSelected.id,
            verifyStatusId: 1,
            replaceRemark: "-",
            remark: "-",
            replaceStatusId:
              presPositionStatusSelected && presPositionStatusSelected.id
            // optVoteTypeSelected.id === 1
            //   ? 2
            //   : presPositionStatusSelected === null
            //   ? null
            //   : presPositionStatusSelected.id
          };
        }
        //console.info(body.localAdministrator);
      }
    }

    console.log("addVoteDate [ G->C ]");

    if (memCheckedRef.current.checked === true) {
      if (optVoteTypeSelected.id !== 2) {
        if (!memEffectedDate) {
          sweetAlerts.warning("กรุณาระบุวันที่ของสาเหตุจัดเลือกตั้ง");
          return;
        }
        if (!memPositionStatusSelected && optVoteTypeSelected.id !== 1) {
          sweetAlerts.warning("กรุณาระบุสาเหตุการจัดเลือกตั้ง");
          return;
        }
      }
      //console.log({"generalVoteDate":generalVoteDate,"voteDateSeleted":voteDateSeleted,"memEffectedDate":memEffectedDate});
      body.localCouncilor = {
        startEffectiveDate: voteDateSeleted.toISOString().split("T")[0],
        replaceDate:
          optVoteTypeSelected && optVoteTypeSelected.id === 2
            ? new Date()
            : memEffectedDate.toISOString().split("T")[0],
        fileUUID: memUUIDFile,
        optVoteStatusId: 2,
        verifyStatusId: 1,
        remark: "-",
        replaceRemark:
          optVoteTypeSelected && optVoteTypeSelected.id === 2
            ? "เลือกตั้งแทนตำแหน่งที่ว่าง"
            : "-",
        replaceStatusId:
          memPositionStatusSelected && memPositionStatusSelected.id
        // optVoteTypeSelected.id === 1
        //   ? 2
        //   : memPositionStatusSelected === null
        //   ? null
        //   : memPositionStatusSelected.id
        // replaceStatusId:
        //   memPositionStatusSelected === null
        //     ? null
        //     : optVoteTypeSelected.id === 1
        //     ? 2
        //     : memPositionStatusSelected.id
      };

      //if (optVoteTypeSelected.id === 1) {
      //2023-05-17 Surachai.k add เลือกตั้งเขตใหม่
      if (optVoteTypeSelected.id === 1 || optVoteTypeSelected.id === 4) {
        body.localCouncilor.optAreaVote = dataTable.map(v => {
          return {
            optAreaId: v.opt_area_id,
            numMember: v.num_member
          };
        });
      }

      if(optVoteTypeSelected.id !== 1) {
        body.localCouncilor.generalOptVoteId = generalVoteDate.id;
      }
      // positionId:date:remark:areaNo:amphurId
      // replaceDetail.forEach((v, idx) => {
      //   if (v !== undefined && v.areaNo !== null) {
      //     replaceDetailStr += getReplaceDetail(v);
      //     if (optAreaVoteObjs[v.areaNo.id] === undefined) {
      //       optAreaVoteObjs[v.areaNo.id] = {
      //         optAreaId: v.areaNo.id,
      //         numMember: 1
      //       };
      //     } else {
      //       optAreaVoteObjs[v.areaNo.id].numMember += 1;
      //     }
      //   }
      // });

      // let optAreaVote = [];
      // for (const [, value] of Object.entries(optAreaVoteObjs)) {
      //   optAreaVote.push(value);
      // }
      // body.localCouncilor.replaceDetail = replaceDetailStr;
      //body.localCouncilor.optAreaVote = optAreaVote;
    }
    //console.log(body);
    //2023-05-17 Surachai.k Add case of เลือกตั้งเขตใหม่
    //console.info({"optSelected":optSelected.id,"voteDate":presEffectedDate.toISOString().split("T")[0],"voteDateSeleted":voteDateSeleted.toISOString().split("T")[0],"body":body});
    //console.info({"optSelected":optSelected.id,"presEffectedDate":presEffectedDate,"voteDateSeleted":voteDateSeleted,"body":body});
    //case of เลือกตั้งเขตใหม่


    if (optVoteTypeSelected.id === 4) {
      console.log("addVoteDate [ G->D(4) ]");
      AddNewVoteDate(
        optSelected.id,
        //presEffectedDate != null ? presEffectedDate.toISOString().split("T")[0]:'',
        voteDateSeleted != null ? voteDateSeleted.toISOString().split("T")[0] : '',
        body
      )
        .then(r => {
          if (optVoteTypeSelected.id === 4) {
            sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
            setPageProps({ active: "MangeVoteDate", action: "cancel" });
            // setPageProps({ active: "MangeVoteDate", props: "addsuccess" });
          }
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.code === 40301) {
              sweetAlerts.error("ไม่สามารถบันทึกวันเลือกตั้งซ้ำ");
            } else {
              sweetAlerts.error(
                "ไม่สามารถบันทึกวันเลือกตั้ง: ",
                error.response.data.desc
              );
            }
          }
        });
    }
    else {
      console.log("addVoteDate [ G->D(!4) ]");
      AddNewVoteDate(
        optSelected.id,
        voteDateSeleted.toISOString().split("T")[0],
        body
      )
        .then(r => {
          if (optVoteTypeSelected.id === 1) {
            sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
            setPageProps({ active: "MangeVoteDate", action: "cancel" });
            // setPageProps({ active: "MangeVoteDate", props: "addsuccess" });
          }
          if (optVoteTypeSelected.id > 1) {
            sweetAlerts.success(
              "บันทึกข้อมูลสำเร็จ กรุณาเพิ่มเขตที่ต้องการจัดเลือกตั้ง"
            );
            setDisabled(false);
            setOptVoteId(r.data.data.localCouncilor.optVoteId);
          }
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.code === 40301) {
              sweetAlerts.error("ไม่สามารถบันทึกวันเลือกตั้งซ้ำ");
            } else {
              sweetAlerts.error(
                "ไม่สามารถบันทึกวันเลือกตั้ง: ",
                error.response.data.desc
              );
            }
          }
        });
    } //---- ELSE
  };

  const cancelVoteDateHandler = () => {
    setPageProps({ active: "MangeVoteDate", action: "cancel" });
  };

  useEffect(() => {
    if (
      optSelected !== null &&
      optVoteTypeSelected !== null &&
      optVoteTypeSelected.id === 1
      //2023-05-17 Surachai.k Add เลือกตั้งเขตใหม่
      //( optVoteTypeSelected.id === 1 || optVoteTypeSelected.id === 4)
    ) {
      //case of เลือกตั้งครบวาระ และกด รับทราบ ปุ่มสีเหลือง
      getAllOptAreaByOptId(optSelected.id)
        .then(r => {
          setDataTable(r.data.data[0].opt_area);
        })
        .catch(err => {
          console.log("error while fetching OPT area", err);
        });
    }
    if (optSelected !== null && generalVoteDate !== null) {
      const amphur = getAmphurArea(
        optSelected.id,
        1,
        generalVoteDate.value.split("T")[0],
        null
      );
      const areaNo = getAreaNoLists(
        optSelected.id,
        generalVoteDate.raw.optVoteTypeId,
        generalVoteDate.value.split("T")[0],
        null
      );
      const position = getPositionStatus();
      Promise.all([amphur, areaNo, position])
        .then(r => {
          //   console.log("promise:", r[1].data.data[0]);
          let areaNoMapper = {};
          let areaNoListFromApi = r[1].data.data[0].opt_area;
          areaNoListFromApi.forEach(v => {
            areaNoMapper[v.opt_area_id] = {
              id: v.opt_area_id,
              value: v.area_no
            };
          });

          let positionMapper = {};
          r[2].data.data.forEach(v => {
            positionMapper[v.statusId] = {
              id: v.statusId,
              value: v.detail
            };
            return positionMapper[v.statusId];
          });
        })
        .catch(err => {
          console.log("error:", err);
        });
    }
    return () => {
      setReplaceDetail([]);
    };
  }, [optSelected, optVoteTypeSelected, generalVoteDate]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" text>
              <CardText color="primary">
                <h4>บันทึกวันเลือกตั้ง</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <MainVotedate titleheader="บันทึกวันเลือกตั้ง" color="info">
                  <GridItem xs={12} sm={12} md={4}>
                    {optVoteTypeSelected !== null &&
                      optVoteTypeSelected !== undefined &&
                      //optVoteTypeSelected.id !== 1 ? (
                      //2023-05-17 Surachai.k Add เลือกตั้งเขตใหม่
                      (optVoteTypeSelected.id !== 1) ? (
                      <ListBoxDate
                        title={
                          optVoteTypeSelected !== null &&
                            optVoteTypeSelected !== undefined &&
                            (optVoteTypeSelected.id === 1 ||
                              optVoteTypeSelected.id === 2)
                            ? "วันเลือกตั้งครบวาระ"
                            : (optVoteTypeSelected.id === 4 ? "วันเลือกตั้งครบวาระ" : "วันเลือกตั้งครบวาระ/แทนตำแหน่งที่ว่าง"
                            )
                        }
                        optVoteTypeSelected={optVoteTypeSelected}
                        optSelected={optSelected}
                        mode={"Add"}
                        onChange={onGeneralVoteDate}
                      />
                    ) : null}
                    <Datepicker
                      id="AddVoteDate"
                      label={
                        optVoteTypeSelected != null &&
                          optVoteTypeSelected != undefined &&
                          optVoteTypeSelected.id == 1
                          ? "วันเลือกตั้งครบวาระ"
                          : optVoteTypeSelected != null &&
                            optVoteTypeSelected != undefined &&
                            optVoteTypeSelected.id == 2
                            ? "วันเลือกตั้งแทนตำแหน่งที่ว่าง"
                            : optVoteTypeSelected != null &&
                              optVoteTypeSelected != undefined &&
                              optVoteTypeSelected.id == 3
                              ? "วันเลือกตั้งใหม่"
                              : "วันเลือกตั้งเขตใหม่"
                      }
                      //minDate={new Date()}
                      value={voteDateSeleted}
                      onChange={date => {
                        if (date) {
                          //s let arr = date.format().split("T");
                          setVoteDateSeleted(date);
                          //s   setDisabled(true);
                          //presCheckedRef.current.checked === false;
                          //  setMemChecked(false);

                          // setData({
                          //   ...data,
                          //   birthdate: arr[0],
                          //   age: getAge(date.format())
                          // });
                        }
                      }}
                    // onChange={onNewVoteDateChange}
                    // onChange={setVoteDateSeleted}
                    ></Datepicker>
                  </GridItem>
                </MainVotedate>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {shouldShowDetial() ? null : (
        <GridContainer direction="row" justify="flex-end" alignItems="flex-end">
          <GridItem>
            <Button size="sm" onClick={cancelVoteDateHandler}>
              ย้อนกลับ
            </Button>
          </GridItem>
        </GridContainer>
      )}

      {!shouldShowDetial() ? null : (
        <>
          <form>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={6}
                style={
                  //Surachai.K กรณีเลือกตั้งเขตใหม่และเลือกตั้งแทนตำแหน่งที่ว่างไม่จำเป็นต้องเลือก ผ.ถ.
                  optVoteTypeSelected.id === 2 || optVoteTypeSelected.id === 4
                    ? { pointerEvents: "none", opacity: "0.4" }
                    : {}
                }
              >
                <PresidentDetailVotedate
                  optVoteTypeSelected={optVoteTypeSelected}
                  presUrlFile={presUrlFile}
                  presUUIDFile={presUUIDFile}
                  setPresUUIDFile={setPresUUIDFile}
                  presCheckedRef={presCheckedRef}
                  presRemark={presRemark}
                  setPresNameFile={setPresNameFile}
                  setPresClick={setPresClick}
                  presEffectedDate={presEffectedDate}
                  onPresEffectedDate={onPresEffectedDate}
                  presPositionStatusSelected={presPositionStatusSelected}
                  onPresPositionStatusSelected={onPresPositionStatusSelected}
                  presVoteStatusSelected={presVoteStatusSelected}
                  onPresVoteStatusSelected={onPresVoteStatusSelected}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <MemberDetailVotedate
                  optVoteTypeSelected={optVoteTypeSelected}
                  memUrlFile={memUrlFile}
                  memUUIDFile={memUUIDFile}
                  setMemUUIDFile={setMemUUIDFile}
                  memCheckedRef={memCheckedRef}
                  memChecked={memChecked}
                  onMemChecked={e => {
                    setMemChecked(e.target.checked);
                  }}
                  memRemark={memRemark}
                  memEffectedDate={memEffectedDate}
                  //optVoteTypeSelected={optVoteTypeSelected}
                  onMemEffectedDate={onMemEffectedDate}
                  memPositionStatusSelected={memPositionStatusSelected}
                  onMemPositionStatusSelected={onMemPositionStatusSelected}
                  memVoteStatusSelected={memVoteStatusSelected}
                  onMemVoteStatusSelected={onMemVoteStatusSelected}
                />
              </GridItem>
            </GridContainer>
            {/* )} */}
            <div style={disabled === true ? {} : { display: "none" }}>
              <GridContainer
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <GridItem>
                  <Button size="sm" onClick={cancelVoteDateHandler}>
                    ยกเลิก
                  </Button>
                  <Button
                    size="sm"
                    color="success"
                    onClick={saveVoteDateHandler}
                  >
                    บันทึก
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
            <div
              style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {/* {optVoteTypeSelected !== null &&
                  optVoteTypeSelected.id > 1 ? (
                    <>
                      {presClick && (
                        <TablePresidentNewVote
                          presEffectedDate={presEffectedDate}
                          presPositionStatusSelected={
                            presPositionStatusSelected
                          }
                          fileUUID={presUUIDFile}
                          filename={presNameFile}
                        />
                      )}
                      {memChecked && (
                        <MaterialTable
                          data={replaceDetail}
                          columns={columns}
                          options={tableOptions}
                          title="เขตเลือกตั้ง"
                          editable={editAble}
                        />
                      )}
                    </>
                  ) : (
                    <TableComplete data={dataTable} optSelected={optSelected} />
                  )} */}

                  {optVoteTypeSelected !== null && presClick && (
                    <TablePresidentNewVote
                      presEffectedDate={presEffectedDate}
                      presPositionStatusSelected={presPositionStatusSelected}
                      fileUUID={presUUIDFile}
                      filename={presNameFile}
                    />
                  )}
                  {optVoteTypeSelected !== null &&
                    memChecked &&
                    optVoteTypeSelected.id !== 1 && (
                      <MaterialTable
                        data={replaceDetail}
                        columns={columns}
                        options={tableOptions}
                        title="เขตเลือกตั้ง"
                        editable={editAble}
                      />
                    )}

                  {optVoteTypeSelected !== null &&
                    memChecked &&
                    optVoteTypeSelected.id === 1 && (
                      <TableComplete
                        data={dataTable}
                        optSelected={optSelected}
                      />
                    )}
                </GridItem>
              </GridContainer>
            </div>
            <GridContainer
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <GridItem>
                <Button size="sm" onClick={cancelVoteDateHandler}>
                  กลับไปหน้าจัดการวันเลือกตั้ง
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </>
      )}
    </>
  );
};
export default AddVoteDate;
