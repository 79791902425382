import MaterialTable from "components/Table/MaterialTable";
import React, { useState, useEffect } from "react";
import { getPositionType, editPositionType } from "contexts/API/OPT/Setting";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { useSnackBar } from "components/Alert/Snackbar";

const PositionType = props => {
  const { sweetAlerts } = useSweetAlert();
  const { snackBars } = useSnackBar();
  const [data, setData] = useState([]);
  const optionsTable = { pageSize: 10 };
  const [columns] = useState([
    {
      title: "positionTypeId",
      field: "positionTypeId",
      hidden: true
    },
    {
      title: "ประเภทตำแหน่ง",
      field: "positionTypeDetail",
      cellStyle: {
        minWidth: "600px"
      }
    },
    {
      title: "อักษรย่อประเภทตำแหน่ง",
      field: "positionTypeShortNameOrginal",
      cellStyle: {
        minWidth: "300px"
      }
    }
  ]);
  useEffect(() => {
    getPositionType()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaterialTable
      title={"บริหารจัดการ ประเภทตำแหน่ง สมาชิกสภาท้องถิ่นและผู้บริหารท้องถิ่น"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              editPositionType(
                oldData.positionTypeId,
                newData.positionTypeDetail,
                newData.positionTypeShortNameOrginal
              )
                .then(result => {
                  snackBars.success("แก้ไขข้อมูลเรียบร้อยแล้ว", 3000);
                  setData([...dataUpdate]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });

              resolve();
            }, 1000);
          })
      }}
    />
  );
};
export default PositionType;
