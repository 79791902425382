import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getCheckPerson(idCard, checkDate,listIdCard) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/checkOptPerson`;
  const data = {
    date: checkDate,
    idCard: idCard,
    listIdCard:listIdCard
  }
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    },
    
  });
}
