/* core components */
import React, { useEffect, useState } from "react";

import AddLawRevoke from "./AddLawRevoke";
import AddLawSuspend from "./AddLawSuspend";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputWithValidator from "components/CustomInput/CustomInputWithValidator";
import Datepicker from "components/Common/Datepicker";
import { FileUpload } from "components/Common/FileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import ListboxSuspendOrg from "components/Common/ListboxSuspendOrg";
import ListboxSuspendStatus from "components/Common/ListboxSuspendStatus";
import ListboxSuspendVoteType from "components/Common/ListboxSuspendVoteType";
import ListboxTitle from "components/Common/ListboxTitle";
import ListboxSuspendYear from "components/Common/ListboxSuspendYear";
//import ListboxWtdOrg from "components/Common/ListboxWtdOrg";
//import ListboxWtdStatus from "components/Common/ListboxWtdStatus";
//import ListboxWtdVoteType from "components/Common/ListboxWtdVoteType";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import { editSuspendPerson } from "contexts/API/SUSPEND/ListSuspend";
import getAge from "utils/CalculateAge";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { getSuspendPerson } from "contexts/API/SUSPEND/ListSuspend";
import { makeStyles } from "@material-ui/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";
import suspendEndDate from "utils/WtdEndDate";
import moment from "moment";

const EditPerson = ({ pageProps, setPageProps }) => {
  let _initialState = {
    idCard: null,
    genderId: null,
    titleId: null,
    firstName: null,
    lastName: null,
    birthdate: null,
    addr1: null,
    addr1TambolId: null,
    addr1AmphurId: null,
    addr1ProvinceId: null,
    suspend: {
      suspendFlag: false,
      suspendOrgId: null,
      suspendStatusId: null,
      suspendFileUUID: null,
      suspendYear: null
    },
    revoke: {
      revokeFlag: false,
      revokeOrgId: null,
      revokeStatusId: null,
      revokeFileUUID: null,
      revokeYear: null
    }
  };

  const useStyles = makeStyles({});

  const classes = useStyles();

  const { sweetAlerts } = useSweetAlert();

  const [data, setData] = useState(_initialState);

  const [suspendFlag, setSuspendFlag] = useState(false);
  const [, setRevokeFlag] = useState(false);

  const [, setSuspendUrlFile] = useState();
  const [, setRevokeUrlFile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [helpertextGender, setHelpertextGender] = useState(null);
  const [helpertextSuspend, setHelpertextSuspend] = useState("");
  const [, setHelpertextfname] = useState(null);
  const [helpertextlname, setHelpertextlname] = useState(null);
  const [helpertexttitle, setHelpertexttitle] = useState(null);
  const [helpertextRevoke, setHelpertextRevoke] = useState("");
  const [, setHelpertextAddr1] = useState("");
  const [helpertextProvince1, setHelpertextProvince1] = useState("");
  const [helpertextAmphur1, setHelpertextAmphur1] = useState("");
  const [helpertextTambol1, setHelpertextTambol1] = useState("");
  const [helpertextBd, setHelpertextBd] = useState("");
  const [, setAddr1ProvinceId] = useState(null);
  const [, setAddr1AmphurId] = useState(null);
  const [, setAddr1TambolId] = useState(null);
  const [suspendLaw, setSuspendLaw] = useState([]);
  const [revokeLaw, setRevokeLaw] = useState([]);

  const onadd1ProvinceSelected = (evt, newValue) => {
    setAddr1ProvinceId(newValue);
    setAddr1AmphurId(null);
    setAddr1TambolId(null);
    setData({ ...data, addr1ProvinceId: newValue ? newValue.id : 0 });
    if (newValue) {
      if (newValue.id === 0) {
        setHelpertextProvince1("กรุณาระบุ*");
      } else {
        setHelpertextProvince1("");
      }
    }
  };
  const onadd1AmphurSelected = (evt, newValue) => {
    setAddr1AmphurId(newValue);
    setAddr1TambolId(null);
    setData({ ...data, addr1AmphurId: newValue ? newValue.id : 0 });
    if (newValue) {
      if (newValue.id === 0) {
        setHelpertextAmphur1("กรุณาระบุ*");
      } else {
        setHelpertextAmphur1("");
      }
    }
  };
  const onadd1TambolSelected = (evt, newValue) => {
    setAddr1TambolId(newValue);
    setData({ ...data, addr1TambolId: newValue ? newValue.id : 0 });
    if (newValue) {
      if (newValue.id === 0) {
        setHelpertextTambol1("กรุณาระบุ*");
      } else {
        setHelpertextTambol1("");
      }
    }
  };

  const backtoMainHandler = () => {
    setPageProps({ active: "ListSuspendPerson", action: "cancel" });
  };

  useEffect(() => {
    if (
      data.revoke.revokeFlag &&
      (data.revoke.revokeOrgId === null ||
        data.revoke.revokeSdate === null ||
        data.revoke.revokeYear === null ||
        data.revoke.revokeEdate === null ||
        data.revoke.revokeStatusId === null ||
        data.revoke.revokeFileUUID === null)
    ) {
      //console.log("dddddd---");
      if (data.revoke.revokeYear === null) {
        setData({
          ...data,
          revoke: {
            ...data.revoke,
            revokeYear: 10
          }
        });
      }
      setHelpertextRevoke("กรุณาระบุข้อมูลให้ครบถ้วน");
    } else {
      setHelpertextRevoke("");
    }
  }, [data.revoke]);

  useEffect(() => {
    if (
      data.suspend.suspendFlag &&
      (data.suspend.suspendOrgId === null ||
        data.suspend.suspendSdate === null ||
        data.suspend.suspendYear === null ||
        data.suspend.suspendEdate === null ||
        data.suspend.suspendStatusId === null)
    ) {
      setHelpertextSuspend("กรุณาระบุข้อมูลให้ครบถ้วน");
    } else {
      setHelpertextSuspend("");
    }
  }, [data.suspend]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const person = await getSuspendPerson(pageProps.props.rowData);
      const value = person.data.data.person[0];
      console.log("value >>>", value)
      if (value) {
        setData({
          ...value,
          idCard: value.idCard,
          voteTypeId: value.suspendPerson[0].voteTypeId,
          suspendPersonId: value.suspendPerson[0].suspendPersonId,
          suspendStatusId: value.suspendPerson[0].suspendStatusId,
          suspend: {
            suspendFlag: value.suspendPerson[0].suspendFlag,
            suspendOrgId: value.suspendPerson[0].suspendOrgId,
            suspendSdate: value.suspendPerson[0].suspendSdate,
            suspendYear: value.suspendPerson[0].suspendYear,
            suspendEdate: value.suspendPerson[0].suspendEdate,
            suspendStatusId: value.suspendPerson[0].suspendStatusId,
            suspendFileUUID: value.suspendPerson[0].suspendFileUUID,
            suspendFileName: value.suspendPerson[0].suspendFileName
          },
          revoke: {
            revokeFlag: value.suspendPerson[0].revokeFlag,
            revokeOrgId: value.suspendPerson[0].revokeOrgId,
            revokeSdate: value.suspendPerson[0].revokeSdate,
            revokeYear: value.suspendPerson[0].revokeYear,
            revokeEdate: value.suspendPerson[0].revokeEdate,
            revokeStatusId: value.suspendPerson[0].revokeStatusId,
            revokeFileUUID: value.suspendPerson[0].revokeFileUUID,
            revokeFileName: value.suspendPerson[0].revokeFileName
          }
        });
        setRevokeFlag(value.suspendPerson[0].revokeFlag);
        setSuspendFlag(value.suspendPerson[0].suspendFlag);
        setSuspendUrlFile(value.suspendPerson[0].suspendFileUUID);
        setRevokeUrlFile(value.suspendPerson[0].revokeFileUUID);
        setSuspendLaw(person.data.data.suspendLaw);
        setRevokeLaw(person.data.data.revokeLaw);
        setIsLoading(false);

        console.log("data >>>", data)
      } else {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelSubmit = () => {
    //console.log("data>>>", data);
    //   const dataUpdate = data;
    let newMessage = { ...data };

    if (data.addr1ProvinceId === 0 || undefined) {
      setHelpertextProvince1("กรุณาระบุจังหวัด*");
    }
    if (data.addr1 === null) {
      setHelpertextAddr1("กรุณาระบุที่อยู่*");
    }
    if (data.addr1AmphurId === 0 || undefined) {
      setHelpertextAmphur1("กรุณาระบุอำเภอ*");
    }
    if (data.addr1TambolId === 0 || undefined) {
      setHelpertextTambol1("กรุณาระบุตำบล*");
    }
    if (data.genderId === null) {
      setHelpertextGender("กรุณาระบุเพศ*");
    }
    if (data.titleId === null) {
      setHelpertexttitle("กรุณาระบุคำนำหน้าชื่อ*");
    }
    if (data.firstName === null) {
      setHelpertextfname("กรุณาระบุชื่อ*");
    }
    if (data.lastName === null) {
      setHelpertextlname("กรุณาระบุนามสกุล*");
    }

    if (data.birthdate === null) {
      setHelpertextBd("กรุณาระบุวันเกิด*");
    }

    if (
      data.suspend.suspendFlag === false ||
      data.suspend.suspendFlag === null
    ) {
      setHelpertextSuspend("");
      if (data.suspend.suspendStatusId === null &&
        data.suspend.suspendYear === null &&
        data.suspend.suspendOrgId === null &&
        data.suspend.suspendSdate === null
      ) {
        delete newMessage.suspend;
        console.log(" >>>> delete suspend <<<<")
      }
    }
    if (data.revoke.revokeFlag === false || data.revoke.revokeFlag === null) {
      setHelpertextRevoke("");
      if (data.revoke.revokeStatusId === null &&
        data.revoke.revokeOrgId === null &&
        data.revoke.revokeYear === null &&
        data.revoke.revokeSdate === null
      ) {
        delete newMessage.revoke;
      }

    } else {
      if (
        data.revoke.revokeFileName === undefined ||
        data.revoke.revokeFileName === null
      ) {
        sweetAlerts.warning("กรุณาระบุเอกสารการถูกเพิกถอนสิทธิสมัครฯ");
        return;
      }
    }

    //console.log("data.addr1ProvinceId >>>",data.addr1ProvinceId);
    //console.log("data.addr1AmphurId >>>",data.addr1AmphurId);

    /* console.log("data.genderId >>>", data.genderId);
    console.log("data.addr1TambolId >>>", data.addr1TambolId);
    console.log("data.firstName >>>", data.firstName);
    console.log("data.lastName >>>", data.lastName);

    console.log("helpertextSuspend >>>", helpertextSuspend);
    console.log("helpertextRevoke >>>", helpertextRevoke);

    console.log("data.revoke.revokeYear >>>", data.revoke.revokeYear); */

    if (
      data.genderId !== null &&
      data.addr1TambolId !== 0 &&
      data.firstName !== null &&
      data.lastName !== null &&
      data.titleId !== 0 &&
      data.addr1ProvinceId !== 0 &&
      data.addr1AmphurId !== 0 &&
      data.birthdate !== null &&
      data.voteTypeId !== null &&
      data.addr1 !== null
      //helpertextSuspend === "" &&
      //helpertextRevoke === ""
    ) {
      editSuspendPerson(data.suspendPersonId, newMessage)
        .then(r => {
          sweetAlerts.success("บันทึกข้อมูลสำเร็จ");

          setPageProps({ active: "ListSuspendPerson", action: "cancel" });
        })
        .catch(error => {
          if (error.response) {
            sweetAlerts.error(
              "ไม่สามารแก้ไขข้อมูลได้ ",
              error.response.data.desc
            );
          }
        });
    } else {
      sweetAlerts.warning(
        "กรุณาระบุข้อมูลให้ครบถ้วน"
      );
    }
  };

  let content = <LoadingData />;
  if (isLoading === false) {
    content = (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>
                    แก้ไขข้อมูลผู้ถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ
                  </h4>
                </CardText>
              </CardHeader>

              <CardHeader>
                <h4 className={classes.cardTitle}>ข้อมูลส่วนบุคคล</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <NumberFormat
                          placeholder="เลขประจำตัวประชาชน"
                          customInput={TextField}
                          format="# #### ##### ## #"
                          mask="X"
                          fullWidth
                          value={data.idCard}
                          readOnly={true}
                          disabled={true}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Button disabled={true} color="info">
                          ตรวจสอบ
                        </Button>
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <FormControlLabel
                          disabled={false}
                          control={
                            <Radio
                              checked={
                                data.genderId === "true" ||
                                data.genderId === true
                              }
                              value={true}
                              onClick={e => {
                                if (e.target.checked) {
                                  setHelpertextGender("");
                                } else {
                                  setHelpertextGender("กรุณาระบุ*");
                                }
                                setData({
                                  ...data,
                                  genderId: e.target.value
                                });
                              }}
                              name="gender_id"
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="ชาย"
                        />
                        <FormControlLabel
                          disabled={
                            [2, 5].includes(data.wtdStatusDetail) ? true : false
                          }
                          control={
                            <Radio
                              onClick={e => {
                                if (e.target.checked) {
                                  setHelpertextGender("");
                                } else {
                                  setHelpertextGender("กรุณาระบุ*");
                                }
                                setData({
                                  ...data,
                                  genderId: e.target.value
                                });
                              }}
                              checked={
                                data.genderId === "false" ||
                                data.genderId === false
                              }
                              value={false}
                              name="genderId"
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="หญิง"
                        />
                        <FormHelperText style={{ color: "red" }}>
                          {helpertextGender}
                        </FormHelperText>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        {/* คำนำหน้าชื่อ */}
                        <ListboxTitle
                          validators={[MustNotNull]}
                          defaultValue={{ id: data.titleId }}
                          onChange={(evt, newValue) => {
                            if (newValue) {
                              setData({
                                ...data,
                                titleId: newValue ? newValue.id : 0
                              });
                              setHelpertexttitle("");
                            } else {
                              setData({
                                ...data,
                                titleId: null
                              });
                              setHelpertexttitle("กรุณาระบุ*");
                            }
                          }}
                        ></ListboxTitle>
                        <FormHelperText style={{ color: "red" }}>
                          {helpertexttitle}
                        </FormHelperText>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInputWithValidator
                          validators={[MustNotNull]}
                          labelText="ชื่อ"
                          formControlProps={{
                            fullWidth: true
                          }}
                          style={{ fontSize: "18px !important " }}
                          inputProps={{
                            value: data.firstName || "",

                            onChange: e => {
                              if (e) {
                                setData({
                                  ...data,
                                  firstName: e.target.value
                                });
                                setHelpertextfname("");
                              } else setHelpertextfname("กรุณาระบุ");
                            }

                            // disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInputWithValidator
                          validators={[MustNotNull]}
                          labelText="นามสกุล"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            value: data.lastName || "",
                            onChange: e => {
                              if (e) {
                                setData({
                                  ...data,
                                  lastName: e.target.value
                                });
                                setHelpertextlname("");
                              } else setHelpertextlname("กรุณาระบุ");
                            }
                          }}
                        />
                        <FormHelperText style={{ color: "red" }}>
                          {helpertextlname}
                        </FormHelperText>
                      </GridItem>
                    </GridContainer>
                    <br></br>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInputWithValidator
                          validators={[MustNotNull]}
                          labelText="ที่อยู่ตามบัตรประชาขน"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            /*  disabled: [2, 5].includes(data.wtdStatusId)
                                 ? true
                                 : false, */
                            value: data.addr1 || "",
                            onChange: e => {
                              setData({ ...data, addr1: e.target.value });
                            }
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <ListboxProvince
                          required={true}
                          validators={[MustNotNull]}
                          defaultValue={{
                            id: data.addr1ProvinceId
                          }}
                          onChange={onadd1ProvinceSelected}
                        ></ListboxProvince>
                        <FormHelperText style={{ color: "red" }}>
                          {helpertextProvince1}
                        </FormHelperText>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <ListboxDistinct
                          required={true}
                          validators={[MustNotNull]}
                          defaultValue={{
                            id: data.addr1AmphurId
                          }}
                          provinceSelected={{ id: data.addr1ProvinceId }}
                          onChange={onadd1AmphurSelected}
                        ></ListboxDistinct>
                        <FormHelperText style={{ color: "red" }}>
                          {helpertextAmphur1}
                        </FormHelperText>
                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <ListboxSubdistinct
                          required={true}
                          validators={[MustNotNull]}
                          amphurSelected={{ id: data.addr1AmphurId }}
                          defaultValue={{
                            id: data.addr1TambolId
                          }}
                          onChange={onadd1TambolSelected}
                        ></ListboxSubdistinct>
                        <FormHelperText style={{ color: "red" }}>
                          {helpertextTambol1}
                        </FormHelperText>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}></GridItem>
                    </GridContainer>
                    <GridContainer
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <Datepicker
                          id="begin"
                          label="วันเดือนปีเกิด"
                          value={data.birthdate}
                          onChange={date => {
                            if (date) {
                              let arr = date.format().split("T");
                              setData({
                                ...data,
                                birthdate: arr[0],
                                age: getAge(date.format())
                              });
                              setHelpertextBd("");
                            } else {
                              setHelpertextBd("กรุณาระบุ");
                            }
                          }}
                        ></Datepicker>
                        <FormHelperText style={{ color: "red" }}>
                          {helpertextBd}
                        </FormHelperText>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        {/* <div style={{ marginTop: "15px" }}> */}

                        <CustomInput
                          labelText="อายุ"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value:
                              data.birthdate === null
                                ? 0
                                : getAge(data.birthdate),
                            disabled: true
                          }}
                        />
                        {/* </div> */}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <ListboxSuspendVoteType
                          validators={[MustNotNull]}
                          defaultValue={{
                            id: data.voteTypeId
                          }}
                          onChange={(e, v) => {
                            if (v) {
                              setData({
                                ...data,
                                voteTypeId: v.id
                              });
                            } else {
                              setData({
                                ...data,
                                voteTypeId: null
                              });
                            }
                          }}
                        ></ListboxSuspendVoteType>

                        {/* <FormHelperText style={{ color: "red" }}>
                            {helpertextvotetype}
                          </FormHelperText> */}
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}></GridItem>
                    </GridContainer>
                    <br /> <br />
                  </GridItem>
                </GridContainer>
                {/* มติ กกต. */}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Card>
                      <CardHeader color="rose" text>
                        <CardText color="rose">
                          <h4 className={classes.cardTitle}>คำวินิจฉัย กกต.</h4>
                        </CardText>
                        <FormHelperText
                          style={{
                            color: "red",
                            fontSize: "15px",
                            paddingTop: "10px"
                          }}
                        >
                          {helpertextSuspend}
                        </FormHelperText>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={data.suspend.suspendFlag}
                                  name="suspendFlag"
                                  onClick={e => {
                                    setSuspendFlag(e.target.checked);
                                    if (e.target.checked === true) {
                                      setData({
                                        ...data,
                                        suspend: {
                                          ...data.suspend,
                                          suspendFlag: e.target.checked
                                        }
                                      });
                                      setHelpertextSuspend(
                                        "กรุณาระบุข้อมูลให้ครบถ้วน"
                                      );
                                    }
                                    if (e.target.checked === false) {
                                      setHelpertextSuspend("");
                                      setData({
                                        ...data,
                                        suspend: {
                                          suspendFlag: e.target.checked,
                                          suspendOrgId: 1,
                                          suspendSdate: null,
                                          suspendYear: null,
                                          suspendEdate: null,
                                          suspendStatusId: 5,
                                          suspendFileUUID: null
                                        }
                                      });
                                      console.log("e.target.checked>> ", e.target.checked);
                                      console.log("data>> ", data);
                                    }
                                    console.log("suspendFlag>> ", suspendFlag);
                                  }}
                                />
                              }
                              label="คำวินิจฉัย กกต."
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <ListboxSuspendOrg
                              disabled={!data.suspend.suspendFlag}
                              orgTypeId={1}
                              validators={[MustNotNull]}
                              title={"หน่วยงานที่มีคำสั่ง"}
                              //defaultValue={{id: initialState.suspendPerson.suspendOrgId}}
                              defaultValue={{
                                id: data.suspend.suspendOrgId
                              }}
                              onChange={(evt, value) => {
                                if (value) {
                                  setData({
                                    ...data,
                                    suspend: {
                                      ...data.suspend,
                                      suspendOrgId: value.id
                                    }
                                  });
                                }
                              }}
                            ></ListboxSuspendOrg>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Datepicker
                              disabled={!data.suspend.suspendFlag}
                              id="date-picker-president_1"
                              label="วันที่ กกต. มีมติ"
                              value={data.suspend.suspendSdate}
                              onChange={date => {
                                if (date) {
                                  const dateSelect = new Date(
                                    date.format().split("T")[0]
                                  );
                                  dateSelect.setFullYear(
                                    dateSelect.getFullYear() + 1
                                  );
                                  const _edate = dateSelect
                                    .toISOString()
                                    .split("T")[0];

                                  setData({
                                    ...data,
                                    suspend: {
                                      ...data.suspend,
                                      suspendSdate: date.format().split("T")[0],
                                      suspendEdate: _edate,
                                      suspendYear: 1
                                    }
                                  });
                                } else {
                                  setData({
                                    ...data,
                                    suspend: {
                                      ...data.suspend,
                                      suspendSdate: null,
                                      suspendEdate: null,
                                      suspendYear: null
                                    }
                                  });
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="ระยะเวลาที่ถูกระงับ"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                value: data.suspend.suspendYear
                                  ? data.suspend.suspendYear + " ปี"
                                  : "",
                                disabled: true
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Datepicker
                              disabled={true}
                              id="date-picker-president_4"
                              label="วันที่พ้นการถูกระงับ"
                              value={data.suspend.suspendEdate}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <ListboxSuspendStatus
                              disabled={!data.suspend.suspendFlag}
                              flagStatus={1}
                              defaultValue={{
                                id: data.suspend.suspendStatusId
                              }}
                              title={"สถานะการถูกระงับสิทธิสมัครฯ"}
                              onChange={(evt, newValue) => {
                                if (newValue) {
                                  setData({
                                    ...data,
                                    suspend: {
                                      ...data.suspend,
                                      suspendStatusId: newValue.id
                                    }
                                  });
                                } else {
                                  setData({
                                    ...data,
                                    suspend: {
                                      ...data.suspend,
                                      suspendStatusId: null
                                    }
                                  });
                                }
                              }}
                            ></ListboxSuspendStatus>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <FileUpload
                              disabled={!data.suspend.suspendFlag}
                              id="file-upload_suspend"
                              label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                              fileName={data.suspend.suspendFileName}
                              remove
                              onFileRemove={() => {
                                setSuspendUrlFile("");
                                setData({
                                  ...data,
                                  suspend: {
                                    ...data.suspend,
                                    suspendFileUUID: "",
                                    suspendFileName: ""
                                  }
                                });
                              }}
                              url={getFileDownloadUrl("suspend", data.suspend.suspendFileUUID)}
                              download
                              onFileHandler={(e, f, progressCb) => {
                                uploadFile("suspend", f, progressCb)
                                  .then(r => {
                                    const dataSuspend = r.data.data;

                                    if (dataSuspend.fileId) {
                                      setSuspendUrlFile(
                                        getFileDownloadUrl(
                                          "suspend",
                                          dataSuspend.fileId
                                        )
                                      );
                                      setData({
                                        ...data,
                                        suspend: {
                                          ...data.suspend,
                                          suspendFileUUID: dataSuspend.fileId,
                                          suspendFileName: dataSuspend.fileName
                                        }
                                      });
                                    }
                                    sweetAlerts.success("แนบไฟล์เรียบร้อย");
                                  })
                                  .catch(err => {
                                    console.log(err);
                                  });
                              }}
                              fileType="application/pdf"
                              btnName={<>Browse</>}
                              btnProps={{
                                color: "primary"
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>

                  {/* คำสั่งศาล */}
                  <GridItem xs={12} sm={12} md={6}>
                    <Card>
                      <CardHeader color="rose" text>
                        <CardText color="rose">
                          <h4 className={classes.cardTitle}>คำสั่งศาล</h4>
                        </CardText>
                        <FormHelperText
                          style={{
                            color: "red",
                            fontSize: "15px",
                            paddingTop: "10px"
                          }}
                        >
                          {helpertextRevoke}
                        </FormHelperText>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="revokeFlag"
                                  checked={data.revoke.revokeFlag}
                                  onClick={e => {
                                    setRevokeFlag(e.target.checked);
                                    if (e.target.checked === true) {
                                      setData({
                                        ...data,
                                        revoke: {
                                          ...data.revoke,
                                          revokeFlag: e.target.checked
                                        }
                                      });
                                      setHelpertextRevoke(
                                        "กรุณาระบุข้อมูลให้ครบถ้วน"
                                      );
                                    }

                                    if (e.target.checked === false) {
                                      setHelpertextRevoke("");
                                      setData({
                                        ...data,
                                        revoke: {
                                          revokeFlag: e.target.checked,
                                          revokeOrgId: null,

                                          revokeSdate: null,
                                          revokeYear: 10,
                                          revokeEdate: null,
                                          revokeStatusId: 6,
                                          revokeFileUUID: null
                                        }
                                      });
                                      console.log(data);
                                    }
                                  }}
                                />
                              }
                              label="คำสั่งศาล"
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <ListboxSuspendOrg
                              disabled={!data.revoke.revokeFlag}
                              orgTypeId={2}
                              validators={[MustNotNull]}
                              title={"หน่วยงานที่มีคำสั่ง"}
                              defaultValue={{ id: data.revoke.revokeOrgId }}
                              onChange={(evt, value) => {
                                if (value) {
                                  setData({
                                    ...data,
                                    revoke: {
                                      ...data.revoke,
                                      revokeOrgId: value.id
                                    }
                                  });
                                } else {
                                  setData({
                                    ...data,
                                    revoke: {
                                      ...data.suspend,
                                      revokeOrgId: null
                                    }
                                  });
                                }
                              }}
                            ></ListboxSuspendOrg>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Datepicker
                              disabled={!data.revoke.revokeFlag}
                              id="date-picker-president_2"
                              label="วันที่เริ่มการถูกเพิกถอน"
                              value={data.revoke.revokeSdate}
                              onChange={date => {

                                let arr = date.format().split("T");

                                /*    dateSelect.setFullYear(
                                     dateSelect.getFullYear() + data.revoke.revokeYear
                                   );
                                   const _edate = dateSelect.toISOString().split("T")[0]; 
                                   */

                                setData({
                                  ...data,
                                  revoke: {
                                    ...data.revoke,
                                    revokeSdate: arr[0]
                                    //revokeSdate: date.format().split("T")[0],
                                    //revokeEdate: _edate
                                  }
                                });
                                if (date && data.revoke.revokeYear) {
                                  //console.log("data.revoke.revokeYear" >> data.revoke.revokeYear);
                                  /*  dateSelect.setFullYear(
                                     dateSelect.getFullYear() + data.revoke.revokeYear
                                   ); */

                                  const end_date = suspendEndDate(
                                    date,
                                    data.revoke.revokeYear
                                  );

                                  setData({
                                    ...data,
                                    revoke: {
                                      ...data.revoke,
                                      revokeSdate: arr[0],
                                      revokeEdate: moment(new Date(end_date)).format("YYYY-MM-DD"),
                                      revokeYear: data.revoke.revokeYear
                                    }
                                  });

                                }

                              }}
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <ListboxSuspendYear
                              disabled={
                                !data.revoke.revokeFlag
                              }
                              defaultValue={{ id: data.revoke.revokeYear || 10 }}
                              onChange={(evt, newValue) => {
                                if (newValue) {

                                  setData({
                                    ...data,
                                    revoke: {
                                      ...data.revoke,
                                      revokeYear: newValue.id
                                    }
                                  });
                                  if (
                                    data.revoke.revokeSdate &&
                                    newValue.id
                                  ) {
                                    const end_date = suspendEndDate(
                                      data.revoke.revokeSdate,
                                      newValue.id
                                    );

                                    setData({
                                      ...data,
                                      revoke: {
                                        ...data.revoke,
                                        revokeYear: newValue.id,

                                        revokeEdate: moment(
                                          new Date(end_date)
                                        ).format("YYYY-MM-DD")
                                      }
                                    });
                                  }
                                }
                              }}
                              title={"ระยะเวลาที่ถูกเพิกถอน "}

                            ></ListboxSuspendYear>

                            {data.revoke.revokeYear === 100 ? (
                              <p style={{ color: "red", fontSize: "14px" }}>
                                {" *100 ปี หมายความว่า ถูกเพิกถอนสิทธิสมัครรับเลือกตั้งตลอดไป "}
                              </p>
                            ) : (
                              <p style={{ color: "red", fontSize: "14px" }}>
                                {" *กรณี เพิกถอนสิทธิสมัครรับเลือกตั้งตอลอดไปให้เลือกระยะเวลาที่ถูกเพิกถอนเป็น 100 ปี "}
                              </p>
                            )}

                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <Datepicker
                              disabled={true}
                              id="date-picker-president_3"
                              label="วันที่พ้นการถูกเพิกถอน"
                              value={data.revoke.revokeEdate}
                              onChange={date => {
                                setData({
                                  ...data,
                                  revoke: {
                                    ...data.revoke,
                                    revokeEdate: date.format().split("T")[0]
                                  }
                                });
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <ListboxSuspendStatus
                              // disabled={true}
                              disabled={!data.suspend.suspendFlag}
                              flagStatus={2}
                              title={"สถานะการถูกเพิกถอนสิทธิสมัครฯ"}
                              defaultValue={{ id: data.revoke.revokeStatusId }}
                              //value={suspendStatusSelected}
                              onChange={(evt, newValue) => {
                                if (newValue) {
                                  setData({
                                    ...data,
                                    revoke: {
                                      ...data.revoke,
                                      revokeStatusId: newValue.id
                                    }
                                  });
                                } else {
                                  setData({
                                    ...data,
                                    revoke: {
                                      ...data.revoke,
                                      revokeStatusId: null
                                    }
                                  });
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <FileUpload
                              disabled={!data.revoke.revokeFlag}
                              id="file-upload_revoke"
                              label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                              remove
                              download
                              fileName={data.revoke.revokeFileName}

                              onFileRemove={() => {
                                setRevokeUrlFile("");
                                setData({
                                  ...data,
                                  revoke: {
                                    ...data.revoke,
                                    revokeFileName: "",
                                    revokeFileUUID: ""
                                  }
                                });
                              }}
                              url={getFileDownloadUrl("suspend", data.revoke.revokeFileUUID)}
                              onFileHandler={(e, f, progressCb) => {
                                uploadFile("suspend", f, progressCb)
                                  .then(r => {
                                    let dataRevoke = r.data.data;

                                    if (dataRevoke.fileId) {
                                      setSuspendUrlFile(
                                        getFileDownloadUrl(
                                          "suspend",
                                          dataRevoke.fileId
                                        )
                                      );
                                      setData({
                                        ...data,
                                        revoke: {
                                          ...data.revoke,
                                          revokeFileUUID: dataRevoke.fileId,
                                          revokeFileName: dataRevoke.fileName
                                        }
                                      });
                                      console.log(data);
                                    }
                                    sweetAlerts.success("แนบไฟล์เรียบร้อย");
                                  })
                                  .catch(err => {
                                    console.log(err);
                                  });
                              }}
                              fileType="application/pdf"
                              btnName={<>Browse</>}
                              btnProps={{
                                color: "primary"
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
                <GridContainer justify="space-between" alignItems="center">
                  <GridItem xs={6} sm={6} md={6}></GridItem>
                  <GridItem>
                    <Button onClick={backtoMainHandler}>ย้อนกลับ</Button>
                    <Button color="success" onClick={handelSubmit}>
                      บันทึกข้อมูล
                    </Button>
                  </GridItem>
                </GridContainer>
                <br /> <br />
                {/* พ.ร.บ. ของระงับ */}
                {data.suspend.suspendFlag ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <AddLawSuspend
                        suspendLaw={suspendLaw}
                        suspendPersonId={data.suspendPersonId}
                        status={data.suspendStatusId}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
                <br></br>
                {/* พ.ร.บ. ของเพิกถอน */}
                {data.revoke.revokeFlag ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <AddLawRevoke
                        revokeLaw={revokeLaw}
                        suspendPersonId={data.suspendPersonId}
                        status={data.revokeStatusId}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}{" "}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div >
    );
  }
  return content;
};
export default EditPerson;
