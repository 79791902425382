import React, { useContext, useEffect, useState } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DatepickerMonth from "components/Common/DatepickerMonth";
import DatepickerVotedate from "components/Common/Datepicker";
import DatepickerYear from "components/Common/DatepickerYear";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import ListboxWtdVoteType from "components/Common/ListboxWtdVoteType";
import Slide from "@material-ui/core/Slide";
import { WtdContext } from "contexts/Wtd";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalReport = props => {
  const {
    areaEctSelected,

    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,

    amphurSelected,
    tambolSelected,
    onTambolSelected,
    optVoteTypeSelected,

    voteTypeSelected,
    onVoteTypeSelected
  } = useContext(WtdContext);

  const { sweetAlerts } = useSweetAlert();
  const { showModalReport, closeModalReport } = props;
  const classes = useStyles();

  const [fromDate, setFromDate] = useState(null);
  const [yearMonth] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [year1] = useState(new Date());
  const [toDate, setToDate] = useState(null);
  const [isFromValid, setIsFromValid] = useState(true);
  const [, setIsValid] = useState(true);
  const [isToValid, setIsToValid] = useState(true);
  const [, setIsMonthValid] = useState(true);
  const [, setIsYearValid] = useState(true);
  const [area, setArea] = useState("");

  const [chairman, setChairman] = useState("");

  useEffect(() => {
    if (yearMonth) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    if (fromDate) {
      setIsFromValid(false);
    } else {
      setIsFromValid(true);
    }
    if (toDate) {
      setIsToValid(false);
    } else {
      setIsToValid(true);
    }
    if (month) {
      setIsMonthValid(false);
    } else {
      setIsMonthValid(true);
    }
    if (year) {
      setIsYearValid(false);
    } else {
      setIsYearValid(true);
    }
  }, [yearMonth, toDate, fromDate, year, month]);

  const getQuery = () => {
    // console.log(year.format().split("T"));

    let endPoint = "";

    if (["5_6"].includes(props.indexReport)) {
      if (fromDate === null && toDate === null) {
        sweetAlerts.warning("กรุณาระบุช่วงวันเลือกตั้ง");
        return;
      } else {
        endPoint += `&area_ect_id=${areaEctSelected !== null ? areaEctSelected.id : 0
          }
        &province_id=${provinceSelected !== null ? provinceSelected.id : 0
          }&vote_type=${optVoteTypeSelected !== null ? optVoteTypeSelected.id : 0
          }&fromDate=${fromDate}&ToDate=${toDate}`;
      }
    }

    if (["2_1"].includes(props.indexReport)) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&wtd_vote_type=${voteTypeSelected !== null ? voteTypeSelected.id : 0
        }&area_no=${area}&notice_date=${fromDate}&chairman=${chairman}`;
    }
    if (["1_1"].includes(props.indexReport)) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&wtd_vote_type=${voteTypeSelected !== null ? voteTypeSelected.id : 0
        }&amphur_id=${amphurSelected !== null ? amphurSelected.id : 0}`;
    }
    if (["3_1"].includes(props.indexReport)) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&amphur_id=${amphurSelected !== null ? amphurSelected.id : 0
        }&tambol_id=${tambolSelected !== null ? tambolSelected.id : 0}&month=${month !== null ? month : 0
        /*  }&year=${year !== null ? year : new Date().getFullYear()}`; */
        }&year=${year !== null ? year : 0}`;
    }
    if (["4_1", "10_1"].includes(props.indexReport)) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&fromDate=${fromDate}&ToDate=${toDate}`;
    }
    if (
      ["5_1", "6_1", "7_1", "11_1", "12_1", "13_1", "16_1"].includes(
        props.indexReport
      )
    ) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }`;
    }
    if (["9_1"].includes(props.indexReport)) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&month=${month !== null ? month : 0}&year=${year !== null ? year : 0
        }`;
    }
    if (["17_1"].includes(props.indexReport)) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&amphur_id=${amphurSelected !== null ? amphurSelected.id : 0
        }&tambol_id=${tambolSelected !== null ? tambolSelected.id : 0}`;
    }
    if (["18_1"].includes(props.indexReport)) {
      endPoint += `&province_id=${provinceSelected !== null ? provinceSelected.id : 0
        }&wtd_vote_date=${fromDate}`;
    }

    if (["19_1"].includes(props.indexReport)) {
      endPoint += `&wtd_vote_date=${fromDate}`;
    }
    return endPoint;
  };
  const handleSubmitReport1 = e => {
    e.preventDefault();

    if (["4_1", "10_1"].includes(props.indexReport)) {
      if (fromDate === null || toDate === null) {
        sweetAlerts.warning("กรุณาระบุช่วงวันเริ่มต้น/สิ้นสุด");
      } else {
        const query = getQuery();
        //  console.log(query);
        window.open(`${props.linkReport}${query}`, "_blank");
      }
    }

    if (["2_1"].includes(props.indexReport)) {
      if (voteTypeSelected === null) {
        sweetAlerts.warning("กรุณาระบุประเภทการเลือกตั้ง");
        return false;
      }
      if (provinceSelected === null) {
        sweetAlerts.warning("กรุณาระบุจังหวัด");
        return false;
      }
      if (!area) {
        sweetAlerts.warning("กรุณาระบุเขต");
        return false;
      }
      if (!fromDate) {
        sweetAlerts.warning("กรุณาระบุวันที่");
        return false;
      }
      if (!chairman) {
        sweetAlerts.warning("กรรมการเลือกตั้งประจำเขต");
        return false;
      } else {
        const query = getQuery();

        window.open(`${props.linkReport}${query}`, "_blank");
      }
    }
    if (
      [
        "1_1",
        "5_1",
        "6_1",
        "7_1",
        "3_1",
        "11_1",
        "12_1",
        "13_1",
        "17_1",
        "16_1"
      ].includes(props.indexReport)
    ) {
      const query = getQuery();

      window.open(`${props.linkReport}${query}`, "_blank");
    }
    // if (["3_1"].includes(props.indexReport)) {
    //   if (provinceSelected === null) {
    //     sweetAlerts.warning("กรุณาระบุจังหวัด");
    //   } else {
    //     const query = getQuery();
    //     //&province_id=0&amphur_id=0&tambol_id=0&month=10&year=2012

    //     window.open(`${props.linkReport}${query}`, "_blank");
    //   }
    // }
    if (["9_1"].includes(props.indexReport)) {
      const query = getQuery();
      //&province_id=0&amphur_id=0&tambol_id=0&month=10&year=2012

      window.open(`${props.linkReport}${query}`, "_blank");
    }
    if (["18_1", "19_1"].includes(props.indexReport)) {
      if (fromDate === null) {
        sweetAlerts.warning("กรุณาระบุวันที่ถูกเพิกถอน");
      } else {
        const query = getQuery();
        //  console.log(query);
        window.open(`${props.linkReport}${query}`, "_blank");
      }
    }
  };

  return (
    <div className={showModalReport ? "modal" : "hide"}>
      <Dialog
        open={showModalReport}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModalReport}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>{props.titleReport}</h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent>
          {["1_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxWtdVoteType
                  titlevotestatus={"เลือกทุกประเภทการเลือกตั้งที่ถูกเพิกถอนสิทธิ"}
                  value={voteTypeSelected}
                  onChange={onVoteTypeSelected}
                ></ListboxWtdVoteType>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxProvince
                  value={provinceSelected}
                  onChange={onProvinceSelected}
                ></ListboxProvince>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <ListboxDistinct
                  title={"เลือกทุกอำเภอ"}
                  provinceSelected={provinceSelected}
                  onChange={onAmphurSelected}
                ></ListboxDistinct>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          {["2_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="เขต"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: area || "",
                    error: true,
                    onChange: e => {
                      if (e.target.value) setArea(e.target.value);
                      else {
                        setArea(null);
                      }
                    }
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <DatepickerVotedate
                  id="begin"
                  label="ประกาศ ณ วันที่"
                  value={fromDate}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setFromDate(arr[0]);
                      setIsFromValid(false);
                    } else {
                      setFromDate(null);
                      setIsFromValid(true);
                    }
                  }}
                ></DatepickerVotedate>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="กรรมการเลือกตั้งประจำเขต"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: chairman,
                    error: true,
                    onChange: e => {
                      if (e.target.value) setChairman(e.target.value);
                      else {
                        setChairman(null);
                      }
                    }
                  }}
                />
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          {["3_1", "17_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxProvince
                  // required={true}
                  value={provinceSelected}
                  onChange={onProvinceSelected}
                ></ListboxProvince>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxDistinct
                  title={"เลือกทุกอำเภอ"}
                  provinceSelected={provinceSelected}
                  onChange={onAmphurSelected}
                ></ListboxDistinct>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxSubdistinct
                  title={"เลือกทุกตำบล"}
                  amphurSelected={amphurSelected}
                  value={tambolSelected}
                  onChange={onTambolSelected}
                ></ListboxSubdistinct>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          {["3_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <DatepickerMonth
                  label="เลือกทุกเดือน"
                  value={month}
                  // valid={isMonthValid}
                  onChange={date => {
                    if (date) {
                      //    console.log(date);
                      let arr = date.format().split("T");
                      let _date = arr[0].split("-");

                      setMonth(_date[1]);
                      console.log(_date[1]);
                      // setIsValid(false);
                    } else {
                      setMonth(null);
                    }
                  }}
                ></DatepickerMonth>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <DatepickerYear
                  label="เลือกทุกปี"
                  //value={year === null ? year1 : year}
                  value={year}
                  // valid={isYearValid}
                  onChange={date => {
                    if (date) {
                      //    console.log(date);
                      let arr = date.format().split("T");
                      let _date = arr[0].split("-");

                      setYear(_date[0]);
                      console.log(_date[0]);
                      // setIsValid(false);
                    } else {
                      setYear(null);
                    }
                  }}
                ></DatepickerYear>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          {["4_1", "10_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxProvince
                  value={provinceSelected}
                  onChange={onProvinceSelected}
                ></ListboxProvince>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <DatepickerVotedate
                  id="begin"
                  label="ตั้งแต่วันที่"
                  value={fromDate}
                  valid={isFromValid}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setFromDate(arr[0]);
                      setIsFromValid(false);
                    } else {
                      setFromDate(null);
                      setIsFromValid(true);
                    }
                  }}
                ></DatepickerVotedate>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <DatepickerVotedate
                  id="endDate"
                  label="ถึงวันที่"
                  value={toDate}
                  valid={isToValid}
                  disabled={fromDate === null ? true : false}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setToDate(arr[0]);
                      setIsToValid(false);
                    } else {
                      setToDate(null);
                      setIsToValid(true);
                    }
                  }}
                ></DatepickerVotedate>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          {["5_1", "6_1", "7_1", "11_1", "12_1", "13_1", "16_1"].includes(
            props.indexReport
          ) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ListboxProvince
                  value={provinceSelected}
                  onChange={onProvinceSelected}
                ></ListboxProvince>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          {["9_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxProvince
                  value={provinceSelected}
                  onChange={onProvinceSelected}
                ></ListboxProvince>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <DatepickerMonth
                  label="เลือกทุกเดือน"
                  value={month}
                  // valid={isMonthValid}
                  onChange={date => {
                    if (date) {
                      //    console.log(date);
                      let arr = date.format().split("T");
                      let _date = arr[0].split("-");

                      setMonth(_date[1]);
                      console.log(_date[1]);
                      // setIsValid(false);
                    } else {
                      setMonth(null);
                    }
                  }}
                ></DatepickerMonth>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <DatepickerYear
                  label="เลือกทุกปี"
                  //value={year === null ? year1 : year}
                  value={year}
                  onChange={date => {
                    if (date) {
                      //    console.log(date);
                      let arr = date.format().split("T");
                      let _date = arr[0].split("-");

                      setYear(_date[0]);
                      console.log(_date[0]);
                      // setIsValid(false);
                    } else {
                      setYear(null);
                    }
                  }}
                ></DatepickerYear>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          {["18_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <ListboxProvince
                  value={provinceSelected}
                  onChange={onProvinceSelected}
                ></ListboxProvince>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <DatepickerVotedate
                  id="begin"
                  label="วันที่เลือกตั้ง"
                  value={fromDate}
                  valid={isFromValid}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setFromDate(arr[0]);
                      setIsFromValid(false);
                    } else {
                      setFromDate(null);
                      setIsFromValid(true);
                    }
                  }}
                ></DatepickerVotedate>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}

          {["19_1"].includes(props.indexReport) ? (
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <DatepickerVotedate
                  id="begin"
                  label="วันที่เลือกตั้ง"
                  value={fromDate}
                  valid={isFromValid}
                  onChange={date => {
                    if (date) {
                      let arr = date.format().split("T");
                      setFromDate(arr[0]);
                      setIsFromValid(false);
                    } else {
                      setFromDate(null);
                      setIsFromValid(true);
                    }
                  }}
                ></DatepickerVotedate>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}

          {/* {["2_1"].includes(props.indexReport) ? (
            <>
            

              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <ListboxPositionType
                    value={positionTypeSelected}
                    onChange={onPostionTypeSelected}
                  ></ListboxPositionType>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    style={{ marginTop: "15px" }}
                  >
                    <InputLabel>สถานะการดำรงตำแหน่ง</InputLabel>
                    <Select
                      value={status}
                      defaultValue={0}
                      onChange={handleStatus}
                    >
                      <MenuItem value={0} selected="selected">
                        ทั้งหมด
                      </MenuItem>
                      <MenuItem value={1}>อยู่ระหว่างดำรงตำแหน่ง </MenuItem>
                      <MenuItem value={2}>พ้นจากตำแหน่ง</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </>
          ) : (
            ""
          )} */}
          <br></br>
          <GridContainer
            direction="column"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem xs={12} sm={12} md={6}>
              <Button onClick={closeModalReport}>ปิดหน้าต่าง</Button>
              <Button color="success" onClick={handleSubmitReport1}>
                ออกรายงาน
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalReport;
