// core UserList
import PageInRolesList from "./PageInRolesList";
import React from "react";
import { VoteDateContextProvider } from "contexts/VoteDate";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

//const styles = {};
//const useStyles = makeStyles(styles);

const UserInRolesIndex = props => {
  return (
    <div>
      <VoteDateContextProvider>
        <PageInRolesList />
      </VoteDateContextProvider>
    </div>
  );
};
export default UserInRolesIndex;
