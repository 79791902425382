import {
  ERROR_NOT_ALLOW_VOTE_MEM,
  ERROR_NOT_ALLOW_VOTE_PRES
} from "../../../../Constants/errors";
import React, { useContext, useEffect, useState } from "react";
import {
  addMultiOptPerson,
  getCandidatureAll,
  getPersonbyCitizenIdList
} from "contexts/API/OPT/OptPerson";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import ConvertDate from "utils/ConvertDate";
import FileSaver from "file-saver";
import { FileUpload } from "components/Common/FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ImportOptPerson from "assets/resources/opt/import_opt_person.xlsx";
//import KeyValueSplit from "utils/KeyValueSplit";
import ListboxAumphurArea from "components/Common/ListboxAumphurArea";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxVotedate from "components/Common/ListboxVotedate";
import MaterialTable from "components/Table/MaterialTable";
import MustIncludes from "utils/Validators/MustIncludes";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import TransposeObj from "utils/TransposeListToObj";
import { VoteDateContext } from "contexts/VoteDate";
import { VoteDateEnum } from "../../../../Constants/strings";
import XLSX from "xlsx";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
//import { getTambolByGeocode } from "contexts/API/MS/MS";
import { getVoteDateTypePrivateIdFromString } from "../../../../utils/Opt";
import { makeStyles } from "@material-ui/core/styles";
import { onlyNumber } from "utils/Validators/Numbers";
import { useSweetAlert } from "components/Alert/SweetAlert";
import validateId from "utils/Validators/ThaiCitizenID";

const useStyles = makeStyles({
  buttoncustom: {
    fontSize: 14,
    fontWeight: 500
    //color: props => props.color
  }
});

const OptImportPerson = ({ pageProps, setPageProps }) => {
  const LABLE_FROM_DB = "ข้อมูลเดิม";
  const LABLE_FROM_IMPORT = "ข้อมูลใหม่";
  const { sweetAlerts } = useSweetAlert();
  const [dataToSave, setDataToSave] = React.useState([]);

  const saveData = (e, v) => {
    return (dataToSave, row, key) => {
      let idx = row.tableData.id;
      let d = [...dataToSave];
      d[idx][key] = v && v.value ? v.value : null;
      setDataToSave(d);
    };
  };

  const [columns, setColumns] = React.useState([]);
  const [data, setData] = useState([]);
  const [chkType, setChkType] = useState(false);
  const [enableImport, setEnableImport] = useState(false);
  const classes = useStyles();

  const {
    optVoteTypeSelected,
    onOptVoteTypeSelected,
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,

    tambolSelected,
    optTypeSelected,
    onOptTypeSingleSelected,
    onOptSelected,
    optSelected,

    onVotedateSelected,
    onAumphurAreaSelected,
    positionTypeSelected,
    aumphurAreaSelected,
    areaSelected,

    votedateSelected,

    getOptTypeSelectedLists
  } = useContext(VoteDateContext);

  const getIdCards = (headAt, rows) => {
    let idCards = [];
    for (let i = headAt + 1; i < rows.length; i++) {
      let row = Object.assign(initRow, rows[i]);
      if (!validateId(row.D.toString())[0]) {
        idCards.push(row.D.toString());
      }
    }
    return idCards;
  };

  /*
  const getRegisterNoList = (headAt, rows) => {
    let registerNoList = [];
    for (let i = headAt + 1; i < rows.length; i++) {
      let row = Object.assign(initRow, rows[i]);
      if (row.D !== "" && row.B !== "") {
        let registerNo = {}
        if (parseInt(row.B) === 1) {
          registerNo = {
            "ผ.ถ.": parseInt(row.D)
          }
        } else if (parseInt(row.B) === 2) {
          registerNo = {
            "ส.ถ.": parseInt(row.D)
          }
        }

        registerNoList.push(registerNo);
      }
    }
    return registerNoList;
  };
  */

  const initRow = {
    A: "",
    B: "",
    C: "",
    D: "",
    E: "",
    F: "",
    G: "",
    H: "",
    I: "",
    J: "",
    K: "",
    L: "",
    M: "",
    N: "",
    O: "",
    P: "",
    Q: "",
    R: "",
    S: "",
    T: "",
    U: "",
    V: "",
    W: "",
    X: "",
    Y: "",
    Z: "",
    AA: "",
    AB: "",
    AC: "",
    AD: "",
    AE: ""
  };

  const onExcelFileHandler = e => {
    try {
      if (optVoteTypeSelected === null) {
        sweetAlerts.warning("กรุณาเลือกประเภทการเลืือกตั้ง");
        return;
      }

      if (provinceSelected === null) {
        sweetAlerts.warning("กรุณาเลือกจังหวัด");
        return;
      }

      if (optSelected === null) {
        sweetAlerts.warning("กรุณาเลือก อปท.");
        return;
      }

      if (votedateSelected === null) {
        sweetAlerts.warning("กรุณาเลือกวันที่เลือกตั้ง");
        return;
      }

      const getOptSubTypeId = () => {
        for (const [, v] of Object.entries(optTypeSelected)) {
          if (v.checked) {
            return parseInt(v.value);
          }
        }
        return -1;
      };

      /*if (getOptSubTypeId() === 1 && !aumphurAreaSelected) {
        sweetAlerts.warning("กรุณาเลือกเขตอำเภอ");
        return;
      }*/

      const headAt = 7; //เริ่มอ่าน header column
      let dataArray = new Uint8Array(e);
      let workbook = XLSX.read(dataArray, { type: "array" });
      let ws = workbook.Sheets[workbook.SheetNames[0]];
      let rows = XLSX.utils.sheet_to_json(ws, {
        header: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "AA",
          "AB",
          "AC",
          "AD",
          "AE"
        ]
      });

      if (rows.length <= 1) {
        throw new Error("ไม่มีข้อมูล");
      }

      //let aumphurArea = aumphurAreaSelected ? aumphurAreaSelected.id : -1;
      /* let registerNoList = getRegisterNoList(headAt, rows);
       console.log("registerNoList >>", registerNoList);
 
       let findDuplicates = registerNoList.filter((item, index) => registerNoList.indexOf(item) != index)
       console.log("findDuplicates >>", findDuplicates);
 
       if (findDuplicates.length > 0) {
         //throw new Error("หมายเลขผู้สมัครซ้ำ [" + findDuplicates + "]");
         sweetAlerts.warning("หมายเลขผู้สมัครซ้ำ [" + findDuplicates + "]");
         return;
       }
       */

      const candidature = getCandidatureAll(
        optSelected,
        positionTypeSelected,
        votedateSelected,
        areaSelected
      );

      const getPeople = getPersonbyCitizenIdList(getIdCards(headAt, rows));

      const result = Promise.all([getPeople, candidature]);
      result
        .then(async r => {
          let personFromDB = {};
          if (r[0].data.data && r[0].data.data.length > 0) {
            personFromDB = TransposeObj(r[0].data.data, "idCard");
          }

          let candidateObjs = {};
          if (r[1].data.data && r[1].data.data.length > 0) {
            candidateObjs = TransposeObj(r[1].data.data, "idCard");
          }



          if (
            rows[headAt].A !== "ตำแหน่ง(ส.ถ./ผ.ถ.)" ||
            rows[headAt].B !== "เขตเลือกตั้งที่" ||
            rows[headAt].C !== "หมายเลขผู้สมัคร" ||
            rows[headAt].D !== "เลขประจำตัวประชาชน" ||
            rows[headAt].E !== "เพศ(ช/ญ)" ||
            rows[headAt].F !== "คำนำหน้าชื่อ" ||
            rows[headAt].G !== "ชื่อ" ||
            rows[headAt].H !== "นามสกุล" ||
            rows[headAt].I !== "วันเกิด" ||
            rows[headAt].J !== "เดือนเกิด" ||
            rows[headAt].K !== "ปีเกิด" ||
            rows[headAt].L !== "ที่อยู่ตามทะเบียนบ้าน" ||
            rows[headAt].M !== "จังหวัด" ||
            rows[headAt].N !== "อำเภอ" ||
            rows[headAt].O !== "ตำบล" ||
            rows[headAt].P !== "รหัสไปรษณีย์" ||
            rows[headAt].Q !== "วุฒิการศึกษา" ||
            rows[headAt].R !== "เบอร์โทรศัพท์" ||
            rows[headAt].S !== "อาชีพ" ||
            rows[headAt].T !== "วันที่รับสมัคร" ||
            rows[headAt].U !== "เดือนที่รับสมัคร" ||
            rows[headAt].V !== "ปีที่รับสมัคร" ||
            rows[headAt].W !== "รับสมัคร/ไม่รับสมัคร" ||
            rows[headAt].X !== "คะแนน" ||
            rows[headAt].Y !== "รหัสตำแหน่ง" ||
            rows[headAt].Z !== "รหัสคำนำหน้าชื่อ" ||
            rows[headAt].AA !== "รหัสจังหวัด" ||
            rows[headAt].AB !== "รหัสอำเภอ" ||
            rows[headAt].AC !== "รหัสตำบล" ||
            rows[headAt].AD !== "รหัสวุฒิการศึกษา" ||
            rows[headAt].AE !== "รหัสอาชีพ"
          ) {
            throw new Error("กรุณาใช้ไฟล์ที่ export ออกจากระบบเท่านั้น");
          }

          let personList = [];
          let personToSaveList = [];
          let areaNoAndApply = {};

          //.log("rows.length >>", rows.length);

          for (let i = headAt + 1; i < rows.length; i++) {

            //console.log("rows >>", rows[i]);

            let optPositionTypeId = 1;
            let optAreaNo = "";
            const row = Object.assign(initRow, rows[i]);
            let rowNum = rows[i].__rowNum__ + 1;

            //console.log("row.A >>", row.A);
            if (row.Y === "" && row.Z === "" && row.AA === "" && row.AB === "" && row.AC === "" && row.AD === "" && row.AE === "") {
              break;
            }


            //รหัสตำแหน่ง
            //let KVOptPositionType = KeyValueSplit(row.A.toString());
            let OptPositionType = row.A.toString().trim();
            //let KVOptPositionType = parseInt(row.B.toString());

            //console.log("OptPositionType 1 >>", OptPositionType);
            // console.log("KVOptPositionType 2 >>", KVOptPositionType);

            //if ("ส.ถ." === KVOptPositionType.value) {
            if ("ส.ถ." === OptPositionType) {
              optPositionTypeId = 2;
              setChkType(true);
              if (onlyNumber(row.B)[0]) {
                throw new Error(
                  `เซลล์ที่ B${rowNum} เขตเลือกตั้งที่ ต้องเป็นตัวเลขเท่านั้น`
                );
              }
              optAreaNo = row.B;
            }


            if (
              optPositionTypeId === 1 &&
              voteDateTypePrivateId.value === VoteDateEnum.ONLY_MEM.value
            ) {
              throw new Error(ERROR_NOT_ALLOW_VOTE_PRES);
            } else if (
              optPositionTypeId === 2 &&
              voteDateTypePrivateId.value === VoteDateEnum.ONLY_PRES.value
            ) {
              throw new Error(ERROR_NOT_ALLOW_VOTE_MEM);
            }
            //หมายเลขผู้สมัคร
            if (onlyNumber(row.C)[0]) {
              throw new Error(
                `เซลล์ที่ C${rowNum} หมายเลขผู้สมัคร ต้องเป็นตัวเลขเท่านั้น`
              );
            }
            let registerNo = row.C;

            let key = `${optPositionTypeId}::${optAreaNo}::${registerNo}`;

            if (areaNoAndApply[key]) {
              throw new Error(`แถวที่ ${rowNum} หมายเลขผู้สมัครซ้ำ`);
            } else {
              areaNoAndApply[key] = row;
            }

            //เลขประจำตัวประชาชน
            if (validateId(row.D.toString())[0]) {
              throw new Error(
                `เซลล์ที่ D${rowNum} เลขประจำตัวประชาชน ไม่ถูกต้อง`);
            }
            let idCard = row.D.toString();

            //เพศ(ช/ญ)
            if (MustIncludes(["ช", "ญ"])(row.E.toString())[0]) {
              throw new Error(
                `เซลล์ที่ E${rowNum} เพศต้องเป็น 'ช' หรือ 'ญ' เท่านั้น
                `
              );
            }
            let gender = row.E.toString();
            let genderId = gender === "ญ" ? 0 : 1;

            //คำนำหน้าชื่อ
            //let KVTitleName = KeyValueSplit(row.F.toString());
            let KVTitleName = parseInt(row.Z); // Z=> id of Title



            if (!KVTitleName) {
              throw new Error(
                `เซลล์ที่ F${rowNum} คำนำหน้าชื่อ รูปแบบไม่ถูกต้อง
                `
              );
            }
            let titleName = row.F.toString();

            //ชื่อ
            if (!row.G.toString()) {
              throw new Error(
                `เซลล์ที่ G${rowNum} ชื่อไม่ถูกต้อง
                `
              );
            }
            let name = row.G.toString();

            //นามสกุล
            if (!row.H.toString()) {
              throw new Error(
                `เซลล์ที่ H${rowNum} นามสกุลไม่ถูกต้อง
                `
              );
            }
            let surname = row.H.toString();

            //วันเกิด
            if (row.I < 1 || row.I > 31) {
              throw new Error(
                `เซลล์ที่ I${rowNum} วันเกิดไม่ถูกต้อง
                `
              );
            }

            //เดือนเกิด
            if (row.J < 1 || row.J > 12) {
              throw new Error(
                `เซลล์ที่ J${rowNum} เดือนเกิดไม่ถูกต้อง
                `
              );
            }

            //ปีเกิด
            if (row.K < 2464 || row.K > new Date().getFullYear() + 543) {
              throw new Error(
                `เซลล์ที่ K${rowNum} ปีเกิดไม่ถูกต้อง
                `
              );
            }

            let birthYear = String(row.K - 543);
            let birthMonth = String(row.J).padStart(2, "0");
            let birthDay = String(row.I).padStart(2, "0");
            const birthDate = `${birthYear}-${birthMonth}-${birthDay}`;

            //ที่อยู่ตามทะเบียนบ้าน
            if (!row.L.toString()) {
              throw new Error(
                `เซลล์ที่ L${rowNum} ที่อยู่ตามทะเบียนบ้านไม่ถูกต้อง
                `
              );
            }
            let addrs = row.L.toString();

            //จังหวัด
            //let KVProvince = KeyValueSplit(row.M.toString());
            let KVProvince = parseInt(row.AA);

            if (!KVProvince) {
              throw new Error(
                `เซลล์ที่ M${rowNum} จังหวัดไม่ถูกต้อง
                `
              );
            }
            let province = row.M.toString();

            //อำเภอ
            //let KVAmphur = KeyValueSplit(row.N.toString());
            let KVAmphur = parseInt(row.AB.toString());
            if (!KVAmphur) {
              throw new Error(
                `เซลล์ที่ N${rowNum} อำเภอไม่ถูกต้อง
                `
              );
            }
            let amphur = row.N.toString();

            //ตำบล
            //let KVTambol = KeyValueSplit(row.O.toString());
            let KVTambol = parseInt(row.AC.toString());

            if (!KVTambol) {
              throw new Error(
                `เซลล์ที่ O${rowNum} ตำบลไม่ถูกต้อง
                `
              );
            }
            let tambol = row.O.toString();
            /*
            let resp = await getTambolByGeocode(KVTambol.key);
            let tambol = resp.data.data;

            if (tambol && tambol.tambolId) {
              KVTambol.key = tambol.tambolId;
            }
            */
            //รหัสไปรษณีย์
            if (onlyNumber(row.P)[0]) {
              throw new Error(
                `เซลล์ที่ P${rowNum} รหัสไปรษณีย์ไม่ถูกต้อง
                `
              );
            }
            let postCode = row.P;

            //วุฒิการศึกษา
            if (rows[i].AD === undefined || rows[i].AD === null) {
              throw new Error(
                `เซลล์ที่ Q${rowNum} วุฒิการศึกษาไม่ถูกต้อง
                `
              );
            }
            let KVEducationLevel = parseInt(rows[i].AD.toString());
            let educationLevel = rows[i].Q.toString();


            //let KVEducationLevel = KeyValueSplit(rows[i].Q.toString());
            // console.log("KVEducationLevel", KVEducationLevel);
            // if (!KVEducationLevel) {
            //   throw new Error(
            //     `เซลล์ที่ Q${rowNum} วุฒิการศึกษาไม่ถูกต้อง
            //     `
            //   );
            // }

            //เบอร์โทรศัพท์
            if (!row.R.toString()) {
              throw new Error(
                `เซลล์ที่ R${rowNum} เบอร์โทรศัพท์ไม่ถูกต้อง
                `
              );
            }
            let tel = row.R.toString();

            //อาชีพ
            //let KVOcupation = KeyValueSplit(row.S.toString());
            let KVOcupation = parseInt(row.AE);
            if (!KVOcupation) {
              throw new Error(
                `เซลล์ที่ S${rowNum} อาชีพไม่ถูกต้อง
                `
              );
            }
            let ocupation = row.S.toString();

            //วันที่รับสมัคร
            if (row.T < 1 || row.T > 31) {
              throw new Error(
                `เซลล์ที่ T${rowNum} วันที่รับสมัครไม่ถูกต้อง
                `
              );
            }
            //เดือนที่รับสมัค
            if (row.U < 1 || row.U > 12) {
              throw new Error(
                `เซลล์ที่ U${rowNum} เดือนที่รับสมัครไม่ถูกต้อง
                `
              );
            }
            //ปีที่รับสมัคร
            if (row.V < 2550 || row.V > new Date().getFullYear() + 543) {
              throw new Error(
                `เซลล์ที่ V${rowNum} ปีที่รับสมัครไม่ถูกต้อง
                `
              );
            }

            let registerDay = String(row.T).padStart(2, 0);
            let registerMonth = String(row.U).padStart(2, "0");
            let registerYear = String(row.V - 543);
            const registerDate = `${registerYear}-${registerMonth}-${registerDay}`;

            if (MustIncludes(["รับ", "ไม่รับ"])(row.W.toString())[0]) {
              throw new Error(
                `เซลล์ที่ W${rowNum} สถานะการรับสมัครไม่ถูกต้อง
                `
              );
            }

            let registerStatus = 0;
            if (row.W.trim() === "รับ") {
              registerStatus = 1;
            }

            const nameForSelect = [{ label: LABLE_FROM_IMPORT, value: name }];
            if (personFromDB[idCard]) {
              nameForSelect.push({
                label: LABLE_FROM_DB,
                value: personFromDB[idCard].firstName
              });
            }
            const surnameForSelect = [
              { label: LABLE_FROM_IMPORT, value: surname }
            ];

            if (personFromDB[idCard]) {
              surnameForSelect.push({
                label: LABLE_FROM_DB,
                value: personFromDB[idCard].lastName
              });
            }

            const registerNoForSelect = [
              { label: LABLE_FROM_IMPORT, value: registerNo }
            ];

            //console.log("personFromDB >>", personFromDB[idCard]);
            //console.log("candidateObjs >>", candidateObjs[idCard]);


            if (candidateObjs[idCard]) {
              registerNoForSelect.push({
                label: LABLE_FROM_DB,
                value: candidateObjs[idCard].applyNo
              });
            }

            const optAreaNoForSelect = [
              { label: LABLE_FROM_IMPORT, value: optAreaNo }
            ];

            if (candidateObjs[idCard]) {
              optAreaNoForSelect.push({
                label: LABLE_FROM_DB,
                value: candidateObjs[idCard].areaNo
              });
            }

            const optPositionTypeIdForSelect = [
              { label: LABLE_FROM_IMPORT, value: optPositionTypeId }
            ];

            if (candidateObjs[idCard]) {
              optPositionTypeIdForSelect.push({
                label: LABLE_FROM_DB,
                value: candidateObjs[idCard].optPositionTypeId
              });
            }

            personList.push({
              optVoteId: candidateObjs[idCard]
                ? candidateObjs[idCard].optVoteId
                : null,
              optId: candidateObjs[idCard]
                ? candidateObjs[idCard].msOptId
                : null,
              optPositionTypeId: OptPositionType,
              optAreaNo: optAreaNo,
              optAreaVote: candidateObjs[idCard]
                ? candidateObjs[idCard].optAreaVote
                : null,
              personId: personFromDB[idCard]
                ? personFromDB[idCard].personId
                : null,
              registerNo: registerNo,
              idCard: idCard,
              gender: gender,
              titleName: titleName,
              name: nameForSelect,
              surname: surnameForSelect,
              birthDate: birthDate,
              address: addrs,
              province: province,
              amphur: amphur,
              tambol: tambol,
              educationLevel: educationLevel,
              tel: tel,
              ocupation: ocupation,
              registerDate: registerDate,
              registerStatus: registerStatus,
              postCode: postCode
            });

            if (optPositionTypeId === 2) {
              personToSaveList.push({
                action:
                  personFromDB[idCard] && personFromDB[idCard].personId
                    ? "mod"
                    : "add",
                optVoteId: candidateObjs[idCard]
                  ? candidateObjs[idCard].optVoteId
                  : null,
                optId: candidateObjs[idCard]
                  ? candidateObjs[idCard].msOptId
                  : null,
                positionTypeId: optPositionTypeId,
                optAreaVote: candidateObjs[idCard]
                  ? candidateObjs[idCard].optAreaVote
                  : null,
                personId: personFromDB[idCard]
                  ? personFromDB[idCard].personId
                  : null,
                applyNo: registerNo,
                optAreaNo: optAreaNo,
                idCard: idCard,
                genderId: genderId,
                titleId: KVTitleName,
                firstName: name,
                lastName: surname,
                birthdate: birthDate,
                addr1: addrs,
                addr1ProvinceId: KVProvince,
                addr1AmphurId: KVAmphur,
                addr1TambolId: KVTambol,
                educationalId: KVEducationLevel,
                tel: tel,
                ocupation: KVOcupation,
                registerDatetime: registerDate,
                registerStatus: registerStatus,
                age: 0,
                postCode: postCode,
                optPersonMem: {
                  optVoteTypeId: getOptSubTypeId(),
                  areaAmphurId: aumphurAreaSelected
                    ? aumphurAreaSelected.id
                    : null,
                  areaNo: optAreaNo,
                  voteDate: votedateSelected.id,
                  applyNo: registerNo,
                  registerDatetime: registerDate,
                  msOptId: optSelected.id,
                  from4_1UUID: null
                }
              });
            } else {
              personToSaveList.push({
                action:
                  personFromDB[idCard] && personFromDB[idCard].personId
                    ? "mod"
                    : "add",
                optVoteId: candidateObjs[idCard]
                  ? candidateObjs[idCard].optVoteId
                  : null,
                optId: candidateObjs[idCard]
                  ? candidateObjs[idCard].msOptId
                  : null,
                positionTypeId: optPositionTypeId,
                optAreaVote: candidateObjs[idCard]
                  ? candidateObjs[idCard].optAreaVote
                  : null,
                personId: personFromDB[idCard]
                  ? personFromDB[idCard].personId
                  : null,
                applyNo: registerNo,
                optAreaNo: optAreaNo,
                idCard: idCard,
                genderId: genderId,
                titleId: KVTitleName,
                firstName: name,
                lastName: surname,
                birthdate: birthDate,
                addr1: addrs,
                addr1ProvinceId: KVProvince,
                addr1AmphurId: KVAmphur,
                addr1TambolId: KVTambol,
                educationalId: KVEducationLevel,
                tel: tel,
                ocupation: KVOcupation,
                registerDatetime: registerDate,
                registerStatus: registerStatus,
                age: 0,
                postCode: postCode,
                optPersonPres: {
                  optVoteTypeId: getOptSubTypeId(),
                  voteDate: votedateSelected.id,
                  applyNo: registerNo,
                  registerDatetime: registerDate,
                  msOptId: optSelected.id,
                  from4_1UUID: null
                }
              });
            }
          }
          //console.log("personList", personList);
          setData(personList);
          setDataToSave(personToSaveList);
          setColumns([
            {
              field: "optPositionTypeId",
              title: "ตำแหน่ง",
              width: "16px"
            },
            {
              field: "optAreaNo",
              title: "เขต",
              width: "2px"
            },
            {
              field: "registerNo",
              title: "หมายเลข",
              width: "16px"
            },
            {
              field: "idCard",
              title: "บัตรประจำตัวประชาชน",
              cellStyle: {
                minWidth: "96px"
              },
              render: rowData => (
                <NumberFormat
                  style={{
                    width: "100%",
                    margin: "0 0 17px 0",
                    paddingTop: "27px",
                    border: "none"
                  }}
                  value={rowData.idCard}
                  placeholder="เลขประจำตัวประชาชน"
                  customInput={TextField}
                  format="# #### ##### ## #"
                  mask="X"
                  fullWidth
                />
              )
            },
            {
              field: "gender",
              title: "เพศ",
              width: "16px"
            },
            {
              field: "titleName",
              title: "คำนำหน้า",
              cellStyle: {
                minWidth: "32px"
              },
              render: row => row.titleName
            },
            {
              field: "name",
              title: "ชื่อ",
              cellStyle: {
                minWidth: "180px"
              },
              render: row => {
                return (
                  <Autocomplete
                    id={"combo-box-name" + row.tableData.id}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    defaultValue={row.name[0]}
                    onChange={(e, v) =>
                      saveData(e, v)(personToSaveList, row, "name")
                    }
                    options={row.name}
                    groupBy={option => option.label}
                    getOptionLabel={option => option.value + ""}
                    renderInput={params => <TextField {...params} />}
                  />
                );
              }
            },
            {
              field: "surname",
              title: "นามสกุล",
              cellStyle: {
                minWidth: "180px"
              },
              render: row => {
                return (
                  <Autocomplete
                    id={"combo-box-surname" + row.tableData.id}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    defaultValue={row.surname[0]}
                    onChange={(e, v) =>
                      saveData(e, v)(personToSaveList, row, "surname")
                    }
                    options={row.surname}
                    groupBy={option => option.label}
                    getOptionLabel={option => option.value + ""}
                    renderInput={params => <TextField {...params} />}
                  />
                );
              }
            },
            {
              field: "birthDate",
              title: "วันเกิด",
              cellStyle: {
                minWidth: "96px"
              },
              render: row => ConvertDate(row.birthDate)
            },
            {
              field: "address",
              title: "ที่อยู่ตามทะเบียนบ้าน",
              cellStyle: {
                minWidth: "96px"
              }
            },
            {
              field: "province",
              title: "จังหวัด",
              cellStyle: {
                minWidth: "96px"
              },
              render: row => row.province
            },
            {
              field: "amphur",
              title: "อำเภอ",
              cellStyle: {
                minWidth: "96px"
              },
              render: row => row.amphur
            },
            {
              field: "tambol",
              title: "ตำบล",
              cellStyle: {
                minWidth: "96px"
              },
              render: row => row.tambol
            },
            {
              field: "postCode",
              title: "รหัสไปรษณีย์",
              cellStyle: {
                minWidth: "96px"
              }
            },
            {
              field: "educationLevel",
              title: "ระดับการศึกษา",
              cellStyle: {
                minWidth: "96px"
              },
              render: row => row.educationLevel
            },
            {
              field: "tel",
              title: "เบอร์โทร",
              cellStyle: {
                minWidth: "96px"
              }
            },
            {
              field: "ocupation",
              title: "อาชีพ",
              cellStyle: {
                minWidth: "96px"
              },
              render: row => row.ocupation
            },
            {
              field: "registerDate",
              title: "วันรับสมัคร",
              cellStyle: {
                minWidth: "96px"
              },
              render: row => ConvertDate(row.registerDate)
            },
            {
              field: "registerStatus",
              title: "รับ/ไม่รับ",
              cellStyle: {
                minWidth: "20px"
              },
              render: row => (row.registerStatus === 1 ? "รับ" : "ไม่รับ")
            }
          ]);
        })
        .catch(err => {
          sweetAlerts.error("กรุณากรอกข้อมูลให้ครบถ้วน", err.toString());
        });
    } catch (err) {
      sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
    }
  };

  const getOptSubTypeId = () => {
    for (const [, v] of Object.entries(optTypeSelected)) {
      if (v.checked) {
        return parseInt(v.value);
      }
    }
    return -1;
  };

  const onConfirm = e => {
    if (optVoteTypeSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภทการเลือกตั้ง");
      return;
    }

    if (optSelected === null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
      return;
    }

    if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันที่เลือกตั้ง");
      return;
    }

    let amphurId = null;
    // console.log("aumphurAreaSelected.id>", aumphurAreaSelected)
    // if (getOptSubTypeId() === 1 || getOptSubTypeId() === 6) {
    //   if (!aumphurAreaSelected) {
    //     sweetAlerts.warning("กรุณาเลือกเขตอำเภอ");
    //     return;
    //     //amphurId = null;
    //   } else {
    //     amphurId = aumphurAreaSelected.id;
    //   }
    //   //console.log("aumphurAreaSelected.id>", aumphurAreaSelected.id)
    // }
    if (getOptSubTypeId() === 1 ) {
      if (aumphurAreaSelected) {
        amphurId = aumphurAreaSelected.id;
      }
    }
    if (chkType && getOptSubTypeId() === 1) {
      if (!aumphurAreaSelected) {
        sweetAlerts.warning("กรุณาเลือกเขตอำเภอ");
        return;
      }
    }
    if (dataToSave.length <= 0) {
      sweetAlerts.warning("กรุณานำเข้าข้อมูลที่ต้องการตรวจสอบ");
      return;
    }
    addMultiOptPerson(
      optSelected.id,
      optVoteTypeSelected.id,
      votedateSelected.id,
      amphurId,
      dataToSave
    )
      .then(r => {
        sweetAlerts.success("นำเข้าข้อมูลเรียบร้อย");
        cleanUp();
      })
      .catch(err => {
        //sweetAlerts.error("การนำเข้ามูลไม่สำเร็จ", err.toString());
        sweetAlerts.error("การนำเข้ามูลไม่สำเร็จ", err.response.data.desc);
      });
  };

  const cleanUp = e => {
    setData([]);
    setDataToSave([]);
    setChkType(false)
  };

  const voteDateSelectedStr = votedateSelected ? votedateSelected.value : "";
  const voteDateTypePrivateId = getVoteDateTypePrivateIdFromString(
    voteDateSelectedStr
  );

  useEffect(() => {
    if (!enableImport) {
      const getOptSubTypeId = () => {
        for (const [, v] of Object.entries(optTypeSelected)) {
          if (v.checked) {
            return parseInt(v.value);
          }
        }
        return -1;
      };

      // console.log("enableImport>" + enableImport)
      // console.log("optSelected>" + optSelected)
      // console.log("votedateSelected>" + votedateSelected)
      // console.log("optVoteTypeSelected>" + optVoteTypeSelected)
    //  console.log("getOptSubTypeId() >" + getOptSubTypeId())
    //   console.log("aumphurAreaSelected>" + aumphurAreaSelected)

      if (
        enableImport &&
        (!(optSelected && votedateSelected && optVoteTypeSelected) ||
          (getOptSubTypeId() === 1 && !aumphurAreaSelected))
      ) {
        setEnableImport(false);
        // console.log("11>" + "11")
      }
      if (
        !enableImport &&
        optSelected &&
        votedateSelected &&
        optVoteTypeSelected
      ) {
        // console.log("22>" + "22")
        if (getOptSubTypeId() === 1) {
          //1 === อบจ.
          setEnableImport(true);
          /*if (aumphurAreaSelected) {
            setEnableImport(true);
          } else {
            setEnableImport(false);
          }*/
        } else {
          setEnableImport(true);
        }
      }
    }
  }, [
    optSelected,
    votedateSelected,
    optVoteTypeSelected,
    aumphurAreaSelected,
    enableImport,
    optTypeSelected
  ]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CheckboxOpttype
                           optTypeSelected={optTypeSelected}
                          onOptTypeSelected={onOptTypeSingleSelected}
                        ></CheckboxOpttype>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxOptVotetype
                          value={optVoteTypeSelected}
                          onChange={onOptVoteTypeSelected}
                          required={true}
                        ></ListboxOptVotetype>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxProvince
                          value={provinceSelected}
                          onChange={onProvinceSelected}
                          required={true}
                        ></ListboxProvince>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxDistinct
                          value={amphurSelected}
                          provinceSelected={provinceSelected}
                          onChange={onAmphurSelected}
                        ></ListboxDistinct>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxOpts
                          value={optSelected}
                          getOptTypeSelectedLists={getOptTypeSelectedLists}
                          optTypeSelected={optTypeSelected}
                          provinceSelected={provinceSelected}
                          amphurSelected={amphurSelected}
                          tambolSelected={tambolSelected}
                          onChange={onOptSelected}
                          required={true}
                        ></ListboxOpts>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxVotedate
                          value={votedateSelected}
                          optSelected={optSelected}
                          optVoteTypeSelected={optVoteTypeSelected}
                          onChange={onVotedateSelected}
                        ></ListboxVotedate>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <ListboxAumphurArea
                          value={aumphurAreaSelected}
                          optSelected={optSelected}
                          votedateSelected={votedateSelected}
                          optVoteTypeSelected={optVoteTypeSelected}
                          onChange={onAumphurAreaSelected}
                        ></ListboxAumphurArea>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer
                  justify="space-between"
                  alignItems="center"
                  direction="row"
                >
                  <GridItem xs={12} sm={12} md={6}>
                    <FileUpload
                      id="file-upload-2"
                      label="แนบไฟล์ (เฉพาะ Excel เท่านั้น)"
                      remove
                      onFileRemove={() => {
                        cleanUp();
                      }}
                      onFileHandler={onExcelFileHandler}
                      fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      btnName={<>Browse</>}
                      btnProps={
                        enableImport
                          ? {
                            color: "primary"
                          }
                          : { disabled: true, color: "primary" }
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{ textAlign: "end" }}>
                      ดาวน์โหลดแบบฟอร์มการนำเข้าข้อมูล:{" "}
                      <Button
                        size="sm"
                        color="success"
                        onClick={() => {
                          FileSaver(ImportOptPerson, "import_opt_person.xlsx");
                        }}
                      >
                        <FontAwesomeIcon icon={faFileExcel} /> แบบฟอร์ม
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <GridItem xs={12} sm={12} md={12}>
          {data.length > 0 ? (
            <MaterialTable
              title="ยืนยันรายการผู้สมัคร ส.ถ./ผ.ถ."
              columns={columns}
              data={data}
              enableInitColumns={false}
              options={{
                headerStyle: {
                  textAlign: "center"
                },
                exportButton: false
              }}
            />
          ) : null}
        </GridItem>
        <br /> <br /> <br />
        <GridContainer
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <GridItem xs={6} sm={6} md={6}></GridItem>
          <GridItem>
            <Button className={classes.buttoncustom} onClick={cleanUp}>
              ยกเลิก
            </Button>
            <Button
              disabled={dataToSave.length === 0}
              color="success"
              className={classes.buttoncustom}
              onClick={onConfirm}
            >
              ยืนยันนำเข้าข้อมูล
            </Button>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </>
  );
};
export default OptImportPerson;
