import React, { useContext, useState } from "react";
import {
  editUserInRole,
  getUserInRole
} from "contexts/API/UserManagement/roles";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxProvince from "components/Common/ListboxProvince.js";
import ListboxRoles from "components/Common/ListboxRoles";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
// import ModaladdUser from "./ModaladdUser";
// import ModaleditUser from "./ModaleditUser";
import { Paper } from "@material-ui/core";
import { VoteDateContext } from "contexts/VoteDate";
import { useSweetAlert } from "components/Alert/SweetAlert";

const UserInRolesList = props => {
  const {
    provinceSelected,

    onProvinceSelected
  } = useContext(VoteDateContext);
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [userName, setUserName] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [loading, setLoading] = useState(false);

  const onRoleSelected = (evt, newValue) => {
    setRoleId(newValue);
  };

  const handelSubmit = () => {
    sweetAlerts.warning(
      "ยืนการการเปลี่ยนแปลงข้อมูล",
      "คุณต้องการเปลี่ยนแปลงข้อมูลใช่หรือไม่",
      () => {
        if (roleId.id) {
          editUserInRole(roleId.id, data)
            .then(result => {
              sweetAlerts.success("บันทึกข้อมูลเรียบร้อยแล้ว");
              //  setData([...dataDelete]);
            })
            .catch(err => {
              sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
            });
        }
      },
      true
    );
  };

  const searchHandler = () => {
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return false;
    }
    if (roleId === null) {
      sweetAlerts.warning("กรุณาเลือกกลุ่มผู้ใช้งาน");
      return false;
    } else {
      setLoading(true);
      //console.log(roleId);
      //console.log(provinceSelected);
      getUserInRole(roleId.id, provinceSelected.id, userName)
        .then(result => {
          setData(result.data.data.userList);
          setData2(result.data.data.usersInRoles);
          setLoading(false);
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
        });
    }
  };

  const columns = [
    {
      title: "userId",
      field: "userId",
      hidden: true
    },
    {
      title: "ใช้งาน/ไม่ใช้งาน",
      field: "isSelect",
      cellStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return (
          <Checkbox
            name="isSelect"
            checked={rowData.isSelect}
            onChange={e => {
              console.log(data);
              let applyPerson = data;

              applyPerson[rowData.tableData.id][e.target.name] =
                e.target.checked;
              setData([...applyPerson]);
            }}
          />
        );
      }
    },
    {
      title: "ชื่อผู้ใช้งาน",
      field: "userName"
    },
    {
      title: "ชื่อ-สกุล",
      field: "fullname"
    },
    {
      title: "จังหวัด",
      field: "provinceName"
    }
  ];
  const columns2 = [
    {
      title: "roleId",
      field: "roleId",
      hidden: true
    },
    {
      title: "กลุ่มผู้ใช้งาน",
      field: "roleName"
    },
    {
      title: "ชื่อผู้ใช้งาน",
      field: "userName"
    },
    {
      title: "ชื่อสกุล",
      field: "fullname"
    },
    {
      title: "จังหวัด",
      field: "province_name"
    }
  ];
  const optionsTable = { pageSize: 10 };
  return (
    <>
      <Card>
        <CardHeader color="warning" text>
          <CardText color="warning">
            <h4>User In Role</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <ListboxProvince
                required={true}
                value={provinceSelected}
                onChange={onProvinceSelected}
              ></ListboxProvince>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <ListboxRoles
                required={true}
                value={roleId}
                onChange={onRoleSelected}
              ></ListboxRoles>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                default
                required
                labelText="ชื่อผู้ใช้งาน"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: userName,

                  autoComplete: "new-password",
                  onChange: e => {
                    setUserName(e.target.value);
                    //  setData({ ...data, addr2: e.target.value });
                    //  handleChange(e, "addr1");
                  }
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button color="info" onClick={searchHandler}>
                ค้นหา
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card>
        {/* <CardBody>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button color="success" onClick={handelSubmit}>
                บันทึก
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody> */}
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {loading ? (
                <LoadingData />
              ) : (
                <>
                  <MaterialTable
                    title={"รายชื่อผู้ใช้งาน"}
                    options={optionsTable}
                    columns={columns}
                    data={data}
                    components={{
                      Container: props => <Paper {...props} elevation={0} />
                    }}
                  />
                </>
              )}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}>
                  {" "}
                </GridItem>
                <GridItem>
                  <Button color="success" onClick={handelSubmit}>
                    บันทึกข้อมูล
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {loading ? (
                <LoadingData />
              ) : (
                <>
                  <MaterialTable
                    title={"รายการผู้ใช้งานที่อยู่ในกลุ่มผู้ใช้งานแต่ละกลุ่ม"}
                    options={optionsTable}
                    columns={columns2}
                    data={data2}
                    components={{
                      Container: props => <Paper {...props} elevation={0} />
                    }}
                  />
                </>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};
export default UserInRolesList;
