import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import MaterialTable from "components/Table/MaterialTable";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { delPopup } from "contexts/API/CommonSetting/Notification";
import { useSweetAlert } from "components/Alert/SweetAlert";

const PopupList = props => {
  const optionsTable = { exportButton: false, paging: true };
  const { sweetAlerts } = useSweetAlert();

  const { PopupData, onaddSuccess } = props;

  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <MaterialTable
              title="รายละเอียดการแจ้งเตือน(POPUP)"
              columns={[
                { title: "id", field: "id", hidden: true },
                { title: "หัวข้อ", field: "header" },
                { title: "รายละเอียด", field: "body" }
                // { title: "จังหวัด", field: "provinceName" }
              ]}
              data={PopupData}
              components={{
                Container: props => <Paper {...props} elevation={0} />
              }}
              options={optionsTable}
              editable={{
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...PopupData];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      //  onaddSuccess(dataDelete);

                      delPopup(oldData.id)
                        .then(result => {
                          sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                          //   setData([...dataDelete]);
                          onaddSuccess(dataDelete);
                        })
                        .catch(err => {
                          sweetAlerts.error(
                            "ไม่สามารถลบข้อมูลได้",
                            err.toString()
                          );
                        });
                      resolve();

                      // props.setProjectData([...dataDelete]);

                      resolve();
                    }, 1000);
                  })
              }}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};
export default PopupList;
