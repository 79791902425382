import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { Validate } from "utils/Validators/Validate";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
import { useFetch } from "hooks/useFetch";
const ListboxArea = props => {
  const [, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [valid, setValid] = React.useState();
  let optId = props.optSelected ? props.optSelected.id : 0;
  let position = props.positionTypeSelected ? props.positionTypeSelected.id : 0;
  let isEnable = false;
  let query = "";
  let data = [];


  if (props.data) {
    data = props.data;
  }

  if (props.optSelected) {
    query += `/opt/${props.optSelected.id}`;
  }

  if (props.optVoteTypeSelected) {
    query += `/area?optVoteTypeId=${props.optVoteTypeSelected.id}`;
  }
  if (props.votedateSelected) {
    query += `&voteDate=${props.votedateSelected.id}`;
  }
  if (props.optVoteIdSelected) {
    query += `&optVoteId=${props.optVoteIdSelected.id}`;
  }

  if (props.aumphurAreaSelected) {
    query += `&amphurId=${props.aumphurAreaSelected.id}`;
  }

  if (optId > 0 && props.optVoteTypeSelected !== null && position !== 1) {
    // if (postion === 1) {
    //   isEnable = false;
    // } else {
    //   isEnable = true;
    // }

    isEnable = true;
  }

  let content = (
    <TextField
      disabled
      label="เลือกทุกเขตเลือกตั้ง"
      margin="normal"
      fullWidth
    />
  );

  const [isLoading, fetchData] = useFetch(conf.END_POINT_URL + `${query}`, [
    props.optSelected,
    props.votedateSelected,
    props.optVoteTypeSelected,
    props.aumphurAreaSelected
  ]);

  if (props.data === undefined || props.data === null) {
    if (
        isEnable &&
        !isLoading &&
        fetchData !== null &&
        fetchData.data !== undefined &&
        fetchData.data[0] !== undefined &&
        fetchData.data[0].opt_area !== undefined
    ) {
      if (props.isreport) {
        data = fetchData.data[0].opt_area.map(v => {
          return {
            id: `${v.area_no}`,
            value: `${
                v.amphur_name === null ? "" : "อำเภอ " + v.amphur_name
            } ส.ถ. เขตที่ ${v.area_no}`
          };
        });
      } else {
        data = fetchData.data[0].opt_area.map(v => {
          return {
            id: `${v.opt_area_id}`,
            value: `${
                v.amphur_name === null ? "" : "อำเภอ " + v.amphur_name
            } ส.ถ. เขตที่ ${v.area_no}`
          };
        });
      }
    }
  }
  if (data.length > 0) {
    content = (
      <Autocomplete
        name="area"
        disabled={props.disabled}
        options={data}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label="เลือกทุกเขตเลือกตั้ง"
              error={valid}
              margin="normal"
              fullWidth
              helperText={helperText}
              inputProps={{
                autoComplete: "new-password",
                //   autoComplete: "off",
                ...params.inputProps
              }}
              onChange={Validate(
                params.inputProps.value,
                props.required,
                setValid
              )}
              required={props.required}
            />
          );
        }}
      />
    );
  }

  return content;
};
export default ListboxArea;
