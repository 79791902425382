import React, { useState } from "react";
import CardAllocate from "./CardAllocate";
import DetailAllocate from "./DetailAllocate";
import AddAllocate from "./AddAllocate";
import EditAllocate from "./EditAllocate";
import { VoteDateContextProvider } from "contexts/VoteDate";

const Index = props => {
  const SwitchComponents = ({ active, children }) => {
    return children.filter(child => child.props.name === active);
  };
  // Local State
  const [pageProps, setPageProps] = useState({
    active: "CardAllocate",
    props: null
  });

  return (
    <VoteDateContextProvider>
      <SwitchComponents active={pageProps.active}>
        <CardAllocate
          name="CardAllocate"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <DetailAllocate
          name="DetailAllocate"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <AddAllocate
          name="AddAllocate"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <EditAllocate
          name="EditAllocate"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
      </SwitchComponents>
    </VoteDateContextProvider>
  );
};

export default Index;
