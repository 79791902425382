import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
import { useFetch } from "hooks/useFetch";
const ListboxAumphurArea = props => {
  let optId = props.optSelected ? props.optSelected.id : 0;
  let isEnable = false;
  let query = "";
  let data = [];
  const [error] = React.useState(false);
  const [helperText] = React.useState("");
  if (props.optSelected) {
    query += `/opt/${props.optSelected.id}`;
  }
  if (props.optVoteTypeSelected) {
    query += `/voteType/${props.optVoteTypeSelected.id}`;
  }

  if (props.votedateSelected) {
    query += `/optAmpher?voteDate=${props.votedateSelected.id}`;
  }
  if (optId > 0 && props.optVoteTypeSelected && props.votedateSelected) {
    isEnable = true;
  }

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + `${query}`,
    [props.optSelected, props.optVoteTypeSelected, props.votedateSelected],
    isEnable
  );

  let content = (
    <TextField
      disabled
      label="เลือกเขตอำเภอ (เฉพาะ อบจ. เท่านั้น)"
      margin="normal"
      fullWidth
    />
  );

  if (
    isEnable &&
    !isLoading &&
    fetchData !== null &&
    fetchData.data !== undefined
  ) {
    data = fetchData.data.map(v => {
      return {
        id: `${v.amphur_id}`,
        value: v.amphur_name
      };
    });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        name="aumphurArea"
        disabled={props.disabled}
        options={data}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={props.onChange}
        renderInput={params => {
          return (
            <TextField
              {...params}
              error={error}
              helperText={helperText}
              label="เลือกเขตอำเภอ"
              margin="normal"
              fullWidth
            />
          );
        }}
        {...props.customProps}
      />
    );
  }

  return content;
};
export default ListboxAumphurArea;
