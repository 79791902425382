import MaterialTable from "components/Table/MaterialTable";
import React, { useState, useEffect } from "react";
import {
  getwtdLawType,
  editwtdLawType,
  addwtdLawType,
  deletewtdLawType
} from "contexts/API/WTD/common";
import { useSweetAlert } from "components/Alert/SweetAlert";

const LawType = props => {
  const { sweetAlerts } = useSweetAlert();
  
  const [data, setData] = useState([]);
  const optionsTable = { pageSize: 10 };
  const [columns] = useState([
    {
      title: "wtdLawTypeId",
      field: "wtdLawTypeId",
      hidden: true
    },
    {
      title: "ประเภท พ.ร.บ.",
      field: "detail",
      cellStyle: {
        minWidth: "800px"
      }
    }
  ]);
  useEffect(() => {
    getwtdLawType()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaterialTable
      title={"บริหารจัดการกฎหมายที่เกี่ยวข้องกับการเพิกถอนสิทธิเลือกตั้ง"}
      options={optionsTable}
      columns={columns}
      data={data}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
              addwtdLawType(newData.detail)
                .then(result => {
                  newData.wtdLawTypeId = result.data.data.wtdLawTypeId;
                  setData([...data, newData]);
                  sweetAlerts.success("เพิ่มข้อมูลเรียบร้อยแล้ว");
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
                });

              resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              editwtdLawType(oldData.wtdLawTypeId, newData.detail)
                .then(result => {
                  sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                  setData([...dataUpdate]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });

              resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              
              dataDelete.splice(index, 1);
              deletewtdLawType(oldData.wtdLawTypeId)
                .then(result => {
                  sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                  setData([...dataDelete]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
                });

              resolve();
          })
      }}
    />
  );
};
export default LawType;
