import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function addNewOpt(
  optSubTypeId,
  optName,
  provinceId,
  amphurId,
  tambolId
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/opt`;

  const body = {
    optSubTypeId: optSubTypeId,
    optName: optName,
    provinceId: provinceId,
    isSetupArea: 1
  };

  if (
    amphurId !== undefined &&
    amphurId !== null &&
    [2, 3, 4, 5].includes(optSubTypeId)
  ) {
    body.amphurId = amphurId;
  }

  if (
    tambolId !== undefined &&
    tambolId !== null &&
    [4, 5].includes(optSubTypeId)
  ) {
    body.tambolId = tambolId;
  }

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getOptLists(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/opt/lists${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteOpt(optId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/opt/${optId}`;

  const body = {
    deleteRemark: "-"
  };
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    },
    data: body
  });
}

export function editOpt(optId, optName, optSubTypeId, provinceId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/opt`;

  const body = {
    optId: optId,
    optName: optName,
    optSubTypeId: optSubTypeId,
    provinceId: provinceId
  };

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function upgradeOpt(
  oldOptId,
  optSubTypeId,
  optName,
  provinceId,
  amphurId,
  tambolId,
  isSetupArea,
  upLevelRemark
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/opt/upgrade`;

  const body = {
    oldOptId: oldOptId,
    optSubTypeId: optSubTypeId,
    optName: optName,
    provinceId: provinceId,
    isSetupArea: 1
  };

  if (amphurId !== undefined && amphurId !== null) {
    body.amphurId = amphurId;
  }

  if (tambolId !== undefined && tambolId !== null) {
    body.tambolId = tambolId;
  }

  if (isSetupArea !== undefined && isSetupArea !== null) {
    body.isSetupArea = isSetupArea;
  }

  if (upLevelRemark !== undefined && upLevelRemark !== null) {
    body.upLevelRemark = upLevelRemark;
  }

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function includeOpt(
  oldOptIds,
  optSubTypeId,
  optName,
  provinceId,
  amphurId,
  tambolId,
  isSetupArea,
  remark
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/opt/include`;

  const body = {
    oldOptIds: oldOptIds,
    optSubTypeId: optSubTypeId,
    optName: optName,
    provinceId: provinceId,
    isSetupArea: 1
  };

  if (amphurId !== undefined && amphurId !== null) {
    body.amphurId = amphurId;
  }

  if (tambolId !== undefined && tambolId !== null) {
    body.tambolId = tambolId;
  }

  if (isSetupArea !== undefined && isSetupArea !== null) {
    body.isSetupArea = isSetupArea;
  }

  if (remark !== undefined && remark !== null) {
    body.remark = remark;
  }

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getPositionStatus(
  oldOptIds,
  optSubTypeId,
  optName,
  provinceId,
  amphurId,
  tambolId,
  isSetupArea,
  remark
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/position/status`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
