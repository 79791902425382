import React, { useContext, useState } from "react";
/* API */
import {
  getSearchLists,
  updateAllocatePrintingList
} from "contexts/API/VoteCard/AllocatePrinting";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import DatepickerVotedate from "components/Common/Datepicker";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FileUpload } from "components/Common/FileUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import HandelError from "utils/HandleError";
import ListboxVotecardPrinting from "components/Common/ListboxVotecardPrinting";
/* Material Components */
import LoadingData from "components/Common/LoadingData";
import { Paper } from "@material-ui/core";
import TableComponent from "components/Table/MaterialTable";
import { VoteDateContext } from "contexts/VoteDate";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

const AllocatetoPrinting = ({ pageProps, setPageProps }) => {
  const [loading, setLoading] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadFileUUID, setUploadFileUUID] = useState("");
  const [newData, setNewData] = useState(null);
  const [isPurchaseValid, setIsPurchaseValid] = useState(true);
  const [, setClickSearch] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  const {
    votecardPrintingSelected,
    setVotecardPrintingSelected,
    onVotecardPrintingSelected,
    startDate,
    endDate,
    setStartDate,
    setEndDate
  } = useContext(VoteDateContext);
  /* const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null); */

  const [
    voteCardPurchaseCentralReferenceNo,
    setVoteCardPurchaseCentralReferenceNo
  ] = useState("");
  const [
    voteCardPurchaseCentralReferenceDate,
    setVoteCardPurchaseCentralReferenceDate
  ] = useState(new Date().toISOString());

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const onClickCancel = e => {
    setVotecardPrintingSelected(null);
    setStartDate(null);
    setEndDate(null);
    setData([]);
  };
  const onClickSearch = e => {
    setClickSearch(false);
    setLoading(true);

    if (startDate !== null && endDate === null) {
      sweetAlerts.warning("กรุณาเลือกวันจัดสรรสิ้นสุด");
    }

    if (startDate === null && endDate !== null) {
      sweetAlerts.warning("กรุณาเลือกวันจัดสรรเริ่มต้น");
    }
    const optQueryUrl = () => {
      let endPoint = "?";

      if (votecardPrintingSelected !== null) {
        endPoint += `&PrintHouseID=${votecardPrintingSelected.id}`;
      }

      if (startDate !== null) {
        endPoint += `&AllocateStartDate=${startDate.format().split("T")[0]}`;
      }

      if (endDate !== null) {
        endPoint += `&AllocateEndDate=${endDate.format().split("T")[0]}`;
      }

      return endPoint;
    };

    getSearchLists(optQueryUrl())
      .then(result => {
        setData(result.data.data);

        const columns = [
          {
            title: "จังหวัด",
            field: "provinceName",

            cellStyle: {
              minWidth: "150px",
              textAlign: "center"
            },
            headerStyle: {
              textAlign: "center"
            }
          },
          {
            title: "อำเภอ",
            field: "amphurName",

            cellStyle: {
              minWidth: "150px",
              textAlign: "center"
            },
            headerStyle: {
              textAlign: "center"
            }
          },
          /*  {
             title: "ประเภท",
             field: "voteCardOptTypeName",
             cellStyle: {
               textAlign: "center"
             },
             headerStyle: {
               textAlign: "center"
             }
           }, */
          {
            title: "ชื่อ อปท.",
            field: "optName",

            cellStyle: {
              textAlign: "center",
              minWidth: "150px"
            },

            render: rowData => {
              return rowData.voteCardOptTypeName + rowData.optName;
            }
          },

          {
            title: "ตำแหน่ง",
            field: "positionDetail",

            cellStyle: {
              minWidth: "100px",
              textAlign: "center"
            },
            headerStyle: {
              textAlign: "center"
            },
            render: rowData => {
              return rowData.positionDetail;
            }
          },
          {
            title: "วันที่เลือกตั้ง",
            field: "voteDate",
            cellStyle: {
              textAlign: "center",
              minWidth: "200px"
            },
            headerStyle: {
              textAlign: "center"
            },
            render: rowData => {
              return ConvertDate(rowData.voteDate);
            }
          },
          {
            title: "เลขที่หนังสืออ้างอิง (สั่งซื้อบัตร)",
            field: "documentNo",

            cellStyle: {
              minWidth: "200px",
              textAlign: "center"
            },
            headerStyle: {
              textAlign: "center"
            }
          },
          {
            title: "วันที่สั่งซื้อบัตร",
            field: "voteCardPurchaseDate",
            cellStyle: {
              textAlign: "center",
              minWidth: "150px"
            },
            headerStyle: {
              textAlign: "center"
            },
            render: rowData => {
              return ConvertDate(rowData.voteCardPurchaseDate);
            }
          },
          {
            title: "วิธีการรับบัตรเลือกตั้ง",
            field: "receiveName",

            cellStyle: {
              textAlign: "center",
              minWidth: "150px"
            },
            headerStyle: {
              textAlign: "center"
            }
          },
          {
            title: "วันที่ส่ง/รับบัตร",
            field: "receiveDatetime",

            cellStyle: {
              textAlign: "center",
              minWidth: "150px"
            },
            headerStyle: {
              textAlign: "center"
            },
            render: rowData => {
              return ConvertDate(rowData.receiveDatetime);
            }
          }
        ];

        setColumns(columns);
        setLoading(false);
        setClickSearch(true);
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้.", err.toString());
      });
  };

  const onSubmit = () => {
    if (newData === null) {
      sweetAlerts.warning("กรุณาเลือก อปท ที่ต้องการจัดสรร");
      return false;
    }
    if (uploadFileUUID === "" || uploadFileUUID === undefined) {
      sweetAlerts.warning("กรุณาแนบไฟล์เอกสาร");
      return false;
    }
    if (voteCardPurchaseCentralReferenceNo === "") {
      sweetAlerts.warning("กรุณาระบุเลขที่หนังสือแนบท้าย");
      return false;
    }
    if (voteCardPurchaseCentralReferenceDate === undefined) {
      sweetAlerts.warning("กรุณาวันที่ลงหนังสือ");
      return false;
    }

    if (newData !== null) {
      for (let i = 0; i < newData.length; i++) {
        // console.log(newData[i]);
        // console.log(newData[i].isSendToPrintingHouse);
        newData[i]["isSendToPrintingHouse"] = 1;
        newData[i]["voteCardPurchaseStatusID"] = 5;
      }
    }
    if (
      newData !== null &&
      voteCardPurchaseCentralReferenceNo !== "" &&
      voteCardPurchaseCentralReferenceDate !== undefined
    ) {
      updateAllocatePrintingList(
        {
          voteCardPurchaseCentralReferenceNo: voteCardPurchaseCentralReferenceNo,
          voteCardPurchaseCentralReferenceDate: voteCardPurchaseCentralReferenceDate.split(
            "T"
          )[0],
          centralFileUuid: uploadFileUUID
        },
        newData
      )
        .then(result => {
          sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");
          setUploadFileName("");
          setUploadFileUUID("");
          setVoteCardPurchaseCentralReferenceNo("");
          onClickSearch();
        })
        .catch(err => {
          //    HandelError(err);
          sweetAlerts.error("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
        });
    }
  };

  return (
    <>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4>ค้นหารายการจัดสรรบัตรเลือกตั้ง</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <ListboxVotecardPrinting
                      value={votecardPrintingSelected}
                      onChange={onVotecardPrintingSelected}
                    ></ListboxVotecardPrinting>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DatepickerVotedate
                      id="begin"
                      label="จัดสรรบัตรตั้งแต่วันที่"
                      value={startDate}
                      defaultValue={startDate}
                      onChange={setStartDate}
                    ></DatepickerVotedate>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DatepickerVotedate
                      id="endDate"
                      label="ถึงวันที่"
                      value={endDate}
                      defaultValue={endDate}
                      onChange={setEndDate}
                    ></DatepickerVotedate>
                  </GridItem>
                </GridContainer>
                <br /> <br />
                <GridContainer
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <GridItem xs={6} sm={6} md={6}></GridItem>
                  <GridItem>
                    <Button onClick={onClickCancel}>ยกเลิก</Button>
                    <Button color="info" type="button" onClick={onClickSearch}>
                      ค้นหา
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <TableComponent
          title="รายชื่อ อปท. ที่มีการจัดสรรเลือกตั้ง"
          columns={columns}
          data={data}
          localization={{}}
          options={{
            paging: true,
            pageSize: 5,
            selection: true,
            exportButton: false
          }}
          localization={{
            toolbar: {
              nRowsSelected: "เลือกทั้งหมด {0} รายการ."
            }
          }}
          components={{
            Container: props => <Paper {...props} elevation={0} />
          }}
          onSelectionChange={rows => {
            setNewData(rows);
          }}
        />
      )}
      <Card>
        <DialogTitle id="classic-modal-slide-title">
          บันทึกหนังสือคำสั่งแนบท้าย
        </DialogTitle>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                error={isPurchaseValid}
                default
                required={true}
                helpertext="กรุณาระบุ*"
                labelText="เลขที่หนังสือคำสั่งแนบท้าย (สนง.กกต.)"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: voteCardPurchaseCentralReferenceNo || "",

                  onChange: e => {
                    if (e.target.value) {
                      setVoteCardPurchaseCentralReferenceNo(e.target.value);
                      setIsPurchaseValid(false);
                    } else {
                      setVoteCardPurchaseCentralReferenceNo(null);
                      setIsPurchaseValid(true);
                    }
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <DatepickerVotedate
                id="begin"
                label="หนังสือลงวันที่"
                value={voteCardPurchaseCentralReferenceDate}
                onChange={date => {
                  if (date) {
                    setVoteCardPurchaseCentralReferenceDate(date.format());
                  } else {
                    setVoteCardPurchaseCentralReferenceDate(null);
                  }
                }}
              ></DatepickerVotedate>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <FileUpload
                id="file-upload-2"
                label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                fileName={uploadFileName}
                remove
                onFileRemove={() => {
                  setUploadFileName("");
                  setUploadFileUUID("");
                }}
                url={getFileDownloadUrl("votecard", uploadFileUUID)}
                download
                onFileHandler={(e, f, p) => {
                  uploadFile("votecard", f, p)
                    .then(r => {
                      const file = r.data.data;
                      sweetAlerts.success("แนบไฟล์เรียบร้อย");
                      setUploadFileName(file.fileName);
                      setUploadFileUUID(file.fileId);
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }}
                fileType="application/pdf"
                btnName={<>Browse</>}
                btnProps={{
                  color: "primary"
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            justify="space-between"
            direction="column"
            alignItems="flex-end"
          >
            <GridItem>
              <Button
                color="info"
                onClick={onSubmit}
                disabled={newData === null ? true : false}
              >
                ส่งข้อมูลจัดสรรบัตรเลือกตั้งไปโรงพิมพ์
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
        <br></br>
      </Card>
      <br></br>
    </>
  );
};
export default AllocatetoPrinting;
