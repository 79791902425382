import React from "react";
// core UserList
import UsersList from "./usersList";
import { VoteDateContextProvider } from "contexts/VoteDate";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

//const styles = {};
//const useStyles = makeStyles(styles);

const UserIndex = props => {
  return (
    <div>
      <VoteDateContextProvider>
        <UsersList />
      </VoteDateContextProvider>
    </div>
  );
};
export default UserIndex;
