export function onlyNumber(v) {
    if (isNaN(Number(v))) {
        return [true, "ต้องเป็นตัวเลขเท่านั้น"];
    }
    return [false, ""];
}

export function positiveNumber(v) {
    if (v === undefined || v === null || v < 0 || v === "") {
        return [true, "ตัวเลขที่ระบุต้องมากกว่า 0"];
    }
    return [false, ""];
}