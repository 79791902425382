import React, { createRef, useContext } from "react";

import AtLeastOne from "utils/Validators/AtLeastOne";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOpttype from "components/Common/ListboxOpttype.js";
// Core Components
import ListboxProvince from "components/Common/ListboxProvince.js";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import Slide from "@material-ui/core/Slide";
import { VoteDateContext } from "contexts/VoteDate";
// Context APIs
import { editOpt } from "contexts/API/OPT/Opt";
// Material Core
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalEditOpt = props => {
  const { sweetAlerts } = useSweetAlert();

  const {
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,
    onTambolSelected,
    onListboxOptSelected
  } = useContext(VoteDateContext);

  const { show, closeModal, data } = props;

  const classes = useStyles();

  const optNameRef = createRef();

  const onSubmit = () => {
    let optId = null;
    let optName = null;
    let optSubTypeId = null;
    let provinceId = null;

    if (data.ms_opt_id === undefined || data.ms_opt_id === null) {
      sweetAlerts.error("ไม่สามารถแก้ไข อปท.", "เนื่องจากไม่พบ opt id");
      return;
    } else {
      optId = data.ms_opt_id;
    }

    if (optNameRef.current.value === "") {
      sweetAlerts.warning("กรุณาระบุชื่อ อปท.");
      return;
    } else {
      optName = optNameRef.current.value;
    }
    optSubTypeId = data.ms_opt_sub_type_id;
    provinceId = data.province_id;

    //console.log("optSubTypeId >", optSubTypeId);
    //console.log("provinceId >", provinceId);

    editOpt(optId, optName, optSubTypeId, provinceId)
      .then(result => {
        sweetAlerts.success("แก้ไข อปท. สำเร็จแล้ว");
        closeModal();
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถแก้ไข อปท.", err.toString());
      });
  };

  return (
    <div className={show ? "modal" : "hide"}>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={show}
        fullWidth={true}
        transition={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          className={classes.titlemodal}
        >
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            {" " + props.title}
          </div>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <form className={classes.form}>
            <FormControl>
              <ListboxOpttype
                customProps={{
                  disabled: true
                }}
                validators={[AtLeastOne]}
                defaultValue={{ id: data.ms_opt_sub_type_id || -1 }}
                onChange={onListboxOptSelected}
              ></ListboxOpttype>

              <ListboxProvince
                customProps={{
                  disabled: true
                }}
                defaultValue={{ id: data.province_id || -1 }}
                validators={[AtLeastOne]}
                onChange={onProvinceSelected}
              ></ListboxProvince>

              <ListboxDistinct
                customProps={{
                  disabled: true
                }}
                defaultValue={{ id: data.amphur_id || -1 }}
                provinceSelected={provinceSelected}
                onChange={onAmphurSelected}
              ></ListboxDistinct>

              {([5, 6, 7].includes(parseInt(data.ms_opt_sub_type_id))) ? (
                <ListboxSubdistinct
                  customProps={{
                    disabled: true
                  }}
                  defaultValue={{ id: data.tambol_id || -1 }}
                  provinceSelected={provinceSelected}
                  amphurSelected={amphurSelected}
                  onChange={onTambolSelected}
                ></ListboxSubdistinct>
              ) : (
                ""
              )}

              <CustomInput
                name="optname"
                default
                required
                labelText="ชื่อ อปท. *"
                inputProps={{
                  inputRef: optNameRef,
                  defaultValue: data.name
                }}
              />
            </FormControl>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={closeModal}>ยกเลิก</Button>
              <Button onClick={onSubmit} variant="contained" color="success">
                บันทึก
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalEditOpt;
