import React, { useContext, useEffect, useState } from "react";
/* API */
//import { getSearchLists } from "contexts/API/VoteCard/Publish";
import {
  addVoteCardAllocate,
  getSearchDetailInfoLists,
  addVoteCardAllocateNoti
} from "contexts/API/VoteCard/Allocate";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxVotecardColor from "components/Common/ListboxVotecardColor";
//import TextField from "@material-ui/core/TextField";
import ListboxVotecardNumber from "components/Common/ListboxVotecardNumber";
import ListboxVotecardPrinting from "components/Common/ListboxVotecardPrinting";
import LoadingData from "components/Common/LoadingData";
import { Paper } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import TableComponent from "components/Table/MaterialTable";
import { VoteDateContext } from "contexts/VoteDate";
import { makeStyles } from "@material-ui/core/styles";
//import { updateVoteCardPurchaseDetailStatus } from "contexts/API/VoteCard/Purchase";
import { useSweetAlert } from "components/Alert/SweetAlert";

// @material-ui/icons

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  },
  marginNormal1: {
    marginTop: "-15px !important"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Modalallocate = props => {
  //const [param, setParam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chkPrintHouseID, setChkPrintHouseID] = useState(null);
  const [chkColorName, setChkColorName] = useState(null);
  const [chkNumber, setChkNumber] = useState(null);
  const [chkPositionTypeID, setChkPositionTypeID] = useState(null);

  const {
    votecardNumberSelected,
    onVotecardNumberSelected,
    votecardPrintingSelected,
    onVotecardPrintingSelected,
    votecardColorSelected, //สีบัตร
    onVotecardColorSelected,
    setVotecardNumberSelected,
    setVotecardColorSelected,
    setVotecardPrintingSelected
  } = useContext(VoteDateContext);

  const {
    showModaladdCardallocate,
    closeModaladdCardallocate,
    areaAllocate
  } = props;
  const [, setClickSearch] = useState(false);
  const { sweetAlerts } = useSweetAlert();

  const classes = useStyles();

  useEffect(() => {
    setData([]);
    setVotecardPrintingSelected(null);
    setVotecardColorSelected(null);
    setVotecardNumberSelected(null);
    setChkPrintHouseID(null);
    setChkColorName(null);
    setChkNumber(null);
    setChkPositionTypeID(null);
  }, [showModaladdCardallocate]);

  const [columns] = useState([
    { title: "โรงพิมพ์", field: "PrintHouseName", editable: "never" },
    { title: "ประเภท", field: "VoteCardOptTypeName", editable: "never" },
    { title: "ตำแหน่ง", field: "PositionTypeDetail", editable: "never" },

    {
      title: "สี",
      field: "VoteCardColorName",
      editable: "never",
      cellStyle: {
        width: "200px",
        textAlign: "left"
      },
      headerStyle: {
        width: "200px",
        textAlign: "left"
      }
    },
    {
      title: "ชนิดหมายเลข",
      field: "VoteCardNumberName",
      editable: "never",
      cellStyle: {
        width: "200px",
        textAlign: "left"
      },
      headerStyle: {
        width: "200px",
        textAlign: "left"
      }
    },
    {
      title: "เลขเล่มที่เหลือ เริ่มต้น ถึง เลขที่เหลือสิ้นสุด (เล่ม)",
      field: "",
      editable: "never",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return (
          new Intl.NumberFormat().format(rowData.VoteCardNumberStart) +
          " - " +
          new Intl.NumberFormat().format(rowData.VoteCardNumberEnd)
        );
      }
    },
    {
      title: "คงเหลือ (เล่ม)",
      field: "VoteCardBalanceAmount",
      editable: "never",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return new Intl.NumberFormat().format(rowData.VoteCardBalanceAmount);
      }
    },
    {
      title: "จำนวนที่จัดสรร",
      field: "VoteCardDisburse1",
      type: "numeric",
      cellStyle: {
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
      // render: rowData => {
      //   return new Intl.NumberFormat().format(0);
      // }
    }
  ]);
  const [data, setData] = useState([]);

  const optionsTable = { exportButton: false, paging: false };

  const onClickSearch = e => {
    setClickSearch(false);
    setLoading(true);

    const optQueryUrl = () => {
      let endPoint = `?voteCareOptTypeID=${areaAllocate.OptTypeId}`;

      if (areaAllocate.PositionTypeID !== null) {
        endPoint += `&PositionTypeID=${areaAllocate.PositionTypeID}`;
      }

      if (votecardPrintingSelected !== null) {
        endPoint += `&PrintHouseID=${votecardPrintingSelected.id}`;
      }

      if (votecardColorSelected !== null) {
        endPoint += `&CardColorID=${votecardColorSelected.id}`;
      }

      if (votecardNumberSelected !== null) {
        endPoint += `&CardNo=${votecardNumberSelected.value}`;
      }

      return endPoint;
    };

    getSearchDetailInfoLists(optQueryUrl(), "")
      .then(result => {
        setData(result.data.data);
        //   console.log(result.data.data);
        setLoading(false);
        setClickSearch(true);
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
      });
  };

  function ChkParma(param) {
    if (
      (chkColorName === null &&
        chkPrintHouseID === null &&
        chkNumber === null) || param.PositionTypeID !== chkPositionTypeID
    ) {
      setChkPrintHouseID(param.PrintHouseID);
      setChkColorName(param.VoteCardColorName);
      setChkNumber(param.VoteCardNumberName);
      setChkPositionTypeID(param.PositionTypeID);
      return true;
    }
    if (
      chkColorName !== null &&
      chkPrintHouseID !== null &&
      chkNumber !== null
    ) {
      if (
        param.VoteCardColorName !== chkColorName ||
        param.PrintHouseID !== chkPrintHouseID ||
        param.VoteCardNumberName !== chkNumber
      ) {
        sweetAlerts.warning(
          "ไม่สามารถจัดสรรได้เนื่องจากลักษณะของบัตรไม่ตรงกับ รายการที่เคยจัดสรรไว้"
        );
        return false;
      }
      if (
        param.VoteCardColorName === chkColorName &&
        param.PrintHouseID === chkPrintHouseID &&
        param.VoteCardNumberName === chkNumber
      ) {
        return true;
      }
    }
  }

  return (
    <div className={showModaladdCardallocate ? "modal" : "hide"}>
      <Dialog
        open={showModaladdCardallocate}
        fullWidth={true}
        maxWidth={"lg"}
        transition={Transition}
        keepMounted
        onClose={closeModaladdCardallocate}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="success">
            <h4 className={classes.cardTitle}>การจัดสรรบัตรเลือกตั้ง</h4>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >

          <GridContainer direction="row"
            justify="space-between"
            alignItems="center">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4 style={{ fontWeight: "bolder", paddingLeft: "10px" }}>ค้นหารายการสั่งพิมพ์บัตรเลือกตั้งในระบบ</h4>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <ListboxVotecardPrinting
                    value={votecardPrintingSelected}
                    onChange={onVotecardPrintingSelected}
                  ></ListboxVotecardPrinting>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <ListboxVotecardColor
                    votecardPrintingSelected={votecardPrintingSelected}
                    value={votecardColorSelected}
                    onChange={onVotecardColorSelected}
                  ></ListboxVotecardColor>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <ListboxVotecardNumber
                    votecardPrintingSelected={votecardPrintingSelected}
                    votecardColorSelected={votecardColorSelected}
                    value={votecardNumberSelected}
                    onChange={onVotecardNumberSelected}
                  ></ListboxVotecardNumber>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer
                direction="column"
                justify="flex-end"
                alignItems="flex-end"
              >
                <GridItem xs={12} sm={12} md={6}>
                  <Button
                    type="button"
                    color="info"
                    className={classes.buttoncustom}
                    onClick={onClickSearch}
                  >
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <br></br>
          <hr></hr>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h4 style={{ fontWeight: "bolder", paddingLeft: "10px" }}>รายละเอียดบัตรเลือกตั้งที่สั่งซื้อ</h4>
            </GridItem>
          </GridContainer>
          <br></br>
          <GridContainer direction="row"
            justify="space-between"
            alignItems="center">

            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>

                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    default
                    required
                    labelText="จังหวัดที่สั่งซื้อ"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpertext="กรุณาระบุ*"
                    inputProps={{
                      value: (areaAllocate.OptTypeId != 6 ? "จ. " + areaAllocate.ProvinceName + " " + (areaAllocate.AmphurName === null ? "" : "อ. " + areaAllocate.AmphurName || "")
                        : (areaAllocate.ProvinceName + (areaAllocate.AmphurName === null ? "" : "ข. " + areaAllocate.AmphurName))) || "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    default
                    required
                    labelText="อปท ที่ต้องการจัดสรร"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpertext="กรุณาระบุ*"
                    inputProps={{
                      value: areaAllocate.OptTypeName + areaAllocate.OptName || "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    default
                    required
                    labelText="ตำแหน่งที่จัดเลือกตั้ง"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpertext="กรุณาระบุ*"
                    inputProps={{
                      value: areaAllocate.PositionTypeName || "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    default
                    required
                    labelText="จำนวนผู้สมัคร"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpertext="กรุณาระบุ*"
                    inputProps={{
                      value: new Intl.NumberFormat().format(areaAllocate.VoteRegister) || "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    default
                    required
                    labelText="จำนวนผู้มีสิทธิเลือกตั้ง"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpertext="กรุณาระบุ*"
                    inputProps={{
                      value: new Intl.NumberFormat().format(areaAllocate.VotePerson) || "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    default
                    required
                    labelText="จำนวนบัตรเลือกตั้ง"
                    formControlProps={{
                      fullWidth: true
                    }}
                    helpertext="กรุณาระบุ*"
                    inputProps={{
                      value: new Intl.NumberFormat().format(areaAllocate.VoteCard) + " เล่ม (" +
                        new Intl.NumberFormat().format(areaAllocate.VoteCard * 20) + " ฉบับ )"
                        || "",
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <br></br>
          <hr></hr>
          {loading ? (
            <LoadingData />
          ) : (
            <TableComponent
              title="รายละเอียดบัตรเลือกตั้ง"
              columns={columns}
              data={data}
              localization={{}}
              options={optionsTable}
              components={{
                Container: props => <Paper {...props} elevation={0} />
              }}
              editable={{
                isEditHidden: newData => (newData.VoteCardDisburse1 != 0 && newData.VoteCardDisburse1 != null),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...data];
                      const index = oldData.tableData.id;

                      let _x = ChkParma(oldData);
                      //  console.log("chkparam,,,,", _x);
                      //let _newStartVolumnNo = newData.VoteCardNumberStart;

                      //console.log("oldData >>", oldData);
                      //console.log("newData >>", newData);
                      // console.log(oldData.VoteCardNumberStart);
                      //console.log(newData.VoteCardDisburse1);
                      if (_x) {
                        let _newEndVolumnNo =
                          newData.VoteCardNumberStart +
                          (newData.VoteCardDisburse1 - 1);
                        const body = {
                          voteCardAllocateID: newData.DataKeyAllocate,
                          voteCardPurchaseDetailID:
                            areaAllocate.VoteCardPurchaseDetailID,
                          voteCardPublishDetailID: newData.DataKey,
                          startVolumnNo: newData.VoteCardNumberStart,
                          endVolumnNo: _newEndVolumnNo,
                          volumnAllocate: newData.VoteCardDisburse1,
                          allocateDateTime: "",
                          Remark: "",
                          voteCardPurchaseStatusID: 4
                        };
                        newData.VoteCardBalanceAmount =
                          oldData.VoteCardBalanceAmount -
                          newData.VoteCardDisburse1;
                        newData.VoteCardNumberStart =
                          oldData.VoteCardNumberStart +
                          newData.VoteCardDisburse1;

                        //voteCardPurchaseDetailID > Update Detail

                        addVoteCardAllocate(body)
                          .then(r => {
                            newData.DataKeyAllocate = r.voteCardAllocateId;

                            dataUpdate[index] = newData;
                            setData([...dataUpdate]);
                            // sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
                            // resolve(); 
                            // console.log("areaAllocate >>", areaAllocate)
                            // Noti

                            addVoteCardAllocateNoti({
                              provinceId: areaAllocate.ProvinceID,
                              isCenter: true,
                              header: "แจ้งเตือนการจัดสรรบัตร",
                              body:
                                "มีการจัดสรรบัตรเลือกตั้งให้กับ ตำแหน่ง " +
                                areaAllocate.PositionTypeName +
                                " ของ " +
                                areaAllocate.OptTypeName +
                                areaAllocate.OptName +
                                " จังหวัด " +
                                areaAllocate.ProvinceName +
                                " เรียบร้อยแล้ว",
                              redirectUrl: "/main/allocate",
                              systemTypeId: 5
                            }).then(result => {
                              //dataUpdate[index] = newData;
                              //setData([...dataUpdate]);
                              sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
                              resolve();
                            });
                            // sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
                            //resolve();
                          })
                          .catch(error => {
                            if (error.response) {
                              sweetAlerts.error(
                                "ไม่สามารถบันทึกข้อมูล : ",
                                error.response.data.desc
                              );
                            }
                          });
                      }
                      resolve();
                    }, 1000);
                  })
              }}
            />
          )}
          <hr></hr>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={closeModaladdCardallocate}>ปิดหน้าต่าง</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default Modalallocate;
