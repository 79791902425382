import React, { useEffect, useState } from "react";
import { editUser, getUserDetail } from "contexts/API/UserManagement/roles";

import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxProvince from "components/Common/ListboxProvince.js";
import ListboxTitle from "components/Common/ListboxTitle";
//import NumberFormat from "react-number-format";
import Slide from "@material-ui/core/Slide";
//import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
// Material Core
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModaleditUser = props => {
  const classes = useStyles();
  const { sweetAlerts } = useSweetAlert();
  const { showModal, closeModal, detail } = props;

  const [helpertexttitle, setHelpertexttitle] = useState("");
  const [helpertextfname, setHelpertextfname] = useState("");
  const [helpertextlname, setHelpertextlname] = useState("");
  const [helpertextProvince, setHelpertextProvince] = useState("");
  //const [helpertextidCard, setHelpertextidCard] = useState("");
  const [helpertextmail, setHelpertextmail] = useState("");
  const [helpertextUser, setHelpertextUser] = useState("");
  const [helpertexttel, setHelpertexttel] = useState("");
  //const [isIdCardValid, setIsIdCardValid] = useState(false);


  let _initialState = {
    userName: null,
    firstName: null,
    email: null,
    password: null,
    lastName: null,
    isCenter: null,

    titleId: { id: 0, value: "" },
    citizenId: null,
    telephone: null,
    msOptId: null,
    provinceId: { id: 0, value: "" }
  };

  const [data, setData] = useState(_initialState);

  const [, setProvinceId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (detail !== null) {

        const person = await getUserDetail(detail);
        const value = person.data.data[0];
        if (value) {
          setData({
            ...data,
            userName: value.userName,
            firstName: value.firstName,
            email: value.email,
            password: value.password,
            lastName: value.lastName,
            isCenter: value.isCenter,

            titleId: {
              id: value.titleId,
              value: value.titleDetail
            },
            citizenId: value.citizenId === null || value.citizenId === undefined ? '1088888888886' : value.citizenId,
            telephone: value.telephone,
            msOptId: value.msOptId,
            provinceId: {
              id: value.provinceId,
              value: value.provinceName
            }
          });
        } else {
          console.log("value null >>", value);
        }
      }
    };

    fetchData();
  }, [detail]);


  const onProvinceId = (evt, newValue) => {
    if (newValue.id !== 0) {
      setProvinceId(newValue);
      setData({ ...data, provinceId: newValue });
      setHelpertextProvince("");
    } else {
      setHelpertextProvince("กรุณาระบุ");
    }
  };
  const onTitleId = (evt, newValue) => {
    if (newValue.id !== 0) {
      setData({ ...data, titleId: newValue });
      setHelpertexttitle("");
    } else {
      setHelpertexttitle("กรุณาระบุคำนำหน้าชื่อ");
    }
  };

  const onOptSelected = (evt, newValue) => {
    setData({ ...data, msOptId: newValue.id });
  };
  const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };



  const submit = () => {
    if (data.provinceId.id === 0) {
      setHelpertextProvince("กรุณาระบุจังหวัด*");
    }
    if (data.userName === null) {
      setHelpertextUser("กรุณาระบุชื่อผู้ใช้งาน*");
    }
    if (!data.titleId) {
      setHelpertexttitle("กรุณาระบุคำนำหน้าชื่อ*");
    }
    if (!data.firstName) {
      setHelpertextfname("กรุณาระบุชื่อ*");
    }
    if (!data.lastName) {
      setHelpertextlname("กรุณาระบุนามสกุล*");
    }
    if (!data.telephone) {
      setHelpertexttel("กรุณาระบุเบอร์โทรศัพท์*");
    }
    if (!data.email) {
      setHelpertextmail("กรุณาระบุให้ถูกต้อง (abc@email.com)*");
    }
    /* if (!data.citizenId) {
      setHelpertextidCard("กรุณาระบุ*");
    } */

    if (
      data.firstName &&
      data.lastName &&
      data.email &&
      data.telephone &&
      data.provinceId &&
      data.userName &&
      data.provinceId &&
      data.citizenId &&
      //helpertextidCard === "" &&
      helpertextmail === ""
    ) {
      editUser(detail, data)
        .then(result => {
          if (result.data.success) {
            closeModal();
            sweetAlerts.success("แก้ไขข้อมูลเรียบร้อย");
            addSucess(result.data.success);
          }
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
        });
    } else {
      sweetAlerts.warning(`กรุณากรอกข้อมูลให้ครบถ้วน`);
    }
  };
  const addSucess = success => {
    props.onaddSuccess(success);
  };

  return (
    <div className={showModal ? "modal" : "hide"}>
      <Dialog
        open={showModal}
        fullWidth={true}
        maxWidth={"md"}
        transition={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <CardHeader color="warning">
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
              แก้ไขข้อมูลบัญชีผู้ใช้งาน
            </div>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <br></br>

          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="ชื่อผู้ใช้งาน"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.userName || "",
                  disabled: true,
                  autoComplete: "new-password",
                  onChange: e => {
                    if (e.target.value) {
                      setData({ ...data, userName: e.target.value });
                      setHelpertextUser("");
                    } else {
                      setData({
                        ...data,
                        userName: null
                      });
                      setHelpertextUser("กรุณาระบุ*");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextUser}
              </FormHelperText>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="อีเมล"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.email || "",

                  autoComplete: "new-password",
                  onChange: e => {
                    if (e.target.value) {
                      if (
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                          e.target.value
                        )
                      ) {
                        setData({ ...data, email: e.target.value });
                        setHelpertextmail("");
                      } else {
                        setData({ ...data, email: e.target.value });
                        setHelpertextmail(
                          "กรุณาระบุให้ถูกต้อง (abc@email.com)*"
                        );
                      }
                    } else {
                      setData({
                        ...data,
                        email: null
                      });
                      setHelpertextmail("กรุณาระบุให้ถูกต้อง (abc@email.com)*");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextmail}
              </FormHelperText>
            </GridItem>
          </GridContainer>

          <DialogTitle id="classic-modal-slide-title">
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
              ข้อมูลรายละเอียดผู้ใช้งาน
            </div>
          </DialogTitle>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxTitle
                value={data.titleId}
                onChange={onTitleId}
              ></ListboxTitle>
              <FormHelperText style={{ color: "red" }}>
                {helpertexttitle}
              </FormHelperText>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="ชื่อ"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.firstName || "",

                  autoComplete: "new-password",
                  onChange: e => {
                    if (e.target.value) {
                      setData({
                        ...data,
                        firstName: e.target.value
                      });
                    } else {
                      setData({
                        ...data,
                        firstName: null
                      });
                      setHelpertextfname("กรุณาระบุ");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertextfname}
              </FormHelperText>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="สกุล"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.lastName || "",

                  autoComplete: "new-password",

                  onChange: e => {
                    if (e.target.value) {
                      setData({
                        ...data,
                        lastName: e.target.value
                      });
                    } else {
                      setData({
                        ...data,
                        lastName: null
                      });
                      setHelpertextfname("กรุณาระบุ");
                    }
                  }
                }}
              />
              <p style={{ color: "red" }}>{helpertextlname}</p>
            </GridItem>

            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                default
                required
                labelText="เบอร์โทรศัพท์"
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  value: data.telephone || "",

                  autoComplete: "new-password",
                  onChange: e => {
                    if (verifyNumber(e.target.value)) {
                      setData({ ...data, telephone: e.target.value });
                      setHelpertexttel("");
                    } else {
                      setData({
                        ...data,
                        telephone: null
                      });
                      setHelpertexttel("กรุณาระบุ");
                    }
                  }
                }}
              />
              <FormHelperText style={{ color: "red" }}>
                {helpertexttel}
              </FormHelperText>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxProvince
                controll={true}
                value={data.provinceId}
                onChange={onProvinceId}
              ></ListboxProvince>
              <FormHelperText style={{ color: "red" }}>
                {helpertextProvince}
              </FormHelperText>
            </GridItem>

            <GridItem xs={6} sm={6} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.isCenter}
                    onClick={e => {
                      if (e.target.checked) {
                        setData({
                          ...data,
                          isCenter: true
                        });
                      } else {
                        setData({
                          ...data,
                          isCenter: false
                        });
                      }
                    }}
                    color="primary"
                  />
                }
                styles={{ paddingTop: "50px" }}
                label="สังกัดส่วนกลาง"
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxOpts
                defaultValue={{ id: data.msOptId }}
                provinceSelected={data.provinceId}
                onChange={onOptSelected}
              ></ListboxOpts>
            </GridItem>
            {/* <GridItem xs={6} sm={6} md={6}>
              <NumberFormat
                style={{
                  width: "100%",
                  margin: "0 0 17px 0",
                  paddingTop: "27px"
                }}
                value={data.citizenId}
                placeholder="เลขประจำตัวประชาชน"
                customInput={TextField}
                format="# #### ##### ## #"
                mask="X"
                fullWidth
                onValueChange={onIDCardHandler}
              />
              <span style={{ color: "red" }}>{helpertextidCard}</span>
            </GridItem> */}
          </GridContainer>

          <br></br>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <div>
              <Button onClick={closeModal} size="sm">
                ปิดหน้าต่าง
              </Button>
            </div>
            <div>
              <Button size="sm" color="success" onClick={submit}>
                บันทึกข้อมูล
              </Button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModaleditUser;
