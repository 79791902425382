import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
export function auth(user, pass) {
  const url = `${END_POINT_URL}/auth`;
  return axios.post(url, {
    user: user,
    password: pass
  });
}
export function changePassword(oldPassword, newPassword, userId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/um/changePassword/${userId}`;
  const body = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
