import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getuploadCmd(cmdApplyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/uploadCmd/list/cmdApplyDateId/${cmdApplyDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function delUploadCmd(uploadId, docTypeId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/uploadCmdFile/${uploadId}/docType/${docTypeId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function UploadCmdFile(candidate, fileUUID, cmdIds) {
  const isAuth = cookies.get("userInfo");
  const body = {
    cmdApplyDateId: candidate,

    fileUUID: fileUUID,
    cmdIds: cmdIds
  };
  console.log(body);
  const url = `${END_POINT_URL}/inspector/uploadCmdFile`;
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
