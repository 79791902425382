import React, { useContext, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectVoteDate from "components/Common/ListboxInspectVoteDate";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import { Paper } from "@material-ui/core";
import { getReport03 } from "contexts/API/INSPEC/Moniter";
import { useSweetAlert } from "components/Alert/SweetAlert";

const InspectorProvince = props => {
  const [data, setData] = useState([]);
  const { sweetAlerts } = useSweetAlert();
  const [loading, setLoading] = useState(false);
  const optionsTable = {};
  const { inspectVotedateSelected, onInspectVotedateSelected } = useContext(
    InspectorContext
  );
  const columns = [
    {
      title: "จังหวัด",
      field: "provinceName"
    },
    {
      title: "กลุ่มจังหวัด",
      field: "groupNo",
      cellStyle: {
        minWidth: "150px",
        alignItems: "center"
      }
    },
    {
      title: "จำนวน ผตล.จว.",
      field: "count",
      cellStyle: {
        minWidth: "150px",
        alignItems: "center"
      }
    }
  ];

  const handelSubmit = async () => {
    if (!inspectVotedateSelected) {
      sweetAlerts.warning("กรุณาเลือกวันเลือกตั้ง");
    } else {
      setLoading(true);
      try {
        const res = await getReport03(inspectVotedateSelected.id);
        setData(res.data.data);
        setLoading(false);
      } catch (error) {
        HandelError(error);
        setLoading(true);
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", error.toString());
      }
    }
  };
  return (
    <div>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <ListboxInspectVoteDate
                  required={true}
                  positionTypeId={null}
                  inspectVotedateSelected={inspectVotedateSelected}
                  onChange={onInspectVotedateSelected}
                ></ListboxInspectVoteDate>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}></GridItem>
            </GridContainer>
            <br /> <br />
            <GridContainer
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <GridItem xs={6} sm={6} md={6}></GridItem>
              <GridItem>
                <Button>ยกเลิก</Button>
                <Button color="info" onClick={handelSubmit}>
                  ค้นหา
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>

      <GridContainer>
        {loading ? (
          <LoadingData />
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <MaterialTable
              title={"สรุปข้อมูลผู้ตรวจการเลือกตั้งประจำจังหวัด"}
              options={optionsTable}
              columns={columns}
              data={data}
              components={{
                Container: props => <Paper {...props} elevation={0} />
              }}
            />
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
};
export default InspectorProvince;
