import {
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Edit,
  FirstPage,
  LastPage,
  Search
} from "@material-ui/icons";
import React, { forwardRef, useEffect, useState } from "react";
import {
  addVoteCardAllocate,
  deleteVoteCardAllocateByID
} from "contexts/API/VoteCard/Allocate";

import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import HandelError from "utils/HandleError";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TableComponent from "components/Table/MaterialTable";
import TextField from "@material-ui/core/TextField";
/* API */
import { getPurchaseDetailByPurchaseDetailIDLists } from "contexts/API/VoteCard/Purchase";
import { getSearchValidateByPurchaseDetailIDLists } from "contexts/API/VoteCard/Allocate";
import { loadCSS } from "fg-loadcss";
import { useSweetAlert } from "components/Alert/SweetAlert";

const VoteCardChild = props => {
  const { sweetAlerts } = useSweetAlert();
  const [numError, setNumError] = useState({
    error: false,

    helperText: "",
    validateInput: false
  });
  const [beginError, setBeginError] = useState({
    error: false,

    helperText: "",
    validateInput: false
  });
  const [data, setData] = useState([]);
  const [votePurchaseDetailID, setVotePurchaseDetailID] = useState(null);
  const [voteCardNumberStart, setVoteCardNumberStart] = useState(null);
  const [voteCardNumberEnd, setVoteCardNumberEnd] = useState(null);
  const [remark, setRemark] = useState("");
  const optionsTable = { exportButton: true, paging: true };

  useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    setVotePurchaseDetailID(props.showData.DataKey);
    setVoteCardNumberStart(props.showData.VoteCardNumberStart);
    setVoteCardNumberEnd(props.showData.VoteCardNumberEnd);
    getPurchaseDetailByPurchaseDetailIDLists(
      "?VotePurchaseDetailID=" + props.showData.DataKey
    )
      .then(result => {
        //typeof result.data.data

        setData(result.data.data);
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูล Dashboard ได้", err.toString());
      });

    //[DataKey]
    /*
    let tmpData = {
      oldBegin: props.showData.VoteCardNumberStart,
      oldEnd: props.showData.VoteCardNumberEnd,
      oldVol: props.showData.VoteCardNumberEnd - props.showData.VoteCardNumberStart,
      newBegin: props.showData.VoteCardNumberStart,
      newEnd: props.showData.VoteCardNumberEnd,
      newVol: props.showData.VoteCardNumberEnd - props.showData.VoteCardNumberStart,
      remark: props.showData.Remark,
    };

    setData([tmpData]);
    */
  }, [props.showData]);

  return (
    <Card>
      <TableComponent
        title="การปรับปรุงรายการบัตรเลือกตั้ง"
        columns={[
          {
            title: "เลขที่เริ่มต้น",
            field: "startVolumnNo",
            cellStyle: {
              width: "100px",
              textAlign: "center"
            },
            headerStyle: {
              width: "100px",
              textAlign: "center"
            },
            render: rowData => {
              return new Intl.NumberFormat().format(rowData.startVolumnNo);
            },
            editComponent: props => (
              <TextField
                type="number"
                error={beginError.error}
                label="เลขที่เริ่มต้น"
                helperText={beginError.helperText}
                value={props.value ? props.value : ""}
                onChange={e => props.onChange(e.target.value)}
              />
            )
          },

          {
            title: "จำนวนเล่ม",
            field: "volumnAllocate",
            cellStyle: {
              width: "100px",
              textAlign: "center"
            },
            headerStyle: {
              width: "100px",
              textAlign: "center"
            },
            render: rowData => {
              return new Intl.NumberFormat().format(rowData.volumnAllocate);
            },
            editComponent: props => (
              <TextField
                type="number"
                error={numError.error}
                id="standard-error"
                label="จำนวนเล่ม"
                helperText={numError.helperText}
                value={props.value ? props.value : ""}
                onChange={e =>
                  props.onRowDataChange({
                    ...props.rowData,
                    endVolumnNo:
                      parseInt(props.rowData.startVolumnNo) +
                      parseInt(e.target.value) -
                      1,
                    volumnAllocate: e.target.value
                  })
                }
              />
            )
          },
          {
            title: "เลขที่สิ้นสุด",
            field: "endVolumnNo",
            cellStyle: {
              width: "100px",
              textAlign: "center"
            },
            headerStyle: {
              width: "100px",
              textAlign: "center"
            },
            render: rowData => {
              return new Intl.NumberFormat().format(rowData.endVolumnNo);
            },
            editComponent: props => (
              <TextField
                type="number"
                error={beginError.error}
                label="เลขที่สิ้นสุด"
                InputProps={{
                  readOnly: true
                }}
                helperText={beginError.helperText}
                value={props.value ? props.value : ""}
                onChange={e => props.onChange(e.target.value)}
              />
            )
          },
          {
            title: "หมายเหตุ",
            field: "remark",
            cellStyle: {
              width: "300px",
              textAlign: "center"
            },
            headerStyle: {
              width: "300px",
              textAlign: "center"
            },
            render: rowData => {
              if (rowData.remark !== null) {
                const usingSplit = rowData.remark.split(";");

                return usingSplit[0];
              } else {
                return rowData.remark;
              }
            },
            editComponent: props => (
              <Select
                width={300}
                onChange={e => props.onChange(e.target.value)}
              >
                <MenuItem value="" selected>
                  เลือกหมายเหตุ
                </MenuItem>
                <MenuItem value="ชำรุดที่โรงพิมพ์">ชำรุดที่โรงพิมพ์</MenuItem>
                <MenuItem value="ชำรุดที่ท้องถิ่น">ชำรุดที่ท้องถิ่น</MenuItem>
                <MenuItem value="อื่น ๆ">อื่น ๆ</MenuItem>
              </Select>
            )
          }
        ]}
        icons={{
          Add: forwardRef((props, ref) => (
            <Button
              color="info"
              style={{
                color: "#fff",
                fontSize: 20,
                backgroundColor: "#43a047"
              }}
              variant="contained"
              size="small"
            >
              เพิ่มรายการ
            </Button>
          )),
          Delete: forwardRef((props, ref) => (
            <Button
              color="danger"
              disabled={console.log(ref)}
              style={{
                color: "#fff",
                fontSize: 20,
                backgroundColor: "#f44336"
              }}
              variant="contained"
              size="small"
            >
              ลบ
            </Button>
          )),
          Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
          Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
          ResetSearch: forwardRef((props, ref) => (
            <Clear {...props} ref={ref} />
          )),
          Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
          Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          FirstPage: forwardRef((props, ref) => (
            <FirstPage {...props} ref={ref} />
          )),
          LastPage: forwardRef((props, ref) => (
            <LastPage {...props} ref={ref} />
          )),
          NextPage: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
          )),
          PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
          ))
        }}
        data={data}
        localization={{}}
        options={optionsTable}
        editable={{
          isDeleteHidden: rowData => rowData.voteCardPurchaseDetailId != null,
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                //setData([...data, newData]);

                if (!newData.volumnAllocate) {
                  setNumError({
                    ...numError,
                    error: true,
                    label: "required",
                    helperText: "กรุณากรอกจำนวนเล่ม"
                  });

                  reject();

                  return;
                }
                if (!newData.startVolumnNo) {
                  setBeginError({
                    ...beginError,
                    error: true,
                    label: "required",
                    helperText: "กรุณาระบุเล่มที่เริ่มต้น"
                  });

                  reject();

                  return;
                }
                if (!newData.endVolumnNo) {
                  setBeginError({
                    ...beginError,
                    error: true,
                    label: "required",
                    helperText: "กรุณาระบุเล่มที่สิ้นสุด"
                  });

                  reject();

                  return;
                }

                /*
                console.log(voteCardNumberStart);
                console.log(newData.startVolumnNo);
                console.log(voteCardNumberEnd);
                console.log(newData.endVolumnNo);
                */

                if (
                  voteCardNumberStart > newData.startVolumnNo ||
                  newData.endVolumnNo > voteCardNumberEnd
                ) {
                  sweetAlerts.warning(
                    "ช่วงเลขที่เริ่มต้น หรือเลขที่สิ้นสุดไม่ถูกต้อง"
                  );

                  reject();
                  return;
                }

                if (
                  parseInt(newData.volumnAllocate) >
                  voteCardNumberEnd - voteCardNumberStart + 1
                ) {
                  sweetAlerts.warning(
                    "จำนวนบัตรที่จัดสรรต้องอยู่ในช่วงเลขที่เริ่มต้น หรือเลขที่สิ้นสุด"
                  );

                  reject();
                  return;
                }

                getSearchValidateByPurchaseDetailIDLists(
                  "?VotePurchaseDetailID=" +
                    props.showData.DataKey +
                    "&StartVolumnNo=" +
                    newData.startVolumnNo +
                    "&EndVolumnNo=" +
                    newData.endVolumnNo
                )
                  .then(result => {
                    if (result.data.data.length < 1) {
                      const voteCardAllocate = {
                        voteCardAllocateID: 1,
                        voteCardPurchaseDetailID: null,
                        voteCardPublishDetailID: votePurchaseDetailID,
                        volumnAllocate: newData.volumnAllocate,
                        startVolumnNo: newData.startVolumnNo,
                        endVolumnNo: newData.endVolumnNo,
                        remark: newData.remark,
                        oldVoteCardAllocateID: null,
                        isDelete: 0
                      };

                      addVoteCardAllocate(voteCardAllocate)
                        .then(result => {
                          sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");

                          getPurchaseDetailByPurchaseDetailIDLists(
                            "?VotePurchaseDetailID=" + props.showData.DataKey
                          )
                            .then(result => {
                              setData(result.data.data);
                              props.showUpdate(props.showData);
                            })
                            .catch(err => {
                              sweetAlerts.error(
                                "ไม่สามารถดึงข้อมูลได้",
                                err.toString()
                              );
                            });
                        })
                        .catch(err => {
                          HandelError(err);
                          sweetAlerts.error(
                            "ไม่สามารถบันทึกข้อมูลได้",
                            err.response.data.desc
                          );
                        });
                    } else {
                      sweetAlerts.warning(
                        "ไม่สามารถจัดสรรบัตรได้ เนื่องจากเลขที่เริ่มต้นหรือเลขที่สิ้นสุดมีการจัดสรรไปแล้ว"
                      );
                    }
                  })
                  .catch(err => {
                    sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", err.toString());
                  });

                resolve();
              }, 1000);
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if (oldData.voteCardPurchaseDetailId != null) {
                  sweetAlerts.warning(
                    "ไม่สามารถลบข้อมูลได้ เนื่องจากไม่ได้เป็นการจัดสรรบัตรด้วยตนเอง"
                  );
                  reject();
                  return;
                }

                const voteCardAllocate = {
                  voteCardAllocateID: oldData.voteCardAllocateId
                };

                deleteVoteCardAllocateByID(voteCardAllocate)
                  .then(result => {
                    sweetAlerts.success("ลบข้อมูลเรียบร้อย");

                    getPurchaseDetailByPurchaseDetailIDLists(
                      "?VotePurchaseDetailID=" + props.showData.DataKey
                    )
                      .then(result => {
                        setData(result.data.data);
                      })
                      .catch(err => {
                        sweetAlerts.error(
                          "ไม่สามารถดึงข้อมูลได้",
                          err.toString()
                        );
                      });
                  })
                  .catch(err => {
                    HandelError(err);
                    sweetAlerts.error(
                      "ไม่สามารถบันทึกข้อมูลได้",
                      err.response.data.desc
                    );
                  });
                resolve();
              }, 1000);
            })
        }}
      ></TableComponent>
    </Card>
  );
};
export default VoteCardChild;
