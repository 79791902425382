import React, { useState } from "react";

import FormManage61 from "./FormManage61";
import ImportFile61 from "./ImportFile61";
import { VoteDateContextProvider } from "contexts/VoteDate";

const SwitchComponents = ({ active, children }) => {
  return children.filter(child => child.props.name === active);
};

const Form61Index = props => {
  const [pageProps, setPageProps] = useState({
    active: "FormManage61",
    props: null
  });
  return (
    <VoteDateContextProvider active={pageProps.active}>
      {window.scrollTo(0, 0)}
      <SwitchComponents active={pageProps.active}>
        <FormManage61
          name="FormManage61"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <ImportFile61
          name="ImportFile61"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
      </SwitchComponents>
    </VoteDateContextProvider>
  );
};

export default Form61Index;
