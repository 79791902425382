import React, { useEffect, useState } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import LoadingData from "components/Common/LoadingData";
import PopUpAdd from "./PopUpAdd";
import PopUpList from "./PopUpList";
import { getAllPopup } from "contexts/API/CommonSetting/Notification";
import { useSweetAlert } from "components/Alert/SweetAlert";

const PopUp = props => {
  const [data, setData] = useState([]);
  const { sweetAlerts } = useSweetAlert();

  const [loading, setLoading] = useState(false);
  function getPopup() {
    getAllPopup()
      .then(result => {
        setLoading(true);
        setData(result.data.data);
        console.log(result.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
      });
  }
  useEffect(() => {
    getPopup();
  }, []);

  //const [projects, setProject] = useState(projectData);

  const addSuccess = project => {
    // data = [{ id: 0, value: "" }, ...data];
    // setData(data => project.concat(data));
    getPopup();
  };

  const delSuccess = project => {
    // project.id = projectData.length + 1;

    setData(project);
    // setProject([...projects, project]);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={4} md={4}>
        <PopUpAdd onaddSuccess={addSuccess} />
      </GridItem>
      <GridItem xs={12} sm={8} md={8}>
        {loading ? (
          <LoadingData />
        ) : (
          <PopUpList PopupData={data} onaddSuccess={delSuccess} />
        )}
      </GridItem>
    </GridContainer>
  );
};
export default PopUp;
