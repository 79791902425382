import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function addVoteCardAllocateManual(data) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/votecard/addallocatemanual`;
  //const body = {};
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
