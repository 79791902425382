import React, { useContext, useState } from "react";
import {
  editCadidate16,
  getCandidate16,
  unlockApply
} from "contexts/API/INSPEC/Selection16";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import Checkbox from "@material-ui/core/Checkbox";
import FileUpload4 from "./FileUpload4";
import FileUpload41 from "./FileUpload4_1";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectApplyDate from "components/Common/ListboxInspectApplyDate";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import { Paper } from "@material-ui/core";
import { REPORTING_SERVICE } from "config/env.conf";
import { UserInfoContext } from "contexts/users";
import { useSweetAlert } from "components/Alert/SweetAlert";

const Selection16People = props => {
  const {
    provinceSelected,
    onProvinceSelected,
    onInspectApplyDateSelected,
    inspectApplyDateSelected
  } = useContext(InspectorContext);
  const { userInfo } = useContext(UserInfoContext);
  const { sweetAlerts } = useSweetAlert();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [file4Name, setFile4Name] = useState(null);
  const [file4_1Name, setFile4_1Name] = useState(null);
  const [file4_1UUID, setFile4_1UUID] = useState(null);
  const [file4UUID, setFile4UUID] = useState(null);
  const [columns, setColumns] = useState([]);
  const [counter, setCounter] = useState(0);
  const optionsTable = { exportButton: false };

  const unlockCandidate = () => {
    sweetAlerts.warning(
      "ยืนยันการปลดล็อคแก้ไขผลการคัดเลือก 16 คน",

      "หลังจากปลดล็อคแล้ว ต้องการให้ทางจังหวัดนำส่งไฟล์ ผตล.4 ที่ลงนาม ผอ.กกต.จว แล้ว ดำเนินการส่งให้ส่วนกลางใหม่อีกครั้ง",
      () => {
        unlockApply(provinceSelected.id, inspectApplyDateSelected.id)
          .then(result => {
            if (result.data.success) {
              sweetAlerts.success("ปล็ดล็อคเรียบร้อย");
              setFile4_1UUID(null);
              setFile4UUID(null);
              setFile4Name(null);
              setFile4_1Name(null);

              //  setCounter(obj);
            }
          })
          .catch(err => {
            HandelError(err);
            sweetAlerts.error(
              "ไม่สามารถปล็ดล็อคข้อมูลได้",
              err.response.data.desc
            );
          });
      }
    );
  };
  const handelSubmit = () => {
    editCadidate16(data)
      .then(result => {
        sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");
        let obj = data.filter(o => o.isCandidates16 === true).length;

        setCounter(obj);
        //  setData([...dataUpdate]);
      })
      .catch(err => {
        HandelError(err);
        sweetAlerts.error("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
      });

    // sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
  };
  const file4Success = _fileName4 => {
    if (_fileName4) {
      //  console.log("_fileName4", _fileName4);
      //  setIsUploadFile4(true);
      setFile4Name(_fileName4);
    }
  };
  const file4_1Success = _fileName4_1 => {
    if (_fileName4_1) {
      //    console.log("_fileName4_1", _fileName4_1);
      setFile4_1Name(_fileName4_1);
      //setIsUploadFile4_1(true);
    }
  };
  const clickSearch = async () => {
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }

    if (inspectApplyDateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกช่วงวันที่สมัคร");
      return;
    } else {
      try {
        setLoading(true);
        const res = await getCandidate16(
          provinceSelected.id,
          inspectApplyDateSelected.id
        );
        let obj = res.data.data.cadidates.filter(o => o.isCandidates16 === true)
          .length;

        setCounter(obj);
        console.log("dd", res.data.data);
        setData(res.data.data.cadidates);
        setFile4_1UUID(res.data.data.file4_1UUID);
        setFile4UUID(res.data.data.file4UUID);
        setFile4Name(res.data.data.file4Name);
        setFile4_1Name(res.data.data.file4_1Name);
        setLoading(false);
        const columns = [
          {
            title: "เลขที่ใบสมัคร",
            field: "applyNo",
            cellStyle: {
              minWidth: "100px",
              textAlign: "center"
            }
          },
          {
            /* title: "ผ่านการคัดเลือก 16 คน", */
            title: "ผ่านการคัดเลือก ",
            field: "isCandidates16",
            cellStyle: {
              minWidth: "200px",
              textAlign: "center"
            },
            render: rowData => {
              return (
                <Checkbox
                  name="isCandidates16"
                  checked={rowData.isCandidates16}
                  onChange={e => {
                    let applyPerson = res.data.data.cadidates;

                    applyPerson[rowData.tableData.id][e.target.name] =
                      e.target.checked;
                    setData([...applyPerson]);
                  }}
                />
              );
            }
          },
          {
            title: "ชื่อ สกุล",
            field: "firstName",
            cellStyle: {
              minWidth: "200px"
            },
            render: rowData => {
              return (
                rowData.titleDetail +
                " " +
                rowData.firstName +
                " " +
                rowData.lastName
              );
            }
          },
          {
            title: "สถานะการสมัคร",
            field: "applyStatusDetail",
            cellStyle: {
              minWidth: "150px"
            }
          },

          {
            title: "ผลการตรวจสอบ",
            field: "",
            cellStyle: {
              minWidth: "150px"
            }
          }
        ];
        setColumns(columns);
      } catch (error) {
        HandelError(error);
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", error.toString());
      }
    }
  };

  const handleSubmitExport4 = e => {
    e.preventDefault();
    window.open(
      `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2finspector%2finspector_rpt12_5_Grouping&rs:Command=Render&rc:Parameters=Collapsed&province_id=${provinceSelected.id}&apply_date_id=${inspectApplyDateSelected.id}`,
      "_blank"
    );
  };

  const handleSubmitExport4_1 = e => {
    e.preventDefault();
    window.open(
      `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2finspector%2finspector_rpt12_6_Grouping&rs:Command=Render&rc:Parameters=Collapsed&province_id=${provinceSelected.id}&apply_date_id=${inspectApplyDateSelected.id}`,
      "_blank"
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="danger" text>
          <CardText color="danger">
            <h4>จัดการรายชื่อที่ผ่านการคัดเลือก16คน</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxProvince
                    required={true}
                    value={provinceSelected}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxInspectApplyDate
                    value={inspectApplyDateSelected}
                    required={true}
                    onChange={onInspectApplyDateSelected}
                  ></ListboxInspectApplyDate>
                </GridItem>
              </GridContainer>
              <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button>ยกเลิก</Button>
                  <Button color="info" onClick={clickSearch}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <GridItem xs={6} sm={6} md={6}>
                      {/*  <Button
                        color="warning"
                        disabled={
                          !(provinceSelected && inspectApplyDateSelected)
                        }
                        onClick={handleSubmitExport4}
                      >
                        พิมพ์ ผตล 4
                      </Button>
                      <Button
                        color="warning"
                        disabled={
                          !(provinceSelected && inspectApplyDateSelected)
                        }
                        onClick={handleSubmitExport4_1}
                      >
                        พิมพ์ ผตล 4/1
                      </Button>
                      <span style={{ paddingLeft: "20px" }}>
                        ขาดผู้ได้รับการคัดเลือกอีก
                        <span style={{ color: "red" }}> {16 - counter} </span>คน
                      </span> */}
                    </GridItem>
                  </GridContainer>
                </CardBody>

                <MaterialTable
                  /* title={"รายชื่อผู้ผ่านการคัดเลือก 16 คน"} */
                  title={"รายชื่อผู้ผ่านการคัดเลือก "}
                  options={optionsTable}
                  columns={columns}
                  data={data}
                  enableInitColumns={false}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />
                  }}
                />

                <CardBody>
                  <GridContainer
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <GridItem xs={6} sm={6} md={6}></GridItem>
                    <GridItem>
                      <Button
                        color="success"
                        disabled={
                          file4Name !== null && file4_1Name !== null
                            ? true
                            : false
                        }
                        onClick={handelSubmit}
                      >
                        {/* บันทึกผลการคัดเลือก 16 คน */}
                        บันทึกผลการคัดเลือก

                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {/*  {file4Name !== null && file4_1Name !== null ? (
            userInfo.iscenter && (
              <GridContainer
                direction="column"
                justify="space-between"
                alignItems="center"
              >
                <br></br> <br></br>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    disabled={
                      props.province === null && props.cadidates === null
                        ? true
                        : false
                    }
                    onClick={unlockCandidate}
                  >
                    ปดล็อคแก้ไขการคัดเลือก 16 คน
                  </Button>
                </GridItem>
              </GridContainer>
            )
          ) : (
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <FileUpload4
                  file4UUID={file4UUID}
                  file4Name={file4Name}
                  province={provinceSelected}
                  cadidates={inspectApplyDateSelected}
                  onaddSuccess={file4Success}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <FileUpload41
                  file4_1Name={file4_1Name}
                  file4_1UUID={file4_1UUID}
                  province={provinceSelected}
                  cadidates={inspectApplyDateSelected}
                  onaddSuccess4_1={file4_1Success}
                />
              </GridItem>
            </GridContainer>
          )} */}
        </>
      )}
    </div>
  );
};
export default Selection16People;
