import React, { useState } from "react";

import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FileUpload } from "components/Common/FileUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Slide from "@material-ui/core/Slide";
import { UploadCmdFile } from "contexts/API/INSPEC/Command";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
// Material Core
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalUpload = props => {
  const { sweetAlerts } = useSweetAlert();
  const [uploadFileName, setUploadFileName] = useState(null);
  const [fileUUID, setFileUUID] = useState(null);
  const { showModalUpload, closeModalUpload } = props;

  const classes = useStyles();

  const submitUpload = () => {
    // const keys_to_keep = ["cmdId"];
    var data = props.childDetail.data.map(function (value) {
      return {
        cmdId: value
      };
    });
    // console.log(data);
    // let data = props.childDetail.data.map(o =>
    //   keys_to_keep.reduce((acc, curr) => {
    //     if (o.fileUUID === null) {
    //       // console.log("curr", o.fileUUID);
    //       acc[curr] = o[curr];
    //       return acc;
    //     }
    //   }, {})
    // );

    // const jsonString = JSON.stringify(
    //   Object.assign({}, props.childDetail.data)
    // );
    // console.log(jsonString);

    //data = data.filter(o => o !== undefined);

    // console.log(props.childDetail.candidate);
    // console.log(data);
    // console.log(fileUUID);
    UploadCmdFile(props.childDetail.candidate, fileUUID, data)
      .then(result => {
        if (result.data.success) {
          fileSuccess(result.data.success);
          closeModalUpload();
          sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");
          //    onfile4Success()
        }
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
      });
  };
  const fileSuccess = command_upload_id => {
    props.onaddSuccess(command_upload_id);
  };

  return (
    <div className={showModalUpload ? "modal" : "hide"}>
      <Dialog
        open={showModalUpload}
        fullWidth={true}
        transition={Transition}
        keepMounted
        onClose={closeModalUpload}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          {/* <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            เพิ่มข้อมูลบัตรเลือกตั้ง
          </div> */}

          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            บันทึกไฟล์คำสั่งแต่งตั้งบัญชีรายชื่อผู้ตรวจการเลือกตั้ง
          </div>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <br></br>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FileUpload
                id="file-upload-1"
                label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                fileName={uploadFileName}
                remove
                onFileRemove={() => {
                  setUploadFileName("");
                }}
                url={fileUUID ? getFileDownloadUrl("inspect", fileUUID) : ""}
                download
                onFileHandler={(e, f, p) => {
                  uploadFile("inspect", f, p)
                    .then(r => {
                      const file = r.data.data;

                      sweetAlerts.success("แนบไฟล์เรียบร้อย");
                      setUploadFileName(file.fileName);
                      setFileUUID(file.fileId);
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }}
                fileType="application/pdf"
                btnName={<>Browse</>}
                btnProps={{
                  color: "primary"
                }}
              />
            </GridItem>
          </GridContainer>
          <br></br>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <div>
              <Button onClick={closeModalUpload} size="sm">
                ยกเลิก
              </Button>
            </div>
            <div>
              <Button size="sm" color="success" onClick={submitUpload}>
                บันทึก
              </Button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalUpload;
