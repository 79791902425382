export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// Helper methods
export const getPrettyDate = (date) => {
    let d = new Date(date);
    const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric"
    };
    return d.toLocaleDateString("th-TH", options);

    // date = date.split(' ')[0];
    // const newDate = date.split('-');
    // const month = months[0];
    // return `${month} ${newDate[2]}, ${newDate[0]}`;
}

// Remove the seconds from the time
export const getPrettyTime = (date) => {
    const time = date.split('T')[1].split(':');
    return `${time[0]}:${time[1]}`;
}