import React from "react";

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import InspectorProvince from "./InspectorProvince";
import SelectionInspector from "./SelectionInspector";
import StatInspectorofDay from "./StatInspectorofDay";

import { InspectorContextProvider } from "contexts/Inspector";

import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";

const styles = {
  textCenter: {
    textAlign: "center"
  },
  //tabRoot: { backgroundColor: "#0a6" },
  span: {
    backgroundColor: "#aa0",
    fontSize: "22px"
  }
};

const useStyles = makeStyles(styles);

const Index = props => {
  const classes = useStyles();

  return (
    <InspectorContextProvider>
      <div className={classes.tabcustom}>
        <form>
          <CustomTabs
            styles={customTabsStyles}
            headerColor="danger"
            tabs={[
              {
                tabName: "การคัดเลือกผู้ตรวจการเลือกตั้ง",
                tabContent: <SelectionInspector />
              },
              {
                tabName: "สถิติการรับสมัครผู้ตรวจการเลือกตั้ง",
                tabContent: <StatInspectorofDay />
              },
              {
                tabName: "ผู้ตรวจการเลือกตั้งประจำจังหวัด",
                tabContent: <InspectorProvince />
              }
            ]}
          />
        </form>
      </div>
    </InspectorContextProvider>
  );
};

export default Index;
