import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxVotcardNumber = props => {
  let printingId = props.votecardPrintingSelected
    ? props.votecardPrintingSelected.id
    : 0;
  let colorId = props.votecardColorSelected
    ? props.votecardColorSelected.id
    : 0;

  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL +
      `/voteCard/printingHouses/${printingId}/color/${colorId}/voteCardNumber`,
    [
      props.votecardPrintingSelected,
      props.votecardColorSelected
    ]
  );

  let content = (
    <TextField disabled label="เลือกชนิดหมายเลข" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return {
        id: `${v.numberId}`, value: `${v.number}`, key: `${v.voteCardDetailId}`
      };
    });
  }

  const onChangeHandler = (e, v) => {
    if (props.onChange !== undefined) {
      props.onChange(e, v);
    }
    const validators = props.validators;
    if (validators !== undefined && validators.length > 0) {
      let isError, helperText;
      for (let i = 0; i < validators.length; i++) {
        let validatorFunc = validators[i];
        [isError, helperText] = validatorFunc(v);
        if (isError) break;
      }
      setError(isError);
      setHelperText(helperText);
    }
  };
  if (data.length > 0) {
    content = (
      <Autocomplete
        disabled={props.disabled}
        id="listBoxVotecardNumber"
        label="เลือกชนิดหมายเลข"
        options={data}
        optionDatas={fetchData}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={onChangeHandler}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label="เลือกชนิดหมายเลข"
              margin="normal"
              error={error}
              fullWidth
              helperText={helperText}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }
  return content;
};
export default ListboxVotcardNumber;
