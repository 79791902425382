import Autocomplete from "@material-ui/lab/Autocomplete";
/* core components */
import React from "react";
//import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import TextField from "@material-ui/core/TextField";
import { Validate } from "utils/Validators/Validate";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxSubdistinct = props => {
  const [valid, setValid] = React.useState();
  let amphurId =
    props.amphurSelected &&
    props.amphurSelected.id &&
    props.amphurSelected.id !== undefined
      ? props.amphurSelected.id
      : 0;

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/ms/tambols?amphur_id=" + amphurId,
    [props.amphurSelected]
  );

  let content = (
    <TextField
      disabled
      label={props.title ? props.title : "เลือกตำบล"}
      margin="normal"
      fullWidth
    />
  );

  let data = [];

  if (
    amphurId !== 0 &&
    !isLoading &&
    fetchData !== null &&
    fetchData.data !== undefined
  ) {
    data = fetchData.data.map(v => {
      return { id: v.tambol_id, value: v.tambol_name };
    });

    if (props.controll) {
      data = [{ id: 0, value: "" }, ...data];
    }
    data = [{ id: 0, value: "" }, ...data];
    // data.push({ id: 0, value: "เลือกตำบล" });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        name="listboxSubdistinct"
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value && props.value.id === 0 ? data[0] : props.value}
        // value={props.value}
        options={data}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={props.onChange}
        renderInput={params => {
          return (
            <TextField
              {...params}
              inputProps={{
                autoComplete: "new-password",
                //   autoComplete: "off",
                ...params.inputProps
              }}
              label={props.title ? props.title : "เลือกตำบล"}
              //label="เลือกทุกตำบล"
              margin="normal"
              fullWidth
              error={valid}
              onChange={Validate(
                params.inputProps.value,
                props.required,
                setValid
              )}
              required={props.required}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }
  return content;
};
export default ListboxSubdistinct;
