import Home from "./Home";
import React from "react";
import { VoteDateContextProvider } from "contexts/VoteDate";

const HomeIndex = props => {
  // Local State
  //const [activeComponent, setActiveComponent] = useState("OptoutPostion");

  return (
    <VoteDateContextProvider>
      <Home />
    </VoteDateContextProvider>
  );
};

export default HomeIndex;
