import React from "react";
// core UserList
import UserInRolesList from "./UserInRolesList";
import { VoteDateContextProvider } from "contexts/VoteDate";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

//const styles = {};
//const useStyles = makeStyles(styles);

const UserInRolesIndex = props => {
  return (
    <div>
      <VoteDateContextProvider>
        <UserInRolesList />
      </VoteDateContextProvider>
    </div>
  );
};
export default UserInRolesIndex;
