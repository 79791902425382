import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
/* custom components */
import { Validate } from "utils/Validators/Validate";
import getDefaultValue from "utils/DefaultValue";

const ListboxMonth = props => {
  let content = (
    <TextField disabled label="เลือกทุกเดือน" margin="normal" fullWidth />
  );
  const [, setError] = React.useState(false);
  const [valid, setValid] = React.useState();
  const [helperText, setHelperText] = React.useState("");
  let monthLists = [
    // { id: null, name: "เลือกเดือน" },
    { id: 1, name: "มกราคม" },
    { id: 2, name: "กุมภาพันธ์" },
    { id: 3, name: "มีนาคม" },
    { id: 4, name: "เมษายน" },
    { id: 5, name: "พฤษภาคม" },
    { id: 6, name: "มิถุนายน" },
    { id: 7, name: "กรกฎาคม" },
    { id: 8, name: "สิงหาคม" },
    { id: 9, name: "กันยายน" },
    { id: 10, name: "ตุลาคม" },
    { id: 11, name: "พฤศจิกายน" },
    { id: 12, name: "ธันวาคม" }
  ];

  let data = [];
  data = monthLists.map(v => {
    return { id: v.id, value: v.name };
  });

  if (data.length > 0) {
    content = (
      <div>
        <Autocomplete
          disabled={props.disabled}
          name="month"
          options={data}
          defaultValue={getDefaultValue(data, props.defaultValue)}
          value={props.value && props.value.id === 0 ? data[0] : props.value}
          getOptionLabel={option => option.value}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={AutocompleteValidator(
            props.onChange,
            props.validators,
            setError,
            setHelperText
          )}
          renderInput={params => {
            return (
              <TextField
                {...params}
                label="เลือกทุกเดือน"
                margin="normal"
                error={valid}
                fullWidth
                helperText={helperText}
                inputProps={{
                  autoComplete: "new-password",
                  //   autoComplete: "off",
                  ...params.inputProps
                }}
                onChange={Validate(
                  params.inputProps.value,
                  props.required,
                  setValid
                )}
                required={props.required}
              />
            );
          }}
        />
      </div>
    );
  }
  return content;
};

export default ListboxMonth;
