import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getCitizenSuspend(citizenId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendPerson/cId/${citizenId}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getSuspendPerson(suspendPersonId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/${suspendPersonId}/suspendPerson`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addSuspendPerson(data) {
  console.log("data : >>", data);

  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendPerson`;
  const body = data;
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editSuspendPerson(suspendPersonId, body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendPerson/${suspendPersonId}`;
  // const body = {
  //   body
  // };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delSuspendPerson(suspendPersonId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendPerson/${suspendPersonId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getListSuspend(queryStr) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendListPerson${queryStr}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

//มาตราที่กระทำผิดของ ระงับ
export function addLawSuspend(suspendPersonId, suspendLawSectionId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendPerson/${suspendPersonId}/suspendlaw`;
  const body = {
    suspendLawSectionId: suspendLawSectionId
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editLawSuspend(
  suspendPersonId,
  suspendPersonLawId,
  suspendLawSectionId
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendPersonlaw/${suspendPersonLawId}`;
  const body = {
    suspendPersonId: suspendPersonId,
    suspendLawSectionId: suspendLawSectionId
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delLawSuspend(suspendPersonLawId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendPersonlaw/${suspendPersonLawId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

//มาตราที่กระทำผิดของ เพิกถอน
export function addLawRevoke(suspendPersonId, suspendLawSectionId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendPerson/${suspendPersonId}/revokelaw`;
  const body = {
    suspendLawSectionId: suspendLawSectionId
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editLawRevoke(
  suspendPersonId,
  suspendRevokeLawId,
  suspendLawSectionId
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendRevokelaw/${suspendRevokeLawId}`;
  const body = {
    suspendPersonId: suspendPersonId,
    suspendLawSectionId: suspendLawSectionId
  };
  console.log('suspendLawSectionId>',suspendLawSectionId)
  console.log('body>',body)
 

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delLawRevoke(suspendRevokeLawId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendRevokelaw/${suspendRevokeLawId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
