import React, { useEffect, useState } from "react";

import ConvertDate from "utils/ConvertDate";
import LoadingData from "components/Common/LoadingData";
import { Paper } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableComponent from "components/Table/MaterialTable";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getPurchaseDetailByOptLists } from "contexts/API/VoteCard/Purchase";
import { useSweetAlert } from "components/Alert/SweetAlert";

const PurChaseDetail = props => {
  const [loading, setLoading] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  const [dataPurchaseDetail, setDataPurchaseDetail] = useState([]);
  useEffect(() => {
    if (props.purchaseID) {
      const optQueryUrl = () => {
        let endPoint = "?";
        if (props.purchaseID !== null) {
          endPoint += `PurchaseID=${props.purchaseID.voteCardPurchaseID}`;
        }

        return endPoint;
      };
      setLoading(true);
      getPurchaseDetailByOptLists(optQueryUrl(), "")
        .then(result => {
          setDataPurchaseDetail(result.data.data);

          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
        });
    }
  }, [props.purchaseID]);

  const columnsPurchaseDetail = [
    {
      title: "อำเภอ",
      field: "amphurName",
      cellStyle: {
        textAlign: "center",
        minWidth: "120px"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "ชื่อ อปท.",
      field: "optName",
      cellStyle: {
        textAlign: "left",
        minWidth: "120px"
      },
      headerStyle: {
        textAlign: "left"
      }
    },
    {
      title: "วันที่เลือกตั้ง",
      field: "voteDate",
      cellStyle: {
        textAlign: "center",
        minWidth: "150px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.voteDate);
      }
    },
    {
      title: "จำนวนผู้สมัคร ผ.ถ.",
      field: "numberRegister1",
      cellStyle: {
        textAlign: "center",
        minWidth: "100px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberRegister1 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberRegister1);
      }
    },
    {
      title: "จำนวนผู้มีสิทธิ ผ.ถ.",
      field: "numberPersonVote1",
      cellStyle: {
        textAlign: "center",
        minWidth: "100px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberPersonVote1 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberPersonVote1);
      }
    },
    {
      title: "จำนวนเล่ม ผ.ถ.",
      field: "",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberVoteCard1 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberVoteCard1);
      }
    },
    {
      title: "จำนวนฉบับ ผ.ถ.",
      field: "numberVoteCard1",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberVoteCard1 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberVoteCard1 * 20);
      }
    },
    {
      title: "เขตเลือกตั้ง",
      field: "areaNo",
      cellStyle: {
        minWidth: "70px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      }
    },
    {
      title: "จำนวนผู้สมัคร ส.ถ.",
      field: "numberRegister2",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberRegister2 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberRegister2);
      }
    },
    {
      title: "จำนวนผู้มีสิทธิ ส.ถ.",
      field: "number_person_vote_2",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberPersonVote2 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberPersonVote2);
      }
    },
    {
      title: "จำนวนเล่ม ส.ถ.",
      field: "numberVoteCard2",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberVoteCard2 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberVoteCard2);
      }
    },
    {
      title: "จำนวนฉบับ ส.ถ.",
      field: "numberVoteCard2",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return rowData.numberVoteCard2 === 0
          ? ""
          : new Intl.NumberFormat().format(rowData.numberVoteCard2 * 20);
      }
    },
    {
      title: "วันที่ส่ง/รับบัตร",
      field: "receiveDatetime",
      cellStyle: {
        textAlign: "center",
        minWidth: "120px"
      },
      headerStyle: {
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.receiveDatetime);
      }
    },
    {
      title: "วิธีรับบัตร",
      field: "receiveTypeName",
      cellStyle: {
        textAlign: "center",
        minWidth: "150px"
      },
      headerStyle: {
        textAlign: "center"
      }
    }
  ];

  return loading ? (
    <LoadingData />
  ) : (
    <TableComponent
      title="รายการสั่งซื้อบัตรเลือกตั้งแต่ละ อปท. ที่บันทึกเข้าสู่ระบบ"
      columns={columnsPurchaseDetail}
      data={dataPurchaseDetail}
      localization={{}}
      options={{ exportButton: false, paging: false }}
      components={{
        Container: props => <Paper {...props} elevation={0} />,
        Header: props => {
          return (
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}></TableCell>
                <TableCell rowSpan={2}>อำเภอ</TableCell>
                <TableCell rowSpan={2}>ชื่อ อปท.</TableCell>
                <TableCell rowSpan={2}>วันเลือกตั้ง</TableCell>

                <TableCell colSpan={4} align="center">
                  ผ.ถ.
                </TableCell>
                <TableCell rowSpan={2}>เขตเลือกตั้ง</TableCell>
                <TableCell colSpan={4} align="center">
                  ส.ถ.
                </TableCell>
                <TableCell rowSpan={2}>วันที่ส่ง/รับบัตร</TableCell>
                <TableCell rowSpan={2}>วิธีการรับบัตร</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">จำนวนผู้สมัคร</TableCell>
                <TableCell align="center">จำนวนผู้มีสิทธิ</TableCell>
                <TableCell align="center">จำนวนเล่ม</TableCell>
                <TableCell align="center">จำนวนฉบับ</TableCell>
                <TableCell align="center">จำนวนผู้สมัคร</TableCell>
                <TableCell align="center">จำนวนผู้มีสิทธิ</TableCell>
                <TableCell align="center">จำนวนเล่ม</TableCell>
                <TableCell align="center">จำนวนฉบับ</TableCell>
              </TableRow>
            </TableHead>
          );
        }
      }}
      //  onRowUpdate={onRowUpdate}
    ></TableComponent>
  );
};

export default PurChaseDetail;
