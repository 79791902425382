import "moment/locale/th";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "material-ui-thai-datepickers";

import FormControl from "@material-ui/core/FormControl";
import MomentUtils from "@date-io/moment";
/* core components */
import React from "react";

const Datepicker = props => {
  // let minDate = new Date();
  const customVoteDate = old => {
    let d = new Date(old);

    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric"
    };
    return d.toLocaleDateString("th-TH", options);
  };

  return (
    <FormControl fullWidth>
      <MuiPickersUtilsProvider locale="th" utils={MomentUtils}>
        <KeyboardDatePicker
          minDate={props.minDate}
          margin="normal"
          disabled={props.disabled}
          id={props.id}
          label={props.label}
          labelFunc={date => (date ? customVoteDate(date) : "")}
          format="ddd, D MMM YYYY"
          pickerHeaderFormat="ddd D MMM"
          yearOffset={543}
          error={props.valid}
          value={props.value}
          onChange={props.onChange}
          TextFieldComponent={props.ref}
          inputProps={{
            autoComplete: "off"
          }}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          {...props.customProps}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};
export default Datepicker;
