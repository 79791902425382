import {insertNotificationList, insertPopupList, markReadNotification, markReadPopup, setEnablePopup} from "./actions";
import {createReducer} from "@reduxjs/toolkit";
import {Map} from 'immutable';
import {actions} from "react-bootstrap-sweetalert/dist/styles/SweetAlertStyles";

const initState = {
    notifications: [],
    notificationsCountUnread: 0,
    notificationsCountRead: 0,

    popups: [],
    isEnabledPopup: true,
    popupsCountUnread: 0,
    popupsCountRead: 0,
}

const countRead = (l) => {
    return l.reduce((acc, cur) => {
        return acc + ((cur.isRead !== false) ? 1 : 0);
    }, 0)
}

const countUnRead = (l) => {
    return l.reduce((acc, cur) => {
        return acc + ((cur.isRead === false) ? 1 : 0);
    }, 0)
}


export const notificationsReducer = createReducer(initState, (builder) => {
    builder.addCase(markReadNotification, (state, action) => {
        let newState = {...state}
        const id = action.payload;

        const index = state.notifications.findIndex((v => v.id == id))

        newState.notifications = [
            ...state.notifications.slice(0, index),
            { ...state.notifications[index], isRead: true},
            ...state.notifications.slice(index + 1),
        ]

        newState.notificationsCountUnread = countUnRead(newState.notifications );
        newState.notificationsCountRead = countRead(newState.notifications);

        return newState;
    })
        .addCase(insertNotificationList, (state, action) => {
            let newState = Map(state)
            newState = newState.set('notifications', action.payload);
            newState = newState.set('notificationsCountUnread', countUnRead(action.payload));
            newState = newState.set('notificationsCountRead', countRead(action.payload));
            return newState.toJS();
        })
})

export const popupsReducer = createReducer(initState, (builder => {
    builder.addCase(markReadPopup, (state, action) => {
        let newState = Map(state);
        const id = action.payload;
        const index = newState.popups.findIndex((v => v.id == id))

        newState = newState.get('popups')[index].isRead = true;
        newState = newState.set('popupsCountUnread', countUnRead(newState.popups));
        newState = newState.set('popupsCountRead',  countRead(newState.popups));

        return newState.toJS();
    })
        .addCase(insertPopupList, (state, action) => {
            let newState = Map(state)
            console.log('default state', newState.toJS())

            newState = newState.set('popups', action.payload);
            newState = newState.set('popupsCountUnread', countUnRead(action.payload));
            newState = newState.set('popupsCountRead', countRead(action.payload));

            return newState.toJS();
        })
        .addCase(setEnablePopup, (state, action) => {
            let newState = Map(state)
            newState = newState.set('isEnabledPopup', action.payload);
            return newState.toJS();
        })
}))