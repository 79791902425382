import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getFormLists(
  optSelected,
  votedateSelected,
  positionTypeSelected
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optSelected.id}/voteDate/${votedateSelected.id}/positionTypeId/${positionTypeSelected.id}/formList`;
  // const url =
  //   "http://ectpl-be.scd-dga.com/api/v1/opt/1643/voteDate/2013-10-20/positionTypeId/1/formList";
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getListperson(query) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}${query}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function deletedOptForm(optForm6Id, positionTypeId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/optForm/${optForm6Id}/positionTypeId/${positionTypeId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editOptForm(
  children,
  optVoteTypeSelected,
  form6TypeSelected,
  sinceDate,
  noticeDate
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/optForm/${children.optForm6Id}/updateOptForm`;
  //opt/1643/voteDate/2013-10-20/positionTypeId/2/addOptForm
  console.log(url);
  const body = {
    optVoteId: children.optVoteId,
    //  optFormId: children.optForm6Id,
    positionTypeId: children.positionTypeId,
    optFormType: children.frm6typeId,
    voteTypeId: optVoteTypeSelected.id,
    sinceDate: sinceDate,
    noticeDate: noticeDate
  };

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addOptForm(
  positionTypeSelected,
  votedateSelected,
  optSelected,
  form6TypeSelected,
  optVoteTypeSelected,
  sinceDate,
  noticeDate,
  optPersonMem,
  optPersonPres,
  optTypeId
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optSelected.id}/voteDate/${votedateSelected.id}/positionTypeId/${positionTypeSelected.id}/addOptForm`;
  //opt/1643/voteDate/2013-10-20/positionTypeId/2/addOptForm

  //console.log("optTypeSelected >>", optTypeId)
  const body = {
    optFormType: form6TypeSelected.id,
    voteTypeId: optVoteTypeSelected.id,
    optTypeId: optTypeId,
    //sinceDate: sinceDate,
    noticeDate: noticeDate,
    optPersonMem: optPersonMem,
    optPersonPres: optPersonPres
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function uploadFile6_1(optFormId, fileUUID) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/optForm/${optFormId}/uploadFile`;
  //opt/1643/voteDate/2013-10-20/positionTypeId/2/addOptForm
  const body = {
    fileUUID: fileUUID
  };

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
