/* core components */
import React, { createRef, useContext, useEffect, useState } from "react";

import AddNewVoteDate from "contexts/API/VoteDate/VoteDate";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import Checkbox from "@material-ui/core/Checkbox";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import Datepicker from "components/Common/Datepicker";
import { FileUpload } from "components/Common/FileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListBoxDate from "components/Common/ListboxDate";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxPositionStatus from "components/Common/ListboxPositionStatus";
import ListboxProvince from "components/Common/ListboxProvince";
import TabelEditVotedate from "components/OptComponent/TableEditVotedate";
/* custom components */
import TableAreaVotedate from "components/OptComponent/TableAreaVotedate";
import TablePresidentNewVote from "components/OptComponent/TablePresidentNewVote";
import { VoteDateContext } from "contexts/VoteDate";
import { editVoteDate } from "contexts/API/VoteDate/VoteDate";
import { getAreaVoteLists } from "contexts/API/OPT/OptArea";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

const EditVoteDate = ({ pageProps, setPageProps }) => {
  const [voteDateSeleted, setVoteDateSeleted] = useState(
    pageProps.props.voteDate
  );
  const [optVoteTypeSelected, setOptVoteTypeSelected] = useState(
    pageProps.props.optVoteTypeSelected
  );
  const [voteDateIdSeleted] = useState(
    pageProps.props.optVoteId
  );

  const [optIdSelected] = useState(pageProps.props.optId);
  const [dataTable, setDataTable] = useState([]);
  const { sweetAlerts } = useSweetAlert();

  const {
    optTypeSelected,

    optSelected
  } = useContext(VoteDateContext);

  let _initPresState = {
    fileUUID: null,
    fileName: null,
    presChecked: false,
    presEffectedDate: null,
    presReplaceDate: null,
    presPositionStatusSelected: null,
    presVoteStatusSelected: null,
    presUrlFile: null,
    presreplaceDetail: null,
    presOptVoteId: null
  };
  let _initMemState = {
    fileUUID: null,
    fileName: null,
    memChecked: false,
    memEffectedDate: null,
    memReplaceDate: null,
    memPositionStatusSelected: null,
    memVoteStatusSelected: null,
    memUrlFile: null,
    memreplaceDetail: null,
    memOptVoteId: null
  };

  const [initPresState, setInitPresState] = useState(_initPresState);
  const [initMemState, setInitMemState] = useState(_initMemState);

  useEffect(() => {
    if (pageProps.props["pres"] !== undefined) {
      let pres = pageProps.props["pres"];
      setInitPresState({
        fileUUID: pres.fileUUID,
        fileName: pres.fileName,

        presChecked: true,
        presOptVoteId: pres.optVoteId,
        presReplaceDate: pres.replaceDate,
        presPositionStatusSelected: pres.replaceStatusId,
        presVoteStatusSelected: { id: pres.optVoteStatusId },

        presreplaceDetail: pres.replaceDetail
      });
      pres.fileUUID
        ? setPresUrlFile(getFileDownloadUrl("opt", pres.fileUUID))
        : setPresUrlFile("");
    }
    if (pageProps.props["mem"] !== undefined) {
      let mem = pageProps.props["mem"];
      setInitMemState({
        fileUUID: mem.fileUUID,
        fileName: mem.fileName,
        memChecked: true,
        memOptVoteId: mem.optVoteId,
        memReplaceDate: mem.replaceDate,
        memPositionStatusSelected: mem.replaceStatusId,
        memVoteStatusSelected: { id: mem.optVoteStatusId },

        memreplaceDetail: mem.replaceDetail
      });
      mem.fileUUID
        ? setMemUrlFile(getFileDownloadUrl("opt", mem.fileUUID))
        : setMemUrlFile("");
    }
  }, [pageProps.props]);

  const [memUrlFile, setMemUrlFile] = useState();
  const [presUrlFile, setPresUrlFile] = useState();

  // Member state and ref
  const memCheckedRef = createRef();

  const [, setMemChecked] = useState(initMemState.memChecked);
  // const [memEffectedDate, setMemEffectedDate] = useState(
  //   initMemState.memEffectedDate
  // );
  // const [, setMemPositionStatusSelected] = useState(
  //   initMemState.memPositionStatusSelected
  // );

  // Memberr Handler
  const onMemChecked = e => {
    setMemChecked(e.target.checked);
  };

  const saveHandler = () => {
    const body = { optVoteTypeId: optVoteTypeSelected.id };
    if (initPresState.presChecked === true) {
      if (!initPresState.presPositionStatusSelected) {
        sweetAlerts.warning("กรุณาระบุสาเหตุการจัดเลือกตั้ง ของ ผ.ถ.");
        return;
      }
      if (!initPresState.presReplaceDate) {
        sweetAlerts.warning("กรุณาระบุวันที่ของสาเหตุจัดเลือกตั้ง ของ ผ.ถ.");
        return;
      } else {
        body.localAdministrator = {
          fileUUID: initPresState.fileUUID,
          replaceDate: initPresState.presReplaceDate,
          optVoteId: initPresState.presOptVoteId,
          optVoteStatusId: 2,
          generalOptVoteId:
            pageProps.props.generalOptVoteId !== null
              ? pageProps.props.generalOptVoteId
              : null,
          replaceStatusId: initPresState.presPositionStatusSelected,
          verifyStatusId: 1,
          replaceRemark: "-",
          remark: "-",
          replaceDetail: "",
          startEffectiveDate: voteDateSeleted.split("T")[0],
          voteDate: voteDateSeleted.split("T")[0]
        };
      }
    } else if (pageProps.props["pres"] !== undefined && initPresState.presChecked === false) {
      body.localAdministrator = {
        action: "del",
        fileUUID: initPresState.fileUUID,
        replaceDate: initPresState.presReplaceDate,
        optVoteId: initPresState.presOptVoteId,
        optVoteStatusId: 2,
        generalOptVoteId:
          pageProps.props.generalOptVoteId !== null
            ? pageProps.props.generalOptVoteId
            : null,
        replaceStatusId: initPresState.presPositionStatusSelected,
        verifyStatusId: 1,
        replaceRemark: "-",
        remark: "-",
        replaceDetail: "",
        startEffectiveDate: voteDateSeleted.split("T")[0],
        voteDate: voteDateSeleted.split("T")[0]
      };
    }
    console.log("pageProps.props >>", pageProps.props);
    console.log("pageProps.props.memChecked >>", pageProps.props);
    console.log("initMemState.memChecked >>", initMemState.memChecked);

    if (initMemState.memChecked === true) {
      if (
        !initMemState.memPositionStatusSelected &&
        optVoteTypeSelected.id !== 2
      ) {
        sweetAlerts.warning("กรุณาระบุสาเหตุการจัดเลือกตั้ง ของ ส.ถ.");
        return;
      }
      if (!initMemState.memReplaceDate && optVoteTypeSelected.id !== 2) {
        sweetAlerts.warning("กรุณาระบุวันที่ของสาเหตุจัดเลือกตั้ง ของ ส.ถ.");
        return;
      } else {
        body.localCouncilor = {
          fileUUID: initMemState.fileUUID,
          replaceDate: initMemState.memReplaceDate,
          optVoteId: initMemState.memOptVoteId,
          replaceStatusId: initMemState.memPositionStatusSelected,
          optVoteStatusId: 2,
          generalOptVoteId:
            pageProps.props.generalOptVoteId !== null
              ? pageProps.props.generalOptVoteId
              : null,
          verifyStatusId: 1,
          replaceRemark: "-",
          remark: "-",
          replaceDetail: "",
          startEffectiveDate: voteDateSeleted.split("T")[0],
          voteDate: voteDateSeleted.split("T")[0]
        };
        if (pageProps.props["mem"] === undefined) {
          body.localCouncilor.action = "add";
        }
      }
    } else if (pageProps.props["mem"] !== undefined && initMemState.memChecked === false) {
      //console.log("pageProps.props.memChecked >>", pageProps.props);
      //console.log("initMemState.memChecked >>", initMemState.memChecked);
      body.localCouncilor = {
        action: "del",
        fileUUID: initMemState.fileUUID,
        replaceDate: initMemState.memReplaceDate,
        optVoteId: initMemState.memOptVoteId,
        replaceStatusId: initMemState.memPositionStatusSelected,
        optVoteStatusId: 2,
        generalOptVoteId:
          pageProps.props.generalOptVoteId !== null
            ? pageProps.props.generalOptVoteId
            : null,
        verifyStatusId: 1,
        replaceRemark: "-",
        remark: "-",
        replaceDetail: "",
        startEffectiveDate: voteDateSeleted.split("T")[0],
        voteDate: voteDateSeleted.split("T")[0]
      };
      //console.log("else delete>>", body)
    }
    // console.log("localAdministrator", body.localAdministrator);
    // console.log("localCouncilor", body.localCouncilor);
    if (
      body.localCouncilor !== undefined &&
      body.localCouncilor.optVoteId === null
    ) {
      const _body = {
        optVoteTypeId: optVoteTypeSelected.id,
        localCouncilor: body.localCouncilor
      };

      addVote(_body);
    } else if (
      body.localAdministrator !== undefined &&
      body.localAdministrator.optVoteId === null
    ) {
      const _body = {
        optVoteTypeId: optVoteTypeSelected.id,
        localAdministrator: body.localAdministrator
      };
      addVote(_body);
    } else {
      editVote(body);
    }
  };

  const addVote = body => {
    console.log("body>>", body);
    AddNewVoteDate(optIdSelected, voteDateSeleted.split("T")[0], body)
      .then(r => {
        //console.log("333 1>>>", body.localCouncilor);
        //console.log("333 2>>>", body.localAdministrator);

        if (body.localCouncilor !== undefined) {
          //if (body.localCouncilor === null) {
          body.localCouncilor.optVoteId = r.data.data.localCouncilor.optVoteId;
          if (body.localCouncilor.optVoteId) {
            editVote(body);
          }
        }
        if (body.localAdministrator !== undefined) {
          //if (body.localAdministrator === null) {
          body.localAdministrator.optVoteId =
            r.data.data.localAdministrator.optVoteId;
          if (body.localAdministrator.optVoteId) {
            editVote(body);
          }
        }

        // setOptVoteId(r.response.data.data.localCouncilor.optVoteId);
        // setSuccess(true);
        sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
        // setPageProps({ active: "MangeVoteDate", props: "addsuccess" });
      })
      .catch(error => {
        console.log("error.response >>", error.response)
        if (error.response) {
          sweetAlerts.error(
            "ไม่สามารถบันทึกวันเลือกตั้ง: ",
            error.response.data.desc
          );
        }
      });
  };

  const editVote = body => {
    console.log("body_editVote>>>", body);
    editVoteDate(optIdSelected, voteDateSeleted.split("T")[0], body)
      .then(r => {
        sweetAlerts.success("บันทึกข้อมูลสำเร็จ");
        //Surachai.K 2023-05-25
        //Request from DGA incase of save success no need redirect to main page
        //setPageProps({ active: "MangeVoteDate", action: "editsuccess" });
      })
      .catch(error => {
        if (error.response) {
          sweetAlerts.error(
            "ไม่สามารถบันทึกวันเลือกตั้ง: ",
            error.response.data.desc
          );
        }
      });
  };
  const cancelHandler = () => {
    setPageProps({ active: "MangeVoteDate", action: "cancel" });
  };

  const onOptVoteTypeSelected = (event, newValue) => {
    setOptVoteTypeSelected(newValue);
  };

  const useStyles = makeStyles({
    buttoncustom: {
      fontSize: 14,
      fontWeight: 500
      //color: props => props.color
    }
  });

  const classes = useStyles();

  useEffect(() => {
    if (optIdSelected !== null) {
      console.log("getAllOptAreaByOptId : ");
      getAreaVoteLists(optIdSelected, voteDateSeleted.split("T")[0], null)
        .then(r => {
          if (r.data.data[0] !== undefined) {
            setDataTable(r.data.data[0].opt_area);
          } else {
            setDataTable([]);
          }
        })
        .catch(err => {
          console.log("error while fetching OPT area", err);
        });
    }
  }, [optVoteTypeSelected, optIdSelected, voteDateSeleted]);

  return (
    <>
      <Card>
        <CardHeader color="primary" text>
          <CardText color="primary">
            <h4 className={classes.cardTitle}>แก้ไขวันเลือกตั้ง</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {/* first section */}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CheckboxOpttype
                    optTypeSelected={optTypeSelected}
                    onOptTypeSelected={null}
                  ></CheckboxOpttype>
                </GridItem>
              </GridContainer>

              {/* second section */}
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxOptVotetype
                    disabled
                    defaultValue={pageProps.props.optVoteTypeSelected}
                    onChange={onOptVoteTypeSelected}
                  ></ListboxOptVotetype>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxProvince
                    disabled
                    defaultValue={{
                      id: pageProps.props.provinceSelected
                    }}
                    onchange={null}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxDistinct
                    disabled
                    provinceSelected={{ id: pageProps.props.provinceSelected }}
                    defaultValue={{ id: pageProps.props.amphurSelected }}
                  ></ListboxDistinct>
                </GridItem>
              </GridContainer>

              {/* third section */}
              <GridContainer>
                {/* <GridItem xs={12} sm={12} md={4}>
                  <ListboxSubdistinct
                    disabled
                    amphurSelected={{ id: pageProps.props.amphurSelected }}
                    defaultValue={{ id: pageProps.props.tambolSelected }}
                    onChange={null}
                  ></ListboxSubdistinct>
                </GridItem> */}
                <GridItem xs={12} sm={12} md={4}>
                  <ListboxOpts
                    disabled
                    defaultValue={{
                      id: pageProps.props.optId
                    }}
                    // value={optSelected}
                    listboxOptSelected={{
                      id: pageProps.props.listboxOptSelected
                    }}
                    optTypeSelected={optTypeSelected}
                    provinceSelected={{ id: pageProps.props.provinceSelected }}
                  ></ListboxOpts>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  {optVoteTypeSelected !== null &&
                    optVoteTypeSelected !== undefined &&
                    (optVoteTypeSelected.id !== 1 && optVoteTypeSelected.id !== 4) ? (
                    <ListBoxDate
                      disabled
                      mode={"Edit"}
                      defaultValue={{ id: pageProps.props.generalOptVoteId }}
                      optSelected={{ id: pageProps.props.optId }}
                      optVoteTypeSelected={{ id: pageProps.props.optVoteTypeSelected }}
                    />
                  ) : null}
                  <Datepicker
                    disabled
                    id="voteDate"
                    label="วันเลือกตั้ง"
                    value={voteDateSeleted}
                    onChange={date => {
                      setVoteDateSeleted(date.format());
                      // let arr = date.format().split("T");
                      //    setVoteDateSeleted(arr[0]);
                    }}
                  ></Datepicker>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {(optVoteTypeSelected.id === 2 || optVoteTypeSelected.id === 4) ? (
      //{(optVoteTypeSelected.id === 2) ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <h4 className={classes.cardTitle}>
                  รายละเอียดจัดเลือกตั้งของ ส.ถ.
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="postionTypeId"
                          onClick={e => {
                            if (e.target.checked) {
                              setInitMemState({
                                ...initMemState,
                                memChecked: true
                              });
                            } else {
                              setInitMemState({
                                ...initMemState,
                                memChecked: false
                              });
                            }
                          }}
                          onChange={onMemChecked}
                          checked={initMemState.memChecked}
                          inputRef={memCheckedRef}
                        />
                      }
                      label="เลือก ส.ถ."
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <FileUpload
                      disabled={!initMemState.memChecked}
                      id="file-upload-1"
                      label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                      remove
                      download
                      fileName={initMemState.fileName}
                      url={memUrlFile}
                      onFileRemove={() => {
                        setMemUrlFile("");
                        setInitMemState({
                          ...initPresState,

                          fileName: ""
                        });
                      }}
                      onFileHandler={(e, f, progressCb) => {
                        uploadFile("opt", f, progressCb)
                          .then(r => {
                            const data = r.data.data;

                            if (data.fileId) {
                              setMemUrlFile(
                                getFileDownloadUrl("opt", data.fileId)
                              );

                              setInitMemState({
                                ...initMemState,
                                fileUUID: data.fileId,
                                fileName: data.fileName
                              });
                            }

                            sweetAlerts.success("แนบไฟล์เรียบร้อย");
                          })
                          .catch(err => {
                            console.log(err);
                          });
                      }}
                      fileType="application/pdf"
                      btnName={<>Browse</>}
                      btnProps={{
                        color: "primary"
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            {/* Presidennt Detail Vote date */}
            <Card>
              <CardHeader>
                <h4 className={classes.cardTitle}>
                  รายละเอียดจัดเลือกตั้งของ ผ.ถ.
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="postionTypeId"
                          onClick={e => {
                            if (e.target.checked) {
                              setInitPresState({
                                ...initPresState,
                                presChecked: true
                              });
                            } else {
                              setInitPresState({
                                ...initPresState,
                                presChecked: false
                              });
                            }
                          }}
                          checked={initPresState.presChecked}
                        />
                      }
                      label="เลือก ผ.ถ."
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <ListboxPositionStatus
                      disabled={!initPresState.presChecked}
                      name="presidentPositionStatus"
                      defaultValue={{
                        id: initPresState.presPositionStatusSelected
                      }}
                      onChange={(evt, newValue) => {
                        setInitPresState({
                          ...initPresState,
                          presPositionStatusSelected: newValue.id
                        });
                      }}
                    ></ListboxPositionStatus>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Datepicker
                      disabled={!initPresState.presChecked}
                      id="date-picker-president"
                      label="วันที่"
                      value={initPresState.presReplaceDate}
                      onChange={date => {
                        setInitPresState({
                          ...initPresState,
                          presReplaceDate: date.format().split("T")[0]
                        });
                      }}
                    //onChange={onPresEffectedDate}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FileUpload
                      disabled={!initPresState.presChecked}
                      id="file-upload-2"
                      label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                      remove
                      download
                      fileName={initPresState.fileName}
                      url={presUrlFile}
                      onFileRemove={() => {
                        setPresUrlFile("");
                        setInitPresState({
                          ...initPresState,

                          fileName: ""
                        });
                      }}
                      onFileHandler={(e, f, progressCb) => {
                        uploadFile("opt", f, progressCb)
                          .then(r => {
                            const data = r.data.data;

                            if (data.fileId) {
                              setPresUrlFile(
                                getFileDownloadUrl("opt", data.fileId)
                              );

                              setInitPresState({
                                ...initPresState,
                                fileUUID: data.fileId,
                                fileName: data.fileName
                              });
                            }

                            sweetAlerts.success("แนบไฟล์เรียบร้อย");
                          })
                          .catch(err => {
                            console.log(err);
                          });
                      }}
                      fileType="application/pdf"
                      btnName={<>Browse</>}
                      btnProps={{
                        color: "primary"
                      }}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="หมายเหตุ"
                      id="reasonPresident"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        inputRef: presRemarkRef
                      }}
                    />
                  </GridItem> */}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            {/* Member Detail Vote date */}
            <Card>
              <CardHeader>
                <h4 className={classes.cardTitle}>
                  รายละเอียดจัดเลือกตั้งของ ส.ถ.
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="postionTypeId"
                          checked={initMemState.memChecked}
                          onClick={e => {
                            if (e.target.checked) {
                              setInitMemState({
                                ...initMemState,
                                memChecked: true
                              });
                            } else {
                              setInitMemState({
                                ...initMemState,
                                memChecked: false
                              });
                            }
                          }}
                        />
                      }
                      label="เลือก ส.ถ."
                    />
                  </GridItem>
                  {optVoteTypeSelected.id === 1 ||
                    optVoteTypeSelected.id === 3 ||
		    optVoteTypeSelected.id === 4 ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <ListboxPositionStatus
                        disabled={!initMemState.memChecked}
                        name="memPositionStatus"
                        defaultValue={{
                          id: initMemState.memPositionStatusSelected
                        }}
                        onChange={(evt, newValue) => {
                          setInitMemState({
                            ...initMemState,
                            memPositionStatusSelected: newValue.id
                          });
                        }}
                      ></ListboxPositionStatus>
                    </GridItem>
                  ) : null}
                  <GridItem xs={12} sm={12} md={12}>
                    <Datepicker
                      disabled={!initMemState.memChecked}
                      id="date-picker-member"
                      label="วันที่"
                      value={initMemState.memReplaceDate}
                      onChange={date => {
                        setInitMemState({
                          ...initMemState,
                          memReplaceDate: date.format().split("T")[0]
                        });
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    {/* TODO: add attached file */}

                    <FileUpload
                      disabled={!initMemState.memChecked}
                      id="file-upload-3"
                      label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                      remove
                      download
                      fileName={initMemState.fileName}
                      url={memUrlFile}
                      onFileRemove={() => {
                        setMemUrlFile("");
                        setInitMemState({
                          ...initPresState,

                          fileName: ""
                        });
                      }}
                      onFileHandler={(e, f, progressCb) => {
                        uploadFile("opt", f, progressCb)
                          .then(r => {
                            const data = r.data.data;

                            if (data.fileId) {
                              setMemUrlFile(
                                getFileDownloadUrl("opt", data.fileId)
                              );

                              setInitMemState({
                                ...initMemState,
                                fileUUID: data.fileId,
                                fileName: data.fileName
                              });
                            }

                            sweetAlerts.success("แนบไฟล์เรียบร้อย");
                          })
                          .catch(err => {
                            console.log(err);
                          });
                      }}
                      fileType="application/pdf"
                      btnName={<>Browse</>}
                      btnProps={{
                        color: "primary"
                      }}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="หมายเหตุ"
                      id="reasonMemident"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        inputRef: memRemarkRef
                      }}
                    />
                  </GridItem> */}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {/* {optVoteTypeSelected !== undefined &&
          optVoteTypeSelected !== null &&
          optVoteTypeSelected.id > 1 ? (
            <TabelEditVotedate
              props={pageProps.props}
              optSelected={optSelected}
            />
          ) : (
            // <TableAddArea

            //   rows={rows}
            //   setRows={setRows}
            //   rowIdx={rowIdx}
            //   setRowIdx={setRowIdx}
            //   optSelected={optSelected}
            //   optVoteTypeSelected={optVoteTypeSelected}
            //   generalVoteDate={generalVoteDate}
            // />
            <TableComplete data={dataTable} optSelected={optSelected} />
          )} */}
          {/* 
          {optVoteTypeSelected !== undefined &&
          optVoteTypeSelected !== null &&
          optVoteTypeSelected.id !== 1 ? (
            <>
              {initPresState.presChecked && (
                <TablePresidentNewVote
                  presEffectedDate={initPresState.presEffectedDate}
                  presPositionStatusSelected={
                    initPresState.presPositionStatusSelected
                  }
                  edit={true}
                  fileUUID={initPresState.fileUUID}
                  filename={initPresState.fileName}
                />
              )}
              {initMemState.memChecked && (
                <TabelEditVotedate
                  props={pageProps.props}
                  optSelected={optSelected}
                />
              )}
            </>
          ) : (
            <TableComplete data={dataTable} optSelected={optSelected} />
          )}
 */}

          {optVoteTypeSelected !== null && initPresState.presChecked && (
            <TablePresidentNewVote
              presEffectedDate={initPresState.presReplaceDate}
              presPositionStatusSelected={
                initPresState.presPositionStatusSelected
              }
              edit={true}
              fileUUID={initPresState.fileUUID}
              filename={initPresState.fileName}
            />
          )}
          {optVoteTypeSelected !== null &&
            initMemState.memChecked &&
            (optVoteTypeSelected.id !== 1 )&&
            pageProps.props["mem"] !== undefined && (
              <TabelEditVotedate
                props={pageProps.props}
                optSelected={optSelected}
              />
            )}

          {optVoteTypeSelected !== null &&
            initMemState.memChecked &&
            (optVoteTypeSelected.id === 1 )&& (
              <TableAreaVotedate
                props={pageProps.props}
                data={dataTable}
                optSelected={optSelected}
              />
            )}
        </GridItem>
      </GridContainer>

      <GridContainer direction="row" justify="flex-end" alignItems="flex-end">
        <GridItem>
          <Button size="sm" onClick={cancelHandler}>
            ย้อนกลับ
          </Button>
          <Button size="sm" color="success" onClick={saveHandler}>
            บันทึกข้อมูล
          </Button>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default EditVoteDate;
