import React, { useState, useEffect } from "react";
import TableComponent from "components/Table/MaterialTable";
const TableHistoryAddr = props => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const _columns = [
      {
        title: "ที่อยู่เดิม",
        field: "oldAddr1",
        editable: "never"
      },
      { title: "ตำบลเดิม", field: "oldAddr1TambolName" },
      {
        title: "อำเภอเดิม",
        field: "oldAddr1AmphurNamr"
      },
      {
        title: "จังหวัดเดิม",
        field: "oldAddr1ProvinceName",
        editable: "never"
      },
      {
        title: "ที่อยู่ใหม่",
        field: "newAddr1"
      },
      { title: "ตำบลใหม่", field: "newAddrTambolName" },
      { title: "อำเภอใหม่", field: "newAmphurName" },
      { title: "จังหวัดใหม่", field: "newProvinceName" }
    ];
    setColumns(_columns);
  }, []);
  useEffect(() => {
    if (props !== null) {
      setData(props.history);
    }
  }, [props]);
  const optionsTable = {};
  return (
    <div>
      <TableComponent
        title={"ประวัติการเปลี่ยนแปลงที่อยู่"}
        columns={columns}
        data={data}
        options={optionsTable}
        //  onRowUpdate={onRowUpdate}
      ></TableComponent>
    </div>
  );
};
export default TableHistoryAddr;
