import Autocomplete from "@material-ui/lab/Autocomplete";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
/* custom components */

const ListboxFullYear = props => {
  let content = (
    <TextField disabled label="เลือกทุกปี" margin="normal" fullWidth />
  );

  let year1 = new Date().getFullYear() + 543;
  let year2 = new Date().getFullYear() - 1 + 543;
  let year3 = new Date().getFullYear() - 2 + 543;
  let year4 = new Date().getFullYear() - 3 + 543;
  let year5 = new Date().getFullYear() - 4 + 543;
  let year6 = new Date().getFullYear() - 5 + 543;
  let year7 = new Date().getFullYear() - 6 + 543;
  let year8 = new Date().getFullYear() - 7 + 543;

  //console.log("year1 >>>" + year2);
  let yearLists = [
    { id: year1 - 543, name: year1.toString() },
    { id: year2 - 543, name: year2.toString() },
    { id: year3 - 543, name: year3.toString() },
    { id: year4 - 543, name: year4.toString() },
    { id: year5 - 543, name: year5.toString() },
    { id: year6 - 543, name: year6.toString() },
    { id: year7 - 543, name: year7.toString() },
    { id: year8 - 543, name: year8.toString() }
  ];

  let data = [];
  data = yearLists.map(v => {
    //console.log("v.id>>>" + v.name);
    return { id: v.id, value: v.name };
  });

  if (data.length > 0) {
    content = (
      <Autocomplete
        disabled={props.disabled}
        name="year"
        value={props.value}
        options={data}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={props.onChange}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label="เลือกทุกปี"
              margin="normal"
              fullWidth
            />
          );
        }}
      />
    );
  }
  return content;
};

export default ListboxFullYear;
