import React, { useEffect, useState } from "react";
import {
  addWtdPersonLaw,
  delWtdPersonLaw,
  editWtdPersonLaw
} from "contexts/API/WTD/Listwtd";
// import { useSnackBar } from "components/Alert/Snackbar";
import { getwtdLawSection, getwtdLawType } from "contexts/API/WTD/common";

import HandelError from "utils/HandleError";
import ListboxWtdLawSection from "components/Common/ListboxWtdLawSection";
import ListboxWtdLawType from "components/Common/ListboxWtdLawType";
import MaterialTable from "components/Table/MaterialTable";
import { useSweetAlert } from "components/Alert/SweetAlert";

const AddFault = props => {
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState();

  const optionsTable = [{}];
  const [lawtypeList, setLawtypeList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  useEffect(() => {
    if (props.dataLaw !== null) {
      setData(props.dataLaw);
    }
  }, [props.dataLaw]);

  useEffect(() => {
    getwtdLawType()
      .then(r => {
        let LawtypeData = r.data.data.map(v => {
          return { id: v.wtdLawTypeId, value: v.detail };
        });
        setLawtypeList(LawtypeData);
      })
      .catch(err => {
        console.log(err);
        sweetAlerts.error("ไม่สามารถดึงข้อมูล wtdLawType :", err.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getwtdLawSection()
      .then(r => {
        let sectionMapper = r.data.data.map(v => {
          return { id: v.wtdLawSectionId, value: v.detail };
        });

        setSectionList(sectionMapper);
      })
      .catch(err => {
        console.log(err);
        sweetAlerts.error("ไม่สามารถดึงข้อมูล wtdLawSection: ", err.toString());
      });
  }, []);

  const columns = [
    {
      title: "wtdPersonId",
      field: "wtdPersonId",
      export: false,
      hidden: true
    },
    {
      titel: "wtdPersonLawId",
      field: "wtdPersonLawId",
      export: false,
      hidden: true
    },
    {
      title: "พ.ร.บ. ที่กระทำผิด",
      field: "wtdLawTypeId",
      cellStyle: {
        minWidth: "600px"
      },
      render: rowData => {
        const v = lawtypeList.find(
          e => e.id === parseInt(rowData.wtdLawTypeId)
        );
        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        return (
          <ListboxWtdLawType
            rowId={0}
            defaultValue={{ id: props.value }}
            //defaultValue={{ id: props.value.rowData.wtdLawTypeId }}
            data={lawtypeList}
            onChange={(i, e, v) => {
              //if (v !== null) {
              //props.rowData.wtdLawTypeId = e.id;
              //}
              if (e !== null) {
                props.onChange(e.id);
              }
            }}
          />
        );
      }
    },
    {
      title: "มาตราที่กระทำผิด",
      field: "wtdLawSectionId",
      cellStyle: {
        minWidth: "400px"
      },
      render: rowData => {
        const v = sectionList.find(
          e => e.id === parseInt(rowData.wtdLawSectionId)
        );

        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        return (
          <ListboxWtdLawSection
            wtdLawTypeIdSelected={{ id: props.rowData.wtdLawTypeId }}
            defaultValue={{ id: props.rowData.wtdLawSectionId }}
            titlelawtype={"เลือกมาตรา"}
            data={sectionList}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.onChange(e.id);
              }
            }}
          />
        );
      }
    }
  ];
  //setColumns(columns);
  const edit = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        if (!newData.wtdLawSectionId) {
          sweetAlerts.warning("กรุณาเลือกมาตราที่กระทำผิด");
          reject();
        } else {
          setTimeout(() => {
            addWtdPersonLaw(props.wtdPersonId, newData.wtdLawSectionId)
              .then(result => {
                newData.wtdPersonLawId = result.data.data.wtdPersonLawId;

                setData([...data, newData]);
                sweetAlerts.success("เพิ่มข้อมูลเรียบร้อย");
                resolve();
              })
              .catch(err => {
                if (err.response.data && err.response.data.desc) {
                  sweetAlerts.error(
                    "เพิ่มข้อมูลไม่สำเร็จ",
                    err.response.data.desc
                  );
                } else {
                  sweetAlerts.error("เพิ่มข้อมูลไม่สำเร็จ");
                }

                reject();
              });
            resolve();
          }, 1000);
        }
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        if (!newData.wtdLawSectionId) {
          sweetAlerts.warning("กรุณาเลือกมาตราที่กระทำผิด");
          reject();
        } else {
          setTimeout(() => {
            const dataUpdate = [...data];

            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            editWtdPersonLaw(
              props.wtdPersonId,
              oldData.wtdPersonLawId,
              newData.wtdLawSectionId
            )
              .then(result => {
                dataUpdate[index] = newData;
                setData([...dataUpdate]);

                sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");

                //  setData([...dataUpdate]);
              })
              .catch(err => {
                HandelError(err);
                sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
              });

            resolve();
          }, 1000);
        }
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;

        delWtdPersonLaw(oldData.wtdPersonLawId)
          .then(result => {
            dataDelete.splice(index, 1);
            setData([...dataDelete]);

            sweetAlerts.success("ลบข้อมูลเรียบร้อย");

            //  setData([...dataUpdate]);
          })
          .catch(err => {
            HandelError(err);
            sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
          });

        resolve();
      })
  };
  return (
    <MaterialTable
      title={"พ.ร.บ. และมาตราที่กระทำผิด"}
      columns={columns}
      data={data}
      options={optionsTable}
      editable={[2, 5].includes(props.status) ? {} : edit}
    />
  );
};
export default AddFault;
