/* core components */
import React, { useEffect, useState } from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
//import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from "@material-ui/core/Switch";
/* custom components */
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const useStyles = makeStyles(styles);

const CheckboxVoteCardType = props => {
  const [fetchData] = useState([
    { id: 1, name: "อบจ." },
    { id: 2, name: "เทศบาล" },
    { id: 3, name: "อบต." },
    { id: 4, name: "กทม." },
    { id: 5, name: "เมืองพัทยา" }
  ]);

  const classes = useStyles();
  const [valid, setValid] = useState();
  const [, setCountAll] = useState(0);

  const [helperText, setHelperText] = useState("");

  let content = <p>กำลังโหลดข้อมูล...</p>;

  useEffect(() => {
    let optforVotecardLists = [];
    for (const [, v] of Object.entries(props.votecardCheckBoxOptSelected)) {
      if (v.checked) {
        optforVotecardLists.push(v.value);
        setCountAll(optforVotecardLists.length);
      }
    }

    if (props.required && optforVotecardLists.length > 0) {
      setValid(false);
      setHelperText("");
    }
    if (props.required && optforVotecardLists.length === 0) {
      setValid(true);
      //setHelperText("");
      setHelperText("ต้องเลือกอย่างน้อย1อย่าง");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.votecarrdCheckBoxOptSelected, props.required]);

  if (typeof fetchData !== "undefined" && fetchData !== null) {
    content = (
      <GridContainer>
        <GridItem xs={12} sm={2}>
          <FormLabel
            required={props.required}
            error={valid}
            className={
              classes.labelHorizontal +
              " " +
              classes.labelHorizontalRadioCheckbox
            }
          >
            <p style={{ color: "#000", fontSize: 15, textAlign: "left" }}>
              {" "}
              เลือกประเภท อปท. :
            </p>
          </FormLabel>
        </GridItem>

        <GridItem xs={12} sm={10}>
          <div
            className={
              classes.checkboxAndRadio +
              " " +
              classes.checkboxAndRadioHorizontal
            }
          >
            {fetchData.map(opt => (
              <FormControlLabel
                control={
                  <Switch
                    disabled={props.disabled}
                    checked={props.votecardCheckBoxOptSelected[opt.id].checked}
                    //  onChange={props.onOptTypeSelected(e, newCheck)}
                    onChange={e => {
                      props.onVotecardCheckBoxOptSelected(e);
                      // handelchang(e);
                    }}
                    value={opt.id}
                    //color="primary"
                    classes={{
                      //  root: classes.root,
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                    name={`${opt.id}`}
                  />
                }
                key={opt.id}
                label={opt.name}
                style={{ color: "rgb(0, 0, 0)" }}
              />
            ))}
            <FormHelperText
              style={{ color: "red", fontSize: 12, textAlign: "left" }}
            >
              {helperText}
            </FormHelperText>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
  return content;
};
export default CheckboxVoteCardType;
