import React, { useEffect, useState } from "react";
import {
  addLawSuspend,
  delLawSuspend,
  editLawSuspend
} from "contexts/API/SUSPEND/ListSuspend";
import {
  getsuspendLawSection,
  getsuspendLawType
} from "contexts/API/SUSPEND/common";

import HandelError from "utils/HandleError";
import ListboxSuspendLawSection from "components/Common/ListboxSuspendLawSection";
import ListboxSuspendLawType from "components/Common/ListboxSuspendLawType";
import MaterialTable from "components/Table/MaterialTable";
import { useSweetAlert } from "components/Alert/SweetAlert";

const AddLawSuspend = props => {
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState();

  const optionsTable = { exportButton: false };
  const [lawList, setLawList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  useEffect(() => {
    if (props.suspendLaw !== null) {
      setData(props.suspendLaw);
    }
  }, [props.suspendLaw]);

  useEffect(() => {
    getsuspendLawType()
      .then(r => {
        let lawMapper = r.data.data.map(v => {
          return { id: v.suspendLawTypeId, value: v.shortName };
        });

        setLawList(lawMapper);
      })
      .catch(err => {
        console.log(err);
        sweetAlerts.error(
          "ไม่สามารถดึงข้อมูล suspendLawType :",
          err.toString()
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getsuspendLawSection()
      .then(r => {
        let sectionMapper = r.data.data.map(v => {
          return { id: v.suspendLawSectionId, value: v.detail };
        });

        setSectionList(sectionMapper);
      })
      .catch(err => {
        console.log(err);
        sweetAlerts.error(
          "ไม่สามารถดึงข้อมูล suspendLawSection: ",
          err.toString()
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "suspendPersonId",
      field: "suspendPersonId",
      export: false,
      hidden: true
    },
    {
      titel: "suspendPersonLawId",
      field: "suspendPersonLawId",
      export: false,
      hidden: true
    },
    {
      title: "พ.ร.บ. ที่กระทำผิด",
      field: "suspendLawTypeId",

      render: rowData => {
        const v = lawList.find(
          e => e.id === parseInt(rowData.suspendLawTypeId)
        );

        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        return (
          <ListboxSuspendLawType
            titlelawtype={"เลือกมาตรา"}
            defaultValue={{ id: props.rowData.suspendLawTypeId }}
            data={lawList}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.onChange(e.id);
              }
            }}
          />
        );
      }
    },
    {
      title: "มาตราที่กระทำผิด",
      field: "suspendLawSectionId",

      render: rowData => {
        const v = sectionList.find(
          e => e.id === parseInt(rowData.suspendLawSectionId)
        );

        return v !== undefined ? v.value : "";
      },
      editComponent: props => {
        return (
          <ListboxSuspendLawSection
            suspendLawTypeIdSelected={{ id: props.rowData.suspendLawTypeId }}
            defaultValue={{ id: props.rowData.suspendLawSectionId }}
            data={sectionList}
            onChange={(i, e, v) => {
              if (e !== null) {
                props.onChange(e.id);
              }
            }}
          />
        );
      }
    }
  ];
  const edit = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        if (!newData.suspendLawSectionId) {
          sweetAlerts.warning("กรุณาเลือกมาตราที่กระทำผิด");
          reject();
        } else {
          addLawSuspend(props.suspendPersonId, newData.suspendLawSectionId)
            .then(result => {
              newData.suspendPersonLawId = result.data.data.suspendPersonLawId;

              setData([...data, newData]);
              sweetAlerts.success("เพิ่มข้อมูลเรียบร้อย");
              resolve();
            })
            .catch(err => {
              if (err.response.data && err.response.data.desc) {
                sweetAlerts.error(
                  "เพิ่มข้อมูลไม่สำเร็จ",
                  err.response.data.desc
                );
              } else {
                sweetAlerts.error("เพิ่มข้อมูลไม่สำเร็จ");
              }

              reject();
            });
          resolve();
        }
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataUpdate = [...data];

          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          editLawSuspend(
            props.suspendPersonId,
            oldData.suspendPersonLawId,
            newData.suspendLawSectionId
          )
            .then(result => {
              dataUpdate[index] = newData;
              setData([...dataUpdate]);

              sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");

              //  setData([...dataUpdate]);
            })
            .catch(err => {
              HandelError(err);
              sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
            });

          resolve();
        }, 1000);
      }),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataDelete = [...data];
          const index = oldData.tableData.id;

          delLawSuspend(oldData.suspendPersonLawId)
            .then(result => {
              dataDelete.splice(index, 1);
              setData([...dataDelete]);

              sweetAlerts.success("ลบข้อมูลเรียบร้อย");
            })
            .catch(err => {
              HandelError(err);
              sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
            });

          resolve();
        }, 1000);
      })
  };

  return (
    <MaterialTable
      title={"พ.ร.บ. และมาตราที่กระทำผิด ของคำวินิจฉัย กกต."}
      columns={columns}
      data={data}
      options={optionsTable}
      editable={[3, 5].includes(props.status) ? {} : edit}
    />
  );
};
export default AddLawSuspend;
