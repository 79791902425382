import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
//-------------ประเภทหน่วยงาน-----------------//
export function getsuspendOrgType() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendOrgType`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editsuspendOrgType(suspendOrgTypeId, orgName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendOrgType/${suspendOrgTypeId}`;

  const body = {
    detail: orgName
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deletesuspendOrgType(orgId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendOrgType/${orgId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addsuspendOrgType(orgName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendOrgType/`;

  const body = {
    detail: orgName
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//-------------หน่วยงาน-----------------//

export function getsuspendOrg() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendOrg`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editsuspendOrg(suspendOrgId, suspendOrgTypeId, orgName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendOrg/${suspendOrgId}`;

  const body = {
    orgName: orgName,
    suspendOrgTypeId: suspendOrgTypeId
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deletesuspendOrg(suspendOrgId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendOrg/${suspendOrgId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addsuspendOrg(suspendOrgTypeId, orgName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendOrg`;

  const body = {
    orgName: orgName,
    suspendOrgTypeId: suspendOrgTypeId
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//-------------มาตรา-----------------//

export function getsuspendLawSection() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendLawSection`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editsuspendLawSection(
  suspendLawSectionId,
  suspendLawTypeId,
  detail
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendLawSection/${suspendLawSectionId}`;

  const body = {
    suspendLawTypeId: suspendLawTypeId,
    detail: detail
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addsuspendLawSection(suspendLawTypeId, detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendLawSection`;

  const body = {
    suspendLawTypeId: suspendLawTypeId,
    detail: detail
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deletesuspendLawSection(suspendLawSectionId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendLawSection/${suspendLawSectionId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//-------------กฏหมาย-----------------//
export function getsuspendLawType() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendLawtype`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editsuspendLawType(suspendLawTypeId, name, shortName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendLawType/${suspendLawTypeId}`;

  const body = {
    name: name,
    short_name: shortName
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addsuspendLawType(name, shortName) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendLawType`;

  const body = {
    name: name,
    short_name: shortName
  };
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deletesuspendLawType(suspendLawTypeId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendLawType/${suspendLawTypeId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//-------------ประเภทการเลือกตั้ง-----------------//

export function getsuspendVoteType() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendVoteType`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editsuspendVoteType(suspendVoteTypeId, detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendVoteType/${suspendVoteTypeId}`;
  const body = {
    detail: detail
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function deletesuspendVoteType(suspendVoteTypeId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendVoteType/${suspendVoteTypeId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function addsuspendVoteType(detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendVoteType`;

  const body = {
    detail: detail
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
