import React, { useEffect, useState } from "react";
import {
  addCandidateDate,
  delCandidateDate,
  editCandidateDate,
  getCadidateDate
} from "contexts/API/INSPEC/CadidateDate";

import ConvertDate from "utils/ConvertDate";
import DateVoteDate from "components/Common/Datepicker";
import HandelError from "utils/HandleError";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSweetAlert } from "components/Alert/SweetAlert";

const RecruitDate = props => {
  const { sweetAlerts } = useSweetAlert();

  const [loading, setLoading] = useState(false);
  //const isAuth = cookies.get("userInfo");

  const [data, setData] = useState([]);
  const optionsTable = {};
  useEffect(() => {
    getCadidateDate()
      .then(result => {
        setData(result.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const columns = [
    {
      title: "commandApplyDateId",
      field: "commandApplyDateId",
      hidden: true
    },
    {
      title: "วันที่เริ่มรับสมัคร",
      field: "startApplyDate",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      defaultSort: "desc",
      render: rowData => {
        return ConvertDate(rowData.startApplyDate);
      },
      editComponent: props => {
        const dateValue = props.rowData.startApplyDate
          ? props.rowData.startApplyDate
          : null;
        return (
          <DateVoteDate
            label="วันที่เริ่มรับสมัคร"
            value={dateValue}
            onChange={date =>
              props.onRowDataChange({
                ...props.rowData,
                startApplyDate: date
              })
            }
          />
        );
      }
    },
    {
      title: "วันสิ้นสุดรับสมัคร",
      field: "endApplyDate",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.endApplyDate);
      },
      editComponent: props => {
        const dateValue = props.rowData.endApplyDate
          ? props.rowData.endApplyDate
          : null;
        return (
          <DateVoteDate
            label="วันสิ้นสุดรับสมัคร"
            value={dateValue}
            onChange={date =>
              props.onRowDataChange({
                ...props.rowData,
                endApplyDate: date
              })
            }
          />
        );
      }
    },
    {
      title: "วันที่เริ่มดำรงตำแหน่ง",
      field: "startEffectiveDate",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.startEffectiveDate);
      },
      editComponent: props => {
        const dateValue = props.rowData.startEffectiveDate
          ? props.rowData.startEffectiveDate
          : null;
        return (
          <DateVoteDate
            label="วันที่เริ่มดำรงตำแหน่ง"
            value={dateValue}
            onChange={date =>
              props.onRowDataChange({
                ...props.rowData,
                startEffectiveDate: date
              })
            }
          />
        );
      }
    },
    {
      title: "วันที่สิ้นสุดดำรงตำแหน่ง",
      field: "endEffectiveDate",
      //editable: "never",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return ConvertDate(rowData.endEffectiveDate);
      },
      editComponent: props => {
        const dateValue = props.rowData.endEffectiveDate
          ? props.rowData.endEffectiveDate
          : null;
        return (
          <DateVoteDate
            label="วันที่สิ้นสุดดำรงตำแหน่ง"
            value={dateValue}
            onChange={date =>
              props.onRowDataChange({
                ...props.rowData,
                endEffectiveDate: date
              })
            }
          />
        );
      }
    },
    {
      title: "จำนวนจังหวัดที่บันทึกข้อมูลแล้ว",
      field: "countVoteDateALL",
      editable: "never",
      cellStyle: {
        minWidth: "200px",
        textAlign: "center"
      }
    }
  ];

  return loading ? (
    <LoadingData />
  ) : (
    <MaterialTable
      title={"บันทึกวันรับสมัคร"}
      options={optionsTable}
      columns={columns}
      data={data}
      // data={() =>
      //   new Promise((resolve, reject) => {
      //     getCadidateDate().then(result => {
      //       resolve({
      //         data: result.data.data,
      //         totalCount: result.data.data.total
      //       });
      //     });
      //   })
      // }

      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              addCandidateDate(
                newData.startApplyDate,
                newData.endApplyDate,
                newData.startEffectiveDate,
                newData.endEffectiveDate
              )
                .then(result => {
                  setData([newData, ...data]);
                  newData.commandApplyDateId =
                    result.data.data.inspectorCommandApplyDateId;
                  newData.endEffectiveDate = result.data.data.endEffectiveDate;
                  newData.countVoteDateALL = result.data.data.countVoteDateALL;
                  sweetAlerts.success("เพิ่มข้อมูลเรียบร้อย");

                  //  setData([...dataUpdate]);
                })
                .catch(err => {
                  HandelError(err);
                  sweetAlerts.error(
                    "ไม่สามารถแก้ไขข้อมูลได้",
                    err.response.data.desc
                  );
                });

              resolve();
            }, 1000);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              editCandidateDate(
                oldData.commandApplyDateId,
                newData.startApplyDate,
                newData.endApplyDate,
                newData.startEffectiveDate,
                //Surachai.K 2023-08-08
                //Issue 131 เมนู บันทึกวันรับสมัคร > แก้ไข วาระการดำรงตำแหน่ง จาก 5 ปี เหลือ 4 ปี
                //วันสิ้นสุดดำรงตำแหน่งกรณีแก้ไขต้องสามารถแก้ไขได้
                newData.endEffectiveDate
              )
                .then(result => {
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  newData.endEffectiveDate = result.data.data.endEffectiveDate;
                  sweetAlerts.success("แก้ไขข้อมูลเรียบร้อย");

                  //  setData([...dataUpdate]);
                })
                .catch(err => {
                  HandelError(err);
                  sweetAlerts.error(
                    "ไม่สามารถแก้ไขข้อมูลได้",
                    err.response.data.desc
                  );
                });

              resolve();
            }, 1000);
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              delCandidateDate(oldData.commandApplyDateId)
                .then(result => {
                  setData([...dataDelete]);
                  sweetAlerts.success("ลบข้อมูลเรียบร้อย");
                  //resolve();
                })
                .catch(err => {
                  HandelError(err);
                  sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
                });

              resolve();
            }, 1000);
          })
      }}
      components={{
        Header: props => {
          return (
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2} align="center">
                  ลำดับ
                </TableCell>
                <TableCell colSpan={2} align="center">
                  ช่วงเวลารับสมัคร
                </TableCell>
                <TableCell colSpan={2} align="center">
                  วาระการดำรงตำแหน่ง
                </TableCell>
                <TableCell rowSpan={2}>
                  จำนวนจังหวัดที่บันทึกข้อมูลแล้ว
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">วันที่เริ่มรับสมัคร </TableCell>
                <TableCell align="center" minWidth="400">
                  วันสิ้นสุดรับสมัคร
                </TableCell>
                <TableCell align="center" width="400">
                  วันที่เริ่มดำรงตำแหน่ง
                </TableCell>
                <TableCell align="center" width="400">
                  วันที่สิ้นสุดดำรงตำแหน่ง
                </TableCell>
              </TableRow>
              {/* <TableRow></TableRow> */}
            </TableHead>
          );
        }
      }}
    />
  );
};
export default RecruitDate;
