import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function checkSuspendPerson(
  date,
  idCard,
  name,
  suspendProvince,
  suspendStatusSelected,
  listIdCard
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/findSuspendPerson`;
  const body = {
    date: date,
    idCard: idCard,
    name: name,
    suspendProvince: suspendProvince !== null ? suspendProvince.id : "",
    suspendStatus:suspendStatusSelected,
    listIdCard:listIdCard
    //  suspendStatusSelected !== null ? suspendStatusSelected.id : ""
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function delSuspendPerson(suspendPersonId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/suspend/suspendPerson/${suspendPersonId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
