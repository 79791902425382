import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import Datepicker from "components/Common/Datepicker";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxPositionStatus from "components/Common/ListboxPositionStatus";
import React from "react";

const TablePresidentNewVote = props => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h4>รายละเอียดจัดเลือกตั้งของ ผ.ถ.</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                {props.edit ? (
                  <ListboxPositionStatus
                    disabled={true}
                    defaultValue={{ id: props.presPositionStatusSelected }}
                  ></ListboxPositionStatus>
                ) : (
                  <ListboxPositionStatus
                    disabled={true}
                    value={props.presPositionStatusSelected}
                  ></ListboxPositionStatus>
                )}
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Datepicker
                  disabled={true}
                  //   minDate={mindate}
                  id="date-picker-president"
                  label="วันที่มีเหตุต้องจัดการเลือกตั้ง"
                  value={props.presEffectedDate}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
export default TablePresidentNewVote;
