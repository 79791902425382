import React from "react";
// core components
import RptSummaryVotecard from "./RptSummaryVotecard";
import { VotecardContextProvider } from "contexts/Votecard";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

const ReportIndex = props => {
  return (
    <div>
      <VotecardContextProvider>
        <RptSummaryVotecard />
      </VotecardContextProvider>
    </div>
  );
};
export default ReportIndex;
