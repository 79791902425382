import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Checkbox from "@material-ui/core/Checkbox";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
import { Validate } from "utils/Validators/Validate";
import conf from "config/env.conf.js";
/* custom components */
import useFetch from "hooks/useFetch";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const MutilpleSelectionProvince = props => {
  const [valid, setValid] = React.useState();

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/ms/provinces",
    []
  );

  let content = (
    <TextField disabled label="เลือกจังหวัด" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return { id: v.province_id, value: v.province_name };
    });
    // if (props.controll) {
    //   data = [{ id: 0, value: "" }, ...data];
    // }
    // data = [{ id: 0, value: "" }, ...data];
    data.push({ id: 0, value: "เลือกจังหวัด" });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        disabled={props.disabled}
        multiple
        id="checkboxes-tags-demo"
        options={data}
        disableCloseOnSelect
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />

            {option.value}
          </React.Fragment>
        )}
        style={{ maxWidth: 500 }}
        onChange={props.onChange}
        renderInput={params => (
          <TextField
            {...params}
            error={valid}
            variant="outlined"
            label="เลือกจังหวัด"
            placeholder="เลือกจังหวัด"
            onChange={Validate(
              params.inputProps.value,
              props.required,
              setValid
            )}
          />
        )}
      />

      //   <Autocomplete
      //     disabled={props.disabled === undefined ? !isCenter : props.disabled}
      //     id={props.id}
      //     options={data}
      //     defaultValue={getDefaultValue(data, props.defaultValue)}
      //     value={props.value && props.value.id === 0 ? data[0] : props.value}
      //     getOptionLabel={option => option.value}
      //     getOptionSelected={(option, value) => option.id === value.id}
      //     onChange={AutocompleteValidator(
      //       props.onChange,
      //       props.validators,
      //       setError,
      //       setHelperText
      //     )}
      //     renderInput={params => {
      //       return (
      //         <TextField
      //           {...params}
      //           label={props.title ? props.title : "เลือกจังหวัด"}
      //           margin="normal"
      //           error={valid}
      //           fullWidth
      //           helperText={helperText}
      //           inputProps={{
      //             autoComplete: "new-password",
      //             //   autoComplete: "off",
      //             ...params.inputProps
      //           }}
      //           onChange={Validate(
      //             params.inputProps.value,
      //             props.required,
      //             setValid
      //           )}
      //           required={props.required}
      //         />
      //   );
      // }}
      //  {...props.customProps}
      //  />
    );
  }
  return content;
};
export default MutilpleSelectionProvince;
