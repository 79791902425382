import React, { useEffect, useState } from "react";
import {
  delVoteCardDetail,
  editVoteCardDetail,
  getColor,
  getNumber
} from "contexts/API/VoteCard/Setting";

import Button from "components/CustomButtons/Button.js";
import Cookies from "universal-cookie";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxVoteCardColorAll from "components/Common/ListboxVoteCardColorAll";
import ListboxVoteCardNumAll from "components/Common/ListboxVoteCardNumAll";
import ListboxVotecardPrinting from "components/Common/ListboxVotecardPrinting";
import ModaladdCard from "./ModaladdCard";
import TableComponent from "components/Table/MaterialTable";
import conf from "config/env.conf";
import { getPrinting } from "contexts/API/VoteCard/Setting";
import { useSweetAlert } from "components/Alert/SweetAlert";

const cookies = new Cookies();

const ManageMasterdata = props => {
  const [showModaladdCard, setshowModaladdCard] = useState(false);
  const openModaladdCard = () => setshowModaladdCard(true);
  const closeModaladdCard = () => setshowModaladdCard(false);
  //const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const { sweetAlerts } = useSweetAlert();
  const [colorList, setColorList] = useState([]);
  const [numList, setNumList] = useState([]);
  const [printingList, setPrintingList] = useState([]);
  //const openModalConfirm = () => setShowComfirmDialog(true);

  useEffect(() => {
    getColor()
      .then(r => {
        let _color = r.data.data.map(v => {
          return { id: v.colorId, value: v.colorName };
        });
        setColorList(_color);
      })
      .catch(err => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getNumber()
      .then(r => {
        let _num = r.data.data.map(v => {
          return { id: v.cardNumberId, value: v.numbertext };
        });
        setNumList(_num);
      })
      .catch(err => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPrinting()
      .then(r => {
        let printing = r.data.data.map(v => {
          return { id: v.printingHousesId, value: v.printingHousesName };
        });
        setPrintingList(printing);
      })
      .catch(err => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.get("userInfo").token
        }
      };
      const response = await fetch(
        conf.END_POINT_URL + "/voteCard/voteCardDetailAll",
        options
      );
      const result = await response.json();

      setData(result.data);
      //setLoading(false);
    };
    fetchData();
  }, [loading]);
  const columns = [
    /*  { title: "ลำดับ", field: "voteCardDetailId" }, */
    {
      title: "voteCardDetailId",
      field: "voteCardDetailId",
      hidden: true
    },

    {
      title: "โรงพิมพ์",
      field: "printingHousesId",
      render: rowData => {
        const v = printingList.find(e => e.id === rowData.printingHousesId);
        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        return (
          <ListboxVotecardPrinting
            defaultValue={{ id: props.rowData.printingHousesId }}
            data={printingList}
            onChange={(i, e, v) => {
              if (v !== null) {
                props.rowData.printingHousesId = e.id;
              }
            }}
          />
        );
      }
    },

    {
      title: "สี",
      field: "cardColorId",
      render: rowData => {
        const v = colorList.find(e => e.id === rowData.cardColorId);
        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        return (
          <ListboxVoteCardColorAll
            defaultValue={{ id: props.rowData.cardColorId }}
            data={colorList}
            onChange={(i, e, v) => {
              if (v !== null) {
                props.rowData.cardColorId = e.id;
              }
            }}
          />
        );
      }
    },
    {
      title: "ชนิดหมายเลข",
      field: "number",
      render: rowData => {
        const v = numList.find(e => e.id === rowData.cardNumberId);
        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        return (
          <ListboxVoteCardNumAll
            defaultValue={{ id: props.rowData.cardNumberId }}
            data={numList}
            onChange={(i, e, v) => {
              if (v !== null) {
                props.rowData.cardNumberId = e.id;
              }
            }}
          />
        );
      }
    }
  ];
  const addSuccess = voteCardDetailId => {
    if (voteCardDetailId) {
      setLoading(true);
      //fetchData();
    }
  };
  return (
    <>
      <ModaladdCard
        closeModaladdCard={closeModaladdCard}
        showModaladdCard={showModaladdCard}
        onaddSuccess={addSuccess}
      ></ModaladdCard>
      <GridContainer
        justify="space-between"
        alignItems="center"
        style={{ paddingBottom: "30px" }}
      >
        <GridItem xs={6} sm={6} md={6}></GridItem>
        <GridItem>
          <Button
            color="success"
            type="button"
            onClick={() => {
              openModaladdCard();
            }}
          >
            เพิ่มข้อมูลบัตรเลือกตั้ง
          </Button>
        </GridItem>
      </GridContainer>
      <TableComponent
        title="รายละเอียดข้อมูลบัตรเลือกตั้ง"
        columns={columns}
        data={data}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;

              editVoteCardDetail(
                newData.voteCardDetailId,
                oldData.printingHousesId,
                newData.cardColorId,
                newData.cardNumberId
              )
                .then(result => {
                  sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                  setData([...dataUpdate]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.toString());
                });
              resolve();
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              console.log(oldData.voteCardDetailId);
              delVoteCardDetail(oldData.voteCardDetailId)
                .then(result => {
                  sweetAlerts.success("ลบข้อมูลเรียบร้อยแล้ว");
                  setData([...dataDelete]);
                })
                .catch(err => {
                  sweetAlerts.error("ไม่สามารถลบข้อมูลได้", err.toString());
                });
              resolve();
            })
        }}
        // actions={[
        //   rowData => ({
        //     icon: () => <DeleteOutline />,
        //     tooltip: "Delete User",
        //     onClick: (event, rowData) => {
        //       console.log(rowData);
        //       openModalConfirm();

        //       setBodyTextDelModal(
        //         `คุณต้องการลบข้อมูลเขตเลือกตั้ง ${rowData.printingHousesName}`
        //       );

        //       setDeletedvalue(rowData.VoteCardDetailId);
        //       setDeletedId(rowData.tableData.id);

        //       //   setDeletedId(tableMeta.rowIndex);
        //     }
        //     //     alert("You want to delete " + rowData.field),
        //   })
        // ]}
        localization={{}}
        options={{ pageSize: 10 }}
        //  onRowUpdate={onRowUpdate}
      ></TableComponent>
    </>
  );
};

export default ManageMasterdata;
