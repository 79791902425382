import React, { createContext, useState, useContext } from 'react';
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Snackbars from "components/Snackbar/Snackbar.js";

export const SnackBarContext = createContext();

export const useSnackBar = () => useContext(SnackBarContext);

export const SnackBarProvider = ({ children }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [,setTime] = useState(0); 
    const initSnackBarInfo = {
        place: "tr",
        color: "success",
        icon: AddAlert,
        message: "เรียบร้อย",
        open: true,
        closeNotification: () => setIsOpen(false),
        close: true
    }
    const [snackBarInfo, setSnackBarInfo] = useState(initSnackBarInfo);

    const snackBar = (color, message, time, place) => {

        if (place === undefined || place === null) {
            place = 'tr';
        }

        setIsOpen(true);

        if (time!== undefined && time > 0) {
            setTimeout(
                function () {
                    setIsOpen(false);
                    setTime(0);
                },
                time
            );
        }
        setSnackBarInfo({ ...initSnackBarInfo, color: color, message: message, place: place });
    }

    const value = {
        snackBar: snackBar,
        snackBars: {
            primary: (message, time, place) => {
                return snackBar("primary", message, time, place);
            },
            success: (message, time, place) => {
                return snackBar("success", message, time, place);
            },
            danger: (message, time, place) => {
                return snackBar("danger", message, time, place);
            },
            error: (message, time, place) => {
                return snackBar("danger", message, time, place);
            },
            warning: (message, time, place) => {
                return snackBar("warning", message, time, place);
            },
            info: (message, time, place) => {
                return snackBar("info", message, time, place);
            },

        }
    }

    const showSnackBar = () => {
        if (isOpen) {
            return <Snackbars {...snackBarInfo} />
        }
    }

    return <SnackBarContext.Provider value={value} v>
        {showSnackBar()}
        {children}
    </SnackBarContext.Provider>
}


