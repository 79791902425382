/* core components */
import React, { useEffect, useState } from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
//import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from "@material-ui/core/Switch";
/* custom components */
import conf from "config/env.conf.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import { useFetch } from "hooks/useFetch";

const useStyles = makeStyles(styles);

const CheckboxOpttype = props => {
  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/ms/opt/subtypes",
    []
  );
  const classes = useStyles();
  const [valid, setValid] = useState();
  const [, setCountAll] = useState(0);

  const [helperText, setHelperText] = useState("");

  let content = <p>กำลังโหลดข้อมูล...</p>;

  useEffect(() => {
    let optIdLists = [];
    for (const [, v] of Object.entries(props.optTypeSelected)) {
      if (v.checked) {
        optIdLists.push(v.value);
        setCountAll(optIdLists.length);
      }
    }

    if (props.required && optIdLists.length > 0) {
      setValid(false);
      setHelperText("");
    }
    if (props.required && optIdLists.length === 0) {
      setValid(true);
      //setHelperText("");
      setHelperText("ต้องเลือกอย่างน้อย 1 อย่าง");
    }
  }, [props.optTypeSelected, props.required]);

  // const handelchang = e => {
  //   if (e.target.checked) {
  //     const newState = {
  //       ...checkedItems,
  //       opt: [...checkedItems.opt, e.target.value]
  //     };
  //     setCheckedItems(newState);

  //     setCount(newState.opt.length);
  //   } else {
  //     const newState = {
  //       ...checkedItems,
  //       opt: checkedItems.opt.filter(prev => prev !== e.target.value)
  //     };

  //     setCheckedItems(newState);
  //     setCount(newState.opt.length);
  //   }
  //   // checkValid(count);
  // };

  if (!isLoading && typeof fetchData !== "undefined" && fetchData !== null) {
    content = (
      <GridContainer>
        <GridItem xs={12} sm={2}>
          <FormLabel
            required={props.required}
            error={valid}
            className={
              classes.labelHorizontal +
              " " +
              classes.labelHorizontalRadioCheckbox
            }
          >
            <p style={{ color: "#000", fontSize: 15, textAlign: "left" }}>
              {" "}
              เลือกประเภท อปท. :
            </p>
          </FormLabel>
        </GridItem>

        <GridItem xs={12} sm={10}>
          <div
            className={
              classes.checkboxAndRadio +
              " " +
              classes.checkboxAndRadioHorizontal
            }
          >
            {fetchData.data.map(opt => (
              <FormControlLabel
                control={
                  <Switch
                    disabled={props.disabled}
                    checked={
                      props.optTypeSelected[opt.ms_opt_sub_type_id].checked
                    }
                    //  onChange={props.onOptTypeSelected(e, newCheck)}
                    onChange={e => {
                      props.onOptTypeSelected(e);
                      // handelchang(e);
                    }}
                    value={opt.ms_opt_sub_type_id}
                    //color="primary"
                    classes={{
                      //  root: classes.root,
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                    id={`${opt.opt_name}`}
                    name={`${opt.ms_opt_sub_type_id}`}
                  />
                }
                key={opt.ms_opt_sub_type_id}
                label={opt.opt_short_name}
                style={{ color: "rgb(0, 0, 0)" }}
              />
            ))}
            <FormHelperText
              style={{ color: "red", fontSize: 12, textAlign: "left" }}
            >
              {helperText}
            </FormHelperText>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
  return content;
};
export default CheckboxOpttype;
