import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";


//
export function findAllPopups(query = "") {
    const cookies = new Cookies();
    const isAuth = cookies.get("userInfo");
    if (!isAuth) {
        return Promise.reject("Unauthorized")
    }
    const url = `${END_POINT_URL}/popup` + query;
    return axios.get(url, {
        headers: {
            Authorization: `Bearer ${isAuth.token}`
        }
    });
}

export function markReadPopups(id) {
    const cookies = new Cookies();
    const isAuth = cookies.get("userInfo");
    if (!isAuth) {
        return Promise.reject("Unauthorized")
    }
    const url = `${END_POINT_URL}/popup`;
    return axios.put(url, {
        id: id,
        isRead: true,
    },
        {
            headers: {
                Authorization: `Bearer ${isAuth.token}`
            }
        });
}