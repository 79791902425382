import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();


export function checkWtdAfter5Y(
  voteDate,
  idCard,
  listIdCard
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/checkWtdAfter5Y`;
  const body = {
    voteDate: voteDate,
    idCard: idCard,
    listIdCard: listIdCard
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
};

export function checkWtdPerson(
  date,
  idCard,
  name,
  wtdProvince,
  wtdStatus,
  wtdLaw,
  listIdCard
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/checkWtdPerson`;
  const body = {
    date: date,
    idCard: idCard,
    name: name,
    wtdProvince: wtdProvince !== null ? wtdProvince.id : "",
    wtdStatus: wtdStatus,
    wtdLaw: wtdLaw,
    listIdCard: listIdCard
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getReport02(provinceId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/wtd/rpt02?provinceId=${provinceId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
