import React, { useContext, useEffect, useState } from "react";
/* API */
import {
  getPromoteLists,
  getPromotePersonLists,
  updatePromotePerson
} from "contexts/API/INSPEC/Promote";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectPromotePerson from "components/Common/ListboxInspectPromotePerson";
import ListboxInspectProvinceGroup from "components/Common/ListboxInspectProvinceGroup";
import ListboxInspectVoteDateInquire from "components/Common/ListboxInspectVoteDateInquire";
/* Material Components */
import LoadingData from "components/Common/LoadingData";
import ModalPromote from "./ModalPromote";
import TableComponent from "components/Table/MaterialTable";
import { useSweetAlert } from "components/Alert/SweetAlert";

const Promote = ({ pageProps, setPageProps }) => {
  const [loading, setLoading] = useState(false);
  const [, setClickSearch] = useState(false);
  const { sweetAlerts } = useSweetAlert();

  const [provinceId] = useState();
  const [showModalPromote, setShowModalPromote] = useState(false);
  const [dataPromoteUser] = useState({});
  // const openModalProvinceId = v => setProvinceId(v);
  // const openModalPromote = () => setShowModalPromote(true);
  const closeModalPromote = () => setShowModalPromote(false);
  const {
    inspectProvinceGroupSelected,
    onInspectProvinceGroupSelected,
    onVotedateSelected,
    votedateSelected
  } = useContext(InspectorContext);

  const [replacePerson, setReplacePerson] = useState([]);

  const [data, setData] = useState([]);
  const optionsTable = {
    exportButton: false,
    paging: false,
    rowStyle: rowData => {
      if (rowData.tableData.id % 2 !== 0) {
        return { backgroundColor: "#f5f5f5" };
      }

      return {};
    }
  };

  const onClickSearch = e => {
    setClickSearch(false);

    if (votedateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกวันเลือกตั้ง");
      return;
    }
    if (inspectProvinceGroupSelected === null) {
      sweetAlerts.warning("กรุณาเลือกกลุ่มจังหวัด");
      return;
    } else {
      setLoading(true);
      const optQueryUrl = () => {
        //let endPoint = "?";
        let endPoint = "/";
        if (inspectProvinceGroupSelected !== null) {
          endPoint += `provinceGroup/${inspectProvinceGroupSelected.id}/`;
        }
        if (votedateSelected !== null) {
          //endPoint += `voteDateId=${votedateSelected.value.split("T")[0]}&`;
          endPoint += `voteDateId/${votedateSelected.id}`;
        }
        return endPoint;
      };

      getPromoteLists(optQueryUrl(), "")
        .then(result => {
          setData(result.data.data);

          setLoading(false);
          setClickSearch(true);
        })
        .catch(err => {
          setLoading(false);
          sweetAlerts.error("ไม่สามารถค้นหาข้อมูลได้", err.toString());
        });
    }
  };
  useEffect(() => {
    if (inspectProvinceGroupSelected && votedateSelected) {
      getPromotePersonLists(
        inspectProvinceGroupSelected.id,
        votedateSelected.id
      )
        .then(r => {
          let personist = r.data.data.map(v => {
            return {
              id: v.personApplyCommandId,
              value: v.fullname
            };
          });

          setReplacePerson(personist);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [inspectProvinceGroupSelected, votedateSelected]);
  const findPerson = person => {
    let v = replacePerson.find(e => e.id === parseInt(person));
    return v !== undefined ? v.value : "";
  };
  const columns = [
    {
      title: "จังหวัด",
      field: "provinceName",
      cellStyle: {
        minWidth: "100px",
        fontSize: "16px",
        fontWeight: "600px",
        verticalAlign: "text-top",
        paddingTop: "35px",
        paddingLeft: "30px"
      },
      editable: "never"
    },
    {
      title: "รายชื่อผู้ตรวจการในจังหวัด",
      field: "provinceName",
      cellStyle: {
        textAlign: "center",
        minWidth: "700px"
      },

      render: rowData => {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              {/* <span>{  v = replacePerson.find(e => e.id === parseInt(rowData.Person1));

return v !== undefined ? v.value : "";}</span> */}

              <CustomInput
                labelText="1 *ภูมิลำเนาเดิม*"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: findPerson(rowData.Person1)
                }}
              />
              <CustomInput
                disabled={true}
                labelText="2 *ภูมิลำเนาเดิม*"
                // value={props.remarkMem}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: findPerson(rowData.Person2)
                }}
              />
              <CustomInput
                labelText="3 *กลุ่มจังหวัด*"
                // value={props.remarkMem}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: findPerson(rowData.Person3)
                }}
              />
              <CustomInput
                labelText="4 *กลุ่มจังหวัด*"
                // value={props.remarkMem}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: findPerson(rowData.Person4)
                }}
              />
              <CustomInput
                labelText="5 *กลุ่มจังหวัด*"
                // value={props.remarkMem}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: findPerson(rowData.Person5)
                }}
              />
              <CustomInput
                labelText="6 *กลุ่มจังหวัด*"
                // value={props.remarkMem}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: findPerson(rowData.Person6)
                }}
              />
              <CustomInput
                labelText="7 *กลุ่มจังหวัด*"
                // value={props.remarkMem}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: findPerson(rowData.Person7)
                }}
              />
              <CustomInput
                labelText="8 *กลุ่มจังหวัด*"
                // value={props.remarkMem}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: findPerson(rowData.Person8)
                }}
              />
            </GridContainer>
          </GridItem>
        );
      },
      editComponent: props => {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              <ListboxInspectPromotePerson
                provinceId={props.rowData.provinceId}
                inspectProvinceGroupSelected={inspectProvinceGroupSelected}
                votedateSelected={votedateSelected}
                defaultValue={{
                  id: props.rowData.Person1
                }}
                data={replacePerson}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    props.rowData.Person1 = e.id;
                  } else {
                    props.rowData.Person1 = null;
                  }
                }}
              />

              <ListboxInspectPromotePerson
                provinceId={props.rowData.provinceId}
                inspectProvinceGroupSelected={inspectProvinceGroupSelected}
                votedateSelected={votedateSelected}
                defaultValue={{
                  id: props.rowData.Person2
                }}
                data={replacePerson}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    props.rowData.Person2 = e.id;
                  } else {
                    props.rowData.Person2 = null;
                  }
                }}
              />
              <ListboxInspectPromotePerson
                notSelectedprovinceId={props.rowData.provinceId}
                inspectProvinceGroupSelected={inspectProvinceGroupSelected}
                votedateSelected={votedateSelected}
                defaultValue={{
                  id: props.rowData.Person3
                }}
                data={replacePerson}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    props.rowData.Person3 = e.id;
                  } else {
                    props.rowData.Person3 = null;
                  }
                }}
              />
              <ListboxInspectPromotePerson
                notSelectedprovinceId={props.rowData.provinceId}
                inspectProvinceGroupSelected={inspectProvinceGroupSelected}
                votedateSelected={votedateSelected}
                defaultValue={{
                  id: props.rowData.Person4
                }}
                data={replacePerson}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    props.rowData.Person4 = e.id;
                  } else {
                    props.rowData.Person4 = null;
                  }
                }}
              />
              <ListboxInspectPromotePerson
                notSelectedprovinceId={props.rowData.provinceId}
                inspectProvinceGroupSelected={inspectProvinceGroupSelected}
                votedateSelected={votedateSelected}
                defaultValue={{
                  id: props.rowData.Person5
                }}
                data={replacePerson}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    props.rowData.Person5 = e.id;
                  } else {
                    props.rowData.Person5 = null;
                  }
                }}
              />
              <ListboxInspectPromotePerson
                notSelectedprovinceId={props.rowData.provinceId}
                inspectProvinceGroupSelected={inspectProvinceGroupSelected}
                votedateSelected={votedateSelected}
                defaultValue={{
                  id: props.rowData.Person6
                }}
                data={replacePerson}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    props.rowData.Person6 = e.id;
                  } else {
                    props.rowData.Person6 = null;
                  }
                }}
              />
              <ListboxInspectPromotePerson
                notSelectedprovinceId={props.rowData.provinceId}
                inspectProvinceGroupSelected={inspectProvinceGroupSelected}
                votedateSelected={votedateSelected}
                defaultValue={{
                  id: props.rowData.Person7
                }}
                data={replacePerson}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    props.rowData.Person7 = e.id;
                  } else {
                    props.rowData.Person7 = null;
                  }
                }}
              />
              <ListboxInspectPromotePerson
                notSelectedprovinceId={props.rowData.provinceId}
                inspectProvinceGroupSelected={inspectProvinceGroupSelected}
                votedateSelected={votedateSelected}
                defaultValue={{
                  id: props.rowData.Person8
                }}
                data={replacePerson}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    props.rowData.Person8 = e.id;
                  } else {
                    props.rowData.Person8 = null;
                  }
                }}
              />
            </GridContainer>
          </GridItem>
        );
      }
    }
  ];

  return (
    <div>
      <ModalPromote
        dataPromoteUser={dataPromoteUser}
        provinceId={provinceId}
        inspectApplyDateSelected={votedateSelected}
        closeModalPromote={closeModalPromote}
        showModalPromote={showModalPromote}
      ></ModalPromote>
      <Card>
        <CardHeader color="danger" text>
          <CardText color="danger">
            <h4>บันทึกผลการจับสลาก ผตล. ให้ดำรงตำแหน่ง ผตล.จว.</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxInspectVoteDateInquire
                    value={votedateSelected}
                    required={true}
                    onChange={onVotedateSelected}
                  ></ListboxInspectVoteDateInquire>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxInspectProvinceGroup
                    value={inspectProvinceGroupSelected}
                    onChange={onInspectProvinceGroupSelected}
                    required={true}
                  ></ListboxInspectProvinceGroup>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button>ยกเลิก</Button>
                  <Button color="info" onClick={onClickSearch}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {loading ? (
            <LoadingData />
          ) : (
            <TableComponent
              title="รายชื่อผู้ตรวจการในจังหวัด"
              enableInitColumns={false}
              columns={columns}
              data={data}
              options={optionsTable}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    //console.log(oldData);
                    const clone = { ...newData };
                    delete clone.provinceId;
                    delete clone.provinceGroupId;
                    delete clone.provinceName;
                    delete clone.voteDateDetailId;

                    //  var objCount = Object.keys(clone).length;
                    let strArray = [];
                    for (var key in clone) {
                      // console.log(x[key]);
                      if (clone[key] !== null) {
                        strArray.push(clone[key]);
                      }
                    }

                    const result = strArray.reduce(
                      (x, y) => (x.includes(y) ? x : [...x, y]),
                      []
                    );

                    if (strArray.length !== result.length) {
                      alert("ไม่สามารถเลือกผู้สมัครซ้ำได้");
                      reject();
                    } else {
                      setTimeout(() => {
                        const dataUpdate = [...data];

                        const index = oldData.tableData.id;
                        //   dataUpdate[index] = newData;

                        let leng = Object.keys(oldData).length;

                        let arr3 = [];
                        let y = 1;
                        for (let i = 0; i < leng; i++) {
                          if (
                            Object.keys(oldData)[i] ===
                              Object.keys(newData)[i] &&
                            Object.keys(newData)[i].indexOf("Person") !== -1
                          ) {
                            arr3 = [
                              {
                                rowIndex: y,
                                isInspectorInProvince:
                                  Object.keys(newData)[i] === "Person1" ||
                                  Object.keys(newData)[i] === "Person2"
                                    ? 1
                                    : 0,
                                oldPersonApplyId: Object.values(oldData)[i],
                                newPersonApplyId: Object.values(newData)[i]
                              },
                              ...arr3
                            ];
                            // }
                            y++;
                          }
                        }

                        let _arr = arr3.sort((a, b) =>
                          a.rowIndex > b.rowIndex ? 1 : -1
                        );
                        //  console.log(_arr);

                        let accounts = _arr.filter(function (n) {
                          return n.oldPersonApplyId || n.newPersonApplyId;
                        });

                        if (accounts.length === 0) {
                          sweetAlerts.warning("กรุณาเลือกรายชื่อผู้ตรวจ");
                          reject();
                          return;
                        }

                        // dataUpdate[index] = newData;
                        // setData([...dataUpdate]);
                        updatePromotePerson(
                          inspectProvinceGroupSelected,
                          votedateSelected,
                          oldData.provinceName,
                          oldData.provinceId,
                          oldData.provinceGroupId,
                          oldData.voteDateDetailId,
                          accounts
                        )
                          .then(result => {
                            dataUpdate[index] = newData;
                            //console.log(dataUpdate[index]);
                            if (oldData.voteDateDetailId === null) {
                              dataUpdate[index].voteDateDetailId =
                                result.data.data.voteDateDetailId;
                            }
                            // newData.voteDateDetailId =
                            //   result.data.data.voteDateDetailId;
                            setData([...dataUpdate]);
                            //  setData([...data, newData]);

                            sweetAlerts.success("บันทึกข้อมูลเรียบร้อยแล้ว");

                            //  setData([...dataUpdate]);
                          })
                          .catch(err => {
                            //alert(err.response.data.desc);
                            sweetAlerts.error(
                              "ไม่สามารถบันทึกข้อมูลได้",
                              err.response.data.desc
                            );
                          });

                        resolve();
                      }, 1000);
                    }
                  })
              }}
            ></TableComponent>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default Promote;
