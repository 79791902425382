import React  from "react";

import OptoutPostion from "./OptoutPostion";

import { VoteDateContextProvider } from "contexts/VoteDate";

const Form61Index = props => {
  // Local State
  //const [activeComponent, setActiveComponent] = useState("OptoutPostion");

  return (
    <VoteDateContextProvider>
      <OptoutPostion />
    </VoteDateContextProvider>
  );
};

export default Form61Index;
