import React from "react";
// core UserList
import RolesList from "./rolesList";
import { VoteDateContextProvider } from "contexts/VoteDate";
// @material-ui/core co../๊User/usersList//import { makeStyles } from "@material-ui/core/styles";

//const styles = {};
//const useStyles = makeStyles(styles);

const RolesIndex = props => {
  return (
    <div>
      <VoteDateContextProvider>
        <RolesList />
      </VoteDateContextProvider>
    </div>
  );
};
export default RolesIndex;
