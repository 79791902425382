import React, { useState } from "react";

import { InspectorContextProvider } from "contexts/Inspector";
import Selection16People from "./Selection16People";

const Index = props => {
  const SwitchComponents = ({ active, children }) => {
    return children.filter(child => child.props.name === active);
  };
  // Local State
  const [pageProps, setPageProps] = useState({
    active: "Selection16People",
    props: null
  });

  return (
    <InspectorContextProvider>
      <SwitchComponents active={pageProps.active}>
        <Selection16People
          name="Selection16People"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <></>
      </SwitchComponents>
    </InspectorContextProvider>
  );
};

export default Index;
