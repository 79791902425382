import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Validate from "utils/Validators/Validate";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxInspecVotetype = props => {
  const [valid, setValid] = React.useState();
  const [, setError] = React.useState();
  const [helperText, setHelperText] = React.useState("");
  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/inspector/vote/type",
    []
  );

  let content = (
    <TextField
      disabled
      label="เลือกประเภทการเลือกตั้ง"
      margin="normal"
      fullWidth
    />
  );

  let data = [];

  // console.log(fetchData[1].data);
  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      return { id: v.inspectorVoteTypeId, value: v.voteTypeName };
    });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        disabled={props.disabled}
        name="listboxOptVotetype"
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        options={data}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label="เลือกประเภทการเลือกตั้ง"
              margin="normal"
              error={valid}
              fullWidth
              helperText={helperText}
              inputProps={{
                ...params.inputProps
              }}
              onChange={Validate(
                // props.onChange,
                params.inputProps.value,
                props.required,
                setValid
              )}
              required={props.required}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }

  return content;
};
export default ListboxInspecVotetype;
