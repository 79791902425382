import React, { useContext, useEffect, useState } from "react";
/* API */
import {
  getCheckingLists,
  getPersonForReplace,
  getPosition
} from "contexts/API/INSPEC/ReplacePerson";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import DateVoteDate from "components/Common/Datepicker";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectApplyDate from "components/Common/ListboxInspectApplyDate";
import ListboxInspectPositionStatus from "components/Common/ListboxInspectPositionStatus";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import MaterialTable from "components/Table/MaterialTable";
/* Material Components */
import ModalReplacement from "./ModalReplacement";
import { Paper } from "@material-ui/core";
//import { Paper } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSweetAlert } from "components/Alert/SweetAlert";

const ReplaceCentral = ({ pageProps, setPageProps }) => {
  const {
    provinceSelected,
    onProvinceSelected,
    onInspectApplyDateSelected,
    inspectApplyDateSelected
  } = useContext(InspectorContext);

  const [loading, setLoading] = useState(false);
  //const [, setClickSearch] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  // const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const [, setReplacePerson] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [childDetail, setChildDetail] = useState(null);
  const [showModalReplacement, setshowModalReplacement] = useState(false);
  const openModalReplacement = () => setshowModalReplacement(true);
  const closeModalReplacement = () => setshowModalReplacement(false);

  const optionsTable = {};
  const addSuccess = result => {
    if (result) {
      onClickSearch();
    }
  };

  async function getReplace() {
    //setClickSearch(false);
    setLoading(true);
    const list = getCheckingLists(
      provinceSelected.id,
      inspectApplyDateSelected.id
    );
    const person = getPersonForReplace(
      provinceSelected.id,
      inspectApplyDateSelected.id
    );
    let responseOne = "";
    let responseTwo = "";
    try {
      await Promise.all([list, person]).then(responses => {
        responseOne = responses[0];
        responseTwo = responses[1];
      });
      setData(responseOne.data.data);

      setReplacePerson(responseTwo.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getPosition()
      .then(r => {
        let positionMemList = r.data.data.map(v => {
          return { id: v.positionStatusId, value: v.positionStatusName };
        });
        let tmp = positionMemList.filter(v => {
          return v.id === 3 || v.id === 4 || v.id === 5;
        });
        setPositionList(tmp);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      title: "personApplyCommandId",
      field: "personApplyCommandId",
      hidden: true
    },
    {
      title: "ชื่อ สกุล",
      field: "fullName",
      cellStyle: {
        minwidth: "200px"
      },
      editable: "never"
    },
    {
      title: "สถานะปัจจุบัน",
      field: "position",
      cellStyle: {
        minwidth: "200px"
      },
      editable: "never"
    },
    {
      title: "สาเหตุออกจากตำแหน่ง",
      field: "positionStatusId",
      cellStyle: {
        textAlign: "center"
      },
      render: rowData => {
        const v = positionList.find(
          e => e.id === parseInt(rowData.positionStatusId)
        );

        return v !== undefined ? v.value : "";
      },

      editComponent: props => {
        return (
          <GridContainer
            direction="row"
            justify="center"
            alignItems="center"
            label="พ้นจากตำแหน่ง"
          >
            <GridItem xs={12} sm={12} md={12}>
              <ListboxInspectPositionStatus
                defaultValue={{
                  id: props.rowData.positionStatusId
                }}
                data={positionList}
                onChange={(i, e, v) => {
                  if (e !== null) {
                    props.rowData.positionStatusId = e.id;
                    props.onRowDataChange({
                      ...props.rowData,
                      positionStatusId: e.id
                    });
                  }
                }}
              />
              {props.rowData.positionStatusId === 5 ? (
                <CustomInput
                  labelText="ระบุสาเหตุ"
                  // value={props.remarkMem}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: props.rowData.replaceRemarkOther,
                    onChange: e => {
                      props.rowData.replaceRemarkOther = e.target.value;
                    }
                  }}
                />
              ) : (
                ""
              )}
            </GridItem>
          </GridContainer>
        );
      }
    },
    {
      title: "วันที่สิ้นสุดการดำรงตำแหน่ง",
      field: "endEffectiveDatetime",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return (
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ConvertDate(rowData.endEffectiveDatetime) || "",
              disabled: true
            }}
          />
        );
      },
      editComponent: props => {
        const dateValue = props.rowData.endEffectiveDatetime
          ? props.rowData.endEffectiveDatetime
          : null;
        return (
          <DateVoteDate
            label=""
            value={dateValue}
            onChange={date =>
              props.onRowDataChange({
                ...props.rowData,
                endEffectiveDatetime: date
              })
            }
          />
        );
      }
    },
    {
      title: "ผู้ดำรงตำแหน่งแทน",
      field: "replaceFullName"
      // cellStyle: {
      //   textAlign: "center"
      // },
      // render: rowData => {
      //   const t = replacePerson.find(
      //     e =>
      //       e.personApplyCommandId ===
      //       parseInt(rowData.replacePersonApplyCommandId)
      //   );

      //   return t !== undefined ? t.personName : "";
      // },

      // editComponent: props => {
      //   return (
      //     <ListboxInspectPersonSelect16
      //       provinceId={provinceSelected}
      //       inspectApplyDateSelected={inspectApplyDateSelected}
      //       defaultValue={{
      //         id: props.rowData.replacePersonApplyCommandId
      //       }}
      //       data={replacePerson}
      //       onChange={(i, e, v) => {
      //         if (e !== null) {
      //           props.rowData.replacePersonApplyCommandId = e.id;
      //         }
      //       }}
      //     />
      //   );
      // }
    },
    {
      title: "วันที่เริ่มดำรงตำแหน่ง",
      field: "personReplaceStartEffectiveDate",
      cellStyle: {
        minwidth: "150px",
        textAlign: "center"
      },
      render: rowData => {
        return (
          <CustomInput
            // value={props.remarkMem}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: ConvertDate(rowData.personReplaceStartEffectiveDate) || "",
              disabled: true

              //  inputRef: props.memRemarkRef
            }}
          />
        );
      },
      editComponent: props => {
        const dateValue = props.rowData.personReplaceStartEffectiveDate
          ? props.rowData.personReplaceStartEffectiveDate
          : null;
        return (
          <DateVoteDate
            label=""
            value={dateValue}
            onChange={date =>
              props.onRowDataChange({
                ...props.rowData,
                personReplaceStartEffectiveDate: date
              })
            }
          />
        );
      }
    }
  ];

  const onClickSearch = e => {
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    }

    if (inspectApplyDateSelected === null) {
      sweetAlerts.warning("กรุณาเลือกช่วงวันที่สมัคร");
      return;
    }
    getReplace();
  };

  //
  return (
    <div>
      <ModalReplacement
        closeModalReplacement={closeModalReplacement}
        showModalReplacement={showModalReplacement}
        childDetail={childDetail}
        onaddSuccess={addSuccess}
      ></ModalReplacement>
      <Card>
        <CardHeader color="danger" text>
          <CardText color="danger">
            <h4>บันทึกรายชื่อผู้ดำรงตำแหน่งแทน ผตล.</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxProvince
                    required={true}
                    value={provinceSelected}
                    // defaultValue={provinceSelected || { id: 0 }}
                    onChange={onProvinceSelected}
                  ></ListboxProvince>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <ListboxInspectApplyDate
                    value={inspectApplyDateSelected}
                    required={true}
                    onChange={onInspectApplyDateSelected}
                  ></ListboxInspectApplyDate>
                </GridItem>
              </GridContainer>
              <br /> <br />
              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <GridItem xs={6} sm={6} md={6}></GridItem>
                <GridItem>
                  <Button>ยกเลิก</Button>
                  <Button color="info" onClick={onClickSearch}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <MaterialTable
                title={"บัญชีเป็นผู้ตรวจการเลือกตั้ง (บัญชี ผตล. 8 คน)"}
                options={optionsTable}
                columns={columns}
                data={data}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "edit",
                    onClick: (event, rowData) => {
                      openModalReplacement();
                      setChildDetail(rowData);
                    }
                  }
                ]}
                components={{
                  Container: props => <Paper {...props} elevation={0} />,
                  Action: props => {
                    if (props.action.icon === "edit") {
                      return (
                        <Button
                          onClick={event =>
                            props.action.onClick(event, props.data)
                          }
                          disabled={
                            props.data.replacePersonApplyCommandId !== null
                              ? true
                              : false
                          }
                          color="info"
                          variant="contained"
                          style={{ textTransform: "none" }}
                          size="sm"
                        >
                          ดำรงตำแหน่งแทน
                        </Button>
                      );
                    }
                  },
                  Header: props => {
                    return (
                      <TableHead>
                        <TableRow>
                          <TableCell rowSpan={2}>ลำดับ</TableCell>
                          <TableCell rowSpan={2}>ชื่อ สกุล</TableCell>
                          <TableCell rowSpan={2}>สถานะปัจจุบัน</TableCell>
                          <TableCell colSpan={4} align="center">
                            รายชื่อผู้ดำรงตำแหน่งแทน
                          </TableCell>
                          <TableCell rowSpan={2} align="center"></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">
                            สาเหตุออกจากตำแหน่ง
                          </TableCell>
                          <TableCell align="center" width="200">
                            วันที่สิ้นสุดการดำรงตำแหน่ง
                          </TableCell>
                          <TableCell align="center" width="200">
                            ผู้ดำรงตำแหน่งแทน
                          </TableCell>
                          <TableCell align="center">
                            วันที่เริ่มการดำรงตำแหน่ง
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    );
                  }
                }}
              />
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
};
export default ReplaceCentral;
