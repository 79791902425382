import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getProvince() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/provinces`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getAmphurs(provinceId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/amphures?province_id=${provinceId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getTambol(amphursId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/tambols?amphur_id=${amphursId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getTambolByGeocode(geocode) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/tambols/geocode/${geocode}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getTitle() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/title`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function getReasonPosition() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/position/status`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
