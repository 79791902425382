import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
import { fileInfo } from "contexts/API/FileManagement/fileInfo";

const cookies = new Cookies();

export default function addVoteDate(optId, voteDate, body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/${voteDate}`;
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editVoteDate(optId, voteDate, body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/${voteDate}`;
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getVoteDateDetail(optId, voteDate, voteType) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/${voteDate}/detail?voteType=${voteType}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export async function getVoteDateDetail2(optId, voteDate, voteType) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/${voteDate}/detail?voteType=${voteType}`;

  try {
    const voteDateDetail = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${isAuth.token}`
      }
    });

    let result = voteDateDetail.data.data;

    if (result.length > 0 && result[0] != null) {
      for (let i = 0; i < result.length; i++) {
        let fileName = null;
        if (result[i].fileUUID) {
          let resp = await fileInfo("opt", result[i].fileUUID);
          let file = resp.data;

          if (file.data && file.data.fileName) {
            fileName = file.data.fileName;
          }
        }

        let data = Object.assign(result[i], {
          fileName: fileName
        });
        voteDateDetail.data.data[i] = data;
      }
      return Promise.resolve(voteDateDetail);
    } else {
      return Promise.resolve(voteDateDetail);
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getVoteDateDetailByVoteId(optId, generalVoteId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/voteId/${generalVoteId}/detail`;

  try {
    const voteDateDetail = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${isAuth.token}`
      }
    });

    let result = voteDateDetail.data.data;

    if (result.length > 0 && result[0] != null) {
      for (let i = 0; i < result.length; i++) {
        let fileName = null;
        if (result[i].fileUUID) {
          let resp = await fileInfo("opt", result[i].fileUUID);
          let file = resp.data;

          if (file.data && file.data.fileName) {
            fileName = file.data.fileName;
          }
        }

        let data = Object.assign(result[i], {
          fileName: fileName
        });
        voteDateDetail.data.data[i] = data;
      }
      return Promise.resolve(voteDateDetail);
    } else {
      return Promise.resolve(voteDateDetail);
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

export function deleteVoteDateById(optId, VoteDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/voteId/${VoteDateId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deleteVoteDateByDate(optId, voteDate, voteType) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/${voteDate}/type/${voteType}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addReplacedetail(
  optId,
  optVoteId,
  optAreaId,
  effectedDate,
  positionStatusId,
  remark
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/replacedetail`;

  const body = {
    optVoteId: optVoteId,
    optAreaId: optAreaId,
    optPositionStatusId: positionStatusId, //ผลการเลือกตั้ง
    replaceDate: effectedDate,
    remark: remark
  };
  console.log(body);
  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function addReplacedetaileditPage(
  optId,
  optVoteId,
  optAreaId,
  effectedDate,
  positionStatusId,
  remark
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/replacedetail`;

  const body = {
    optVoteId: optVoteId,
    optAreaId: optAreaId,
    optPositionStatusId: positionStatusId, //ผลการเลือกตั้ง
    replaceDate: effectedDate,
    remark: remark
  };

  return axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getReplacedetail(optId, optVoteId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/replacedetail?optVoteId=${optVoteId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editReplacedetail(
  optId,
  optReplaceDetailId,
  replaceDate,
  optPositionStatusId,
  remark
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/replacedetail/${optReplaceDetailId}`;
  const body = {
    optPositionStatusId: optPositionStatusId,
    replaceDate: replaceDate,
    remark: remark
  };

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function deletedReplacedetail(optId, optReplaceDetailId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/replacedetail/${optReplaceDetailId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
