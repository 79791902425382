import React, { useContext, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ConvertDate from "utils/ConvertDate";
import { FileUpload } from "components/Common/FileUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TabePersonDetail from "./TablePeronDetail";
import { UserInfoContext } from "contexts/users";
import { addPersonreceivelist } from "contexts/API/VoteCard/AllocateToBranch";
import { addVoteCardAllocateNoti } from "contexts/API/VoteCard/Allocate";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useSweetAlert } from "components/Alert/SweetAlert";

/* API */

const OnhandWay = ({ pageProps, setPageProps }) => {
  const { userInfo } = useContext(UserInfoContext);
  const { props } = pageProps;

  const { sweetAlerts } = useSweetAlert();
  const [receiveDate] = useState(null);
  const [dataTable] = useState([
    {
      idCard: "",
      firstName: "",
      lastName: "",
      fileUUID: "",
      personFileName: ""
    },
    {
      idCard: "",
      firstName: "",
      lastName: "",
      fileUUID: "",
      personFileName: ""
    },
    {
      idCard: "",
      firstName: "",
      lastName: "",
      fileUUID: "",
      personFileName: ""
    }
  ]);
  const [assignFileUUID, setAssignFileUUID] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const useStyles = makeStyles(theme => ({
    listbox: {
      marginTop: "-20px"
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto"
      // maxWidth: 600
    },
    text: {
      textAlign: "right"
    },
    space: {
      marginTop: "20px"
    },
    person: {
      textAlign: "left",
      alignItems: "center",

      justifyContent: "center"
    },
    img: {
      display: "flex",

      alignItems: "center",

      justifyContent: "center"
    }
  }));
  const classes = useStyles();

  /*
   MYSELF
   POSTOFFICE
  */

  const backtoMainHandler = rowData => {
    setPageProps({ active: "ManageAllocate", action: "Cancel" });
  };
  const onSubmit = () => {
    const chk1 = dataTable.filter(function (t) {
      return t.idCard === "";
    });

    const chk2 = dataTable.filter(function (t) {
      return t.firstName === "";
    });
    const chk3 = dataTable.filter(function (t) {
      return t.lastName === "";
    });
    var valueArr = dataTable.map(function (item) {
      return item.idCard;
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });

    if (isDuplicate) {
      sweetAlerts.warning("เลขบัตรประชาชนซ้ำ");
      return;
    }

    if (chk1.length > 1 || chk2.length > 1 || chk3.length > 1) {
      sweetAlerts.warning(
        "กรุณาระบุข้อมูลผู้รับบัตรมากกว่า 2 คน และต้องระบุข้อมูลเลขประจำตัวประชาชน, ชื่อ-นามสกุลให้ครบถ้วน"
      );
      return;
    }

    let _result = [];
    dataTable.forEach(function (o) {
      if (o.firstName !== "" && o.lastName !== "" && o.idCard !== "")
        _result.push(o);
    });

    if (assignFileUUID === "") {
      sweetAlerts.warning("กรุณาแนบหนังสือคำสั่งแต่งตั้ง");
      return;
    }

    addPersonreceivelist(
      props.voteCardpurchaseDetailId,
      props.receiveTypeCode,
      receiveDate,
      assignFileUUID,
      _result
    )
      .then(result => {
        addVoteCardAllocateNoti({
          provinceId: props.provinceId,
          isCenter: true,
          header: "แจ้งการมารับบัตรด้วยตนเอง",
          body:
            props.optTypeName + props.optName +
            " ตำแหน่ง " +
            props.positionTypeShortName +
            " อำเภอ " +
            props.amphurName +
            " จังหวัด " +
            props.provinceName +
            " มารับบัตรด้วยตนเองเรียบร้อยแล้ว",
          redirectUrl: "/main/manageCardToBranch",
          systemTypeId: 5
        }).then(result => {
          sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");
          backtoMainHandler();
        });
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถบันทึกข้อมูลได้", err.response.data.desc);
      });
  };
  function getDetailVoteCard(rowData) {
    let _t = "";
    _t = (
      <ul styles={{ marginTop: "0px !important" }}>
        {rowData.map((post, index) => (
          <li key={index}>
            <p>
              {post.cardNumber} สี{post.colorName} เล่มที่{" "}
              {post.allocateStartNo} ถึง {post.allocateEndNo} จำนวน{" "}
              {post.volumnAllocate} เล่ม
            </p>
          </li>
        ))}
      </ul>
    );
    return _t;
  }
  return (
    <>
      <Card>
        <CardBody>
          <div className={classes.cardHeader}>
            <h4 className={classes.cardTitle}>บันทึกข้อมูลผู้มารับบัตร</h4>
          </div>
          <br />

          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  เลขที่หนังคือคำสั่งแนบท้าย (สนง.กกต.) :
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {props.centralDocumentNo}
                </GridItem>
              </GridContainer>
              {/* <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  ลงวันที่ :
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {ConvertDate(props.CentralDocumentDate)}
                </GridItem>
              </GridContainer> */}
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  จังหวัด :
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {props.provinceName}
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  อำเภอ :
                </GridItem>

                <GridItem xs={8} sm={8} md={8}>
                  {props.amphurName}
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  ชื่อ อปท. :
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {props.optName}
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  วันที่เลือกตั้ง :
                </GridItem>
                <GridItem xs={8} sm={8} md={8} styles={{ textAlign: "left" }}>
                  {ConvertDate(props.voteDate)}
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  รายละเอียดการจัดสรรบัตร :
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {getDetailVoteCard(props.voteCardAllocate)}
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.space}>
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  วิธีการรับบัตรเลือกตั้ง :
                </GridItem>
                <GridItem xs={8} sm={8} md={8} styles={{ textAlign: "left" }}>
                  {props.receiveTypeName}
                </GridItem>
              </GridContainer>
              <GridContainer
                className={classes.space}
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <GridItem xs={4} sm={4} md={4} className={classes.text}>
                  วันที่รับบัตร :
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {/* <Datepicker
                    valid={isFromValid}
                    id="voteDate"
                    label="วันที่รับบัตร"
                    value={receiveDate}
                    onChange={date => {
                      setReceiveDate(date.format());
                      if (date) {
                        setIsFromValid(false);
                      } else {
                        setIsFromValid(true);
                      }
                    }}
                  ></Datepicker> */}

                  {ConvertDate(props.receiveDate)}
                </GridItem>
              </GridContainer>

              <TabePersonDetail data={dataTable} />
            </GridItem>
          </GridContainer>
          <br></br>
          <GridContainer direction="row" justify="flex-end" alignItems="center">
            <GridItem xs={6} md={6}>
              <FileUpload
                id="file-upload-2"
                label="แนบหนังสือคำสั่งแต่งตั้ง"
                remove
                download
                fileName={fileName}
                url={fileUrl}
                onFileRemove={() => {
                  setFileUrl("");
                  setFileName("");
                }}
                onFileHandler={(e, f, progressCb) => {
                  uploadFile("opt", f, progressCb)
                    .then(r => {
                      const data = r.data.data;

                      if (data.fileId) {
                        setFileUrl(getFileDownloadUrl("opt", data.fileId));

                        setAssignFileUUID(data.fileId);
                        setFileName(data.fileName);
                      }

                      sweetAlerts.success("แนบไฟล์เรียบร้อย");
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }}
                fileType="application/pdf"
                btnName={<>Browse</>}
                btnProps={{
                  color: "primary"
                }}
              />
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              ผู้จ่ายบัตร :
            </GridItem>
            <GridItem xs={4} sm={4} md={4} styles={{ textAlign: "left" }}>
              {userInfo.tokenInfo.data.firstname}
            </GridItem>
          </GridContainer>

          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button onClick={backtoMainHandler}>กลับ</Button>
              <Button
                color="success"
                className={classes.buttoncustom}
                //type="submit"
                onClick={onSubmit}
              >
                บันทึกข้อมูล
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};
export default OnhandWay;
