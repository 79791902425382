import React, { useContext, useState } from "react";

import Button from "components/CustomButtons/Button.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxProvince from "components/Common/ListboxProvince.js";
import MaterialTable from "components/Table/MaterialTable";
import ModalIncludedOpt from "./ModalIncludedOpt.";
import Paper from "@material-ui/core/Paper";
import { VoteDateContext } from "contexts/VoteDate";
// Context APIs
import { getOptLists } from "contexts/API/OPT/Opt";
import { useSweetAlert } from "components/Alert/SweetAlert";

const MsOptIncluded = props => {
  const {
    optTypeSelected,
    onOptTypeSelected,
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,

    getOptTypeSelectedLists
  } = useContext(VoteDateContext);

  const [show, setShow] = useState(false);
  const { sweetAlerts } = useSweetAlert();
  const [data, setData] = useState([]);
  const [includedOptData, setIncludedOptData] = useState(null);

  const onClickSearch = e => {
    let optIdLists = getOptTypeSelectedLists();
    let endPoint = "?";
    //endPoint += `per_page=${per_page}&page=${page}`;
    if (optIdLists.length > 0) {
      endPoint += `&optsubtype=[${optIdLists.toString()}]`;
    } else {
      sweetAlerts.warning("กรุณาเลือกประเภท อปท.");
      return;
    }
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");

      return;
    }
    if (amphurSelected === null) {
      sweetAlerts.warning("กรุณาเลือกอำเภอ");

      return;
    } else {
      // if (optSelected !== null) {
      //   endPoint += `&optId=${optSelected.id}`;
      // }
      // if (tambolSelected !== null) {
      //   endPoint += `&tambolId=${tambolSelected.id}`;
      // }
      if (amphurSelected !== null) {
        endPoint += `&amphurId=${amphurSelected.id}`;
      }
      if (provinceSelected !== null) {
        endPoint += `&provinceId=${provinceSelected.id}`;
      }
      getOptLists(endPoint)
        .then(result => {
          if (result.data !== null && result.data.data.length > 0) {
            setData(result.data.data);
          } else {
            sweetAlerts.info("ไม่พบข้อมูล");
            setData(null);
          }
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถดึงข้อมูลได้ในขณะนี้");
        });
    }
  };

  const openModal = () => setShow(true);

  const closeModal = () => {
    setShow(false);
    onClickSearch();
  };

  const columns = [
    {
      field: "ms_opt_id",
      export: false,
      hidden: true
    },
    {
      field: "province_name",
      title: "จังหวัด",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "amphur_name",
      title: "อำเภอ",
      cellStyle: {
        textAlign: "center"
      }
    },

    {
      field: "opt_sub_type_short_name",
      title: "ประเภท อปท.",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "name",
      title: "ชื่อ อปท.",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      field: "countarea",
      title: "จำนวนเขต",
      cellStyle: {
        textAlign: "left"
      }
    }
  ];

  const options_table = {
    selection: true
  };

  const actions = [
    {
      tooltip: "ยุบรวม อปท.",
      icon: "edit",
      onClick: (evt, data) => {
        setIncludedOptData({
          oldOptIds: data.map(v => {
            return { optId: v.ms_opt_id };
          })
        });
        if (data === null || data.length <= 1) {
          sweetAlerts.warning("ต้องเลือก 2 อปท. ขึ้นไป");
        } else {
          openModal();
        }
      }
    }
  ];

  return (
    <>
      {show ? (
        <ModalIncludedOpt
          show={show}
          closeModal={closeModal}
          title={"ยุบรวม อปท."}
          data={includedOptData}
        />
      ) : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br /> <br />
          <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={12} sm={12} md={12}>
              <CheckboxOpttype
                optTypeSelected={optTypeSelected}
                onOptTypeSelected={onOptTypeSelected}
                required={true}
              ></CheckboxOpttype>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxProvince
                value={provinceSelected}
                title={"เลือกจังหวัด"}
                onChange={onProvinceSelected}
                required={true}
              ></ListboxProvince>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <ListboxDistinct
                value={amphurSelected}
                provinceSelected={provinceSelected}
                title={"เลือกอำเภอ"}
                onChange={onAmphurSelected}
                required={true}
              ></ListboxDistinct>
            </GridItem>
            {/* <GridItem xs={4} sm={12} md={4}>
              <ListboxSubdistinct
                provinceSelected={provinceSelected}
                amphurSelected={amphurSelected}
                title={"เลือกตำบล"}
                value={tambolSelected}
                onChange={onTamboldSelected}
              ></ListboxSubdistinct>
            </GridItem> */}
          </GridContainer>
          <br /> <br /> <br />
          <GridContainer
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <GridItem>
              <Button onClick={e => setData([])}>ยกเลิก</Button>
              <Button color="info" onClick={onClickSearch}>
                ค้นหา
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <br /> <br /> <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <MaterialTable
            data={data}
            columns={columns}
            title={"รายชื่อองค์กรปกครองส่วนท้องถิ่น"}
            options={options_table}
            components={{
              Container: props => <Paper {...props} elevation={0} />,
              Action: props => (
                <div style={{ paddingLeft: "8px" }}>
                  <Button
                    color="warning"
                    size="sm"
                    onClick={event => props.action.onClick(event, props.data)}
                  >
                    ยุบรวม อปท.
                  </Button>
                </div>
              )
            }}
            actions={actions}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};
export default MsOptIncluded;
