import React, { useState } from "react";
import MangeVoteDate from "./ManageVoteDate";
import AddVoteDate from "./AddVoteDate";
import EditVoteDate from "./EditVoteDate";
import {VoteDateContextProvider} from "contexts/VoteDate";


const SwitchComponents = ({active, children}) => {
  return children.filter(child => child.props.name === active);
}

const VoteDateIndex = props => {

  // Local State
  const [pageProps, setPageProps] = useState({ active: "MangeVoteDate", props: null});
  
  return (
    <VoteDateContextProvider>
      <SwitchComponents active={pageProps.active} >
        <MangeVoteDate name="MangeVoteDate" pageProps={pageProps} setPageProps={setPageProps} />
        <AddVoteDate name="AddVoteDate" pageProps={pageProps}  setPageProps={setPageProps}/>
        <EditVoteDate name="EditVoteDate" pageProps={pageProps} setPageProps={setPageProps}/>
      </SwitchComponents>
    </VoteDateContextProvider>
    )
};

export default VoteDateIndex;