import React from "react";

import VoteCardPublish from "./VoteCardPublish";

import { VoteDateContextProvider } from "contexts/VoteDate";

const Index = props => {
  // Local State
  //const [activeComponent, setActiveComponent] = useState("VoteCardPublish");

  return (
    <VoteDateContextProvider>
      <VoteCardPublish />
    </VoteDateContextProvider>
  );
};

export default Index;
