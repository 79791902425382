export default function getFieldValue(rowData, columnDef) {
  if (columnDef.exportData) {
    let data = columnDef.exportData(rowData);
    if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean') {
      return data;
    }
    throw new Error('data to export should only be string or number, see more(ดูวิธีการใช้งานเพิ่มเติม) https://github.com/DGA-SCD/ECTPL/issues/547');
  }
  if (columnDef.render) {
    let renderedData = columnDef.render(rowData);
    return typeof renderedData === 'object'
      ? rowData[columnDef.field]
      : renderedData;
  }
  return rowData[columnDef.field];
}
