import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
import CovertDate from "utils/ConvertDate";
import React from "react";
//import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import TextField from "@material-ui/core/TextField";
import { Validate } from "utils/Validators/Validate";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
import { useFetch } from "hooks/useFetch";

const ListboxVotedate = props => {
  let optId = props.optSelected ? props.optSelected.id : 0;
  let isEnable = false;
  let query = "";
  let data = [];

  const [, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [valid, setValid] = React.useState();

  if (props.optVoteTypeSelected) {
    query += `?voteType=${props.optVoteTypeSelected.id}`;
  }
  if (optId > 0 && props.optVoteTypeSelected) {
    isEnable = true;
  }

  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + `/opt/${optId}/vote/date${query}`,

    [optId, query],
    isEnable
  );

  let content = (
    <TextField
      disabled
      label="เลือกวันที่เลือกตั้ง"
      margin="normal"
      fullWidth
    />
  );

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      let arr = v.date.split("T00");
      return {
        id: arr["0"],
        type:  v.positionId,
        value: CovertDate(v.date) + " " + v.positionDesc
      };
    });

    content = (
      <Autocomplete
        id="votedate"
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        options={data}
        value={props.value}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={props.title ? props.title : "เลือกวันที่เลือกตั้ง"}
              margin="normal"
              error={valid}
              fullWidth
              helperText={helperText}
              inputProps={{
                ...params.inputProps
              }}
              onChange={Validate(
                params.inputProps.value,
                props.required,
                setValid
              )}
              required={props.required}
            />
          );
        }}
        {...props.customProps}
      />
    );
  }
  return content;
};
export default ListboxVotedate;
