/* core components */
import React, { useState } from "react";
import {
  addSuspendPerson,
  getCitizenSuspend
} from "contexts/API/SUSPEND/ListSuspend";

import AddLawRevoke from "./AddLawRevoke";
import AddLawSuspend from "./AddLawSuspend";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datepicker from "components/Common/Datepicker";
import { FileUpload } from "components/Common/FileUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
//import { SuspendContext } from 'contexts/Suspend';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import ListboxSuspendOrg from "components/Common/ListboxSuspendOrg";
import ListboxSuspendStatus from "components/Common/ListboxSuspendStatus";
import ListboxSuspendVoteType from "components/Common/ListboxSuspendVoteType";
import ListboxTitle from "components/Common/ListboxTitle";
import ListboxSuspendYear from "components/Common/ListboxSuspendYear";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import getAge from "utils/CalculateAge";
import { getFileDownloadUrl } from "contexts/API/FileManagement/utils";
import { makeStyles } from "@material-ui/core/styles";
import { uploadFile } from "contexts/API/FileManagement/upload";
import { useHistory } from "react-router-dom";
import { useSweetAlert } from "components/Alert/SweetAlert";
import suspendEndDate from "utils/WtdEndDate";
import moment from "moment";

const AddPerson = ({ pageProps, setPageProps }) => {
  let _initialState = {
    idCard: null,
    genderId: null,
    titleId: null,
    firstName: null,
    lastName: null,
    birthdate: null,
    addr1: null,
    addr1TambolId: null,
    addr1AmphurId: null,
    addr1ProvinceId: null,

    suspendPerson: {
      suspendPersonId: null,
      voteTypeId: null,

      suspendFlag: false,
      suspendOrgId: 1,
      suspendSdate: null,
      suspendYear: null,
      suspendEdate: null,
      suspendStatusId: 1,
      suspendFileName: null,
      suspendFileUUID: null,

      revokeFlag: false,
      revokeOrgId: null,
      revokeSdate: null,
      revokeYear: null,
      revokeEdate: null,
      revokeStatusId: 2,
      revokeFileName: null,
      revokeFileUUID: null
    }
  };

  const [addr1ProvinceId, setAddr1ProvinceId] = useState({
    id: 0,
    value: ""
  });
  const [addr1AmphurId, setAddr1AmphurId] = useState({
    id: 0,
    value: ""
  });
  const [addr1TambolId, setAddr1TambolId] = useState({
    id: 0,
    value: ""
  });

  const [initialState, setInitialState] = useState(_initialState);

  const [suspendUrlFile, setSuspendUrlFile] = useState();
  const [revokeUrlFile, setRevokeUrlFile] = useState();

  const useStyles = makeStyles({});
  const { sweetAlerts } = useSweetAlert();
  const [isIdCardValid, setIsIdCardValid] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [helpertextGender, setHelpertextGender] = useState(null);
  const [helpertexttitle, setHelpertexttitle] = useState("");
  const [helpertextlname, setHelpertextlname] = useState("");
  const [helpertextfname, setHelpertextfname] = useState("");
  const [helpertextaddr, setHelpertextaddr] = useState("");
  const [helpertexttambol, setHelpertexttambol] = useState("");
  const [helpertextprovince, setHelpertextprovince] = useState("");
  const [helpertextBd, setHelpertextBd] = useState("");
  const [helpertextvotetype, setHelpertextvotetype] = useState("");

  const classes = useStyles();
  const [suspendPersonId, setSuspendPersonId] = useState("");

  const [foundSuspend, setFoundSuspend] = useState(false);

  const [, setSuspendChecked] = useState(
    initialState.suspendPerson.suspendFlag
  );
  const [, setRevokeChecked] = useState(initialState.suspendPerson.revokeFlag);

  const [suspendLaw] = useState([]);
  const [revokeLaw] = useState([]);
  let history = useHistory();
  const onadd1ProvinceSelected = (evt, newValue) => {
    setAddr1ProvinceId(newValue);
    setAddr1AmphurId(null);
    setAddr1TambolId(null);
    setInitialState({
      ...initialState,
      addr1ProvinceId: newValue ? newValue.id : 0,
      addr1ProvinceName: newValue ? newValue.value : ""
    });
  };
  const onadd1AmphurSelected = (evt, newValue) => {
    setAddr1AmphurId(newValue);
    setAddr1TambolId(null);
    setInitialState({
      ...initialState,
      addr1AmphurId: newValue ? newValue.id : 0,
      addr1AmphurName: newValue ? newValue.value : ""
    });
  };
  const onadd1TambolSelected = (evt, newValue) => {
    setAddr1TambolId(newValue);
    setHelpertexttambol("");
    setInitialState({
      ...initialState,
      addr1TambolId: newValue ? newValue.id : 0,
      addr1TambolName: newValue ? newValue.value : ""
    });
  };

  const onSuspendChecked = e => {
    setSuspendChecked(e.target.checked);
  };

  const onRevokeChecked = e => {
    setRevokeChecked(e.target.checked);
  };

  async function fetchData(idCard) {
    setIsLoading(true);

    try {
      const person = await getCitizenSuspend(idCard);

      let result = person.data.data;

      if (result.length > 0 && result[0] != null) {
        if (result[0].suspendPerson.length > 0) {
          sweetAlerts.warning(
            "บุคคลนี้ได้อยู่ระหว่างการถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ",
            "หากต้องการแก้ไข ให้ไปที่เมนู  รายชื่อผู้ถูกระงับ/เพิกถอนสิทธิ"
          );
          setFoundSuspend(true);
        } else {
          setFoundSuspend(false);
        }

        setIsLoading(false);

        setInitialState({
          ...initialState,
          idCard: idCard,
          genderId: result[0].genderId,
          titleId: result[0].titleId,
          firstName: result[0].firstName,
          lastName: result[0].lastName,
          birthdate: result[0].birthdate,
          addr1: result[0].addr1,
          addr1TambolId: result[0].addr1TambolId,
          addr1AmphurId: result[0].addr1AmphurId,
          addr1ProvinceId: result[0].addr1ProvinceId,
          suspendPersonId: null,
          suspendPerson: {
            suspendPersonId: null,
            voteTypeId: null,

            suspendFlag: false,
            suspendOrgId: 1,
            suspendSdate: null,
            suspendYear: null,
            suspendEdate: null,
            suspendStatusId: 1,
            suspendFileName: null,
            suspendFileUUID: null,

            revokeFlag: false,
            revokeOrgId: null,
            revokeSdate: null,
            revokeYear: null,
            revokeEdate: null,
            revokeStatusId: 2,
            revokeFileName: null,
            revokeFileUUID: null
          }
        });
        setAddr1ProvinceId({
          id: result[0].addr1ProvinceId,
          value: result[0].addr1ProvinceName
        });
        setAddr1AmphurId({
          id: result[0].addr1AmphurId,
          value: result[0].addr1AmphurName
        });
        setAddr1TambolId({
          id: result[0].addr1TambolId,
          value: result[0].addr1TambolName
        });
      } else {
        sweetAlerts.warning("ไม่พบเลขประจำตัวประชาชนนี้ในระบบ");
        //setInitialState(_initialState);
        setInitialState({
          ...initialState,
          idCard: idCard,
          genderId: null,
          titleId: null,
          firstName: null,
          lastName: null,
          birthdate: null,
          addr1: null,
          addr1TambolId: null,
          addr1AmphurId: null,
          addr1ProvinceId: null,
          suspendPersonId: null,
          suspendPerson: {
            suspendPersonId: null,
            voteTypeId: null,

            suspendFlag: false,
            suspendOrgId: 1,
            suspendSdate: null,
            suspendYear: null,
            suspendEdate: null,
            suspendStatusId: 1,
            suspendFileName: null,
            suspendFileUUID: null,

            revokeFlag: false,
            revokeOrgId: null,
            revokeSdate: null,
            revokeYear: null,
            revokeEdate: null,
            revokeStatusId: 2,
            revokeFileName: null,
            revokeFileUUID: null
          }
        });
        setAddr1ProvinceId({
          id: 0,
          value: ""
        });
        setAddr1AmphurId({
          id: 0,
          value: ""
        });
        setAddr1TambolId({
          id: 0,
          value: ""
        });

        setIsLoading(false);
        setFoundSuspend(false);
      }
    } catch (error) {
      sweetAlerts.error("ไม่สามารถดึงข้อมูล", error.response.data.desc);
    }
  }

  const onIDCardHandler = v => {
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      if (isError) {
        sweetAlerts.danger("เลขประจำตัวประชาชนผิด");
        if (isIdCardValid === true) {
          setIsIdCardValid(false);
        }
      } else {
        if (isIdCardValid === false) {
          setIsIdCardValid(true);
          fetchData(v.value);
        } else {
          fetchData(v.value);
        }
      }
    }

    setInitialState({ ...initialState, idCard: v.value });
  };

  const handelSubmit = () => {
    if (initialState.genderId === null) {
      setHelpertextGender("กรุณาระบุ*");
    }
    if (initialState.titleId === null) {
      setHelpertexttitle("กรุณาระบุ*");
    }
    if (initialState.firstName === null) {
      setHelpertextfname("กรุณาระบุ*");
    }
    if (initialState.lastName === null) {
      setHelpertextlname("กรุณาระบุ*");
    }
    if (initialState.addr1 === null || "") {
      setHelpertextaddr("กรุณาระบุ*");
    }

    if (initialState.addr1TambolId === 0 || null) {
      setHelpertexttambol("กรุณาระบุ*");
    }
    if (initialState.addr1ProvinceId === 0 || null) {
      setHelpertextprovince("กรุณาระบุ*");
    }
    if (initialState.birthdate === null) {
      setHelpertextBd("กรุณาระบุ*");
    }

    if (initialState.idCard.length !== 13) {
      sweetAlerts.warning("กรุณากรอกเลขประจำตัวประชาชนให้ครบ 13 หลัก");
      return;
    }

    if (
      initialState.suspendPerson === undefined ||
      initialState.suspendPerson.voteTypeId === undefined ||
      initialState.suspendPerson.voteTypeId === null ||
      initialState.suspendPerson.voteTypeId === 0
    ) {
      setHelpertextvotetype("กรุณาระบุ*");
      sweetAlerts.warning("กรุณาระบุประเภทการเลือกตั้ง");
      return;
    }

    if (
      initialState.suspendPerson === undefined ||
      initialState.suspendPerson.suspendFlag === true
    ) {
      if (initialState.suspendPerson.suspendOrgId === 0) {
        sweetAlerts.warning("กรุณาระบุหน่วยงานที่มีคำสั่ง คำวินิจฉัย");
        return;
      }
      if (
        initialState.suspendPerson.suspendSdate === null ||
        initialState.suspendPerson.suspendSdate === undefined
      ) {
        sweetAlerts.warning("กรุณาระบุวันที่ กกต. มีมติ");
        return;
      }
      if (
        initialState.suspendPerson.suspendYear === undefined ||
        initialState.suspendPerson.suspendYear === null
      ) {
        sweetAlerts.warning("กรุณาระบุระยะเวลาที่ถูกระงับสิทธิสมัครฯ");
        return;
      }
      if (
        initialState.suspendPerson.suspendEdate === null ||
        initialState.suspendPerson.suspendEdate === undefined
      ) {
        sweetAlerts.warning("กรุณาระบุวันที่พ้นการถูกระงับสิทธิสมัครฯ");
        return;
      }
      if (
        initialState.suspendPerson.suspendStatusId === undefined ||
        initialState.suspendPerson.suspendStatusId === null
      ) {
        sweetAlerts.warning("กรุณาระบุสถานะการถูกระงับสิทธิสมัครฯ");
        return;
      }
    }

    if (
      initialState.suspendPerson === undefined ||
      initialState.suspendPerson.revokeFlag === true
    ) {
      if (initialState.suspendPerson.revokeOrgId === 0) {
        sweetAlerts.warning(
          "กรุณาระบุหน่วยงานที่มีคำสั่ง ของการถูกเพิกถอนสิทธิสมัครฯ"
        );
        return;
      }
      if (
        initialState.suspendPerson.revokeSdate === null ||
        initialState.suspendPerson.revokeSdate === undefined
      ) {
        sweetAlerts.warning("กรุณาระบุวันที่เริ่มการถูกเพิกถอนสิทธิสมัครฯ");
        return;
      }
      // console.log("revokeYear: ", initialState.suspendPerson.revokeYear);
      if (
        initialState.suspendPerson.revokeYear === undefined ||
        initialState.suspendPerson.revokeYear === null
      ) {
        sweetAlerts.warning("กรุณาระบุระยะเวลาที่ถูกเพิกถอนสิทธิสมัครฯ");
        return;
      }
      if (
        initialState.suspendPerson.revokeEdate === null ||
        initialState.suspendPerson.revokeEdate === undefined
      ) {
        sweetAlerts.warning("กรุณาระบุวันที่พ้นการถูกเพิกถอนสิทธิสมัครฯ");
        return;
      }
      if (
        initialState.suspendPerson.revokeStatusId === undefined ||
        initialState.suspendPerson.revokeStatusId === null
      ) {
        sweetAlerts.warning("กรุณาระบุสถานะการถูกเพิกถอนสิทธิสมัครฯ");
        return;
      }

      if (
        initialState.suspendPerson.revokeFileName === undefined ||
        initialState.suspendPerson.revokeFileName === null
      ) {
        sweetAlerts.warning("กรุณาระบุเอกสารการถูกเพิกถอนสิทธิสมัครฯ");
        return;
      }
    }
    if (
      initialState.genderId !== null &&
      initialState.titleId &&
      initialState.addr1TambolId &&
      initialState.firstName &&
      initialState.lastName &&
      initialState.birthdate &&
      initialState.suspendPerson !== undefined &&
      (initialState.suspendPerson.suspendFlag === true ||
        initialState.suspendPerson.revokeFlag === true)
      /*  (initialState.suspendPerson.suspendFlag === true && initialState.suspendPerson.suspendOrgId !== null) &&
      (initialState.suspendPerson.suspendFlag === true && initialState.suspendPerson.suspendSdate !== null) &&
      (initialState.suspendPerson.suspendFlag === true && initialState.suspendPerson.suspendEdate !== null) &&
      (initialState.suspendPerson.suspendFlag === true && initialState.suspendPerson.suspendStatusId !== null) &&

      (initialState.suspendPerson.revokeFlag === true && initialState.suspendPerson.revokeOrgId !== null) &&
      (initialState.suspendPerson.revokeFlag === true && initialState.suspendPerson.revokeSdate !== null) &&
      (initialState.suspendPerson.revokeFlag === true && initialState.suspendPerson.revokeYear !== null) &&
      (initialState.suspendPerson.revokeFlag === true && initialState.suspendPerson.revokeEdate !== null) &&
      (initialState.suspendPerson.revokeFlag === true && initialState.suspendPerson.revokeStatusId !== null)  */
    ) {
      const body = {
        idCard: initialState.idCard,
        genderId: initialState.genderId,
        titleId: initialState.titleId,
        firstName: initialState.firstName,
        lastName: initialState.lastName,
        birthdate: initialState.birthdate,
        addr1: initialState.addr1,
        addr1TambolId: initialState.addr1TambolId,
        voteTypeId: initialState.suspendPerson.voteTypeId
      };
      if (initialState.suspendPerson.suspendFlag === true) {
        body.suspend = {
          suspendFlag: initialState.suspendPerson.suspendFlag,
          suspendOrgId: initialState.suspendPerson.suspendOrgId,
          suspendSdate: initialState.suspendPerson.suspendSdate,
          suspendYear: initialState.suspendPerson.suspendYear,
          suspendEdate: initialState.suspendPerson.suspendEdate,
          suspendStatusId: initialState.suspendPerson.suspendStatusId,
          suspendFileName: initialState.suspendPerson.suspendFileName,
          suspendFileUUID:
            initialState.suspendPerson.suspendFileUUID === undefined
              ? null
              : initialState.suspendPerson.suspendFileUUID
        };
      }

      if (initialState.suspendPerson.revokeFlag === true) {
        body.revoke = {
          revokeFlag: initialState.suspendPerson.revokeFlag,
          revokeOrgId: initialState.suspendPerson.revokeOrgId,
          revokeSdate: initialState.suspendPerson.revokeSdate,
          revokeYear: initialState.suspendPerson.revokeYear,
          revokeEdate: initialState.suspendPerson.revokeEdate,
          revokeStatusId: initialState.suspendPerson.revokeStatusId,
          revokeFileName: initialState.suspendPerson.revokeFileName,
          revokeFileUUID:
            initialState.suspendPerson.revokeFileUUID === undefined
              ? null
              : initialState.suspendPerson.revokeFileUUID
        };
      }

      // console.log("data->", body);

      addSuspendPerson(body)
        .then(result => {
          setSuspendPersonId(result.data.data.suspendPersonId);
          let desc = "*กรุณาระบุ พ.ร.บ. และมาตราที่กระทำผิด";

          sweetAlerts.success(
            `เพิ่มข้อมูลเรียบร้อยแล้ว`,
            <div style={{ fontSize: "24px", fontWeight: "bold", color: "red" }}>
              {desc}
            </div>
          );
          //setInitialState(_initialState);
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถเพิ่มข้อมูลได้", err.toString());
        });
    } else {
      sweetAlerts.warning(`กรุณาระบุข้อมูลให้ครบถ้วน`);
    }
  };

  let content = <LoadingData />;
  if (isLoading === false) {
    content = (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>
                    บันทึกข้อมูลผู้ถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ
                  </h4>
                </CardText>
              </CardHeader>

              <CardHeader>
                <h4 className={classes.cardTitle}>ข้อมูลส่วนบุคคล</h4>
              </CardHeader>

              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <NumberFormat
                            placeholder="เลขประจำตัวประชาชน"
                            style={{
                              paddingTop: "20px"
                            }}
                            customInput={TextField}
                            format="# #### ##### ## #"
                            mask="X"
                            fullWidth
                            value={initialState.idCard}
                            onValueChange={onIDCardHandler}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Button
                            disabled={!isIdCardValid}
                            variant="contained"
                            color="success"
                            onClick={() => fetchData(initialState.idCard)}
                          >
                            ตรวจสอบ
                          </Button>
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControlLabel
                            disabled={!isIdCardValid}
                            control={
                              <Radio
                                checked={
                                  initialState.genderId === "true" ||
                                  initialState.genderId === true
                                }
                                value={true}
                                onClick={e => {
                                  if (e.target.checked) {
                                    setHelpertextGender("");
                                  } else {
                                    setHelpertextGender("กรุณาระบุ*");
                                  }
                                  setInitialState({
                                    ...initialState,
                                    genderId: e.target.value
                                  });
                                }}
                                name="genderId"
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot
                            }}
                            label="ชาย"
                          />
                          <FormControlLabel
                            disabled={!isIdCardValid}
                            control={
                              <Radio
                                onClick={e => {
                                  if (e.target.checked) {
                                    setHelpertextGender("");
                                  } else {
                                    setHelpertextGender("กรุณาระบุ*");
                                  }
                                  setInitialState({
                                    ...initialState,
                                    genderId: e.target.value
                                  });
                                }}
                                checked={
                                  initialState.genderId === "false" ||
                                  initialState.genderId === false
                                }
                                value={false}
                                name="genderId"
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot
                            }}
                            label="หญิง"
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {initialState.genderId !== null
                              ? ""
                              : helpertextGender}
                          </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          {/* คำนำหน้าชื่อ */}
                          <ListboxTitle
                            disabled={!isIdCardValid}
                            defaultValue={{ id: initialState.titleId }}
                            onChange={(evt, newValue) => {
                              if (newValue) {
                                setInitialState({
                                  ...initialState,
                                  titleId: newValue ? newValue.id : 0
                                });
                              } else {
                                setHelpertexttitle("กรุณาระบุ");
                              }
                            }}
                          ></ListboxTitle>
                          <FormHelperText style={{ color: "red" }}>
                            {initialState.titleId ? "" : helpertexttitle}
                          </FormHelperText>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            validators={[MustNotNull]}
                            labelText="ชื่อ"
                            formControlProps={{
                              fullWidth: true
                            }}
                            style={{ fontSize: "18px !important " }}
                            inputProps={{
                              disabled: !isIdCardValid,
                              value: initialState.firstName || "",
                              onChange: e => {
                                if (e) {
                                  setHelpertextfname("");
                                  setInitialState({
                                    ...initialState,
                                    firstName: e.target.value
                                  });
                                } else {
                                  setHelpertextfname("กรุณาระบุ");
                                }
                              }
                            }}
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {initialState.firstName ? "" : helpertextfname}
                          </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            disabled={!isIdCardValid}
                            validators={[MustNotNull]}
                            labelText="นามสกุล"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: !isIdCardValid,
                              value: initialState.lastName || "",
                              onChange: e => {
                                if (e) {
                                  setHelpertextlname("");
                                  setInitialState({
                                    ...initialState,
                                    lastName: e.target.value
                                  });
                                } else setHelpertextlname("กรุณาระบุ");
                              }
                            }}
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {initialState.lastName ? "" : helpertextlname}
                          </FormHelperText>
                        </GridItem>
                      </GridContainer>
                      <br></br>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            //success={initialState.addr1 !== null}
                            //error={initialState.addr1 === null}
                            validators={[MustNotNull]}
                            labelText="ที่อยู่ตามบัตรประชาขน"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: !isIdCardValid,
                              value: initialState.addr1 || "",
                              onChange: e => {
                                if (e) {
                                  setHelpertextaddr("");
                                  setInitialState({
                                    ...initialState,
                                    addr1: e.target.value
                                  });
                                } else setHelpertextaddr("กรุณาระบุ");
                              }
                            }}
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {initialState.addr1 ? "" : helpertextaddr}
                          </FormHelperText>
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxProvince
                            disabled={!isIdCardValid}
                            required={true}
                            value={addr1ProvinceId}
                            controll={true}
                            onChange={onadd1ProvinceSelected}
                          ></ListboxProvince>
                          <FormHelperText style={{ color: "red" }}>
                            {initialState.addr1ProvinceId
                              ? ""
                              : helpertextprovince}
                          </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxDistinct
                            disabled={!isIdCardValid}
                            validators={[MustNotNull]}
                            value={addr1AmphurId}
                            controll={true}
                            required={true}
                            // defaultValue={{
                            //   id: data.addr1AmphurId
                            // }}
                            provinceSelected={{
                              id: initialState.addr1ProvinceId
                            }}
                            onChange={onadd1AmphurSelected}
                          ></ListboxDistinct>
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxSubdistinct
                            disabled={!isIdCardValid}
                            amphurSelected={{ id: initialState.addr1AmphurId }}
                            controll={true}
                            required={true}
                            // provinceSelected={{ id: data.addr1ProvinceId }}
                            validators={[MustNotNull]}
                            value={addr1TambolId}
                            onChange={onadd1TambolSelected}
                          ></ListboxSubdistinct>
                          <FormHelperText style={{ color: "red" }}>
                            {initialState.addr1TambolId ? "" : helpertexttambol}
                          </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}></GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <Datepicker
                            disabled={!isIdCardValid}
                            id="begin"
                            label="วันเดือนปีเกิด"
                            value={initialState.birthdate}
                            onChange={date => {
                              if (date) {
                                let arr = date.format().split("T");
                                //const v = getAge(date.format());
                                setInitialState({
                                  ...initialState,
                                  birthdate: arr[0],
                                  age: getAge(date.format())
                                });
                              }
                            }}
                          ></Datepicker>
                          <FormHelperText style={{ color: "red" }}>
                            {initialState.birthdate ? "" : helpertextBd}
                          </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="อายุ"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              value:
                                initialState.birthdate === null
                                  ? 0
                                  : getAge(initialState.birthdate),
                              disabled: true
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxSuspendVoteType
                            disabled={!isIdCardValid}
                            validators={[MustNotNull]}
                            // defaultValue={{
                            //   id: initialState.suspendPerson.voteTypeId
                            // }}
                            voteTypeSelected={{
                              id: initialState.suspendPerson.voteTypeId
                            }}
                            onChange={(evt, newValue) => {
                              // console.log("newValue.id", newValue);
                              if (newValue) {
                                //setVoteTypeSelected(newValue.id );
                                setInitialState({
                                  ...initialState,
                                  suspendPerson: {
                                    ...initialState.suspendPerson,
                                    voteTypeId: newValue.id
                                  }
                                });
                                setHelpertextvotetype("");
                              }
                            }}
                          ></ListboxSuspendVoteType>

                          <FormHelperText style={{ color: "red" }}>
                            {/* {initialState.suspendPerson.voteTypeId ? "" : helpertextvotetype} */}
                            {helpertextvotetype}
                          </FormHelperText>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                      </GridContainer>
                      <br /> <br />
                    </GridItem>
                  </GridContainer>
                </form>
                {/* มติ กกต. */}
                {foundSuspend === false ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Card>
                        <CardHeader color="rose" text>
                          <CardText color="rose">
                            <h4 className={classes.cardTitle}>
                              คำวินิจฉัย กกต.
                            </h4>
                          </CardText>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={!isIdCardValid}
                                    name="suspendFlag"
                                    onClick={e => {
                                      if (e.target.checked) {
                                        setInitialState({
                                          ...initialState,
                                          suspendPerson: {
                                            ...initialState.suspendPerson,
                                            suspendFlag: true,
                                            suspendYear: 1
                                          }
                                        });
                                      } else {
                                        setInitialState({
                                          ...initialState,
                                          suspendPerson: {
                                            ...initialState.suspendPerson,
                                            suspendFlag: false
                                          }
                                        });
                                      }
                                    }}
                                    onChange={onSuspendChecked}
                                    checked={
                                      initialState.suspendPerson.suspendFlag
                                    }
                                  //inputRef={suspendCheckedRef}
                                  />
                                }
                                /* label="มติ กกต." */
                                label="คำวินิจฉัย กกต."
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <ListboxSuspendOrg
                                disabled={
                                  !initialState.suspendPerson.suspendFlag
                                }
                                orgTypeId={1}
                                validators={[MustNotNull]}
                                title={"หน่วยงานที่มีคำสั่ง"}
                                //defaultValue={{id: initialState.suspendPerson.suspendOrgId}}
                                defaultValue={{ id: 1 }}
                                onChange={(evt, value) => {
                                  if (value) {
                                    setInitialState({
                                      ...initialState,
                                      suspendPerson: {
                                        ...initialState.suspendPerson,
                                        suspendOrgId: value.id
                                      }
                                    });
                                  }
                                }}
                              ></ListboxSuspendOrg>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <Datepicker
                                disabled={
                                  !initialState.suspendPerson.suspendFlag
                                }
                                id="date-picker-president_1"
                                label="วันที่ กกต. มีมติ"
                                value={initialState.suspendPerson.suspendSdate}
                                onChange={date => {
                                  const dateSelect = new Date(
                                    date.format().split("T")[0]
                                  );
                                  dateSelect.setFullYear(
                                    dateSelect.getFullYear() + 1
                                  );
                                  const _edate = dateSelect
                                    .toISOString()
                                    .split("T")[0];

                                  setInitialState({
                                    ...initialState,
                                    suspendPerson: {
                                      ...initialState.suspendPerson,
                                      suspendSdate: date.format().split("T")[0],
                                      suspendEdate: _edate,
                                      suspendYear: 1
                                    }
                                  });
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <ListboxSuspendYear
                                disabled={true}
                                defaultValue={{ id: 1 }}
                                title={"ระยะเวลาที่ถูกระงับ"}
                              /* onChange={(evt, newValue) => {
                            if (newValue) {
                              setInitialState({
                                ...initialState,
                                suspendPerson: {
                                  ...initialState.suspendPerson,
                                  suspendYear: newValue.id
                                }
                              });
                            }
                          }} */
                              ></ListboxSuspendYear>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <Datepicker
                                disabled={
                                  !initialState.suspendPerson.suspendFlag
                                }
                                id="date-picker-president_4"
                                label="วันที่พ้นการถูกระงับ"
                                value={initialState.suspendPerson.suspendEdate}
                                onChange={date => {
                                  setInitialState({
                                    ...initialState,
                                    suspendPerson: {
                                      ...initialState.suspendPerson,
                                      suspendEdate: date.format().split("T")[0]
                                    }
                                  });
                                }}
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                              <ListboxSuspendStatus
                                disabled={true}
                                //value={initialState.suspendPerson.suspendStatusId}
                                defaultValue={{ id: 1 }}
                                flagStatus={1}
                                title={"สถานะการถูกระงับสิทธิสมัครฯ"}
                              /* onChange={(evt, newValue) => {
                              setInitialState({
                                ...initialState,
                                suspendPerson: {
                                  ...initialState.suspendPerson,
                                  suspendStatusId: newValue.id
                                }
                              });
                            }} */
                              ></ListboxSuspendStatus>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <FileUpload
                                disabled={
                                  !initialState.suspendPerson.suspendFlag
                                }
                                id="file-upload_1"
                                label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                                remove
                                download
                                fileName={
                                  initialState.suspendPerson.suspendFileName
                                }
                                url={suspendUrlFile}
                                onFileRemove={() => {
                                  setSuspendUrlFile("");
                                  setInitialState({
                                    ...initialState,
                                    suspendPerson: {
                                      ...initialState.suspendPerson,
                                      suspendFileName: ""
                                    }
                                  });
                                }}
                                onFileHandler={(e, f, progressCb) => {
                                  uploadFile("suspend", f, progressCb)
                                    .then(r => {
                                      const dataSuspend = r.data.data;

                                      if (dataSuspend.fileId) {
                                        setSuspendUrlFile(
                                          getFileDownloadUrl(
                                            "suspend",
                                            dataSuspend.fileId
                                          )
                                        );
                                        setInitialState({
                                          ...initialState,
                                          suspendPerson: {
                                            ...initialState.suspendPerson,
                                            suspendFileUUID: dataSuspend.fileId,
                                            suspendFileName:
                                              dataSuspend.fileName
                                          }
                                        });
                                      }
                                      sweetAlerts.success("แนบไฟล์เรียบร้อย");
                                    })
                                    .catch(err => {
                                      console.log(err);
                                    });
                                }}
                                fileType="application/pdf"
                                btnName={<>Browse</>}
                                btnProps={{
                                  color: "primary"
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>

                    {/* คำสั่งศาล */}
                    <GridItem xs={12} sm={12} md={6}>
                      <Card>
                        <CardHeader color="rose" text>
                          <CardText color="rose">
                            <h4 className={classes.cardTitle}>คำสั่งศาล</h4>
                          </CardText>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={!isIdCardValid}
                                    name="revokeFlag"
                                    onClick={e => {
                                      if (e.target.checked) {
                                        setInitialState({
                                          ...initialState,
                                          suspendPerson: {
                                            ...initialState.suspendPerson,
                                            revokeFlag: true,
                                            revokeYear: 10
                                          }
                                        });
                                      } else {
                                        setInitialState({
                                          ...initialState,
                                          suspendPerson: {
                                            ...initialState.suspendPerson,
                                            revokeFlag: false
                                          }
                                        });
                                      }
                                    }}
                                    onChange={onRevokeChecked}
                                    checked={
                                      initialState.suspendPerson.revokeFlag
                                    }
                                  //inputRef={suspendCheckedRef}
                                  />
                                }
                                /* label="คำสั่งศาล/คำวินิจฉัย" */
                                label="คำสั่งศาล"
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                              <ListboxSuspendOrg
                                disabled={
                                  !initialState.suspendPerson.revokeFlag
                                }
                                orgTypeId={2}
                                validators={[MustNotNull]}
                                title={"หน่วยงานที่มีคำสั่ง"}
                                defaultValue={{
                                  id: initialState.suspendPerson.revokeOrgId
                                }}
                                onChange={(evt, value) => {
                                  if (value) {
                                    setInitialState({
                                      ...initialState,
                                      suspendPerson: {
                                        ...initialState.suspendPerson,
                                        revokeOrgId: value.id
                                      }
                                    });
                                  }
                                }}
                              ></ListboxSuspendOrg>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <Datepicker
                                disabled={
                                  !initialState.suspendPerson.revokeFlag
                                }
                                id="date-picker-president_2"
                                label="วันที่เริ่มการถูกเพิกถอน"
                                value={initialState.suspendPerson.revokeSdate}
                                onChange={date => {
                                  let arr = date.format().split("T");

                                  setInitialState({
                                    ...initialState,
                                    suspendPerson: {
                                      ...initialState.suspendPerson,
                                      revokeSdate: arr[0]
                                      //revokeSdate: date.format().split("T")[0],
                                    }
                                  });

                                  console.log("initialState.suspendPerson.revokeYear =>", initialState.suspendPerson.revokeYear)

                                  if (date && initialState.suspendPerson.revokeYear) {

                                    /*                           dateSelect.setFullYear(
                                                                dateSelect.getFullYear() + initialState.suspendPerson.revokeYear
                                                              );
                          
                           */
                                    const end_date = suspendEndDate(
                                      date,
                                      initialState.suspendPerson.revokeYear
                                    );


                                    setInitialState({
                                      ...initialState,
                                      suspendPerson: {
                                        ...initialState.suspendPerson,
                                        revokeSdate: arr[0],
                                        revokeEdate: moment(new Date(end_date)).format("YYYY-MM-DD"),
                                        revokeYear: initialState.suspendPerson.revokeYear
                                      }
                                    });
                                  }

                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <ListboxSuspendYear
                                disabled={
                                  !initialState.suspendPerson.revokeFlag
                                }
                                defaultValue={{ id: 10 }}
                                onChange={(evt, newValue) => {
                                  if (newValue) {

                                    setInitialState({
                                      ...initialState,
                                      suspendPerson: {
                                        ...initialState.suspendPerson,
                                        revokeYear: newValue.id
                                      }
                                    });
                                    if (
                                      initialState.suspendPerson.revokeSdate &&
                                      newValue.id
                                    ) {
                                      const end_date = suspendEndDate(
                                        initialState.suspendPerson.revokeSdate,
                                        newValue.id
                                      );

                                      setInitialState({
                                        ...initialState,
                                        suspendPerson: {
                                          ...initialState.suspendPerson,
                                          revokeYear: newValue.id,

                                          revokeEdate: moment(
                                            new Date(end_date)
                                          ).format("YYYY-MM-DD")
                                        }
                                      });
                                    }
                                  }
                                }}
                                title={"ระยะเวลาที่ถูกเพิกถอน "}
                              ></ListboxSuspendYear>
                              <p style={{ color: "red", fontSize: "14px" }}>
                                {" *กรณี เพิกถอนสิทธิสมัครรับเลือกตั้งตอลอดไปให้เลือกระยะเวลาที่ถูกเพิกถอนเป็น 100 ปี "}
                              </p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <Datepicker
                                disabled={
                                  !initialState.suspendPerson.revokeFlag
                                }
                                id="date-picker-president_3"
                                label="วันที่พ้นการถูกเพิกถอน"
                                value={initialState.suspendPerson.revokeEdate}
                                onChange={date => {
                                  setInitialState({
                                    ...initialState,
                                    suspendPerson: {
                                      ...initialState.suspendPerson,
                                      revokeEdate: date.format().split("T")[0]
                                    }
                                  });
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <ListboxSuspendStatus
                                disabled={true}
                                flagStatus={2}
                                title={"สถานะการถูกเพิกถอนสิทธิสมัครฯ"}
                                defaultValue={{ id: 2 }}
                              //value={initialState.suspendPerson.revokeStatusId}
                              /* onChange={(evt, newValue) => {
                              setInitialState({
                                ...initialState,
                                suspendPerson: {
                                  ...initialState.suspendPerson,
                                  revokeStatusId: newValue.id
                                }
                              });
                            }} */
                              ></ListboxSuspendStatus>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <FileUpload
                                disabled={
                                  !initialState.suspendPerson.revokeFlag
                                }
                                id="file-upload_2"
                                label="แนบไฟล์ (เฉพาะ PDF เท่านั้น)"
                                remove
                                download
                                fileName={
                                  initialState.suspendPerson.revokeFileName
                                }
                                url={revokeUrlFile}
                                onFileRemove={() => {
                                  setRevokeUrlFile("");
                                  setInitialState({
                                    ...initialState,
                                    suspendPerson: {
                                      ...initialState.suspendPerson,
                                      revokeFileName: ""
                                    }
                                  });
                                }}
                                onFileHandler={(e, f, progressCb) => {
                                  uploadFile("suspend", f, progressCb)
                                    .then(r => {
                                      const data = r.data.data;

                                      if (data.fileId) {
                                        setRevokeUrlFile(
                                          getFileDownloadUrl(
                                            "suspend",
                                            data.fileId
                                          )
                                        );

                                        setInitialState({
                                          ...initialState,
                                          suspendPerson: {
                                            ...initialState.suspendPerson,
                                            revokeFileUUID: data.fileId,
                                            revokeFileName: data.fileName
                                          }
                                        });
                                      }

                                      sweetAlerts.success("แนบไฟล์เรียบร้อย");
                                    })
                                    .catch(err => {
                                      console.log(err);
                                    });
                                }}
                                fileType="application/pdf"
                                btnName={<>Browse</>}
                                btnProps={{
                                  color: "primary"
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
                <br /> <br />
                <GridContainer justify="space-between" alignItems="center">
                  <GridItem xs={6} sm={6} md={6}></GridItem>
                  <GridItem>
                    <Button
                      onClick={() =>
                        history.push({
                          pathname: "listSuspend"
                        })
                      }
                    >
                      ย้อนกลับ
                    </Button>
                    <Button
                      color="success"
                      onClick={handelSubmit}
                      disabled={foundSuspend || !isIdCardValid || !(suspendPersonId === "")}
                    >
                      บันทึกข้อมูล
                    </Button>
                  </GridItem>
                </GridContainer>
                <br /> <br />
                {/* พ.ร.บ. ของระงับ */}
                {foundSuspend === false &&
                  initialState.suspendPerson.suspendFlag ? (
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={
                        suspendPersonId === ""
                          ? { pointerEvents: "none", opacity: "0.4" }
                          : {}
                      }
                    >
                      <AddLawSuspend
                        suspendLaw={suspendLaw}
                        suspendPersonId={suspendPersonId}
                        status={1}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
                <br></br>
                {/* พ.ร.บ. ของเพิกถอน */}
                {foundSuspend === false &&
                  initialState.suspendPerson.revokeFlag ? (
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={
                        suspendPersonId === ""
                          ? { pointerEvents: "none", opacity: "0.4" }
                          : {}
                      }
                    >
                      <AddLawRevoke
                        revokeLaw={revokeLaw}
                        suspendPersonId={suspendPersonId}
                        status={2}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
  return content;
};
export default AddPerson;
