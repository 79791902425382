import React, { useContext, useState } from "react";
import {
  getSearchLists,
  updateStatusVoteCardFollow
} from "contexts/API/VoteCard/Follow";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import ConvertDate from "utils/ConvertDate";
import { DataGrid } from "@material-ui/data-grid";
import DatepickerVotedate from "components/Common/Datepicker";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxProvince from "components/Common/ListboxProvince";
import LoadingData from "components/Common/LoadingData";
import { VoteDateContext } from "contexts/VoteDate";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const FollowCard = ({ pageProps, setPageProps }) => {
  const useStyles = makeStyles(theme => ({
    root: {
      "& .MuiDataGrid-columnHeaderWrapper": {
        backgroundColor: "rgb(221, 221, 221)"
      },
      "& .MuiDataGrid-window": {
        backgroundColor: "white"
        //  overflowX: "auto"
      },
      "& .MuiDataGrid-renderingZone": {
        minHeight: "60px !important",
        maxHeight: "max-content !important"
      },
      "& .MuiDataGrid-row": {
        minHeight: "60px !important",
        maxHeight: "max-content !important"
      },
      "& .MuiDataGrid-cell": {
        alignItems: "center",
        minHeight: "60px !important",
        maxHeight: "max-content !important"
      }
    },
    dataGrid: {
      //  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      borderRadius: 3,
      border: 0,
      // color: "white",
      // height: 48,
      // padding: "0 30px",
      // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      //width: "1600px"
    }
  }));

  const { sweetAlerts } = useSweetAlert();
  const [loading, setLoading] = useState(false);

  const {
    provinceSelected,

    startDate,
    endDate,

    setStartDate,
    setEndDate,
    setProvinceSelected,
    setOptTypeSelected,
    setAmphurSelected,
    setTambolSelected,
    setOptSelected,
    setVotecardPrintingSelected,

    onProvinceSelected
  } = useContext(VoteDateContext);
  const handelSubmit = () => {
    let postData = selectionModel.map(function (value) {
      return {
        voteCardPurchaseDetailId: value
      };
    });

    if (postData === null) {
      sweetAlerts.warning(
        "ไม่พบรายการที่เลือก กรุณาเลือกรายการรับบัตรเลือกตั้ง"
      );
      return;
    } else if (postData.length === 0) {
      sweetAlerts.warning(
        "ไม่พบรายการที่เลือก กรุณาเลือกรายการรับบัตรเลือกตั้งด้วย"
      );
      return;
    }

    updateStatusVoteCardFollow(postData)
      .then(result => {
        if (result.data.success) {
          sweetAlerts.success("บันทึกข้อมูลเรียบร้อย");
          onClickSearch();
        }
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.response.data.desc);
      });
    // setChecked(event.target.checked);
  };
  const [selectionModel, setSelectionModel] = useState([]);
  const classes = useStyles();

  const [columns] = useState([
    {
      field: "id",
      // minWidth: 150,
      hide: true
    },
    {
      headerName: "จังหวัด",
      field: "provinceName",
      resizable: false,
      width: 150,
      renderCell: params => {
        return params.provinceName;
      }
    },

    {
      headerName: "ชื่อ อปท.",
      field: "optName",
      resizable: false,
      width: 120,
      renderCell: params => {
        return params.optName;
      }
    },
    /* {
      headerName: "ประเภท",
      field: "optTypeName",
      resizable: false,
      width: 150,
      renderCell: params => {
        return params.optTypeName;
      }
    }, */
    {
      headerName: "ตำแหน่ง",
      field: "positionTypeShortName",
      resizable: false,
      width: 150,
      renderCell: params => {
        return params.positionTypeShortName;
      }
    },
    {
      headerName: "วันที่เลือกตั้ง",
      field: "voteDate",
      width: 200,
      resizable: false,
      renderCell: params => {
        return ConvertDate(params.value);
      }
    },

    {
      headerName: "รายละเอียดการจัดสรรบัตรเลือกตั้ง",
      field: "voteCardAllocate",
      resizable: false,
      width: 350,
      renderCell: params => {
        let t = "";
        if (params.row.voteCardAllocate.length > 0) {
          t = (
            <ul>
              {params.row.voteCardAllocate.map((post, index) => (
                <li key={index}>
                  {post.cardNumber} สี{post.colorName} เล่มที่{" "}
                  {post.allocateStartNo} ถึง {post.allocateEndNo} จำนวน{" "}
                  {post.volumnAllocate} เล่ม
                </li>
              ))}
            </ul>
          );
        }
        return t;
      }
    },
    {
      headerName: "สถานะการรับบัตร ",
      field: "voteCardPurchaseStatusName",
      resizable: false,
      width: 270,
      renderCell: params => {
        if (params.row.voteCardPurchaseStatusId === 12) {
          return (
            <span style={{ color: "red" }}>
              {params.row.voteCardPurchaseStatusName}
            </span>
          );
        } else {
          return params.row.voteCardPurchaseStatusName;
        }
      }
    },
    {
      headerName: "วิธีการรับบัตรเลือกตั้ง",
      field: "receiveTypeName",
      resizable: false,
      width: 200,
      renderCell: params => {
        return params.receiveTypeName;
      }
    },
    {
      headerName: "วันที่ส่ง/รับบัตร",
      field: "receiveDate",
      resizable: false,
      width: 200,
      renderCell: params => {
        return ConvertDate(params.value);
      }
    }
  ]);

  const [data, setData] = useState([]);

  const onClickCancel = e => {
    setStartDate(null);
    setEndDate(null);
    setProvinceSelected(null);
    setOptTypeSelected(null);
    setAmphurSelected(null);
    setTambolSelected(null);
    setOptSelected(null);
    setVotecardPrintingSelected(null);
    setData([]);
  };
  const optQueryUrl = () => {
    let endPoint = "";

    let arr1 = startDate.format().split("T");

    let arr2 = endDate.format().split("T");

    endPoint += `?&provinceID=${provinceSelected.id}&startVoteDate=${arr1[0]}&endVoteDate=${arr2[0]}`;

    return endPoint;
  };
  const onClickSearch = e => {
    if (provinceSelected === null) {
      //
      sweetAlerts.warning("กรุณาระบุจังหวัด");
    }

    if (startDate === null || endDate === null) {
      //
      sweetAlerts.warning("กรุณาระบุวันที่เริ่มต้น/สิ้นสุด");
    }
    if (startDate !== null && endDate !== null && provinceSelected !== null) {
      setLoading(true);

      getSearchLists(optQueryUrl())
        .then(result => {
          // const filterData = groupBy(result.data.data, "printing_houses_name");

          result.data.data.map(function (d) {
            d.id = d.voteCardpurchaseDetailId;
          });

          setData(result.data.data);
          console.log(result.data.data);

          setLoading(false);
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", err.response.data.desc);
          setLoading(false);
        });
    }
  };

  //const [checkStatus] = useState([]);

  return (
    <>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4>ค้นหารายการรับบัตรเลือกตั้ง</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <ListboxProvince
                  required={true}
                  value={provinceSelected}
                  onChange={onProvinceSelected}
                ></ListboxProvince>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <DatepickerVotedate
                  id="begin"
                  valid={true}
                  label="ตั้งแต่วันที่เลือกตั้ง"
                  value={startDate}
                  onChange={setStartDate}
                ></DatepickerVotedate>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <DatepickerVotedate
                  id="endDate"
                  label="ถึงวันที่"
                  valid={true}
                  value={endDate}
                  onChange={setEndDate}
                ></DatepickerVotedate>
              </GridItem>
            </GridContainer>

            <br />
            <br />
            <GridContainer
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <GridItem xs={6} sm={6} md={6}></GridItem>
              <GridItem>
                <Button onClick={onClickCancel}>ยกเลิก</Button>
                <Button
                  type="button"
                  color="info"
                  className={classes.buttoncustom}
                  onClick={onClickSearch}
                >
                  ค้นหา
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </Card>
      {loading ? (
        <LoadingData />
      ) : (
        <>
          <GridContainer
            justify="space-between"
            direction="column"
            alignItems="flex-end"
          >
            <GridItem>
              <Button color="success" onClick={handelSubmit}>
                บันทึกข้อมูล
              </Button>
            </GridItem>
          </GridContainer>
          <div
            style={{ height: "600px", width: "100%", overflow: "auto" }}
            className={classes.root}
          >
            <DataGrid
              className={classes.dataGrid}
              columns={columns}
              rows={data}
              //autoHeight={true}
              // rowHeight={true}
              //    rowHeight={"auto"}
              hideFooter={true}
              checkboxSelection
              // scrollbarSize={15}
              autoWidth={true}
              // showBorders={true}
              // maxColumns={10}
              isRowSelectable={params =>
                params.row.voteCardPurchaseStatusId !== 12
              }
              sortModel={[
                {
                  field: "id",
                  sort: "asc"
                }
              ]}
              onSelectionModelChange={newSelection => {
                setSelectionModel(newSelection.selectionModel);
                //  openModalUpload();
              }}
              selectionModel={selectionModel}
            />
          </div>
        </>
      )}
    </>
  );
};
export default FollowCard;
