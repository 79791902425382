import React, { useState, useEffect } from "react";
import TableComponent from "components/Table/MaterialTable";

import { getTitle } from "contexts/API/MS/MS.js";

const TableHistoryName = props => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    getTitle()
      .then(r => {
        var titleData = r.data.data.map(v => {
          return { id: v.titleId, value: v.titleShortName };
        });

        const _columns = [
          {
            title: "คำนำหน้าชื่อเดิม",
            field: "oldTitleId",
            editable: "never",
            render: rawData => {
              const v = titleData.find(e => e.id === rawData.oldTitleId);
              return v !== undefined ? v.value : "";
            }
          },
          { title: "ชื่อเดิม", field: "oldFirstName", editable: "never" },
          {
            title: "นามสกุลเดิม",
            field: "oldLastName",
            editable: "never"
          },
          {
            title: "คำนำหน้าชื่อใหม่",
            field: "newTitleId",
            render: rawData => {
              const v = titleData.find(e => e.id === rawData.newTitleId);
              return v !== undefined ? v.value : "";
            }
          },
          { title: "ชื่อใหม่", field: "newFirstName" },
          { title: "นามสกุลใหม่", field: "newLastName" }
        ];
        setColumns(_columns);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (props !== null) {
      setData(props.history);
    }
  }, [props]);

  const optionsTable = {};
  return (
    <div>
      <TableComponent
        title={"ประวัติการเปลี่ยนแปลงชื่อ"}
        columns={columns}
        data={data}
        options={optionsTable}
        //  onRowUpdate={onRowUpdate}
      ></TableComponent>
    </div>
  );
};
export default TableHistoryName;
