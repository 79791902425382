export default function WithValidator(onChange, validators, setError, setHelperText) {
    return function (e) {
        if (onChange !== undefined) {
            onChange(e);
        }
        if (validators !== undefined && validators.length > 0) {
            let isError, helperText;
            for (let i = 0; i < validators.length; i++) {
                let validatorFunc = validators[i];
                [isError, helperText] = validatorFunc(e.target.value);
                if (isError) break;
            }
            if( setError !== undefined){
                setError(isError);
            }
            if( setHelperText !== undefined){
                setHelperText(helperText);
            }
        }
    }
}