import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getUseVote(optId, voteDate, query) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/${voteDate}/usevote${query ? "?" + query : ""
    }`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editUseVotePresident(
  optId,
  voteDate,
  optVoteId,
  optVotePresInfoId,
  numRealVote,
  numRightVote,
  numCard,
  numAppearCard,
  numGoodCard,
  numBadCard,

  numNoSelectedCard,
  numRemainCard,
  diffCardRemark

  // numReplace,
  // numMemberNow
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/${voteDate}/usevote`;

  const body = {
    optVoteId: optVoteId,
    optVotePresInfoId: optVotePresInfoId,
    optPositionTypeId: 1,
    numRealVote: Number(numRealVote),
    numRightVote: Number(numRightVote),
    numCard: Number(numCard),
    //numRightVote: Number(numRightVote),
    numGoodCard: Number(numGoodCard),
    numBadCard: Number(numBadCard),
    numNoSelectedCard: Number(numNoSelectedCard),
    numRemainCard: Number(numRemainCard),
    diffCardRemark: diffCardRemark,
    numAppearCard: Number(numAppearCard),
    numRightVoteMale: 0,
    numRightVoteFemale: 0,
    numRealVoteMale: 0,
    numRealVoteFemale: 0,
    numReplace: 0,
    numMemberNow: 0
  };

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editUseVoteMember(
  optId,
  voteDate,
  optAreaVoteId,
  optAreaId,
  optVoteId,
  optVotePresInfoId,
  numRealVote,
  numRightVote,
  numCard,
  numAppearCard,
  numGoodCard,
  numBadCard,
  numNoSelectedCard,
  numRemainCard,
  diffCardRemark

  // numReplace,
  // numMemberNow,
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/${voteDate}/usevote`;

  const body = {
    optAreaVoteId: optAreaVoteId,
    optAreaId: optAreaId,
    optVoteId: optVoteId,
    optPositionTypeId: 2,
    // numReplace: numReplace,
    // numMemberNow: numMemberNow,
    numStationNow: 1,
    diffCardRemark: diffCardRemark,
    numRightVote: Number(numRightVote),
    numCard: Number(numCard),
    numRealVote: Number(numRealVote),
    numGoodCard: Number(numGoodCard),
    numNoSelectedCard: Number(numNoSelectedCard),
    numBadCard: Number(numBadCard),
    numAppearCard: Number(numAppearCard),
    numRemainCard: Number(numRemainCard),
    diffCardRemark: diffCardRemark,
    numRightVoteMale: 0,
    numRightVoteFemale: 0,
    numRealVoteMale: 0,
    numRealVoteFemale: 0
    /* 
    optVoteId: optVoteId,
    optAreaId: optAreaId,
    optVotePresInfoId: optVotePresInfoId,
    optPositionTypeId: 2,
    numRealVote: Number(numRealVote),
    numRightVote: Number(numRightVote),
    numCard: Number(numCard),
    //numRightVote: Number(numRightVote),
    numGoodCard: Number(numGoodCard),
    numBadCard: Number(numBadCard),
    numNoSelectedCard: Number(numNoSelectedCard),
    numRemainCard: Number(numRemainCard),
    diffCardRemark: Number(diffCardRemark),
    numAppearCard: Number(numAppearCard),
    numRightVoteMale: 0,
    numRightVoteFemale: 0,
    numRealVoteMale: 0,
    numRealVoteFemale: 0,
    numReplace: numReplace,
    numMemberNow: numMemberNow */
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function bulkUpdateUseVote(optId, voteDate, body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/${optId}/vote/date/${voteDate}/usevote`;
  let arr_2 = body.map(e => Object.assign({}, e));
  arr_2.forEach(elm => delete elm.tableData);
  arr_2.forEach(elm => delete elm.areaNo);

  return axios.put(url, arr_2, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
