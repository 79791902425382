import React, { useState } from "react";

import AddCandidate from "./AddCandidate";
import Candidate from "./Candidate";
import EditCandidate from "./EditCandidate";
import { InspectorContextProvider } from "contexts/Inspector";

const Index = props => {
  const SwitchComponents = ({ active, children }) => {
    return children.filter(child => child.props.name === active);
  };
  // Local State
  const [pageProps, setPageProps] = useState({
    active: "Candidate",
    props: null
  });

  return (
    <InspectorContextProvider>
      {window.scrollTo(0, 0)}
      <SwitchComponents active={pageProps.active}>
        <AddCandidate
          name="AddCandidate"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <Candidate
          name="Candidate"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <EditCandidate
          name="EditCandidate"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
      </SwitchComponents>
    </InspectorContextProvider>
  );
};

export default Index;
