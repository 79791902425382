import React, { useContext, useState } from "react";

//วันที่รับสมัคร
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HandelError from "utils/HandleError";
import { InspectorContext } from "contexts/Inspector";
import ListboxInspectCadidateDate from "components/Common/ListboxInspectCadidateDate";
import LoadingData from "components/Common/LoadingData";
//import { InspectorContext } from "contexts/Inspector";
import MaterialTable from "components/Table/MaterialTable";
import { Paper } from "@material-ui/core";
import { getReport01 } from "contexts/API/INSPEC/Moniter";
import { useSweetAlert } from "components/Alert/SweetAlert";

const SelectionInspector = props => {
  const [data, setData] = useState([]);
  const { sweetAlerts } = useSweetAlert();
  const [loading, setLoading] = useState(false);
  const optionsTable = {};

  //const inspectorContext = useContext(InspectorContext);
  const {
    onInspectCadidateDateSelected,
    inspectCadidateDateSelected
  } = useContext(InspectorContext);
  const columns = [
    {
      title: "จังหวัด",
      field: "province_name"
    },
    {
      title: "จำนวนผู้สมัคร",
      field: "applicants",
      cellStyle: {
        minWidth: "150px",
        alignItems: "center"
      }
    },
    {
      title: "คัดเลือก 16 คน",
      field: "selected_16",
      cellStyle: {
        minWidth: "150px"
      }
    },
    {
      title: "อัปโหลด ผตล.4",
      field: "isUploadFile16",
      cellStyle: {
        minWidth: "150px"
      },
      render: rawData => {
        return rawData.isUploadFile16 === "true" ? (
          <CheckIcon style={{ color: "#4caf50" }} />
        ) : (
          <ClearIcon style={{ color: "#f44336" }} />
        );
      }
    },
    {
      title: "คัดเลือก 8 คน",
      field: "selected_8",
      cellStyle: {
        minWidth: "150px"
      }
    },
    {
      title: "อัปโหลดไฟล์",
      field: "isUploadFile8",
      cellStyle: {
        minWidth: "150px"
      },
      render: rawData => {
        return rawData.isUploadFile8 === "true" ? (
          <CheckIcon style={{ color: "#4caf50" }} />
        ) : (
          <ClearIcon style={{ color: "#f44336" }} />
        );
      }
    }
  ];

  const handelSubmit = async () => {
    if (!inspectCadidateDateSelected) {
      sweetAlerts.warning("กรุณาเลือกวันที่สมัคร");
    } else {
      setLoading(true);
      try {
        const res = await getReport01(inspectCadidateDateSelected.id);
        setData(res.data.data);
        setLoading(false);
      } catch (error) {
        HandelError(error);
        setLoading(false);
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้", error.toString());
      }
    }
  };
  return (
    <div>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <ListboxInspectCadidateDate
                  period={true}
                  required={true}
                  inspectCadidateDateSelected={inspectCadidateDateSelected}
                  onChange={onInspectCadidateDateSelected}
                ></ListboxInspectCadidateDate>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}></GridItem>
            </GridContainer>
            <br /> <br />
            <GridContainer
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <GridItem xs={6} sm={6} md={6}></GridItem>
              <GridItem>
                <Button>ยกเลิก</Button>
                <Button color="info" onClick={handelSubmit}>
                  ค้นหา
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>

      <GridContainer>
        {loading ? (
          <LoadingData />
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <MaterialTable
              title={"สรุปข้อมูลการคัดเลือกผู้ตรวจการเลือกตั้ง"}
              options={optionsTable}
              columns={columns}
              data={data}
              components={{
                Container: props => <Paper {...props} elevation={0} />
              }}
            />
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
};
export default SelectionInspector;
