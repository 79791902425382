import React, { createRef, useState } from "react";

import AtLeastOne from "utils/Validators/AtLeastOne";
import Button from "components/CustomButtons/Button.js";
import CustomInputWithValidator from "components/CustomInput/CustomInputWithValidator";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOpttype from "components/Common/ListboxOpttype";
// Core Components
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import MustNotNull from "utils/Validators/MustNotNull";
import Slide from "@material-ui/core/Slide";
// Material Core
import { makeStyles } from "@material-ui/core/styles";
// Context APIs
import { upgradeOpt } from "contexts/API/OPT/Opt";
import { useSweetAlert } from "components/Alert/SweetAlert";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalUpgradeOpt = props => {
  const { show, closeModal, data } = props;

  const [_listboxOptSelected, _setListboxOptSelected] = useState({
    id: data.optSubTypeId
  });
  const [_provinceSelected, _setProvinceSelected] = useState({
    id: data.provinceId
  });
  const [_amphurSelected, _setAmphurSelected] = useState({ id: data.amphurId });
  const [_tambolSelected, _setTambolSelected] = useState({ id: data.tambolId });

  const { sweetAlerts } = useSweetAlert();

  const classes = useStyles();
  const optNameRef = createRef();

  const onSubmit = () => {
    let optSubTypeId = null;
    let provincedId = null;
    let amphurId = null;
    let tambolId = null;
    let optName = null;

    if (_listboxOptSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภท อปท.");
      return;
    } else {
      optSubTypeId = _listboxOptSelected.id;
    }

    if (_provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    } else {
      provincedId = _provinceSelected.id;
    }

    if ([2, 3, 4, 5].includes(optSubTypeId)) {
      if (_amphurSelected === null) {
        sweetAlerts.warning("กรุณาเลือกอำเภอ");
        return;
      } else {
        amphurId = _amphurSelected.id;
      }
    }

    if ([4, 5].includes(optSubTypeId)) {
      if (_tambolSelected === null) {
        sweetAlerts.warning("กรุณาเลือกตำบล");
        return;
      } else {
        tambolId = _tambolSelected.id;
      }
    }

    if (optNameRef.current.value === "") {
      sweetAlerts.warning("กรุณาระบุชื่อ อปท.");
      return;
    } else {
      optName = optNameRef.current.value;
    }

    upgradeOpt(
      data.oldOptId,
      optSubTypeId,
      optName,
      provincedId,
      amphurId,
      tambolId,
      1
    )
      .then(result => {
        sweetAlerts.success("ยกฐานะ อปท. สำเร็จแล้ว");
        closeModal();
      })
      .catch(err => {
        console.log("err >>", err);
        sweetAlerts.error("ไม่สามารถยกฐานะได้", err.response.data.desc);
        closeModal();
      });
  };

  return (
    <div className={show ? "modal" : "hide"}>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={show}
        fullWidth={true}
        transition={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          className={classes.titlemodal}
        >
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            {" " + props.title}
          </div>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <form className={classes.form}>
            <FormControl>
              <ListboxOpttype
                defaultValue={_listboxOptSelected}
                validators={[AtLeastOne]}
                onChange={(e, n) => {
                  if (n) {
                    _setListboxOptSelected(n);
                  }
                }}
              ></ListboxOpttype>
              <ListboxProvince
                validators={[AtLeastOne]}
                defaultValue={_provinceSelected}
                title={"เลือกจังหวัด"}
                onChange={(e, n) => {
                  _setProvinceSelected(n);
                }}
                customProps={{
                  disabled: true
                }}
              ></ListboxProvince>

              {[2, 3, 4, 5, 6, 7].includes(_listboxOptSelected.id) ? (
                <ListboxDistinct
                  defaultValue={_amphurSelected}
                  provinceSelected={_provinceSelected}
                  title={"เลือกอำเภอ"}
                  onChange={(e, n) => {
                    _setAmphurSelected(n);
                  }}
                ></ListboxDistinct>
              ) : null}
              {[5, 6, 7].includes(_listboxOptSelected.id) ? (
                <ListboxSubdistinct
                  provinceSelected={_provinceSelected}
                  amphurSelected={_amphurSelected}
                  defaultValue={_tambolSelected}
                  title={"เลือกตำบล"}
                  onChange={(e, n) => {
                    _setTambolSelected(n);
                  }}
                ></ListboxSubdistinct>
              ) : null}
              <CustomInputWithValidator
                name="optname"
                validators={[MustNotNull]}
                labelText="ชื่อ อปท. *"
                inputProps={{
                  inputRef: optNameRef
                }}
              />
            </FormControl>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={closeModal}>ยกเลิก</Button>
              <Button onClick={onSubmit} variant="contained" color="success">
                บันทึก
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalUpgradeOpt;
