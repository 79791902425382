// core components
import Button from "components/CustomButtons/Button.js";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import Slide from "@material-ui/core/Slide";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  titlemodal: {
    fontFamily: `"Athiti", sans-serif`
  },
  rootx: {
    color: "#5f5c5c",
    paddingLeft: "100px"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ConfirmDelete = props => {
  const {
    showComfirmDialog,
    closeModalConfirm,
    confirmDeleted,
    bodyTextDelModal,
    deletedvalue,
    deletedId
  } = props;

  const classes = useStyles();
  return (
    <div className={showComfirmDialog ? "modal" : "hide"}>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={showComfirmDialog}
        transition={Transition}
        keepMounted
        onClose={closeModalConfirm}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          classes={{
            root: classes.titlemodal
          }}
        >
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            {props.title}
            <Button
              justIcon
              className={classes.rootx}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={closeModalConfirm}
            >
              <Close className={classes.modalClose} />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <h5>{bodyTextDelModal}</h5>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={closeModalConfirm}>ยกเลิก</Button>
          <Button
            onClick={() => {
              confirmDeleted(deletedvalue[0], deletedId);
            }}
            color="danger"
          >
            ลบข้อมูล
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDelete;
