import React, { useContext, useEffect, useState } from "react";

import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";
import CheckboxSuspendStatus from "components/Common/CheckboxSuspendStatus";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import FileSaver from "file-saver";
import { FileUpload } from "components/Common/FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ImportCheckIdFile from "assets/resources/opt/import_check_id.xlsx";
//import ListboxSuspendStatus from "components/Common/ListboxSuspendStatus.js";
import ListboxSuspendYear from "components/Common/ListboxSuspendYear";
import LoadingData from "components/Common/LoadingData";
import NumberFormat from "react-number-format";
import { Paper } from "@material-ui/core";
//import ListboxProvince from "components/Common/ListboxProvince.js";
import { REPORTING_SERVICE } from "config/env.conf";
import { SuspendContext } from "contexts/Suspend";
import TableComponent from "components/Table/MaterialTable";
import XLSX from "xlsx";
import { checkSuspendPerson } from "contexts/API/SUSPEND/CheckSuspend";
//import checkSuspendPerson from "mock/TestHelper";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useSweetAlert } from "components/Alert/SweetAlert";
import validateId from "utils/Validators/ThaiCitizenID";

const CheckImportfile = props => {
  const { sweetAlerts } = useSweetAlert();
  const [orgName, setOrgName] = useState(null);
  //const [cerrentDate, setCerrentDate] = useState();
  const [AllIDs, setAllIDs] = useState(null);
  const [IDs, setIDs] = useState(null);
  const [ListIDs, setListIDs] = useState(null);
  const [InvalidIDs, setInvalidIDs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [suspendStatus, setSuspendStatus] = useState("");
  const [orgNameState, setOrgNameState] = useState(true);
  const [keyTempIdCard, setKeyTempIdCard] = useState(null);

  const [disableStatus, setDisableStatus] = useState(true);

  const optionsTable = {
    paging: false,
    search: false,
    exportButton: false
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "ลำดับ",
      field: "rowId",
      cellStyle: {
        minWidth: "25px",
        textAlign: "center"
      }
    },
    {
      title: "เลขประจำตัวประชาชน",
      field: "idCard",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      }
    },
    {
      title: "ผลการตรวจระงับสิทธิสมัครฯ",
      field: "suspendStatusDetail"
    },
    {
      title: "ผลการตรวจเพิกถอนสิทธิสมัครฯ",
      field: "revokeStatusDetail"
    }
  ]);

  const {
    //onProvinceSelected,
    //provinceSelected,
    yearSelected,
    supspendCheckBoxSelected,
    onSupspendCheckBoxSelected,
    onYearSelected,
    getSupspendCheckboxLists
  } = useContext(SuspendContext);

  const onExceclFileHandler = e => {
    try {
      setData([]);
      let errStr = [];
      let AllIDLists = [];
      let IDLists = [];
      let ValitIDLists = [];
      let InvIDLists = [];
      let data = new Uint8Array(e);
      let workbook = XLSX.read(data, { type: "array" });
      let ws = workbook.Sheets[workbook.SheetNames[0]];
      let rows = XLSX.utils.sheet_to_json(ws, { header: ["A"] });

      if (rows.length <= 1) {
        throw new Error("ไม่มีข้อมูล");
      }

      if (rows[0].A !== "เลขประจำตัวประชาชน") {
        throw new Error("กรุณาใช้ไฟล์ที่ export ออกจากระบบเท่านั้น");
      }

      for (let i = 1; i < rows.length; i++) {
        const [isError, errDetail] = validateId(rows[i].A.toString());

        if (isError) {
          let error = "เซลล์ที่ A" + (rows[i].__rowNum__ + 1) + " " + errDetail;

          errStr.push(
            <div key={"err" + i} style={{ textAlign: "left" }}>
              เซลล์ที่ A{rows[i].__rowNum__ + 1} '{rows[i].A}'{" "}
              <u
                style={{
                  borderBottom: "1px  dotted red",
                  textDecoration: "none"
                }}
              >
                {errDetail}
              </u>
            </div>
          );
          InvIDLists.push({
            rowId: rows[i].__rowNum__,
            idCard: rows[i].A.toString(),
            resultRevoke: [error],
            resultSuspend: [error]
          });
          AllIDLists.push(rows[i].A.toString());
        } else {
          AllIDLists.push(rows[i].A.toString());
          IDLists.push(rows[i].A.toString());
          ValitIDLists.push({
            rowId: rows[i].__rowNum__,
            idCard: rows[i].A.toString()
          });
        }
      }
      setAllIDs(AllIDLists);
      setListIDs(ValitIDLists);
      setIDs(IDLists);
      setInvalidIDs(InvIDLists);

      //console.log("InvIDLists>> ",InvIDLists)

      if (errStr.length > 0) {
        sweetAlerts.error(
          "เกิดข้อผิดพลาดสำหรับไฟล์ที่นำเข้า ต้องการดำเนินการต่อหรือไม่",
          <>
            <div style={{ textAlign: "left" }}>
              {" "}
              <b>มีข้อผิดพลาดดังนี้: </b>
            </div>
            <div style={{ overflow: "auto", height: "150px" }}>{errStr}</div>,
            <div style={{ textAlign: "left" }}>
              <b>
                จำนวนทั้งหมด: <Badge color="success">{rows.length - 1} </Badge>{" "}
                กรอกข้อมูลผิดพลาด: <Badge color="danger">{errStr.length}</Badge>
              </b>
            </div>
          </>,
          () => {
            //onConfirmed
            //do nothing
          },
          true,
          () => {
            //onCanceled
            setIDs(null);
          }
        );
      } else {
        sweetAlerts.success(
          "นำเข้าข้อมูลสำเร็จ",
          <>
            <div style={{ textAlign: "center" }}>
              <b>
                จำนวนทั้งหมด: <Badge color="success">{rows.length - 1} </Badge>{" "}
                กรอกข้อมูลผิดพลาด: <Badge color="danger">{errStr.length}</Badge>
              </b>
            </div>
          </>,
          () => {
            //onConfirmed
            //do nothing
          },
          false
        );
      }
    } catch (err) {
      sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
    }
  };

  const onSearchHandler = () => {
    let year = 0;
    let suspendStatus = null;

    if (!IDs) {
      sweetAlerts.warning("กรุณาระบุเลขประจำตัวประชาชน");
      return;
    }

    if (!yearSelected) {
      year = 0;
    } else {
      year = yearSelected.id;
    }

    setSuspendStatus(getSupspendCheckboxLists());

    /*  if (suspendStatusSelected && suspendStatusSelected.id) {
       suspendStatus = suspendStatusSelected;
     } */

    const today = new Date();
    today.setFullYear(today.getFullYear() - year);
    const checkDate = today.toISOString().split("T")[0];

    if (orgNameState === "" && orgName === null) {
      setOrgNameState(false);
    }
    if (orgName === null) {
      sweetAlerts.warning("กรุณาระบุหน่วยงาน");
      return;
    }
    if (checkDate === null) {
      sweetAlerts.warning(
        "กรุณาเลือกระยะเวลาถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ"
      );
      return;
    }
    setLoading(true);

    checkSuspendPerson(
      checkDate,
      IDs,
      null,
      null,
      suspendStatus,
      AllIDs.toString()
    )
      .then(result => {
        let ListID_temp = ListIDs;
        const dataUniqKey = {};
        setKeyTempIdCard(result.data.data.keyTempIdCard);
        result.data.data.result.forEach(v => {
          let revokeStatusDetail = ""; //เพิกถอนสิทธิสมัคร
          let suspendStatusDetail = ""; //ระงับสิทธิสมัคร
          let __level__ = 0;

          //console.log("f ListIDs >>" ,ListIDs);

          let rowId = ListID_temp.find(x => x.idCard === v.idCard).rowId;
          if (rowId !== null) {
            ListID_temp = ListID_temp.filter(r => {
              return r.rowId !== rowId;
            });
          }

          if (
            !v.suspendEdate ||
            !v.suspendSdate
            /* ถ้าไม่มี suspendEdate ให้ถือว่ายังไม่เคยดำรงตำแหน่ง */
          ) {
            __level__ = 0;
            suspendStatusDetail = "--ไม่พบข้อมูล--";
          } else {
            __level__ = 1;
            suspendStatusDetail = `${ConvertDate(
              v.suspendSdate
            )} - ${ConvertDate(v.suspendEdate)}`;
            /* )} จ.${v.provinceName}`; */
          }

          if (
            !v.revokeEdate ||
            !v.revokeSdate
            /* ถ้าไม่มี revokeEdate ให้ถือว่ายังไม่เคยดำรงตำแหน่ง */
          ) {
            __level__ = 0;
            revokeStatusDetail = "--ไม่พบข้อมูล--";
          } else {
            __level__ = 1;
            revokeStatusDetail = `${ConvertDate(v.revokeSdate)} - ${ConvertDate(
              v.revokeEdate
            )}`;
            /* )} จ.${v.provinceName}`; */
          }

          if (dataUniqKey[v.idCard + rowId] === undefined) {
            /* บันทึกสถานะ */
            dataUniqKey[v.idCard + rowId] = {
              rowId: rowId,
              idCard: v.idCard,
              resultRevoke: [revokeStatusDetail],
              resultSuspend: [suspendStatusDetail],
              __level__: __level__
            };
          } else if (__level__ >= 1) {
            /* เพิ่มประวัติการดำรงตำแหน่ง */
            if (dataUniqKey[v.idCard + rowId].__level__ === 0) {
              dataUniqKey[v.idCard + rowId].resultRevoke = [revokeStatusDetail];
              dataUniqKey[v.idCard + rowId].resultSuspend = [
                suspendStatusDetail
              ];
              dataUniqKey[v.idCard + rowId].__level__ = __level__;
            } else {
              dataUniqKey[v.idCard + rowId].resultRevoke = [
                ...dataUniqKey[v.idCard + rowId].resultRevoke,
                revokeStatusDetail
              ];
              dataUniqKey[v.idCard + rowId].resultSuspend = [
                ...dataUniqKey[v.idCard + rowId].resultSuspend,
                suspendStatusDetail
              ];
              dataUniqKey[v.idCard + rowId].__level__ = __level__;
            }
          } else {
            //do nothing
          }
        });

        setData(InvalidIDs.concat(Object.values(dataUniqKey)));

        //setData(Object.values(dataUniqKey));
        setLoading(false);
        setColumns([
          {
            title: "ลำดับ",
            field: "rowId",
            cellStyle: {
              minWidth: "25px",
              textAlign: "center"
            },
            defaultSort: "asc"
          },
          {
            title: "เลขประจำตัวประชาชน",
            field: "idCard",
            cellStyle: {
              minWidth: "150px"
            },
            render: rowData => {
              return (
                <NumberFormat
                  value={rowData.idCard}
                  format="# #### ##### ## #"
                  style={{
                    fontSize: "15px",
                    width: "100%",
                    border: "0"
                  }}
                />
              );
            }
          },
          {
            title: "ผลการตรวจระงับสิทธิสมัครฯ",
            field: "resultSuspend",
            render: rawData => {
              // return rawData.resultSuspend.map((e, i) => {
              //   return <div key={i}>{e}</div>;
              // });
              let aa = rawData.resultSuspend.map((e, i) => {
                return <div key={i}>{e}</div>;
              });
              return aa;
            },
            cellStyle: {
              minWidth: "350px"
            }
          },
          {
            title: "ผลการตรวจเพิกถอนสิทธิสมัครฯ",
            field: "resultRevoke",
            render: rawData => {
              return rawData.resultRevoke.map((e, i) => {
                return <div key={i}>{e}</div>;
              });
            },
            cellStyle: {
              minWidth: "350px"
            }
          }
        ]);
      })
      .catch(err => {
        sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
      });
  };

  const exportUrl = () => {
    let endPointExp = "";
    let year = 0;

    if (!yearSelected) {
      year = 0;
    } else {
      year = yearSelected.id;
    }

    const today = new Date();
    today.setFullYear(today.getFullYear() - year);
    const checkDate = today.toISOString().split("T")[0];

    /*  endPointExp += `&citizenid=${IDs}
       &date=${checkDate}
       &year=${year !== null ? year : 0}
       &suspendStatus=${suspendStatus.toString()}
       &orgName=${orgName}`; */
    endPointExp += `&keyTempIdCard=${keyTempIdCard}&Status=${suspendStatus.toString() !== "" ? suspendStatus.toString() : 0
      }&date=${checkDate}&orgName=${orgName !== null ? orgName : 0}&year=${year !== null ? year : 0
      }&total=${AllIDs.length}`;

    return endPointExp;
  };

  useEffect(() => {
    if (IDs !== null && orgNameState === false) {
      setDisableStatus(false);
    } else {
      setDisableStatus(true);
    }
  }, [IDs, orgName]);

  const handleSubmitExport = e => {
    e.preventDefault();
    window.open(
      `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2fsuspend%2fsuspend_rpt_PersonCheck&rs:Command=Render&rc:Parameters=Collapsed${exportUrl()}`
    );
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br /> <br />
          <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={12} sm={12} md={12}>
              ดาวน์โหลดแบบฟอร์มการตรวจสอบ :
              <Button
                size="sm"
                color="success"
                onClick={() => {
                  FileSaver.saveAs(ImportCheckIdFile, "import_check_id.xlsx");
                }}
              >
                <FontAwesomeIcon icon={faFileExcel} />
                แบบฟอร์มการตรวจสอบ
              </Button>
              <span
                style={{ fontSize: "14px", color: "red", paddingLeft: "10px" }}
              >
                ตรวจสอบข้อมูลได้ไม่เกิน 5,000 รายการ
              </span>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <FileUpload
                id="file-upload-2"
                label="แนบไฟล์ (เฉพาะ Excel เท่านั้น)"
                remove
                onFileHandler={onExceclFileHandler}
                fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                btnName={<>Browse</>}
                btnProps={{
                  color: "primary"
                }}
              />
              {/*  </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>  */}
              <ListboxSuspendYear
                defaultValue={{ id: 0 }}
                title={"ระยะเวลาที่ตรวจสอบย้อนหลัง"}
                onChange={onYearSelected}
              ></ListboxSuspendYear>
              <CustomInput
                id="orgName"
                required
                labelText="หน่วยงานที่ขอตรวจสอบ (โปรดระบุ)"
                error={orgNameState}
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  autoComplete: "new-password",
                  onChange: e => {
                    if (verifyLength(e.target.value, 1)) {
                      setOrgNameState(false);
                    } else {
                      setOrgNameState(true);
                    }
                    setOrgName(e.target.value);
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CheckboxSuspendStatus
                supspendCheckBoxSelected={supspendCheckBoxSelected}
                onSupspendCheckBoxSelected={onSupspendCheckBoxSelected}
              ></CheckboxSuspendStatus>
            </GridItem>
          </GridContainer>
          {/* <GridContainer
            justify='space-between'
            alignItems='center'
            direction='row'
          >
            <GridItem xs={12} sm={12} md={4}>
              <ListboxSuspendStatus
                flagStatus={null}
                value={suspendStatusSelected}
                onChange={onSuspendStatusSelected}
              ></ListboxSuspendStatus>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <ListboxSuspendYear
                defaultValue={{ id: 0 }}
                title={"ระยะเวลาถูกระงับย้อนหลัง"}
                onChange={onYearSelected}
              ></ListboxSuspendYear>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <ListboxProvince
                value={provinceSelected}
                title={"จังหวัดที่ถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ"}
                onChange={onProvinceSelected}
              ></ListboxProvince>
            </GridItem>

          </GridContainer> */}
          <GridContainer justify="space-between" alignItems="center">
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button
                onClick={() => {
                  setData([]);
                }}
              >
                ยกเลิก
              </Button>
              <Button
                color="info"
                disabled={disableStatus}
                onClick={onSearchHandler}
              >
                ตรวจสอบ
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <br />
      {data.length !== 0 ? (
        <GridContainer justify="space-between" alignItems="center">
          <GridItem xs={6} sm={6} md={6}></GridItem>
          <GridItem>
            นำออกข้อมูล:{" "}
            <Button justIcon round color="success" onClick={handleSubmitExport}>
              <FontAwesomeIcon icon={faFileExcel} />
            </Button>
          </GridItem>
        </GridContainer>
      ) : null}

      {loading === true ? (
        <LoadingData />
      ) : (
        <TableComponent
          title={
            "ผลการตรวจสอบบุคคลที่ถูกระงับสิทธิสมัครรับเลือกตั้ง และการถูกเพิกถอนสิทธิสมัครรับเลือกตั้ง"
          }
          enableInitColumns={false}
          options={optionsTable}
          columns={columns}
          data={data}
          components={{
            Container: props => <Paper {...props} elevation={0} />
          }}
        />
      )}
    </>
  );
};
export default CheckImportfile;
