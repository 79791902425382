import React from "react";
// core components
//import loadingData from "utils/loading.json";
import styles from "assets/css/loading.css";

const LoadingData = props => {
  //  const classes = useStyles();
  // const defaultOption = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: loadingData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice"
  //   }
  // };
  return (
    <>
      <div className="sk-circle">
        <div className="sk-circle1 sk-child"></div>
        <div className="sk-circle2 sk-child"></div>
        <div className="sk-circle3 sk-child"></div>
        <div className="sk-circle4 sk-child"></div>
        <div className="sk-circle5 sk-child"></div>
        <div className="sk-circle6 sk-child"></div>
        <div className="sk-circle7 sk-child"></div>
        <div className="sk-circle8 sk-child"></div>
        <div className="sk-circle9 sk-child"></div>
        <div className="sk-circle10 sk-child"></div>
        <div className="sk-circle11 sk-child"></div>
        <div className="sk-circle12 sk-child"></div>
      </div>
      {/* <FadeIn>
        <Lottie options={defaultOption} height={140} width={140} />
      </FadeIn> */}
    </>
  );
};
export default LoadingData;
