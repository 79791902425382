import {
  blackColor,
  cardTitle,
  container,
  hexToRgb,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.js";

const lockScreenPageStyle = theme => ({
  cardTitle: {
    color: "white"
  },
  container: {
    //   ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "100px"
    },
    paddingBottom: "100px"
  },
  customCardClass: {
    width: "500px",
    margin: "60px auto 0",
    color: whiteColor,
    display: "block",
    transform: "translate3d(" + hexToRgb(blackColor) + ")",
    transition: "all 300ms linear",
    backgroundColor: "#303aa5"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardAvatar: {
    maxWidth: "90px",
    maxHeight: "90px",
    marginTop: "-45px"
  },
  img: {
    paddingTop: "30px"
  },
  textfield: {
    backgroundColor: "#fff",
    color: "#ffff",

    borderRadius: "5px",
    // border: "1px solid #ccc",
    // margin-bottom: 20px;
    // font-size: 14px;
    // font-family: Montserrat;
    // padding: 0 20px 0 50px;
    outline: "none"
  },
  customCardFooterClass: {
    border: "none",
    paddingTop: "0"
  },
  justifyContentCenter: {
    justifyContent: "center !important",
    paddingBottom: "20px"
  }
});

export default lockScreenPageStyle;
