import React, { useState } from "react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ModalReport from "./ModalReport";
import { REPORTING_SERVICE } from "config/env.conf";
import { makeStyles } from "@material-ui/core/styles";

//import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const RptSummaryInspect = props => {
  const classes = useStyles();
  const [showModalReport, setshowModalReport] = useState(false);
  const openModalReport = () => setshowModalReport(true);
  const closeModalReport = () => setshowModalReport(false);
  const [child, setChild] = useState("");
  const [title, setTitle] = useState("");
  const [index, setIndex] = useState("");

  return (
    <form>
      <ModalReport
        closeModalReport={closeModalReport}
        showModalReport={showModalReport}
        linkReport={child}
        titleReport={title}
        indexReport={index}
      ></ModalReport>
      <Card>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4>รายงานระบบผู้ตรวจการ</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer spacing={2}>
            <GridItem xs={12} md={6}>
              <h4 className={classes.cardTitle}>รายงาน</h4>
            </GridItem>
          </GridContainer>
          <GridContainer spacing={2}>
            <GridItem xs={12} md={12}>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
              >
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="1.รายงานการปฏิบัติหน้าที่ผู้ตรวจการเลือกตั้งประจำจังหวัด"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2finspector%2finspector_rpt1&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle(
                        "1.รายงานการปฏิบัติหน้าที่ผู้ตรวจการเลือกตั้งประจำจังหวัด"
                      );
                      setIndex("1");
                    }}
                  />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="2. รายงานผู้ตรวจการเลือกตั้ง จำแนกตามอายุ"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2finspector%2finspector_rpt2_agerange&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle("2. รายงานผู้ตรวจการเลือกตั้ง จำแนกตามอายุ");
                      setIndex("2");
                    }}
                  />
                </ListItem>

                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="3. รายงานผู้ตรวจการเลือกตั้ง จำแนกตามอาชีพ"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2finspector%2finspector_rpt3_career&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle("3. รายงานผู้ตรวจการเลือกตั้ง จำแนกตามอาชีพ");
                      setIndex("3");
                    }}
                  />
                </ListItem>


                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="4. รายชื่อผู้สมัครเข้ารับการคัดเลือกเป็นผู้ตรวจการเลือกตั้ง"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2finspector%2finspector_rpt4&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle("4. รายชื่อผู้สมัครเข้ารับการคัดเลือกเป็นผู้ตรวจการเลือกตั้ง");
                      setIndex("4");
                    }}
                  />
                </ListItem>

                <ListItem button>
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="5. ทะเบียนรายชื่อผู้สมัครเข้ารับการคัดเลือกเป็นผู้ตรวจการเลือกตั้ง"
                    onClick={() => {
                      openModalReport();
                      setChild(
                        REPORTING_SERVICE +
                          "/Pages/ReportViewer.aspx?%2freports%2finspector%2finspector_rpt5&rs:Command=Render&rc:Parameters=Collapsed"
                      );
                      setTitle("5. ทะเบียนรายชื่อผู้สมัครเข้ารับการคัดเลือกเป็นผู้ตรวจการเลือกตั้ง");
                      setIndex("5");
                    }}
                  />
                </ListItem>


              </List>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </form>
  );
};

export default RptSummaryInspect;
