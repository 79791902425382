import {createAction} from "@reduxjs/toolkit";

// Notifications
export const insertNotificationList = createAction('notifications/insert')
export const markReadNotification = createAction('notifications/markRead')
export const markUnreadNotification = createAction('notifications/markUnread')

// Pop-ups
export const insertPopupList = createAction('popups/insert')
export const markReadPopup = createAction('popups/markRead')
export const setEnablePopup = createAction('popups/setEnable')

