import React, { useContext } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import ListboxVoteCardColorAll from "components/Common/ListboxVoteCardColorAll";
import ListboxVoteCardNumAll from "components/Common/ListboxVoteCardNumAll";
import ListboxVotecardPrinting from "components/Common/ListboxVotecardPrinting";
import Slide from "@material-ui/core/Slide";
import { VotecardContext } from "contexts/Votecard";
import { addVoteCardDetail } from "contexts/API/VoteCard/Setting";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

//import ListboxPositionType from "components/Common/ListboxPositionType";

// @material-ui/icons

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    //  width: "fit-content"
  },

  titlemodal: {
    background: "info",
    fontFamily: `"Athiti", sans-serif`
  },
  error: {
    color: "red"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModaladdCard = props => {
  const { sweetAlerts } = useSweetAlert();
  const {
    votecardNumberSelected,
    onVotecardNumberSelected,
    voteCardColorAllSelected,
    onVoteCardColorAllSelected,

    votecardPrintingSelected,
    onVotecardPrintingSelected
  } = useContext(VotecardContext);

  const { showModaladdCard, closeModaladdCard } = props;

  const classes = useStyles();

  const addCard = () => {
    if (
      !votecardPrintingSelected ||
      !votecardNumberSelected ||
      !voteCardColorAllSelected
    ) {
      sweetAlerts.warning("กรุณาระบุข้อมูลให้ครบถ้วน");
    } else {
      addVoteCardDetail(
        votecardPrintingSelected.id,
        voteCardColorAllSelected.id,
        votecardNumberSelected.id
      )
        .then(result => {
          sweetAlerts.success(`เพิ่มข้อมูลเรียบร้อยแล้ว`);
          closeModaladdCard();

          addSucess(result.data.data.voteCardDetailId);
        })
        .catch(err => {
          if (err.response.data && err.response.data.desc) {
            sweetAlerts.error("เพิ่มข้อมูลไม่สำเร็จ", err.response.data.desc);
          } else {
            sweetAlerts.error("เพิ่มข้อมูลไม่สำเร็จ");
          }
        });
    }
  };
  const addSucess = voteCardDetailId => {
    props.onaddSuccess(voteCardDetailId);
  };
  return (
    <div className={showModaladdCard ? "modal" : "hide"}>
      <Dialog
        open={showModaladdCard}
        fullWidth={true}
        transition={Transition}
        keepMounted
        onClose={closeModaladdCard}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="classic-modal-slide-title">
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>
            เพิ่มข้อมูลบัตรเลือกตั้ง
          </div>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <form className={classes.form}>
            <FormControl>
              <br /> <br />
              <ListboxVotecardPrinting
                required={true}
                value={votecardPrintingSelected}
                onChange={onVotecardPrintingSelected}
              ></ListboxVotecardPrinting>
              {/* <ListboxVotecardSize
                value={votecardSizeSelected}
                onChange={onVotecardSizeSelected}
              ></ListboxVotecardSize> */}
              <ListboxVoteCardColorAll
                required={true}
                value={voteCardColorAllSelected}
                onChange={onVoteCardColorAllSelected}
              ></ListboxVoteCardColorAll>
              <ListboxVoteCardNumAll
                required={true}
                value={votecardNumberSelected}
                onChange={onVotecardNumberSelected}
              ></ListboxVoteCardNumAll>
            </FormControl>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={closeModaladdCard}>ยกเลิก</Button>
              <Button onClick={addCard} variant="contained" color="success">
                บันทึก
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModaladdCard;
