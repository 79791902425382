import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteValidator } from "utils/Validators/CommonValidator";
/* core components */
import React from "react";
import TextField from "@material-ui/core/TextField";
import conf from "config/env.conf.js";
import getDefaultValue from "utils/DefaultValue";
/* custom components */
import useFetch from "hooks/useFetch";

const ListboxInspectPersonSelect16 = props => {
  let provinceId = props.provinceId ? props.provinceId.id : 0;
  let inspectApplyDateSelected = props.inspectApplyDateSelected
    ? props.inspectApplyDateSelected.id
    : 0;
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  let url = `/inspector/listInspectPersonForReplace/provinceId/${provinceId}/cmdApplyDateId/${inspectApplyDateSelected}`;
  if (props.cmtReplace) {
    url = `/inspector/listCmtPersonReplace/provinceId/${provinceId}/cmdApplyDateId/${inspectApplyDateSelected}`;
  }
  const [isLoading, fetchData] = useFetch(conf.END_POINT_URL + url, []);

  let content = (
    <TextField disabled label="เลือกผู้สมัคร" margin="normal" fullWidth />
  );

  let data = [];

  if (!isLoading && fetchData !== null && fetchData.data !== undefined) {
    data = fetchData.data.map(v => {
      if (props.cmtReplace) {
        return {
          id: v.personApplyCommandId,
          value: v.fullName
        };
      } else {
        return {
          id: v.personApplyCommandId,
          value: v.personName
        };
      }
    });
  }

  if (data.length > 0) {
    content = (
      <Autocomplete
        name={props.name}
        disabled={props.disabled}
        defaultValue={getDefaultValue(data, props.defaultValue)}
        value={props.value}
        options={data}
        getOptionLabel={option => option.value}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={AutocompleteValidator(
          props.onChange,
          props.validators,
          setError,
          setHelperText
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              error={error}
              helperText={helperText}
              label="เลือกผู้สมัคร"
              margin="normal"
              fullWidth
            />
          );
        }}
        {...props.customProps}
      />
    );
  }

  return content;
};
export default ListboxInspectPersonSelect16;
