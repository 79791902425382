import React, { useContext, useEffect, useState } from "react";

import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";
import DatepickerVotedate from "components/Common/Datepicker";
import FileSaver from "file-saver";
import { FileUpload } from "components/Common/FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ImportCheckIdFile from "assets/resources/opt/import_check_id.xlsx";
import LoadingData from "components/Common/LoadingData";
import NumberFormat from "react-number-format";
import { Paper } from "@material-ui/core";
import { REPORTING_SERVICE } from "config/env.conf";
import TableComponent from "components/Table/MaterialTable";
import { WtdContext } from "contexts/Wtd";
import XLSX from "xlsx";
import { checkWtdAfter5Y } from "contexts/API/WTD/CheckWtd";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useSweetAlert } from "components/Alert/SweetAlert";
import validateId from "utils/Validators/ThaiCitizenID.js";

const CheckAfter5Years = props => {
    const {
        yearSelected
    } = useContext(WtdContext);
    const { sweetAlerts } = useSweetAlert();

    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [isFromValid, setIsFromValid] = useState(true);

    const [keyTempIdCard, setKeyTempIdCard] = useState(null);
    const [columns, setColumns] = useState([
        {
            title: "เลขประจำตัวประชาชน",
            field: "idCard",
            cellStyle: {
                minWidth: "100px"
            }
        },
        {
            title: "ผลการตรวจเพิกถอนสิทธิเลือกตั้ง",
            field: "result"
        },
        {
            title: "รายละเอียดของการถูกเพิกถอนสิทธิ",
            field: "detail"
        }
    ]);
    const [AllIDs, setAllIDs] = useState(null);
    const [IDs, setIDs] = useState(null);
    const [ListIDs, setListIDs] = useState(null);
    const [InvalidIDs, setInvalidIDs] = useState([]);
    const [loading, setLoading] = useState(false);

    const [disableStatus, setDisableStatus] = useState(true);

    const onExceclFileHandler = e => {
        try {
            setData([])
            let errStr = [];
            let AllIDLists = [];
            let IDLists = [];
            let ValitIDLists = [];
            let InvIDLists = [];
            let data = new Uint8Array(e);
            let workbook = XLSX.read(data, { type: "array" });
            let ws = workbook.Sheets[workbook.SheetNames[0]];
            let rows = XLSX.utils.sheet_to_json(ws, { header: ["A"] });

            if (rows.length <= 1) {
                throw new Error("ไม่มีข้อมูล");
            }

            if (rows[0].A !== "เลขประจำตัวประชาชน") {
                throw new Error("กรุณาใช้ไฟล์ที่ export ออกจากระบบเท่านั้น");
            }

            for (let i = 1; i < rows.length; i++) {
                const [isError, errDetail] = validateId(rows[i].A.toString());
                //console.log('verify : ', rows[i]);
                if (isError) {
                    let error = "เซลล์ที่ A" + (rows[i].__rowNum__ + 1) + " " + errDetail;

                    errStr.push(
                        <div key={"err" + i} style={{ textAlign: "left" }}>
                            เซลล์ที่ A{rows[i].__rowNum__ + 1} '{rows[i].A}'{" "}
                            <u
                                style={{
                                    borderBottom: "1px  dotted red",
                                    textDecoration: "none"
                                }}
                            >
                                {errDetail}
                            </u>
                        </div>
                    );
                    InvIDLists.push({
                        rowId: rows[i].__rowNum__,
                        idCard: rows[i].A.toString(),
                        result: [error]
                    });
                    AllIDLists.push(rows[i].A.toString());
                } else {
                    AllIDLists.push(rows[i].A.toString());
                    IDLists.push(rows[i].A.toString());
                    ValitIDLists.push({
                        rowId: rows[i].__rowNum__,
                        idCard: rows[i].A.toString()
                    });
                }
            }
            setAllIDs(AllIDLists);
            setListIDs(ValitIDLists);
            setIDs(IDLists);
            setInvalidIDs(InvIDLists);

            if (errStr.length > 0) {
                sweetAlerts.error(
                    "เกิดข้อผิดพลาดสำหรับไฟล์ที่นำเข้า ต้องการดำเนินการต่อหรือไม่",
                    <>
                        <div style={{ textAlign: "left" }}>
                            {" "}
                            <b>มีข้อผิดพลาดดังนี้: </b>
                        </div>
                        <div style={{ overflow: "auto", height: "150px" }}>{errStr}</div>,
                        <div style={{ textAlign: "left" }}>
                            <b>
                                จำนวนทั้งหมด: <Badge color="success">{rows.length - 1} </Badge>{" "}
                                กรอกข้อมูลผิดพลาด: <Badge color="danger">{errStr.length}</Badge>
                            </b>
                        </div>
                    </>,
                    () => {
                        //onConfirmed
                        //do nothing
                    },
                    true,
                    () => {
                        //onCanceled
                        setIDs(null);
                    }
                );
            } else {
                sweetAlerts.success(
                    "นำเข้าข้อมูลสำเร็จ",
                    <>
                        <div style={{ textAlign: "center" }}>
                            <b>
                                จำนวนทั้งหมด: <Badge color="success">{rows.length - 1} </Badge>{" "}
                                กรอกข้อมูลผิดพลาด: <Badge color="danger">{errStr.length}</Badge>
                            </b>
                        </div>
                    </>,
                    () => {
                        //onConfirmed
                        //do nothing
                    },
                    false
                );
            }
        } catch (err) {
            sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
        }
    };

    const onSearchHandler = () => {
        let year = 0;
        //let wtdStatus = null;

        if (!IDs) {
            sweetAlerts.warning("กรุณาระบุเลขประจำตัวประชาชน");
            return;
        }

        if (!yearSelected) {
            year = 0;
        } else {
            year = yearSelected.id;
        }

        //const today = new Date();
        //today.setFullYear(today.getFullYear() - year);
        //const checkDate = today.toISOString().split("T")[0];
        setLoading(true);

        console.log("fromDate >>", fromDate)

        checkWtdAfter5Y(
            fromDate,
            IDs,
            AllIDs.toString()
        )
            .then(result => {
                let ListID_temp = ListIDs;
                const dataUniqKey = {};
                setKeyTempIdCard(result.data.data.keyTempIdCard);
                result.data.data.result.forEach(v => {
                    let postionStatus = "";
                    let detail = "";
                    let __level__ = 0;

                    let rowId = ListID_temp.find(x => x.idCard === v.idCard).rowId;
                    if (rowId !== null) {
                        ListID_temp = ListID_temp.filter(r => {
                            return r.rowId !== rowId;
                        });
                    }
                    console.log("v >>", v)
                    /*  console.log("wtdSdate >>", v.wtdSdate)
                     console.log("wtdEdate >>", v.wtdEdate)
                     console.log("wtdEdate >>", v.detail)
                     console.log("wtdEdate >>", (v.wtdSdate === null || v.wtdEdate === null))
                      */
                    if (
                        v.wtdSdate === "" ||
                        v.wtdEdate === "" /* ถ้าไม่มี wtdSdate or wtdEdate ให้ถือว่ายังไม่ถูกเพิกถอน */
                    ) {
                        __level__ = 0;
                        postionStatus = "--ไม่พบข้อมูล--";
                        detail = "--ไม่พบข้อมูล--";

                    } else {
                        console.log("detail >>", v.detail)
                        __level__ = 1;
                        postionStatus = "--พบข้อมูล--";
                        detail = v.detail;
                    }

                    if (dataUniqKey[v.idCard + rowId] === undefined) {
                        /* บันทึกสถานะ */
                        dataUniqKey[v.idCard + rowId] = {
                            rowId: rowId,
                            idCard: v.idCard,
                            result: [postionStatus],
                            detail: [detail],
                            __level__: __level__
                        };
                    } else if (__level__ >= 1) {
                        /* เพิ่มประวัติการดำรงตำแหน่ง */

                        if (dataUniqKey[v.idCard + rowId].__level__ === 0) {
                            dataUniqKey[v.idCard + rowId].result = [postionStatus];
                            dataUniqKey[v.idCard + rowId].detail = [detail];
                            dataUniqKey[v.idCard + rowId].__level__ = __level__;
                        } else {
                            dataUniqKey[v.idCard + rowId].result = [
                                ...dataUniqKey[v.idCard + rowId].result,
                                postionStatus
                            ];
                            dataUniqKey[v.idCard + rowId].result = [
                                ...dataUniqKey[v.idCard + rowId].detail,
                                detail
                            ];
                            dataUniqKey[v.idCard + rowId].__level__ = __level__;
                        }
                    } else {
                        console.log("do nothing ")
                        //do nothing
                    }
                });
                setData(InvalidIDs.concat(Object.values(dataUniqKey)));
                setLoading(false);
                setColumns([
                    {
                        title: "ลำดับ",
                        field: "rowId",
                        cellStyle: {
                            minWidth: "25px",
                            textAlign: "center"
                        },
                        defaultSort: "asc"
                    },
                    {
                        title: "เลขประจำตัวประชาชน",
                        field: "idCard",
                        cellStyle: {
                            minWidth: "100px"
                        },
                        render: rowData => {
                            return (
                                <NumberFormat
                                    value={rowData.idCard}
                                    format="# #### ##### ## #"
                                    style={{
                                        fontSize: "15px",
                                        width: "100%",
                                        border: "0"
                                    }}
                                />
                            );
                        }
                    },

                    {
                        title: "ผลการตรวจเพิกถอนสิทธิเลือกตั้ง",
                        field: "result",
                        render: rawData => {
                            return rawData.result.map((e, i) => {
                                return <div key={i}>{e}</div>;
                            });
                        },
                        cellStyle: {
                            minWidth: "200px"
                        }
                    },
                    {
                        title: "รายละเอียดของการถูกเพิกถอนสิทธิ",
                        field: "detail",
                        render: rawData => {
                            return rawData.detail.map((e, i) => {
                                return <div key={i}>{e}</div>;
                            });
                        },
                        cellStyle: {
                            minWidth: "300px"
                        }
                    }
                ]);
            })
            .catch(err => {
                sweetAlerts.error("เกิดข้อผิดพลาด", err.toString());
            });
    };

    const exportUrl = () => {
        let endPointExp = "";
        let year = 0;

        if (!yearSelected) {
            year = 0;
        } else {
            year = yearSelected.id;
        }

        const today = new Date();
        today.setFullYear(today.getFullYear() - year);
        //const checkDate = today.toISOString().split("T")[0];
        let voteDate;

        if (fromDate === null || fromDate === "") {
            voteDate = today.toISOString().split("T")[0];
        } else {
            voteDate = fromDate.split("T")[0];
        }
        endPointExp += `&keyTempIdCard=${keyTempIdCard}&voteDate=${voteDate}`;


        return endPointExp;
    };

    useEffect(() => {
        if (IDs !== null) {
            setDisableStatus(false);
        } else {
            setDisableStatus(true);
        }
    }, [IDs]);
    const handleSubmitExport = e => {
        e.preventDefault();
        window.open(
            `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2fwtd%2fwtd_rptWTDPersonCheckAfter5Y&rs:Command=Render&rc:Parameters=Collapsed${exportUrl()}`,
            "_blank"
        );
    };
    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <br /> <br />
                    <GridContainer
                        justify="flex-start"
                        alignItems="center"
                        direction="row"
                    >
                        <GridItem xs={12} sm={12} md={12}>
                            ดาวน์โหลดแบบฟอร์มการตรวจสอบ :
                            <Button
                                size="sm"
                                color="success"
                                onClick={() => {
                                    FileSaver.saveAs(ImportCheckIdFile, "import_check_id.xlsx");
                                }}
                            >
                                <FontAwesomeIcon icon={faFileExcel} />
                                แบบฟอร์มการตรวจสอบ
                            </Button>
                            <span style={{ fontSize: "14px", color: "red", paddingLeft: "10px" }}>
                                ตรวจสอบข้อมูลได้ไม่เกิน 5,000 รายการ
                            </span>
                        </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer
                        justify="flex-start"
                        alignItems="center"
                        direction="row"
                    >
                        <GridItem xs={12} sm={12} md={6}>
                            <FileUpload
                                id="file-upload-2"
                                label="แนบไฟล์ (เฉพาะ Excel เท่านั้น)"
                                remove
                                onFileHandler={onExceclFileHandler}
                                fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                btnName={<>Browse</>}
                                btnProps={{
                                    color: "primary"
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer justify="flex-start" alignItems="top" direction="row">
                        <GridItem xs={6} sm={6} md={6}>
                            <DatepickerVotedate
                                id="begin"
                                label="วันที่เลือกตั้ง"
                                value={fromDate}
                                valid={isFromValid}
                                onChange={date => {
                                    if (date) {
                                        let arr = date.format().split("T");
                                        setFromDate(arr[0]);
                                        setIsFromValid(false);
                                    } else {
                                        setFromDate(null);
                                        setIsFromValid(true);
                                    }
                                }}
                            ></DatepickerVotedate>
                        </GridItem>
                    </GridContainer>
                    <GridContainer justify="space-between" alignItems="center">
                        <GridItem xs={6} sm={6} md={6}></GridItem>
                        <GridItem>
                            <Button
                                onClick={() => {
                                    setData([]);
                                }}
                            >
                                ยกเลิก
                            </Button>
                            <Button
                                color="info"
                                disabled={disableStatus}
                                onClick={onSearchHandler}
                            >
                                ตรวจสอบ
                            </Button>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <br />
            {data.length !== 0 ? (
                <GridContainer justify="space-between" alignItems="center">
                    <GridItem xs={6} sm={6} md={6}></GridItem>
                    <GridItem>
                        นำออกข้อมูล:{" "}
                        <Button justIcon round color="success" onClick={handleSubmitExport}>
                            <FontAwesomeIcon icon={faFileExcel} />
                        </Button>
                    </GridItem>
                </GridContainer>
            ) : null}
            {loading === true ? (
                <LoadingData />
            ) : (
                <TableComponent
                    title={"ผลการตรวจสอบบุคคลที่ถูกเพิกถอนสิทธิเลือกตั้ง"}
                    enableInitColumns={false}
                    columns={columns}
                    data={data}
                    components={{
                        Container: props => <Paper {...props} elevation={0} />
                    }}
                    localization={{}}
                    options={{
                        paging: false,
                        search: false,
                        exportButton: false
                    }}
                />
            )}
        </>
    );
};
export default CheckAfter5Years;
