import React, { useContext, useEffect, useState } from "react";
import {
  editVotePublishMember,
  editVotePublishPresident,
  getPositionStatus,
  getReplaceMemberPerson,
  getReplacePerson,
  getVotePublish,
  getCountReNewScore,
  delReNewScore
} from "contexts/API/OPT/OptVotePublish";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import HandelError from "utils/HandleError";
import ListboxArea from "components/Common/ListboxArea";
import ListboxAumphurArea from "components/Common/ListboxAumphurArea";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxOpts from "components/Common/ListboxOpts";
import ListboxPositionPublish from "components/Common/ListboxPositionPublish";
import ListboxPositionType from "components/Common/ListboxPositionType";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxVotedate from "components/Common/ListboxVotedate";
import ListboxreplacePerson from "components/Common/ListboxreplacePerson";
import ListboxreplacePersonMem from "components/Common/ListboxreplacePersonMem";
import LoadingData from "components/Common/LoadingData";
import ModaladdNewvote from "./ModaladdNewvote";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import TableCell from "@material-ui/core/TableCell";
import TableComponent from "components/Table/MaterialTable";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { VoteDateContext } from "contexts/VoteDate";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { withStyles } from "@material-ui/core/styles";

const OptVotepublish = props => {
  const { sweetAlerts } = useSweetAlert();
  const AntSwitch = withStyles(theme => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex"
    },

    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.success.main,
          borderColor: theme.palette.success.main
        }
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none"
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#ff1228"
      //backgroundColor: theme.palette.common.red
    },

    checked: {}
  }))(Switch);
  const {
    optVoteTypeSelected,
    onOptVoteTypeSelected,
    onProvinceSelected,
    provinceSelected,
    onAmphurSelected,
    amphurSelected,

    tambolSelected,
    optTypeSelected,
    onOptSelected,
    optSelected,
    onOptTypeSingleSelected,
    onVotedateSelected,
    onAumphurAreaSelected,
    positionTypeSelected,

    aumphurAreaSelected,
    areaSelected,
    onAreaSelected,
    votedateSelected,
    onPostionTypeSelected,
    getOptTypeSelectedLists,

  } = useContext(VoteDateContext);

  const optTypes = getOptTypeSelectedLists();

  const [showModalNewvote, setshowModalNewvote] = useState(false);
  const openModalNewvote = () => setshowModalNewvote(true);
  const closeModalNewvote = () => setshowModalNewvote(false);
  const [columnsMem, setColumnsMem] = React.useState([]);
  const [columnsPres, setColumnsPres] = React.useState([]);
  const [dataMem, setDataMem] = useState([]);
  const [dataPres, setDataPres] = useState([]);
  const [, setData] = useState([]);
  const [, setPresComment] = useState(null);
  const [, setMemComment] = useState(null);

  const [loading, setLoading] = useState(false);

  const [personPresList, setPersonPresList] = useState([]);
  const [optArea,] = useState(null);
  const [personMemList, setPersonMemList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [listReNewScore, setListCountReNewScore] = useState([]);
  // const handleComment = (id, value) => {
  //   editComment(id, value)
  //     .then(result => {
  //       sweetAlerts.success("บันทึกข้อมูลเรียบร้อยแล้ว", 3000);
  //     })
  //     .catch(err => {
  //       sweetAlerts.error("ไม่สามารถอัพเดทข้อมูลได้", err.toString());
  //     });
  // };

  useEffect(() => {
    getPositionStatus()
      .then(r => {
        let statusMapper = r.data.data.map(v => {
          return { id: v.optPositionStatusId, value: v.detail };
        });

        setStatusList(statusMapper);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (votedateSelected) {
      getReplacePerson(optSelected, votedateSelected)
        .then(r => {
          let personPres = r.data.data.map(v => {
            return {
              id: v.optPersonPresId,
              value: v.detail + " " + v.firstName + " " + v.lastName
            };
          });

          setPersonPresList(personPres);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [optSelected], [votedateSelected]);

  useEffect(() => {
    if (optArea) {
      getReplaceMemberPerson(optArea, optSelected, votedateSelected)
        .then(r => {
          var personMemMapper = r.data.data.map(v => {
            return {
              id: v.optPersonMemId,
              value: v.detail + " " + v.firstName + " " + v.lastName
            };
          });

          setPersonMemList(personMemMapper);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [optArea], [optSelected], [votedateSelected]);


  const fetchData = () => {
    setLoading(true);
    setDataMem([]);
    setData(null);
    setPresComment(null);
    setMemComment(null);
    //async function fetchData() {
    getVotePublish(
      optSelected,
      votedateSelected,
      optVoteTypeSelected,
      areaSelected,
      positionTypeSelected,
      aumphurAreaSelected
    )
      .then(result => {
        if (result.data.success) {

          getCountReNewScore(optSelected, votedateSelected)
            .then(r => {
              //console.log("r.data.data >> ==>", r.data.data);
              let listId = [];
              for (let i = 0; i < r.data.data.length; i++) {
                listId.push(r.data.data[i].person_score_history_id)
              }
              //console.log("listId >> ==>", listId);
              setListCountReNewScore(listId);
            })
            .catch(err => {
              console.log(err);
            });

          //======================== สถ ====================================//
          if (result.data.data.member && result.data.data.member.length > 0) {
            let optAreaId = result.data.data.member.map(function (value) {
              return value.optAreaId;
            });

            //console.log("optSelected>>>", optSelected);
            // setOptArea(optAreaId[0]);
            var personMemMapper = "";
            getReplaceMemberPerson(optAreaId[0], optSelected, votedateSelected)
              .then(r => {
                personMemMapper = r.data.data.map(v => {
                  return {
                    id: v.optPersonMemId,
                    value: v.detail + " " + v.firstName + " " + v.lastName
                  };
                });

                setPersonMemList(personMemMapper);
              })
              .catch(err => {
                console.log(err);
              });

            setData(result.data.data);
            let amphurIsnull = false;

            let _dataAumPhur = result.data.data.member;
            _dataAumPhur.map(function (value) {
              if (value.amphurName !== null) {
                value.newKey =
                  "อำเภอ " + value.amphurName + " เขตที่ " + value.areaNo;
              } else {
                amphurIsnull = true;
                value.newKey = "เขตที่ " + value.areaNo;
              }
            });

            setDataMem(_dataAumPhur);

            //  if (optVoteTypeSelected && optVoteTypeSelected.id !== 1)
            if (optSelected.value.indexOf("อบต.") === 0) {
              // อบต

              const _columnMember = [
                {
                  title: "optPersonMemId",
                  field: "optPersonMemId",
                  hidden: true
                },
                {
                  title: "เขต",
                  field: "areaNo",
                  editable: "never",
                  defaultGroupOrder: 0,

                  cellStyle: {
                    backgroundColor: "#039be5",
                    color: "#FFF",
                    fontWeight: 500
                  },
                  headerStyle: {
                    fontWeight: 500
                  }
                },

                {
                  title: "หมายเลขผู้สมัคร",
                  field: "applyNo",
                  editable: "never",
                  cellStyle: {
                    minWidth: "50px"
                  }
                },
                {
                  title: "ชื่อ สกุล",
                  field: "titleDetail",
                  editable: "never",
                  cellStyle: {
                    minWidth: "100px"
                  },

                  render: rowData => {
                    return (
                      rowData.titleDetail +
                      " " +
                      rowData.firstName +
                      " " +
                      rowData.lastName
                    );
                  },
                  exportData: row => {
                    return (
                      row.titleDetail + " " + row.firstName + " " + row.lastName
                    );
                  }
                },
                {
                  title: "คะแนน",
                  field: "score",
                  type: "numeric",
                  cellStyle: {
                    minWidth: "50px",
                    paddingRight: "10px"
                  },

                  render: rowData => {
                    return !rowData.score ? 0 : rowData.score.toLocaleString();
                  },
                  editComponent: props => {
                    return (
                      <NumberFormat
                        style={{
                          width: "100%",
                          fontSize: "15px",
                          borderStyle: "none none solid none"
                        }}
                        thousandSeparator={true}
                        customInput={TextField}
                        thousandsGroupStyle="thousand"
                        value={
                          !props.rowData.score
                            ? 0
                            : parseInt(props.rowData.score)
                        }
                        onValueChange={values => {
                          const { value } = values;

                          props.rowData.score = parseInt(value);
                          //this.setState({profit: formattedValue})
                        }}
                      />
                    );
                  }
                },
                {
                  title: "สถานะ",
                  field: "optVoteSelectedStatusId",
                  hidden: true,
                  export: true,
                  exportData: row => {
                    return row.optVoteSelectedStatusId === 2 ? "ได้" : "ไม่ได้";
                  }
                }
              ];

              const _columns = [
                {
                  title: "ชื่อ สกุล",
                  field: "replaceOptPersonMemId",

                  cellStyle: {
                    minWidth: "150px"
                  },
                  render: rowData => {
                    if (personMemMapper.length > 0) {
                      const v = personMemMapper.find(
                        e => e.id === parseInt(rowData.replaceOptPersonMemId)
                      );
                      return v !== undefined ? v.value : "";
                    } else {
                      return null;
                    }
                  },
                  editComponent: props => {
                    //console.log("2 =>", props.rowData.optAreaId)
                    //console.log("2 =>", optAreaId[0])
                    return (
                      <ListboxreplacePersonMem
                        data={personMemList}
                        areaSelected={optAreaId[0]}
                        optSelected={optSelected}
                        votedateSelected={votedateSelected}
                        defaultValue={{
                          id: props.rowData.replaceOptPersonMemId
                        }}
                        onChange={(i, e, v) => {
                          if (v !== null) {
                            props.rowData.replaceOptPersonMemId = e.id;
                          }
                        }}
                      />
                    );
                  }
                },

                {
                  title: "สาเหตุ",
                  field: "optPositionStatusId",
                  cellStyle: {
                    minWidth: "100px",
                    textAlign: "center"
                  },
                  exportData: row => {
                    //console.log("statusList >>", statusList)
                    //console.log("row.optPositionStatusId >>", row.optPositionStatusId)
                    const v = statusList.find(
                      e => e.id === parseInt(row.optPositionStatusId)
                    );
                    console.log("v >>", v)
                    return v !== undefined ? v.value : "";
                  },
                  render: rowData => {
                    const v = statusList.find(
                      e => e.id === parseInt(rowData.optPositionStatusId)
                    );
                    return v !== undefined ? v.value : "";
                  },
                  editComponent: props => {
                    return (
                      <>
                        <ListboxPositionPublish
                          defaultValue={{
                            id: props.rowData.optPositionStatusId
                          }}
                          data={statusList}
                          onChange={(i, e, v) => {
                            if (v !== null) {
                              props.rowData.optPositionStatusId = e.id;
                            }
                          }}
                        />
                      </>
                    );
                  }
                },
                {
                  title: "หมายเหตุ",
                  field: "replaceRemark",
                  cellStyle: {
                    minWidth: "150px"
                  }
                }
              ];
              let setColumn =
                (optVoteTypeSelected && optVoteTypeSelected.id === 2) ||
                  optVoteTypeSelected.id === 3
                  ? _columnMember.concat(_columns)
                  : _columnMember;

              setColumnsMem(setColumn);
            } else {
              //อบจ
              //console.log("ไม่ใช่อบต");
              const _columns = [
                {
                  title: "ชื่อ สกุล",
                  field: "replaceOptPersonName",
                  cellStyle: {
                    minWidth: "150px"
                  },
                  exportData: row => {
                    return row.replaceOptPersonName;
                  },
                  render: rowData => {
                    if (personMemMapper.length > 0) {
                      const v = personMemMapper.find(
                        e => e.id === parseInt(rowData.replaceOptPersonMemId)
                      );
                      return v !== undefined ? v.value : "";
                    } else {
                      return null;
                    }
                  },
                  editComponent: props => {
                    //  props.rowData.replaceOptPersonMemId
                    //   ? props.rowData.replaceOptPersonMemId
                    //   : undefined;
                    console.log("1 =>", props.rowData.optAreaId)
                    return (
                      <ListboxreplacePersonMem
                        areaSelected={{ id: props.rowData.optAreaId }}
                        optSelected={optSelected}
                        votedateSelected={votedateSelected}
                        defaultValue={{
                          id: props.rowData.replaceOptPersonMemId
                        }}
                        onChange={(i, e, v) => {
                          if (v !== null) {
                            props.rowData.replaceOptPersonMemId = e.id;
                          }
                        }}
                      />

                    );
                  }
                },

                {
                  title: "สาเหตุ",
                  field: "optPositionStatusId",
                  cellStyle: {
                    minWidth: "100px",
                    textAlign: "center"
                  },
                  render: rowData => {
                    console.log("statusList >>", statusList)
                    console.log("rowData.optPositionStatusId >>", rowData.optPositionStatusId)
                    const v = statusList.find(
                      e => e.id === parseInt(rowData.optPositionStatusId)
                    );
                    console.log("v.value >>", v)
                    return v !== undefined ? v.value : "";
                  },
                  editComponent: props => {
                    return (
                      <ListboxPositionPublish
                        defaultValue={{
                          id: props.rowData.optPositionStatusId
                        }}
                        data={statusList}
                        onChange={(i, e, v) => {
                          if (v !== null) {
                            props.rowData.optPositionStatusId = e.id;
                          }
                        }}
                      />
                    );
                  }
                },

                {
                  title: "หมายเหตุ",
                  field: "replaceRemark",
                  cellStyle: {
                    minWidth: "150px"
                  }
                }
              ];
              const _columnMember = [
                {
                  title: "optPersonMemId",
                  field: "optPersonMemId",
                  hidden: true
                },
                amphurIsnull
                  ? {
                    title: "เขต",
                    field: "newKey",
                    editable: "never",
                    defaultGroupOrder: 0,

                    cellStyle: {
                      backgroundColor: "#039be5",
                      color: "#FFF",
                      fontWeight: 500
                    },
                    headerStyle: {
                      fontWeight: 500
                    }
                  }
                  : {
                    title: "เขตอำเภอ",
                    field: "newKey",
                    editable: "never",
                    defaultGroupOrder: 0,

                    cellStyle: {
                      backgroundColor: "#039be5",
                      color: "#FFF",
                      fontWeight: 500
                    },
                    headerStyle: {
                      fontWeight: 500
                    }
                  },
                // {
                //   title: "อำเภอ",
                //   field: "amphurName"
                // },

                {
                  title: "หมายเลขผู้สมัคร",
                  field: "applyNo",
                  editable: "never"
                },
                {
                  title: "ชื่อ สกุล",
                  editable: "never",
                  field: "",
                  cellStyle: {
                    minWidth: "100px"
                  },
                  exportData: row => {
                    return (
                      row.titleDetail + " " + row.firstName + " " + row.lastName
                    );
                  },
                  render: rowData => {
                    return (
                      rowData.titleDetail +
                      " " +
                      rowData.firstName +
                      " " +
                      rowData.lastName
                    );
                  }
                },
                {
                  title: "คะแนน",
                  field: "score",
                  type: "numeric",
                  cellStyle: {
                    minWidth: "50px",
                    textAlign: "center"
                    // paddingRight: "0px"
                  },
                  render: rowData => {
                    return !rowData.score ? 0 : rowData.score.toLocaleString();
                  },
                  editComponent: props => {
                    return (
                      <NumberFormat
                        style={{
                          width: "100%",
                          fontSize: "15px",
                          borderStyle: "none none solid none"
                        }}
                        thousandSeparator={true}
                        thousandsGroupStyle="thousand"
                        value={
                          !props.rowData.score
                            ? 0
                            : parseInt(props.rowData.score)
                        }
                        onValueChange={values => {
                          const { value } = values;

                          props.rowData.score = parseInt(value);
                          //this.setState({profit: formattedValue})
                        }}
                      />
                    );
                  }
                },
                {
                  title: "สถานะ",
                  field: "optVoteSelectedStatusId",
                  hidden: true,
                  export: true,
                  exportData: row => {
                    return row.optVoteSelectedStatusId === 2 ? "ได้" : "ไม่ได้";
                  }
                }
              ];
              let setColumn =
                optVoteTypeSelected.id === 2 || optVoteTypeSelected.id === 3
                  ? _columnMember.concat(_columns)
                  : _columnMember;

              setColumnsMem(setColumn);
            }
          }

          //======================== ผถ ====================================//
          if (result.data.data.pres && result.data.data.pres.length > 0) {

            var personPresMapper = "";
            getReplacePerson(optSelected, votedateSelected)
              .then(r => {
                personPresMapper = r.data.data.map(v => {
                  return {
                    id: v.optPersonPresId,
                    value: v.detail + " " + v.firstName + " " + v.lastName
                  };
                });

                setPersonPresList(personPresMapper);
              })
              .catch(err => {
                console.log(err);
              });

            setDataPres(result.data.data.pres);
            const _columnPres = [
              {
                title: "optPersonPresId",
                field: "optPersonPresId",
                hidden: true
              },
              {
                title: "หมายเลขผู้สมัคร",
                field: "applyNo",
                editable: "never",
                cellStyle: {
                  minWidth: "10px"
                }
              },
              {
                title: "ชื่อ สกุล",
                field: "titleDetail",
                editable: "never",
                cellStyle: {
                  minWidth: "100px"
                },
                exportData: row => {
                  return (
                    row.titleDetail + " " + row.firstName + " " + row.lastName
                  );
                },
                render: rowData => {
                  return (
                    rowData.titleDetail +
                    " " +
                    rowData.firstName +
                    " " +
                    rowData.lastName
                  );
                }
              },
              {
                title: "คะแนน",
                field: "score",
                type: "numeric",
                cellStyle: {
                  minWidth: "50px",
                  textAlign: "center"
                  // paddingRight: "150px"
                },
                render: rowData => {
                  return !rowData.score ? 0 : rowData.score.toLocaleString();
                },
                editComponent: props => {
                  return (
                    <NumberFormat
                      style={{
                        width: "100%",
                        fontSize: "15px",
                        borderStyle: "none none solid none"
                      }}
                      thousandSeparator={true}
                      thousandsGroupStyle="thousand"
                      value={
                        !props.rowData.score ? 0 : parseInt(props.rowData.score)
                      }
                      onValueChange={values => {
                        const { value } = values;

                        props.rowData.score = parseInt(value);
                        //this.setState({profit: formattedValue})
                      }}
                    />
                  );
                }
              },

              {
                title: "ชื่อ สกุล",
                // field: "replaceOptPersonPresId",
                field: "replaceOptPersonPresId",
                cellStyle: {
                  minWidth: "100px"
                },
                render: rowData => {
                  if (personPresMapper.length > 0) {
                    const v = personPresMapper.find(
                      e => e.id === parseInt(rowData.replaceOptPersonPresId)
                    );
                    return v !== undefined ? v.value : "";
                  } else {
                    return null;
                  }
                },
                editComponent: props => {
                  return (
                    <ListboxreplacePerson
                      defaultValue={{
                        id: props.rowData.replaceOptPersonPresId
                      }}
                      optSelected={optSelected}
                      data={personPresList}
                      onChange={(i, e, v) => {
                        if (v !== null) {
                          props.rowData.replaceOptPersonPresId = e.id;
                        }
                      }}
                    />
                  );
                }
              },
              {
                title: "สาเหตุ",
                field: "optPositionStatusId",
                cellStyle: {
                  minWidth: "150px",
                  textAlign: "center"
                },
                exportData: row => {
                  const v = statusList.find(
                    e => e.id === parseInt(row.optPositionStatusId)
                  );
                  return v !== undefined ? v.value : "";
                },
                render: rowData => {
                  const v = statusList.find(
                    e => e.id === parseInt(rowData.optPositionStatusId)
                  );
                  return v !== undefined ? v.value : "";
                },
                editComponent: props => {
                  return (
                    <ListboxPositionPublish
                      defaultValue={{
                        id: props.rowData.optPositionStatusId
                      }}
                      data={statusList}
                      onChange={(i, e, v) => {
                        if (v !== null) {
                          props.rowData.optPositionStatusId = e.id;
                        }
                      }}
                    />
                  );
                }
              },

              {
                title: "หมายเหตุ",
                field: "replaceRemark", //2020-08-10
                cellStyle: {
                  minWidth: "150px"
                }
              },
              {
                title: "สถานะ",
                field: "optVoteSelectedStatusId",
                hidden: true,
                export: true,
                exportData: row => {
                  return row.optVoteSelectedStatusId === 2 ? "ได้" : "ไม่ได้";
                }
              }
            ];
            setColumnsPres(_columnPres);
          }
          setLoading(false);
        } else {
          sweetAlerts.warning("ไม่สามารถดึงข้อมูลได้");
        }
      })
      .catch(err => {
        HandelError(err);
        setLoading(false);

        //    sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.toString());
      });
  };
  const addSuccess = success => {
    if (success) {
      // setLoading(false);
      // setClickSearch(null);
      handleSubmit();
    }
  };

  const handleDelReNewScore = () => {
    console.log("listReNewScore >>>>", listReNewScore)

    delReNewScore(listReNewScore)
      .then(result => {
        sweetAlerts.success("ลบข้อมูลการนับคะแนนใหม่เรียบร้อยแล้ว");
        fetchData();
      })
      .catch(err => {
        sweetAlerts.error(
          "ไม่สามารถลบข้อมูลการนับคะแนนใหม่ได้",
          err.toString()
        );
      });

  };

  const handleSubmit = () => {
    // e.preventDefault();
    setDataMem([]);
    setDataPres([]);
    setData([]);
    setColumnsMem([]);
    setColumnsPres([]);
    if (optSelected == null) {
      sweetAlerts.warning("กรุณาเลือก อปท.");
    } else if (votedateSelected == null) {
      sweetAlerts.warning("กรุณาระบุวันเลือกตั้ง");
    } else if (optVoteTypeSelected == null) {
      sweetAlerts.warning("กรุณาระบุประเภทเลือกตั้ง");
    } else {
      fetchData();
    }
  };

  return (
    <>
      <ModaladdNewvote
        closeModalNewvote={closeModalNewvote}
        showModalNewvote={showModalNewvote}
        onaddSuccess={addSuccess}
      ></ModaladdNewvote>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CheckboxOpttype
                        optTypeSelected={optTypeSelected}
                        onOptTypeSelected={onOptTypeSingleSelected}
                        required={true}
                      ></CheckboxOpttype>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxOptVotetype
                        value={optVoteTypeSelected}
                        onChange={onOptVoteTypeSelected}
                        required={true}
                        validators={[MustNotNull]}
                      ></ListboxOptVotetype>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxProvince
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                        required={true}
                        validators={[MustNotNull]}
                      ></ListboxProvince>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxDistinct
                        value={amphurSelected}
                        provinceSelected={provinceSelected}
                        onChange={onAmphurSelected}
                      ></ListboxDistinct>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxOpts
                        value={optSelected}
                        getOptTypeSelectedLists={getOptTypeSelectedLists}
                        optTypeSelected={optTypeSelected}
                        provinceSelected={provinceSelected}
                        amphurSelected={amphurSelected}
                        tambolSelected={tambolSelected}
                        onChange={onOptSelected}
                        required={true}
                        validators={[MustNotNull]}
                      ></ListboxOpts>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {/* <ListboxOpts
                        value={optSelected}
                        // getOptTypeSelectedLists={getOptTypeSelectedLists}
                        optTypeSelected={optTypeSelected}
                        provinceSelected={provinceSelected}
                        amphurSelected={amphurSelected}
                        tambolSelected={tambolSelected}
                        onChange={onOptSelected}
                      ></ListboxOpts> */}

                      <ListboxVotedate
                        value={votedateSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onVotedateSelected}
                        required={true}
                        validators={[MustNotNull]}
                      ></ListboxVotedate>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxAumphurArea
                        value={aumphurAreaSelected}
                        optSelected={optSelected}
                        votedateSelected={votedateSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        onChange={onAumphurAreaSelected}
                      ></ListboxAumphurArea>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxPositionType
                        value={positionTypeSelected}
                        onChange={onPostionTypeSelected}
                      ></ListboxPositionType>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxArea
                        disabled={
                          positionTypeSelected && positionTypeSelected.id === 1
                            ? true
                            : false
                        }
                        value={areaSelected}
                        optSelected={optSelected}
                        optVoteTypeSelected={optVoteTypeSelected}
                        votedateSelected={votedateSelected}
                        aumphurAreaSelected={aumphurAreaSelected}
                        onChange={onAreaSelected}
                      ></ListboxArea>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <br /> <br /> <br />
              <GridContainer justify="space-between" alignItems="center">
                <GridItem >
                  <Button
                    color="success"
                    onClick={() => {
                      openModalNewvote();
                    }}
                  >
                    นับคะแนนใหม่
                  </Button>
                  <Button
                    disabled={listReNewScore.length === 0}
                    color="warning"
                    type="submit"
                    onClick={handleDelReNewScore}
                  >
                    ลบการนับคะแนนใหม่
                  </Button>
                </GridItem>

                <GridItem >
                  <Button>ยกเลิก</Button>
                  <Button color="info" type="submit" onClick={handleSubmit}>
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {loading ? (
        <LoadingData />
      ) : (
        <>
          {positionTypeSelected === null || positionTypeSelected.id === 1 ? (
            <>
              <GridItem xs={12} sm={12} md={12}>
                <TableComponent
                  title="ข้อมูลผลคะแนน/ผู้ได้รับเลือกตั้ง (ผ.ถ)"
                  columns={columnsPres}
                  data={dataPres}
                  enableInitColumns={false}
                  components={{
                    Container: props => <Paper {...props} elevation={0} />,
                    Header: props => {
                      return (
                        <TableHead>
                          <TableRow>
                            {/* <TableCell rowSpan={2} align="center">
                              ลำดับ
                            </TableCell> */}
                            <TableCell
                              rowSpan={2}
                              align="center"
                            // style={{ backgroundColor: "red", minWidth: "10px",padding:"5px" }}
                            >
                              หมายเลขผู้สมัคร
                            </TableCell>
                            <TableCell rowSpan={2} align="center">
                              ชื่อ สกุล
                            </TableCell>
                            <TableCell rowSpan={2} align="center">
                              คะแนน
                            </TableCell>

                            <TableCell colSpan={3} align="center">
                              เลือกตั้งแทนผู้สมัครเดิม
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center">ชื่อ สกุล</TableCell>
                            <TableCell align="center">สาเหตุ</TableCell>
                            <TableCell align="center">หมายเหตุ</TableCell>
                          </TableRow>
                        </TableHead>
                      );
                    }
                  }}
                  actions={[
                    rowData => ({
                      icon: props => (
                        // <div
                        //   style={{
                        //     fontFamily: "Athiti",
                        //     fontWeight: "400",
                        //     position: "absolute",
                        //     width: "max-content",

                        //     marginRight: "20px"
                        //   }}
                        // >
                        //  <GridItem>ไม่ได้</GridItem>
                        <>
                          <GridItem
                            style={{
                              fontWeight: "400",
                              fontFamily: "Athiti",
                              padding: "5px"
                            }}
                          >
                            ไม่ได้
                          </GridItem>
                          <AntSwitch
                            checked={rowData.optVoteSelectedStatusId === 2}
                            name="optVoteSelectedStatusId"
                          />
                          <GridItem
                            style={{
                              fontWeight: "400",
                              fontFamily: "Athiti",
                              padding: "5px"
                            }}
                          >
                            ได้
                          </GridItem>
                        </>
                      ),
                      onClick: (event, rowData) => {
                        const dataUpdate = [...dataPres];

                        const changeData = {
                          [event.target.name]:
                            event.target.checked === true ? 2 : 3
                        };

                        const newData = { ...rowData, ...changeData };

                        const index = rowData.tableData.id;
                        dataUpdate[index] = newData;
                        sweetAlerts.danger(
                          "ยืนยันข้อมูล",
                          "ยืนยันการเปลี่ยนแปลงผู้ที่ได้รับการเลือกตั้ง",
                          () => {
                            editVotePublishPresident(
                              newData.optPersonPresId,
                              newData.score,
                              newData.optVoteSelectedStatusId,
                              newData.replaceOptPersonPresId,
                              newData.optPositionStatusId,
                              newData.replaceRemark
                            )
                              .then(result => {
                                console.log(result);
                                setDataPres([...dataUpdate]);
                                sweetAlerts.success(`แก้ไขข้อมูลเรียบร้อยแล้ว`);
                              })
                              .catch(err => {
                                if (err.response.data.code !== 20000) {
                                  sweetAlerts.danger(`ไม่สามารถแก้ไขข้อมูลได้`, err.response.data.desc);
                                }
                              });
                          },
                          true
                        );
                      }
                    })
                  ]}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataUpdate = [...dataPres];

                          const index = oldData.tableData.id;

                          dataUpdate[index] = newData;
                          editVotePublishPresident(
                            oldData.optPersonPresId,
                            newData.score,
                            newData.optVoteSelectedStatusId,
                            newData.replaceOptPersonPresId,
                            newData.optPositionStatusId,
                            newData.replaceRemark
                          )
                            .then(result => {
                              sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                              setDataPres([...dataUpdate]);
                            })
                            .catch(err => {
                              //sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้",err.toString());
                              sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.response.data.desc);
                            });

                          resolve();
                        }, 1000);
                      })
                  }}
                  localization={{}}
                  options={{ paging: false, defaultExpanded: true }}
                ></TableComponent>
              </GridItem>

              <br></br>
              <br></br>

              <br></br>

              <hr size="20"></hr>
            </>
          ) : (
            <></>
          )}
          {positionTypeSelected === null || positionTypeSelected.id === 2 ? (
            <TableComponent
              title="ข้อมูลผลคะแนน/ผู้ได้รับเลือกตั้ง (ส.ถ.)"
              columns={columnsMem}
              data={dataMem}
              enableInitColumns={false}
              components={{
                Header: props => {
                  return optVoteTypeSelected && optVoteTypeSelected.id === 1 ? (
                    <TableHead>
                      <TableRow>
                        {optSelected && optTypes !== ["5"] ? (
                          <TableCell>เขต</TableCell>
                        ) : (
                          <TableCell>อำเภอ</TableCell>
                        )}

                        <TableCell>หมายเลขผู้สมัคร</TableCell>

                        <TableCell>ชื่อ สกุล</TableCell>
                        <TableCell
                          align="right"
                          style={{ paddingRight: "100px" }}
                        >
                          คะแนน
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  ) : (
                    <TableHead>
                      <TableRow>
                        {optSelected && optTypes === ["5"] ? (
                          <>
                            {/* <TableCell rowSpan={2} align="center">
                                เขต
                              </TableCell> */}
                            {/* <TableCell rowSpan={2}>ลำดับ</TableCell> */}
                          </>
                        ) : (
                          <>
                            {/* <TableCell rowSpan={2} align="center">
                                เขต
                              </TableCell> */}
                            {/* <TableCell rowSpan={2}>ลำดับ</TableCell> */}
                            <TableCell rowSpan={2}>อำเภอ</TableCell>
                          </>
                        )}

                        <TableCell rowSpan={2}>หมายเลขผู้สมัคร</TableCell>

                        <TableCell rowSpan={2} align="center">
                          ชื่อ สกุล
                        </TableCell>
                        <TableCell rowSpan={2} align="right">
                          คะแนน
                        </TableCell>

                        <TableCell colSpan={3} align="center">
                          เลือกตั้งแทนผู้สมัครเดิม
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">ชื่อ สกุล</TableCell>
                        <TableCell align="center">สาเหตุ</TableCell>
                        <TableCell align="center">หมายเหตุ</TableCell>
                      </TableRow>
                    </TableHead>
                  );
                },
                Container: props => <Paper {...props} elevation={0} />
              }}
              actions={[
                rowData => ({
                  icon: props => (
                    // <div
                    //   style={{
                    //     fontFamily: "Athiti",
                    //     fontWeight: "400",
                    //     position: "absolute",
                    //     width: "max-content",
                    //     marginLeft: "40px",
                    //     marginRight: "100px"
                    //   }}
                    // >
                    //   {/* <GridItem>ไม่ได้</GridItem> */}
                    //   ไม่ได้
                    //   <AntSwitch
                    //     checked={rowData.optVoteSelectedStatusId === 2}
                    //     name="optVoteSelectedStatusId"
                    //   />
                    //   ได้
                    //   {/*
                    //   <GridItem>ได้</GridItem> */}
                    // </div>
                    <>
                      <GridItem
                        style={{
                          fontWeight: "400",
                          fontFamily: "Athiti",
                          padding: "5px"
                        }}
                      >
                        ไม่ได้
                      </GridItem>
                      <AntSwitch
                        checked={rowData.optVoteSelectedStatusId === 2}
                        name="optVoteSelectedStatusId"
                      />
                      <GridItem
                        style={{
                          fontWeight: "400",
                          fontFamily: "Athiti",
                          padding: "5px"
                        }}
                      >
                        ได้
                      </GridItem>
                    </>
                  ),
                  onClick: (event, rowData) => {
                    const dataUpdate = [...dataMem];

                    const changeData = {
                      [event.target.name]: event.target.checked === true ? 2 : 3
                    };

                    const newData = { ...rowData, ...changeData };

                    const index = rowData.tableData.id;
                    dataUpdate[index] = newData;
                    sweetAlerts.danger(
                      "ยืนยันข้อมูล",
                      "ยืนยันการเปลี่ยนแปลงผู้ที่ได้รับการเลือกตั้ง",
                      () => {
                        editVotePublishMember(
                          newData.optPersonMemId,

                          newData.score,
                          newData.optVoteSelectedStatusId,
                          newData.replaceOptPersonMemId,
                          newData.optPositionStatusId,
                          newData.replaceRemark
                        )
                          .then(result => {
                            setDataMem([...dataUpdate]);
                            sweetAlerts.success(`แก้ไขข้อมูลเรียบร้อยแล้ว`);
                          })
                          .catch(err => {
                            if (err.response.data.code !== 20000) {
                              sweetAlerts.danger(`ไม่สามารถแก้ไขข้อมูลได้`, err.response.data.desc);
                            }
                          });
                      },
                      true
                    );
                  }
                })
              ]}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...dataMem];

                      const index = oldData.tableData.id;

                      dataUpdate[index] = newData;

                      editVotePublishMember(
                        oldData.optPersonMemId,

                        newData.score,
                        newData.optVoteSelectedStatusId,
                        newData.replaceOptPersonMemId,
                        newData.optPositionStatusId,
                        newData.replaceRemark
                      )
                        .then(result => {
                          sweetAlerts.success("แก้ไขข้อมูลเรียบร้อยแล้ว");
                          setDataMem([...dataUpdate]);
                        })
                        .catch(err => {
                          sweetAlerts.error("ไม่สามารถแก้ไขข้อมูลได้", err.response.data.desc);
                        });

                      resolve();
                    }, 1000);
                  })
              }}
              localization={{
                header: {
                  actions: "kkkkk"
                }
              }}
              options={{ paging: false, defaultExpanded: true }}
            //  onRowUpdate={onRowUpdate}
            ></TableComponent>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default OptVotepublish;
