import React, { useContext, useState } from "react";
import {
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CheckboxOpttype from "components/Common/CheckboxOpttype";
import ConvertDate from "utils/ConvertDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxMsAreaEct from "components/Common/ListboxMsAreaEct.js";
import ListboxOptVotetype from "components/Common/ListboxOptVotetype";
import ListboxProvince from "components/Common/ListboxProvince.js";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import TableComponent from "components/Table/MaterialTable";
import { UserInfoContext } from "contexts/users";
import { VoteDateContext } from "contexts/VoteDate";
import { getOptStatus } from "contexts/API/OPT/OptStatus";
import { makeStyles } from "@material-ui/core/styles";
import { useSweetAlert } from "components/Alert/SweetAlert";

const CompleteOptData = props => {
  const useStyles = makeStyles({
    buttoncustom: {
      fontSize: 14,
      fontWeight: 500
      //color: props => props.color
    }
  });
  const {
    optVoteTypeSelected,
    provinceSelected,
    optTypeSelected,
    areaEctSelected,
    onAreaEctSelected,
    onOptVoteTypeSelected,
    onProvinceSelected,
    onOptTypeSelected,
    getOptTypeSelectedLists
  } = useContext(VoteDateContext);

  const classes = useStyles();

  //local state

  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [data, setData] = useState([]);
  const { sweetAlerts } = useSweetAlert();
  const { userInfo } = useContext(UserInfoContext);

  const handelSubmit = () => {
    let optIdLists = getOptTypeSelectedLists();

    if (optIdLists.length === 0) {
      //   endPoint += `&optSubType=[${optIdLists.toString()}]`;
      // } else {
      sweetAlerts.warning("กรุณาเลือกประเภท อปท.");
      return;
    }

    if (optVoteTypeSelected === null) {
      sweetAlerts.warning("กรุณาเลือกประเภทการเลือกตั้ง");
      return;
    }

    /* if (areaEctSelected === null) {
      sweetAlerts.warning("กรุณาเลือกภาค");
      return;
    }
    if (provinceSelected === null) {
      sweetAlerts.warning("กรุณาเลือกจังหวัด");
      return;
    } */

    const _columnGeneral = [
      {
        title: "จังหวัด",
        field: "provinceName",
        cellStyle: {
          minWidth: "30px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        }
      },
      {
        title: "ประเภท อปท.",
        field: "optShortName",
        cellStyle: {
          minWidth: "30px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        }
      },
      {
        title: "ชื่อ อปท.",
        field: "optName",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        }
      },

      {
        title: "บันทึกวันเลือกตั้งทั่วไป ผ.ถ.",
        field: "recordVotePres",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.recordVotePres === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.recordVotePres === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "บันทึกวันเลือกตั้งทั่วไป ส.ถ.",
        field: "recordVoteMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.recordVoteMem === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.recordVoteMem === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "บันทึกผู้สมัคร ผ.ถ.",
        field: "recordPersonPres",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.recordPersonPres === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.recordPersonPres === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "บันทึกผู้สมัคร ส.ถ.",
        field: "recordPersonMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.recordPersonMem === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.recordPersonMem === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "ประกาศผล ผ.ถ.",
        field: "publishVotePres",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.publishVotePres === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.publishVotePres === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "ประกาศผล ส.ถ.",
        field: "publishVoteMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.publishVoteMem === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.publishVoteMem === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "บันทึกใช้สิทธิ ผ.ถ.",
        field: "usedVotePres",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.usedVotePres === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.usedVotePres === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "บันทึกใช้สิทธิ ส.ถ.",
        field: "usedVoteMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.usedVoteMem === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.usedVoteMem === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "วันเลือกตั้งทั่วไปล่าสุด ผ.ถ.",
        field: "votedateHead",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          return ConvertDate(rowData.votedateHead);
        }
      },
      {
        title: "วันครบวาระ ผ.ถ.",
        field: "endEffectiveHead",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          return ConvertDate(rowData.endEffectiveHead);
        }
      },
      {
        title: "วันเลือกตั้งทั่วไปล่าสุด ส.ถ.",
        field: "votedateMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          return ConvertDate(rowData.votedateMem);
        }
      },
      {
        title: "วันครบวาระ ส.ถ.",
        field: "endEffectiveMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          return ConvertDate(rowData.endEffectiveMem);
        }
      }
    ];

    const _columnReplace = [
      {
        title: "จังหวัด",
        field: "provinceName",
        headerStyle: {
          textAlign: "center"
        },
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        }
      },
      {
        title: "ประเภท อปท.",
        field: "optShortName",
        headerStyle: {
          textAlign: "center"
        },
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        }
      },
      {
        title: "ชื่อ อปท.",
        field: "optName",
        headerStyle: {
          textAlign: "center"
        },
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        }
      },
      {
        title: "วันเลือกตั้งซ่อม",
        field: "tambol_name",
        headerStyle: {
          textAlign: "center"
        },
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        render: rowData => {
          return ConvertDate(rowData.voteVdate);
        }
      },
      {
        title: "เขตที่จัดเลือกตั้ง",
        field: "areaNo",
        headerStyle: {
          textAlign: "center"
        },
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        }
      },
      {
        title: "บันทึกผู้สมัคร ส.ถ.",
        field: "recordPersonMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.recordPersonMem === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.recordPersonMem === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "ประกาศผล ส.ถ.",
        field: "publishVoteMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.publishVoteMem === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.publishVoteMem === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "บันทึกใช้สิทธิ ส.ถ.",
        field: "usedVoteMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.usedVoteMem === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.usedVoteMem === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      }
    ];
    const _columnNew = [
      {
        title: "จังหวัด",
        field: "provinceName",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        }
      },
      {
        title: "ประเภท อปท.",
        field: "optShortName",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        }
      },
      {
        title: "ชื่อ อปท.",
        field: "optName",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        }
      },
      {
        title: "วันเลือกตั้งซ่อม",
        field: "tambol_name",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          return ConvertDate(rowData.voteVdate);
        }
      },
      {
        title: "เขตที่จัดเลือกตั้ง",
        field: "areaNo",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        }
      },
      {
        title: "บันทึกผู้สมัคร ส.ถ.",
        field: "recordPersonMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.recordPersonMem === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.recordPersonMem === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "ประกาศผล ส.ถ.",
        field: "publishVoteMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.publishVoteMem === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.publishVoteMem === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "บันทึกใช้สิทธิ ส.ถ.",
        field: "usedVoteMem",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.usedVoteMem === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.usedVoteMem === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "บันทึกผู้สมัคร ผ.ถ.",
        field: "recordPersonPres",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.recordPersonPres === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.recordPersonPres === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "ประกาศผล ผ.ถ.",
        field: "publishVotePres",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.publishVotePres === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.publishVotePres === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      },
      {
        title: "บันทึกใช้สิทธิ ผ.ถ.",
        field: "usedVotePres",
        cellStyle: {
          minWidth: "50px",
          textAlign: "center"
        },
        headerStyle: {
          textAlign: "center"
        },
        render: rowData => {
          if (rowData.usedVotePres === true) {
            return (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "green", fontSize: 20 }}
              />
            );
          }
          if (rowData.usedVotePres === false) {
            return (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: "red", fontSize: 20 }}
              />
            );
          }
        }
      }
    ];
    const initColumn = [
      {
        title: "ลำดับ",
        field: "rowId",
        export: true,
        //editable: "onAdd",
        editable: "never",
        cellStyle: {
          minWidth: "20px",
          paddingLeft: "10px",
          textAlign: "center"
        },
        headerStyle: {
          paddingLeft: "10px",
          textAlign: "center"
        },
        render: rowData => {
          if (
            rowData &&
            rowData.tableData !== undefined &&
            rowData.tableData.id !== undefined
          )
            return rowData.tableData.id + 1;
        }
      }
    ];
    setLoading(true);
    setIsClick(true);
    getOptStatus(
      optVoteTypeSelected,
      //(provinceSelected === null ? {id: userInfo.provinceId} :provinceSelected),
      provinceSelected,
      areaEctSelected,
      optIdLists
    )
      .then(result => {
        if (result.data !== null && result.data.data.length > 0) {
          if (optVoteTypeSelected) {
            if (optVoteTypeSelected.id === 1) {
              setColumns([...initColumn, ..._columnGeneral]);
              // setColumns([...columns, initColumn]);
            }
            if (optVoteTypeSelected.id === 2) {
              setColumns([...initColumn, ..._columnReplace]);
              //   setColumns(_columnReplace);
            }
            if (optVoteTypeSelected.id === 3) {
              setColumns([...initColumn, ..._columnNew]);
              //   setColumns(_columnNew);
            }
            setIsClick(false);
            setLoading(false);
          }

          setData(result.data.data);
        } else {
          sweetAlerts.info("ไม่พบข้อมูล");
          setIsClick(false);
          setLoading(false);
          setData(null);
        }
      })
      .catch(err => {
        sweetAlerts.error("ไม่สามารถดึงข้อมูลได้ในขณะนี้");
      });
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" text>
              <CardText color="primary">
                <h4 className={classes.cardTitle}>สถานะการบันทึกข้อมูล</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <br /> <br />
                  <GridItem xs={12} sm={12} md={12}>
                    <CheckboxOpttype
                      optTypeSelected={optTypeSelected}
                      onOptTypeSelected={onOptTypeSelected}
                      required={true}
                    ></CheckboxOpttype>
                  </GridItem>
                  {/* </GridContainer> */}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxOptVotetype
                        value={optVoteTypeSelected}
                        onChange={onOptVoteTypeSelected}
                        required={true}
                        validators={[MustNotNull]}
                      ></ListboxOptVotetype>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <ListboxMsAreaEct
                        value={areaEctSelected}
                        onChange={onAreaEctSelected}
                        disabled={!userInfo.iscenter}
                      ></ListboxMsAreaEct>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {/* <ListboxProvince
                        areaEctSelected={areaEctSelected}
                        title="เลือกทุกจังหวัด"
                        disabled={!userInfo.iscenter}
                        defaultValue={{
                          id:
                            userInfo.provinceId !== null && !userInfo.iscenter
                              ? userInfo.provinceId
                              : null
                        }}
                      
                        //value={provinceSelected}
                        onChange={onProvinceSelected}
                      ></ListboxProvince> */}
                      <ListboxProvince
                        areaEctSelected={areaEctSelected}
                        value={provinceSelected}
                        onChange={onProvinceSelected}
                      ></ListboxProvince>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>

              <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ paddingTop: "30px" }}
              >
                <GridItem></GridItem>
                <GridItem>
                  <Button
                    color="info"
                    onClick={handelSubmit}
                    disabled={isClick}
                  >
                    ค้นหา
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {loading === true ? (
        <LoadingData />
      ) : (
        <TableComponent
          title="รายละเอียดการบันทึกข้อมูล"
          columns={columns}
          data={data}
          enableInitColumns={false}
          options={{
            pageSize: 10,
            headerStyle: {
              backgroundColor: "#ddd",
              color: "#000",
              zIndex: 0,
              font: "Athiti",
              position: "sticky",
              top: 0,
              paddingLeft: "0px",
              paddingRight: "0px"
            },
            cellStyle: {
              padding: "0"
            }
          }}
        ></TableComponent>
      )}
    </>
  );
};

export default CompleteOptData;
