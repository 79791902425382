import "index.css";
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { AlertUtilsProvider } from "components/Alert/SweetAlert";
import ChangePassword from "views/pages/ChangePassword";
import CheckSuspend from "views/pages/CheckSuspend";
import CheckWtd from "views/pages/CheckWtd";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { Integrations } from "@sentry/tracing";
import Login from "views/pages/Login";
// Layouts
import Main from "layouts/Main";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Provider } from "react-redux";
/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { SnackBarProvider } from "components/Alert/Snackbar";
import Test from "layouts/Test";
import { UserInfoContextProvider } from "contexts/users";
import { createBrowserHistory } from "history";
import { store } from "./redux/store";

const hist = createBrowserHistory();

const THEME = createMuiTheme({
  typography: {
    fontFamily: `"Athiti", sans-serif`,
    fontSize: 14,

    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    color: "#00000"
  },
  h4: {
    fontFamily: `"Athiti", sans-serif`
  }
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={THEME}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <UserInfoContextProvider>
          <AlertUtilsProvider>
            <SnackBarProvider>
              <Router history={hist}>
                <Switch>
                  <Route path="/main" component={Main} />
                  <Route path="/login" component={Login} />
                  <Route path="/test" component={Test} />
                  <Route path="/changepassword" component={ChangePassword} />
                  <Route path="/CheckSuspend" component={CheckSuspend} />
                  <Route path="/revoke" component={CheckWtd} />
                  <Route path="/suspension" component={CheckSuspend} />
                  <Redirect from="/" to="/login" />
                </Switch>
              </Router>
            </SnackBarProvider>
          </AlertUtilsProvider>
        </UserInfoContextProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
