/* core components */
import React, { useEffect, useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
/* custom components */
import conf from "config/env.conf.js";
import { makeStyles } from "@material-ui/core/styles";
import { useFetch } from "hooks/useFetch";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(3)
  }
}));
const CheckboxWtdStatus = props => {
  const [isLoading, fetchData] = useFetch(
    conf.END_POINT_URL + "/wtd/wtdStatus",
    []
  );
  const classes = useStyles();
  const [valid, setValid] = useState();
  const [, setCountAll] = useState(0);

  const [helperText, setHelperText] = useState("");

  let content = <p>กำลังโหลดข้อมูล...</p>;

  useEffect(() => {
    let wtdIdLists = [];
    for (const [, v] of Object.entries(props.wtdCheckBoxSelected)) {
      if (v.checked) {
        wtdIdLists.push(v.value);
        setCountAll(wtdIdLists.length);
      }
    }

    if (props.required && wtdIdLists.length > 0) {
      setValid(false);
      setHelperText("");
    }
    if (props.required && wtdIdLists.length === 0) {
      setValid(true);
      //setHelperText("");
      setHelperText("ต้องเลือกอย่างน้อย1อย่าง");
    }
  }, [props.wtdCheckBoxSelected, props.required]);

  if (!isLoading && typeof fetchData !== "undefined" && fetchData !== null) {
    content = (
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel required={props.required} error={valid}>
            <span style={{ color: "#000", fontSize: 15 }}>
              สถานะการถูกเพิกถอน
            </span>
          </FormLabel>

          <FormGroup>
            {fetchData.data.map(wtd => (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={props.disabled}
                    checked={
                      props.wtdCheckBoxSelected[wtd.wtdStatusId]
                        .checked
                    }
                    onChange={e => {
                      props.onWtdCheckBoxSelected(e);
                    }}
                    value={wtd.wtdStatusId}
                    name={`${wtd.wtdStatusId}`}
                  />
                }
                key={wtd.wtdStatusId}
                label={wtd.detail}
              />
            ))}
          </FormGroup>
          <FormHelperText
            style={{ color: "red", fontSize: 12, textAlign: "left" }}
          >
            {helperText}
          </FormHelperText>
        </FormControl>
      </div>
    );
  }
  return content;
};
export default CheckboxWtdStatus;
