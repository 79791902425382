import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import EditAllocate from "./EditAllocate";
import TableComponent from "components/Table/MaterialTable";
import { loadCSS } from "fg-loadcss";
//import { useSweetAlert } from "components/Alert/SweetAlert";
//import { VoteDateContext } from "contexts/VoteDate";
//import styles from "assets/jss/material-dashboard-pro-react/views/gridSystemStyle.js";
//const useStyles = makeStyles(styles);

const DetailAllocate = ({ pageProps, setPageProps }) => {
  //const [loading, setLoading] = useState(false);
  //const [data, setData] = useState([]);
  //const [columns, setColumns] = useState([]);

  /*   const { sweetAlerts } = useSweetAlert();
    const [showModaladdCardallocate, setshowModaladdCardallocate] = useState(false);
    const openModaladdCardallocate = () => setshowModaladdCardallocate(true);
    const closeModaladdCardallocate = () => setshowModaladdCardallocate(false);
    
    const [purchaseID, setPurchaseID] = useState(pageProps.props.PurchaseID);
   */
  const backtoMainHandler = rowData => {
    pageProps.props.action = 'backtohome';
    setPageProps({ active: "CardAllocate", props: pageProps });
  };
  const useStyles = makeStyles(theme => ({
    listbox: {
      marginTop: "-15px"
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 600
    },
    text: {
      textAlign: "right"
    },
    space: {
      margin: "10px"
    }
  }));
  const classes = useStyles();
  //const {} = useContext(VoteDateContext);

  const [showDetail, setShowDetail] = useState(false);
  const [detail, setDetail] = useState([]);
  const onClick = () => {
    // setShowDetail(!showDetail);
    if (showDetail === false) {
      setShowDetail(true);
    }
    if (showDetail === true) {
      setShowDetail(false);
    }
    console.log(showDetail);
  };

  const [data] = useState([
    {
      index: 1,
      optName: "อำเภอ ก",
      optPersonPresId: "อปท กก",
      firstName: 1,
      personId: "ผถ",
      registerStatus: "dddd",
      optPositionTypeId: "",
      num: "30",
      numofreal: "20",
      numofcard: "320"
    },
    {
      index: 2,
      optName: "อำเภอ ข",
      optPersonPresId: "อปท กจ",
      firstName: 3,
      personId: "สถ",
      registerStatus: "dddd",
      optPositionTypeId: "",
      num: "10",
      numofreal: "200",
      numofcard: "300"
    }
  ]);
  const [columns] = useState([
    {
      title: "อำเภอ",
      field: "optName"
    },
    {
      title: "ชื่อ อปท.",
      field: "optPersonPresId",
      editable: "never"
    },
    {
      title: "เขต",
      field: "firstName",
      editable: "never"
    },
    {
      title: "ตำแหน่ง",
      field: "personId"
    },
    {
      title: "วันที่เลือกตั้ง",
      field: "registerStatus"
    },
    {
      title: "จำนวนผู้สมัคร",
      field: "num"
    },
    {
      title: "จำนวนผู้มีสิทธิ",
      field: "numofreal"
    },
    {
      title: "จำนวนบัตรเลือกตั้ง",
      field: "numofcard"
    },
    {
      title: "รายละเอียดการจัดสรรบัตร",
      field: "optPositionTypeId"
    },
    {
      title: "",
      field: "",
      render: rowData => (
        <Button
          size="sm"
          color="warning"
          onClick={() => {
            setDetail(rowData);
            onClick();
          }}
        >
          จัดการบัตรเลือกตั้งด้วยตนเอง
        </Button>
      )
    }
  ]);

  useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <div className={classes.cardHeader}>
            <h4 className={classes.cardTitle}>รายละเอียด</h4>
          </div>
          <br />
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer className={classes.space}>
                  <GridItem xs={3} sm={3} md={3}>
                    เลขที่แนบท้ายหนังสือ (สนง.กกต.) :
                  </GridItem>
                  <GridItem xs={9} sm={9} md={9}>
                    กรุงเทพมหานคร-กกต 11222/22
                  </GridItem>
                </GridContainer>
                <GridContainer className={classes.space}>
                  <GridItem xs={3} sm={3} md={3}>
                    ลงวันที่:
                  </GridItem>
                  <GridItem xs={9} sm={9} md={9}>
                    จ 2 มิ.ย. 2557
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </Card>
      <TableComponent
        title="รายการบัตรเลือกตั้งที่สั่งซื้อ"
        columns={columns}
        data={data}
        localization={{}}
        options={{
          paging: false
        }}
      />
      {showDetail === true ? <EditAllocate allocateData={detail} /> : ""}
      <br></br>
      <GridContainer
        justify="space-between"
        direction="column"
        alignItems="flex-end"
      >
        <GridItem>
          <Button color="default" onClick={backtoMainHandler}>
            กลับ
          </Button>
        </GridItem>
      </GridContainer>
    </>
  );
};
export default DetailAllocate;
