/* core components */
import React, { useState, useEffect } from "react";
import MaterialTable from "components/Table/MaterialTable";
import { getAreaVoteLists } from "contexts/API/OPT/OptArea";

const TableAreaVotedate = props => {
  let content = <p>กำลังโหลดตารางเขตที่ต้องการจัดการเลือกตั้ง...</p>;
  //console.log("props>>>", props);
  const columns = [
    {
      field: "amphur_name",
      title: "เขตอำเภอ",
      cellStyle: {
        textAlign: "center"
      },
      //defaultGroupOrder: 0
    },
    {
      field: "area_no",
      title: "เขตที่",
      cellStyle: {
        textAlign: "center"
      },
    },
    {
      field: "num_member",
      title: "จำนวนสมาชิก",
      cellStyle: {
        textAlign: "center"
      },
    },
    {
      field: "num_station",
      title: "จำนวนหน่วย",
      cellStyle: {
        textAlign: "center"
      },
    }
  ];

  const options_table = {
    // filter: true,
    // print: false,
    // download: true,
    // // filterType: "dropdown",
    // selectableRows: "none",
    // responsive: "vertical",
    // viewColumns: true
  };

  let [data, setData] = useState([]);

  useEffect(() => {
    console.log("props.data >>", props.data)
    if (props.data === null || props.data === undefined) {
      const voteDate = props.props.voteDate.split("T")[0];
      const optVoteId = props.props.optVoteId;
      getAreaVoteLists(props.optSelected.id, voteDate, null)

        .then(r => {
          if (r.data.data[0] !== undefined) {
            setData(r.data.data[0].opt_area);
          }
        })
        .catch(err => {
          console.log("error while fetching OPT area", err);
        });
    } else {
      setData(props.data);
    }
  }, [props.data, props.optSelected, props]);

  if (data.length > 0) {
    content = (
      <MaterialTable
        data={data}
        columns={columns}
        title={"รายชื่อเขตที่จัดการเลือกตั้ง"}
        options={options_table}
      ></MaterialTable>
    );
  }
  return content;
};
export default TableAreaVotedate;
