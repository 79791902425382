import "./Wtd.css";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import SaveIcon from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NumberFormat from "react-number-format";
import { REPORTING_SERVICE } from "config/env.conf";
import React from "react";
//import PrintIcon from "@material-ui/icons/Print";
import SearchIcon from "@material-ui/icons/Search";
//import CustomInputWithValidator from "components/CustomInput/CustomInputWithValidator";
import TextField from "@material-ui/core/TextField";
// Utils
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import logo from "assets/img/ectpl_logo_login.png";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Context APIs
import { postWtdPerson } from "contexts/API/Reports/WtdPerson";
import { useSweetAlert } from "components/Alert/SweetAlert";

//import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

export default function CheckWtd(props) {
  const { sweetAlerts } = useSweetAlert();

  const useStyles = makeStyles(styles => ({
    mainresult: {
      paddingTop: "20px",

      // backgroundColor: "#005DDB",
      // color: "#fff",
      height: "calc(100% - 0%)"
    },

    result: {
      paddingTop: "10px",
      fontSize: "clamp(1.3rem, 2.5vw, 2.3rem)",
      //  fontSize: "2.8vw",
      Color: "#000",
      fontWeight: "500",
      textAlign: "center",
      marginTop: "20px"
    },
    title: {
      fontSize: "clamp(1.25rem, 2.8vw, 4rem)",
      color: "#fff",
      fontWeight: "500",
      textAlign: "center",
      marginTop: "20px"
    },
    subresult: {
      // paddingTop: "50px",
      fontSize: "clamp(1.2rem , 2.5vw, 2rem)",

      color: "#000",
      fontWeight: "400",
      textAlign: "center",
      // marginTop: "50px"
      marginTop: "calc(100% - 92%)"
    },
    subtitle: {
      fontSize: "clamp(1.25rem, 2.2vw, 3rem)", //1rem = 16px
      color: "#fff",
      fontWeight: "500",
      textAlign: "center",
      marginTop: "calc(100% - 92%)"
    },
    searchbox: {
      paddingTop: "40px",
      paddingLeft: "30px",
      paddingRight: "30px",
      textAlign: "center"
    },
    flexsearchInput: {
      height: "100px",
      padding: "0 46px 0 10px",
      width: "100%",
      borderRadius: "30px",

      borderWidth: "5px",
      marginTop: "15px",
      // color: "#333",
      boxShadow: "2px 3px 10px #292929",
      backgroundColor: "#fff",
      fontSize: "26px"
    },

    search_box: {
      width: " 100%",
      padding: "20px",
      paddingRight: "60px",

      marginTop: "calc(100% - 95%)",
      borderRadius: "20px",
      borderWidth: "5px",
      boxShadow: "2px 3px 10px #292929",
      backgroundColor: "#fff",
      color: "#fff",
      outline: "none",
      marginBottom: "50px",
      fontSize: "clamp(18px, 3vw, 30px)"
    },

    icon: {
      position: "absolute",
      top: " 50%",
      transform: "translateY(-50%)",
      right: "25px",
      color: " #fff",
      fontSize: "25px"
    },
    resize: {
      fontSize: "clamp(0.75rem, 3vw, 1.3rem)",
      fontWeight: "400"
    }
  }));

  const classes = useStyles();
  const [isIdCardValid, setIsIdCardValid] = React.useState(false);
  const [idCard, setIdCard] = React.useState("");
  const [wtdData, setWtdData] = React.useState(null);
  const [tempId, setTempId] = React.useState(null);

  const printingReport = id => {
    window.open(
      `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freport_online%2frevoke%2frevoke_rpt_PersonCheck&rs:Command=Render&rc:Parameters=Collapsed&keyTempIdCard=${id}&Status=0&date=2021-07-06&orgName=test&year=0&total=1`,
      "_blank"
    );
  };

  const onSearch = v => {
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      if (isError) {
        sweetAlerts.danger("เลขประจำตัวประชาชนผิด");
      } else {
        setIsIdCardValid(true);
        setIdCard(v);

        postWtdPerson(v.value)
          .then(r => {
            //console.log(">>>", r.data.data.result[0]);
            if (r.data.data.result[0].wtdSdate === null && r.data.data.result[0].wtdEdate === null) {
              setWtdData(null);
            } else {
              setWtdData(r.data.data.result[0]);
            }
            setTempId(r.data.data.keyTempIdCard);
          })
          .catch(err => {
            console.log(err.response.data);
            sweetAlerts.error("เกิดข้อผิดพลาดขณะตรวจสอบข้อมูล");
          });
      }
    } else {
      if (isIdCardValid === true) {
        setIsIdCardValid(false);
        setIdCard(null);
      }
    }
  };

  return (
    <div>
      <GridContainer
        // spacing={3}
        direction="row"
        //justify="space-around"
        justify="center"
        alignItems="center"
      >
        <GridItem xs={12} sm={12} md={12}>
          <CardBody className="mainheader">
            <GridContainer className="header">
              <GridItem xs={12} sm={12} md={3} className="logoimg">
                <img src={logo} alt="" />
              </GridItem>

              {/* ฝั่งซ้าย */}
              <GridItem xs={12} sm={12} md={9} className="maintitle">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11} className={classes.title}>
                    ตรวจสอบข้อมูลผู้ถูกเพิกถอนสิทธิการเลือกตั้ง
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={11}
                    className={classes.subtitle}
                  >
                    สำนักงานคณะกรรมการการเลือกตั้ง
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer
              direction="row"
              justify="center"
              alignItems="stretch"
            >
              <GridItem xs={12} sm={12} md={10}>
                <NumberFormat
                  placeholder="เลขประจำตัวประชาชน"
                  customInput={TextField}
                  className={classes.search_box}
                  format="# #### ##### ## #"
                  onValueChange={onSearch}
                  InputProps={{
                    endAdornment: <SearchIcon position="end"></SearchIcon>,
                    classes: {
                      input: classes.resize
                    }
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </GridItem>
        {/* result */}
        {isIdCardValid ? (
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardBody>
                <GridContainer
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <GridItem xs={12} sm={12} md={2} className="logoresult">
                    {wtdData ? (
                      <CheckIcon
                        style={{ fontSize: 90, color: "green" }}
                      ></CheckIcon>
                    ) : (
                      <ClearIcon
                        style={{ fontSize: 90, color: "red" }}
                      ></ClearIcon>
                    )}
                  </GridItem>
                  <GridItem sm={12} md={10}>
                    <GridContainer
                      spacing={2}
                      direction="column"
                      justify="center"
                      alignItems="stretch"
                    >
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.result}
                      >
                        {wtdData
                          ? "พบข้อมูลการถูกเพิกถอนสิทธิ"
                          : "ไม่พบข้อมูลการถูกเพิกถอนสิทธิ"}
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.subresult}
                      >
                        เลขประจำตัวประชาชน: {idCard.formattedValue}
                      </GridItem>
                      {/* <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.subresult}
                      >
                        {suspendedData ? suspendedData.personName : ""}
                      </GridItem> */}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <br></br>
                <GridContainer
                  direction="column"
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <Button color="info" onClick={e => printingReport(tempId)}>
                      บันทึกไฟล์
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <div className="footerFlex">
            <div>
              ข้อมูลตรวจสอบดังกล่าว เป็นข้อมูลการตรวจสอบในเบื้องต้นเท่านั้น{" "}
            </div>
            <div>ไม่สามารถนำไปใช้อ้างอิงได้ หากท่านต้องการข้อมูลเพิ่มเติม</div>
            <div>โปรดติดต่อสำนักงานคณะกรรมการเลือกตั้งประจำจังหวัดของท่าน</div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
