import React, { useState } from "react";
import ManageAllocate from "./ManageAllocate";
import PostWay from "./PostWay";
import OnhandWay from "./OnhandWay";
import { VoteDateContextProvider } from "contexts/VoteDate";
const Index = props => {
  const SwitchComponents = ({ active, children }) => {
    return children.filter(child => child.props.name === active);
  };
  // Local State
  const [pageProps, setPageProps] = useState({
    active: "ManageAllocate",
    props: null
  });

  return (
    <VoteDateContextProvider>
      <SwitchComponents active={pageProps.active}>
        <ManageAllocate
          name="ManageAllocate"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <PostWay
          name="PostWay"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
        <OnhandWay
          name="OnhandWay"
          pageProps={pageProps}
          setPageProps={setPageProps}
        />
      </SwitchComponents>
    </VoteDateContextProvider>
  );
};

export default Index;
