import React, { useContext, useState } from "react";

import Button from "components/CustomButtons/Button.js";
//import ListboxProvince from "components/Common/ListboxProvince.js";
import CheckboxSuspendStatus from "components/Common/CheckboxSuspendStatus";
import ConvertDate from "utils/ConvertDate";
import CustomInput from "components/CustomInput/CustomInput.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListboxSuspendYear from "components/Common/ListboxSuspendYear.js";
import LoadingData from "components/Common/LoadingData";
import NumberFormat from "react-number-format";
import { Paper } from "@material-ui/core";
import { REPORTING_SERVICE } from "config/env.conf";
import { SuspendContext } from "contexts/Suspend";
import TableComponent from "components/Table/MaterialTable";
import TextField from "@material-ui/core/TextField";
//Material Core
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { checkSuspendPerson } from "contexts/API/SUSPEND/CheckSuspend";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
// core components
import { useSweetAlert } from "components/Alert/SweetAlert";

const CheckPerson = props => {
  const { sweetAlerts } = useSweetAlert();

  //const [valid, setValid] = useState(true);
  const [idValid, setIdValid] = useState(true);

  const [idCard, setIdCard] = useState(null);
  const [isIdCardValid, setIsIdCardValid] = useState(false);
  const [suspendTypes, setSuspendTypes] = useState("");
  const [loading, setLoading] = useState(false);
  const [orgName, setOrgName] = useState(null);
  const [orgNameState, setOrgNameState] = useState(true);
  const [keyTempIdCard, setKeyTempIdCard] = useState(null);

  const {
    //onProvinceSelected,
    //provinceSelected,
    yearSelected,
    onYearSelected,
    supspendCheckBoxSelected,
    //onSuspendStatusSelected,
    onSupspendCheckBoxSelected,
    getSupspendCheckboxLists
  } = useContext(SuspendContext);

  const optionsTable = {
    paging: false,
    search: false,
    exportButton: false
  };
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "เลขประจำตัวประชาชน",
      field: "idCard",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center"
      }
    },
    {
      title: "ผลการตรวจระงับสิทธิสมัครฯ",
      field: "suspendStatusDetail",
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      title: "ผลการตรวจเพิกถอนสิทธิสมัครฯ",
      field: "revokeStatusDetail",
      cellStyle: {
        textAlign: "center"
      }
    }
  ]);

  const onIDCardHandler = v => {
    //setIdCard(v.value);
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      if (isError) {
        sweetAlerts.danger("เลขประจำตัวประชาชนผิด");
        //  setIdValid(!idValid);
        if (isIdCardValid === true) {
          setIsIdCardValid(false);
        }
        setIdValid(true);
      } else {
        if (isIdCardValid === false) {
          setIsIdCardValid(true);
        }
        setIdCard(v.value);
        setIdValid(false);
        //setIdValid(!idValid);
      }
    } else {
      setIsIdCardValid(false);
      setIdValid(true);
    }
  };

  const handelSubmit = () => {
    let year = 0;

    if (!yearSelected) {
      year = 0;
    } else {
      year = yearSelected.id;
    }
    let supType = "";
    setSuspendTypes(getSupspendCheckboxLists());

    if (suspendTypes.length > 0) {
      supType = suspendTypes;
    }

    const today = new Date();
    today.setFullYear(today.getFullYear() - year);
    const date = today.toISOString().split("T")[0];

    if (idCard === null) {
      sweetAlerts.warning("กรุณาระบุเลขประจำตัวประชาชน");
      return;
    }

    if (orgNameState === "" && orgName === null) {
      setOrgNameState(false);
    }

    if (orgName === null) {
      sweetAlerts.warning("กรุณาระบุหน่วยงาน");
      return;
    }

    if (date === null) {
      sweetAlerts.warning("กรุณาเลือกระยะเวลาถูกเพิกถอน");
      return;
    } else {
      setLoading(true);
      setData([]);

      checkSuspendPerson(date, [idCard], null, null, supType, idCard.toString())
        .then(result => {
          setKeyTempIdCard(result.data.data.keyTempIdCard);
          const dataUniqKey = {};
          result.data.data.result.forEach(v => {
            let revokeStatusDetail = ""; //เพิกถอนสิทธิสมัคร
            let suspendStatusDetail = ""; //ระงับสิทธิสมัคร
            let __level__ = 0;
            //console.log("revokeStatusDetail ", !v.revokeEdate || !v.revokeSdate)
            if (
              !v.revokeEdate ||
              !v.revokeSdate /* ถ้าไม่มี suspendEdate ให้ถือว่ายังไม่เคยดำรงตำแหน่ง */
            ) {
              __level__ = 0;
              revokeStatusDetail = "--ไม่พบข้อมูล--";
            } else {
              __level__ = 1;
              revokeStatusDetail = `${ConvertDate(
                v.revokeSdate
              )} - ${ConvertDate(v.revokeEdate)}`;
              /* )} จ.${v.provinceName}`; */
            }

            if (
              !v.suspendEdate ||
              !v.suspendSdate /* ถ้าไม่มี startEffectiveDate ให้ถือว่ายังไม่เคยดำรงตำแหน่ง */
            ) {
              __level__ = 0;
              suspendStatusDetail = "--ไม่พบข้อมูล--";
            } else {
              __level__ = 1;
              suspendStatusDetail = `${ConvertDate(
                v.suspendSdate
              )} - ${ConvertDate(v.suspendEdate)}`;
              /* )} จ.${v.provinceName}`; */
            }
            if (dataUniqKey[v.idCard] === undefined) {
              /* บันทึกสถานะ */
              dataUniqKey[v.idCard] = {
                idCard: v.idCard,
                resultRevoke: [revokeStatusDetail],
                resultSuspend: [suspendStatusDetail],
                __level__: __level__
              };
            } else if (__level__ >= 1) {
              /* เพิ่มประวัติการดำรงตำแหน่ง */

              if (dataUniqKey[v.idCard].__level__ === 0) {
                dataUniqKey[v.idCard].resultRevoke = [revokeStatusDetail];
                dataUniqKey[v.idCard].resultSuspend = [suspendStatusDetail];
                dataUniqKey[v.idCard].__level__ = __level__;
              } else {
                dataUniqKey[v.idCard].resultRevoke = [
                  ...dataUniqKey[v.idCard].resultRevoke,
                  revokeStatusDetail
                ];
                dataUniqKey[v.idCard].resultSuspend = [
                  ...dataUniqKey[v.idCard].resultSuspend,
                  suspendStatusDetail
                ];
                dataUniqKey[v.idCard].__level__ = __level__;
              }
            } else {
              //do nothing
            }
          });
          setData(Object.values(dataUniqKey));

          setColumns([
            {
              title: "เลขประจำตัวประชาชน",
              field: "idCard",
              cellStyle: {
                minWidth: "150px"
              },
              render: rowData => {
                return (
                  <NumberFormat
                    value={rowData.idCard}
                    format="# #### ##### ## #"
                    style={{
                      width: "100%",
                      border: "0"
                    }}
                  />
                );
              }
            },
            {
              title: "ผลการตรวจระงับสิทธิสมัครฯ",
              field: "resultSuspend",
              render: rawData => {
                return rawData.resultSuspend.map((e, i) => {
                  return <div key={i}>{e}</div>;
                });
              },
              cellStyle: {
                minWidth: "200px"
              }
            },
            {
              title: "ผลการตรวจเพิกถอนสิทธิสมัครฯ",
              field: "resultRevoke",
              render: rawData => {
                return rawData.resultRevoke.map((e, i) => {
                  return <div key={i}>{e}</div>;
                });
              },
              cellStyle: {
                minWidth: "200px"
              }
            }
          ]);
          setLoading(false);
        })
        .catch(err => {
          sweetAlerts.error("ไม่สามารถดึงข้อมูล", err.response.data.desc);
        });
    }
  };
  const exportUrl = () => {
    let endPointExp = "";
    let year = 0;

    if (!yearSelected) {
      year = 0;
    } else {
      year = yearSelected.id;
    }

    const today = new Date();
    today.setFullYear(today.getFullYear() - year);
    const checkDate = today.toISOString().split("T")[0];

    endPointExp += `&keyTempIdCard=${keyTempIdCard}&Status=${suspendTypes.toString() !== "" ? suspendTypes.toString() : 0
      }&date=${checkDate}&orgName=${orgName !== null ? orgName : 0}&year=${year !== null ? year : 0
      }&total=1`;

    return endPointExp;
  };

  const handleSubmitExport = e => {
    e.preventDefault();
    window.open(
      //`${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2Freport_online%2Frpt_suspend_person_detection&rs:Command=Render&rc:Parameters=Collapsed${exportUrl()}`
      `${REPORTING_SERVICE}/Pages/ReportViewer.aspx?%2freports%2fsuspend%2fsuspend_rpt_PersonCheck&rs:Command=Render&rc:Parameters=Collapsed${exportUrl()}`
    );
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br /> <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <NumberFormat
                style={{
                  width: "100%",
                  margin: "0 0 17px 0",
                  paddingTop: "27px"
                }}
                placeholder="เลขประจำตัวประชาชนที่ต้องการตรวจสอบ"
                customInput={TextField}
                format="# #### ##### ## #"
                mask="X"
                fullWidth
                error={idValid}
                onValueChange={onIDCardHandler}
              />

              <ListboxSuspendYear
                title="ระยะเวลาที่ตรวจสอบย้อนหลัง"
                defaultValue={{ id: 0 }}
                onChange={onYearSelected}
              ></ListboxSuspendYear>
              {/*  <div style={{ display: "none" }}>
                <input
                  type="password"
                  name="password"
                  autocomplete="new-password"
                ></input>
              </div> */}

              <CustomInput
                value={orgName}
                labelText="หน่วยงานที่ขอตรวจสอบ (โปรดระบุ)"
                error={orgNameState}
                formControlProps={{
                  fullWidth: true
                }}
                helpertext="กรุณาระบุ*"
                inputProps={{
                  onChange: e => {
                    if (verifyLength(e.target.value, 1)) {
                      setOrgNameState(false);
                    } else {
                      setOrgNameState(true);
                    }
                    setOrgName(e.target.value);
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CheckboxSuspendStatus
                supspendCheckBoxSelected={supspendCheckBoxSelected}
                onSupspendCheckBoxSelected={onSupspendCheckBoxSelected}
              ></CheckboxSuspendStatus>
              {/* <CustomInput
                labelText="ชื่อ หรือ นามสกุล"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: name,

                  onChange: e => {
                    setName(e.target.value);
                  }
                }}
              /> */}
            </GridItem>
          </GridContainer>
          {/* <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <GridItem xs={12} sm={12} md={4}>
              <ListboxProvince
                value={provinceSelected}
                title={"จังหวัดที่ถูกระงับสิทธิสมัครฯ/เพิกถอนสิทธิสมัครฯ"}
                onChange={onProvinceSelected}
              ></ListboxProvince>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <ListboxSuspendYear
                labelText="ระยะเวลาถูกเพิกถอน"
                defaultValue={{ id: 0 }}
                onChange={onYearSelected}
              ></ListboxSuspendYear>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField label="หน่วยงานที่มีคำสั่ง" fullWidth /> 
            </GridItem>
          </GridContainer> */}
          <GridContainer justify="space-between" alignItems="center">
            <GridItem xs={6} sm={6} md={6}></GridItem>
            <GridItem>
              <Button
                onClick={() => {
                  setData([]);
                }}
              >
                ยกเลิก
              </Button>
              <Button
                disabled={!isIdCardValid}
                color="info"
                onClick={handelSubmit}
              >
                ตรวจสอบ
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <br />
      {data.length !== 0 ? (
        <GridContainer justify="space-between" alignItems="center">
          <GridItem xs={6} sm={6} md={6}></GridItem>
          <GridItem>
            นำออกข้อมูล:{" "}
            <Button justIcon round color="success" onClick={handleSubmitExport}>
              <FontAwesomeIcon icon={faFileExcel} />
            </Button>
          </GridItem>
        </GridContainer>
      ) : null}
      {loading === true ? (
        <LoadingData />
      ) : (
        <TableComponent
          title={
            "ผลการตรวจสอบบุคคลที่ถูกระงับสิทธิสมัครรับเลือกตั้ง และการถูกเพิกถอนสิทธิสมัครรับเลือกตั้ง"
          }
          options={optionsTable}
          columns={columns}
          data={data}
          components={{
            Container: props => <Paper {...props} elevation={0} />
          }}
        />
      )}
    </>
  );
};
export default CheckPerson;
