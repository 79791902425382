import CustomTabs from "components/CustomTabs/CustomTabs.js";
import React from "react";
import RptOnlineOpt from "./RptOnlineOpt";
// core components
import RptSummaryOpt from "./RptSummaryOpt";
import { VoteDateContextProvider } from "contexts/VoteDate";
import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const styles = {};
const useStyles = makeStyles(styles);

const ReportIndex = props => {
  const classes = useStyles();
  return (
    <div className={classes.tabcustom}>
      <VoteDateContextProvider>
        <CustomTabs
          headerColor="primary"
          styles={customTabsStyles}
          tabs={[
            {
              tabName: "รายงาน",
              tabContent: <RptSummaryOpt />
            },
            {
              tabName: "ส่งรายงานออนไลน์",

              tabContent: <RptOnlineOpt />
            }
          ]}
        />
      </VoteDateContextProvider>
    </div>
  );
};
export default ReportIndex;
