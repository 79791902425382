import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import MsOptManger from "./MsOptManager";
import OptArea from "./OptArea";
import MsOptUpLevel from "./MsOptUplevel";
import MsOptIncluded from "./MsOptIncluded";
import { VoteDateContextProvider } from "contexts/VoteDate";

import customTabsStyles from "assets/jss/material-dashboard-pro-react/components/customTabsStyleForECTPL";

const styles = {
  textCenter: {
    textAlign: "center"
  },
  //tabRoot: { backgroundColor: "#0a6" },
  span: {
    backgroundColor: "#aa0",
    fontSize: "22px"
  }
};

const useStyles = makeStyles(styles);

const OptHandler = props => {
  const classes = useStyles();

  const addOptSchema = yup.object().shape({
    provinceId: yup.string().required("This field is required."),
    amphurId: yup.string().required("This field is required.")
  });

  const addOptForm = useForm({
    validationSchema: addOptSchema
  });

  return (
    <VoteDateContextProvider>
      <div className={classes.tabcustom}>
        <form>
          <CustomTabs
            styles={customTabsStyles}
            headerColor="primary"
            tabs={[
              {
                tabName: "บริหารทะเบียน อปท.",
                tabContent: <MsOptManger formProps={addOptForm} />
              },
              {
                tabName: "บริหารจัดการเขต",
                tabContent: <OptArea />
              },
              {
                tabName: "ยกฐานะ",
                tabContent: <MsOptUpLevel />
              },
              {
                tabName: "ยุบรวม",
                tabContent: <MsOptIncluded />
              }
            ]}
          />
        </form>
      </div>
    </VoteDateContextProvider>
  );
};

export default OptHandler;
