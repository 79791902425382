import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();

export function getOptStatus(
  optVoteTypeSelected,
  provinceSelected,
  areaEctSelected,
  optIdLists
) {
  const isAuth = cookies.get("userInfo");
  let endPoint = "";
  //let optTypes = getOptTypeSelectedLists();

  if (provinceSelected !== null && provinceSelected !== 0) {
    endPoint += `&provinceId=${provinceSelected.id}`;
  }
  if (areaEctSelected !== null) {
    endPoint += `&areaEct=${areaEctSelected.id}`;
  }
  if (optIdLists.length > 0) {
    endPoint += `&optSubType=[${optIdLists.toString()}]`;
  }

  const url =
    `${END_POINT_URL}/opt/voteType/${optVoteTypeSelected.id}/statusOptData?` +
    endPoint;
  ///opt/voteType/3/statusOptData?provinceId=71&areaEct=1&optSubType=[1]
  console.log(url);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
