import React  from "react";

import AddPerson from "./AddPerson";

import { WtdContextProvider } from "contexts/Wtd";

const Index = props => {
  // Local State
  //const [activeComponent, setActiveComponent] = useState("AddPerson");

  return (
    <WtdContextProvider>
      <AddPerson />
    </WtdContextProvider>
  );
};

export default Index;
