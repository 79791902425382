import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";
const cookies = new Cookies();
//-------------ประเภทหน่วยงาน-----------------//
export function getOptType() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/opt/types`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editOptType(
  ms_opt_type_id,
  opt_type_name,
  opt_type_short_name
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/opt/type/${ms_opt_type_id}`;

  const body = {
    optTypeName: opt_type_name,
    optTypeShortName: opt_type_short_name
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getSubOpttype() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/opt/subtypes`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editSubOpttype(ms_opt_sub_type_id, opt_name, opt_short_name) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/ms/opt/subtype/${ms_opt_sub_type_id}`;

  const body = {
    msOptTypeId: ms_opt_sub_type_id,
    optName: opt_name,
    optShortName: opt_short_name
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getVoteType() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/vote/type`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
export function editVoteType(voteTypeId, detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/vote/type/${voteTypeId}`;

  const body = {
    detail: detail
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getVoteStatus() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/vote/status`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getVotePositionstatus() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/position/status`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editVotePositionstatus(statusId, detail) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/position/status/${statusId}`;
  const body = {
    detail: detail
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
//------------------------------------ประเภทตำแหน่ง
export function getPositionType() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/position/type`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function editPositionType(
  positionTypeId,
  positionTypeDetail,
  positionTypeShortNameOrginal
) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/opt/position/type/${positionTypeId}`;
  const body = {
    positionTypeDetail: positionTypeDetail,
    positionTypeShortName: positionTypeShortNameOrginal
  };
  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
