import CHK_IMPORT_FILE from "./CheckImportfile";
import CHK_PERSON from "./CheckPerson";
// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import React from "react";
import { SuspendContextProvider } from "contexts/Suspend";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const styles = {};
const useStyles = makeStyles(styles);

const CheckSuspendIndex = props => {
  const classes = useStyles();
  return (
    <div className={classes.tabcustom}>
      <SuspendContextProvider>
        <CustomTabs
          headerColor="rose"
          tabs={[
            {
              tabName: (
                <span style={{ fontSize: "16px", color: "#fff" }}>
                  สืบค้นรายบุคคล
                </span>
              ),
              tabContent: <CHK_PERSON />
            },
            {
              tabName: (
                <span style={{ fontSize: "16px", color: "#fff" }}>
                  สืบค้นข้อมูลแบบกลุ่ม (นำเข้าข้อมูล)
                </span>
              ),
              tabContent: <CHK_IMPORT_FILE />
            }
          ]}
        />
      </SuspendContextProvider>
    </div>
  );
};
export default CheckSuspendIndex;
