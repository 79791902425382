/* core components */
import React, { useContext, useState } from "react";
import { addWtdPerson, getCitizenWtd } from "contexts/API/WTD/Listwtd";

import AddFault from "./AddFault";
import AddFile from "./AddFile";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datepicker from "components/Common/Datepicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import ListboxDistinct from "components/Common/ListboxDistinct";
import ListboxProvince from "components/Common/ListboxProvince";
import ListboxSubdistinct from "components/Common/ListboxSubdistinct";
import ListboxTitle from "components/Common/ListboxTitle";
import ListboxWtdOrg from "components/Common/ListboxWtdOrg";
import ListboxWtdStatus from "components/Common/ListboxWtdStatus";
import ListboxWtdVoteType from "components/Common/ListboxWtdVoteType";
import ListboxWtdYear from "components/Common/ListboxWtdYear";
import LoadingData from "components/Common/LoadingData";
import MustNotNull from "utils/Validators/MustNotNull";
import NumberFormat from "react-number-format";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import ThaiCitizenID from "utils/Validators/ThaiCitizenID";
import { WtdContext } from "contexts/Wtd";
import WtdEndDate from "utils/WtdEndDate";
import getAge from "utils/CalculateAge";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSweetAlert } from "components/Alert/SweetAlert";
import { UserInfoContext } from "contexts/users";
import { wsWTDAddWtdPerson } from "contexts/API/DOPA/WS";
import ConvertDateFormat from "utils/ConvertDateFormat";

const AddPerson = ({ pageProps, setPageProps }) => {
  const {
    provinceSelected,
    setProvinceSelected
  } = useContext(WtdContext);
  const userInfo = useContext(UserInfoContext);
  let initialState = {
    idCard: null,
    genderId: null,
    titleId: null,
    firstName: null,
    lastName: null,
    birthdate: null,
    addr1: null,
    addr1TambolId: null,
    addr1AmphurId: null,
    addr1ProvinceId: null,
    wtdPersonInfo: {
      wtdProvinceId: provinceSelected,
      // wtdProvinceId: null,
      wtdEdate: null,
      wtdSdate: null,
      wtdYear: null,
      wtdOrgId: null,
      wtdStatusId: null,
      wtdCommandDetail: null,
      voteTypeId: null,
      lawsuitNumber: null
    }
  };
  const [addr1ProvinceId, setAddr1ProvinceId] = useState({
    id: 0,
    value: ""
  });
  const [addr1AmphurId, setAddr1AmphurId] = useState({
    id: 0,
    value: ""
  });
  const [addr1TambolId, setAddr1TambolId] = useState({
    id: 0,
    value: ""
  });

  const useStyles = makeStyles({});
  const { sweetAlerts } = useSweetAlert();
  const [isIdCardValid, setIsIdCardValid] = React.useState(false);
  const [data, setData] = useState(initialState);
  const [dataLaw] = useState([]);
  const [dataFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [helpertextGender, setHelpertextGender] = useState(null);
  const [helpertexttitle, setHelpertexttitle] = useState("");
  const [helpertextlname, setHelpertextlname] = useState("");
  const [helpertextfname, setHelpertextfname] = useState("");
  const [helpertextaddr, setHelpertextaddr] = useState("");
  const [helpertexttambol, setHelpertexttambol] = useState("");
  const [helpertextvotetype, setHelpertextvotetype] = useState("");
  const [helpertextyear, setHelpertextyear] = useState("");
  const [helpertextwtdorg, setHelpertextwtdorg] = useState("");
  const [helpertextwtdstatus, setHelpertextwtdstatus] = useState("");
  const [helpertextedate, setHelpertextedate] = useState("");
  const [helpertextsdate, setHelpertextsdate] = useState("");
  const [helpertextLaw, setHelpertextLaw] = useState("");
  const [helpertextBd, setHelpertextBd] = useState("");
  const classes = useStyles();
  const [wtdPersonId, setWtdPersonId] = useState("");

  const [foundWtd, setFoundWtd] = useState(false);
  let history = useHistory();

  const onadd1ProvinceSelected = (evt, newValue) => {
    setAddr1ProvinceId(newValue);
    setAddr1AmphurId(null);
    setAddr1TambolId(null);
    setData({
      ...data,
      addr1ProvinceId: newValue ? newValue.id : 0,
      addr1ProvinceName: newValue ? newValue.value : ""
    });
  };
  const onadd1AmphurSelected = (evt, newValue) => {
    setAddr1AmphurId(newValue);
    setData({
      ...data,
      addr1AmphurId: newValue ? newValue.id : 0,
      addr1AmphurName: newValue ? newValue.value : ""
    });
  };
  const onadd1TambolSelected = (evt, newValue) => {
    setAddr1TambolId(newValue);

    setData({
      ...data,
      addr1TambolId: newValue ? newValue.id : 0,
      addr1TambolName: newValue ? newValue.value : ""
    });
  };

  const onProvinceWtd = (evt, newValue) => {
    if (newValue) {
      setProvinceSelected(newValue);

      setData({
        ...data,
        wtdPersonInfo: {
          ...data.wtdPersonInfo,
          wtdProvinceId: newValue.id
        }
      });
    } else {
      setProvinceSelected(null);

      setData({
        ...data,
        wtdPersonInfo: {
          ...data.wtdPersonInfo,
          wtdProvinceId: null
        }
      });
    }
  };

  async function fetchData(idCard) {
    setIsLoading(true);

    try {
      const person = await getCitizenWtd(idCard);

      let result = person.data.data;
      // console.log(result[0]);

      // console.log("legngt", result.length);
      if (result.length > 0 && result[0] != null) {
        if (result[0].wtdPerson.length > 0) {
          sweetAlerts.warning(
            "บุคคลนี้ได้อยู่ระหว่างการถูกเพิกถอน หากต้องการแก้ไขให้ไปที่เมนูรายชื่อผู้ถูกเพิกถอน"
          );
          setFoundWtd(true);
        } else {
          setFoundWtd(false);
        }
        //  console.log(Object.keys(result[0].wtdPerson[0]).length);

        setIsLoading(false);

        setData({
          ...data,
          idCard: idCard,
          genderId: result[0].genderId,
          titleId: result[0].titleId,
          firstName: result[0].firstName,
          lastName: result[0].lastName,
          birthdate: result[0].birthdate,
          addr1: result[0].addr1,
          addr1TambolId: result[0].addr1TambolId,
          addr1AmphurId: result[0].addr1AmphurId,
          addr1ProvinceId: result[0].addr1ProvinceId,
          wtdPersonInfo: {
            wtdProvinceId: provinceSelected !== null ? provinceSelected.id : 0,
            wtdEdate: null,
            wtdSdate: null,
            wtdYear: null,
            wtdOrgId: null,
            wtdStatusId: null,
            wtdCommandDetail: null,
            voteTypeId: null,
            lawsuitNumber: null
          }
        });
        setAddr1ProvinceId({
          id: result[0].addr1ProvinceId,
          value: result[0].addr1ProvinceName
        });
        setAddr1AmphurId({
          id: result[0].addr1AmphurId,
          value: result[0].addr1AmphurName
        });
        setAddr1TambolId({
          id: result[0].addr1TambolId,
          value: result[0].addr1TambolName
        });
      } else {
        sweetAlerts.warning("ไม่พบเลขประจำตัวประชาชนนี้ในระบบ");
        console.log("provinceSelected >", provinceSelected)
        setData({
          ...data,
          idCard: idCard,
          genderId: null,
          titleId: null,
          firstName: null,
          lastName: null,
          birthdate: null,
          addr1: null,
          addr1TambolId: null,
          addr1AmphurId: null,
          addr1ProvinceId: null,
          wtdPersonInfo: {
            wtdProvinceId: provinceSelected !== null ? provinceSelected.id : 0,
            wtdEdate: null,
            wtdSdate: null,
            wtdYear: null,
            wtdOrgId: null,
            wtdStatusId: null,
            wtdCommandDetail: null,
            voteTypeId: null,
            lawsuitNumber: null
          }
        });

        setIsLoading(false);
        setFoundWtd(false);
        setAddr1ProvinceId({
          id: 0,
          value: ""
        });
        setAddr1AmphurId({
          id: 0,
          value: ""
        });
        setAddr1TambolId({
          id: 0,
          value: ""
        });
        if (userInfo.userInfo.iscenter) {
          setProvinceSelected({
            id: 0,
            value: ""
          });
        }
      }
    } catch (error) {
      sweetAlerts.error("ไม่สามารถดึงข้อมูล", error.toString());
    }
  }

  const onIDCardHandler = v => {
    if (
      v !== null &&
      v !== undefined &&
      v.value !== null &&
      v.value !== undefined &&
      v.value.length === 13
    ) {
      const [isError] = ThaiCitizenID(v.value);
      if (isError) {
        sweetAlerts.danger("เลขประจำตัวประชาชนผิด");
        if (isIdCardValid === true) {
          setIsIdCardValid(false);
        }
      } else {
        if (isIdCardValid === false) {
          setIsIdCardValid(true);
          fetchData(v.value);
        } else {
          fetchData(v.value);
        }
      }
    }

    setData({ ...data, idCard: v.value });
  };

  const handelSubmit = () => {
    if (data.genderId === null) {
      setHelpertextGender("กรุณาระบุ*");
    }
    if (data.titleId === null) {
      setHelpertexttitle("กรุณาระบุ*");
    }
    if (data.addr1TambolId === 0 || null) {
      setHelpertexttambol("กรุณาระบุ*");
    }
    if (data.birthdate === null) {
      setHelpertextBd("กรุณาระบุ*");
    }
    if (
      data.wtdPersonInfo === undefined ||
      data.wtdPersonInfo.voteTypeId === undefined ||
      data.wtdPersonInfo.voteTypeId === null
    ) {
      setHelpertextvotetype("กรุณาระบุ*");
    }
    if (
      data.wtdPersonInfo === undefined ||
      data.wtdPersonInfo.wtdYear === undefined ||
      data.wtdPersonInfo.wtdYear === null
    ) {
      setHelpertextyear("กรุณาระบุ*");
    }

    if (
      data.wtdPersonInfo === undefined ||
      data.wtdPersonInfo.wtdOrgId === undefined ||
      data.wtdPersonInfo.wtdOrgId === null
    ) {
      setHelpertextwtdorg("กรุณาระบุ*");
    }

    if (
      data.wtdPersonInfo === undefined ||
      data.wtdPersonInfo.wtdStatusId === undefined ||
      data.wtdPersonInfo.wtdStatusId === null
    ) {
      setHelpertextwtdstatus("กรุณาระบุ*");
    }
    if (
      data.wtdPersonInfo === undefined ||
      data.wtdPersonInfo.wtdEdate === null ||
      data.wtdPersonInfo.wtdEdate === undefined
    ) {
      setHelpertextedate("กรุณาระบุ*");
    }
    if (
      data.wtdPersonInfo === undefined ||
      data.wtdPersonInfo.wtdSdate === null ||
      data.wtdPersonInfo.wtdSdate === undefined
    ) {
      setHelpertextsdate("กรุณาระบุ*");
    }
    if (
      data.wtdPersonInfo === undefined ||
      data.wtdPersonInfo.lawsuitNumber === null ||
      data.wtdPersonInfo.lawsuitNumber === undefined
    ) {
      setHelpertextLaw("กรุณาระบุ*");
    }

    if (data.firstName === null) {
      setHelpertextfname("กรุณาระบุ*");
    }
    if (data.lastName === null) {
      setHelpertextlname("กรุณาระบุ*");
    }
    if (data.addr1 === null) {
      setHelpertextaddr("กรุณาระบุ*");
    }

    if (data.idCard.length !== 13) {
      sweetAlerts.warning("กรุณากรอกเลขประจำตัวประชาชนให้ครบ 13 หลัก");
      return;
    }

    if (
      data.genderId !== null &&
      data.titleId &&
      data.addr1TambolId &&
      data.firstName &&
      data.lastName &&
      data.birthdate &&
      data.wtdPersonInfo.wtdProvinceId !== 0 &&
      data.wtdPersonInfo !== undefined &&
      data.wtdPersonInfo.voteTypeId !== undefined &&
      data.wtdPersonInfo.voteTypeId !== null &&
      data.wtdPersonInfo.wtdYear !== undefined &&
      data.wtdPersonInfo.wtdYear !== null &&
      data.wtdPersonInfo.wtdOrgId !== undefined &&
      data.wtdPersonInfo.wtdOrgId !== null &&
      data.wtdPersonInfo.wtdEdate !== undefined &&
      data.wtdPersonInfo.wtdEdate !== null &&
      data.wtdPersonInfo.wtdSdate !== undefined &&
      data.wtdPersonInfo.wtdSdate !== null &&
      data.wtdPersonInfo.lawsuitNumber !== undefined &&
      data.wtdPersonInfo.lawsuitNumber !== null &&
      data.wtdPersonInfo.wtdStatusId !== undefined &&
      data.wtdPersonInfo.wtdStatusId !== null
    ) {
      addWtdPerson(data)
        .then(result => {

          console.log("wtdSdate >>>", ConvertDateFormat(data.wtdPersonInfo.wtdSdate))
          console.log("wtdEdate >>>", ConvertDateFormat(data.wtdPersonInfo.wtdEdate))

          /*---- start call web service Dopa -----*/
          wsWTDAddWtdPerson(data.idCard,
            data.wtdPersonInfo.wtdStatusId,
            ConvertDateFormat(data.wtdPersonInfo.wtdSdate),
            ConvertDateFormat(data.wtdPersonInfo.wtdEdate)
          ).then(r => {
            console.log("Call service wsWTDAddWtdPerson success ");
          })
            .catch(err => {
              console.log("Call service wsWTDAddWtdPerson Error :" + err);
            });
          /*---- end call web service Dopa -----*/

          setWtdPersonId(result.data.data.wtdPersonId);
          let desc =
            "*กรุณาระบุ พ.ร.บ.และมาตราที่กระทำผิด และเอกสารที่กระทำผิด";

          sweetAlerts.success(
            `เพิ่มข้อมูลเรียบร้อยแล้ว`,
            <p style={{ color: "red", fontSize: "24px", fontWeight: "bolder" }}>
              {desc}
            </p>
          );
        })
        .catch(err => {
          console.log(err.response);
          //  if (err.response.data.code !== 20000) {
          sweetAlerts.danger(
            `ไม่สามารถเพิ่มข้อมูลได้`,
            err.response.data.desc.toString()
          );
          //  }
        });
    } else {
      sweetAlerts.warning(`กรุณาระบุข้อมูลให้ครบถ้วน`);
    }
  };
  let content = <LoadingData />;
  if (isLoading === false) {
    content = (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>
                    บันทึกข้อมูลผู้ถูกเพิกถอน
                  </h4>
                </CardText>
              </CardHeader>

              <CardHeader>
                <h4 className={classes.cardTitle}>ข้อมูลส่วนบุคคล</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <NumberFormat
                            placeholder="เลขประจำตัวประชาชน"
                            style={{
                              paddingTop: "20px"
                            }}
                            customInput={TextField}
                            format="# #### ##### ## #"
                            mask="X"
                            fullWidth
                            value={data.idCard}
                            onValueChange={onIDCardHandler}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Button
                            disabled={!isIdCardValid}
                            variant="contained"
                            color="success"
                            onClick={() => fetchData(data.idCard)}
                          >
                            ตรวจสอบ
                          </Button>
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControlLabel
                            disabled={!isIdCardValid}
                            control={
                              <Radio
                                checked={
                                  data.genderId === "true" ||
                                  data.genderId === true
                                }
                                value={true}
                                onClick={e => {
                                  if (e.target.checked) {
                                    setHelpertextGender("");
                                  } else {
                                    setHelpertextGender("กรุณาระบุ*");
                                  }
                                  setData({
                                    ...data,
                                    genderId: !!JSON.parse(
                                      String(e.target.value).toLowerCase()
                                    )
                                  });
                                }}
                                name="genderId"
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot
                            }}
                            label="ชาย"
                          />
                          <FormControlLabel
                            disabled={!isIdCardValid}
                            control={
                              <Radio
                                onClick={e => {
                                  if (e.target.checked) {
                                    setHelpertextGender("");
                                  } else {
                                    setHelpertextGender("กรุณาระบุ*");
                                  }
                                  setData({
                                    ...data,
                                    genderId: e.target.value
                                  });
                                }}
                                checked={
                                  data.genderId === "false" ||
                                  data.genderId === false
                                }
                                value={false}
                                name="genderId"
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot
                            }}
                            label="หญิง"
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {data.genderId !== null ? "" : helpertextGender}
                          </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxTitle
                            disabled={!isIdCardValid}
                            defaultValue={{ id: data.titleId }}
                            onChange={(evt, newValue) => {
                              if (newValue) {
                                setData({
                                  ...data,
                                  titleId: newValue ? newValue.id : 0
                                });
                              } else {
                                setHelpertexttitle("กรุณาระบุ");
                              }
                            }}
                          ></ListboxTitle>
                          <FormHelperText style={{ color: "red" }}>
                            {data.titleId ? "" : helpertexttitle}
                          </FormHelperText>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            validators={[MustNotNull]}
                            labelText="ชื่อ"
                            formControlProps={{
                              fullWidth: true
                            }}
                            style={{ fontSize: "18px !important " }}
                            inputProps={{
                              disabled: !isIdCardValid,
                              value: data.firstName || "",
                              onChange: e => {
                                if (e) {
                                  setHelpertextfname("");
                                  setData({
                                    ...data,
                                    firstName: e.target.value
                                  });
                                } else {
                                  setHelpertextfname("กรุณาระบุ");
                                }
                              }
                              // disabled: true
                            }}
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {data.firstName ? "" : helpertextfname}
                          </FormHelperText>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            validators={[MustNotNull]}
                            labelText="นามสกุล"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:
                                !isIdCardValid ||
                                  [2, 5].includes(data.wtdStatusId)
                                  ? true
                                  : false,
                              value: data.lastName || "",
                              onChange: e => {
                                if (e) {
                                  setHelpertextlname("");
                                  setData({
                                    ...data,
                                    lastName: e.target.value
                                  });
                                } else setHelpertextlname("กรุณาระบุ");
                              }
                            }}
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {data.lastName ? "" : helpertextlname}
                          </FormHelperText>
                        </GridItem>
                      </GridContainer>
                      <br></br>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            success={data.addr1 !== null}
                            error={data.addr1 === null}
                            validators={[MustNotNull]}
                            labelText="ที่อยู่ตามบัตรประชาขน"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:
                                !isIdCardValid ||
                                  [2, 5].includes(data.wtdStatusId)
                                  ? true
                                  : false,
                              value: data.addr1 || "",

                              onChange: e => {
                                if (e) {
                                  setHelpertextaddr("");
                                  setData({ ...data, addr1: e.target.value });
                                } else setHelpertextaddr("กรุณาระบุ");
                              }
                            }}
                          />
                          <FormHelperText style={{ color: "red" }}>
                            {data.addr1 ? "" : helpertextaddr}
                          </FormHelperText>
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxProvince
                            disabled={!isIdCardValid}
                            validators={[MustNotNull]}
                            //disabled={false}
                            required={true}
                            value={addr1ProvinceId}
                            controll={true}
                            // defaultValue={{
                            //   id: data.addr1ProvinceId
                            // }}
                            onChange={onadd1ProvinceSelected}
                          ></ListboxProvince>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxDistinct
                            disabled={!isIdCardValid}
                            validators={[MustNotNull]}
                            value={addr1AmphurId}
                            controll={true}
                            required={true}
                            // defaultValue={{
                            //   id: data.addr1AmphurId
                            // }}
                            provinceSelected={{ id: data.addr1ProvinceId }}
                            onChange={onadd1AmphurSelected}
                          ></ListboxDistinct>
                        </GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <ListboxSubdistinct
                            disabled={!isIdCardValid}
                            amphurSelected={{ id: data.addr1AmphurId }}
                            controll={true}
                            required={true}
                            // provinceSelected={{ id: data.addr1ProvinceId }}
                            validators={[MustNotNull]}
                            value={addr1TambolId}
                            onChange={onadd1TambolSelected}
                          ></ListboxSubdistinct>
                          <FormHelperText style={{ color: "red" }}>
                            {data.addr1TambolId ? "" : helpertexttambol}
                          </FormHelperText>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}></GridItem>
                      </GridContainer>
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <GridItem xs={12} sm={12} md={6}>
                          <Datepicker
                            disabled={!isIdCardValid}
                            id="begin"
                            label="วันเดือนปีเกิด"
                            value={data.birthdate}
                            onChange={date => {
                              if (date) {
                                let arr = date.format().split("T");

                                setData({
                                  ...data,
                                  birthdate: arr[0],
                                  age: getAge(date.format())
                                });
                              }
                            }}
                          ></Datepicker>
                          <FormHelperText style={{ color: "red" }}>
                            {data.birthdate ? "" : helpertextBd}
                          </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          {/* <div style={{ marginTop: "15px" }}> */}

                          <CustomInput
                            labelText="อายุ"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              value:
                                data.birthdate === null
                                  ? 0
                                  : getAge(data.birthdate),
                              disabled: true
                            }}
                          />

                          {/* </div> */}
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}></GridItem>
                      </GridContainer>
                      <br /> <br />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/* ข้อมูลเพิกถอนสิทธิ */}
        {foundWtd === false ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                  <h4 className={classes.cardTitle}>ข้อมูลเพิกถอนสิทธิ</h4>
                </CardHeader>

                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              id="lawsuitNumber"
                              default
                              required
                              labelText="หมายเลขคดีอุทธรณ์/ฏีกา"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: !isIdCardValid,
                                autoComplete: "new-password",
                                defaultValue:
                                  data.wtdPersonInfo !== undefined
                                    ? data.wtdPersonInfo.lawsuitNumber
                                    : null,
                                onChange: e => {
                                  if (e.target.value) {
                                    setData({
                                      ...data,
                                      wtdPersonInfo: {
                                        ...data.wtdPersonInfo,
                                        lawsuitNumber: e.target.value
                                      }
                                    });
                                    setHelpertextLaw("");
                                  }
                                }
                              }}
                            />

                            <FormHelperText style={{ color: "red" }}>
                              {helpertextLaw}
                            </FormHelperText>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <ListboxWtdVoteType
                              disabled={!isIdCardValid}
                              validators={[MustNotNull]}
                              // defaultValue={{
                              //   id : data.wtdPersonInfo !== undefined
                              //   ? data.wtdPersonInfo.lawsuitNumber
                              //   : null,

                              // }}
                              onChange={(evt, newValue) => {
                                if (newValue) {
                                  setHelpertextvotetype("");
                                  setData({
                                    ...data,
                                    wtdPersonInfo: {
                                      ...data.wtdPersonInfo,
                                      voteTypeId: newValue.id
                                    }
                                  });
                                  // setData({
                                  //   ...data,
                                  //   wtdPersonInfo:{
                                  //     ...wtdPersonInfo,voteTypeId:newValue.id}})
                                  // });
                                  // setData({
                                  //   ...data,
                                  //   wtdPersonInfo: {
                                  //     voteTypeId: newValue.id
                                  //   }
                                  // });
                                }
                              }}
                            />
                            <FormHelperText style={{ color: "red" }}>
                              {helpertextvotetype}
                            </FormHelperText>
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          direction="row"
                          justify="space-between"
                          alignItems="flex-end"
                        >
                          <GridItem xs={12} sm={12} md={6}>
                            <ListboxProvince
                              //disabled={false}
                              validators={[MustNotNull]}
                              required={true}
                              value={provinceSelected}
                              onChange={onProvinceWtd}
                            ></ListboxProvince>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Datepicker
                              disabled={!isIdCardValid}
                              id="wtdSdate"
                              label="วันที่เริ่มการถูกเพิกถอน"
                              value={
                                data.wtdPersonInfo !== undefined &&
                                  data.wtdPersonInfo.wtdSdate !== undefined
                                  ? // data.wtdPersonInfo.wtdSdate !== undefined
                                  data.wtdPersonInfo.wtdSdate
                                  : null
                              }
                              onChange={date => {
                                if (date) {
                                  let arr = date.format().split("T");

                                  setData({
                                    ...data,
                                    wtdPersonInfo: {
                                      ...data.wtdPersonInfo,
                                      wtdSdate: arr[0]
                                    }
                                  });
                                  if (date && data.wtdPersonInfo.wtdYear) {
                                    const end_date = WtdEndDate(
                                      date,
                                      data.wtdPersonInfo.wtdYear
                                    );

                                    setData({
                                      ...data,
                                      wtdPersonInfo: {
                                        ...data.wtdPersonInfo,
                                        //wtdEdate: new Date(end_date * 1000),
                                        wtdEdate: moment(
                                          new Date(end_date)
                                        ).format("YYYY-MM-DD"),
                                        wtdSdate: arr[0]
                                      }
                                    });
                                  }
                                }

                                setHelpertextsdate("");
                              }}
                            ></Datepicker>
                            <FormHelperText style={{ color: "red" }}>
                              {helpertextsdate}
                            </FormHelperText>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <ListboxWtdYear
                              disabled={!isIdCardValid}
                              defaultValue={{ id: data.wtdYear }}
                              onChange={(evt, newValue) => {
                                if (newValue) {
                                  setHelpertextyear("");

                                  setData({
                                    ...data,
                                    wtdPersonInfo: {
                                      ...data.wtdPersonInfo,
                                      wtdYear: newValue.id
                                    }
                                  });
                                  if (
                                    data.wtdPersonInfo.wtdSdate &&
                                    newValue.id
                                  ) {
                                    const end_date = WtdEndDate(
                                      data.wtdPersonInfo.wtdSdate,
                                      newValue.id
                                    );

                                    setData({
                                      ...data,
                                      wtdPersonInfo: {
                                        ...data.wtdPersonInfo,
                                        wtdYear: newValue.id,
                                        wtdEdate: moment(
                                          new Date(end_date)
                                        ).format("YYYY-MM-DD")
                                      }
                                    });
                                  }
                                }
                              }}
                            ></ListboxWtdYear>
                            <FormHelperText style={{ color: "red" }}>
                              {helpertextyear}
                            </FormHelperText>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Datepicker
                              disabled={!isIdCardValid}
                              id="wtdEdate"
                              label="วันที่พ้นฯ"
                              value={
                                data.wtdPersonInfo !== undefined &&
                                  data.wtdPersonInfo.wtdEdate !== undefined
                                  ? data.wtdPersonInfo.wtdEdate
                                  : null
                              }
                              onChange={date => {
                                if (date) {
                                  let arr = date.format().split("T");

                                  setData({
                                    ...data,
                                    wtdPersonInfo: {
                                      ...data.wtdPersonInfo,
                                      wtdEdate: arr[0]
                                    }
                                  });
                                }
                                setHelpertextedate("");
                              }}
                            ></Datepicker>
                            <FormHelperText style={{ color: "red" }}>
                              {helpertextedate}
                            </FormHelperText>
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          direction="row"
                          justify="space-between"
                          alignItems="flex-end"
                        >
                          <GridItem xs={12} sm={12} md={6}>
                            <ListboxWtdOrg
                              disabled={!isIdCardValid}
                              validators={[MustNotNull]}
                              defaultValue={{
                                id: data.wtdOrgId
                              }}
                              onChange={(evt, newValue) => {
                                if (newValue) {
                                  setData({
                                    ...data,
                                    wtdPersonInfo: {
                                      ...data.wtdPersonInfo,
                                      wtdOrgId: newValue.id
                                    }
                                  });

                                  setHelpertextwtdorg("");
                                }
                              }}
                            ></ListboxWtdOrg>

                            <FormHelperText style={{ color: "red" }}>
                              {helpertextwtdorg}
                            </FormHelperText>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <ListboxWtdStatus
                              disabled={!isIdCardValid}
                              defaultValue={{ id: data.wtdStatusId }}
                              onChange={(evt, value) => {
                                if (value) {
                                  setHelpertextwtdstatus("");

                                  setData({
                                    ...data,
                                    wtdPersonInfo: {
                                      ...data.wtdPersonInfo,
                                      wtdStatusId: value.id
                                    }
                                  });
                                }
                              }}
                            ></ListboxWtdStatus>
                            <FormHelperText style={{ color: "red" }}>
                              {helpertextwtdstatus}
                            </FormHelperText>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              id="lawsuitNumber"
                              default
                              required
                              labelText="รายละเอียดการเพิกถอนสิทธิ/ฏีกา"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: !isIdCardValid,
                                autoComplete: "new-password",
                                defaultValue: data.wtdCommandDetail,
                                onChange: e => {
                                  if (e) {
                                    setData({
                                      ...data,
                                      wtdPersonInfo: {
                                        ...data.wtdPersonInfo,
                                        wtdCommandDetail: e.target.value
                                      }
                                    });
                                  }
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <br /> <br />
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
        <GridContainer justify="space-between" alignItems="center">
          <GridItem xs={6} sm={6} md={6}></GridItem>
          <GridItem>
            <Button
              onClick={() =>
                history.push({
                  pathname: "listWtd"
                })
              }
            >
              ย้อนกลับ
            </Button>
            <Button
              color="success"
              onClick={handelSubmit}
              disabled={foundWtd || !isIdCardValid}
            >
              บันทึกข้อมูล
            </Button>
          </GridItem>
        </GridContainer>
        {/* พ.ร.บ. */}
        {foundWtd === false ? (
          <>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={
                  wtdPersonId === ""
                    ? { pointerEvents: "none", opacity: "0.4" }
                    : {}
                }
              >
                <AddFault
                  dataLaw={dataLaw}
                  wtdPersonId={wtdPersonId}
                  status={1}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={
                  wtdPersonId === ""
                    ? { pointerEvents: "none", opacity: "0.4" }
                    : {}
                }
              >
                <AddFile
                  dataFile={dataFile}
                  wtdPersonId={wtdPersonId}
                  wtdProvinceId={data.wtdPersonInfo.wtdProvinceId}
                  status={1}
                />
              </GridItem>
            </GridContainer>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
  return content;
};
export default AddPerson;
