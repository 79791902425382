import Cookies from "universal-cookie";
import { END_POINT_URL } from "config/env.conf";
import axios from "axios";

const cookies = new Cookies();

export function getCheckingLists(provinceId, applyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/listCmtPersonReplace/provinceId/${provinceId}/cmdApplyDateId/${applyDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getPersonForReplace(provinceId, applyDateId) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/listInspectPersonForReplace/provinceId/${provinceId}/cmdApplyDateId/${applyDateId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function updatePersonForReplace(body) {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/replacePerson`;

  return axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}

export function getPosition() {
  const isAuth = cookies.get("userInfo");
  const url = `${END_POINT_URL}/inspector/position/status`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${isAuth.token}`
    }
  });
}
