import React, { createContext, useState, useContext } from 'react';
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// styles for buttons on sweetalert
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);

export const AlertUtilsContext = createContext();

export const useSweetAlert = () => useContext(AlertUtilsContext);

export const AlertUtilsProvider = ({ children }) => {

    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const initAlertInfo = {
        primary: false,
        success: true,
        info: false,
        warning: false,
        danger: false,
        showCancel: false,
        title: "title",
        contents: "contents...",
        onConfirm: () => { },
        onCancel: () => { },
        confirmBtnCssClass: classes.button + " " + classes.success,
        cancelBtnCssClass: classes.button + " " + classes.cancel
    }


    /* Declare states */
    const [alertInfo, setAlertInfo] = useState(initAlertInfo);

    /* Internal Handler */
    const _getConfirmText = (type) => {
        return (type === 'primary') ? 'ตกลง' :
            (type === 'success') ? 'ตกลง' :
                (type === 'warning') ? 'รับทราบ' :
                    (type === 'danger') ? 'ตกลง' :
                        (type === 'info') ? 'ตกลง' :
                            'ตกลง'
    }
    const _getCancelBtnText = (type) => {
        return (type === 'primary') ? 'ยกเลิก' :
            (type === 'success') ? 'ยกเลิก' :
                (type === 'warning') ? 'ยกเลิก' :
                    (type === 'danger') ? 'ยกเลิก' :
                        (type === 'info') ? 'ยกเลิก' :
                            'ยกเลิก'
    }
    const _onConfirm = (onConfirm) => {
        return function () {
            if (onConfirm !== undefined && onConfirm !== null)
                onConfirm();
            setIsOpen(false);
        }

    }
    const _onCancel = (onCancel) => {
        return function () {
        if (onCancel !== undefined && onCancel !== null)
            onCancel();
        setIsOpen(false);
        }
    }

    /* External function */
    const sweetAlert = (type, title, contents, onConfirm, showCancel, onCancel) => {
        setAlertInfo({
            ...initAlertInfo,
            primary: ('primary' === type),
            success: ('success' === type),
            info: ('info' === type),
            warning: ('warning' === type),
            danger: ('danger' === type),
            showCancel: (showCancel !== undefined && showCancel === true),
            confirmBtnText: _getConfirmText(type),
            cancelBtnText: _getCancelBtnText(type),
            title: title,
            contents: contents,
            onConfirm: _onConfirm(onConfirm),
            onCancel: _onCancel(onCancel),
            confirmBtnCssClass: classes.button + " " + classes[type] + " " + classes.cancel,
            cancelBtnCssClass: classes.button + " " + classes.cancel
        })
        setIsOpen(true);
    }

    const value = {
        sweetAlert: sweetAlert,
        sweetAlerts: {
            primary: (title, contents, onConfirm, showCancel, onCancel)=>{
                return sweetAlert("primary", title, contents, onConfirm, showCancel, onCancel);
            },
            success: (title, contents, onConfirm, showCancel, onCancel)=>{
                return sweetAlert("success", title, contents, onConfirm, showCancel, onCancel);
            },
            error: (title, contents, onConfirm, showCancel, onCancel)=>{
                return sweetAlert("danger", title, contents, onConfirm, showCancel, onCancel);
            },
            danger: (title, contents, onConfirm, showCancel, onCancel)=>{
                return sweetAlert("danger", title, contents, onConfirm, showCancel, onCancel);
            },
            warning: (title, contents, onConfirm, showCancel, onCancel)=>{
                return sweetAlert("warning", title, contents, onConfirm, showCancel, onCancel);
            },
            info: (title, contents, onConfirm, showCancel, onCancel)=>{
                return sweetAlert("info", title, contents, onConfirm, showCancel, onCancel);
            },
        }
    }

    const showAlert = () => {
        if (isOpen) {
            return (<SweetAlert {...alertInfo}>
                {alertInfo.contents}
            </SweetAlert>)
        }
    }

    return (
        <AlertUtilsContext.Provider value={value}>
            {showAlert()}
            {children}
        </AlertUtilsContext.Provider>
    );
};
